import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useMemo } from 'react';
import ProjectForm, { ProjectFormModalProps } from '.';

const ProjectFormModal: React.FC<ProjectFormModalProps> = ({
  project,
  open,
  handleCancel,
  handleSaveProject,
  parentProject,
}) => {
  const { t } = useTranslations();
  const isPriority = useMemo(
    () => !parentProject && !project.parent_project,
    [parentProject, project.parent_project],
  );
  return (
    <Modal
      open={open}
      handleCancel={handleCancel}
      size="large"
      styles={{
        body: { display: 'flex', flexDirection: 'column', gap: 24 },
      }}
    >
      <Typography elementTheme="h5">
        {!!project.project_id
          ? t('project|project_update', { project_name: project.title })
          : isPriority
            ? t('project|addNewPriority')
            : t('project|project_create')}
      </Typography>

      <ProjectForm
        project={project}
        isModal
        handleSaveProject={handleSaveProject}
        isPriority={isPriority}
      />
    </Modal>
  );
};

export default ProjectFormModal;
