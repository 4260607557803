import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { createFontSizeCSS } from '../../../theme/global-style';

export const RichTextEditorContainer = styled(ReactQuill)`
  border-radius: 4px;
  .ql-toolbar {
    background-color: ${({ theme }) => theme.colors.grey_1};
    .ql-picker {
      background-color: ${({ theme }) => theme.colors.white_1};
      color: ${({ theme }) => theme.colors.grey_1};
      min-width: 160px;
      height: auto;
      .ql-picker-label {
        padding: 4px 12px;
      }
      &:hover {
        .ql-picker-label {
          color: ${({ theme }) => theme.colors.purple_2};
          svg {
            * {
              stroke: ${({ theme }) => theme.colors.purple_3};
            }
          }
        }
      }
      .ql-picker-options {
        .ql-picker-item {
          &:hover {
            color: ${({ theme }) => theme.colors.purple_2};
          }
        }
      }
    }
    .ql-formats {
      button {
        svg {
          * {
            stroke: ${({ theme }) => theme.colors.white_1};
          }
        }
        &:hover {
          svg {
            * {
              stroke: ${({ theme }) => theme.colors.purple_3};
            }
          }
        }
      }
    }
  }
  .ql-container {
    border-color: ${({ theme }) => theme.colors.grey_1};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    ${createFontSizeCSS('body2')}
    &:hover {
      border-color: ${({ theme }) => theme.colors.grey_1};
    }
  }
  &:hover {
    box-shadow: 2px 2px 4px 0px rgba(137, 23, 143, 0.3);
  }
`;
