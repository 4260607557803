import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationLink } from '../../../features/entities/layout-entities';
import { Language } from '../../../features/entities/translations_ns';
import { useAppData } from '../../../features/providers/app-data-provider';
import { usePlatform } from '../../../features/providers/platform-provider';
import Div from '../../common/div';
import LanguageSwitcher from './language-switcher';
import NavigationMenuItem from './menu-item';
import Socials from './socials';
import { DesktopNavigationHeaderContainer, Navigation } from './styles';

interface DesktopNavigationProps {
  links: NavigationLink[];
  handleLanguageChange?: (newLanguage: Language) => void;
  defaultOpen?: boolean;
}

const DesktopNavigation = ({
  links,
  handleLanguageChange,
  defaultOpen,
}: DesktopNavigationProps) => {
  const { app } = usePlatform();
  const { navCollapsed } = useAppData();
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([]);
  }, [navCollapsed]);

  useEffect(() => {
    if (!!defaultOpen) {
      setSelectedKeys(
        links.filter((i) => !!i.subLinks?.length).map((itm) => itm.pathname),
      );
    }
  }, [defaultOpen, links]);

  const goToHome = () => {
    navigate('/');
  };
  return (
    <Navigation $app={app} $isOpen={!navCollapsed}>
      <Div flex="column" gap={36} align="center">
        <DesktopNavigationHeaderContainer
          $navCollapsed={navCollapsed}
          onClick={goToHome}
        >
          <HdLogo
            backgroundType={app === 'provider' ? 'dark' : 'light'}
            type={navCollapsed ? 'tertiary' : 'primary'}
            color="b/w"
            width={navCollapsed ? 48 : 178}
            height={48}
          />
        </DesktopNavigationHeaderContainer>

        <Div flex="column" gap={12} align="center">
          {React.Children.toArray(
            links
              .filter((link) => !link.hide)
              .map((link) => (
                <NavigationMenuItem
                  {...link}
                  app={app}
                  selectedKeys={selectedKeys}
                  setSelectedKeys={setSelectedKeys}
                />
              )),
          )}
        </Div>
      </Div>
      {app === 'admin' ? (
        <LanguageSwitcher
          app={app}
          handleLanguageChange={handleLanguageChange}
        />
      ) : (
        <Socials minimize={navCollapsed} app={app} />
      )}
    </Navigation>
  );
};

export default DesktopNavigation;
