import Drawer from 'antd/es/drawer';
import { useMemo } from 'react';
import {
  AssetEntity,
  AssetOwnerInformation,
  AssetRecordInformation,
  AssetRowMeta,
} from '../../../../features/entities/assets-entities';
import { HandleSaveAssetFunction } from '../../../../features/helpers/use-on-file-finish-upload';
import AssetUploadForm from '../../asset-upload/asset-upload-form';

interface AssetEditorDrawerProps {
  open: boolean;
  onClose: () => void;
  meta: AssetRowMeta;
  handleSaveAsset: HandleSaveAssetFunction;
  records: AssetRecordInformation[];
  owners: AssetOwnerInformation[];
}

const AssetEditorDrawer: React.FC<AssetEditorDrawerProps> = ({
  open,
  onClose,
  meta,
  handleSaveAsset,
  records,
  owners,
}) => {
  const asset = useMemo(() => meta?.original_asset as AssetEntity, [meta]);

  return (
    <Drawer
      title="Files"
      size="default"
      placement="right"
      closable={true}
      open={open}
      onClose={onClose}
    >
      <AssetUploadForm
        handleAfterFinish={onClose}
        handleSaveAsset={handleSaveAsset}
        asset={asset}
        records={records}
        owners={owners}
        showVersion
      />
    </Drawer>
  );
};

export default AssetEditorDrawer;
