import { FormInstance } from 'antd/es/form';
import { RcFile } from 'antd/es/upload';
import { useEffect, useMemo, useState } from 'react';
import { UploadAsset } from '../../../features/entities/assets-entities';
import { AppType, Contact } from '../../../features/entities/core-entities';
import {
  ContactFormValues,
  FormAssetList,
  FormSubmissionResponse,
  FormsSubmission,
  FormsSubmissionItem,
  TranslatedFormItemResponse,
  TranslatedFormPageResponse,
  TranslatedFormResponse,
} from '../../../features/entities/form-entities';
import useLocale from '../../../features/providers/locale-provider';
import { useTheme } from '../../../plugins/styled';
import Div from '../../common/div';
import { default as ContactForm } from './contact-form';
import FormCurrentPage from './form-current-page';
import FormCurrentPageEndFooter from './form-current-page/form-current-page-footer';
import FormHeader from './form-header';
import FormLoading from './form-loading';

export interface FormData {
  [key: string]: any;
}
export interface MappedFormItems {
  [key: string]: TranslatedFormItemResponse;
}

export interface ViewerForms {
  contactForm?: FormInstance<ContactFormValues> | undefined;
  dataForm?: FormInstance<FormData> | undefined;
}

export type FormViewerType = 'modal' | 'page' | 'preview';

export type FormSubmissionEligilityState =
  | 'eligible'
  | 'maybe-eligible'
  | 'not-eligible';

interface FormViewerProps {
  formResponse: TranslatedFormResponse;
  handleClose: () => void;
  handleSave: (props: FormSubmissionResponse) => Promise<string>;
  type: FormViewerType;
  contact?: Contact;
  setHasData?: (hasData: boolean) => void;
  source: AppType;
  page_name: string;
  withLoading?: boolean;
}

enum FormStates {
  ContactForm = 'Contact Form',
  InquiryForm = 'Inquiry Form',
  Loading = 'Loading',
}

const FormViewer = ({
  formResponse,
  handleSave,
  handleClose,
  type,
  contact,
  setHasData,
  source,
  page_name,
  withLoading,
}: FormViewerProps) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();

  const [state, setState] = useState<FormStates>(FormStates.InquiryForm);

  const [forms, setForms] = useState<ViewerForms>({
    contactForm: undefined,
    dataForm: undefined,
  });
  const [formAssetList, setFormAssetList] = useState<FormAssetList[]>([]);

  const contactInitialValues: ContactFormValues = useMemo(
    () => ({
      first_name: '',
      last_name: '',
      company_name: '',
      email: contact?.email ?? '',
      phone: contact?.phone ?? '',
      province: 'QC',
      preferred_language: selectedLocale,
    }),
    [contact],
  );

  const [formData, setFormData] = useState<FormData>({});
  const [contactData, setContactData] =
    useState<ContactFormValues>(contactInitialValues);

  useEffect(() => {
    setContactData(contactInitialValues);
  }, [contactInitialValues]);

  const [history, setHistory] = useState<number[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const currentPage = useMemo(
    () => formResponse.pages?.[currentPageIndex],
    [currentPageIndex, formResponse.pages],
  );

  const [mappedItems, setMappedItems] = useState<MappedFormItems>({});

  useEffect(() => {
    const newMappedItems: MappedFormItems = { ...mappedItems };

    currentPage?.groups?.forEach((group) => {
      group.items?.forEach((item) => {
        newMappedItems[item.form_item_id] = item;
      });
    });

    setMappedItems(newMappedItems);
  }, [currentPage]);

  const handleGoToContactForm = () => {
    if (!contact) {
      setState(FormStates.ContactForm);
    } else {
      handleLoadingState();
    }
  };

  const handleLoadingState = () => {
    if (state === FormStates.Loading || !withLoading) {
      setHasFinished(true);
      setState(FormStates.InquiryForm);
    } else {
      setState(FormStates.Loading);
    }
  };

  const handleSaveContact = (values: ContactFormValues) => {
    setContactData((prevData) => ({
      ...prevData,
      ...values,
    }));

    handleLoadingState();
  };
  const onContactChange = (key: string, value: string) => {
    setContactData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleReset = () => {
    forms.dataForm?.resetFields();
    forms.contactForm?.resetFields();
    setContactData(contactInitialValues);
    setFormData({});
    setMappedItems({});
    setHistory([]);
    setCurrentPageIndex(0);
    setProgressValue(0);
    setHasFinished(false);
    setState(FormStates.InquiryForm);
    !!setHasData && setHasData(false);
  };

  const isEndPage =
    !!currentPage?.page_type?.includes('end') &&
    state === FormStates.InquiryForm;
  const isOpening =
    !!currentPage?.page_type &&
    currentPage?.page_type.includes('opening') &&
    state === FormStates.InquiryForm;
  const isContact = state === FormStates.ContactForm;

  useEffect(() => {
    return handleReset;
  }, []);

  useEffect(() => {
    if (hasFinished) {
      handleFinish();
    }
    if (!!setHasData) {
      if (!!Object.keys(formData).length && !isEndPage) {
        setHasData(true);
      } else {
        setHasData(false);
      }
    }
  }, [formData, isEndPage, hasFinished]);

  const handleSubmit = async (data: FormData) => {
    setFormData((prevData) => ({ ...prevData, ...data }));

    let nextPageNumber = currentPageIndex + 1;
    let errorFound = false;
    const keyToCheck: string[] = determinedKeysToCheck(currentPage);

    Object.entries(data)
      ?.filter(([key, _]) => keyToCheck.includes(key))
      .some(([key, value]) => {
        const nextPage = determinedFormItemCondition(key, value);
        if (nextPage !== currentPageIndex + 1) {
          nextPageNumber = nextPage;
        }
        return false;
      });

    if (!errorFound) {
      history.push(nextPageNumber);
      setHistory(history);
      setProgressValue(progress + 100 / formResponse.number_pages);
      if (isEnding(nextPageNumber)) {
        handleGoToContactForm();
        setProgressValue(100 - 100 / formResponse.number_pages);
      }
      setCurrentPageIndex(nextPageNumber);
    }
  };

  const isEnding = (nextPageNumber: number) => {
    return (
      !!formResponse.pages[nextPageNumber].page_type &&
      formResponse.pages[nextPageNumber].page_type.includes('end')
    );
  };

  const [progress, setProgressValue] = useState(0);

  const handleNext = () => {
    if (state === FormStates.ContactForm) {
      forms.contactForm
        ?.validateFields()
        .then(handleSaveContact)
        .catch((e) => console.error(e));
    } else {
      try {
        forms.dataForm
          ?.validateFields()
          .then(handleSubmit)
          .catch((e) => console.error(e));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePrevious = () => {
    if (state === FormStates.ContactForm) {
      setState(FormStates.InquiryForm);
      setProgressValue(
        ((history.length - 1) * 100) / formResponse.number_pages,
      );
    } else {
      setProgressValue(progress - 100 / formResponse.number_pages);
    }
    if (history.length > 1) {
      const previousPageNumber: number = history[history.length - 2];
      setHistory(history.slice(0, -1));
      setCurrentPageIndex(previousPageNumber);
    } else {
      setHistory([]);
      setCurrentPageIndex(0);
    }
  };

  const determinedKeysToCheck = (
    page: TranslatedFormPageResponse,
  ): string[] => {
    let keys: string[] = [];
    page.groups?.forEach((group) => {
      group.items?.forEach((item) => {
        keys.push(item.form_item_id);
      });
    });
    return keys;
  };

  const determinedFormItemCondition = (key: string, value: string): number => {
    const item = mappedItems[key];

    if (item && item.form_item_conditions) {
      for (const condition of item.form_item_conditions) {
        if (
          condition.condition_type === 'equals' ||
          condition.condition_type === 'contains'
        ) {
          if (
            !!condition.trigger_values.find(
              ({ trigger_value }) => trigger_value === value,
            )
          ) {
            if (!!condition.action_page) {
              return condition.action_page;
            }
          }
        }
      }
    }

    return currentPageIndex + 1;
  };

  const determinedEligibilityState = (): FormSubmissionEligilityState => {
    const pageType = currentPage?.page_type;

    if (pageType && pageType.includes('end')) {
      if (pageType === 'elligible-end-icp' || pageType === 'elligible-end') {
        return 'eligible';
      } else if (pageType === 'maybe-elligible-end') {
        return 'maybe-eligible';
      } else {
        return 'not-eligible';
      }
    }

    return 'not-eligible';
  };

  useEffect(() => {
    if (formResponse.pages?.length > 0) {
      setCurrentPageIndex(0);
      setState(FormStates.InquiryForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formResponse]);

  const handleFinish = async () => {
    try {
      const submission: FormsSubmission = {
        form_submission_id: '',
        form_crm_id: formResponse.crm_id,
        form_id: formResponse.form_id,
        source,
        page_name,
        eligibility_state: determinedEligibilityState(),
      };

      const submissionItems: FormsSubmissionItem[] = [];
      Object.entries(formData)?.map(([key, value]) => {
        const item = mappedItems[key];
        value = item?.field_type === 'checkbox' ? value.join(',') : value;
        if (value != undefined) {
          submissionItems.push({
            form_submission_item_id: '',
            form_submission_id: '',
            form_item_id: key,
            value,
            form_item_title: item.content ?? '',
          });
        }
      });
      const assetSubmissions: UploadAsset[] = [];
      if (formAssetList.length > 0) {
        formAssetList.forEach((formAsset: FormAssetList) => {
          formAsset.assets.forEach((file: RcFile) => {
            submissionItems.push({
              form_submission_item_id: '',
              form_submission_id: '',
              form_item_id: formAsset.form_item_id,
              value: formAsset.form_item_label,
              form_item_title: formAsset.form_item_label,
            });
            assetSubmissions.push({
              values: {
                file_name: file.name,
                file_description: formAsset.form_item_label,
                record_id: formAsset.form_item_id,
                asset_id: '',
                version: '',
                record_type: 'form_submission_item',
              },
              asset: file,
            });
          });
        });
      }

      const { email, phone, first_name, last_name, province, company_name } =
        contactData;

      const editedContact: ContactFormValues = {
        email: email ?? contact?.email,
        phone: phone ?? contact?.phone,
        company_name: company_name ?? '',
        first_name: first_name ?? contact?.name,
        last_name: last_name ?? '',
        province: province ?? '',
        preferred_language: selectedLocale,
      };

      await handleSave({
        submission,
        submissionItems,
        contact: editedContact,
        uploadAssets: assetSubmissions,
      });
      setHasFinished(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (!formResponse.pages?.length) return <></>;

  const lastPageIndex = formResponse?.pages?.length - 1;
  const firstPageIndex = useMemo(() => {
    return formResponse.pages.findIndex(
      (page) => !page.page_type?.includes('opening'),
    );
  }, [formResponse.pages]);

  return (
    <Div
      flex="column"
      gap={24}
      align="center"
      style={{
        maxWidth: !['modal'].includes(type) ? theme.caps.normal : undefined,
        width: '100%',
      }}
      tablet={{
        style: {
          maxWidth: 'auto',
        },
      }}
    >
      <FormHeader
        formResponse={formResponse}
        type={type}
        isEndPage={isEndPage}
        isOpening={isOpening}
        progress={progress}
      />

      <Div
        flex="column"
        gap={32}
        backgroundColor={
          ['modal'].includes(type)
            ? theme.colors.beige_3
            : !isOpening && !isEndPage
              ? theme.colors.beige_3
              : undefined
        }
        borderColor={
          ['page', 'preview'].includes(type) && !isOpening && !isEndPage
            ? theme.colors.primary
            : undefined
        }
        borderRadius={4}
        style={{
          padding: ['modal'].includes(type) ? '54px 128px' : 24,
          position: 'relative',
          overflow: 'hidden',
        }}
        tablet={{
          style: {
            padding: isEndPage || isOpening ? '0 24px' : 24,
            paddingTop: isEndPage || isOpening ? 0 : 24,
            overflow: 'visible',
          },
          bordersSide: 'all',

          borderRadius: 0,
          borderColor:
            (isEndPage || isOpening) && !['modal'].includes(type)
              ? 'transparent'
              : theme.colors.grey_1,
        }}
      >
        {state === FormStates.Loading ? (
          <FormLoading onLoadingFinished={handleLoadingState} />
        ) : (
          <>
            <Div flex="column" gap={32}>
              {state === FormStates.ContactForm ? (
                <ContactForm
                  onContactChange={onContactChange}
                  handleNext={handleNext}
                  initialValues={contactData}
                  setForms={setForms}
                />
              ) : (
                <FormCurrentPage
                  formData={formData}
                  formResponse={formResponse}
                  currentPage={currentPage}
                  handleClose={handleClose}
                  handleNext={handleNext}
                  setForms={setForms}
                  isEndPage={isEndPage}
                  isOpening={isOpening}
                  viewerType={type}
                  formAssetList={formAssetList}
                  setFormAssetList={setFormAssetList}
                />
              )}
              <FormCurrentPageEndFooter
                isEndPage={isEndPage}
                isOpening={isOpening}
                isContact={isContact}
                handlePrevious={handlePrevious}
                currentPageIndex={currentPageIndex}
                lastPageIndex={lastPageIndex}
                firstPageIndex={firstPageIndex}
                handleNext={handleNext}
              />
            </Div>
          </>
        )}
      </Div>
    </Div>
  );
};

export default FormViewer;
