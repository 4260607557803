import Filter from '@hellodarwin/icons/dist/icons/Filter';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Button from '../../../../common/button';
import Div from '../../../../common/div';

interface AdvancedFiltersButtonProps {
  handleAdvancedFilters: () => void;
  advancedFiltersOpened: boolean;
}
const AdvancedFiltersButton: React.FC<AdvancedFiltersButtonProps> = ({
  handleAdvancedFilters,
  advancedFiltersOpened,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Button
      defaultStyle={theme.colors.purple_3}
      style={{
        height: 45,
        flex: 1,
        borderRadius: 4,
        gap: 24,
        minWidth: 220,
      }}
      xl={{
        style: {
          alignItems: 'stretch',
          gap: 0,
          padding: '0 12px',

          flexDirection: 'column',
        },
      }}
      onClick={handleAdvancedFilters}
    >
      <Div
        flex="row"
        gap={24}
        xl={{
          gap: 12,
          justify: 'space-between',
          fitContent: false,
        }}
        style={{ padding: '0 12px' }}
        align="center"
      >
        <Div
          flex="row"
          gap={24}
          fitContent
          align="center"
          xl={{ style: { width: '100% !important' }, gap: 12 }}
        >
          <Filter size={20} />
          {t(`grants|fundingExplorerFilter.advancedFilters`)}
        </Div>
        <TabArrow size={16} down={advancedFiltersOpened} />
      </Div>
    </Button>
  );
};
export default AdvancedFiltersButton;
