import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Ecommerce = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77778 3.55554H3.81078C4.55886 3.55554 5.21159 4.06369 5.40521 4.79258L5.967 6.92147M5.967 6.92147C14.1381 6.69019 22.3013 7.60762 30.2222 9.64739C29.0136 13.2829 27.5776 16.8148 25.9333 20.2222H9.47855M5.967 6.92147L9.47855 20.2222M9.47855 20.2222C8.31148 20.2222 7.19221 20.6905 6.36697 21.524C5.54172 22.3575 5.07811 23.4879 5.07811 24.6667H28.1804M7.27833 29.1111C7.27833 29.4058 7.16242 29.6884 6.95611 29.8968C6.7498 30.1051 6.46999 30.2222 6.17822 30.2222C5.88645 30.2222 5.60663 30.1051 5.40032 29.8968C5.19401 29.6884 5.07811 29.4058 5.07811 29.1111C5.07811 28.8164 5.19401 28.5338 5.40032 28.3254C5.60663 28.117 5.88645 28 6.17822 28C6.46999 28 6.7498 28.117 6.95611 28.3254C7.16242 28.5338 7.27833 28.8164 7.27833 29.1111ZM25.9802 29.1111C25.9802 29.4058 25.8643 29.6884 25.658 29.8968C25.4517 30.1051 25.1719 30.2222 24.8801 30.2222C24.5883 30.2222 24.3085 30.1051 24.1022 29.8968C23.8959 29.6884 23.78 29.4058 23.78 29.1111C23.78 28.8164 23.8959 28.5338 24.1022 28.3254C24.3085 28.117 24.5883 28 24.8801 28C25.1719 28 25.4517 28.117 25.658 28.3254C25.8643 28.5338 25.9802 28.8164 25.9802 29.1111Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Ecommerce;
