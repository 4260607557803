import { useEffect, useRef, useState } from 'react';
import { GMAPS_MAP_ID } from '../../../../api';
import {
  InitialLocalisation,
  Localisation,
} from '../../../../features/entities';
import { getLocationFromAddress } from '../../../../features/helpers/geocode';
import useLocale from '../../../../features/providers/locale-provider';
import Div from '../../../common/div';

type ContactMapProps = {
  address: string;
  name: string;
};

const ContactMap = ({ address, name }: ContactMapProps) => {
  const [location, setLocation] = useState<Localisation>(InitialLocalisation);
  const { selectedLocale } = useLocale();

  useEffect(() => {
    (async () => {
      setLocation(InitialLocalisation);
      try {
        getLocationFromAddress(address).then(
          (response: any) => {
            const { lat, lng } = response.results[0].geometry.location;
            setLocation({ lat, lng });
          },
          (error: any) => {
            console.error(error);
          },
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, [address]);

  const Map = ({
    center,
    zoom,
  }: {
    center: google.maps.LatLngLiteral;
    zoom: number;
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      try {
        const map = new window.google.maps.Map(ref.current!, {
          center,
          zoom,
          clickableIcons: false,
          mapId: GMAPS_MAP_ID,

          disableDefaultUI: true,
        });

        const markerElement = document.createElement('div');
        markerElement.style.display = 'inline-block';
        markerElement.style.width = '40px';
        markerElement.style.height = '40px';
        markerElement.style.backgroundImage =
          "url('https://hellodarwin-prod-public-assets.s3.amazonaws.com/map-marker.svg')";
        markerElement.style.backgroundSize = 'contain';
        markerElement.style.backgroundRepeat = 'no-repeat';

        new google.maps.marker.AdvancedMarkerElement({
          position: { lat: center.lat, lng: center.lng },
          map,
          title: name,
          content: markerElement,
        });
      } catch (error) {
        console.error(error);
      }
    });

    return <div ref={ref} id="map" style={{ height: '100%' }} />;
  };

  return (
    <>
      {!!location.lat && !!location.lng && selectedLocale && (
        <>
          <Div
            overflow="hidden"
            style={{
              aspectRatio: `524 / 365`,
              flex: 3,
              flexShrink: 0,
              maxHeight: 365,
              borderRadius: 6,
              overflow: 'hidden',
              position: 'relative',
            }}
            xl={{
              style: {
                aspectRatio: `278 / 287`,
                width: '100%',
              },
            }}
          >
            <Map
              zoom={16}
              center={{
                lat: location.lat,
                lng: location.lng,
              }}
            />
          </Div>
        </>
      )}
    </>
  );
};
export default ContactMap;
