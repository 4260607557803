import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import React from 'react';
import { useTheme } from 'styled-components';
import { GrantResult } from '../../../features/entities/grants-entities';
import dollarFormat from '../../../features/helpers/dollar-format';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import { Tablet } from '../../common/Media';
import Button from '../../common/button';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import GrantCardInfosBox from './grant-card-infos-box';
import { GrantCardBodyContainer, GrantCardBodyContent } from './styles';

const GrantCardBody = ({
  funding_max_amount,
  funding_min_amount,
  grant_financing_type,
  percentage_funding,
  grant_industry_sectors,
}: GrantResult) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  return (
    <GrantCardBodyContainer>
      <GrantCardBodyContent>
        <GrantCardInfosBox
          Icon={Budget}
          label={t('grant_card|eligibleFinancing')}
        >
          <ul>
            {!!funding_min_amount && !!funding_max_amount ? (
              <li>
                {t('grant_card|fromMinToMax', {
                  min: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                  max: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                })}
              </li>
            ) : !!funding_min_amount ? (
              <li>
                {t('grant_card|minCount', {
                  count: `${dollarFormat(funding_min_amount, selectedLocale)}`,
                })}
              </li>
            ) : (
              !!funding_max_amount && (
                <li>
                  {t('grant_card|maxCount', {
                    count: `${dollarFormat(funding_max_amount, selectedLocale)}`,
                  })}
                </li>
              )
            )}
            {!!percentage_funding && (
              <li>
                {t('grant_card|projectCostPercent', {
                  percent: `${percentage_funding}`,
                })}
              </li>
            )}
            {!funding_min_amount &&
              !funding_max_amount &&
              !percentage_funding && <li>{t('grant_card|noCondition')}</li>}
          </ul>
        </GrantCardInfosBox>

        <GrantCardInfosBox
          Icon={Industry}
          label={t('grant_card|eligibleIndustries')}
        >
          <ul style={{ width: '100%' }}>
            {!!grant_industry_sectors?.length ? (
              <>
                {React.Children.toArray(
                  grant_industry_sectors
                    .slice(0, 4)
                    .map((tag) => <li key={tag}>{tag}</li>),
                )}
              </>
            ) : (
              <li style={{ width: '100%' }}>{t('grant_card|allIndustries')}</li>
            )}
          </ul>
        </GrantCardInfosBox>
        <GrantCardInfosBox
          Icon={Invoices}
          label={t('grant_card|financingType')}
        >
          <Div flex="row" gap={8} wrap="wrap">
            {React.Children.toArray(
              grant_financing_type?.map((type) => (
                <HdTag text={type} color={theme.colors.purple_3} />
              )),
            )}
          </Div>
        </GrantCardInfosBox>
        <Tablet direction="down">
          <div style={{ padding: 24 }}>
            <Button
              withArrowRight
              size="small"
              defaultStyle={theme.colors.purple_2}
            >
              {t('grant_card|learnMore')}
            </Button>
          </div>
        </Tablet>
      </GrantCardBodyContent>
    </GrantCardBodyContainer>
  );
};
export default GrantCardBody;
