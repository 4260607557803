import Modal from 'antd/es/modal';
interface NotifyAllModalProps {
  modalVisible: boolean;
  closeModal: () => void;
  notifyAll: () => void;
}

const NotifyAllModal = ({
  modalVisible,
  closeModal,
  notifyAll,
}: NotifyAllModalProps) => {
  return (
    <Modal
      title={'Project Description Updated!'}
      open={modalVisible}
      onOk={notifyAll}
      onCancel={closeModal}
      okText={'Notify all SPs'}
      okType={'primary'}
      cancelText={"Don't notify all SPs"}
    >
      <p>
        Do you want to notify all service providers about the updated project
        description?
      </p>
    </Modal>
  );
};
export default NotifyAllModal;
