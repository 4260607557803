import WarningOutlined from '@ant-design/icons/WarningOutlined';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Popover from 'antd/es/popover';
import Paragraph from 'antd/es/typography/Paragraph';
import { useState } from 'react';
import theme from '../../theme';
type ExternalLinkProps = {
  id?: string;
  title?: string;
  url?: string;
  onEnd?: (newValue: string) => void;
};

const ExternalLink = ({ id, title, url, onEnd }: ExternalLinkProps) => {
  const [newId, setNewId] = useState(id);

  const handleOnEnd = () => {
    if (id !== newId && !!onEnd) onEnd(newId || '');
  };

  return (
    <Popover
      title={title}
      content={
        <Paragraph
          editable={{
            tooltip: 'click to edit id',
            onChange: setNewId,
            text: newId,
          }}
          onBlur={handleOnEnd}
          className="body3"
        >
          {id}
        </Paragraph>
      }
    >
      {id ? (
        <a href={url} rel="noopener noreferrer" target="_blank">
          <NewWindow style={{ color: theme.colors.purple_1 }} width={16} />
        </a>
      ) : (
        <WarningOutlined style={{ fontSize: 24, color: '#fc3503' }} />
      )}
    </Popover>
  );
};

export default ExternalLink;
