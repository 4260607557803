import Div from '@hellodarwin/core/lib/components/common/div';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Verified from '@hellodarwin/icons/dist/icons/Verified';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app';
import { getAuth0UserID } from '../../features/api/slices/admins-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { copyIntoClipboard } from '../clipboard';
import Auth0UserButtons from './auth0-user-buttons';

interface AdminAuth0UserButtonsProps {
  admin: AdminProfile;
  destination: string;
  compactView?: boolean;
  handleVerify: (adminId: string) => void;
}

const AdminAuth0UserButtons = ({
  admin,
  destination,
  compactView,
  handleVerify,
}: AdminAuth0UserButtonsProps) => {
  const api = useAdminApi();
  const theme = useTheme();
  const [resetPasswordLink, setResetPasswordLink] = useState<string>('');
  const [linkIsValid, setLinkIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  //Create user Auth0 account and send activation email through SendGrid
  const auth0CreateAccount = () => {
    (async () => {
      try {
        setIsLoading(true);
        const newUserInfo = await api.createAuth0Admin(
          admin?.admin_id,
          destination,
        );
        setResetPasswordLink(newUserInfo.reset_link);
        message.success('Auth0 user created! Activation email sent');
      } catch (err: any) {
        if (err.response.data.error_code === 5024)
          message.error('Failed to find contact by ID');
        else message.error('Failed to create Auth0 account');
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const auth0resetPasswordEmail = () => {
    (async () => {
      try {
        setIsLoading(true);
        await api.resetAdminAuth0PasswordEmail(admin?.admin_id, destination);
        setResetPasswordLink('');
        message.success('Reset password email sent!');
      } catch (err: any) {
        if (err.response.data.error_code === 5024)
          message.error('Failed to find contact by ID');
        else message.error('Failed to send reset password email');
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const auth0resetPasswordLink = () => {
    (async () => {
      try {
        setLinkIsValid(true);
        setIsLoading(true);
        setResetPasswordLink(
          await api.resetAuth0PasswordLink(admin?.admin_id, destination),
        );
        message.success('Reset password link generated successfully!');
      } catch (err: any) {
        if (err.response.data.error_code === 5024)
          message.error('Failed to find contact by ID');
        else message.error('Failed to generate reset password link');
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const handleRefresh = () => {
    dispatch(
      getAuth0UserID({ api, adminEmail: admin.email, adminId: admin.admin_id }),
    );
  };

  useEffect(() => {
    if (resetPasswordLink && linkIsValid) {
      copyIntoClipboard(resetPasswordLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordLink]);

  const isVerified = admin.is_verified_admin;
  return (
    <Div flex="row" gap={8}>
      <Tooltip
        title={isVerified ? 'Admin is verified' : "Admin isn't verified"}
        color={isVerified ? theme.colors.green_1 : theme.colors.red_1}
      >
        <Button
          style={{
            borderColor: isVerified ? theme.colors.green_1 : theme.colors.red_1,
            flexShrink: 0,
          }}
          onClick={() =>
            !isVerified ? handleVerify(admin.admin_id) : undefined
          }
          size="middle"
          danger={!isVerified}
          icon={
            <Verified
              color={isVerified ? theme.colors.green_1 : theme.colors.red_1}
              width={20}
              height={20}
            />
          }
        />
      </Tooltip>
      <Auth0UserButtons
        auth0UserID={admin.auth_id}
        auth0GoogleUserID={admin.google_auth_id}
        auth0UserIDMatch={admin.auth_id_match}
        auth0CreateAccount={auth0CreateAccount}
        auth0resetPasswordEmail={auth0resetPasswordEmail}
        auth0resetPasswordLink={auth0resetPasswordLink}
        compactView={compactView}
        isLoading={isLoading}
        saveContact={handleRefresh}
      />
    </Div>
  );
};

export default AdminAuth0UserButtons;
