const arraysEqual = <T>(arr1: T[], arr2: T[]) => {
  if (arr1.length !== arr2.length) return false;
  return (
    arr1.every((item) => arr2.includes(item)) &&
    arr2.every((item) => arr1.includes(item))
  );
};

export const objectsEqual = <T extends Object>(obj1: T, obj2: T) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key as keyof T];
    const val2 = obj2[key as keyof T];
    const areObjects = isObject(val1 as Object) && isObject(val2 as Object);
    if (
      (areObjects && !objectsEqual(val1 as Object, val2 as Object)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const isObject = <T extends Object>(object: T) => {
  return object != null && typeof object === 'object';
};

export const arraysOfObjectsEqual = <T extends Object>(
  arr1: T[],
  arr2: T[],
) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (!objectsEqual(arr1[i], arr2[i])) return false;
  }
  return true;
};

export default arraysEqual;
