enum GrantEligibilitySections {
  EligibilityCriteria = 'eligibility_criteria',
  EligibleExpenses = 'eligible_expenses',
  WhoCanApply = 'who_can_apply',
  WhoCannotApply = 'who_cannot_apply',
  ProjectActivity = 'project_activity',
  Zone = 'zone',
}

export default GrantEligibilitySections;
