import Copy from '@hellodarwin/icons/dist/icons/Copy';
import copyToClipboard from '../../../features/helpers/copy-to-clipboard';
import theme from '../../../theme';
import Button from '../button';
import { CopyButtonProps } from './entities';

const CopyButton = ({ copyable }: CopyButtonProps) => {
  return (
    <Button
      isLink
      onClick={() => copyToClipboard(copyable)}
      defaultStyle={theme.colors.grey_3}
      style={{ minHeight: 'auto' }}
    >
      {copyable.icon ?? <Copy size={16} />}
    </Button>
  );
};

export default CopyButton;
