import {
  ApiError,
  ApiErrorInitialState,
  MilestoneAttachment,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const milestoneAttachmentsAdapter = createEntityAdapter<
  MilestoneAttachment,
  string
>({
  selectId: (model: MilestoneAttachment) => model.milestone_attachment_id,
});

export interface MilestoneAttachementState {
  status: 'idle' | 'pending';
  error: ApiError;
  milestonesAttachments: EntityState<MilestoneAttachment, string>;
}

const initialState: MilestoneAttachementState = {
  status: 'idle',
  error: ApiErrorInitialState,
  milestonesAttachments: milestoneAttachmentsAdapter.getInitialState(),
};

export const createMilestoneAttachment = createAsyncThunk<
  MilestoneAttachment,
  { api: AdminApiClient; milestoneId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/createMilestoneAttachment',
  async (
    {
      api,
      milestoneId,
      data,
    }: { api: AdminApiClient; milestoneId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.createMilestoneAttachment(milestoneId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { milestonesAttachments } = getState();
      if (milestonesAttachments.status === 'pending') return false;
    },
  },
);

export const fetchMilestonesAttachments = createAsyncThunk<
  MilestoneAttachment[],
  { api: AdminApiClient; programId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/fetchMilestonesAttachments',
  async (
    { api, programId }: { api: AdminApiClient; programId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchMilestonesAttachments(programId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { milestonesAttachments } = getState();
      if (milestonesAttachments.status === 'pending') return false;
    },
  },
);

const milestonesAttachmentsSlice = createSlice({
  name: 'milestonesAttachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      createMilestoneAttachment.fulfilled,
      (state, { payload }) => {
        milestoneAttachmentsAdapter.addOne(
          state.milestonesAttachments,
          payload,
        );
        state.status = 'idle';
      },
    );
    builder.addCase(
      createMilestoneAttachment.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(createMilestoneAttachment.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchMilestonesAttachments.fulfilled,
      (state, { payload }) => {
        if (payload !== null) {
          milestoneAttachmentsAdapter.addMany(
            state.milestonesAttachments,
            payload,
          );
        }
        state.status = 'idle';
      },
    );
    builder.addCase(
      fetchMilestonesAttachments.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(fetchMilestonesAttachments.pending, (state) => {
      state.status = 'pending';
    });
  },
});

export const selectMilestonesAttachmentsByMilestoneId = createSelector(
  [
    (state: RootState, _) => selectAllMilestonesAttachments(state),
    (_, milestoneId: string) => milestoneId,
  ],
  (milestoneAttachments, milestoneId) =>
    milestoneAttachments.filter(
      (milestoneAttachment) =>
        milestoneAttachment.milestone_attachment_milestone_id === milestoneId,
    ),
);

// Your existing selectors remain unchanged
export const {
  selectAll: selectAllMilestonesAttachments,
  selectById: selectMilestoneAttachment,
} = milestoneAttachmentsAdapter.getSelectors(
  (state: RootState) => state.milestonesAttachments.milestonesAttachments,
);

export const selectMilestonesAttachmentLoading = (state: RootState) =>
  state.milestonesAttachments.status === 'pending';

export const milestonesAttachmentsReducer = milestonesAttachmentsSlice.reducer;
