import {
  EditSubmissionPageResponse,
  FormItemResponse,
  FormPageResponse,
  FormsSubmissionItem,
} from '../../../../../features/entities/form-entities';
import { FormData } from '../../types';
export const getFormPageKeysToCheck = (page: FormPageResponse): string[] => {
  return (
    page.groups?.flatMap(
      (group) => group.items?.map((item) => item.form_item_id) || [],
    ) || []
  );
};

export const getFormPageSubmissionValues = (
  page: EditSubmissionPageResponse,
  formData: FormData,
): FormsSubmissionItem[] => {
  return (
    page.groups?.flatMap((group) =>
      group.items
        .filter((item) => !!formData[item.form_item.form_item_id])
        .map((item) => item.forms_submissions_item),
    ) || []
  );
};

export const getFormItemConditions = (
  value: string,
  nextPageIndex: string,
  item: FormItemResponse,
): string => {
  if (!item?.form_item_conditions) return nextPageIndex;

  const matchedCondition = item.form_item_conditions.find((condition) => {
    return (
      (condition.condition_type === 'equals' ||
        condition.condition_type === 'contains') &&
      condition.trigger_values.some(
        (trigger) => trigger.trigger_value === value,
      )
    );
  });

  return matchedCondition?.form_page_id || nextPageIndex;
};
