import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  annualRevenue,
  companySize,
} from '@hellodarwin/core/lib/components/forms/utils/company-infos';
import FundingExplorer from '@hellodarwin/core/lib/components/grants/funding-explorer';
import { FundingExplorerFilterValues } from '@hellodarwin/core/lib/components/grants/funding-explorer/filter/types';
import FundingExplorerCompanyInfo from '@hellodarwin/core/lib/components/grants/funding-explorer/funding-explorer-company-info';
import {
  Company,
  NewTag,
  TagType,
} from '@hellodarwin/core/lib/features/entities';
import AdminQueryFundingExplorerProps from '@hellodarwin/core/lib/features/entities/admin-entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { usePagination } from '@hellodarwin/core/lib/features/providers/pagination-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { ThemeProvider } from '@hellodarwin/core/lib/plugins/styled';
import theme from '@hellodarwin/core/lib/theme';
import getAntdTheme from '@hellodarwin/core/lib/theme/antd-theme';
import Collapse from 'antd/es/collapse/Collapse';
import ConfigProvider from 'antd/es/config-provider';
import Form from 'antd/es/form';
import { useWatch } from 'antd/es/form/Form';
import Select from 'antd/es/select';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchGrantProjects,
  selectBestGrantProjects,
  selectGrantProjectsLoading,
} from '../../features/api/slices/grant-projects-slice';
import {
  fetchGrantFinancingType,
  fetchGrantService,
  fetchProvinces,
  queryFundingExplorer,
  selectAllFundingExplorerGrants,
  selectGrantFinancingType,
  selectGrantService,
  selectGrantsIsLoading,
  selectProvinces,
} from '../../features/api/slices/grants-slice';
import {
  fetchChildTags,
  selectExpenseCategoryTagsOptions,
  selectGoalTagsOptions,
  selectTagsLoading,
} from '../../features/api/slices/new-tags-slice';
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
  selectTagsIsLoading,
} from '../../features/api/slices/tags-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';

const AdminFundingExplorer = () => {
  const dispatch = useAppDispatch();
  const newApi = useNewAdminApi();
  const api = useAdminApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants],
  );
  const isGrantsLoading = useAppSelector(selectGrantsIsLoading);
  const isGrantsProjectsLoading = useAppSelector(selectGrantProjectsLoading);
  const isGrantsTagsLoading = useAppSelector(selectTagsLoading);
  const isTagsLoading = useAppSelector(selectTagsIsLoading);

  const isLoading = isGrantsLoading || isTagsLoading || isGrantsTagsLoading;

  const provinces = useAppSelector(selectProvinces);
  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const filterValues = useWatch([], filterform);
  const [company, setCompany] = useState<Company>();
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectors(state, filterValues?.industry || []),
  );
  const services = useAppSelector(selectGrantService);
  const bestProjects = useAppSelector(selectBestGrantProjects);
  const goalTagsOptions = useAppSelector(selectGoalTagsOptions);
  const expenseCategoryTagsOptions = useAppSelector(
    selectExpenseCategoryTagsOptions,
  );
  const expenseTagsOptions = useAppSelector(selectExpenseCategoryTagsOptions);
  const [selectedGoals, setSelectedGoals] = useState<NewTag[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<NewTag[]>([]);
  const [selectedExpenses, setSelectedExpenses] = useState<NewTag[]>([]);

  const { activePage, pageSize } = usePagination();

  const grantIds = useMemo(
    () => grants?.map((grant) => grant.grant_id),
    [grants],
  );

  useEffect(() => {
    if (grantIds?.length === 0 || !selectedLocale) return;
    dispatch(
      fetchGrantProjects({ api: newApi, locale: selectedLocale, grantIds }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantIds, selectedLocale]);

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantService({ api, locale: selectedLocale }));

    dispatch(
      fetchChildTags({
        api: newApi,
        parentIds: [],
        locale: selectedLocale,
        type: TagType.Goal,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  useEffect(() => {
    if (selectedGoals?.length > 0) {
      const parentIds = selectedGoals.map((goal) => goal.tag_id);
      dispatch(
        fetchChildTags({
          api: newApi,
          parentIds,
          locale: selectedLocale,
          type: TagType.ExpenseCategory,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoals, selectedLocale]);

  useEffect(() => {
    if (selectedCategories?.length > 0) {
      const parentIds = selectedCategories.map((type) => type.tag_id);
      dispatch(
        fetchChildTags({
          api: newApi,
          parentIds,
          locale: selectedLocale,
          type: TagType.Expense,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, selectedLocale]);

  const fetch = async (values: FundingExplorerFilterValues) => {
    try {
      const queryProps: AdminQueryFundingExplorerProps = {
        locale: selectedLocale,
        page: activePage,
        limit: pageSize,
        companyId: undefined,
        tagGoals: selectedGoals.map((tag) => tag?.tag_id),
        tagExpenseCategories: selectedCategories.map(
          (category) => category?.tag_id,
        ),
        tagExpenses: selectedExpenses.map((tag) => tag?.tag_id),
        ...values,
      };

      dispatch(queryFundingExplorer({ api, ...queryProps }));
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    options: { [key: string]: NewTag },
  ) => {
    const newTags = values.map((value) => options[value]);
    setSelected(newTags);
  };

  const removeCompany = () => {
    setCompany(undefined);
  };

  const TagsCollapse = () => {
    return (
      <Collapse ghost style={{ width: '100%' }}>
        <Collapse.Panel header={'Tag Filters'} key="1">
          <Div flex="row" gap={8}>
            {React.Children.toArray(
              [TagType.Goal, TagType.ExpenseCategory, TagType.Expense].map(
                (type, idx) => (
                  <Div key={idx}>
                    <Typography elementTheme="body3">
                      {type
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </Typography>
                    <Select
                      mode="multiple"
                      placeholder={`Select ${type
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (c) => c.toUpperCase())}(s)`}
                      style={{ width: '100%' }}
                      value={
                        type === TagType.Goal
                          ? selectedGoals.map((tag) => tag.tag_id)
                          : type === TagType.ExpenseCategory
                            ? selectedCategories.map((tag) => tag.tag_id)
                            : selectedExpenses.map((tag) => tag.tag_id)
                      }
                      onChange={(values) =>
                        handleTagChange(
                          values,
                          type === TagType.Goal
                            ? setSelectedGoals
                            : type === TagType.ExpenseCategory
                              ? setSelectedCategories
                              : setSelectedExpenses,
                          type === TagType.Goal
                            ? goalTagsOptions.entities
                            : type === TagType.ExpenseCategory
                              ? expenseCategoryTagsOptions.entities
                              : expenseTagsOptions.entities,
                        )
                      }
                      options={Object.values(
                        type === TagType.Goal
                          ? goalTagsOptions.entities
                          : type === TagType.ExpenseCategory
                            ? expenseCategoryTagsOptions.entities
                            : expenseTagsOptions.entities,
                      )?.map((option) => ({
                        label: option.content,
                        value: option.tag_id,
                      }))}
                    />
                  </Div>
                ),
              ),
            )}
          </Div>
        </Collapse.Panel>
      </Collapse>
    );
  };

  const antdTheme = getAntdTheme(theme);

  return (
    <PageLayout
      app="admin"
      title={t(`grants|fundingExplorer`)}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Funding Explorer',
        },
      ]}
      actions={
        <Div flex="row" fitContent>
          <Typography style={{ paddingRight: '8px' }} nowrap>
            {t(`grants|results`)}
          </Typography>
          <Typography bold nowrap>
            {t(`grants|nbGrants`, {
              nb: hasGrants ? grants?.[0]?.total_count : 0,
            })}
          </Typography>
        </Div>
      }
    >
      <ThemeProvider theme={theme}>
        <ConfigProvider theme={antdTheme}>
          <FundingExplorer
            filterValues={filterValues}
            afterFilters={
              !!company && (
                <FundingExplorerCompanyInfo
                  company={company}
                  removeCompany={removeCompany}
                  provinces={provinces}
                  companySize={companySize}
                  annualRevenue={annualRevenue}
                  industries={industries}
                  subIndustries={subindustries}
                />
              )
            }
            company={company}
            filterform={filterform}
            financingType={financingType}
            industries={industries}
            subIndustries={subindustries}
            provinces={provinces}
            services={services}
            companySize={companySize}
            annualRevenue={annualRevenue}
            TagsCollapse={TagsCollapse}
            featuredProjects={bestProjects}
            bestProjects={bestProjects}
            grantsProjectsLoading={isGrantsProjectsLoading}
            goToSettings={() => {}}
            grants={grants}
            isLoading={isLoading}
            fetch={fetch}
          />
        </ConfigProvider>
      </ThemeProvider>
    </PageLayout>
  );
};

export default AdminFundingExplorer;
