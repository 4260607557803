import { useMemo } from 'react';
import { IconProps } from '../features/entities/general';

const formatSize = (size: string | number) => {
  if (typeof size === 'number') return size;

  return parseInt(size.replace('px', ''));
};
const useIconProps = (iconProps: IconProps) => {
  return useMemo(() => {
    const {
      width,
      height,
      size = 32,
      style,
      className,
      onClick,
      color,
    } = iconProps;

    return {
      width: width ? formatSize(width) : size,
      height: height ? formatSize(height) : size,
      style: { ...style, color },
      className,
      onClick,
    };
  }, [iconProps]);
};

export default useIconProps;
