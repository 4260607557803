import { GinApplication } from '@hellodarwin/core/lib/features/entities';
import Form, { FormInstance } from 'antd/es/form';
import RenderInputNumber from './application-form-input-number';

const IdentifiedOpportunitiesForm = ({
  form,
  application,
}: {
  form: FormInstance<GinApplication>;
  application: GinApplication;
}) => {
  return (
    <Form form={form} layout="vertical" initialValues={application}>
      <RenderInputNumber
        label="targeted_amount"
        name="application_targeted_amount"
      />
    </Form>
  );
};

export default IdentifiedOpportunitiesForm;
