import Container from '@hellodarwin/core/lib/components/common/container';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { ChatType } from '@hellodarwin/core/lib/features/entities';
import Select from 'antd/es/select';
import { TabsProps } from 'antd/es/tabs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app';
import ChatsTab from '../components/hd-chat/chats-tab';
import PromptsTab from '../components/hd-chat/prompts-tab';
import TemplatesTab from '../components/hd-chat/templates-tab';
import {
  selectActiveTab,
  setActiveTab,
} from '../features/api/slices/grants-slice';
import {
  selectSelectedChatType,
  setSelectedChatType,
} from '../features/api/slices/hd-chats-slice';

type ChatsPageTab = 'chats' | 'templates' | 'prompts';

const HdChatsPage = ({ defaultKey }: { defaultKey: ChatsPageTab }) => {
  const activeTab = useAppSelector(selectActiveTab);
  const dispatch = useAppDispatch();
  const selectedChatType = useAppSelector(selectSelectedChatType);

  const tabs: TabsProps['items'] = [
    {
      key: 'chats',
      label: 'Chats',
      children: <ChatsTab />,
    },
    {
      key: 'templates',
      label: 'Templates',
      children: <TemplatesTab />,
    },
    {
      key: 'prompts',
      label: 'Prompts',
      children: <PromptsTab />,
    },
  ];

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab('chats'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!!defaultKey) {
      dispatch(setActiveTab(defaultKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultKey]);

  const navigate = useNavigate();
  const handleTabChange = (key: string) => {
    switch (key) {
      case 'chats':
        navigate('/chats');
        break;
      case 'templates':
        navigate('/chats/templates');
        break;
      case 'prompts':
        navigate('/chats/prompts');
        break;
      default:
        navigate('/chats');
        break;
    }
    dispatch(setActiveTab(key));
  };

  return (
    <PageLayout
      app="admin"
      title={'Chats'}
      actions={
        <Select
          defaultValue={selectedChatType}
          style={{ width: 175 }}
          onChange={(value: ChatType) => dispatch(setSelectedChatType(value))}
        >
          {Object.values(ChatType).map((type) => (
            <Select.Option key={type} value={type}>
              {type.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
            </Select.Option>
          ))}
        </Select>
      }
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Chats',
        },
      ]}
    >
      <Container
        defaultActiveTab="1"
        handleTabClick={handleTabChange}
        activeTab={activeTab}
        tabs={tabs}
        fullWidth
      />
    </PageLayout>
  );
};

export default HdChatsPage;
