import Modal, { ModalProps } from '..';

interface ModalLayoutSimpleProps extends ModalProps {}

const ModalLayoutSimple = ({
  children,
  ...modalProps
}: ModalLayoutSimpleProps) => {
  return <Modal {...modalProps}>{children}</Modal>;
};

export default ModalLayoutSimple;
