import styled from '@hellodarwin/core/lib/plugins/styled';

export const CardLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.beige_3};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  position: relative;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.purple_2};
    cursor: pointer;
    z-index: 1;
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};

    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);

      border: 2px solid ${({ theme }) => theme.colors.purple_2};
      top: -1px;
      left: -1px;
    }
  }
`;

export const RelatedLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  position: relative;
  margin-top: 1px;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.purple_2};
    cursor: pointer;
    z-index: 1;
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);

      border: 2px solid ${({ theme }) => theme.colors.purple_2};
      top: -1px;
      left: -1px;
    }
  }
`;
