import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Ads = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77777 6.96294C1.77777 6.06339 2.51295 5.33331 3.4188 5.33331H12.1709C13.0768 5.33331 13.812 6.06339 13.812 6.96294V12.395C13.812 13.2946 13.0768 14.0247 12.1709 14.0247H3.4188C2.98357 14.0247 2.56617 13.853 2.25842 13.5474C1.95066 13.2417 1.77777 12.8272 1.77777 12.395V6.96294ZM19.282 9.13578C19.282 8.23623 20.0172 7.50615 20.9231 7.50615H28.5812C29.487 7.50615 30.2222 8.23623 30.2222 9.13578V21.0864C30.2222 21.986 29.487 22.716 28.5812 22.716H20.9231C20.4878 22.716 20.0704 22.5443 19.7627 22.2387C19.4549 21.9331 19.282 21.5186 19.282 21.0864V9.13578ZM3.96581 20C3.96581 19.1004 4.70098 18.3703 5.60683 18.3703H13.265C14.1708 18.3703 14.906 19.1004 14.906 20V23.2592C14.906 24.1588 14.1708 24.8889 13.265 24.8889H5.60683C5.1716 24.8889 4.7542 24.7172 4.44645 24.4116C4.1387 24.1059 3.96581 23.6914 3.96581 23.2592V20Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Ads;
