import { AdminGinContactResponse } from '@hellodarwin/core/lib/features/entities';
import Email from '@hellodarwin/icons/dist/icons/Email';
import Enterprise from '@hellodarwin/icons/dist/icons/Enterprise';
import Language from '@hellodarwin/icons/dist/icons/Language';
import Phone from '@hellodarwin/icons/dist/icons/Phone';
import CardLayout from '../../../components/gins/sidebars/cards/card-layout';

interface ContactCardProps {
  contact: AdminGinContactResponse;
  grantId: string;
}

export const ContactCard = ({ contact, grantId }: ContactCardProps) => {
  if (!contact) return <></>;
  return (
    <CardLayout
      to={`/grants/providers/${contact.grant_provider_id}/contacts?grantId=${grantId}`}
      title={`${contact.first_name} ${contact.last_name}`}
      subtitle={contact.title}
      infos={[
        {
          Icon: Phone,
          value: contact.phone_number ?? 'N/A',
        },
        {
          Icon: Email,
          value: contact.email ?? 'N/A',
        },
        {
          Icon: Enterprise,
          value: contact.provider_title ?? 'N/A',
        },
        {
          Icon: Language,
          value: contact.language === 'fr' ? 'Français' : 'English',
        },
      ]}
    />
  );
};
