import Rate, { RateProps } from 'antd/es/rate';
import { useEffect, useMemo, useState } from 'react';
import Typography from '../../common/typography';
import { ElementTheme } from '../../common/typography/entities';
import { ReviewRatingsContainer } from './styles';
import useRatingIcon from './use-rating-icon';

export type ReviewRatingsSize =
  | 'extra-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large';

export type ReviewRatingsTextPlacement = 'start' | 'end';

export interface ReviewRatingsProps {
  defaultValue: number;
  size?: ReviewRatingsSize;
  disabled?: boolean;
  showValue?: boolean;
  showTotal?: boolean;
  showOne?: boolean;
  biggerText?: boolean;
  textPlacement?: ReviewRatingsTextPlacement;
  onChange?: RateProps['onChange'];
  allowHalf?: RateProps['allowHalf'];
  value?: number;
}

const ReviewRatings: React.FC<ReviewRatingsProps> = (props) => {
  const {
    defaultValue,
    disabled,
    showValue,
    showOne,
    showTotal,
    size = 'medium',
    textPlacement,
    biggerText,
    onChange,
    value,
    allowHalf,
  } = props;
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const getRatingIcon = useRatingIcon({ ...props, value: currentValue });

  const typographySize = useMemo(() => {
    const elementTheme: ElementTheme = biggerText
      ? ['extra-large', 'large'].includes(size)
        ? 'h4'
        : ['extra-small', 'small'].includes(size)
          ? 'subtitle1'
          : 'h5'
      : ['extra-large', 'large'].includes(size)
        ? 'subtitle2'
        : ['small'].includes(size)
          ? 'body2'
          : ['extra-small'].includes(size)
            ? 'overline'
            : 'body1';
    return elementTheme;
  }, [props]);

  useEffect(() => {
    if (!!value) {
      setCurrentValue(value);
    } else {
      setCurrentValue(defaultValue);
    }
  }, [value, defaultValue]);

  const handleChange: RateProps['onChange'] = (value) => {
    setCurrentValue(value);
    onChange(value);
  };

  const rateProps: RateProps = useMemo(
    () => ({
      allowHalf,
      character: getRatingIcon,
      defaultValue,
      disabled,
      onChange: handleChange,
    }),
    [props, currentValue],
  );
  const icon = useMemo(
    () => (showOne ? getRatingIcon() : <Rate {...rateProps} />),
    [props],
  );

  return showValue ? (
    <ReviewRatingsContainer $textPlacement={textPlacement}>
      <Typography
        elementTheme={typographySize}
        style={{ lineHeight: 1, transform: 'translateY(2px)' }}
      >
        {defaultValue} {!!showTotal && `/ 5`}
      </Typography>
      {icon}
    </ReviewRatingsContainer>
  ) : (
    icon
  );
};

export default ReviewRatings;
