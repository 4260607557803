import { Project } from '../../../features/entities/projects-entities';

const formatRange = ({
  project,
  locale,
  separator,
  formatValue,
  keys,
}: {
  project: Project;
  locale: string;
  separator: string;
  formatValue: (value: any, locale: string) => string;
  keys: (keyof Project)[];
}) => {
  return keys
    .map((key) => (!!project[key] ? formatValue(project[key], locale) : ''))
    .filter((i) => !!i.length)
    .join(separator);
};

export default formatRange;
