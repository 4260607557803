import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Upload, { UploadFile } from 'antd/es/upload';
import { FC, useEffect } from 'react';
import { TeamMember } from '../../../../../features/entities/core-entities';
import { TeamMemberFormValues } from '../../../../../features/entities/form-entities';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Grid from '../../../../common/hd-grid';
import Typography from '../../../../common/typography';
import FormItem from '../../../form-layouts/form-item';
import validateFile, { getFileTypes } from '../../../utils/validate-file';

interface TeamMemberFormProps {
  form: FormInstance<TeamMemberFormValues>;
  teamMember: TeamMember;
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
  handleSubmit: () => void;
}

const TeamMemberForm: FC<TeamMemberFormProps> = ({
  form,
  teamMember,
  fileList,
  setFileList,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      name: teamMember.name,
      role: teamMember.role,
      linked_in: teamMember.linked_in,
    });

    if (teamMember.profile_picture) {
      setFileList([
        {
          uid: '-1',
          url: !!teamMember.profile_picture
            ? `${teamMember.profile_picture}?${new Date().toString()}`
            : undefined,
          name: `${teamMember.name} picture`,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [teamMember]);

  const handleFileChange = (fileList: UploadFile[]) => {
    if (fileList.length > 0) {
      if (!validateFile(fileList[0].originFileObj!)) return;
    }
    setFileList(fileList);
  };

  const uploadButton = (
    <div>
      <AddNew size={24} />
      <div style={{ marginTop: 8 }}>{t('upload.upload')}</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={teamMember}
    >
      <FormItem
        name="upload"
        rules={[
          () => ({
            validator(_, value) {
              if (fileList.length < 1) {
                return Promise.reject(
                  new Error(t('team|form.validation.upload')),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Div
          flex="row"
          gap={24}
          tablet={{
            flex: 'column',
            align: 'center',
            gap: 12,
            style: { marginBottom: 18 },
          }}
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            beforeUpload={() => false}
            onChange={(fileList) => handleFileChange(fileList.fileList)}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>

          <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
            <Typography elementTheme="caption" color={theme.colors.grey_3}>
              {t('upload.resolution', { width: '200', height: '200' })}
            </Typography>
            <Typography elementTheme="caption" color={theme.colors.grey_3}>
              {t('upload.fileSize')}
            </Typography>
            <Typography elementTheme="caption" color={theme.colors.grey_3}>
              {t('upload.allowedFile', { types: getFileTypes().join(', ') })}
            </Typography>
          </div>
        </Div>
      </FormItem>

      <Grid xl={2} md={1} gutter={8}>
        <FormItem
          name="name"
          label={t('team|form.label.name')}
          rules={[{ required: true, message: t('team|form.validation.name') }]}
        >
          <Input size="large" placeholder={t('team|form.placeholder.name')} />
        </FormItem>
        <FormItem
          name="role"
          label={t('team|form.label.role')}
          rules={[{ required: true, message: t('team|form.validation.role') }]}
        >
          <Input size="large" placeholder={t('team|form.placeholder.role')} />
        </FormItem>
        <FormItem
          className="hd-0-mb"
          name="linked_in"
          label={t('team|form.label.linkedIn')}
        >
          <Input
            size="large"
            placeholder={t('team|form.placeholder.linkedIn')}
          />
        </FormItem>
      </Grid>
    </Form>
  );
};

export default TeamMemberForm;
