import AppLayout from '@hellodarwin/core/lib/components/common/layout/app-layout';
import { useAppData } from '@hellodarwin/core/lib/features/providers/app-data-provider';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import AppRoutesRender from '@hellodarwin/core/lib/features/router/app-routes-render';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './app';
import ProviderActionModal from './components/companies/companies-action-modal';
import MatchesActionModal from './components/matches/matches-action-modal';
import ProgramsActionModal from './components/programs/programs-action-modal';
import RfpActionModal from './components/rfp/rfp-action-modal';
import { useActionBar } from './context/action-bar-provider';
import {
  me,
  selectAuthentifiedUser,
  selectIsLoading,
} from './features/api/slices/global-slice';
import { useAdminApi } from './features/api/use-admin-api';
import HeaderBar from './layout/header-bar';
import Navigation from './layout/navigation';
import adminAppPages from './pages';
import UnverifiedAdminPage from './pages/unverified-page';

const AdminApp = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const admin = useAppSelector(selectAuthentifiedUser);
  const { actionBar } = useActionBar();
  const { changeLanguage } = useLocale();
  const isLoading = useAppSelector(selectIsLoading);
  const isLoggedIn = useMemo(() => !!admin.admin_id, [admin]);

  const isAppLoading = useMemo(
    () => isLoading || !isLoggedIn,
    [isLoading, isLoggedIn],
  );

  useEffect(() => {
    if (isLoggedIn) {
      changeLanguage(admin.preferred_language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(me({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appData = useAppData();
  useEffect(() => {
    const { setHeader, setNav } = appData;

    setHeader(<HeaderBar />);
    setNav(<Navigation />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultHomepage = useMemo(
    () => admin.admin_homepage ?? '/projects',
    [admin],
  );

  if (admin.roles === 'Unverified') return <UnverifiedAdminPage />;

  return (
    <>
      <AppLayout isLoading={isAppLoading}>
        <AppRoutesRender
          pages={adminAppPages}
          defaultHomepage={defaultHomepage}
        />
        <>{actionBar}</>
      </AppLayout>
      <RfpActionModal />
      <ProviderActionModal />
      <MatchesActionModal />
      <ProgramsActionModal />
    </>
  );
};

export default AdminApp;
