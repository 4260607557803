import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import {
  Forms,
  GrantPreview,
  InitialForms,
} from '@hellodarwin/core/lib/features/entities';
import { getStringDateFromString } from '@hellodarwin/core/lib/features/helpers/get-formatted-date';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Table, { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchForms,
  selectAllForms,
} from '../../features/api/slices/forms-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import FormSelectPublishedDateModal from './form-select-published-date-modal';

interface ModalState {
  open: boolean;
  activeForm: Forms;
}

const InitialModalState: ModalState = { open: false, activeForm: InitialForms };

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 100;
interface PaginationState {
  page: number;
  size: number;
}

const paginationInitialValues: PaginationState = {
  page: DEFAULT_PAGE,
  size: DEFAULT_LIMIT,
};

const FormsList = () => {
  const theme = useTheme();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const [modalState, setModalState] = useState<ModalState>(InitialModalState);
  const closeModal = () => setModalState(InitialModalState);

  const forms = useAppSelector(selectAllForms);
  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, false, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paginationState, setPaginationState] = useState<PaginationState>(
    paginationInitialValues,
  );
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const fetch = (
    page: number,
    size: number,
    loading: boolean,
    isSearching: boolean,
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        setApiState({ isLoading: true, isSearching: true, isErrored: null });

        dispatch(fetchForms({ api, page, limit: size }));

        setPaginationState({
          page: page,
          size: size,
        });
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, true, false);
  };

  const openGrant = (grant: GrantPreview) => {
    window.open(`/grants/${grant.grant_id}`, '_blank');
  };

  const columns: ColumnsType<Forms> = [
    {
      title: 'Form',
      dataIndex: 'form_id',
      key: 'form_id',
      render: (formID: string, record) => {
        return (
          <Div flex="row" gap={16} justify={'center'}>
            <Link target="_blank" to={`/forms/${formID}`}>
              <Button isLink size="square" defaultStyle={theme.colors.purple_1}>
                <NewWindow width={20} height={20} />
              </Button>
            </Link>
            <Button
              isLink
              size="square"
              defaultStyle={theme.colors.purple_1}
              onClick={() => setModalState({ open: true, activeForm: record })}
            >
              <Timeline width={20} height={20} />
            </Button>
          </Div>
        );
      },
      width: 75,
    },
    {
      title: t('forms_list|form_name'),
      dataIndex: 'title_en',
      key: 'title_en',
      render: (value: string, record: Forms) => {
        return <Div>{value + ' / ' + record.title_fr}</Div>;
      },
    },
    {
      title: t('forms_list|programsTitle'),
      dataIndex: 'grants',
      key: 'grants',
      width: 320,
      render: (grants: GrantPreview[]) => {
        return (
          <Div flex="row" gap={8} wrap="wrap">
            {grants?.map((grant) => (
              <HdTag
                color={theme.colors.purple_2}
                text={grant.display_title}
                hoverable
                icon={<NewWindow width={12} height={12} />}
                onClick={() => openGrant(grant)}
                iconPosition="end"
              />
            ))}
          </Div>
        );
      },
    },
    {
      title: t('forms_list|created_on'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return new Date(date).toLocaleDateString();
      },
    },
    {
      title: t('forms_list|published'),
      dataIndex: 'published_at',
      key: 'published_at',
      render: (date: string) => {
        return !!date ? (
          dayjs(date).diff() < 0 ? (
            <HdTag
              color={theme.colors.green_1}
              text={t('forms_list|published')}
            />
          ) : (
            <HdTag
              color={theme.colors.yellow_1}
              text={`Scheduled for ${getStringDateFromString(
                date,
                selectedLocale,
              )}`}
            />
          )
        ) : (
          <HdTag
            color={theme.colors.red_1}
            text={t('forms_list|not_published')}
          />
        );
      },
    },
    {
      title: t('forms_list|updatedAt'),
      dataIndex: 'updated_at',
      key: 'form_updated_at',
      render: (date: string) => {
        return new Date(date).toLocaleDateString();
      },
    },
    {
      title: 'Type',
      dataIndex: 'form_type',
      key: 'form_type',
      render: (form_type: string) => {
        return form_type;
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const Header = () => {
    const navigate = useNavigate();
    const handleCreateForm = () => {
      navigate(`/forms/create`);
    };

    return (
      <Div flex="row" gap={16} justify="flex-end">
        <Button
          onClick={handleCreateForm}
          size="small"
          defaultStyle={theme.colors.white_1}
          trailingIcon={<AddNew size={16} />}
        >
          {t('forms_list|createForm')}
        </Button>
      </Div>
    );
  };
  return (
    <Div flex="column" gap={24}>
      <Header />
      <Table
        bordered
        dataSource={forms}
        columns={columns}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              navigate(`/forms/${record.form_id}`);
            },
          };
        }}
        pagination={{
          pageSize: paginationState.size,
          current: paginationState.page,
          total: forms.length,
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={apiState.isLoading}
      />
      <FormSelectPublishedDateModal
        handleCancel={closeModal}
        open={modalState.open}
        activeForm={modalState.activeForm}
      />
    </Div>
  );
};

export default FormsList;
