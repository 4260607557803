import Email from '@hellodarwin/icons/dist/icons/Email';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import HubspotLogo from '@hellodarwin/icons/dist/icons/HubspotLogo';
import IdIcon from '@hellodarwin/icons/dist/icons/IdIcon';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Link from '@hellodarwin/icons/dist/icons/Link';
import Location from '@hellodarwin/icons/dist/icons/Location';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Phone from '@hellodarwin/icons/dist/icons/Phone';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import { SelectProps } from 'antd/es/select';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Company } from '../../../features/entities/core-entities';
import { getStringDateFromString } from '../../../features/helpers/get-formatted-date';
import parse from '../../../features/helpers/parse';
import prefixHTTPS from '../../../features/helpers/prefix-https';
import useLocale from '../../../features/providers/locale-provider';
import { usePlatform } from '../../../features/providers/platform-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import Typography from '../../common/typography';
import formatPhoneNumber from '../../forms/normalizers/format-phone-number';
import BusinessProfileInfoRow, {
  BusinessProfileInfoRowProps,
} from './business-profile-info-row';
import { BusinessProfileList } from './styles';

interface BusinessProfileProps {
  company: Company;
  industries: SelectProps['options'];
  subindustries: SelectProps['options'];
}

const BusinessProfile: React.FC<BusinessProfileProps> = ({
  company,
  industries,
  subindustries,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { app } = usePlatform();
  const isAdmin = useMemo(() => app === 'admin', [app]);

  const { description } = company;
  const mappedIndustries = useMemo(
    () =>
      industries.reduce(
        (o, i) => ({ ...o, [i.value]: i.label }),
        {} as { [value: string]: string },
      ),
    [industries],
  );

  const mappedSubindustries = useMemo(
    () =>
      subindustries.reduce(
        (o, i) => ({ ...o, [i.value]: i.label }),
        {} as { [value: string]: string },
      ),
    [subindustries],
  );

  const infos: BusinessProfileInfoRowProps[] = useMemo(() => {
    const {
      created_at,
      phone,
      website,
      email,
      size,
      annual_revenue,
      business_number,
      address,
      city,
      province,
      postal_code,
      country,
      industry_sector,
      industry_subsector,
      hubspot_url,
    } = company;

    const formattedPhone = !!phone ? formatPhoneNumber(phone) : undefined;
    const formattedWebsite = !!website
      ? prefixHTTPS(website).toLowerCase()
      : undefined;

    return [
      isAdmin && {
        label: t('company|clientSince'),
        Icon: Timeline,
        value: !!created_at
          ? getStringDateFromString(created_at, selectedLocale)
          : '',
      },
      isAdmin && {
        label: 'Hubspot',
        Icon: HubspotLogo,
        value: !!hubspot_url ? (
          <a href={hubspot_url} target="_blank">
            {t('company|hubspotLink')} <NewWindow size={16} />
          </a>
        ) : (
          ''
        ),
      },
      isAdmin && {
        label: t('company|phone'),
        Icon: Phone,
        value: !!formattedPhone ? (
          <a href={`tel:${formattedPhone}`}>{formattedPhone}</a>
        ) : (
          ''
        ),
      },
      isAdmin && {
        label: t('company|companyEmail'),
        Icon: Email,
        value: !!email ? <a href={`mailto:${email}`}>{email}</a> : ' - ',
      },
      isAdmin && {
        label: t('company|website'),
        Icon: Link,
        value: !!formattedWebsite ? (
          <a href={`${formattedWebsite}`} target="_blank" rel="noreferrer">
            {formattedWebsite}
          </a>
        ) : (
          ' - '
        ),
      },
      {
        label: t('company|scianCode'),
        Icon: IdIcon,
        value: ' - ',
      },
      {
        label: t('company|businessNumber'),
        Icon: IdIcon,
        value: business_number ?? ' - ',
      },
      {
        label: t('company|annualRevenue'),
        Icon: Invoices,
        value: annual_revenue ?? ' - ',
      },
      {
        label: t('company|companySize'),
        Icon: Employees,
        value: !!size?.length ? t(`company|companySizeValue`, { size }) : ' - ',
      },

      {
        label: t('company|companyAddress'),
        Icon: Location,
        value: [address, city, province, country, postal_code]
          ?.filter((i) => !!i)
          ?.join(', '),
      },
      isAdmin && {
        label: t('company|industry'),
        Icon: Employees,
        value:
          industry_sector?.length || industry_subsector?.length ? (
            <Div flex="row" wrap="wrap" gap={8}>
              {React.Children.toArray(
                industry_sector?.map((tag) => (
                  <HdTag
                    key={tag}
                    text={mappedIndustries[tag]}
                    color={theme.colors.purple_2}
                  />
                )),
              )}
              {React.Children.toArray(
                industry_subsector?.map((tag) => (
                  <HdTag
                    key={tag}
                    text={mappedSubindustries[tag]}
                    color={theme.colors.purple_3}
                  />
                )),
              )}
            </Div>
          ) : (
            '-'
          ),
      },
    ].filter((itm) => !!itm);
  }, [company, mappedIndustries, mappedSubindustries]);
  return (
    <Div flex="column" gap={54}>
      {!!description && <Typography>{parse(description)}</Typography>}
      <BusinessProfileList>
        {infos.map((info) => (
          <BusinessProfileInfoRow {...info} />
        ))}
      </BusinessProfileList>
    </Div>
  );
};

export default BusinessProfile;
