import Button from '@hellodarwin/core/lib/components/common/button';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GrantResult } from '@hellodarwin/core/lib/features/entities/grants-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { useState } from 'react';
import GrantsSearch from '../../../../../../grants/grants-search';

interface ProjectGrantFormProps {
  handleSaveGrant: (grant: GrantResult) => void;
}
const ProjectGrantForm: React.FC<ProjectGrantFormProps> = ({
  handleSaveGrant,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const [activeGrant, setActiveGrant] = useState<GrantResult | undefined>(
    undefined,
  );

  const onSave = () => {
    if (!!activeGrant?.grant_id) {
      handleSaveGrant(activeGrant);
    }
  };

  return (
    <>
      <Typography elementTheme="h5">{t(`project|searchGrantTitle`)}</Typography>
      <GrantsSearch handleSubmit={setActiveGrant} />
      {!!activeGrant && (
        <HdTag
          text={activeGrant.grant_display_title}
          color={theme.colors.purple_2}
        />
      )}
      <Button
        onClick={onSave}
        defaultStyle={theme.colors.purple_2}
        style={{ alignSelf: 'flex-end' }}
        disabled={!activeGrant}
      >
        {t('button|assign')}
      </Button>
    </>
  );
};

export default ProjectGrantForm;
