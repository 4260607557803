import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { useMemo } from 'react';
import FileSectionGin from '../../../gins/gin-single/file-section';
import { GinBlockConfig } from '../../../gins/gin-single/gin-block';
import { GinGrantSinglePageSection } from '../../../gins/grant-single/use-gin-grant-blocks';
import BusinessProfileBlock from '../business-profile-block';
import CompanyCommentsEditor from '../comments-editor-block';
import CompanyProjectsBlock from '../projects-block';

export enum CompanySinglePageSections {
  BusinessProfile = 'business_profile',
  Comments = 'comments',
  Priorities = 'priorities',
  Files = 'files',
}

const useCompanyBlocks = () => {
  const { t } = useTranslations();
  return useMemo(() => {
    const CompanySectionBlocks: { [section: string]: GinBlockConfig } = {
      [CompanySinglePageSections.Comments]: {
        id: CompanySinglePageSections.Comments,
        Content: CompanyCommentsEditor,
        noHistory: true,
      },
      [CompanySinglePageSections.Priorities]: {
        id: CompanySinglePageSections.Priorities,
        Content: CompanyProjectsBlock,
        noHistory: true,
        buttonTexts: {
          edit: { label: t('project|addNewPriority'), Icon: AddNew },
        },
        isSimpleActions: true,
      },
      [CompanySinglePageSections.BusinessProfile]: {
        id: CompanySinglePageSections.BusinessProfile,
        Content: BusinessProfileBlock,
        noHistory: true,
        isSimpleActions: true,
      },
      [GinGrantSinglePageSection.Files]: {
        id: GinGrantSinglePageSection.Files,
        Content: FileSectionGin,
        isSimpleActions: true,
        noHistory: true,
      },
    };

    return CompanySectionBlocks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
};

export default useCompanyBlocks;
