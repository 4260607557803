import { CSSProperties } from 'react';
import { ArrowContainer } from './styles';

export type HdArrowProps = {
  dir: 'left' | 'right' | 'top' | 'bottom';
  style?: CSSProperties;
};

const HdArrow = ({ dir }: HdArrowProps) => {
  return (
    <ArrowContainer className="hd-arrow" $dir={dir}>
      <svg
        width="8.73"
        height="16"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: `scale(${dir === 'left' ? -1 : 1})` }}
      >
        <path
          d="M1 1L10.8182 11L1 21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </ArrowContainer>
  );
};

export default HdArrow;
