import { ProviderStatus } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useAdminApi } from '../../features/api/use-admin-api';

type ProviderStatusFormProps = {
  providerId: string;
  status: ProviderStatus | undefined;
};

const ProviderStatusForm = ({
  providerId,
  status,
}: ProviderStatusFormProps) => {
  const api = useAdminApi();
  const { t } = useTranslations();
  const [providerStatus, setProviderStatus] = useState<ProviderStatus>(
    ProviderStatus.Unverified,
  );

  useEffect(() => {
    if (status) {
      setProviderStatus(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (newStatus: ProviderStatus) => {
    (async () => {
      try {
        setProviderStatus(newStatus);
        await api.updateProviderStatus(providerId, newStatus);
        message.success('Status successfully changed!');
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      }
    })();
  };

  return (
    <Select
      value={providerStatus}
      size={'small'}
      onSelect={(status: ProviderStatus) => handleStatusChange(status)}
    >
      <Select.Option value={ProviderStatus.Unverified}>
        {t('providerStatus|unverified')}
      </Select.Option>
      <Select.Option value={ProviderStatus.Verified}>
        {t('providerStatus|verified')}
      </Select.Option>
      <Select.Option value={ProviderStatus.Rejected}>
        {t('providerStatus|Rejected')}
      </Select.Option>
      <Select.Option value={ProviderStatus.Suspended}>
        {t('providerStatus|suspending')}
      </Select.Option>
      <Select.Option value={ProviderStatus.Canceled}>
        {t('providerStatus|Canceled')}
      </Select.Option>
    </Select>
  );
};

export default ProviderStatusForm;
