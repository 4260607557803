import GinFilterHeader from './gin-filter-header';
import { FilterSectionContainterStyled, FilterSectionStyled } from './styles';

export interface GinFilterProps {
  applyFilter: (query: string, filter: string[]) => void;
}

const GinFilter = ({ applyFilter }: GinFilterProps) => {
  return (
    <FilterSectionContainterStyled $position="top">
      <FilterSectionStyled $position="top">
        <GinFilterHeader applyFilter={applyFilter} />
      </FilterSectionStyled>
    </FilterSectionContainterStyled>
  );
};

export default GinFilter;
