import { ListGridType, ListItemLayout } from '.';
import styled, { css } from '../../../plugins/styled';
import { fadeIn, fadeOut } from '../../../theme/keyframes';

export const ListContainer = styled.div<{ $isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 54px;

  animation: ${fadeIn} 200ms linear;
  opacity: 1;
  .fade-out {
    animation: ${fadeOut} 200ms linear;
    opacity: 0;
  }
`;

interface ListContentProps {
  $layout?: ListItemLayout;
  $bordered?: boolean;
  $divider?: boolean;
  $grid?: ListGridType;
}

const HorizontalLayoutStyle = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 32px;
  flex-wrap: wrap;
`;

const VerticalLayoutStyle = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const GridLayoutStyle = css<ListContentProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${!!props.$grid?.column ? props.$grid?.column : 1}, 1fr)`};

  @media (min-width: ${(props) => props.theme.breakpoints.xl + 1}px) {
    ${(props) =>
      !!props.$grid?.xxl
        ? `grid-template-columns: repeat(${props.$grid?.xxl}, 1fr)`
        : undefined};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    ${(props) =>
      !!props.$grid?.xl
        ? `grid-template-columns: repeat(${props.$grid?.xl}, 1fr)`
        : undefined};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    ${(props) =>
      !!props.$grid?.lg
        ? `grid-template-columns: repeat(${props.$grid?.lg}, 1fr)`
        : undefined};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    ${(props) =>
      !!props.$grid?.md
        ? `grid-template-columns: repeat(${props.$grid?.md}, 1fr)`
        : undefined};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    ${(props) =>
      !!props.$grid?.sm
        ? `grid-template-columns: repeat(${props.$grid?.sm}, 1fr)`
        : undefined};
  }
  ${(props) =>
    !!props.$grid?.gutter ? `column-gap:${props.$grid?.gutter}px` : undefined}
`;

export const ListContent = styled.div<ListContentProps>`
  ${(props) =>
    !!props.$grid
      ? GridLayoutStyle
      : props.$layout === 'horizontal'
        ? HorizontalLayoutStyle
        : VerticalLayoutStyle};
  ${(props) =>
    !!props.$bordered
      ? `border: 1px solid ${props.theme.colors.primary};`
      : undefined}
  ${(props) =>
    !!props.$divider
      ? `
      ${ListItemContainer} {
        &::after{
          content:"";
          display:block;
          width:100%;
          margin:8px 0;
          border-bottom: 1px solid ${props.theme.colors.grey_4};
        }
        &:last-child {
          &::after{
            display:none;
          }
        }
      }
      `
      : undefined}
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    width: 100%;
    height: 100%;
  }
`;
