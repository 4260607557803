import { Breadcrumb } from '../../../../features/entities/context-entities';
import BreadcrumbDivider from './breadcrumb-divider';
import { StyledBreadcrumbs } from './styles';
import useBreadcrumbRender from './use-breadcrumb-render';

export type BreadcrumbsContentSize = 'medium' | 'large';
interface BreadcrumbsContentProps {
  breadcrumbs: Breadcrumb[];
  size?: BreadcrumbsContentSize;
}

const BreadcrumbsContent = ({
  breadcrumbs,
  size = 'medium',
}: BreadcrumbsContentProps) => {
  const breadcrumbRender = useBreadcrumbRender();

  return (
    <div style={{ overflow: 'hidden' }}>
      <StyledBreadcrumbs
        $size={size}
        style={{ width: '100%' }}
        separator={<BreadcrumbDivider />}
        items={breadcrumbs}
        itemRender={breadcrumbRender}
      />
    </div>
  );
};

export default BreadcrumbsContent;
