import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Drawer from 'antd/es/drawer';
import { useMemo } from 'react';
import ProjectForm, { ProjectFormModalProps } from '.';

const ProjectFormDrawer: React.FC<ProjectFormModalProps> = ({
  project,
  open,
  handleCancel,
  handleSaveProject,
  parentProject,
}) => {
  const { t } = useTranslations();
  const isPriority = useMemo(
    () => !parentProject && !project.parent_project,
    [parentProject, project],
  );
  return (
    <Drawer
      open={open}
      onClose={handleCancel}
      size="large"
      width={900}
      title={t(
        isPriority ? 'project|priority_update' : 'project|project_update',
        { project_name: project.title },
      )}
      styles={{
        body: {
          display: 'flex',
          flexDirection: 'column',
          gap: 32,
          alignItems: 'flex-end',
        },
      }}
    >
      <ProjectForm
        project={{ ...project }}
        isPriority={isPriority}
        handleSaveProject={handleSaveProject}
      />
    </Drawer>
  );
};

export default ProjectFormDrawer;
