import ImagePlus from '@hellodarwin/icons/dist/icons/ImagePlus';
import { MouseEvent, useState } from 'react';
import { PortfolioAsset } from '../../../../../../features/entities/core-entities';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import PortfolioAssetModal from './asset-modal';
import PortfolioAssetsList from './assets-list';

type PortfolioAssetsProps = {
  assets: PortfolioAsset[];
  setAssets: (assets: PortfolioAsset[]) => void;
  portfolio_id: string;
};

const PortfolioAssets = ({
  assets,
  setAssets,
  portfolio_id,
}: PortfolioAssetsProps) => {
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const theme = useTheme();

  return (
    <Div>
      <Button
        defaultStyle={theme.colors.purple_1}
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          setAssetModalOpen(true);
        }}
        size="small"
        style={{ width: '100%', borderRadius: 6 }}
      >
        <ImagePlus width={100} height={100} />
      </Button>

      {!!assets?.length && (
        <PortfolioAssetsList assets={assets} setAssets={setAssets} />
      )}

      <PortfolioAssetModal
        open={assetModalOpen}
        handleClose={() => setAssetModalOpen(false)}
        portfolio_id={portfolio_id}
        assets={assets}
        setAssets={setAssets}
      />
    </Div>
  );
};

export default PortfolioAssets;
