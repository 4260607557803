import styled from '@hellodarwin/core/lib/plugins/styled';

export const ApplicationStateBox = styled.div<{ $isActive?: boolean }>`
  width: 220px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.beige_2 : theme.colors.beige_3};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};

  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding-inline: 8px;
  padding-block: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.beige_1};
  }
`;
