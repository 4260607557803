import useForm, { FormInstance } from 'antd/es/form/hooks/useForm';
import Select, { SelectProps } from 'antd/es/select';
import { useState } from 'react';
import { useTranslation } from '../../../../plugins/i18n';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';

export interface SelectCountryProps extends SelectProps {
  setCanada: (isCanada: boolean) => void;
  form: FormInstance<any>;
}

const SelectCountry = ({
  setCanada,
  form,
  ...selectProps
}: SelectCountryProps) => {
  const { t } = useTranslation();
  const handleSelectChange = (value: boolean) => {
    setCanada(value);
    if (value) {
      form.setFieldValue('country', 'Canada');
    } else {
      form.setFieldValue('country', '');
    }
  };

  return (
    <FormItem
      label={
        <FormLabel label={t('profile|profileForm.address.labels.country')} />
      }
      name="isCanada"
      style={{ minWidth: 175 }}
    >
      <Select
        {...selectProps}
        onChange={handleSelectChange}
        size="large"
        placeholder={t('profile|profileForm.address.extra.selectCountry')}
      >
        <Select.Option value={true}>Canada</Select.Option>
        <Select.Option value={false}>
          {t('profile|profileForm.address.labels.other')}
        </Select.Option>
      </Select>
    </FormItem>
  );
};

export default SelectCountry;

SelectCountry.Demo = () => {
  const [form] = useForm();
  const [isCanada, setCanada] = useState(false);
  return <SelectCountry form={form} setCanada={setCanada} disabled />;
};
