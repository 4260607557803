import { TeamMember, TeamMemberItem } from '../../../../../features/entities';
import { ProfileEditModalFunctions } from '../../../../forms/prebuilt-forms/profile-edit-modal';

export const handleReorderTeamMembers = ({
  team_member_id,
  item_order,
  members,
  functions,
  setTeamMembers,
}: {
  team_member_id: string;
  item_order?: number;
  functions?: ProfileEditModalFunctions;
  members: TeamMember[];
  setTeamMembers?: (values: TeamMember[]) => void;
}) => {
  const movingItemId = members.findIndex(
    (item) => item.provider_team_member_id === team_member_id,
  );

  const [removedItem] = members.splice(movingItemId, 1);
  if (!!item_order) {
    members.splice(movingItemId + item_order, 0, removedItem);
  }

  const teamMemberItems: TeamMemberItem[] = [];
  members.forEach((item, index) => {
    teamMemberItems.push({
      provider_team_member_id: item.provider_team_member_id,
      order_team: index,
    });
  });

  const updatedMembers = members.map((member, index) =>
    member.provider_team_member_id === team_member_id
      ? {
          ...member,
          order_team: index,
        }
      : member,
  );

  !!functions?.updateTeamMemberOrder &&
    functions.updateTeamMemberOrder({ teamMembers: teamMemberItems });
  !!setTeamMembers && setTeamMembers(updatedMembers);
};
