import {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { CardStyled } from './styles';

export interface CardProps {
  backgroundColor?: string;
  className?: string;
  cover?: ReactNode;
  hoverable?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
  styles?: {
    body?: CSSProperties;
    cover?: CSSProperties;
  };
}

const Card = ({
  backgroundColor,
  className,
  children,
  cover,
  hoverable,
  style,
  styles,
  onClick,
}: CardProps & PropsWithChildren) => {
  return (
    <CardStyled
      $backgroundColor={backgroundColor}
      $hoverable={hoverable}
      style={{ ...style }}
      onClick={onClick}
      className={className}
    >
      {!!cover && (
        <div className="card-cover" style={{ ...styles?.cover }}>
          {cover}
        </div>
      )}
      <div className="card-body" style={{ ...styles?.body }}>
        {children}
      </div>
    </CardStyled>
  );
};

export default Card;
