import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from 'react';

interface ActionBarContextType {
  actionBar: ReactNode | null;
  setActionBar: (element: ReactNode | null) => void;
}

export const ActionBarContext = createContext<ActionBarContextType>({
  actionBar: null,
  setActionBar: (element) => {},
});

const ActionBarProvider = ({ children }: PropsWithChildren) => {
  const [actionBar, setActionBar] =
    useState<ActionBarContextType['actionBar']>(null);

  return (
    <ActionBarContext.Provider
      value={{
        actionBar,
        setActionBar,
      }}
    >
      {children}
    </ActionBarContext.Provider>
  );
};

export const useActionBar = () => useContext(ActionBarContext);
export default ActionBarProvider;
