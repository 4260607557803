import Modal from 'antd/es/modal';
interface DiscountRfpModalProps {
  modalVisible: boolean;
  closeModal: () => void;
  sendDiscountNotification: () => void;
}

const RfpDiscountModal = ({
  modalVisible,
  closeModal,
  sendDiscountNotification,
}: DiscountRfpModalProps) => {
  return (
    <Modal
      title="Rfp Discounted!"
      open={modalVisible}
      onOk={() => sendDiscountNotification()}
      onCancel={closeModal}
      okText={'Send notification'}
      okType={'primary'}
      cancelText={"Don't send notification"}
    >
      <p>
        Do you want to notify all service providers that matched with this rfp?
      </p>
    </Modal>
  );
};
export default RfpDiscountModal;
