import { GinApplication } from '@hellodarwin/core/lib/features/entities/gin-entities';
import dollarFormat from '@hellodarwin/core/lib/features/helpers/dollar-format';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useMemo } from 'react';
const useApplicationAmout = (application?: GinApplication) => {
  const { selectedLocale } = useLocale();
  return useMemo(() => {
    if (!application) return '';

    const {
      application_accepted_amount,
      application_submitted_amount,
      application_targeted_amount,
    } = application;

    return dollarFormat(
      application_accepted_amount ??
        application_submitted_amount ??
        application_targeted_amount ??
        0,
      selectedLocale,
      true,
    );
  }, [application, selectedLocale]);
};

export default useApplicationAmout;
