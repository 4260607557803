import Typography from '@hellodarwin/core/lib/components/common/typography';
import theme from '@hellodarwin/core/lib/theme';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { RelatedLinkContainer } from './styles';

interface RelatedLinkProps {
  label: string;
  to?: string;
  onClick?: MouseEventHandler;
}

const RelatedLinkContent: React.FC<RelatedLinkProps> = ({ onClick, label }) => {
  return (
    <RelatedLinkContainer onClick={onClick}>
      <Typography color={theme.colors.purple_1} elementTheme="body3">
        {label}
      </Typography>

      <NewWindow
        style={{ flexShrink: 0 }}
        color={theme.colors.purple_1}
        size={16}
      />
    </RelatedLinkContainer>
  );
};
export const RelatedLink = (props: RelatedLinkProps) => {
  const { to } = props;
  return !!to ? (
    <Link target="_blank" to={to}>
      <RelatedLinkContent {...props} />
    </Link>
  ) : (
    <RelatedLinkContent {...props} />
  );
};
