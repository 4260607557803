import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Download = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99976 23V26.5C1.99976 27.4283 2.3685 28.3185 3.02488 28.9749C3.68126 29.6313 4.5715 30 5.49976 30H26.4998C27.428 30 28.3183 29.6313 28.9746 28.9749C29.631 28.3185 29.9998 27.4283 29.9998 26.5V23M22.9998 16L15.9998 23M15.9998 23L8.99976 16M15.9998 23V2"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Download;
