import Div from '@hellodarwin/core/lib/components/common/div';
import Dropdown from '@hellodarwin/core/lib/components/common/dropdown';
import { Match, Rfp } from '@hellodarwin/core/lib/features/entities';
import RfpType from '@hellodarwin/core/lib/features/enums/rfp-type';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import { MenuProps } from 'antd/es/menu';
import message from 'antd/es/message';
import Skeleton from 'antd/es/skeleton/Skeleton';
import { useMemo, useState } from 'react';
import { useAppDispatch } from '../../app/app-hooks';
import {
  setSelectedMatch,
  toggleMatchesModal,
} from '../../features/api/slices/matches-slice';
import {
  adminUnrefuseMatch,
  fetchRfpMatches,
  setSelectedRfp,
  unignoreRfp,
} from '../../features/api/slices/rfp-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import LeadMatchPreview from './lead-match-preview';
import LeaveReviewModal from './leave-review-modal';
import ProviderMatchPreview from './provider-match-preview';
import RaisehandMatchPreview from './raisehand-match-preview';
import RfpMatchPreview from './rfp-match-preview';

import './match-preview.scss';

type MatchPreviewProps = {
  match: Match;
  rfp?: Rfp;
  isProvider?: Boolean;
  updateWinner: (matchId: string, isWinner: boolean) => void;
};

const MatchPreview = ({
  match,
  rfp,
  isProvider,
  updateWinner,
}: MatchPreviewProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [apiState, setApiState] = useState<{
    isLoading: Boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isErrored: null,
  });

  const [openReview, setOpenReview] = useState(false);
  const handleReviewModal = () => {
    setOpenReview(!openReview);
  };

  const openRefuseModal = () => {
    dispatch(setSelectedMatch(match));
    if (!!rfp) {
      dispatch(setSelectedRfp(rfp));
    }

    dispatch(toggleMatchesModal({ type: 'refuse', isVisible: true }));
  };

  const openIgnoreModal = () => {
    dispatch(setSelectedMatch(match));
    if (!!rfp) {
      dispatch(setSelectedRfp(rfp));
    }

    dispatch(toggleMatchesModal({ type: 'ignore', isVisible: true }));
  };

  const className = match.winner_at
    ? 'hd-card match-preview winner'
    : 'hd-card match-preview';

  const fetchInvoice = () => {
    (async () => {
      setApiState({ isLoading: true, isErrored: null });
      try {
        const invoice = await api.fetchInvoice(match.match_id);
        window.open(invoice.HostedURL, '_blank');
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      } finally {
        setApiState({ isLoading: false, isErrored: null });
      }
    })();
  };

  const fetchRhInvoice = () => {
    (async () => {
      setApiState({ isLoading: true, isErrored: null });
      try {
        const invoice = await api.fetchRaisehandInvoice(match.match_id);
        window.open(invoice.HostedURL, '_blank');
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      } finally {
        setApiState({ isLoading: false, isErrored: null });
      }
    })();
  };

  const fetchWinFeeInvoice = () => {
    (async () => {
      setApiState({ isLoading: true, isErrored: null });
      try {
        const invoice = await api.fetchWinFeeInvoice(match.match_id);
        window.open(invoice.HostedURL, '_blank');
      } catch (e) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      } finally {
        setApiState({ isLoading: false, isErrored: null });
      }
    })();
  };

  const sendProviderNotification = () => {
    (async () => {
      setApiState({ isLoading: true, isErrored: null });
      try {
        await api.sendProviderReviewNotification(match.match_id);
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      } finally {
        setApiState({ isLoading: false, isErrored: null });
      }
    })();
  };

  const unrefuseMatch = async (match_id: string) => {
    await dispatch(
      adminUnrefuseMatch({
        api,
        matchId: match_id,
      }),
    );
    if (rfp) {
      dispatch(fetchRfpMatches({ api, rfpId: rfp.rfp_id }));
    }
  };

  const unignoreMatch = async (match_id: string) => {
    await dispatch(
      unignoreRfp({
        api,
        matchId: match_id,
      }),
    );
  };

  const actionMenuItems: MenuProps['items'] = useMemo(() => {
    const items: MenuProps['items'] = [];

    if (!!match.winner_at) {
      items.push({
        label: 'Deselect this as winner',
        key: 'deselect_winner',
        onClick: () => updateWinner(match.match_id, false),
      });
    } else if (!!match.purchased_at) {
      items.push({
        label: 'Select this as winner',
        key: 'select_winner',
        onClick: () => updateWinner(match.match_id, true),
      });
    }
    if (!isProvider) {
      if (rfp?.type === RfpType.RaiseHand) {
        if (!!match.raise_hand_rejected_at) {
          items.push({
            label: 'Unrefuse Match',
            key: 'unrefuse_match',
            onClick: () => unrefuseMatch(match.match_id),
          });
        } else {
          items.push({
            label: 'Refuse Match',
            key: 'refuse_match',
            onClick: openRefuseModal,
          });
        }
      }
      if (!!match.purchased_at) {
        items.push(
          ...[
            {
              label: 'Leave a review',
              key: 'leave_review',
              onClick: handleReviewModal,
            },
            {
              label: 'Send review notification',
              onClick: sendProviderNotification,
              key: 'send_notification',
            },
          ],
        );
      }
    } else {
      if (!match.refused_at) {
        items.push({
          onClick: openIgnoreModal,
          label: 'Ignore Rfp',
          key: 'ignore_rfp',
        });
      } else {
        items.push({
          label: 'Unignore Rfp',
          onClick: () => unignoreMatch(match.match_id),
          key: 'uniginore_rfp',
        });
      }

      if (rfp?.type === RfpType.Classic) {
        items.push({
          label: 'View Invoice',
          key: 'view_invoice',
          onClick: fetchInvoice,
        });
      } else {
        items.push({
          label: ' View Raisehand Invoice',
          key: 'view_raisehand_invoice',
          onClick: fetchRhInvoice,
        });
        if (!!match.shortlisted_at) {
          items.push({
            label: 'View Shortlist Invoice',
            key: 'view_shortlist_invoice',
            onClick: fetchInvoice,
          });
        }
        if (!!match.winner_at && match.win_fee_price !== 0) {
          items.push({
            label: 'View Win Fee Invoice',
            key: 'view_winfee_invoice',
            onClick: fetchWinFeeInvoice,
          });
        }
      }
    }
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  if (apiState.isLoading) {
    return (
      <Card>
        <Skeleton active paragraph={{ rows: 2 }} />
      </Card>
    );
  }

  return (
    <>
      <Div className={className} flex="column">
        <Div className="match-preview-menu" flex={'row'}>
          <Dropdown items={actionMenuItems} cta={{ size: 24 }} />
        </Div>
        <div>
          {isProvider ? (
            <ProviderMatchPreview match={match} />
          ) : (
            <RfpMatchPreview match={match} />
          )}
          <Divider style={{ margin: '0.5rem' }} />
          {rfp?.type === RfpType.Classic ? (
            <LeadMatchPreview match={match} />
          ) : (
            <RaisehandMatchPreview match={match} />
          )}
        </div>
      </Div>
      <LeaveReviewModal
        selectedMatch={match}
        open={openReview}
        handleClose={handleReviewModal}
      />
    </>
  );
};

export default MatchPreview;
