import Div from '@hellodarwin/core/lib/components/common/div';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { useState } from 'react';
import ProjectApplicationFormStateSelector from './application-form-state-selector';
import ProjectCreateApplicationForm from './create-application';
import ProjectSearchExistingApplication from './search-existing-application';
import {
  ProjectApplicationFormProps,
  ProjectApplicationFormState,
} from '../../../types';

const ProjectApplicationForm: React.FC<ProjectApplicationFormProps> = (
  props,
) => {
  const [activeState, setActiveState] =
    useState<ProjectApplicationFormState>('search');
  const theme = useTheme();

  return (
    <Div flex="row" gap={54} align="stretch">
      <ProjectApplicationFormStateSelector
        activeState={activeState}
        setActiveState={setActiveState}
      />
      <div style={{ borderLeft: `1px solid ${theme.colors.grey_1}` }}></div>
      {activeState === 'create' && <ProjectCreateApplicationForm {...props} />}
      {activeState === 'search' && (
        <ProjectSearchExistingApplication {...props} />
      )}
    </Div>
  );
};

export default ProjectApplicationForm;
