import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Time = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.39997V16H23.2M30.4 16C30.4 17.891 30.0275 19.7635 29.3039 21.5106C28.5802 23.2577 27.5195 24.8451 26.1823 26.1823C24.8452 27.5195 23.2577 28.5802 21.5106 29.3038C19.7636 30.0275 17.891 30.4 16 30.4C14.109 30.4 12.2364 30.0275 10.4894 29.3038C8.74227 28.5802 7.15482 27.5195 5.81766 26.1823C4.4805 24.8451 3.4198 23.2577 2.69613 21.5106C1.97247 19.7635 1.6 17.891 1.6 16C1.6 12.1809 3.11714 8.51816 5.81766 5.81764C8.51818 3.11711 12.1809 1.59998 16 1.59998C19.8191 1.59998 23.4818 3.11711 26.1823 5.81764C28.8829 8.51816 30.4 12.1809 30.4 16Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Time;
