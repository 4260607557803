import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { PropsWithChildren } from 'react';
import Div from '../../common/div';
import Typography from '../../common/typography';
import { GrantCardInfosBoxBody, GrantCardInfosBoxContainer } from './styles';

interface GrantCardInfosBoxProps extends PropsWithChildren {
  label: string;
  Icon: IconNode;
}
const GrantCardInfosBox = ({
  label,
  children,
  Icon,
}: GrantCardInfosBoxProps) => (
  <GrantCardInfosBoxContainer>
    <Div flex="row" align="center" gap={10}>
      <Icon size={14} />
      <Typography elementTheme="overline" nowrap>
        {label}
      </Typography>
    </Div>
    <GrantCardInfosBoxBody>
      <Typography
        elementTheme="body2"
        textAlign="left"
        style={{ width: '100%' }}
      >
        {children}
      </Typography>
    </GrantCardInfosBoxBody>
  </GrantCardInfosBoxContainer>
);

export default GrantCardInfosBox;
