import { CollapsePanelProps } from '@hellodarwin/core/lib/components/common/Collapse/CollapsePanel';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import TabArrowIcon from '@hellodarwin/icons/dist/icons/TabArrow';
import { ReactNode } from 'react';
import GinGrantSummary, { GinSummaryProps } from '../gin-grant-summary';
import { GinSingleHeaderContainer } from '../styles';

interface GinGrantHeaderProps extends GinSummaryProps {
  HeaderContent: () => ReactNode;
}

const GinSingleHeader = ({
  HeaderContent,
  ...ginSummaryProps
}: GinGrantHeaderProps) => {
  const theme = useTheme();
  const collapseItems: CollapsePanelProps[] = [
    {
      id: 'gin',
      title: <HeaderContent />,
      children: <GinGrantSummary {...ginSummaryProps} />,
    },
  ];

  return (
    <GinSingleHeaderContainer
      items={collapseItems}
      expandIconPosition="end"
      ExpandIcon={({ isActive }) => (
        <TabArrowIcon size={24} color={theme.colors.grey_1} down={isActive} />
      )}
      defaultActiveKey={'gin'}
      ghost
      noPadding
      styles={{
        panelContainer: {
          background: theme.colors.white_1,
          border: `1px solid ${theme.colors.grey_1}`,
          borderRadius: '8px 8px 0 0',
        },
        panelHeader: { padding: '24px 48px' },
        panelContent: {
          padding: 0,
          borderTop: `1px solid ${theme.colors.grey_1}`,
        },
      }}
    />
  );
};

export default GinSingleHeader;
