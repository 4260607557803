import { useMemo } from 'react';
import { FilesListProps } from '..';
import { AssetRecordInformation } from '../../../../features/entities';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import AssetUpload from '../../asset-upload';

interface FilesListFooterProps extends FilesListProps {}
const FilesListFooter: React.FC<FilesListFooterProps> = ({
  handleSaveAsset,
  activeRecord,
  rootRecord,
  additionalOwners,
  allowUpload,
  title,
}) => {
  const records = useMemo(() => {
    const records: AssetRecordInformation[] = [];
    if (activeRecord) {
      records.push(activeRecord);
    } else {
      records.push(rootRecord);
    }

    return records;
  }, [activeRecord, rootRecord]);

  return (
    <Div
      flex="row"
      gap={54}
      align="center"
      justify={!!title ? 'space-between' : 'flex-end'}
      style={{ width: '100%' }}
    >
      {!!title && <Typography elementTheme="subtitle2">{title}</Typography>}

      {!!allowUpload && (
        <AssetUpload
          handleSaveAsset={handleSaveAsset}
          records={records}
          owners={additionalOwners}
        />
      )}
    </Div>
  );
};

export default FilesListFooter;
