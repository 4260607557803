import React from 'react';
import { ProfileModalProps } from '../../../../features/entities/profile-entities';
import { Provider } from '../../../../features/entities/providers-entities';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import { useTranslations } from '../../../../features/providers/translations-provider';
import SeeMoreList from '../../../common/see-more/see-more-list';
import ProfileSection from '../profile-section';
import ExpertiseCard from './ExpertiseCard';

type ProfileExpertiseProps = {
  provider: Provider;
  isEditable?: boolean;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  id: string;
};

const ProfileExpertise = ({
  provider,
  isEditable = false,
  toggleEditModal,
  id,
}: ProfileExpertiseProps) => {
  const { t } = useTranslations();

  const handleModal = () =>
    !!toggleEditModal &&
    toggleEditModal({ type: ProfileBlocks.Expertise, open: true });

  return (
    <ProfileSection
      title={t('profile_expertise|expertiseAlternativeTitle', {
        companyName: provider.name,
      })}
      isEditable={isEditable}
      handleEdit={handleModal}
      id={id}
    >
      {provider?.tags && (
        <SeeMoreList
          content={React.Children.toArray(
            Object.values(provider?.tags).map((tag) => (
              <ExpertiseCard key={`${tag.category}`} {...tag} />
            )),
          )}
          limit={3}
          grid={{ sm: 1, md: 1, lg: 1, column: 1, gutter: 24 }}
        ></SeeMoreList>
      )}
    </ProfileSection>
  );
};
export default ProfileExpertise;
