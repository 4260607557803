import { LogoProps, theme } from '../../features/entities/general';

const SecondaryLogo = ({
  primaryColor = theme.grey_1,
  secondaryColor = theme.grey_1,
  width = 278,
  height = 98,
  style,
  onClick,
}: LogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onClick={onClick}
      viewBox="0 0 278 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 55.2509H18.4454C34.3268 55.2509 41.0437 64.7458 41.0437 76.2601C41.0437 88.9188 32.8608 97.8148 18.996 97.8148H0V55.2541V55.2509ZM17.8949 90.3362C24.7974 90.3362 31.5142 87.2256 31.5142 76.6928C31.5142 64.4699 22.4756 62.7233 16.1236 62.7233H9.09843V90.333H17.8949V90.3362Z"
        fill={primaryColor}
      />
      <path
        d="M61.9683 55.2509H72.5958L90.6731 97.8117H80.9612L76.198 86.68H57.998C56.4092 90.3895 54.8834 94.1021 53.2946 97.8117H43.8878L61.9683 55.2509ZM72.9607 78.825C71.4946 75.3883 69.6636 70.967 67.2191 65.0186H67.0964C65.5077 68.8912 63.3715 73.9114 61.2321 78.825H72.9576H72.9607Z"
        fill={primaryColor}
      />
      <path
        d="M98.8373 55.2509H117.22C120.457 55.2509 133.771 56.4519 133.771 68.9476C133.771 76.9687 128.458 80.6782 123.021 81.9325V81.9858C126.991 87.2256 130.962 92.5186 134.932 97.8117H124.67L113.432 82.5879H107.936V97.8117H98.8341V55.2509H98.8373ZM115.571 75.3852C118.991 75.3852 124.245 74.2375 124.245 69.1107C124.245 63.9838 119.054 62.6167 115.694 62.6167H107.936V75.3852H115.571Z"
        fill={primaryColor}
      />
      <path
        d="M138.972 55.2509H149.354C152.714 66.2196 155.46 75.9308 158.333 86.3538H158.455C161.815 75.9872 165.295 65.6175 168.655 55.2509H179.037C182.397 65.6175 185.876 75.9872 189.296 86.3538H189.419C192.228 76.2601 195.161 65.5642 198.335 55.2509H208.717L195.157 97.8117H183.857C180.069 87.1158 177.137 78.2763 173.903 68.1261H173.78C170.483 78.6056 167.368 88.044 163.945 97.8117H152.525L138.965 55.2509H138.972Z"
        fill={primaryColor}
      />
      <path
        d="M218.133 55.2509H227.235V97.8117H218.133V55.2509Z"
        fill={primaryColor}
      />
      <path
        d="M238.237 55.2509H247.703C254.728 64.5796 261.75 73.6951 268.835 83.5725H268.958V55.2509H277.997V97.8117H268.59C261.565 88.5896 254.543 79.7501 247.518 70.0921H247.335V97.8117H238.234V55.2509H238.237Z"
        fill={primaryColor}
      />
      <path d="M278 27.1928H149.521V29.6167H278V27.1928Z" fill={primaryColor} />
      <path
        d="M81.0554 41.9932C79.9983 42.4071 78.8751 42.614 77.7174 42.614C75.9367 42.614 74.5524 42.0308 73.6054 40.8769C72.6899 39.7668 72.2463 38.0014 72.2463 35.4866V4.08893H64.7587V5.96721H69.6886V35.4866C69.6886 37.2865 69.8647 38.8073 70.214 39.9989C70.5443 41.1371 71.032 42.0527 71.658 42.7238C72.2841 43.3948 73.0486 43.8683 73.9892 44.1756C74.9803 44.4955 76.1286 44.6585 77.409 44.6585C77.9785 44.6585 78.6171 44.5895 79.3061 44.4547C80.0045 44.3167 80.7124 44.1129 81.4077 43.8526C82.1093 43.5924 82.7983 43.2663 83.4621 42.8868C83.8774 42.6485 84.2769 42.3851 84.6513 42.1029L83.6383 40.557C82.7196 41.1967 81.8513 41.6796 81.0554 41.99V41.9932Z"
        fill={secondaryColor}
      />
      <path
        d="M99.2841 41.9932C98.227 42.4071 97.1039 42.614 95.9461 42.614C94.1654 42.614 92.7812 42.0308 91.8342 40.8769C90.9187 39.7668 90.4751 38.0014 90.4751 35.4866V4.08893H82.9874V5.96721H87.9173V35.4866C87.9173 37.2865 88.0935 38.8073 88.4427 39.9989C88.773 41.1371 89.2607 42.0527 89.8867 42.7238C90.5128 43.3948 91.2773 43.8683 92.218 44.1756C93.209 44.4955 94.3573 44.6585 95.6378 44.6585C96.2072 44.6585 96.8459 44.5895 97.5349 44.4547C98.2333 44.3167 98.9412 44.1129 99.6364 43.8526C100.338 43.5924 101.027 43.2663 101.691 42.8868C102.106 42.6485 102.506 42.3851 102.88 42.1029L101.867 40.557C100.948 41.1967 100.08 41.6796 99.2841 41.99V41.9932Z"
        fill={secondaryColor}
      />
      <path
        d="M0 0H2.57662V20.266H2.68987C5.15325 15.1297 9.18652 13.0633 13.7798 13.0633C19.1564 13.0633 25.2064 15.8541 25.2064 25.4555V44.6617H22.6297V26.5719C22.6297 17.8076 17.8131 15.3492 13.3865 15.3492C6.21662 15.3492 2.57662 21.8809 2.57662 27.13V44.6585H0V0Z"
        fill={secondaryColor}
      />
      <path
        d="M32.4769 28.4157C32.4769 18.8142 38.7502 12.1132 47.4868 12.1132C52.7534 12.1132 61.3233 14.5716 61.3233 27.1865V28.6916H35.2769C35.2769 36.8977 40.3736 42.3695 47.6001 42.3695C53.7066 42.3695 57.6833 38.3496 59.53 33.6617L61.8267 34.3892C59.4168 41.087 53.8734 44.6617 47.4334 44.6617C38.8068 44.6617 32.4769 38.0736 32.4769 28.4157ZM58.4667 26.1266C58.4667 17.3624 52.4734 14.4023 47.3767 14.4023C40.8801 14.4023 36.2868 19.203 35.3902 26.1266H58.4667Z"
        fill={secondaryColor}
      />
      <path
        d="M104.028 28.4157C104.028 18.8142 110.302 12.1132 119.318 12.1132C128.335 12.1132 134.555 18.8142 134.555 28.4157C134.555 38.0172 128.281 44.6617 119.318 44.6617C110.355 44.6617 104.028 37.9639 104.028 28.4157ZM119.318 42.3727C126.712 42.3727 131.752 36.6218 131.752 28.4157C131.752 20.2096 126.712 14.4023 119.318 14.4023C111.925 14.4023 106.828 20.2096 106.828 28.4157C106.828 36.6218 111.925 42.3727 119.318 42.3727Z"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default SecondaryLogo;
