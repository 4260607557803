import { SpotIconProps } from './icon-entities';
const Group1Filled = ({
  style,
  className,
  width = 359,
  height = 330,
}: SpotIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 359 330"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M191.512 291.732C195.379 293.111 197.272 288.425 196.853 285.281C190.981 265.347 226.369 254.16 236.918 239.976C264.756 202.551 244.511 182.244 210.605 216.311C199.354 227.617 198.807 274.309 179.43 265.201C171.062 260.237 167.094 251.804 159.282 248.409C136.26 239.758 109.811 250.379 118.563 279.338C121.915 289.163 142.24 286.23 139.267 297.9C137.575 304.065 130.096 306.399 128.324 312.408C119.981 340.691 150.49 326.694 158.623 316.525C161.826 312.519 168.404 303.151 170.07 301.754C175.98 296.803 181.77 282.788 190.065 291.037C189.346 290.653 191.241 291.646 191.517 291.729L191.512 291.732Z"
        fill="currentColor"
      />
      <path
        d="M0.330223 184.555C1.07236 187.374 2.81916 189.824 5.1743 191.539C25.3155 206.201 52.666 195.778 76.0786 201.461C104.626 208.391 139.021 225.003 163.86 200.882C190.542 174.973 157.045 148.194 128.573 159.626C106.519 168.484 89.1443 152.246 68.8466 147.742C56.3085 144.752 49.6798 153.415 56.5155 164.45C63.2629 180.297 39.7038 182.26 28.9504 180.598C20.3982 179.274 -3.04726 171.742 0.330223 184.555Z"
        fill="currentColor"
      />
      <path
        d="M0.811745 274.039C0.840483 258.162 9.29429 243.084 23.9627 256.669C28.9368 248.524 36.6818 242.28 44.1297 236.424C66.9167 218.503 104.669 230.534 86.5448 261.654C77.9785 274.221 67.5896 286.798 54.6407 295.72C23.0671 317.48 0.730321 317.351 0.811745 274.039Z"
        fill="currentColor"
      />
      <path
        d="M11.5417 72.5231C19.1802 68.1535 27.634 70.9874 35.3709 69.1588C42.7948 67.4034 43.34 58.7045 46.2555 52.6805C54.6084 36.4927 78.0261 36.5054 90.3193 48.1972C107.298 64.6654 89.7614 86.8545 100.439 89.9157C120.068 95.5432 134.953 70.1287 155.287 67.1659C171.654 64.7816 185.979 76.2739 168.844 88.476C159.583 93.9696 148.024 96.2807 137.213 96.018C133.969 95.9397 130.544 96.8844 127.87 98.7383C126.656 99.5794 125.2 101.789 124.612 103.194C119.947 114.36 125.551 130.217 107.573 134.001C94.9412 137.416 81.2925 128.82 75.893 118.033C72.8816 112.014 75.108 104.338 71.3342 98.4782C71.9325 99.1121 69.3754 96.1974 69.8853 96.796C57.1831 87.0389 41.9314 97.8543 27.851 96.8137C14.8359 95.8539 -8.06685 83.7452 11.5417 72.5282V72.5231Z"
        fill="currentColor"
      />
      <path
        d="M222.26 134.403C231.461 108.804 207.765 88.0493 197.933 66.3932C190.636 50.3266 187.092 30.4183 193.695 14.031C200.084 -1.82842 223.179 -3.85916 233.238 10.3509C254.283 40.077 245.039 76.2513 233.733 107.071C221.139 137.557 244.249 137.588 266.427 128.55C288.287 119.642 349.975 112.289 357.844 138.479C367.683 171.241 311.415 172.671 287.527 175.538C283.259 177.801 285.728 184.56 281.954 187.086C265.453 198.138 240.031 166.42 228.305 154.781C225.935 152.593 222.083 151.601 219.18 151.202C209.373 151.022 200.826 161.567 191.257 157.67C183.285 152.904 187.167 136.585 197.161 138.068C205.415 140.199 217.946 145.304 222.257 134.398L222.26 134.403Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Group1Filled;
