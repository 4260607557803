import notification from 'antd/es/notification';
import { ReactNode } from 'react';

export interface CopyConfig {
  text?: string;
  onCopy?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  icon?: React.ReactNode;
  successMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
}

const Copy = ({
  text,
  successMessage = 'Text copied to clipboard',
  errorMessage = 'Could not copy text',
}: {
  text: string;
  successMessage?: ReactNode;
  errorMessage?: ReactNode;
}) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => {
        notification.success({ message: successMessage });
      },
      (err) => {
        notification.error({ message: errorMessage });
      },
    );
  } else {
    // Fallback for older browsers
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      notification.success({ message: successMessage });
    } catch (err) {
      notification.error({ message: errorMessage });
    }
    document.body.removeChild(textArea);
  }
};

const copyToClipboard = (props: string | CopyConfig) => {
  if (typeof props === 'string') {
    Copy({ text: props });
  } else {
    Copy({
      text: props.text ?? '',
      errorMessage: props.errorMessage,
      successMessage: props.successMessage,
    });
    if (!!props.onCopy) props.onCopy();
  }
};

export default copyToClipboard;
