import { useBreadcrumbs } from '../../../../features/providers/breadcrumbs-provider';
import { usePlatform } from '../../../../features/providers/platform-provider';
import BreadcrumbsContent from './breadcrumbs-content';

const Breadcrumbs = () => {
  const { breadCrumbs } = useBreadcrumbs();
  const { app } = usePlatform();
  return (
    <BreadcrumbsContent
      breadcrumbs={breadCrumbs}
      size={app === 'admin' ? 'large' : 'medium'}
    />
  );
};

export default Breadcrumbs;
