import TeamOutlined from '@ant-design/icons/TeamOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Company, Contact } from '@hellodarwin/core/lib/features/entities';
import { getFormattedDate } from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Card from 'antd/es/card';
import Skeleton from 'antd/es/skeleton';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/index';
import {
  fetchCompanyQuickInfos,
  selectCompanyCardIsLoading,
  selectCompanyHeaderById,
} from '../../features/api/slices/users-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
const { Title } = Typography;

type CompanyCardProps = {
  company: Company;
  contact?: Contact;
  className?: string;
  isCompanyPage?: boolean;
};

const CompanyCard = ({
  company,
  contact,
  className,
  isCompanyPage,
}: CompanyCardProps) => {
  const navigate = useNavigate();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const quickInfos = useAppSelector((state) =>
    selectCompanyHeaderById(state, company.company_id),
  );
  const loading = useAppSelector(selectCompanyCardIsLoading);

  useEffect(() => {
    if (company.company_id) {
      dispatch(fetchCompanyQuickInfos({ api, companyId: company.company_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.company_id]);

  const accessCompany = () => {
    navigate(`/companies/${company.company_id}`);
  };

  if (loading)
    return (
      <Card style={{ padding: '1rem' }}>
        <Skeleton paragraph={{ rows: 2 }} active></Skeleton>
      </Card>
    );
  return (
    <Card
      hoverable
      className={`client-card ${className || ''}${
        isCompanyPage ? ' on-client-page' : ''
      }`}
      onDoubleClick={accessCompany}
      bordered
      style={{ width: '100% ' }}
    >
      <Div flex="row" align="center" justify="space-between">
        <Title level={4} elementTheme="h6">
          {company.name}
        </Title>
      </Div>
      <Div justify="space-between" tablet={{ flex: 'column' }} flex="row">
        <Typography textAlign={'left'}>
          {quickInfos && (
            <Div flex={'row'} gap={8} tablet={{ flex: 'column' }}>
              <Typography elementTheme="caption">
                {t('companyAdminPage|numberRfpSubmitted')}
              </Typography>
              <Typography elementTheme="caption" bold>
                {quickInfos.rfps_count}
              </Typography>
            </Div>
          )}
          {company.created_at && (
            <Div flex={'row'} gap={8} tablet={{ flex: 'column' }}>
              <Typography elementTheme="caption">
                {t('companyAdminPage|clientSince')}
              </Typography>
              <Typography elementTheme="caption" bold>
                {getFormattedDate(company.created_at)}
              </Typography>
            </Div>
          )}
          {quickInfos && (
            <Div flex={'row'} gap={8} tablet={{ flex: 'column' }}>
              <Typography elementTheme="caption">
                {t('companyAdminPage|lastActivatedRfp')}
              </Typography>
              <Typography elementTheme="caption" bold>
                {quickInfos.latest_activated_rfp}
              </Typography>
            </Div>
          )}
          {contact?.last_login_at && (
            <Div flex={'row'} gap={8} tablet={{ flex: 'column' }}>
              <Typography elementTheme="caption">
                {t('companyAdminPage|lastLogin')}
              </Typography>
              <Typography elementTheme="caption" bold>
                {getFormattedDate(contact?.last_login_at)}
              </Typography>
            </Div>
          )}
        </Typography>
        <Typography textAlign="right">
          <Div
            flex={'row'}
            gap={8}
            justify={'flex-end'}
            tablet={{ flex: 'column' }}
          >
            <Typography elementTheme="caption">
              {t('companyAdminPage|phoneNumber')}
            </Typography>
            <Typography elementTheme="caption">
              <a href={`tel:${company.phone}`}>{company.phone}</a>
            </Typography>
          </Div>
          <Div
            flex={'row'}
            gap={8}
            justify={'flex-end'}
            tablet={{ flex: 'column' }}
          >
            <Typography elementTheme="caption" ellipsis>
              {t('companyAdminPage|companyEmail')}
            </Typography>
            <Typography elementTheme="caption" bold ellipsis>
              {company.email}
            </Typography>
          </Div>
          <Div
            flex={'row'}
            gap={8}
            justify={'flex-end'}
            tablet={{ flex: 'column' }}
          >
            {company.size && (
              <>
                {' '}
                <TeamOutlined />
                <Typography elementTheme="caption" bold>
                  {company.size}
                </Typography>
              </>
            )}
            <Typography elementTheme="caption">
              {t('companyAdminPage|website')}
            </Typography>
            <Typography elementTheme="caption" bold>
              {company.website}
            </Typography>
          </Div>
          {company.industry && (
            <Div flex={'row'} gap={8} justify={'flex-end'}>
              <Typography elementTheme="caption">
                {t('companyAdminPage|companyIndustry')}
              </Typography>
              <Typography elementTheme="caption" bold>
                {company.industry}
              </Typography>
            </Div>
          )}
        </Typography>
      </Div>
    </Card>
  );
};

export default CompanyCard;
