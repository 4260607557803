import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';
const ZoomOut = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4001 30.3999L22.0853 22.0851M22.0853 22.0851C24.3357 19.8347 25.6 16.7825 25.6 13.5999C25.6 10.4173 24.3357 7.36507 22.0853 5.11464C19.8349 2.86422 16.7826 1.59995 13.6001 1.59995C10.4175 1.59995 7.36525 2.86422 5.11483 5.11464C2.8644 7.36507 1.60013 10.4173 1.60013 13.5999C1.60013 16.7825 2.8644 19.8347 5.11483 22.0851C7.36525 24.3355 10.4175 25.5998 13.6001 25.5998C16.7826 25.5998 19.8349 24.3355 22.0853 22.0851ZM18.4006 13.6007H8.80107"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ZoomOut;
