import Container from '@hellodarwin/core/lib/components/common/container';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import FilesList from '@hellodarwin/core/lib/components/files/files-list';
import {
  AssetEntity,
  AssetItemType,
  AssetRecordInformation,
} from '@hellodarwin/core/lib/features/entities/assets-entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app';
import {
  downloadAsset,
  fetchFoldersFromOwner,
  selectAllFolders,
  selectFolderById,
  uploadAsset,
} from '../features/api/slices/assets-slice';
import { selectAuthentifiedUser } from '../features/api/slices/global-slice';
import { useNewAdminApi } from '../features/api/use-admin-api';

const AdminFileVault = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { folderId } = useParams<{ folderId: string }>();
  const folder = useAppSelector((state) =>
    selectFolderById(state, folderId ?? ''),
  );

  const admin = useAppSelector(selectAuthentifiedUser);

  const folders = useAppSelector(selectAllFolders);
  const api = useNewAdminApi();

  useEffect(() => {
    dispatch(
      fetchFoldersFromOwner({
        api,
        owner: { owner_type: 'admin', owner_id: admin.admin_id },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async (asset: AssetEntity) => {
    try {
      const url = (
        await dispatch(
          downloadAsset({ api, asset_id: asset.asset_id }),
        ).unwrap()
      ).link;
      window.open(url, 'download');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData,
  ): Promise<'rejected' | 'fulfilled'> =>
    (
      await dispatch(
        uploadAsset({
          api,
          formData,
        }),
      )
    ).meta.requestStatus;

  const activeItemType: AssetItemType[] = useMemo(
    () => (!!folder.record_id ? folder?.asset_details : folders),
    [folder, folders],
  );

  const rootRecord: AssetRecordInformation = useMemo(
    () => ({
      record_id: admin.admin_id,
      record_name: admin.first_name,
      record_type: 'admin',
    }),
    [admin],
  );

  const activeRecord: AssetRecordInformation | undefined = useMemo(
    () => (!!folder.record_id ? folder : undefined),
    [folder],
  );

  return (
    <PageLayout
      app="admin"
      title={folder?.record_name ?? t('navigation.fileVault')}
      breadcrumbs={
        folder.record_id
          ? [
              {
                breadcrumbName: t('navigation.home'),
                path: '/',
              },
              {
                breadcrumbName: t('navigation.fileVault'),
                path: '/files-vault',
              },
              {
                breadcrumbName: folder.record_name,
              },
            ]
          : [
              {
                breadcrumbName: t('navigation.home'),
                path: '/',
              },
              {
                breadcrumbName: t('navigation.fileVault'),
              },
            ]
      }
    >
      <Container>
        <FilesList
          items={activeItemType}
          vaultPath={'/files-vault'}
          handleDownload={handleDownload}
          activeRecord={activeRecord}
          rootRecord={rootRecord}
          handleSaveAsset={handleSaveAsset}
          allowUpload
        />
      </Container>
    </PageLayout>
  );
};

export default AdminFileVault;
