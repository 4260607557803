enum RfpDescriptionType {
  Client_Description = 'client_description',
  Project_Description = 'project_description',
  Context = 'context',
  Goals = 'goals',
  Details = 'details',
  Provider_Requirement = 'provider_requirement',
  Budget = 'budget',
  Contact = 'contact',
  Other = 'other',
}
export default RfpDescriptionType;
