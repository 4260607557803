import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useAppSelector } from '../../../app';
import {
  selectGinApplicationCompany,
  selectGinsIsLoading,
} from '../../../features/api/slices/gins-slice';
import { RelatedLink } from './cards/related-link';

interface RelatedApplicationsListProps {
  applicationId: string;
}

const RelatedApplicationsList = ({
  applicationId,
}: RelatedApplicationsListProps) => {
  const company = useAppSelector(selectGinApplicationCompany);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'applicationCompany'),
  );
  const filteredApplications = company.company_applications?.filter(
    (application) => application.application_id !== applicationId,
  );

  const { t } = useTranslations();

  if (isLoading) return <Loading />;
  if (!company.company_applications.length)
    return (
      <Typography elementTheme="subtitle2">
        {t('application_single|no_other_company_applications')}
      </Typography>
    );

  return (
    <Div flex="column" gap={8}>
      {isLoading ? (
        <Div style={{ height: 'fit-content' }}>
          <Loading />
        </Div>
      ) : filteredApplications && filteredApplications.length > 0 ? (
        filteredApplications.map((application, index) => (
          <RelatedLink
            key={index}
            label={application.application_name ?? ''}
            to={`/programs/${application.application_id}`}
          />
        ))
      ) : (
        <Typography>
          {t('application_single|no_other_company_applications')}
        </Typography>
      )}
    </Div>
  );
};

export default RelatedApplicationsList;
