import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import {
  AdminGinContactResponse,
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities';

import { RootState } from '../../../app/app-store';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const ginContactAdapter = createEntityAdapter({
  selectId: (model: AdminGinContactResponse) => model.gin_contact_id,
});

export interface GinContactsState {
  status: 'idle' | 'pending';
  error: ApiError;
  ginContacts: EntityState<AdminGinContactResponse, string>;
}

const initialState: GinContactsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  ginContacts: ginContactAdapter.getInitialState(),
};

export const fetchGinContacts = createAsyncThunk<
  AdminGinContactResponse[],
  { api: AdminApiClient; grantId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchGinContacts',
  async (
    { api, grantId }: { api: AdminApiClient; grantId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchGinContacts(grantId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchGinContactsByGrantProviderId = createAsyncThunk<
  AdminGinContactResponse[],
  { api: AdminApiClient; grantProviderId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchGinContactsByProviderId',
  async (
    { api, grantProviderId }: { api: AdminApiClient; grantProviderId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchGinContactsByGrantProviderId(grantProviderId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateGinContact = createAsyncThunk<
  AdminGinContactResponse,
  {
    api: AdminApiClient;
    ginContact: AdminGinContactResponse;
  },
  { rejectValue: ApiError }
>(
  'admin/updateGinContact',
  async (
    {
      api,
      ginContact,
    }: { api: AdminApiClient; ginContact: AdminGinContactResponse },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateGinContact(ginContact);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createGinContact = createAsyncThunk<
  AdminGinContactResponse,
  {
    api: AdminApiClient;
    ginContact: AdminGinContactResponse;
  },
  { rejectValue: ApiError }
>(
  'admin/createGinContact',
  async (
    {
      api,
      ginContact,
    }: { api: AdminApiClient; ginContact: AdminGinContactResponse },
    { rejectWithValue },
  ) => {
    try {
      return await api.createGinContact(ginContact);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteGinContact = createAsyncThunk<
  string,
  { api: AdminApiClient; ginContactId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteGinContact',
  async (
    { api, ginContactId }: { api: AdminApiClient; ginContactId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteGinContact(ginContactId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const ginContactsSlice = createSlice({
  name: 'ginContacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGinContacts.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchGinContacts.fulfilled, (state, { payload }) => {
      ginContactAdapter.setAll(state.ginContacts, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchGinContacts.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload as ApiError;
    });
    builder.addCase(fetchGinContactsByGrantProviderId.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchGinContactsByGrantProviderId.fulfilled,
      (state, { payload }) => {
        ginContactAdapter.setAll(state.ginContacts, payload);
        state.status = 'idle';
      },
    );
    builder.addCase(
      fetchGinContactsByGrantProviderId.rejected,
      (state, { payload }) => {
        state.status = 'idle';
        state.error = payload as ApiError;
      },
    );
    builder.addCase(updateGinContact.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateGinContact.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      ginContactAdapter.updateOne(state.ginContacts, {
        id: payload.gin_contact_id,
        changes: payload,
      });
    });
    builder.addCase(updateGinContact.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload as ApiError;
    });
    builder.addCase(createGinContact.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload as ApiError;
    });
    builder.addCase(createGinContact.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createGinContact.fulfilled, (state, { payload }) => {
      ginContactAdapter.upsertOne(state.ginContacts, payload);
      state.status = 'idle';
    });
    builder.addCase(deleteGinContact.pending, (state) => {
      state.status = 'pending';
      state.error = ApiErrorInitialState;
    });
    builder.addCase(deleteGinContact.fulfilled, (state, { payload }) => {
      ginContactAdapter.removeOne(state.ginContacts, payload);
      state.status = 'idle';
      state.error = ApiErrorInitialState;
    });
    builder.addCase(deleteGinContact.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload ? payload : ApiErrorInitialState;
    });
  },
});

export const selectGinContactsIsLoading = (state: RootState) =>
  state.ginContacts.status === 'pending';
export const { selectAll: selectGinContacts } = ginContactAdapter.getSelectors(
  (state: RootState) => state.ginContacts.ginContacts,
);

export const ginContactsReducer = ginContactsSlice.reducer;
