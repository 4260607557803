import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Provider } from '@hellodarwin/core/lib/features/entities';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAdminApi } from '../../features/api/use-admin-api';

import './blacklist-form.scss';

const { Title } = Typography;

type BlacklistFormProps = {
  rfpId: string;
};

export interface BlacklistFormValues {
  provider_id: string;
}

const BlacklistForm = ({ rfpId }: BlacklistFormProps) => {
  const api = useAdminApi();
  const [blacklist, setBlacklist] = useState<Provider[]>([]);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    (async () => {
      const newBlacklist = await api.getBlacklistedProviders(rfpId);
      setBlacklist(newBlacklist);
    })();
  }, [api, rfpId]);

  const onFinish = (values: BlacklistFormValues) => {
    (async () => {
      setIsSaving(true);
      try {
        const provider = await api.createBlacklistedProvider(
          rfpId,
          values.provider_id,
        );
        setBlacklist([...blacklist, provider]);
        setIsSaving(false);
        form.resetFields();
        message.success('Saved!');
      } catch (e: any) {
        setIsSaving(false);
        message.error('Something went wrong, try again later!');
        console.error(e);
      }
    })();
  };

  const onDelete = (providerId: string) => {
    (async () => {
      setIsSaving(true);
      try {
        const newBlacklist = [...blacklist];
        const index = newBlacklist.findIndex(
          (p) => p.provider_id === providerId,
        );
        if (index < 0) {
          return;
        }
        await api.deleteBlacklistedProvider(rfpId, providerId);
        newBlacklist.splice(index, 1);
        setBlacklist(newBlacklist);
        message.success('Saved!');
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    })();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} name={'blacklist'}>
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4} elementTheme="h6">
            Blacklist
          </Title>
        </Col>
        {blacklist.length > 0 && (
          <Col span={24}>
            <div className="blacklist-form-list">
              {blacklist.map((provider, i) => (
                <div key={`provider-${i}`} className="blacklist-form-list-item">
                  <Tooltip placement="top" title="see provider">
                    <Link
                      target="_blank"
                      to={`/providers/${provider.provider_id}`}
                    >
                      {provider.name}
                    </Link>
                  </Tooltip>
                  <Tooltip placement="top" title={'delete'}>
                    <DeleteOutlined
                      className="blacklist-form-list-delete"
                      onClick={() => onDelete(provider.provider_id)}
                    />
                  </Tooltip>
                </div>
              ))}
            </div>
          </Col>
        )}
        <Col span={24}>
          <Form.Item
            label="Provider id"
            name="provider_id"
            rules={[{ required: true, message: 'provider id is required' }]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item style={{ margin: 0 }} shouldUpdate={true}>
            {() => (
              <Button block type="primary" htmlType="submit" loading={isSaving}>
                Blacklist
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BlacklistForm;
