import {
  ApiError,
  ApiErrorInitialState,
  ReviewResult,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/index';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const providerReviewsAdapter = createEntityAdapter({
  selectId: (model: ReviewResult) => model?.provider_id || '',
});

export interface ReviewsState {
  status: 'idle' | 'pending';
  error: ApiError;
  providerReviews: EntityState<ReviewResult, string>;
}

const initialState: ReviewsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  providerReviews: providerReviewsAdapter.getInitialState(),
};

export const fetchReviewsById = createAsyncThunk<
  ReviewResult[],
  { api: AdminApiClient; provider_id: string },
  { rejectValue: ApiError }
>(
  'client/fetchReviewsById',
  async (
    { api, provider_id }: { api: AdminApiClient; provider_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchReviewsById(provider_id);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReviewsById.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchReviewsById.fulfilled, (state, { payload }) => {
      providerReviewsAdapter.addMany(state.providerReviews, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchReviewsById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const {
  selectAll: selectProviderReviews,
  selectById: selectProviderReviewsById,
} = providerReviewsAdapter.getSelectors(
  (state: RootState) => state.reviews.providerReviews,
);

export const reviewsReducer = reviewsSlice.reducer;
