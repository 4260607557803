import { Rfp } from '../entities/rfp-entities';
import RfpStatus from '../enums/rfp-status';

const sortByStatus = (p1: Rfp, p2: Rfp) => {
  const map = new Map<RfpStatus, number>([
    [RfpStatus.PendingApproval, 1],
    [RfpStatus.Active, 2],
    [RfpStatus.EvaluateFinalists, 3],
    [RfpStatus.FilterCandidates, 4],
    [RfpStatus.Completed, 5],
    [RfpStatus.Canceled, 6],
    [RfpStatus.Rejected, 7],
  ]);
  const value1 = map.get(p1.status);
  const value2 = map.get(p2.status);

  if (!value1 || !value2) {
    return 0;
  }

  if (value1 < value2) {
    return -1;
  }
  if (value1 > value2) {
    return 1;
  }

  return 0;
};

export const sortByService = (p1: Rfp, p2: Rfp) => {
  if (!p1?.service || !p2?.service) return 0;
  return p1.service.localeCompare(p2?.service);
};

export const sortByCreatedDate = (p1: Rfp, p2: Rfp) => {
  if (!p1?.created_at || !p2?.created_at) return 0;
  return p2.created_at > p1.created_at ? 1 : -1;
};
export const sortByUpdatedDate = (p1: Rfp, p2: Rfp) => {
  if (!p1?.updated_at || !p2?.updated_at) return 0;
  return p2.updated_at > p1.updated_at ? 1 : -1;
};

const filterRfps = (rfps: Rfp[], sortType: string, orderType: string) => {
  let filtered = rfps;
  switch (sortType) {
    case 'status':
      filtered = rfps.sort(sortByStatus);
      break;
    case 'service':
      filtered = rfps.sort(sortByService);
      break;
    case 'recent':
      filtered = rfps.sort(sortByCreatedDate);
      break;
  }

  switch (orderType) {
    case 'ascending':
      break;
    case 'descending':
      filtered = filtered.reverse();
      break;
  }

  return filtered;
};

export default filterRfps;
