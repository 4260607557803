import { Contact } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import notification from 'antd/es/notification';
import Select from 'antd/es/select';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app';
import { selectAuthentifiedUser } from '../../../features/api/slices/global-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import useDebounce from '../../../hooks/use-debounce';
import { LeadSourceType } from '../rfp-form';

const SEARCH_DELAY_MS = 500;
export const EMAIL_ALREADY_EXISTS_ERROR = 5023;

type CreateRfpFormProps = {
  visible: boolean;
  handleClose: () => void;
};

const CreateRfpForm = ({ visible, handleClose }: CreateRfpFormProps) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const { t } = useTranslations();
  const api = useAdminApi();
  const navigate = useNavigate();
  const admin = useAppSelector(selectAuthentifiedUser);
  const parseMinAndMaxBudget = (budgetRange: string): number[] => {
    const budgetArr = budgetRange.split('-');
    return budgetArr.map((b) => Number(b));
  };
  const onFinish = (values: any) => {
    (async () => {
      setIsSaving(true);
      try {
        const budgetArr = values.budget_range
          ? parseMinAndMaxBudget(values.budget_range)
          : [0, 0];

        const newRfp = {
          ...selectedContact,
          ...values,
          budget_min: budgetArr[0],
          budget_max: budgetArr[1],
          account_manager: `${admin?.admin_id}`,
        };

        const rfp = await api.createRfp(newRfp);
        setIsSaving(false);
        handleClose();
        navigate('/projects/' + rfp.rfp_id);
        message.success(t('createRfp|saved'));
      } catch (e: any) {
        setIsSaving(false);

        if (e.response.data.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
          notification.error({
            message: t('createRfp|emailExists'),
            description: t('createRfp|emailExistsDescription'),
            placement: 'bottomLeft',
          });
          return;
        }

        message.error(t('createRfp|messageError'));
        console.error(e);
      }
    })();
  };

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      setIsSearching(true);
      try {
        const response = await api.queryContacts(1, 8, query);
        setContacts(response);
      } catch (e: any) {
        message.error(t('createRfp|messageError'));
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleChange = (value: string) => {
    if (value === '1') {
      setSelectedContact(null);
      setIsNewContact(true);
      return;
    }
    const contact = contacts.find((c) => c.contact_id === value);
    if (contact) {
      setIsNewContact(false);
      setSelectedContact(contact);
    }
  };

  return (
    <Modal
      title={t('createRfp|create_rfp_title')}
      afterClose={form.resetFields}
      open={visible}
      okText={t('button|button_create')}
      okButtonProps={{ loading: isSaving }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.error('Validate Failed:', info);
          });
      }}
      cancelText={t('button|cancel')}
      onCancel={handleClose}
      keyboard={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-project"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t('createRfp|menu_contact')}
              name="contact_id"
              rules={[
                {
                  required: true,
                  message: t('createRfp|minimum_contacts'),
                },
              ]}
            >
              <Select
                loading={isSearching}
                filterOption={false}
                showSearch
                placeholder={t('createRfp|search_company_contacts')}
                defaultActiveFirstOption={false}
                onChange={handleChange}
                onSearch={handleSearch}
              >
                <Select.Option value="1">
                  {t('createRfp|new_contact')}
                </Select.Option>
                {contacts.map((c) => (
                  <Select.Option key={c.contact_id} value={c.contact_id}>
                    {c.name}/ {c.email}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isNewContact && (
            <>
              <Col span={24}>
                <Form.Item
                  label={t('createRfp|form_name')}
                  name="full_name"
                  rules={[
                    {
                      required: true,
                      message: t('createRfp|missing_fullName'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('createRfp|only_email')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('createRfp|emailRequired'),
                    },
                    {
                      type: 'email',
                      message: t('createRfp|email_valid'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('createRfp|preferredLanguage')}
                  name="project_language"
                  rules={[
                    {
                      required: true,
                      message: t('createRfp|language_required'),
                    },
                  ]}
                >
                  <Select placeholder="Select option">
                    <Select.Option value="fr">Français</Select.Option>
                    <Select.Option value="en">English</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24}>
            <Form.Item
              label="Budget"
              name="budget_range"
              rules={[{ type: 'string' }]}
            >
              <Select placeholder="Select option">
                <Select.Option value={'0-1000'}>0$ - 1000$</Select.Option>
                <Select.Option value={'1000-5000'}>1000$ - 5000$</Select.Option>
                <Select.Option value={'5000-10000'}>
                  5000$ - 10,000$
                </Select.Option>
                <Select.Option value={'10000-25000'}>
                  10,000$ - 25,000$
                </Select.Option>
                <Select.Option value={'25000-50000'}>
                  25,000$ - 50,000$
                </Select.Option>
                <Select.Option value={'50000-100000'}>
                  50,000$ - 100,000$
                </Select.Option>
                <Select.Option value={'100000-250000'}>
                  100,000$ - 250,000${' '}
                </Select.Option>
                <Select.Option value={'250000-500000'}>
                  250,000$ - 500,000${' '}
                </Select.Option>
                <Select.Option value={'500000-750000'}>
                  500,000$ - 750,000${' '}
                </Select.Option>
                <Select.Option value={'750000-1000000'}>
                  750,000$ +{' '}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('createRfp|titleProgram')} name="title">
              <TextArea rows={2} autoSize />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="lead_source"
              rules={[
                {
                  required: true,
                  message: t('createRfp|requiredLeadSource'),
                },
              ]}
            >
              <Select
                showSearch
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                }}
                style={{ width: '200px' }}
              >
                <Select.Option value={LeadSourceType.GrantsPackage}>
                  {LeadSourceType.GrantsPackage}
                </Select.Option>
                <Select.Option value={LeadSourceType.AffiliateSP}>
                  {LeadSourceType.AffiliateSP}
                </Select.Option>
                <Select.Option value={LeadSourceType.ReEngagement}>
                  {LeadSourceType.ReEngagement}
                </Select.Option>
                <Select.Option value={LeadSourceType.OolongMedia}>
                  {LeadSourceType.OolongMedia}
                </Select.Option>
                <Select.Option value={LeadSourceType.RBC}>
                  {LeadSourceType.RBC}
                </Select.Option>
                <Select.Option value={LeadSourceType.NonIcp}>
                  {LeadSourceType.NonIcp}
                </Select.Option>
                <Select.Option value={LeadSourceType.PCAN2}>
                  {LeadSourceType.PCAN2}
                </Select.Option>
                <Select.Option value={LeadSourceType.Unsold}>
                  {LeadSourceType.Unsold}
                </Select.Option>
                <Select.Option value={LeadSourceType.AWQ}>
                  {LeadSourceType.AWQ}
                </Select.Option>
                <Select.Option value={LeadSourceType.ClientApp}>
                  {LeadSourceType.ClientApp}
                </Select.Option>
                <Select.Option value={LeadSourceType.ClientReferral}>
                  {LeadSourceType.ClientReferral}
                </Select.Option>
                <Select.Option value={LeadSourceType.Direct}>
                  {LeadSourceType.Direct}
                </Select.Option>
                <Select.Option value={LeadSourceType.Email}>
                  {LeadSourceType.Email}
                </Select.Option>
                <Select.Option value={LeadSourceType.Facebook}>
                  {LeadSourceType.Facebook}
                </Select.Option>
                <Select.Option value={LeadSourceType.Google}>
                  {LeadSourceType.Google}
                </Select.Option>
                <Select.Option value={LeadSourceType.Organic}>
                  {LeadSourceType.Organic}
                </Select.Option>
                <Select.Option value={LeadSourceType.Other}>
                  {LeadSourceType.Other}
                </Select.Option>
                <Select.Option value={LeadSourceType.ReferenceshD}>
                  {LeadSourceType.ReferenceshD}
                </Select.Option>
                <Select.Option value={LeadSourceType.Tradeshows}>
                  {LeadSourceType.Tradeshows}
                </Select.Option>
                <Select.Option value={LeadSourceType.MCEL}>
                  {LeadSourceType.MCEL}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateRfpForm;
