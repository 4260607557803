import Div from '@hellodarwin/core/lib/components/common/div';
import { Company } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Modal from 'antd/es/modal/Modal';
import { useEffect, useRef } from 'react';
import theme from '../../theme';
import CompaniesSearch from './companies-search';
type AssignCompanyModalProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (company: Company) => void;
};

const AssignCompanyModal = ({
  open,
  handleClose,
  handleSubmit,
}: AssignCompanyModalProps) => {
  const { t } = useTranslation();

  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      inputElement.current?.focus();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      className="assign-company-modal"
    >
      <Div
        style={{ padding: '1rem 2rem' }}
        borderColor={theme.colors.white_2}
        bordersSide="bottom"
      >
        {t('rfp|company.assign')}
      </Div>
      <CompaniesSearch handleSubmit={handleSubmit} />
    </Modal>
  );
};
export default AssignCompanyModal;
