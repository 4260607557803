import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const RfpIcon = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);
  return (
    <svg
      {...iconProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M12.6732 11.4993H24.6732M7.59318 11.4093L8.42318 12.1793L10.0232 10.8193M12.6732 17.9993H24.6732M7.59318 17.9093L8.42318 18.6793L10.0232 17.3193M12.6732 24.4993H24.6732M7.59318 24.4093L8.42318 25.1793L10.0232 23.8193M22.5232 4.38926C22.6332 5.83926 22.4832 6.96926 20.8832 6.84926C18.5232 6.84926 13.3932 6.88926 11.4632 6.81926C10.1832 6.74926 10.1932 5.55926 10.1932 4.38926M22.5232 4.38926C25.6232 4.32926 27.7332 4.63926 27.5832 7.92926C27.6032 13.8193 27.6032 20.9493 27.5832 26.9693C27.5632 29.5193 27.2532 29.9493 24.8832 29.9893C22.2832 30.0193 19.6032 29.9993 16.9232 29.9993C13.9032 29.9893 9.69318 30.0193 7.12318 29.9893C4.86318 30.0493 4.61318 29.7193 4.65318 27.5393C4.63318 23.5093 4.42318 13.1593 4.42318 8.29926C4.15318 4.38926 6.46318 4.33926 10.1932 4.38926M22.5232 4.38926C22.5332 2.75926 21.8732 1.96926 20.2832 2.00926C18.2232 1.98926 14.4932 1.98926 12.4332 2.00926C10.8532 1.96926 10.1732 2.74926 10.1932 4.38926"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8211 21.7917C17.5237 21.7917 18.1575 21.3704 18.5917 20.7717C19.2684 19.8363 20.1179 19.0643 21.0859 18.505C21.7162 18.1423 22.2628 17.6021 22.527 16.8853C22.7125 16.3831 22.8078 15.8471 22.8077 15.3062V14.7083C22.8077 14.5205 22.8766 14.3403 22.9992 14.2075C23.1218 14.0746 23.2881 14 23.4615 14C23.9818 14 24.4807 14.2239 24.8485 14.6224C25.2164 15.0209 25.4231 15.5614 25.4231 16.125C25.4231 17.213 25.1964 18.2434 24.7928 19.1642C24.5609 19.6912 24.886 20.375 25.4248 20.375H28.15C29.0445 20.375 29.8457 21.0304 29.9407 21.9947C29.9799 22.3933 30 22.7975 30 23.2083C30.0036 25.7927 29.1884 28.3 27.6906 30.3115C27.3523 30.7667 26.8301 31 26.2914 31H22.7903C22.3692 31 21.9498 30.9263 21.5497 30.7828L18.8349 29.8006C18.4349 29.6564 18.016 29.5831 17.5944 29.5833H16.1855M23.4615 20.375H25.4231ZM16.1855 29.5833C16.2579 29.7769 16.3364 29.9658 16.4209 30.1519C16.5927 30.5297 16.3529 31 15.965 31H15.1734C14.3984 31 13.68 30.5108 13.4542 29.708C13.1523 28.6344 12.9992 27.5182 13 26.3958C13 24.9291 13.2572 23.5285 13.7245 22.2422C13.9912 21.5112 14.6712 21.0833 15.3974 21.0833H16.3154C16.7269 21.0833 16.9649 21.6084 16.7513 21.99C16.0067 23.3178 15.614 24.8422 15.6163 26.3958C15.6163 27.5235 15.8185 28.5992 16.1864 29.5833H16.1855Z"
        fill="white"
      />
      <path
        d="M16.8211 21.7917C17.5237 21.7917 18.1575 21.3704 18.5917 20.7717C19.2684 19.8363 20.1179 19.0643 21.0859 18.505C21.7162 18.1423 22.2628 17.6021 22.527 16.8853C22.7125 16.3831 22.8078 15.8471 22.8077 15.3062V14.7083C22.8077 14.5205 22.8766 14.3403 22.9992 14.2075C23.1218 14.0746 23.2881 14 23.4615 14C23.9818 14 24.4807 14.2239 24.8485 14.6224C25.2164 15.0209 25.4231 15.5614 25.4231 16.125C25.4231 17.213 25.1964 18.2434 24.7928 19.1642C24.5609 19.6912 24.886 20.375 25.4248 20.375H28.15C29.0445 20.375 29.8457 21.0304 29.9407 21.9947C29.9799 22.3933 30 22.7975 30 23.2083C30.0036 25.7927 29.1884 28.3 27.6906 30.3115C27.3523 30.7667 26.8301 31 26.2914 31H22.7903C22.3692 31 21.9498 30.9263 21.5497 30.7828L18.8349 29.8006C18.4349 29.6564 18.016 29.5831 17.5944 29.5833H16.1855M16.1855 29.5833C16.2579 29.7769 16.3364 29.9658 16.4209 30.1519C16.5927 30.5297 16.3529 31 15.965 31H15.1734C14.3984 31 13.68 30.5108 13.4542 29.708C13.1523 28.6344 12.9992 27.5182 13 26.3958C13 24.9291 13.2572 23.5285 13.7245 22.2422C13.9912 21.5112 14.6712 21.0833 15.3974 21.0833H16.3154C16.7269 21.0833 16.9649 21.6084 16.7513 21.99C16.0067 23.3178 15.614 24.8422 15.6163 26.3958C15.6163 27.5235 15.8185 28.5992 16.1864 29.5833H16.1855ZM23.4615 20.375H25.4231"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RfpIcon;
