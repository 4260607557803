import Progress from 'antd/es/progress';
import { useMediaQuery } from 'react-responsive';
import { FormViewerType } from '.';
import { TranslatedFormResponse } from '../../../features/entities/form-entities';
import theme from '../../../theme';
import Div from '../../common/div';
import Typography from '../../common/typography';

const FormHeader = ({
  formResponse,
  isEndPage,
  isOpening,
  type,
  progress,
}: {
  formResponse: TranslatedFormResponse;
  isEndPage: boolean;
  isOpening: boolean;
  type: FormViewerType;
  progress: number;
}) => {
  if (isEndPage || isOpening) return <></>;
  if (type === 'modal') {
    return (
      <Progress
        percent={progress}
        type="circle"
        strokeColor={theme.colors.green_1}
        size="small"
        strokeWidth={12}
        trailColor={theme.colors.grey_4}
        style={{
          position: 'absolute',
          zIndex: 10,
          right: 128 - 20,
          top:
            54 - 30 + parseInt(theme.fontsLineHeights.h6.replace('px', '')) / 2,
        }}
      />
    );
  }

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return (
    <Div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
      tablet={{ style: { flexDirection: 'column' } }}
    >
      <Div
        flex="column"
        gap={8}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          textAlign: 'center',
        }}
        tablet={{ style: { padding: '0 24px', position: 'static' } }}
      >
        <Typography.Title level={1} elementTheme={'h4'} textAlign="center">
          {formResponse.title}
        </Typography.Title>
        <Typography elementTheme={'body1'} textAlign="center">
          {formResponse.subtitle}
        </Typography>
      </Div>
      <Progress
        style={{ padding: isTablet ? '0px 24px' : 0 }}
        strokeWidth={14}
        percent={progress}
        type={isTablet ? 'line' : 'circle'}
        percentPosition={isTablet ? { align: 'end', type: 'inner' } : {}}
        strokeColor={theme.colors.green_1}
        trailColor={theme.colors.grey_4}
      />
    </Div>
  );
};

export default FormHeader;
