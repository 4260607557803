import Steps from 'antd/es/steps';
import { useRef, useState } from 'react';
import { ReviewForms } from '../../../../features/entities/form-entities';
import {
  Review,
  ReviewRequest,
  ReviewStatus,
} from '../../../../features/entities/reviews-entities';
import { ReviewFormSteps } from '../../../../features/entities/rfp-status-flow-entities';
import { MappedParseTags } from '../../../../features/entities/tags-entities';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Spin from '../../../common/spin';
import ReviewFlowLayout from '../../common/review-flow-layout';
import ReviewConfirm from './review-confirm';
import ReviewContact from './review-contact';
import ReviewExperience from './review-experience';

type ReviewFormProps = {
  providerName: string;
  providerId: string;
  rfpId?: string;
  handleClose: () => void;
  reviewProvider: (reviewRequest: ReviewRequest) => Promise<void>;
  reviewStep: ReviewFormSteps;
  setReviewStep: (reviewStep: ReviewFormSteps) => void;
  tags: MappedParseTags;
  companyTags: string[];
};

const ReviewFormHeader = ({
  rfpId,
  reviewStep,
}: {
  rfpId?: string;
  reviewStep: ReviewFormSteps;
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  if (rfpId || isTablet) return <></>;

  return (
    <div style={{ marginBottom: 24 }}>
      <Steps
        style={{
          width: '100%',
        }}
        size="small"
        current={reviewStep == ReviewFormSteps.Experience ? 0 : 1}
        items={[
          {
            title: t('reviews|newReviewStepsStep1'),
          },
          {
            title: t('reviews|newReviewStepsStep2'),
          },
        ]}
      />
    </div>
  );
};

const ReviewForm = ({
  providerName,
  providerId,
  rfpId,
  handleClose,
  reviewProvider,
  reviewStep,
  setReviewStep,
  tags,
  companyTags,
}: ReviewFormProps) => {
  const { t } = useTranslations();

  const ref = useRef<HTMLDivElement>(null);

  const [forms, setForms] = useState<ReviewForms>({
    experienceForm: undefined,
    contactForm: undefined,
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      const [experienceFormValues, contactFromValues] = await Promise.all([
        forms.experienceForm?.validateFields(),
        forms.contactForm?.validateFields(),
      ]);

      setSubmitLoading(true);

      const review: Review = {
        review_id: '',
        provider_id: providerId,
        rfp_id: rfpId,
        description_summary: experienceFormValues?.summary,
        rating_collaboration: experienceFormValues?.relationship,
        rating_quality: experienceFormValues?.quality,
        recommend: experienceFormValues?.recommend,
        status: ReviewStatus.Public,
        locale: 'fr',
        service: experienceFormValues?.service,
        verified: 'Unverified',
        review_source: 'Client App',
      };

      const reviewRequest: ReviewRequest = {
        review: review,
        contact_name: contactFromValues?.contact_name,
        contact_email: contactFromValues?.contact_email,
        contact_title: contactFromValues?.contact_title,
        company_name: contactFromValues?.company_name,
        company_industry: contactFromValues?.company_industry,
        company_size: contactFromValues?.company_size,
        terms: 'Agree',
      };

      await reviewProvider(reviewRequest);

      setReviewStep(ReviewFormSteps.Confirm);
      setSubmitLoading(false);
    } catch (e: any) {
      console.error(e);
      setSubmitLoading(false);
    }
  };
  const theme = useTheme();

  const isFromWebsite = !rfpId;

  return reviewStep === ReviewFormSteps.Confirm ? (
    <ReviewConfirm handleClose={handleClose} isActive={true} />
  ) : (
    <ReviewFlowLayout
      title={<>{t('reviews|newReviewRatingHeading', { providerName })}</>}
      description={
        reviewStep === ReviewFormSteps.Contact
          ? t('reviews|newReviewRatingSubheadingContact')
          : t('reviews|newReviewRatingSubheading')
      }
    >
      <div ref={ref}>
        {submitLoading ? (
          <Spin />
        ) : (
          <>
            <ReviewFormHeader rfpId={rfpId} reviewStep={reviewStep} />
            <ReviewExperience
              providerName={providerName}
              experiences={forms?.experienceForm?.getFieldsValue(true)}
              setForms={setForms}
              setFlowStep={setReviewStep}
              isActive={reviewStep == ReviewFormSteps.Experience}
              tags={tags}
              isFromWebsite={isFromWebsite}
              handleSubmit={handleSubmit}
            />
            {isFromWebsite && (
              <ReviewContact
                contactInfo={forms?.contactForm?.getFieldsValue(true)}
                setForms={setForms}
                setFlowStep={setReviewStep}
                isActive={reviewStep == ReviewFormSteps.Contact}
                handleSubmit={handleSubmit}
                isSubmit={true}
                companyTags={companyTags}
              />
            )}
          </>
        )}
      </div>
    </ReviewFlowLayout>
  );
};

export default ReviewForm;
