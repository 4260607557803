import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Loading from '@hellodarwin/core/lib/components/loading';
import { GrantResult } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import Search from '@hellodarwin/icons/dist/icons/Search';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  searchGrants,
  selectAllSearchedGrants,
  selectIsLoadingSoloGen,
} from '../../../features/api/slices/grants-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import useDebounce from '../../../hooks/use-debounce';
import theme from '../../../theme';
import { GrantSearchDropdown, GrantsSearchInput } from './styles';

interface GrantsSearchProps {
  handleSubmit: (grant: GrantResult) => void;
  noStyle?: boolean;
}

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const GrantsSearch: React.FC<GrantsSearchProps> = ({
  handleSubmit,
  noStyle,
}) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedLocale } = useLocale();
  const grants = useAppSelector(selectAllSearchedGrants);
  const isLoading = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, 'search-grants'),
  );
  const [isActive, setIsActive] = useState(false);

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      dispatch(
        searchGrants({
          api,
          page: DEFAULT_PAGE,
          limit: DEFAULT_LIMIT,
          query,
          locale: selectedLocale,
        }),
      );
    })();
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleResultClick = (grant: GrantResult) => {
    handleSubmit(grant);
    clearSearch();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleFocus = () => {
    setIsActive(true);
  };
  const handleBlur = () => {
    setTimeout(() => setIsActive(false), 500);
  };
  return (
    <div style={{ position: 'relative' }}>
      <GrantsSearchInput
        prefix={<Search size={24} />}
        variant={!noStyle ? 'borderless' : undefined}
        value={searchTerm}
        placeholder="Search... (grant title, description)"
        onChange={(e) => {
          e.stopPropagation();
          setSearchTerm(e.target.value);
        }}
        size="large"
        onFocus={handleFocus}
        onBlur={handleBlur}
        suffix={isLoading ? <Loading size="small" /> : null}
        onClear={clearSearch}
        allowClear
        $noStyle={noStyle}
      />

      {isActive && !!grants.length && !isLoading && (
        <GrantSearchDropdown>
          {grants?.map((grant) => (
            <HdTag
              text={
                grant.grant_display_title
                  ? grant.grant_display_title.length > 50
                    ? `${grant.grant_display_title?.substring(0, 50)}...`
                    : grant.grant_display_title
                  : ''
              }
              hoverable
              color={theme.colors.purple_2}
              onClick={(e) => {
                e.stopPropagation();
                handleResultClick(grant);
              }}
            />
          ))}
        </GrantSearchDropdown>
      )}
    </div>
  );
};

export default GrantsSearch;
