import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';

const validateWebsite =
  ({
    message,
    isAdmin,
    isRequired,
  }: {
    message: string;
    isAdmin?: boolean;
    isRequired?: boolean;
  }) =>
  (_: RuleObject, value: StoreValue) => {
    if (!isRequired) return Promise.resolve();
    if ((!value || value?.length === 0) && isAdmin) return Promise.resolve();

    // regex from https://regexr.com/3e6m0
    const urlRegex = new RegExp(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    );
    return urlRegex.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error(message));
  };

export default validateWebsite;
