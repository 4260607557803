import { Step, StepAsset } from '@hellodarwin/core/lib/features/entities';
import React, { useMemo } from 'react';
import GinStepCardContentAdmin from './card-content-admin';
import GinStepCardContentClient from './card-content-client';

interface GinStepCardContentProps extends Step {
  isClientView?: boolean;
  handleOpenAsset: (asset: StepAsset) => void;
}

const GinStepCardContent: React.FC<GinStepCardContentProps> = ({
  isClientView,
  handleOpenAsset,
  ...step
}) => {
  const Component = useMemo(
    () => (isClientView ? GinStepCardContentClient : GinStepCardContentAdmin),
    [isClientView],
  );

  return <Component handleOpenAsset={handleOpenAsset} {...step} />;
};

export default GinStepCardContent;
