import { TableProps } from 'antd/es/table';
import { ColumnsType, RefTable } from 'antd/es/table/interface';
import { TableStyled } from './styles';

const Table: RefTable = <RecordType,>(props: TableProps<RecordType>) => {
  return <TableStyled<RecordType> {...props} />;
};

export default Table;

export { ColumnsType, TableProps };
