import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Informations = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8333 14.8333L14.8971 14.8022C15.0966 14.7026 15.3204 14.6622 15.5421 14.6858C15.7639 14.7094 15.9742 14.7961 16.1482 14.9356C16.3221 15.075 16.4525 15.2614 16.5239 15.4726C16.5952 15.6839 16.6045 15.9112 16.5507 16.1276L15.4493 20.5391C15.3951 20.7556 15.4041 20.9831 15.4753 21.1946C15.5465 21.4061 15.6768 21.5928 15.8509 21.7325C16.0249 21.8721 16.2354 21.959 16.4573 21.9826C16.6792 22.0063 16.9033 21.9658 17.1029 21.866L17.1667 21.8333M30 16C30 17.8385 29.6379 19.659 28.9343 21.3576C28.2307 23.0561 27.1995 24.5995 25.8995 25.8995C24.5995 27.1995 23.0561 28.2307 21.3576 28.9343C19.659 29.6379 17.8385 30 16 30C14.1615 30 12.341 29.6379 10.6424 28.9343C8.94387 28.2307 7.40053 27.1995 6.1005 25.8995C4.80048 24.5995 3.76925 23.0561 3.06569 21.3576C2.36212 19.659 2 17.8385 2 16C2 12.287 3.475 8.72601 6.1005 6.10051C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.10051C28.525 8.72601 30 12.287 30 16ZM16 10.1667H16.0124V10.1791H16V10.1667Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Informations;
