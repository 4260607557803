import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';

interface TabArrowProps extends IconProps {
  down?: boolean;
}

const TabArrow = (props: TabArrowProps) => {
  const { down } = props;
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      style={{
        ...iconProps.style,
        flexShrink: 0,
        transform: down ? 'rotate(0deg)' : 'rotate(-180deg)',
        transition: 'transform 150ms ease',
      }}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 10L16.0001 22L4 10"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TabArrow;
