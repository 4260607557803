import { Language } from '../entities/translations_ns';

// Format number to return a user friendly string
const dollarFormat = (
  x: number | undefined,
  lang: string | Language | undefined,
  withDollarSign?: boolean,
): string => {
  if (!x) return '';
  if (!lang) lang = 'en';
  const value =
    lang === 'fr'
      ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(160))
      : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (withDollarSign) {
    return `${lang === 'en' ? '$ ' : ''}${value}${lang === 'fr' ? ' $' : ''}`;
  }

  return value;
};
export default dollarFormat;
