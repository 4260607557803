import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import ErrorBox from '@hellodarwin/core/lib/components/error/error-box';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import Refresh from '@hellodarwin/icons/dist/icons/Refresh';
import Verified from '@hellodarwin/icons/dist/icons/Verified';

const UnverifiedAdminPage = () => {
  const { refreshApp } = useLogin();

  const refreshPage = () => {
    refreshApp();
  };

  return (
    <PageLayout tabTitle="Page not found" app={'admin'} breadcrumbs={[]}>
      <Div
        flex="column"
        justify="center"
        align="center"
        style={{
          minHeight: `calc(100vh - 40px - 40px - 32px - 54px - 64px - 40px)`,
        }}
      >
        <ErrorBox
          errorMessage={'You are unverified! Please contact your team lead.'}
          ErrorIcon={Verified}
          ctaAction={refreshPage}
          CtaIcon={Refresh}
        />
      </Div>
    </PageLayout>
  );
};

export default UnverifiedAdminPage;
