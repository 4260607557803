import { CSSProperties, MouseEventHandler, PropsWithChildren } from 'react';
import { DivContainer } from './styles';
import useDivProps from './utils/use-div-props';

export interface GeneralDivProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onPointerEnter?: MouseEventHandler<HTMLDivElement>;
  onPointerLeave?: MouseEventHandler<HTMLDivElement>;
  id?: string;
  className?: string;
}

export interface ViewDivProps {
  flex?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  justifySelf?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  fitContent?: boolean;
  fitMaxContent?: boolean;
  fitMinContent?: boolean;
  fullHeight?: boolean;
  wrap?: CSSProperties['flexWrap'];
  hidden?: boolean;
  backgroundColor?: string;
  backgroundOpacity?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  backgroundOpacityHover?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  borderColor?: string;
  borderColorHover?: string;
  bordersSide?:
    | 'all'
    | 'vertical'
    | 'horizontal'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom';
  borderRadius?: CSSProperties['borderRadius'];
  gap?: CSSProperties['gap'];
  overflow?: CSSProperties['overflow'];
  position?: CSSProperties['position'];
  style?: CSSProperties;
}

export type DivProps = GeneralDivProps &
  ViewDivProps & {
    mobile?: ViewDivProps;
    tablet?: ViewDivProps;
    xl?: ViewDivProps;
    xxl?: ViewDivProps;
  } & PropsWithChildren;

const Div = (props: DivProps) => {
  const { currentProps, currentStyle, generalProps } = useDivProps(props);
  const {
    children,
    onClick,
    onDoubleClick,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    id,
    className,
  } = generalProps;

  return (
    <DivContainer
      $backgroundColor={currentProps?.$backgroundColor}
      $backgroundOpacity={currentProps?.$backgroundOpacity}
      $backgroundOpacityHover={currentProps?.$backgroundOpacityHover}
      $flexDirection={currentProps?.$flexDirection}
      $borderColor={currentProps?.$borderColor}
      $borderColorHover={currentProps?.$borderColorHover}
      $hidden={currentProps?.$hidden}
      $fitContent={currentProps?.$fitContent}
      $minContent={currentProps?.$minContent}
      $maxContent={currentProps?.$maxContent}
      $fullHeight={currentProps?.$fullHeight}
      $borderSide={currentProps?.$borderSide}
      className={className}
      style={currentStyle}
      id={id}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {children}
    </DivContainer>
  );
};

export default Div;
