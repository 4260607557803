import { FormsSelectOptions } from '../../../../features/entities/form-entities';

export const getSelectOptions = (
  formsSelectOptions: FormsSelectOptions[],
  locale: string,
): { label: string | undefined; value: string }[] => {
  if (locale === 'en') {
    return formsSelectOptions
      .filter((option) => !option.hidden)
      .map((option) => ({
        label: option.label_en,
        value: option.value,
      }));
  } else {
    return formsSelectOptions
      .filter((option) => !option.hidden)
      .map((option) => ({
        label: option.label_fr,
        value: option.value,
      }));
  }
};
