import { ButtonProps, ButtonSize } from '.';
import styled, { css } from '../../../plugins/styled';
import { DefaultTheme } from '../../../theme';
import { ArrowContainer } from './arrow/styles';
import getButtonTheme from './get-button-theme';

export type ButtonContainerProps = {
  $fitContent?: boolean;
  $isTransparent?: boolean;
  $isLink?: boolean;
  $hidden?: boolean;
  $fullWidth?: boolean;
  $wrap?: boolean;
  $withArrow?: boolean;
  $size?: ButtonSize;
  disabled?: boolean;
  $defaultTheme?: ButtonProps['defaultStyle'];
};

const createButtonCSS = (name: string) => css`
  font-size: ${({ theme }) => theme?.fonts?.[`button_${name}`]};
  line-height: normal;
  letter-spacing: ${({ theme }) => theme?.letterSpacing?.[`button_${name}`]}px;
  min-height: ${({ theme }) => theme?.buttons?.[`height_${name}`]};
  min-width: ${({ theme }) => theme?.buttons?.[`width_${name}`]};
  width: fit-content;
  max-width: 100%;
  ${name === 'large' ? `font-weight:600;` : ''}
  @media (max-width: ${({ theme }) => theme?.breakpoints?.tablet}px) {
    font-size: ${({ theme }) => theme?.fonts?.[`button_${name}_md`]};
    letter-spacing: ${({ theme }) =>
      theme?.letterSpacing?.[`button_${name}_md`]}px;
  }
`;

const buttonLarge = createButtonCSS('large');
const buttonMedium = createButtonCSS('medium');
const buttonTertiary = createButtonCSS('small');
const buttonExtraSmall = createButtonCSS('extra_small');

const buttonAuto = css`
  min-width: auto;
  min-height: auto;
  gap: 0;
  span {
    margin: 0;
  }
`;

const buttonCircle = css`
  aspect-ratio: 1/1;
  padding: 8px;
  gap: 0;
  span {
    margin: 0;
  }
`;
const buttonSquare = css`
  aspect-ratio: 1/1;
  padding: 8px;
  gap: 0;
  border-radius: 2px;
  span {
    margin: 0;
  }
`;

const buttonSizeStyling = css<ButtonContainerProps>`
  width: fit-content;
  .ant-btn-icon,
  .ant-btn-icon + span {
    margin-left: 0;
  }
  ${({ $size }) =>
    $size === 'extra-small'
      ? buttonExtraSmall
      : $size === 'small'
        ? buttonTertiary
        : $size === 'auto'
          ? buttonAuto
          : $size === 'large'
            ? buttonLarge
            : $size === 'circle'
              ? buttonCircle
              : $size === 'square'
                ? buttonSquare
                : buttonMedium}
`;

const buttonColorStyling = css<ButtonContainerProps>`
  ${({ theme, $defaultTheme }) => {
    const buttonTheme = getButtonTheme(theme, $defaultTheme);
    return css`
      color: ${buttonTheme?.textColor || theme.colors.white_1};
      background: ${buttonTheme?.backgroundColor ||
      $defaultTheme ||
      theme.colors.purple_1};
      border: 1px solid
        ${buttonTheme?.borderColor || $defaultTheme || theme.colors.purple_1};
      svg {
        color: ${buttonTheme?.textColor || theme.colors.white_1};
      }
      ${ArrowContainer} {
        color: ${buttonTheme?.textColor || theme.colors.white_1};
        &:after {
          border-color: ${buttonTheme?.textColor || theme.colors.white_1};
        }
      }
    `;
  }}
`;

const buttonColorStylingHover = css<ButtonContainerProps>`
  ${({ theme, $defaultTheme }) => {
    const buttonTheme = getButtonTheme(theme as DefaultTheme, $defaultTheme);
    return css`
      color: ${buttonTheme?.textColorHover || theme.colors.white_1};
      background: ${buttonTheme?.backgroundColorHover || theme.colors.grey_1};
      border: 1px solid ${buttonTheme?.borderColorHover || theme.colors.grey_1};
      svg {
        color: ${buttonTheme?.textColorHover || theme.colors.white_1};
      }
      ${ArrowContainer} {
        color: ${buttonTheme?.textColorHover || theme.colors.white_1};
        &:after {
          border-color: ${buttonTheme?.textColorHover || theme.colors.white_1};
        }
      }
    `;
  }}
`;

interface LinkTheme {
  textColor: string;
  textColorHover: string;
}

const getLinkTheme = (theme: DefaultTheme, color?: string): LinkTheme => {
  const linkThemes: { [name: string]: LinkTheme } = {
    [theme.colors.grey_1]: {
      textColor: theme.colors.grey_1,
      textColorHover: theme.colors.grey_2,
    },
    [theme.colors.grey_3]: {
      textColor: theme.colors.grey_3,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.grey_4]: {
      textColor: theme.colors.grey_4,
      textColorHover: theme.colors.purple_3,
    },
    [theme.colors.grey_5]: {
      textColor: theme.colors.grey_5,
      textColorHover: theme.colors.purple_4,
    },
    [theme.colors.purple_1]: {
      textColor: theme.colors.purple_1,
      textColorHover: theme.colors.purple_3,
    },
    [theme.colors.white_1]: {
      textColor: theme.colors.white_1,
      textColorHover: theme.colors.purple_3,
    },
  };
  return linkThemes[color ?? theme.colors.grey_1];
};

const transparentHoverStyling = css<ButtonContainerProps>`
  background-color: transparent;
  border-radius: 0;
  outline: none;
  border: none;
  transform: none;
  width: fit-content;
  transition: ${({ theme }) => theme.appearance.transition};
  color: ${(props) =>
    getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)
      ?.textColorHover || props.theme.colors.grey_1};
  svg {
    color: ${(props) =>
      getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)
        ?.textColorHover || props.theme.colors.grey_1};
  }
  ${ArrowContainer} {
    color: ${(props) =>
      getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)
        ?.textColorHover || props.theme.colors.grey_1};
    &:after {
      border-color: ${(props) =>
        getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)
          ?.textColorHover || props.theme.colors.grey_1};
    }
  }
`;

const transparentStyling = css<ButtonContainerProps>`
  ${buttonSizeStyling}
  font-weight:400;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  outline: none;
  width: fit-content;
  transition: ${({ theme }) => theme.appearance.transition};
  border: none;
  color: ${(props) =>
    getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)?.textColor ||
    props.theme.colors.grey_3};

  min-width: auto;

  svg,
  ${ArrowContainer} {
    color: ${(props) =>
      getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)
        ?.textColor || props.theme.colors.grey_3};
    &:after {
      border-color: ${(props) =>
        getLinkTheme(props.theme as DefaultTheme, props.$defaultTheme)
          ?.textColor || props.theme.colors.grey_3};
    }
  }
  &:focus {
    outline: none;
    border: none;
  }
  &:before,
  &:after {
    display: none;
  }
`;

const LinkStyling = css<ButtonContainerProps>`
  ${transparentStyling}
  span {
    margin: 0;
  }
  text-decoration: underline;
  text-underline-offset: 6px;
  min-width: auto;
  gap: 8px;
`;

const disabledStyling = css<ButtonContainerProps>`
  background-color: ${(props) =>
    props.$defaultTheme === props.theme.colors.white_1
      ? props.theme.colors.grey_5
      : props.theme.colors.grey_4};
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  color: ${(props) =>
    props.$defaultTheme === props.theme.colors.white_1
      ? props.theme.colors.grey_4
      : props.theme.colors.white_1};
  cursor: not-allowed;
  svg {
    color: ${(props) =>
      props.$defaultTheme === props.theme.colors.white_1
        ? props.theme.colors.grey_4
        : props.theme.colors.white_1};
  }
  ${ArrowContainer} {
    color: ${(props) =>
      props.$defaultTheme === props.theme.colors.white_1
        ? props.theme.colors.grey_4
        : props.theme.colors.white_1};
    &:after {
      border-color: ${(props) =>
        props.$defaultTheme === props.theme.colors.white_1
          ? props.theme.colors.grey_4
          : props.theme.colors.white_1};
    }
  }
`;

export const buttonWithoutArrowStyling = css`
  ${buttonColorStyling}
`;

export const buttonWithArrowStyling = css`
  ${buttonColorStyling}
`;

export const ButtonBaseStyling = css<ButtonContainerProps>`
  font-family: 'Inter';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: normal;
  border-radius: ${({ theme }) => theme.appearance.border_radius_button};
  background-color: ${({ theme }) => theme.colors.purple_1};
  border: 1px solid ${({ theme }) => theme.colors.purple_1};
  color: ${({ theme }) => theme.colors.white_1};
  box-shadow: none;
  white-space: nowrap;
  transition: ${({ theme }) => theme.appearance.transition};
  cursor: pointer;
  gap: 8px;
  &:before,
  &:after {
    display: block;
    content: '';
  }
  a {
    color: inherit;
  }
  svg {
    color: ${({ theme }) => theme.colors.white_1};
    display: block;
  }
  ${(props) => (!!props.$wrap ? 'white-space: normal;' : '')}
  ${(props) => (!!props.$fullWidth ? 'width: 100%;' : '')}
  ${(props) => (!!props.$fitContent ? 'width: fit-content;' : '')}
  ${buttonSizeStyling}
  &:disabled,
  &.ant-btn-disabled {
    ${disabledStyling}
  }
  ${(props) => (!!props.$hidden ? 'display: none;' : '')}
  ${({ $isTransparent, $isLink, $withArrow }) =>
    !!$isTransparent
      ? transparentStyling
      : !!$isLink
        ? LinkStyling
        : !!$withArrow
          ? buttonWithArrowStyling
          : buttonWithoutArrowStyling}
`;

export const ButtonHoverStyling = css<ButtonContainerProps>`
  &:not(:disabled):not(.ant-btn-disabled) {
    ${ArrowContainer} {
      width: 30px;
    }
    ${({ $isLink, $isTransparent }) =>
      $isLink || $isTransparent ? transparentHoverStyling : undefined}
    ${({ $isTransparent, $isLink, $withArrow }) =>
      $isTransparent || $isLink
        ? transparentHoverStyling
        : !!$withArrow
          ? buttonWithArrowStyling
          : buttonColorStylingHover}
  }
  &:disabled {
    background-color: ${(props) =>
      props.$defaultTheme === props.theme.colors.white_1
        ? props.theme.colors.grey_5
        : props.theme.colors.grey_4};
    border: 1px solid ${({ theme }) => theme.colors.grey_4};
    color: ${(props) =>
      props.$defaultTheme === props.theme.colors.white_1
        ? props.theme.colors.grey_4
        : props.theme.colors.white_1};
    transform: none;
  }
`;

export const ButtonContainer = styled.button<ButtonContainerProps>`
  ${ButtonBaseStyling}
  &:hover,
  &:active {
    ${ButtonHoverStyling}
  }
`;
