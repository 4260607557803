import Div from '@hellodarwin/core/lib/components/common/div';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import Cancel from '@hellodarwin/icons/dist/icons/CancelProject';
import Checkmark from '@hellodarwin/icons/dist/icons/Checkmark';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import theme from '../../theme';
import { copyIntoClipboard } from '../clipboard';
type HubspotUserButtonProps = {
  admin: AdminProfile;
};

const HubspotUserButton = ({ admin }: HubspotUserButtonProps) => {
  const hubspotId = admin.hubspot?.crm_id || '';

  return (
    <Div flex="row" fitContent gap={8}>
      {hubspotId !== '' ? (
        <Div flex="row" fitContent align={'center'}>
          <Tooltip
            title={'User is linked to hubspot'}
            color={theme.colors.green_1}
          >
            <Button
              style={{ borderColor: theme.colors.green_1 }}
              onClick={() => copyIntoClipboard(hubspotId)}
              size="middle"
              icon={
                <Checkmark
                  color={theme.colors.green_1}
                  width={20}
                  height={20}
                />
              }
            />
          </Tooltip>
        </Div>
      ) : (
        <Div flex="row" fitContent align={'center'}>
          <Tooltip
            title={
              'User does not have an hubspot account. Please create one in Hubspot.'
            }
            color={theme.colors.error}
          >
            <Button
              style={{ borderColor: theme.colors.red_1 }}
              size="middle"
              icon={
                <Cancel color={theme.colors.red_1} width={20} height={20} />
              }
            />
          </Tooltip>
        </Div>
      )}
    </Div>
  );
};

export default HubspotUserButton;
