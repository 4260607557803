import React, { Fragment, ReactNode, useState } from 'react';
import {
  Contact,
  Match,
  Portfolio,
  ProfileModalProps,
  Provider,
  ReviewResult,
  Rfp,
  TeamMember,
} from '../../../features/entities';
import ProfileBlocks from '../../../features/enums/profile-blocks';
import { useTranslations } from '../../../features/providers/translations-provider';
import ProfileRfps from '../profile-rfp';
import ProfileAbout from './profile-about';
import ProfileVideo from './profile-assets/profile-video';
import ProfileContact from './profile-contact';
import ContactPreview from './profile-contact/contact-preview';
import ProfileExpertise from './profile-expertise';
import ProfileFastInformations from './profile-fast-informations';
import ProfileHeader from './profile-header';
import ProviderMessage from './profile-message';
import ProfilePortfolio from './profile-portfolio';
import ProfileReviews from './profile-reviews';
import ProfileTeam from './profile-team';
import { ProviderProfileContainer } from './styles';

export interface ProfileBlock {
  label: string;
  content: ReactNode;
  hide?: boolean;
}

type ProviderProfileProps = {
  match?: Match;
  rfp?: Rfp;
  provider: Provider;
  providerActions?: ReactNode;
  providerPrimaryActions?: ReactNode;
  reviews: ReviewResult[];
  portfolios: Portfolio[];
  rfps?: { isLoading?: boolean; rfps?: Rfp[] };
  teamMembers: TeamMember[];
  bannerCTA?: () => void;
  handleLeaveAReview?: () => void;
  handleRedirectToSettings?: () => void;
  websiteService?: string;
  sections: ProfileBlocks[];
  locale?: string;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  state?: 'client' | 'website' | 'edit';
  contact?: Contact;
  banners?: ReactNode[];
  setTeamMembers?: (value: TeamMember[]) => void;
};

const ProviderProfile = ({
  match,
  rfp,
  provider,
  providerActions,
  providerPrimaryActions,
  state = 'website',
  reviews,
  portfolios,
  rfps,
  teamMembers,
  bannerCTA,
  handleLeaveAReview,
  handleRedirectToSettings,
  sections,
  locale = 'en',
  toggleEditModal,
  contact,
  banners,
  setTeamMembers,
}: ProviderProfileProps) => {
  const [showContact, setShowContact] = useState(false);

  const { t } = useTranslations();

  if (provider === undefined) {
    return <></>;
  }

  const getAllIndexes = <T,>(arr: T[], val: T) => {
    const indexes = arr
      .map((element, index) => (element === val ? index : -1))
      .filter((i) => i !== -1);

    return indexes;
  };

  const blocks: { [block_name: string]: ProfileBlock } = {
    [ProfileBlocks.FastInfos]: {
      label: '',
      content: (
        <ProfileFastInformations
          provider={provider}
          isEditable={state === 'edit'}
          id={'infos'}
          toggleEditModal={toggleEditModal}
          locale={locale}
        />
      ),
    },

    [ProfileBlocks.About]: {
      label: t('profile_sections|about'),
      content: (
        <ProfileAbout
          provider={provider}
          isEditable={state === 'edit'}
          toggleEditModal={toggleEditModal}
          id={'about'}
        />
      ),
      hide: !provider?.description?.length,
    },
    [ProfileBlocks.Portfolios]: {
      label: t('profile_sections|portfolio'),
      content: (
        <ProfilePortfolio
          portfolios={portfolios}
          isEditable={state === 'edit'}
          toggleEditModal={toggleEditModal}
          id={'portfolios'}
          providerName={provider.name}
        />
      ),
      hide: !portfolios?.length,
    },

    [ProfileBlocks.Presentation]: {
      label: t('profile_sections|video'),
      content: (
        <ProfileVideo
          provider={provider}
          isEditable={state === 'edit'}
          isWebsite={state === 'website'}
          toggleEditModal={toggleEditModal}
          id={'video'}
        />
      ),
      hide: !provider.assets?.length,
    },
    [ProfileBlocks.Team]: {
      label: t('profile_sections|team'),
      content: (
        <ProfileTeam
          provider={provider}
          teamMembers={teamMembers}
          setTeamMembers={setTeamMembers!}
          isEditable={state === 'edit'}
          toggleEditModal={toggleEditModal}
          id={'team'}
        />
      ),
      hide: !teamMembers?.length,
    },
    [ProfileBlocks.Reviews]: {
      label: t('profile_sections|reviews'),
      content: (
        <ProfileReviews
          provider={provider}
          reviews={reviews}
          state={state}
          id={'reviews'}
          handleLeaveAReview={handleLeaveAReview}
          locale={locale}
        />
      ),
    },

    [ProfileBlocks.Expertise]: {
      label: t('profile_sections|expertise'),
      content: (
        <ProfileExpertise
          provider={provider}
          isEditable={state === 'edit'}
          toggleEditModal={toggleEditModal}
          id={'expertise'}
        />
      ),
      hide: !provider.tags,
    },

    [ProfileBlocks.Rfp]: {
      label: t('profile_sections|rfp'),
      content: (
        <ProfileRfps
          isLoading={rfps?.isLoading}
          id={'rfp'}
          rfps={rfps?.rfps ?? []}
        />
      ),
      hide: !rfps?.rfps?.length,
    },
    [ProfileBlocks.Contact]: {
      label: t('profile_sections|contact'),
      content: (
        <ProfileContact
          provider={provider}
          isEditable={state === 'edit'}
          isRfp={!!rfp}
          providerPrimaryActions={providerPrimaryActions}
          handleRedirectToSettings={handleRedirectToSettings}
          handleShowContact={
            !!bannerCTA ? bannerCTA : () => setShowContact(true)
          }
          isWebsite={state === 'website'}
          id={'contact'}
        />
      ),
    },
    [ProfileBlocks.AdBanner]: {
      label: '',
      content: '',
      hide: false,
    },
  };

  const sectionsVisible = sections.filter(
    (s) => state === 'edit' || !blocks[s]?.hide,
  );

  const bannerIndexes = getAllIndexes(sectionsVisible, ProfileBlocks.AdBanner);

  return (
    <ProviderProfileContainer>
      <ProfileHeader
        provider={provider}
        isEditable={state === 'edit'}
        sections={sections}
        blocks={blocks}
        providerActions={providerActions}
        toggleEditModal={toggleEditModal}
        websiteCTA={
          state === 'website'
            ? bannerCTA
            : state === 'edit'
              ? () => setShowContact(true)
              : undefined
        }
        handleLeaveAReview={handleLeaveAReview}
        handleRedirectToSettings={handleRedirectToSettings}
      />

      {match?.hello_message && (
        <ProviderMessage message={match?.hello_message || ''} />
      )}

      {React.Children.toArray(
        sectionsVisible.map((s, i) => (
          <Fragment>
            {s === ProfileBlocks.AdBanner
              ? banners?.[bannerIndexes.findIndex((index) => index === i)]
              : blocks[s]?.content}
          </Fragment>
        )),
      )}

      {!!contact && (
        <ContactPreview
          isVisible={showContact}
          onCancel={() => setShowContact(false)}
          logo={provider.logo || ''}
          name={contact.name}
          preferred_language={provider.rfp_language}
          phone={contact.phone || ''}
          email={contact.email || ''}
          ext={contact.ext || ''}
          title={contact.title ?? ''}
        />
      )}
    </ProviderProfileContainer>
  );
};

export default ProviderProfile;
