import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Development = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26655 8.86662L11.7999 12.2666L7.26655 15.6666M14.0666 15.6666H18.5999M4.99988 28.1333H25.3999C26.3016 28.1333 27.1664 27.7751 27.804 27.1374C28.4417 26.4998 28.7999 25.635 28.7999 24.7333V6.59995C28.7999 5.69821 28.4417 4.83341 27.804 4.19579C27.1664 3.55816 26.3016 3.19995 25.3999 3.19995H4.99988C4.09815 3.19995 3.23334 3.55816 2.59572 4.19579C1.9581 4.83341 1.59988 5.69821 1.59988 6.59995V24.7333C1.59988 25.635 1.9581 26.4998 2.59572 27.1374C3.23334 27.7751 4.09815 28.1333 4.99988 28.1333Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Development;
