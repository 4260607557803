import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { RfpDescriptionTemplate } from '@hellodarwin/core/lib/features/entities/rfp-entities';
import Language from '@hellodarwin/core/lib/features/enums/language';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import RfpDescriptionTemplateForm from '../../components/rfp/rfp-description-template-form';
import { useAdminApi } from '../../features/api/use-admin-api';

const RfpDescriptionTemplateContainer = () => {
  const api = useAdminApi();

  const [rfpDescriptionTemplates, setRfpDescriptionTemplates] =
    useState<RfpDescriptionTemplate>({} as RfpDescriptionTemplate);
  const [language, setLanguage] = useState<string>('en');

  useEffect(() => {
    fetchRfpDescriptionTemplates(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const fetchRfpDescriptionTemplates = async (language: string) => {
    try {
      const templates =
        await api.fetchRfpDescriptionTemplateByLanguage(language);
      setRfpDescriptionTemplates(templates);
    } catch (error: any) {
      console.error(error);
      message.error('Failed to fetch project description templates');
    }
  };

  const handleLanguageChange = (selectedLanguage: string) => {
    setLanguage(selectedLanguage);
  };

  return (
    <Div flex="column" gap={32} align="center">
      <Div flex="row" gap={32} align="center">
        <Typography.Title level={5}>
          Project Description Template
        </Typography.Title>
        <Select
          value={language}
          onChange={handleLanguageChange}
          style={{ minWidth: 120 }}
        >
          <Select.Option value={Language.English}>English</Select.Option>
          <Select.Option value={Language.French}>Français</Select.Option>
        </Select>
      </Div>
      <RfpDescriptionTemplateForm
        key={rfpDescriptionTemplates.rfp_description_template_id}
        rfpDescriptionTemplate={rfpDescriptionTemplates}
        language={language}
      />
    </Div>
  );
};

export default RfpDescriptionTemplateContainer;
