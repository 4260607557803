import { AppType } from '../../../../features/entities';
import styled from '../../../../plugins/styled';

export const UserMenuButtonStyled = styled.div<{
  $app: AppType;
  $isOnNav?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 200px;
  cursor: pointer;
  color: ${({ theme, $app, $isOnNav }) =>
    $app === 'provider' && $isOnNav
      ? theme.colors.white_1
      : theme.colors.primary};
  &:hover {
    color: ${({ theme, $app, $isOnNav }) =>
      $app === 'provider' && $isOnNav
        ? theme.colors.purple_3
        : theme.colors.purple_2};
  }
`;
