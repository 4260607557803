import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import {
  AdminTag,
  ApiError,
  ApiErrorInitialState,
  Company,
  CompanyAsset,
  Contact,
  Match,
  Portfolio,
  PortfolioAsset,
  Provider,
  ProviderProfile,
  ProviderRequest,
  ProviderTagRequest,
  Rfp,
  ServiceTag,
  SpecialtyTag,
  TeamMember,
  TeamMemberItem,
} from '@hellodarwin/core/lib/features/entities';

import { createPortfolioAdapter } from '@hellodarwin/core/lib/features/adapters/portfolio-adapter';
import { ProfileModalProps } from '@hellodarwin/core/lib/features/entities';
import ProfileBlocks from '@hellodarwin/core/lib/features/enums/profile-blocks';
import { RootState } from '../../../app/app-store';
import { showErrorNotification } from '../../utils';
import { createTeamMemberAdapter } from '../adapters/team-adapter';
import AdminApiClient from '../admin-api-client';

//Adapters
const portfoliosAdapter = createPortfolioAdapter();

const teamMemberOrderAdapter = createTeamMemberAdapter();

const matchesAdapter = createEntityAdapter({
  selectId: (model: Match) => model.rfp_id,
});
const referredRfpAdapter = createEntityAdapter({
  selectId: (model: Rfp) => model.rfp_id,
});
const teamMemberAdapter = createEntityAdapter({
  selectId: (model: TeamMember) => model.provider_team_member_id,
});

//State
export interface ProvidersState {
  status: 'idle' | 'pending';
  error: ApiError;
  matches: {
    active: EntityState<Match, string>;
    refused: EntityState<Match, string>;
    purchased: EntityState<Match, string>;
  };
  rfp: Match[];
  referredRfp: EntityState<Rfp, string>;
  portfolios: { [key: string]: Portfolio };
  teamMembers: EntityState<TeamMember, string>;
  selectedProvider: Provider;
  creditBalance: number;
  modal: {
    isVisible: boolean;
    type: string;
  };
  profileModal: ProfileModalProps;
  profileScore: ProviderProfile;
}

const initialState: ProvidersState = {
  status: 'idle',
  error: ApiErrorInitialState,
  matches: {
    active: matchesAdapter.getInitialState(),
    refused: matchesAdapter.getInitialState(),
    purchased: matchesAdapter.getInitialState(),
  },
  rfp: [],
  referredRfp: referredRfpAdapter.getInitialState(),
  portfolios: portfoliosAdapter.getInitialState(),
  teamMembers: teamMemberAdapter.getInitialState(),
  selectedProvider: {} as Provider,
  creditBalance: 0,
  modal: {
    isVisible: false,
    type: '',
  },
  profileModal: {
    open: false,
  },
  profileScore: {} as ProviderProfile,
};

export const fetchProviderById = createAsyncThunk<
  Provider,
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchProviderById',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderById(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchActiveMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchActiveMatches',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderActiveMatches(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRefusedMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchRefusedMatches',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderRefusedMatches(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPurchasedMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchPurchasedMatches',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderPurchasedMatches(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProviderReferredRfp = createAsyncThunk<
  Rfp[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchReferredRfps',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderReferredRfps(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProviderTags = createAsyncThunk<
  AdminTag[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchProviderTags',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderTags(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPortfolios = createAsyncThunk<
  Portfolio[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchPortfolios',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderPortfolios(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const uploadPortfolioAsset = createAsyncThunk<
  PortfolioAsset,
  { api: AdminApiClient; data: FormData; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/uploadPortfolioAsset',
  async (
    {
      api,
      data,
      providerId,
    }: { api: AdminApiClient; data: FormData; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.uploadPortfolioAsset(data, providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { providers } = getState();
      if (providers.status === 'pending') return false;
    },
  },
);

export const removePortfolioAsset = createAsyncThunk<
  PortfolioAsset,
  { api: AdminApiClient; assetID: string; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/removePortfolioAsset',
  async (
    {
      api,
      assetID,
      providerId,
    }: { api: AdminApiClient; assetID: string; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.removePortfolioAsset(assetID, providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { providers } = getState();
      if (providers.status === 'pending') return false;
    },
  },
);

export const createPortfolio = createAsyncThunk<
  Portfolio,
  {
    api: AdminApiClient;
    data: FormData;
    providerId: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/createPortfolio',
  async (
    {
      api,
      data,
      providerId,
    }: {
      api: AdminApiClient;
      providerId: string;
      data: FormData;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.createPortfolio(data, providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { providers } = getState();
      if (providers.status === 'pending') return false;
    },
  },
);

export const deletePortfolio = createAsyncThunk<
  Portfolio[],
  { api: AdminApiClient; portfolio_id: string },
  { rejectValue: ApiError }
>(
  'admin/deletePortfolio',
  async (
    { api, portfolio_id }: { api: AdminApiClient; portfolio_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deletePortfolio(portfolio_id);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePortfolio = createAsyncThunk<
  Portfolio,
  {
    api: AdminApiClient;
    data: FormData;
    providerId: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updatePortfolio',
  async (
    {
      api,
      data,
      providerId,
    }: {
      api: AdminApiClient;
      data: FormData;
      providerId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.updatePortfolio(data, providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const updateProviderTags = createAsyncThunk<
  string,
  {
    api: AdminApiClient;
    rfpId: string;
    serviceTags: ServiceTag[];
    specialtyTags: SpecialtyTag[];
    industryTag: string | undefined;
  },
  { rejectValue: ApiError }
>(
  'admin/updateProviderTag',
  async (
    {
      api,
      rfpId,
      serviceTags,
      specialtyTags,
      industryTag,
    }: {
      api: AdminApiClient;
      rfpId: string;
      serviceTags: ServiceTag[];
      specialtyTags: SpecialtyTag[];
      industryTag: string | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      const tags: ProviderTagRequest[] = [];

      for (const tag of serviceTags) {
        tags.push({
          tag: tag.tag,
          minBudget: tag.min_budget,
          maxBudget: tag.max_budget,
        });
      }

      for (const tag of specialtyTags) {
        tags.push({
          tag: tag.tag,
          minBudget: 0,
          maxBudget: 0,
        });
      }

      industryTag &&
        tags.push({
          tag: industryTag,
          minBudget: 0,
          maxBudget: 0,
        });

      if (tags.length <= 0) return;

      return await api.updateProviderTags(rfpId, tags);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProvider = createAsyncThunk<
  Provider,
  { api: AdminApiClient; request: ProviderRequest },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateProvider',
  async (
    { api, request }: { api: AdminApiClient; request: ProviderRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateProvider(request);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateCompany = createAsyncThunk<
  Company,
  { api: AdminApiClient; company_id: string; request: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateCompany',
  async (
    {
      api,
      company_id,
      request,
    }: { api: AdminApiClient; company_id: string; request: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateCompanyV2(company_id, request);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchProviderMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/fetchProviderMatches',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchProviderMatches(providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProviderContacts = createAsyncThunk<
  Contact,
  { api: AdminApiClient; contact: Contact },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateProviderContacts',
  async (
    { api, contact }: { api: AdminApiClient; contact: Contact },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateContact(contact);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateLogo = createAsyncThunk<
  Provider,
  { api: AdminApiClient; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateLogo',
  async (
    {
      api,
      companyId,
      data,
    }: { api: AdminApiClient; companyId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateLogo(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteLogo = createAsyncThunk<
  Provider,
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/deleteLogo',
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteLogo(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateBanner = createAsyncThunk<
  Provider,
  { api: AdminApiClient; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateBanner',
  async (
    {
      api,
      companyId,
      data,
    }: { api: AdminApiClient; companyId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateBanner(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteBanner = createAsyncThunk<
  Provider,
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/deleteBanner',
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteBanner(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateVideos = createAsyncThunk<
  CompanyAsset,
  { api: AdminApiClient; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/updateVideos',
  async (
    {
      api,
      companyId,
      data,
    }: { api: AdminApiClient; companyId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateVideos(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteVideos = createAsyncThunk<
  string,
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/deleteVideos',
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteVideos(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTeamMember = createAsyncThunk<
  TeamMember,
  { api: AdminApiClient; provider_team_member_id: string; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/deleteTeamMember',
  async (
    {
      api,
      provider_team_member_id,
      providerId,
    }: {
      api: AdminApiClient;
      provider_team_member_id: string;
      providerId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteTeamMember(provider_team_member_id, providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchTeamMembers = createAsyncThunk<
  TeamMember[],
  { api: AdminApiClient; provider_id: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/fetchTeamMembers',
  async (
    { api, provider_id }: { api: AdminApiClient; provider_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchTeamMembers(provider_id);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitTeamMember = createAsyncThunk<
  TeamMember,
  { api: AdminApiClient; data: FormData; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/submitTeamMember',
  async (
    {
      api,
      data,
      providerId,
    }: { api: AdminApiClient; data: FormData; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.submitTeamMember(data, providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const editTeamMember = createAsyncThunk<
  TeamMember,
  {
    teamMemberId: string;
    api: AdminApiClient;
    data: FormData;
    providerId: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/editTeamMember',
  async (
    {
      teamMemberId,
      providerId,
      api,
      data,
    }: {
      teamMemberId: string;
      api: AdminApiClient;
      data: FormData;
      providerId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.editTeamMember(teamMemberId, providerId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const editTeamMemberOrder = createAsyncThunk<
  TeamMemberItem[],
  { api: AdminApiClient; teamMembers: TeamMemberItem[] },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/editTeamMemberOrder',
  async (
    {
      teamMembers,
      api,
    }: {
      teamMembers: TeamMemberItem[];
      api: AdminApiClient;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateTeamMemberOrder(teamMembers);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const removeTeamMember = createAsyncThunk<
  string,
  { api: AdminApiClient; teamMemberID: string; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'provider/removeTeamMember',
  async (
    {
      api,
      teamMemberID,
      providerId,
    }: { api: AdminApiClient; teamMemberID: string; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.removeTeamMember(teamMemberID, providerId);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { providers } = getState();
      if (providers.status === 'pending') return false;
    },
  },
);

export const fetchProfileScore = createAsyncThunk<
  ProviderProfile,
  { api: AdminApiClient; providerId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchProfileScore',
  async (
    { api, providerId }: { api: AdminApiClient; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getProfileScore(providerId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleProviderCategoryTag = createAction<string>(
  'admin/toggleProviderCategoryTag',
);
export const selectProviderServiceTag = createAction<string>(
  'admin/selectProviderServiceTag',
);
export const deselectProviderServiceTag = createAction<string>(
  'admin/deselectProviderServiceTag',
);
export const handleServiceBudgetChange = createAction<{
  service: string;
  minBudget: number;
  maxBudget: number;
}>('admin/handleServiceBudgetChange');

export const toggleProviderModal = createAction<{
  isVisible: boolean;
  type: string;
}>('admin/toggleProviderModal');

export const toggleProfileModal = createAction<{
  open: boolean;
  type?: ProfileBlocks;
  targetId?: string;
}>('global/toggleProfileModal');

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProviderById.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchProviderById.fulfilled, (state, { payload }) => {
      state.selectedProvider = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchProviderById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchActiveMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchActiveMatches.fulfilled, (state, { payload }) => {
      matchesAdapter.setAll(state.matches.active, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchActiveMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchRefusedMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRefusedMatches.fulfilled, (state, { payload }) => {
      matchesAdapter.setAll(state.matches.refused, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchRefusedMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchPurchasedMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPurchasedMatches.fulfilled, (state, { payload }) => {
      matchesAdapter.setAll(state.matches.purchased, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchPurchasedMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchProviderReferredRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchProviderReferredRfp.fulfilled,
      (state, { payload }) => {
        referredRfpAdapter.setAll(state.referredRfp, payload);
        state.status = 'idle';
      },
    );
    builder.addCase(fetchProviderReferredRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchPortfolios.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPortfolios.fulfilled, (state, { payload }) => {
      state.portfolios = portfoliosAdapter.setAll(state.portfolios, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchPortfolios.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(uploadPortfolioAsset.fulfilled, (state, { payload }) => {
      const portfolio_id = payload.provider_portfolio_id || '';
      state.portfolios = portfoliosAdapter.insertOnePortfolioAsset(
        state.portfolios,
        {
          portfolio_id,
          asset: payload,
        },
      );

      state.status = 'idle';
    });
    builder.addCase(uploadPortfolioAsset.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(removePortfolioAsset.fulfilled, (state, { payload }) => {
      const portfolio_id = payload.provider_portfolio_id || '';

      state.portfolios = portfoliosAdapter.removeOnePortfolioAsset(
        state.portfolios,
        {
          id: portfolio_id,
          changes: payload,
        },
      );

      state.status = 'idle';
    });
    builder.addCase(removePortfolioAsset.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(createPortfolio.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createPortfolio.fulfilled, (state, { payload }) => {
      state.portfolios = portfoliosAdapter.insertOneAtStart(
        state.portfolios,
        payload,
      );

      state.status = 'idle';
    });
    builder.addCase(createPortfolio.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updatePortfolio.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updatePortfolio.fulfilled, (state, { payload }) => {
      state.portfolios = portfoliosAdapter.updateOne(state.portfolios, {
        id: payload.provider_portfolio_id || '',
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(updatePortfolio.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateProvider.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateProvider.fulfilled, (state, { payload }) => {
      state.selectedProvider = { ...state.selectedProvider, ...payload };
      state.status = 'idle';
    });
    builder.addCase(updateProvider.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.selectedProvider = { ...state.selectedProvider, ...payload };
      state.status = 'idle';
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateProviderContacts.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateProviderContacts.fulfilled, (state, { payload }) => {
      state.selectedProvider.contacts = [payload];
      state.status = 'idle';
    });
    builder.addCase(updateProviderContacts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateProviderTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateProviderTags.fulfilled, (state, { payload }) => {
      state.status = 'idle';
    });
    builder.addCase(updateProviderTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchTeamMembers.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchTeamMembers.fulfilled, (state, { payload }) => {
      teamMemberAdapter.setAll(state.teamMembers, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchTeamMembers.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateLogo.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateLogo.fulfilled, (state, { payload }) => {
      state.selectedProvider = payload;
      state.status = 'idle';
    });
    builder.addCase(updateLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteLogo.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteLogo.fulfilled, (state, { payload }) => {
      state.selectedProvider = payload;
      state.status = 'idle';
    });
    builder.addCase(deleteLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateBanner.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateBanner.fulfilled, (state, { payload }) => {
      state.selectedProvider = payload;
      state.status = 'idle';
    });
    builder.addCase(updateBanner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteBanner.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteBanner.fulfilled, (state, { payload }) => {
      state.selectedProvider = payload;
      state.status = 'idle';
    });
    builder.addCase(deleteBanner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateVideos.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateVideos.fulfilled, (state, { payload }) => {
      state.selectedProvider.assets = [payload];
      state.status = 'idle';
    });
    builder.addCase(updateVideos.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteVideos.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteVideos.fulfilled, (state, { payload }) => {
      if (state.selectedProvider.company_id === payload) {
        state.selectedProvider.assets = [];
      }
      state.status = 'idle';
    });
    builder.addCase(deleteVideos.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(submitTeamMember.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(submitTeamMember.fulfilled, (state, { payload }) => {
      teamMemberAdapter.addOne(state.teamMembers, payload);
      state.status = 'idle';
    });
    builder.addCase(submitTeamMember.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(editTeamMember.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(editTeamMember.fulfilled, (state, { payload }) => {
      teamMemberAdapter.updateOne(state.teamMembers, {
        id: payload.provider_team_member_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(editTeamMember.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(editTeamMemberOrder.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(editTeamMemberOrder.fulfilled, (state, { payload }) => {
      const allTeamMembers = teamMemberAdapter
        .getSelectors()
        .selectAll(state.teamMembers);

      const updatedTeamMembers = teamMemberOrderAdapter.updateTeamMembersOrder(
        allTeamMembers,
        payload,
      );

      teamMemberAdapter.setAll(state.teamMembers, updatedTeamMembers);

      state.status = 'idle';
    });
    builder.addCase(editTeamMemberOrder.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(removeTeamMember.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(removeTeamMember.fulfilled, (state, { payload }) => {
      teamMemberAdapter.removeOne(state.teamMembers, payload);
      state.status = 'idle';
    });
    builder.addCase(removeTeamMember.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchProviderMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchProviderMatches.fulfilled, (state, { payload }) => {
      state.rfp = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchProviderMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchProfileScore.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchProfileScore.fulfilled, (state, { payload }) => {
      state.profileScore = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchProfileScore.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(toggleProviderModal, (state, { payload }) => {
      state.modal = payload;
    });
    builder.addCase(toggleProfileModal, (state, { payload }) => {
      state.profileModal = payload;
    });
  },
});

export const {
  selectAll: selectProviderTeamMembers,
  selectById: selectProviderTeamMemberoById,
} = teamMemberAdapter.getSelectors(
  (state: RootState) => state.providers.teamMembers,
);

export const {
  selectAll: selectProviderActiveMatches,
  selectById: selectProviderActiveMatchById,
} = matchesAdapter.getSelectors(
  (state: RootState) => state.providers.matches.active,
);

export const {
  selectAll: selectProviderRefusedMatches,
  selectById: selectProviderRefusedMatchById,
} = matchesAdapter.getSelectors(
  (state: RootState) => state.providers.matches.refused,
);

export const {
  selectAll: selectProviderPurchasedMatches,
  selectById: selectProviderPurchasedMatchById,
} = matchesAdapter.getSelectors(
  (state: RootState) => state.providers.matches.purchased,
);

export const {
  selectAll: selectProviderReferredRfp,
  selectById: selectProviderReferredProjectById,
} = referredRfpAdapter.getSelectors(
  (state: RootState) => state.providers.referredRfp,
);

export const selectProviderPortfolios = (state: RootState) =>
  portfoliosAdapter.selectAll(state.providers.portfolios);
export const selectProviderPortfolioById = (
  state: RootState,
  portfolio_id: string,
) => portfoliosAdapter.getPortfolio(state.providers.portfolios, portfolio_id);

export const selectProvidersIsErrored = (state: RootState) =>
  !!state.providers.error.error.length;
export const selectSelectedProvider = (state: RootState) =>
  state.providers.selectedProvider;
export const selectProviderRfp = (state: RootState) => state.providers.rfp;

export const selectProfileScore = (state: RootState) =>
  state.providers.profileScore;

export const providersReducer = providersSlice.reducer;
export const selectProviderModal = (state: RootState) => state.providers.modal;

export const selectProvidersLoading = (state: RootState) =>
  state.providers.status === 'pending';
export const selectProfileModal = (state: RootState) =>
  state.providers.profileModal;
