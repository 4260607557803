import Div from '@hellodarwin/core/lib/components/common/div';
import { ReactNode } from 'react';

type BlockProps = {
  children: ReactNode;
  title: string;
  visible: boolean;
  span: number;
};

const Block = ({ children, title, visible, span }: BlockProps) => {
  if (!visible) return <></>;
  return (
    <Div flex="column" gap={span}>
      {title && <p>{title}</p>}
      {children}
    </Div>
  );
};

export default Block;
