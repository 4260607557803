import Div from '../common/div';
import Spin, { SpinSize } from '../common/spin';

type LoadingProps = {
  size?: SpinSize;
};

const Loading = ({ size }: LoadingProps) => {
  return (
    <Div
      fullHeight
      flex="column"
      align="center"
      justify="center"
      style={{ width: '100%' }}
    >
      <Spin size={size || 'large'} />
    </Div>
  );
};

export default Loading;
