const normalizePhoneNumber = (value: string | undefined): string => {
  if (!value) return '';

  // Remove non-digit characters and split main number and extension
  const digits = value.replace(/\D/g, '');

  // Main number: first 10 digits
  const mainPart = digits.slice(0, 10);
  // Extension: remaining digits after the main part
  const extPart = digits.length > 10 ? digits.slice(10) : '';

  // Format the main phone number
  const formattedMain = mainPart.replace(
    /(\d{1,3})(\d{1,3})?(\d{1,4})?/,
    (_, p1, p2, p3) =>
      `${p1 ? `(${p1}` : ''}${p2 ? `) ${p2}` : ''}${p3 ? `-${p3}` : ''}`,
  );

  // Automatically add an extension if extra digits exist
  const fullNumber = `${formattedMain}${extPart ? ` ext. ${extPart}` : ''}`;

  return fullNumber.trim();
};

export default normalizePhoneNumber;
