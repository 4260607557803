import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GrantProviderFormValues } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Col from 'antd/es/col';
import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input';
import Popconfirm from 'antd/es/popconfirm';
import Row from 'antd/es/row';
import Select from 'antd/es/select';
import React from 'react';
import ReactQuill from 'react-quill';
import { quillModules } from '../../components/text-editor';

interface FormRepresentativeProps {
  form: FormInstance<GrantProviderFormValues>;
  initialValues: GrantProviderFormValues;
  selectedContact: number | null;
  grantProvided: { label: string; value: string }[];
  createRepresentative: () => void;
  onSave: () => void;
  onDelete: () => void;
}

const FormRepresentative: React.FC<FormRepresentativeProps> = ({
  form,
  initialValues,
  selectedContact,
  grantProvided,
  createRepresentative,
  onSave,
  onDelete,
}) => {
  const { t } = useTranslations();
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      name="grant"
      style={{ width: '88%' }}
    >
      <Div flex="column" gap={8}>
        <Div flex="row" justify="space-between" align="center">
          <Typography.Title level={4} elementTheme="h6">
            {t('mandated_agents|mandated_agents')}
          </Typography.Title>
          <Div style={{ height: 'fit-content', width: 'fit-content' }}>
            {selectedContact === null ? (
              <Button
                onClick={async () => {
                  createRepresentative();
                }}
                size="small"
              >
                {t('button|button_create')}
              </Button>
            ) : (
              <Div flex="row" gap={8}>
                <Popconfirm
                  title={t('mandated_agents|title_delete_agent')}
                  description={t('mandated_agents|content_delete_agent')}
                  onConfirm={async () => {
                    onDelete();
                  }}
                  okText={t('mandated_agents|yes')}
                  cancelText={t('mandated_agents|no')}
                >
                  <Button
                    size="small"
                    fitContent
                    style={{ padding: 8, color: theme.colors.white_1 }}
                    defaultStyle={theme.colors.red_2}
                  >
                    {t('button|delete')}
                  </Button>
                </Popconfirm>
                <Button onClick={async () => onSave()} size="small">
                  {t('button|save')}
                </Button>
              </Div>
            )}
          </Div>
        </Div>

        <Row gutter={32}>
          <Col span={8}>
            <Form.Item label={t('mandated_agents|firstName')} name="first_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('mandated_agents|lastName')} name="last_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('mandated_agents|role')} name="title">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={8}>
            <Form.Item
              label={t('mandated_agents|phoneNumber')}
              name="phone_number"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Extension" name="extension">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('mandated_agents|preferredLanguage')}
              name="language"
            >
              <Select style={{ width: '100%' }}>
                <Select.Option value="en">English</Select.Option>
                <Select.Option value="fr">Français</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label={t('mandated_agents|only_email')} name="email">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('mandated_agents|application_program')}
              name="grant_ids"
              rules={[
                {
                  required: true,
                  message: t('mandated_agents|missing_application_program'),
                },
              ]}
            >
              <Select mode="multiple" allowClear style={{ width: '100%' }}>
                {grantProvided &&
                  grantProvided.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Notes" name="notes">
              <ReactQuill
                className="text-editor"
                theme="snow"
                modules={quillModules}
              />
            </Form.Item>
          </Col>
        </Row>
      </Div>
    </Form>
  );
};

export default FormRepresentative;
