import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { ReactNode, useMemo, useState } from 'react';
import { Portfolio } from '../../../../features/entities/core-entities';
import { ProfileModalProps } from '../../../../features/entities/profile-entities';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import { DefaultTheme } from '../../../../theme';
import SeeMoreList from '../../../common/see-more/see-more-list';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
import PortfolioCard from './portfolio-card';
import {
  PortfolioCardContainer,
  PortfolioLogoContainer,
} from './portfolio-card/styles';
import PortfolioModal from './portfolio-modal';

type ProfilePortfolioProps = {
  portfolios: Portfolio[];
  isEditable?: boolean;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  id: string;
  providerName: string;
};

const ProfilePortfolio = ({
  portfolios,
  isEditable = false,
  toggleEditModal,
  id,
  providerName,
}: ProfilePortfolioProps) => {
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const viewLimit = isTablet ? 3 : 6;

  const [previewPortfolio, setPreviewPortfolio] = useState<
    Portfolio | undefined
  >(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => setModalOpen(!modalOpen);

  const handleEditModal = (portfolio?: Portfolio) => {
    if (isEditable && toggleEditModal) {
      toggleEditModal({
        open: true,
        targetId: portfolio?.provider_portfolio_id,
        type: ProfileBlocks.Portfolios,
      });
    } else {
      setPreviewPortfolio(portfolio);
      handleModal();
    }
  };
  const AddPortfolioCard = () => {
    return (
      <PortfolioCardContainer hoverable onClick={() => handleEditModal()}>
        <PortfolioLogoContainer>
          <AddNew width={64} height={64} color={theme.colors.white_1} />
        </PortfolioLogoContainer>
      </PortfolioCardContainer>
    );
  };

  const content = useMemo<ReactNode[]>(() => {
    const content: ReactNode[] = [];

    if (isEditable) {
      content.push(<AddPortfolioCard />);
    }
    portfolios.forEach((portfolio) => {
      content.push(
        <PortfolioCard portfolio={portfolio} onClick={handleEditModal} />,
      );
    });
    return content;
  }, [portfolios, isEditable]);

  return (
    <>
      <ProfileSection
        title={t('profile_portfolio|portfolioProfileTitle', {
          companyName: providerName,
        })}
        id={id}
        level={2}
        isEditable={isEditable}
        handleEdit={() => handleEditModal()}
        hide={!portfolios.length}
        emptyState={
          <ProfileEmptySection
            title={t('profile_portfolio|portfolioEmptyTitle')}
            subtitle={t('profile_portfolio|portfolioEmptySubtitle')}
            cta={t('profile_portfolio|portfolioEmptyCta')}
            sectionName="Portfolio"
            handleClick={handleEditModal}
            isEditable={isEditable}
          />
        }
      >
        <SeeMoreList
          limit={viewLimit}
          grid={{ sm: 1, md: 1, lg: 2, column: 3, gutter: 24 }}
          content={content}
        />
      </ProfileSection>
      {!isEditable && previewPortfolio && (
        <PortfolioModal
          open={modalOpen}
          handleCancel={handleModal}
          portfolio={previewPortfolio}
        />
      )}
    </>
  );
};

export default ProfilePortfolio;
