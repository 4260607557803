import { GrantBlock } from '..';
import { GrantResult } from '../../../../features/entities/grants-entities';
import SingleGrantSections from '../../../../features/enums/single-grant-sections';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import SingleHeaderMenu from '../../../common/single-header-menu';
import Typography from '../../../common/typography';
import { GrantLogo } from '../../card/styles';
import { GrantHeaderContainer, GrantHeaderContent } from './styles';

export interface GrantHeaderProps extends GrantResult {
  sections: SingleGrantSections[];
  blocks: { [block_name: string]: GrantBlock };
}

const GrantHeader = ({
  grant_display_title,
  grant_logo,
  province,
  country,
  grant_description_short,
  sections,
  blocks,
  city,
}: GrantHeaderProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return (
    <GrantHeaderContainer>
      <GrantHeaderContent>
        <GrantLogo
          src={grant_logo}
          alt={`${grant_display_title}${province ? ` ${province}` : ''}${
            country ? ` ${country}` : ''
          }`}
          $size={'large'}
        >
          {grant_display_title[0]}
        </GrantLogo>

        <Div
          flex="column"
          gap={24}
          style={{ paddingTop: 16 }}
          tablet={{ gap: 17, style: { paddingTop: 0 } }}
        >
          <Div
            flex="row"
            justify="space-between"
            gap={8}
            style={{ width: '100%' }}
          >
            <Typography.Title
              level={1}
              elementTheme="h4"
              noMargin
              tablet={{ textAlign: 'center' }}
            >
              {grant_display_title}
            </Typography.Title>
            <Typography
              textAlign="right"
              elementTheme="body3"
              color={theme.colors.grey_2}
              style={{ width: '250px' }}
            >
              {city}
              {!!city && province && ', '}
              {province}
              {(!!city || province) && country && ', '}
              {country}
            </Typography>
          </Div>
          <Typography
            color={theme.colors.grey_2}
            tablet={{ textAlign: 'center' }}
          >
            {grant_description_short}
          </Typography>
        </Div>
      </GrantHeaderContent>
      <SingleHeaderMenu
        items={sections
          .filter((key) => !blocks[key].hide)
          .map((key) => ({
            label: blocks[key].label,
            id: blocks[key].id,
          }))}
      />
    </GrantHeaderContainer>
  );
};

export default GrantHeader;
