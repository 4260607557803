import Loading from '@hellodarwin/core/lib/components/loading';
import Form from 'antd/es/form';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import GrantForm, {
  GrantFormValues,
} from '../../components/grants/grant-form/grant-form';
import {
  fetchGrant,
  selectGrantById,
} from '../../features/api/slices/grants-slice';
import { useNewAdminApi } from '../../features/api/use-admin-api';
import { AdminPagesForms } from '../single-rfp-page';

interface SingleGrantFormProps {
  grantID: string;
}

const SingleGrantForm = ({ grantID }: SingleGrantFormProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<GrantFormValues>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setForms] = useState<AdminPagesForms>({
    grantForm: undefined,
  });
  const grant = useAppSelector((state) =>
    selectGrantById(state, grantID || ''),
  );

  useEffect(() => {
    if (!grant) {
      dispatch(fetchGrant({ api, grantId: grantID }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantID]);

  if (!grantID) {
    return <Loading />;
  }
  if (!grant) {
    return <Loading />;
  }

  return <GrantForm grant={grant} form={form} setForms={setForms}></GrantForm>;
};

export default SingleGrantForm;
