import message from 'antd/es/message';
import {
  AdminFormResponse,
  FormPageResponse,
  InitialFormPageResponse,
  MappedFormBuilderPage,
} from '../../../../features/entities/form-entities';
import { FormBuilderFunctions } from '../types';

export const findInfoByPageId = ({
  pageId,
  pages,
}: {
  pageId: string;
  pages: FormPageResponse[];
}): FormPageResponse => {
  return (
    pages.find((page) => page.form_page_id === pageId) ??
    InitialFormPageResponse
  );
};

export const handleReorderFormPages = ({
  formPageId,
  functions,
  formResponse,
  page_order,
}: {
  page_order: number;
  formPageId: string;
  formResponse: AdminFormResponse;
  functions: FormBuilderFunctions;
}) => {
  const pages = [...formResponse.pages];
  pages.sort((g1, g2) => g1.page_number - g2.page_number);

  const movingPageID = pages.findIndex(
    (page) => page.form_page_id === formPageId,
  );
  const [removedPage] = pages.splice(movingPageID, 1);

  pages.splice(page_order, 0, removedPage);

  const pagesResponse: FormPageResponse[] = [];
  pages.forEach((page, index) => {
    pagesResponse.push({
      ...page,
      page_number: index,
    });
  });

  functions.updateFormPages({ ...formResponse, pages: pagesResponse });
};

export const handleDeleteFormPage = ({
  form_id,
  form_page_id,
  formResponse,
  formPages,
  functions,
}: {
  form_id: string;
  form_page_id: string;
  formResponse: AdminFormResponse;
  formPages: MappedFormBuilderPage;
  functions: FormBuilderFunctions;
}) => {
  if (formResponse.pages?.length > 1) {
    const deletedIndex = formPages[form_page_id].entity.page_number;
    const newFormPages: MappedFormBuilderPage = { ...formPages };
    delete newFormPages[form_page_id];

    var newAdminResponse: AdminFormResponse = {
      form_id: form_id,
      pages: [],
    };
    for (const pageIndex in newFormPages) {
      const page = newFormPages[pageIndex];
      newAdminResponse.pages.push({
        form_page_id: page.id,
        page_number:
          page.entity.page_number <= deletedIndex
            ? page.entity.page_number
            : --page.entity.page_number,
        page_title_en: page.entity.page_title_en,
        page_title_fr: page.entity.page_title_fr,
        form_id,
        groups: [],
      });
    }

    newAdminResponse.pages.push({
      form_page_id,
      page_number: 0,
      form_id: form_id,
      groups: [],
      deleted: true,
    });

    functions.updateFormPages(newAdminResponse);
  } else {
    message.error('The form has to have a least one page');
  }
};

export const handleNewPageDrop_FromControls = ({
  form_id,
  functions,
  page_number,
}: {
  form_id: string;
  functions: FormBuilderFunctions;
  page_number: number;
}) => {
  let formPage: FormPageResponse = {
    form_page_id: '',
    page_number,
    page_title_en: 'New Page Title',
    page_title_fr: 'Nouveau titre de page',
    form_id,

    groups: [],
  };

  functions.createPage(formPage);
};
