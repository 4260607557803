import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';

const InstagramLogo = (props: IconProps) => {
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.7 1.5H22.3C26.8239 1.5 30.5 5.17614 30.5 9.7V22.3C30.5 24.4748 29.6361 26.5605 28.0983 28.0983C26.5605 29.6361 24.4748 30.5 22.3 30.5H9.7C5.17614 30.5 1.5 26.8239 1.5 22.3V9.7C1.5 7.52523 2.36393 5.43952 3.90172 3.90172C5.43952 2.36393 7.52523 1.5 9.7 1.5ZM9.4 3.5C7.83522 3.5 6.33453 4.12161 5.22807 5.22807C4.12161 6.33453 3.5 7.83522 3.5 9.4V22.6C3.5 25.8611 6.13886 28.5 9.4 28.5H22.6C24.1648 28.5 25.6655 27.8784 26.7719 26.7719C27.8784 25.6655 28.5 24.1648 28.5 22.6V9.4C28.5 6.13886 25.8611 3.5 22.6 3.5H9.4ZM23.875 6.75C24.2397 6.75 24.5894 6.89487 24.8473 7.15273C25.1051 7.41059 25.25 7.76033 25.25 8.125C25.25 8.48967 25.1051 8.83941 24.8473 9.09727C24.5894 9.35513 24.2397 9.5 23.875 9.5C23.5103 9.5 23.1606 9.35513 22.9027 9.09727C22.6449 8.83941 22.5 8.48967 22.5 8.125C22.5 7.76033 22.6449 7.41059 22.9027 7.15273C23.1606 6.89487 23.5103 6.75 23.875 6.75ZM16 9C17.8565 9 19.637 9.7375 20.9497 11.0503C22.2625 12.363 23 14.1435 23 16C23 17.8565 22.2625 19.637 20.9497 20.9497C19.637 22.2625 17.8565 23 16 23C14.1435 23 12.363 22.2625 11.0503 20.9497C9.7375 19.637 9 17.8565 9 16C9 14.1435 9.7375 12.363 11.0503 11.0503C12.363 9.7375 14.1435 9 16 9ZM16 11C14.6739 11 13.4021 11.5268 12.4645 12.4645C11.5268 13.4021 11 14.6739 11 16C11 17.3261 11.5268 18.5979 12.4645 19.5355C13.4021 20.4732 14.6739 21 16 21C17.3261 21 18.5979 20.4732 19.5355 19.5355C20.4732 18.5979 21 17.3261 21 16C21 14.6739 20.4732 13.4021 19.5355 12.4645C18.5979 11.5268 17.3261 11 16 11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InstagramLogo;
