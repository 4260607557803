import {
  AppState,
  Auth0Provider,
  AuthorizationParams,
} from '@auth0/auth0-react';
import { PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorNetwork from '../../components/error/network';
import { AxiosError } from '../../plugins/axios';
import { AppType } from '../entities';
import LoginProvider from './login-provider';

export interface OauthOptions {
  domain: string;
  client_id: string;
  audience: string;
  scope: string;
}

export interface Auth0ProviderWithNavigateProps extends PropsWithChildren {
  config?: OauthOptions;
  error?: AxiosError;
  app: AppType;
}

export const Auth0ProviderWithNavigate = ({
  children,
  config,
  error,
  app,
}: Auth0ProviderWithNavigateProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const { client_id, domain, scope, audience, isError } = useMemo(() => {
    return {
      client_id: config?.client_id || '',
      domain: config?.domain || '',
      scope: config?.scope || '',
      audience: config?.audience || '',
      isError: !config?.client_id && !config?.domain,
    };
  }, [config]);

  const authorizationParams: AuthorizationParams = useMemo(() => {
    const params: AuthorizationParams = {
      redirect_uri: window?.location?.origin || '',
      scope: app === 'admin' ? 'profile' : scope,
      audience: audience,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    };

    return params;
  }, [window.location, app, scope, audience]);

  if (isError) {
    return <ErrorNetwork errorMessage={error?.message ?? ''} />;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={client_id}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      <LoginProvider domain={domain} app={app}>
        {children}
      </LoginProvider>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
