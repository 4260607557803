import styled, {
  DefaultTheme,
  ThemeContext,
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
} from 'styled-components';

export default styled;
export {
  ThemeContext,
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
  type DefaultTheme,
};
