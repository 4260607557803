const hostname =
  typeof window !== 'undefined' ? window?.location?.hostname : '';
const port = typeof window !== 'undefined' ? window?.location?.port : '';

const getWebsiteURL = (): string => {
  const websiteUrl = hostname.includes('localhost')
    ? 'http://localhost:8000'
    : hostname.includes('staging')
      ? 'website-git-development-hello-darwin.vercel.app'
      : 'https://hellodarwin.com';
  return websiteUrl;
};

const getClientAppURL = (): string => {
  const clientAppUrl = hostname.includes('localhost')
    ? 'http://localhost:3000'
    : hostname.includes('staging') || hostname.includes('development')
      ? 'https://app.staging.darwinrevolution.com/'
      : 'https://app.hellodarwin.com';
  return clientAppUrl;
};

const getOrigin = (): string => {
  const origin = hostname.includes('localhost')
    ? port.includes('3000')
      ? 'client'
      : port.includes('3001')
        ? 'admin'
        : port.includes('3002')
          ? 'partner'
          : port.includes('8000') || port.includes('9000')
            ? 'website'
            : 'other'
    : hostname.includes('app')
      ? 'client'
      : hostname.includes('partner')
        ? 'partner'
        : hostname.includes('admin')
          ? 'admin'
          : 'admin';
  return origin;
};

const isDev = hostname.includes('localhost') || hostname.includes('staging');

const handleExternalLinkCLick = (path: string) => {
  window?.open(path, '_blank');
};
export default getWebsiteURL;
export { getClientAppURL, getOrigin, handleExternalLinkCLick, isDev };
