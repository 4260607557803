import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchAdminById,
  selectAdminById,
} from '../../features/api/slices/admins-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import AdminSettingsForm from './admin-settings-form';

const EditAdminSettingsPage = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { adminId } = useParams<{ adminId: string }>();

  const admin = useAppSelector((state) =>
    selectAdminById(state, adminId ?? ''),
  );
  useEffect(() => {
    dispatch(fetchAdminById({ api, adminId: adminId ?? '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);
  return (
    <PageLayout
      app="admin"
      tabTitle={'Settings'}
      title={'Settings'}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Settings',
        },
      ]}
    >
      <AdminSettingsForm admin={admin} />
    </PageLayout>
  );
};

export default EditAdminSettingsPage;
