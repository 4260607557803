import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import EditFormBuilder from '../../components/forms/single-form/edit-form-builder';
import {
  fetchFormById,
  selectForm,
} from '../../features/api/slices/forms-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

const SingleFormPage = () => {
  const api = useAdminApi();

  const params = useParams<{
    id: string;
  }>();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formResponse = useAppSelector(selectForm);

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      fetchInitialForm(params.id);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInitialForm = async (formId: string) => {
    await dispatch(fetchFormById({ api, formId }));
  };

  if (!params.id) return <></>;

  return (
    <PageLayout
      app="admin"
      tabTitle={params.id ? 'Edit Form' : 'Create Form'}
      breadcrumbs={[
        {
          breadcrumbName: 'Forms',
          path: '/forms',
        },
        {
          breadcrumbName: params.id ? 'Edit Form' : 'Create Form',
        },
      ]}
      isLoading={isLoading}
      noPadding
    >
      <EditFormBuilder formResponse={formResponse} />
    </PageLayout>
  );
};

export default SingleFormPage;
