import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import message from 'antd/es/message';
import { useEffect, useState } from 'react';
import { useAdminApi } from '../../features/api/use-admin-api';
import { copyIntoClipboard } from '../clipboard';
import Auth0UserButtons from './auth0-user-buttons';

type ContactAuth0ButtonsProps = {
  contact: Contact;
  destination: string;
  compactView?: boolean;
};

const ContactAuth0Buttons = ({
  contact,
  destination,
  compactView = false,
}: ContactAuth0ButtonsProps) => {
  const [resetPasswordLink, setResetPasswordLink] = useState<string>('');
  const [linkIsValid, setLinkIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const api = useAdminApi();
  const [auth0UserID, setAuth0UserID] = useState<string>('');
  const [auth0GoogleUserID, setAuth0GoogleUserID] = useState<string>('');
  const [auth0UserIDMatch, setAuth0UserIDMatch] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      setIsLoading(true);
      if (isMounted) {
        setAuth0UserID(contact.auth_id || '');
        setAuth0GoogleUserID(contact.auth_id || '');
        setAuth0UserIDMatch(contact.auth_id_match ?? false);
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  //Create user Auth0 account and send activation email through SendGrid
  const auth0CreateAccount = () => {
    (async () => {
      try {
        setIsLoading(true);
        const newUserInfo = await api.createAuth0User(
          contact?.contact_id,
          destination,
        );
        setAuth0UserID(newUserInfo.auth_id);
        setResetPasswordLink(newUserInfo.reset_link);
        message.success('Auth0 user created! Activation email sent');
      } catch (err: any) {
        if (err.response.data.error_code === 5024)
          message.error('Failed to find contact by ID');
        else message.error('Failed to create Auth0 account');
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const auth0resetPasswordEmail = () => {
    (async () => {
      try {
        setIsLoading(true);
        await api.resetAuth0PasswordEmail(contact?.contact_id, destination);
        setResetPasswordLink('');
        message.success('Reset password email sent!');
      } catch (err: any) {
        if (err.response.data.error_code === 5024)
          message.error('Failed to find contact by ID');
        else message.error('Failed to send reset password email');
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const auth0resetPasswordLink = () => {
    (async () => {
      try {
        setLinkIsValid(true);
        setIsLoading(true);
        setResetPasswordLink(
          await api.resetAuth0PasswordLink(contact?.contact_id, destination),
        );
        message.success('Reset password link generated successfully!');
      } catch (err: any) {
        if (err.response.data.error_code === 5024)
          message.error('Failed to find contact by ID');
        else message.error('Failed to generate reset password link');
      } finally {
        setIsLoading(false);
      }
    })();
  };

  useEffect(() => {
    if (resetPasswordLink && linkIsValid) {
      copyIntoClipboard(resetPasswordLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordLink]);

  const saveContact = async () => {
    if (contact) {
      try {
        setIsLoading(true);
        const updatedContact = { ...contact, auth_id: auth0UserID };
        await api.updateContact(updatedContact);
        message.success('Contact updated successfully!');
        const id = await api.getAuth0UserID(contact?.email || '');
        setAuth0UserID(id.auth_id);
        setAuth0UserIDMatch(id.auth_id_match);
      } catch (err: any) {
        message.error('Failed to update contact');
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <Auth0UserButtons
      auth0CreateAccount={auth0CreateAccount}
      auth0GoogleUserID={auth0GoogleUserID}
      auth0UserID={auth0UserID}
      auth0UserIDMatch={auth0UserIDMatch}
      auth0resetPasswordEmail={auth0resetPasswordEmail}
      auth0resetPasswordLink={auth0resetPasswordLink}
      noGoogle
      isLoading={isLoading}
      compactView={compactView}
      saveContact={saveContact}
    />
  );
};

export default ContactAuth0Buttons;
