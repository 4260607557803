import { useMediaQuery } from 'react-responsive';
import { FundingExplorerFilterProps } from '..';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import FilterTagBar from '../tag-bar';
import AdvancedFiltersButton from './advanced-filters-button';
import IndustriesSelector from './industries-selector';
import FundingExplorerSearchBar from './search-bar';

export interface FilterHeaderProps extends FundingExplorerFilterProps {
  handleAdvancedFilters: () => void;
  advancedFiltersOpened: boolean;
}

const FilterHeader = (props: FilterHeaderProps) => {
  const { handleAdvancedFilters, advancedFiltersOpened } = props;

  const theme = useTheme();

  const isXl = useMediaQuery({ maxWidth: theme.breakpoints.l });

  if (isXl) {
    return (
      <Div flex="column" gap={24}>
        <Div flex="column" gap={12}>
          <FundingExplorerSearchBar {...props} />
          <Div flex="row" gap={12} tablet={{ flex: 'column' }}>
            <IndustriesSelector {...props} />
            <AdvancedFiltersButton
              handleAdvancedFilters={handleAdvancedFilters}
              advancedFiltersOpened={advancedFiltersOpened}
            />
          </Div>
        </Div>
        <FilterTagBar {...props} />
      </Div>
    );
  } else {
    return (
      <Div flex="column" gap={8} tablet={{ gap: 24 }}>
        <Div flex="row" gap={8}>
          <IndustriesSelector {...props} />

          <FundingExplorerSearchBar {...props} />
          <AdvancedFiltersButton
            handleAdvancedFilters={handleAdvancedFilters}
            advancedFiltersOpened={advancedFiltersOpened}
          />
        </Div>
        <FilterTagBar {...props} />
      </Div>
    );
  }
};
export default FilterHeader;
