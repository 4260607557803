import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Happy = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0912 21.0912C20.4226 21.7598 19.6289 22.2901 18.7553 22.652C17.8818 23.0138 16.9455 23.2 16 23.2C15.0545 23.2 14.1182 23.0138 13.2447 22.652C12.3711 22.2901 11.5774 21.7598 10.9088 21.0912M30.4 16C30.4 17.891 30.0275 19.7635 29.3039 21.5106C28.5802 23.2577 27.5195 24.8451 26.1823 26.1823C24.8452 27.5195 23.2577 28.5802 21.5106 29.3038C19.7636 30.0275 17.891 30.4 16 30.4C14.109 30.4 12.2365 30.0275 10.4894 29.3038C8.74228 28.5802 7.15483 27.5195 5.81767 26.1823C4.4805 24.8451 3.41981 23.2577 2.69614 21.5106C1.97247 19.7635 1.60001 17.891 1.60001 16C1.60001 12.1809 3.11714 8.51816 5.81767 5.81764C8.51819 3.11711 12.1809 1.59998 16 1.59998C19.8191 1.59998 23.4818 3.11711 26.1823 5.81764C28.8829 8.51816 30.4 12.1809 30.4 16ZM12.4 12.4C12.4 13.0624 12.1312 13.6 11.8 13.6C11.4688 13.6 11.2 13.0624 11.2 12.4C11.2 11.7376 11.4688 11.2 11.8 11.2C12.1312 11.2 12.4 11.7376 12.4 12.4ZM11.8 12.4H11.8128V12.424H11.8V12.4ZM20.8 12.4C20.8 13.0624 20.5312 13.6 20.2 13.6C19.8688 13.6 19.6 13.0624 19.6 12.4C19.6 11.7376 19.8688 11.2 20.2 11.2C20.5312 11.2 20.8 11.7376 20.8 12.4ZM20.2 12.4H20.2128V12.424H20.2V12.4Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Happy;
