import styled from '@hellodarwin/core/lib/plugins/styled';
import { fadeIn } from '@hellodarwin/core/lib/theme/keyframes';
import Input from 'antd/es/input/Input';

export const GrantsSearchInput = styled(Input)<{ $noStyle?: boolean }>`
  ${({ $noStyle, theme }) =>
    !$noStyle
      ? `
      font-size: 24px;
      border-bottom: 2px solid ${theme.colors.grey_4};
      border-radius: 0;
      width: 100%;

      &:focus {
        border-bottom: 2px solid ${theme.colors.purple_2};
      }
      &:hover {
        border-bottom: 2px solid ${theme.colors.purple_1};
      }
`
      : ``}
`;

export const GrantSearchDropdown = styled.div`
  padding: 24px 40px 24px 16px;
  position: absolute;
  top: calc(100% + 16px);
  width: 100%;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  background: ${({ theme }) => theme.colors.white_2};
  animation: ${fadeIn} 500ms linear;
`;
