import Collapse from '@hellodarwin/core/lib/components/common/Collapse';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  GrantContentRaw,
  GrantContext,
  GrantManualContext,
} from '@hellodarwin/core/lib/features/entities';
import {
  getFormattedDate,
  getShortFormattedDate,
} from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Refresh from '@hellodarwin/icons/dist/icons/Refresh';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Radio from 'antd/es/radio';
import Select from 'antd/es/select';
import Space from 'antd/es/space';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import GrantValidationButton from '../../components/grants/grant-validation/grant-validation-button';
import {
  createGrantContentRaw,
  createGrantContext,
  fetchGrantContentRawLinks,
  fetchGrantContext,
  scrapeGrantContentRawLinks,
  selectGrantContentRawLinksByGrantID,
  selectGrantContextEn,
  selectGrantContextFr,
} from '../../features/api/slices/grants-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';
import theme from '../../theme';
import { AdminPagesForms } from '../single-rfp-page';
import AdminTag from '../single-rfp-page/admin-tag';
const { TextArea } = Input;

export type GrantContentFormValues = {
  rawContentEn: string;
  manualContentEn: string;
  rawContentFr: string;
  manualContentFr: string;
};

const SingleGrantContextPage = () => {
  const [form] = Form.useForm<GrantContentFormValues>();
  const [contextsUsed, setContextsUsed] = useState('');
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [locale, setLocale] = useState('en');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingLinks, setIsGeneratingLinks] = useState(false);
  const { t } = useTranslations();
  const params = useParams<{
    id: string;
  }>();

  // Variable is used to keep the previous value when calling onChange
  var previousContextsUsed = useRef('');

  const allowedFileTypes = [
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'rtf',
    'txt',
    'odt',
    'ods',
    'odp',
  ];

  const grantContextEn = useAppSelector((state) =>
    selectGrantContextEn(state, params.id || ''),
  );

  const grantContextFr = useAppSelector((state) =>
    selectGrantContextFr(state, params.id || ''),
  );

  function getActiveRawContext(): GrantContentRaw {
    if (locale === 'en') {
      return grantContextEn.raw_context;
    }
    return grantContextFr.raw_context;
  }

  function getActiveRawContextFieldName(): string {
    if (locale === 'en') {
      return 'rawContentEn';
    }
    return 'rawContentFr';
  }

  const [forms, setForms] = useState<AdminPagesForms>({
    grantContentForm: undefined,
  });

  const saveEn = async () => {
    try {
      const grantContextFormValues = forms.grantContentForm?.getFieldsValue()!;

      if (grantContextFormValues === undefined) {
        return;
      }

      const updatedGrantContentRawEn: GrantContentRaw = {
        ...grantContextEn.raw_context,
        content: grantContextFormValues.rawContentEn,
        locale: 'en',
      };

      const updatedGrantManualContextEn: GrantManualContext = {
        ...grantContextEn.manual_context,
        content: grantContextFormValues.manualContentEn,
        contexts_used: contextsUsed!,
        locale: 'en',
      };

      const fullGrantContextEn: GrantContext = {
        grant_id: grantContextEn.grant_id,
        manual_context: updatedGrantManualContextEn,
        raw_context: updatedGrantContentRawEn,
      };

      await dispatch(
        createGrantContext({ api, grantContext: fullGrantContextEn }),
      );
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };
  const saveFr = async () => {
    try {
      const grantContextFormValues = forms.grantContentForm?.getFieldsValue()!;

      if (grantContextFormValues === undefined) {
        return;
      }

      const updatedGrantContentRawFr: GrantContentRaw = {
        ...grantContextFr.raw_context,
        content: grantContextFormValues.rawContentFr,
        locale: 'fr',
      };

      const updatedGrantManualContextFr: GrantManualContext = {
        ...grantContextFr.manual_context,
        content: grantContextFormValues.manualContentFr,
        contexts_used: contextsUsed!,
        locale: 'fr',
      };

      const fullGrantContextFr: GrantContext = {
        grant_id: grantContextFr.grant_id,
        manual_context: updatedGrantManualContextFr,
        raw_context: updatedGrantContentRawFr,
      };

      await dispatch(
        createGrantContext({ api, grantContext: fullGrantContextFr }),
      );
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const onSave = async () => {
    try {
      saveEn();
      saveFr();
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    } finally {
      message.success(`Saved Scraped Contexts!`);
    }
  };

  const grantContentRawLinks = useAppSelector((state) =>
    selectGrantContentRawLinksByGrantID(state, params.id || ''),
  );
  const filteredLinks = useMemo(() => {
    if (!grantContentRawLinks) {
      return [];
    }
    return grantContentRawLinks.filter((link) => {
      const extension = link.unique_url.split('.').pop();
      return !allowedFileTypes.includes(extension!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantContentRawLinks]);

  const filteredFiles = useMemo(() => {
    if (!grantContentRawLinks) {
      return [];
    }
    return grantContentRawLinks.filter((link) => {
      const extension = link.unique_url.split('.').pop();
      return allowedFileTypes.includes(extension!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantContentRawLinks]);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      grantContentForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(fetchGrantContext({ api, grantId: params.id, locale: 'en' }));
    dispatch(fetchGrantContext({ api, grantId: params.id, locale: 'fr' }));
    dispatch(fetchGrantContentRawLinks({ api: newApi, grantId: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contextsUsed && previousContextsUsed.current !== '') {
      onSave();
    }
    previousContextsUsed.current = contextsUsed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextsUsed]);

  useEffect(() => {
    if (grantContextEn?.manual_context?.contexts_used) {
      setContextsUsed(grantContextEn.manual_context.contexts_used);
    }
  }, [grantContextEn]);
  useEffect(() => {
    if (grantContextFr?.manual_context?.contexts_used) {
      setContextsUsed(grantContextFr.manual_context.contexts_used);
    }
  }, [grantContextFr]);

  if (!params.id) return <Loading />;

  if (!grantContextEn) {
    return <Loading />;
  }
  if (!grantContextFr) {
    return <Loading />;
  }

  const goToGrantSinglePage = () => {
    navigate(`/grants/${params.id}`);
  };

  const initialValues: GrantContentFormValues = {
    rawContentEn: grantContextEn.raw_context.content || '',
    manualContentEn: grantContextEn.manual_context.content || '',
    rawContentFr: grantContextFr.raw_context.content || '',
    manualContentFr: grantContextFr.manual_context.content || '',
  };

  const changeScrapeContext = async (value: string) => {
    try {
      if (!params.id) {
        return;
      }
      setLocale(value);
    } catch (e: any) {
      message.error('Error !');
    }
  };

  const generateNewScrapeContext = async () => {
    try {
      setIsGenerating(true);
      if (!params.id) {
        return;
      }
      const newGrantContentRawEn = await dispatch(
        createGrantContentRaw({
          api,
          grantId: params.id,
          locale: 'en',
        }),
      ).unwrap();
      const newGrantContentRawFr = await dispatch(
        createGrantContentRaw({
          api,
          grantId: params.id,
          locale: 'fr',
        }),
      ).unwrap();

      form.setFieldValue('rawContentEn', newGrantContentRawEn.content);
      form.setFieldValue('rawContentFr', newGrantContentRawFr.content);
    } catch (e: any) {
      message.error('Error !');
    } finally {
      setIsGenerating(false);
    }
  };

  const generateScrapeContextLinks = async () => {
    try {
      if (!params.id) {
        return;
      }
      setIsGeneratingLinks(true);
      const links = await dispatch(
        scrapeGrantContentRawLinks({
          api: newApi,
          grantId: params.id,
        }),
      ).unwrap();
      if (!links) {
        message.success('No links found.');
        return;
      }
      const fileCount = links.filter((link) => {
        const extension = link.unique_url.split('.').pop();
        return allowedFileTypes.includes(extension!);
      }).length;
      if (fileCount > 0) {
        message.success(`${fileCount} file(s) found.`);
      } else {
        message.success('No files found.');
      }
      message.success(`${links.length} link(s) found.`);
    } catch (e: any) {
      message.error('Error ! Failed to scrape links and files.');
      console.error(e);
    } finally {
      setIsGeneratingLinks(false);
    }
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={'Context'}
      title={'Context'}
      handleBack={goToGrantSinglePage}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('programs_admin|programsTitle'),
          path: '/grants',
        },
        {
          breadcrumbName: 'Single Grant Page',
          path: `/grants/${params.id}`,
        },
        {
          breadcrumbName: 'Scrape Context',
        },
      ]}
    >
      <Div flex="row" align="center" justify="space-between">
        <Div>
          <Button
            type="primary"
            onClick={onSave}
            size="large"
            color={'primary'}
            style={{ width: '150px' }}
          >
            Save
          </Button>
        </Div>
        <Div alignSelf="center" align="center">
          <GrantValidationButton grantId={params.id} filterByType="scrape" />
          {getActiveRawContext().generated_at && (
            <AdminTag
              text={
                'Updated ' +
                getShortFormattedDate(getActiveRawContext().generated_at || '')
              }
              tooltip={`${getFormattedDate(getActiveRawContext().generated_at)}`}
              color={'gold'}
            />
          )}
        </Div>
        <Div flex="column" align="start">
          <label
            htmlFor="context-select"
            style={{
              fontSize: '14px',
              marginBottom: '8px',
            }}
          >
            Generation uses
          </label>
          <Select
            id="context-select"
            placeholder="Select context used"
            value={contextsUsed}
            onChange={(value: string) => setContextsUsed(value)}
          >
            <Select.Option value="bothContexts">Both contexts</Select.Option>
            <Select.Option value="manualContext">
              Manual context only
            </Select.Option>
            <Select.Option value="rawContext">Raw context only</Select.Option>
          </Select>
        </Div>
        <Div flex="row" align="center" justify="center" gap={16}>
          <Button
            onClick={() => generateNewScrapeContext()}
            loading={isGenerating}
            icon={
              <Refresh
                style={{ color: theme.colors.purple_1 }}
                width={14}
                height={14}
              />
            }
          >
            Generate Section Only
          </Button>
          <Button
            onClick={generateScrapeContextLinks}
            loading={isGeneratingLinks}
          >
            Generate Section Links
          </Button>
        </Div>
        <Div flex="row" align="center" justify="flex-end">
          <Radio.Group
            size={'large'}
            onChange={(e) => changeScrapeContext(e.target.value)}
            defaultValue="en"
            buttonStyle="solid"
          >
            <Space direction="horizontal">
              <Radio.Button value="en">English</Radio.Button>
              <Radio.Button value="fr">Français</Radio.Button>
            </Space>
          </Radio.Group>
        </Div>
      </Div>

      <Card style={{ padding: '1rem' }}>
        {filteredLinks.length > 0 && (
          <Collapse
            style={{ marginBottom: '1rem' }}
            items={[
              {
                id: 'id',
                styles: {
                  header: {
                    backgroundColor: theme.colors.beige_2,
                    padding: '8px 24px',
                  },
                },
                title: (
                  <>
                    <Typography>Links</Typography>
                  </>
                ),
                children: filteredLinks.map((link) => (
                  <Div style={{ marginBottom: '1rem' }}>
                    <Link to={link.unique_url} target="_blank">
                      {link.unique_url}
                    </Link>
                  </Div>
                )),
              },
            ]}
            ExpandIcon={({ isActive }) => <TabArrow down={isActive} />}
          />
        )}
        {filteredFiles.length > 0 && (
          <Collapse
            style={{ marginBottom: '1rem' }}
            items={[
              {
                id: 'id',
                styles: {
                  header: {
                    backgroundColor: theme.colors.beige_2,
                    padding: '8px 24px',
                  },
                },
                title: (
                  <>
                    <Typography>Files</Typography>
                  </>
                ),
                children: filteredFiles.map((link) => (
                  <Div style={{ marginBottom: '1rem' }}>
                    <Link to={link.unique_url} target="_blank">
                      {link.unique_url}
                    </Link>
                  </Div>
                )),
              },
            ]}
            ExpandIcon={({ isActive }) => <TabArrow down={isActive} />}
          />
        )}
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="grantContext"
        >
          <Div flex="row" align="start" justify="space-between">
            <Div flex="column" align="start" justify="space-between">
              <Form.Item
                label="Manual Content EN"
                name="manualContentEn"
                style={{ width: '95%' }}
              >
                <TextArea rows={12} />
              </Form.Item>
              <Form.Item
                label="Manual Content FR"
                name="manualContentFr"
                style={{ width: '95%' }}
              >
                <TextArea rows={12} />
              </Form.Item>
            </Div>
            <Form.Item
              label="Scrape Content"
              name={getActiveRawContextFieldName()}
              style={{ width: '95%' }}
            >
              <TextArea rows={27} />
            </Form.Item>
          </Div>
        </Form>
      </Card>
    </PageLayout>
  );
};

export default SingleGrantContextPage;
