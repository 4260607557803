import Div from '@hellodarwin/core/lib/components/common/div';
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import Check from '@hellodarwin/icons/dist/icons/Check';
import Refereral from '@hellodarwin/icons/dist/icons/Refereral';
import Refresh from '@hellodarwin/icons/dist/icons/Refresh';
import Button from 'antd/es/button';
import { Dispatch, SetStateAction } from 'react';
import { grantPromptPropertiesTitles } from '../../pages/single-grant-page';

type GrantPromptButtonsProps = {
  section: string;
  setSelectedPrompt?: (prompt: HdChatPromptRequest) => void;
  setAddNewPromptDrawer?: (promptDrawer: boolean) => void;
  onSave: (() => Promise<void>) | undefined;
  grant?: AdminGrantResult | undefined;
  loading?: boolean;
  promptButtonDisabled?: boolean;
  loadingSections?: string[];
  prompts?: HdChatPromptRequest[];
  generateContent: () => void;
  canSave: boolean;
  setCanSave: Dispatch<SetStateAction<boolean>>;
};

const GrantPromptButtons = ({
  section,
  setSelectedPrompt,
  setAddNewPromptDrawer,
  onSave,
  grant,
  loading,
  promptButtonDisabled,
  loadingSections,
  prompts,
  generateContent,
  canSave,
  setCanSave,
}: GrantPromptButtonsProps) => {
  return (
    <>
      {grant?.grant_id !== '' && (
        <Div style={{ width: '200px' }}>
          <Button
            disabled={promptButtonDisabled}
            color={'primary'}
            onClick={() => {
              if (setSelectedPrompt && setAddNewPromptDrawer) {
                prompts?.forEach((doc: any) => {
                  if (
                    doc.name ===
                    (grantPromptPropertiesTitles.includes(section)
                      ? section
                      : section.slice(0, -3))
                  ) {
                    setSelectedPrompt(doc as HdChatPromptRequest);
                    setAddNewPromptDrawer(true);
                  }
                });
              }
            }}
          >
            <Refereral size={24}></Refereral>
          </Button>
          <Button
            onClick={() => {
              generateContent();
            }}
            disabled={loading}
            style={{ marginRight: '8px', marginLeft: '8px' }}
            color={'primary'}
          >
            <Refresh size={24}></Refresh>
          </Button>
          <Button
            color={'primary'}
            onClick={() => {
              onSave!();
              setCanSave(false);
            }}
            disabled={
              !canSave ||
              (loadingSections &&
                loadingSections.length > 0 &&
                loadingSections[0] === 'all')
            }
          >
            <Check size={24}></Check>
          </Button>
        </Div>
      )}
    </>
  );
};

export default GrantPromptButtons;
