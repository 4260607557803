import styled from '../../../../plugins/styled';
import Div from '../../../common/div';
export const InfoLineContainer = styled(Div)`
  padding: 27px 23px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey_4};
  &:last-child {
    border: none;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding: 27px 0;
  }
`;

export const SocialsContainer = styled(Div)`
  a {
    text-decoration: none;
    border: none;
    color: ${({ theme }) => theme.colors.grey_1};
    &:hover {
      color: ${({ theme }) => theme.colors.purple_2};
    }
  }
`;
