import Divider from 'antd/es/divider';
import {
  InitialTeamMember,
  TeamMember,
} from '../../../../features/entities/core-entities';
import { Provider } from '../../../../features/entities/providers-entities';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { Tablet } from '../../../common/Media';
import Div from '../../../common/div';
import ModalWithHeaderFooter from '../../../common/hd-modal/layouts/modal-with-header-footer';
import TeamList from './team-list';
import TeamMemberPreview from './team-member-preview';

type TeamPreviewModalProps = {
  provider: Provider;
  open: boolean;
  onCancel: () => void;
  teamMembers: TeamMember[];
  selectedMember?: TeamMember;
  setSelectedMember: (selectedMember: TeamMember | undefined) => void;
  setMembers?: (value: TeamMember[]) => void;
};

const TeamPreviewModal = ({
  provider,
  open,
  onCancel,
  teamMembers,
  selectedMember,
  setSelectedMember,
  setMembers,
}: TeamPreviewModalProps) => {
  const { t } = useTranslations();

  const handleClose = () => {
    setSelectedMember(undefined);
    onCancel();
  };

  return (
    <ModalWithHeaderFooter
      open={open}
      handleCancel={handleClose}
      size="large"
      header={
        <Tablet direction="down">
          <ModalWithHeaderFooter.Header
            title={t('profile_team|teamPreviewTitle')}
          />
        </Tablet>
      }
    >
      <Div flex="row" align="stretch">
        <TeamMemberPreview
          teamMember={selectedMember ?? InitialTeamMember}
          name={provider.name}
        />
        <Tablet direction="up">
          <Divider type="vertical" style={{ height: '100%' }} />
          <TeamList
            teamMembers={teamMembers}
            selectedMember={selectedMember ?? InitialTeamMember}
            setSelectedMember={setSelectedMember}
            setMembers={setMembers}
          />
        </Tablet>
      </Div>
    </ModalWithHeaderFooter>
  );
};

export default TeamPreviewModal;
