import { CancelRfpBody } from '@hellodarwin/core/lib/features/entities';
import message from 'antd/es/message';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  selectRfpsModal,
  selectSelectedRfp,
  toggleRfpsModal,
} from '../../../features/api/slices/rfp-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import { getRfpStatus } from '../../../features/utils';
import CancelRfpModal from './cancel-rfp-modal';

import CreateRfpForm from './create-rfp-form';
import RfpDiscountModal from './rfp-discount-modal';

const RfpActionModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const api = useAdminApi();
  const modal = useAppSelector(selectRfpsModal);
  const rfp = useAppSelector(selectSelectedRfp) || '';

  const handleClose = () => {
    dispatch(toggleRfpsModal({ isVisible: false, type: '' }));
  };

  const handleCancel = async (canceledReason: string) => {
    try {
      const newStatus = getRfpStatus(canceledReason);
      const cancelCtx: CancelRfpBody = {
        status: newStatus,
        canceled_reason: canceledReason,
      };

      await api.cancelRfp(rfp.rfp_id || '', cancelCtx);

      navigate(0);
      message.success('Rfp Canceled!');
      handleClose();
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
      handleClose();
    }
  };

  const sendDiscountNotification = async () => {
    if (!rfp.rfp_id) return;
    try {
      await api.sendDiscountNotification(rfp.rfp_id);
      message.success('Notification sent');
      handleClose();
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
      handleClose();
    }
  };

  switch (modal.type) {
    case 'create':
      return (
        <CreateRfpForm visible={modal.isVisible} handleClose={handleClose} />
      );
    case 'cancel':
      return (
        <CancelRfpModal
          modalVisible={modal.isVisible}
          handleCancel={handleCancel}
          closeModal={handleClose}
          matches={rfp.matches || []}
        />
      );
    case 'discount':
      return (
        <RfpDiscountModal
          modalVisible={modal.isVisible}
          sendDiscountNotification={sendDiscountNotification}
          closeModal={handleClose}
        />
      );
    default:
      return <></>;
  }
};

export default RfpActionModal;
