import { AppName } from '../../../features/entities/core-entities';
import getMeetingUrl from '../../../features/helpers/get-meeting-url';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import Typography from '../../common/typography';
import HubspotMeeting from '../../hubspot/hubspot-meeting';

interface FormMeetingProps {
  isICP: boolean;
}
const FormMeeting = ({ isICP }: FormMeetingProps) => {
  const { t } = useTranslations();

  const { selectedLocale } = useLocale();

  const meetingUrl = getMeetingUrl(
    selectedLocale,
    'client',
    isICP ? 'grants-qualification-icp' : 'grants-qualification',
  );

  return (
    <Div flex="column" align="center" gap={8} fitContent>
      <Typography elementTheme="subtitle2">
        {t('form_viewer|bookMeeting')}
      </Typography>
      <HubspotMeeting
        meetingUrl={meetingUrl}
        name={''}
        email={''}
        app={AppName.Client}
        companyName={''}
        phone={''}
      />
    </Div>
  );
};

export default FormMeeting;
