import Select from 'antd/es/select';
import React from 'react';
import { useTranslation } from '../../../../../plugins/i18n';
import { ExpertiseHeader } from './expertise-form';

type CategorySelectProps = {
  selectedCategories: string[] | undefined;
  categoriesOptions: string[];
  toggleCategory: (category: string) => void;
};

const CategorySelect = ({
  selectedCategories,
  categoriesOptions,
  toggleCategory,
}: CategorySelectProps) => {
  const { t } = useTranslation();

  const options = React.Children.toArray(
    categoriesOptions.map((category) => (
      <Select.Option key={category} value={category}>
        {t(`tags|${category}`)}
      </Select.Option>
    )),
  );

  return (
    <div>
      <ExpertiseHeader
        size="medium"
        title={t(`provider-profile|expertise.category.title`)}
        subtitle={t(`expertise|category.subtitle`)}
      />
      <Select
        mode={'multiple'}
        allowClear
        placeholder={t(`expertise|category.select.placeholder`)}
        value={selectedCategories}
        onSelect={toggleCategory}
        onDeselect={toggleCategory}
        defaultValue={selectedCategories}
        style={{ width: '100%' }}
        size="large"
      >
        {options}
      </Select>
    </div>
  );
};

export default CategorySelect;
