import { ExpandIconPosition } from '.';
import styled from '../../../plugins/styled';
import { CollapsePanelExtendedProps } from './CollapsePanel';

interface CollapsePanelContainerProps {
  $isOpened?: boolean;
  $noPadding?: boolean;
  $ghost?: CollapsePanelExtendedProps['ghost'];
  $align?: CollapsePanelExtendedProps['align'];
  $hasGap?: CollapsePanelExtendedProps['hasGap'];
}

export const CollapseContainer = styled.div``;

interface CollapsePanelHeaderProps {
  $expandIconPosition: ExpandIconPosition;
  $headerIsColumnOnTablet: CollapsePanelExtendedProps['headerIsColumnOnTablet'];
}
export const CollapsePanelHeader = styled.div<CollapsePanelHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ $expandIconPosition }) =>
    $expandIconPosition === 'start'
      ? 'row-reverse'
      : $expandIconPosition === 'bottom'
        ? 'column'
        : $expandIconPosition === 'top'
          ? 'column-reverse'
          : 'row'};

  ${({ $headerIsColumnOnTablet, theme, $expandIconPosition }) =>
    $headerIsColumnOnTablet
      ? `
          @media (max-width: ${theme?.breakpoints?.tablet}px) {
            flex-direction: ${
              ['start', 'top'].includes($expandIconPosition)
                ? 'column-reverse'
                : 'column'
            };
          }
          `
      : undefined}

  gap: 24px;
  cursor: pointer;
`;

export const CollapsePanelContainer = styled.div<CollapsePanelContainerProps>`
  ${(props) =>
    !props.$ghost
      ? `
      border: 1px solid ${props.theme.colors.primary};
      ${
        props.$hasGap
          ? `border-bottom: 1px solid ${props.theme.colors.primary};`
          : 'border-bottom:none;'
      }
      &:last-child {
        border-bottom: 1px solid ${props.theme.colors.primary};
      }
      background-color: ${props.theme.colors.white_1};`
      : undefined}

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  .ReactCollapse--content {
    padding: ${({ $noPadding }) => (!!$noPadding ? 0 : 24)}px;
    ${(props) =>
      !props.$ghost
        ? `border-top: 1px solid ${props.theme.colors.grey_4};`
        : undefined}
  }

  ${CollapsePanelHeader} {
    padding: ${({ $noPadding }) => (!!$noPadding ? 0 : '12px 24px')};

    ${(props) =>
      !props.$ghost
        ? `
      background-color: ${props.theme.colors.white_1};
      `
        : undefined}
  }
`;

export const CollapsePanelBody = styled.div``;
