import Div from '@hellodarwin/core/lib/components/common/div';
import {
  AdminGinContactResponse,
  GrantProviderFormValues,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Form from 'antd/es/form';
import notification from 'antd/es/notification';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  createGinContact,
  deleteGinContact,
  fetchGinContactsByGrantProviderId,
  selectGinContacts,
  updateGinContact,
} from '../../features/api/slices/gin-contacts-slice';
import {
  fetchGrantProvidedByProvider,
  selectGrantProvidedByProvider,
} from '../../features/api/slices/grants-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import FormRepresentative from './form-representative';
import SelectableContact from './selectable-contact';

type GrantProviderFormProps = {
  grantProviderId?: string;
};

const GrantProviderContactForm = ({
  grantProviderId,
}: GrantProviderFormProps) => {
  const [form] = Form.useForm<GrantProviderFormValues>();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const contacts = useAppSelector(selectGinContacts);
  const [selectedContact, setSelectedContact] = useState<number | null>(null);
  const grantProvided = useAppSelector(selectGrantProvidedByProvider);
  const { t } = useTranslations();
  useEffect(() => {
    if (grantProviderId) {
      dispatch(fetchGinContactsByGrantProviderId({ api, grantProviderId }));
      dispatch(
        fetchGrantProvidedByProvider({
          api,
          grantProviderId: grantProviderId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedContact !== null && !!contacts) {
      form.setFieldsValue({
        first_name: contacts[selectedContact].first_name,
        last_name: contacts[selectedContact].last_name,
        title: contacts[selectedContact].title,
        email: contacts[selectedContact].email,
        phone_number: contacts[selectedContact].phone_number,
        extension: contacts[selectedContact].extension,
        language: contacts[selectedContact].language,
        notes: contacts[selectedContact].notes,
        grant_ids: contacts[selectedContact].grant_ids,
      });
    } else {
      form.setFieldsValue(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact]);

  const initialValues: GrantProviderFormValues = {
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone_number: '',
    extension: '',
    language: '',
    notes: '',
    grant_ids: [],
  };

  const hasAtLeastOneValue = (values: GrantProviderFormValues) => {
    const requiredFields: (keyof GrantProviderFormValues)[] = [
      'first_name',
      'last_name',
      'title',
      'email',
      'phone_number',
      'notes',
    ];
    const hasAtLeastOneValue = requiredFields.some((field) => {
      const value = values[field] as string;
      return value && value.trim() !== '';
    });

    if (!hasAtLeastOneValue) {
      notification.error({
        message: t('mandated_agents|validation_error'),
        description: t('mandated_agents|at_least_one_field_is_required'),
      });
    }
    return hasAtLeastOneValue;
  };

  const createRepresentative = async () => {
    try {
      const values = await form.validateFields();

      if (!hasAtLeastOneValue(values)) return;

      const newContact: AdminGinContactResponse = {
        gin_contact_id: '',
        first_name: values.first_name,
        last_name: values.last_name,
        title: values.title,
        email: values.email,
        phone_number: values.phone_number,
        extension: values.extension,
        language: values.language,
        notes: values.notes,
        grant_ids: values.grant_ids,
        grant_provider_id: grantProviderId,
      };

      await dispatch(createGinContact({ api, ginContact: newContact })).then(
        (action) => {
          if (action.meta.requestStatus === 'fulfilled') {
            notification.success({
              message: t('mandated_agents|created_agent'),
            });
            if (!!contacts) {
              setSelectedContact(contacts.length);
            } else {
              setSelectedContact(0);
            }
          }
        },
      );
    } catch (err: any) {
      console.error(err);
    }
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();

      if (!hasAtLeastOneValue(values)) return;

      if (selectedContact == null) {
        return;
      }

      const updatedContact = {
        ...contacts[selectedContact],
        ...values,
      };

      await dispatch(
        updateGinContact({ api, ginContact: updatedContact }),
      ).then((action) => {
        if (action.meta.requestStatus === 'fulfilled') {
          notification.success({
            message: t('mandated_agents|update_agent'),
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    try {
      if (selectedContact == null) {
        return;
      }

      const contactToDelete = contacts[selectedContact]?.gin_contact_id || '';

      if (contactToDelete) {
        await dispatch(
          deleteGinContact({
            api,
            ginContactId: contactToDelete,
          }),
        ).then((action) => {
          if (action.meta.requestStatus === 'fulfilled') {
            notification.success({
              message: t('mandated_agents|deleted_agent'),
            });
            setSelectedContact(null);
          }
        });
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <>
      <Div
        flex="row"
        justify="space-between"
        fitContent
        gap={16}
        style={{ width: '100%' }}
      >
        <FormRepresentative
          form={form}
          initialValues={initialValues}
          selectedContact={selectedContact}
          grantProvided={grantProvided}
          createRepresentative={createRepresentative}
          onSave={onSave}
          onDelete={onDelete}
        />
        <Div
          flex="column"
          fullHeight
          gap={16}
          borderColor={theme.colors.grey_4}
          backgroundColor={theme.colors.white_1}
          overflow="auto"
          fitMaxContent
          style={{
            position: 'relative',
            height: '700px',
            padding: '8px',
            minWidth: '200px',
          }}
        >
          <Div
            onClick={() => setSelectedContact(null)}
            style={{ cursor: 'pointer' }}
          >
            <SelectableContact selected={selectedContact === null} />
          </Div>
          {!!contacts &&
            contacts.map((contact, index) => (
              <Div
                key={index}
                onClick={() => setSelectedContact(index)}
                style={{ cursor: 'pointer' }}
              >
                <SelectableContact
                  contact={contact}
                  selected={selectedContact === index}
                />
              </Div>
            ))}
        </Div>
      </Div>
    </>
  );
};

export default GrantProviderContactForm;
