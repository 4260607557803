import Order from '@hellodarwin/icons/dist/icons/Order';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Select, { SelectProps } from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Div from '../../../common/div';
import Typography from '../../../common/typography';

export interface FilesListOrderBySelectProps {
  onChange: (value: string) => void;
  value: string;
}

const FilesListOrderBySelect: React.FC<FilesListOrderBySelectProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  const orderOptions: SelectProps['options'] = useMemo(
    () => [
      {
        value: 'ascending',
        label: t('assets|filters.orderBy.options.ascending'),
      },
      {
        value: 'descending',
        label: t('assets|filters.orderBy.options.descending'),
      },
    ],
    [t],
  );

  return (
    <Select
      options={orderOptions}
      onChange={onChange}
      placeholder={t('assets|filters.orderBy.title')}
      value={value}
      labelRender={({ label }) => (
        <Div flex="row" align="center" gap={8}>
          <Order size={16} />
          <Typography elementTheme="body2">{label}</Typography>
        </Div>
      )}
      style={{
        width: 221,
      }}
      suffixIcon={<TabArrow size={16} down />}
    />
  );
};

export default FilesListOrderBySelect;
