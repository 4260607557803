import { useCallback, useMemo } from 'react';
import { FundingExplorerProps } from '..';
import { usePagination } from '../../../../features/providers/pagination-provider';
import { FundingExplorerFilterValues } from '../filter/types';

const rangeBetween = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

const usePrepareFundingExplorerFilters = ({
  filterform,
}: FundingExplorerProps) => {
  const { params } = usePagination();

  const parseArrayParam = (key: string): string[] =>
    params[key]?.split(',') ?? [];

  const filteredSubindustry = useMemo(() => {
    const industry = parseArrayParam('industry');
    const subindustry = parseArrayParam('subindustry');

    return subindustry.filter((si) =>
      industry.some((i) => {
        const [start, end] = i.split('-').map(Number);
        if (!isNaN(start) && !isNaN(end)) {
          return rangeBetween(start, end).some((index) =>
            si.startsWith(`${index}`),
          );
        }
        return si.startsWith(i);
      }),
    );
  }, [params]);

  return useCallback(() => {
    const formMapping: FundingExplorerFilterValues = {
      filterBy: params['filterBy'] ?? '',
      industry: parseArrayParam('industry'),
      subindustry: filteredSubindustry,
      service: parseArrayParam('service'),
      financingType: parseArrayParam('financingType'),
      status: parseArrayParam('status'),
      selectedProjects: parseArrayParam('selectedProjects') ?? [],
      closingDate: new Date(params['closingDate']) ?? undefined,
      region: parseArrayParam('region'),
      companySize: params['companySize'],
      annualRevenue: params['annualRevenue'],
      textQuery: params['textQuery'] ?? '',
    };

    filterform.setFieldsValue(formMapping);
  }, [filterform, params, filteredSubindustry]);
};

export default usePrepareFundingExplorerFilters;
