import RfpStartWhen from '../enums/rfp-start-when';
import { Provider } from './providers-entities';

interface AnalyticsUser {
  user_type: string;
  entity_id: string;
  company_id: string;
  company_name: string;
  company_email: string;
  company_size: string;
  city: string;
}

export const newPartnerAnalyticsUser = (profile: Provider) => {
  const user: AnalyticsUser = {
    user_type: 'Partner',
    entity_id: `${profile.contacts?.[0]?.contact_id}`,
    company_id: profile.company_id,
    company_name: profile.name,
    company_email: profile.email || '',
    company_size: profile.size || '',
    city: profile.city || '',
  };

  return user;
};

export interface AvailableRfp {
  rfp_id: string;
  match_id: string;
  description: string;
  lead_price: number;
  discounted_amount: number;
  project_estimated_value: number;
  project_city: string;
  project_province: string;
  project_tag: string;
  additional_comments: string;
  visible_at: string;
}

export interface RefuseMatch {
  refused_reason: string;
}

export interface ProviderProfile {
  provider_id: string;
  analytics_profile_founded_at_score: number;
  analytics_profile_description_score: number;
  analytics_profile_banner_score: number;
  analytics_profile_logo_score: number;
  analytics_profile_tagline_score: number;
  analytics_profile_website_score: number;
  analytics_profile_asset_type_score: number;
  analytics_profile_address_score: number;
  analytics_profile_company_size_score: number;
  analytics_profile_section_team_score: number;
  analytics_profile_section_portfolio_score: number;
  analytics_profile_section_review_score: number;
  analytics_profile_language_score: number;
  analytics_profile_section_transparency_score: number;
  analytics_profile_portfolio_count: number;
  analytics_profile_team_member_count: number;
  analytics_profile_review_count: number;
  analytics_profile_completed_percentage: number;
}

export interface PurchasedRfp {
  rfp_id: string;
  match_id: string;
  description: string;
  lead_price: number;
  discounted_amount: number;
  must_start_by: string;
  start_when: RfpStartWhen;
  match_price: string;
  company_name: string;
  company_website: string;
  client_name: string;
  client_email: string;
  client_phone: string;
  client_phone_ext: string;
  client_title: string;
  client_preferred_language: string;
  client_comments: string;
  project_estimated_value: number;
  project_tag: string;
  project_city: string;
  project_province: string;
  additional_comments: string;
  visible_at: string;
  type: string;
}
export interface Attachment {
  name: string;
  description: string;
  size: number;
  attachment_comments: string;
}

export type ReferredRfp = {
  client_name: string;
  client_email: string;
  client_phone: string;
  original_description: string;
  project_min_budget: number;
  project_max_budget: number;
};
