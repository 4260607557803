import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Enterprise = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60001 29.6H30.2M3.80001 3.20001V29.6M19.2 3.20001V29.6M28 9.80001V29.6M8.20001 8.70001H9.30001M8.20001 13.1H9.30001M8.20001 17.5H9.30001M13.7 8.70001H14.8M13.7 13.1H14.8M13.7 17.5H14.8M8.20001 29.6V24.65C8.20001 23.7392 8.93921 23 9.85 23H13.15C14.0608 23 14.8 23.7392 14.8 24.65V29.6M2.70001 3.20001H20.3M19.2 9.80001H29.1M23.6 15.3H23.6117V15.3117H23.6V15.3ZM23.6 19.7H23.6117V19.7117H23.6V19.7ZM23.6 24.1H23.6117V24.1117H23.6V24.1Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Enterprise;
