import styled from 'styled-components';

export const FundingExplorerListPageLockedWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.white_2};
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
`;

export const FundingExplorerListPageLockedContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 100;
  top: 0;
  left: 0;
`;

export const FundingExplorerListPageLockedWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding: 24px;
  background: ${({ theme }) => theme.colors.beige_4};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: calc(100% - 24px);
    margin: auto;
  }
`;
