import {
  GinProgram,
  ProgramGrantStatus,
} from '@hellodarwin/core/lib/features/entities/programs-entities';
import {
  dollarFormat,
  getStringDate,
} from '@hellodarwin/core/lib/features/helpers';
import { getProgramGrantStatusColor } from '@hellodarwin/core/lib/features/helpers/get-status-color';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import CardLayout from './card-layout';

interface ProgramCardProps {
  program: GinProgram;
  selectedLocale: string;
}

export const ProgramCard = ({ program, selectedLocale }: ProgramCardProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  if (!program) return <></>;
  return (
    <CardLayout
      to={`/programs/${program.program_id}`}
      title={program.program_company_name ?? ''}
      subtitle={program.program_contact_name}
      infos={[
        {
          Icon: Grants,
          value: program.program_targeted_amount
            ? dollarFormat(
                program.program_targeted_amount,
                selectedLocale,
                true,
              )
            : 'N/A',
        },
        {
          Icon: Timeline,
          value: getStringDate(
            new Date(`${program.program_activated_at}`),
            selectedLocale,
          ),
        },
      ]}
      tag={{
        color: getProgramGrantStatusColor(
          program.program_grant_status ?? ProgramGrantStatus.NotSubmited,
          theme,
        ),
        text: t(`programs_labels|${program.program_grant_status}`),
      }}
    />
  );
};
