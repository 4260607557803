import { DraggableLocation } from 'react-beautiful-dnd';
import { DragDropData } from '../multiple';

export const clone = <T,>(data: DragDropData<T>) => {
  return JSON.parse(JSON.stringify(data));
};

export const onChange = (
  source: DraggableLocation,
  destination: DraggableLocation,
) => {
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return true;
  }
  return false;
};

export const deleteItem = <T,>(
  data: DragDropData<T>,
  { droppableId, index }: { droppableId: string; index: number },
) => {
  data.columns[droppableId].itemIds.splice(index, 1);
  return data;
};

export const addItem = <T,>(
  data: DragDropData<T>,
  { droppableId, index }: DraggableLocation,
  taskId: string,
) => {
  data.columns[droppableId].itemIds.splice(index, 0, taskId);
  return data;
};
