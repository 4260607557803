import React from 'react';
import {
  ArrowOutlineStyled,
  TriggerStyled,
} from '../../../layout/navigation/styles';
import ChildSidebarBlock from './child-sidebar-block';
import SidebarBlock, { SidebarBlockConfig } from './sidebar-block';
import { SidebarPageSiderContainer } from './styles';

interface SidebarPageSiderProps {
  sidebarBlocks: SidebarBlockConfig[];
  isCollapsed: boolean;
  handleCollapse: () => void;
  side: 'right' | 'left';
}

const SidebarPageSider = ({
  sidebarBlocks,
  side,
  isCollapsed,
  handleCollapse,
}: SidebarPageSiderProps) => {
  if (!sidebarBlocks?.length) return <></>;
  return (
    <SidebarPageSiderContainer
      $position={side}
      collapsible
      collapsed={isCollapsed}
      onCollapse={handleCollapse}
      width={300}
      collapsedWidth={20}
      trigger={
        <TriggerStyled style={{ top: 150 }}>
          <ArrowOutlineStyled
            size={24}
            style={{
              transform:
                (isCollapsed && side === 'left') ||
                (!isCollapsed && side === 'right')
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              transition: 'transform 500ms linear',
            }}
          />
        </TriggerStyled>
      }
    >
      {!isCollapsed &&
        React.Children.toArray(
          sidebarBlocks.map((block, index) =>
            React.isValidElement(block.content) ? (
              <SidebarBlock {...block} />
            ) : (
              <ChildSidebarBlock {...block} />
            ),
          ),
        )}
    </SidebarPageSiderContainer>
  );
};

export default SidebarPageSider;
