import { HighlightProps } from './entities';
import { HighlightContainer, Highlighting } from './styles';

const Highlight = ({ children, color }: HighlightProps) => {
  return (
    <HighlightContainer>
      <Highlighting $color={color} />
      <span style={{ position: 'relative' }}>{children}</span>
    </HighlightContainer>
  );
};

export default Highlight;
