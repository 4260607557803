import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import FormsSubmissionList from '../../components/forms/submissions/forms-submission-list';
import {
  fetchFormById,
  selectForm,
} from '../../features/api/slices/forms-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

const SingleFormPageSubmissions = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams<{
    id: string;
  }>();
  const navigate = useNavigate();

  const formResponse = useAppSelector(selectForm);

  useEffect(() => {
    if (id) {
      dispatch(fetchFormById({ api, formId: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(`/forms/${id}`);
  };
  return (
    <PageLayout
      app="admin"
      title={'Submissions'}
      handleBack={handleBack}
      breadcrumbs={[
        {
          breadcrumbName: 'Forms',
          path: '/forms',
        },
        {
          breadcrumbName: formResponse.title_en,
          path: `/forms/${formResponse.form_id}`,
        },
        { breadcrumbName: 'Submissions' },
      ]}
    >
      <FormsSubmissionList type="form" entityId={id} />
    </PageLayout>
  );
};

export default SingleFormPageSubmissions;
