import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchGinContacts,
  selectGinContacts,
  selectGinContactsIsLoading,
} from '../../../features/api/slices/gin-contacts-slice';
import {
  fetchGrantProvidersByGrantId,
  selectGrantProviders,
} from '../../../features/api/slices/grants-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import { ContactCard } from '../../../pages/gin/single-grant/contact-card';
import GinContactDrawer from './related-contacts-drawer';

interface GinRelatedContactsProps {
  grantId: string;
  open: boolean;
  onClose: () => void;
}

const GinRelatedContacts = ({
  grantId,
  open,
  onClose,
}: GinRelatedContactsProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const ginContacts = useAppSelector(selectGinContacts);
  const isLoading = useAppSelector(selectGinContactsIsLoading);
  const providers = useAppSelector(selectGrantProviders);

  useEffect(() => {
    dispatch(fetchGinContacts({ api, grantId }));
    dispatch(
      fetchGrantProvidersByGrantId({
        api,
        locale: selectedLocale,
        grantId: grantId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantId]);

  useEffect(() => {
    if (!!providers && providers.length > 0) {
      setSelectedProvider(providers[0].value);
    }
  }, [providers]);

  return (
    <Div flex="column" gap={16}>
      <GinContactDrawer
        isAddAgentVisible={open}
        onClose={onClose}
        providers={providers}
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        ginContacts={ginContacts}
        isLoading={isLoading}
        grantId={grantId}
      />
      {isLoading ? (
        <Loading />
      ) : (
        ginContacts &&
        ginContacts?.length > 0 &&
        ginContacts?.map((contact, index) => (
          <ContactCard key={index} contact={contact} grantId={grantId} />
        ))
      )}
    </Div>
  );
};

export default GinRelatedContacts;
