import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const StepType = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60001 16H30.4M1.60001 22.4H30.4M1.60001 28.8H30.4M4.87273 3.20001H27.1273C27.9953 3.20001 28.8277 3.53715 29.4414 4.13727C30.0552 4.73739 30.4 5.55132 30.4 6.40001C30.4 7.24871 30.0552 8.06264 29.4414 8.66275C28.8277 9.26287 27.9953 9.60001 27.1273 9.60001H4.87273C4.00475 9.60001 3.17232 9.26287 2.55857 8.66275C1.94481 8.06264 1.60001 7.24871 1.60001 6.40001C1.60001 5.55132 1.94481 4.73739 2.55857 4.13727C3.17232 3.53715 4.00475 3.20001 4.87273 3.20001Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StepType;
