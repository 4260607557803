import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const ProjectIcon = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);
  return (
    <svg
      {...iconProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M12.6732 11.4993H24.6732M7.59319 11.4093L8.42319 12.1793L10.0232 10.8193M12.6732 17.9993H24.6732M7.59319 17.9093L8.42319 18.6793L10.0232 17.3193M12.6732 24.4993H24.6732M7.59319 24.4093L8.42319 25.1793L10.0232 23.8193M22.5232 4.38926C22.6332 5.83926 22.4832 6.96926 20.8832 6.84926C18.5232 6.84926 13.3932 6.88926 11.4632 6.81926C10.1832 6.74926 10.1932 5.55926 10.1932 4.38926M22.5232 4.38926C25.6232 4.32926 27.7332 4.63926 27.5832 7.92926C27.6032 13.8193 27.6032 20.9493 27.5832 26.9693C27.5632 29.5193 27.2532 29.9493 24.8832 29.9893C22.2832 30.0193 19.6032 29.9993 16.9232 29.9993C13.9032 29.9893 9.69319 30.0193 7.12319 29.9893C4.86319 30.0493 4.61319 29.7193 4.65319 27.5393C4.63319 23.5093 4.42319 13.1593 4.42319 8.29926C4.15319 4.38926 6.46319 4.33926 10.1932 4.38926M22.5232 4.38926C22.5332 2.75926 21.8732 1.96926 20.2832 2.00926C18.2232 1.98926 14.4932 1.98926 12.4332 2.00926C10.8532 1.96926 10.1732 2.74926 10.1932 4.38926"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProjectIcon;
