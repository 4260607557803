import { Tablet } from '@hellodarwin/core/lib/components/common/Media';
import UserMenu from '@hellodarwin/core/lib/components/layout/header/user-menu';
import {
  AdminCallersTeams,
  NavigationLink,
} from '@hellodarwin/core/lib/features/entities';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import Document from '@hellodarwin/icons/dist/icons/Doc';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import Settings from '@hellodarwin/icons/dist/icons/Settings';
import Spin from 'antd/es/spin';
import { useMemo } from 'react';
import { useAppSelector } from '../app';
import { selectAuthentifiedUser } from '../features/api/slices/global-slice';

const HeaderBarUser = () => {
  const { user } = useLogin();
  const profile = useAppSelector(selectAuthentifiedUser);

  const noHomepage = useMemo(() => !profile.admin_homepage, [profile]);
  const noCallLinks = useMemo(
    () =>
      !!profile.teams?.find((t) => AdminCallersTeams.includes(t)) &&
      !profile.meeting_link_en &&
      !profile.meeting_link_fr,
    [profile],
  );

  const notifsCount = useMemo(() => {
    let count = 0;
    if (noHomepage) {
      count += 1;
    }
    if (noCallLinks) {
      count += 1;
    }
    return count;
  }, [noHomepage, noCallLinks]);

  const userMenu: NavigationLink[] = useMemo(
    () => [
      {
        label: 'Settings',
        pathname: '/settings',
        Icon: Settings,
        notifs: notifsCount,
      },
      {
        Icon: Employees,
        label: 'Admins',
        pathname: '/admins',
      },
      {
        Icon: Document,
        label: 'Files',
        pathname: '/files-vault',
      },
    ],
    [notifsCount],
  );

  if (!user) return <Spin size="small" />;

  return (
    <Tablet direction="up">
      <UserMenu
        user={{ name: user.name || '', logo: user.picture || '' }}
        links={userMenu}
        app="admin"
        notifs={notifsCount}
      />
    </Tablet>
  );
};

export default HeaderBarUser;
