import { ReviewResult } from '../../../../features/entities';
import useMediaQuery from '../../../../features/helpers/use-media-query';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { UserAvatar } from './styles';

const ReviewHeader = ({ review }: { review: ReviewResult }) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const isLarge = useMediaQuery({ minWidth: theme.breakpoints.l });

  const size = isLarge ? 60 : 38;
  return (
    <Div flex="column" fullHeight gap={8} fitContent>
      <Div
        flex="row"
        align="center"
        gap={22}
        tablet={{ flex: 'column' }}
        fitContent
      >
        <UserAvatar
          background={theme.colors.purple_2}
          shape="round"
          size={size}
          style={{ flexShrink: 0, flexGrow: 0, flexBasis: size }}
        >
          {review.contact_name?.[0]}
        </UserAvatar>

        <Div flex="column" gap={7} fitContent>
          <Typography.Title
            level={4}
            elementTheme="h6"
            noMargin
            tablet={{ textAlign: 'center' }}
          >
            {review.contact_name}
          </Typography.Title>
          <Typography
            elementTheme="caption"
            color={theme.colors.grey_3}
            tablet={{ textAlign: 'center' }}
          >
            {review.title
              ? t('profile_reviews|reviewRole', {
                  role_name: review.title,
                  company_name: review.company_name,
                })
              : review.company_name}
          </Typography>
        </Div>
      </Div>
    </Div>
  );
};

export default ReviewHeader;
