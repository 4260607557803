import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AppType } from '../../../../features/entities/core-entities';
import Div from '../../../common/div';
import NavigationBurger from '../navigation-burger';

interface MobileNavigationHeaderProps {
  app: AppType;
  toggleMenu: () => void;
  isOpen: boolean;
}

const MobileNavigationHeader = ({
  app,
  toggleMenu,
  isOpen,
}: MobileNavigationHeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handleRedirect = () => {
    if (isOpen) toggleMenu();
    navigate('/');
  };
  return (
    <Div
      backgroundColor={
        app === 'provider' ? theme.colors.primary : theme.colors.white_1
      }
      flex={'row'}
      justify="space-between"
      style={{ padding: 21, zIndex: 100 }}
      align="center"
    >
      <HdLogo
        onClick={handleRedirect}
        backgroundType={app === 'provider' ? 'dark' : 'light'}
        type={'primary'}
        color="b/w"
        width={178}
        height={48}
      />
      <NavigationBurger app={app} isOpen={isOpen} onClick={toggleMenu} />
    </Div>
  );
};

export default MobileNavigationHeader;
