import FacebookLogo from '@hellodarwin/icons/dist/icons/FacebookLogo';
import InstagramLogo from '@hellodarwin/icons/dist/icons/InstagramLogo';
import LinkedinLogo from '@hellodarwin/icons/dist/icons/LinkedinLogo';
import PinterestLogo from '@hellodarwin/icons/dist/icons/PinterestLogo';
import TiktokLogo from '@hellodarwin/icons/dist/icons/TiktokLogo';
import TwitterLogo from '@hellodarwin/icons/dist/icons/TwitterLogoOutline';
import YoutubeLogo from '@hellodarwin/icons/dist/icons/YoutubeLogo';
import { Provider } from '../../../../features/entities';
import { SocialsContainer } from './styles';

const ProfileContactSocials = ({
  facebook,
  twitter,
  instagram,
  linked_in,
  tiktok,
  pinterest,
  youtube,
}: Provider) => {
  if (
    !facebook &&
    !twitter &&
    !instagram &&
    !linked_in &&
    !tiktok &&
    !pinterest &&
    !youtube
  )
    return <></>;

  return (
    <SocialsContainer
      flex="row"
      gap={8}
      justify="flex-end"
      style={{ marginTop: 20 }}
    >
      {!!linked_in && (
        <a target="__blank" rel="nofollow" href={linked_in}>
          <LinkedinLogo size={16} />
        </a>
      )}
      {!!facebook && (
        <a
          target="__blank"
          rel="nofollow"
          href={facebook}
          style={{ textDecoration: 'none' }}
        >
          <FacebookLogo size={16} />
        </a>
      )}
      {!!instagram && (
        <a target="__blank" rel="nofollow" href={instagram}>
          <InstagramLogo size={16} />
        </a>
      )}
      {!!twitter && (
        <a target="__blank" rel="nofollow" href={twitter}>
          <TwitterLogo size={16} />
        </a>
      )}
      {!!tiktok && (
        <a target="__blank" rel="nofollow" href={tiktok}>
          <TiktokLogo size={16} />
        </a>
      )}
      {!!youtube && (
        <a target="__blank" rel="nofollow" href={youtube}>
          <YoutubeLogo width={16} height={10} />
        </a>
      )}
      {!!pinterest && (
        <a target="__blank" rel="nofollow" href={pinterest}>
          <PinterestLogo width={16} height={10} />
        </a>
      )}
    </SocialsContainer>
  );
};

export default ProfileContactSocials;
