// Based on https://github.com/googlemaps/react-wrapper?tab=readme-ov-file
import { Library, Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { LoaderService } from './gmaps-loader-service';

export enum Status {
  LOADING = 'LOADING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export interface WrapperProps extends LoaderOptions {
  children?: ReactNode;
  render?: (status: Status) => ReactElement;
  callback?: (status: Status, loader: Loader) => void;
  library: Library;
}

export const Wrapper = ({
  children,
  render,
  callback,
  library,
  ...options
}: WrapperProps): ReactElement => {
  const [status, setStatus] = useState(Status.LOADING);
  const loaderService = useMemo(
    () => LoaderService.getInstance(options),
    [options],
  );

  const prepareScript = () => {
    const loader = loaderService.getLoader();
    const setStatusAndExecuteCallback = (status: Status) => {
      if (callback) callback(status, loader);
      setStatus(status);
    };

    setStatusAndExecuteCallback(Status.LOADING);

    loader.importLibrary(library).then(
      () => setStatusAndExecuteCallback(Status.SUCCESS),
      () => setStatusAndExecuteCallback(Status.FAILURE),
    );
  };

  useEffect(() => {
    prepareScript();
  }, []);

  if (status === Status.SUCCESS && children) return <>{children}</>;

  if (render) return render(status);

  return <></>;
};
