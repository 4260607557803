import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Loading from '@hellodarwin/core/lib/components/loading';
import { AdminGrantResult } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Dots from '@hellodarwin/icons/dist/icons/Dots';
import Button from 'antd/es/button';
import Drawer from 'antd/es/drawer';
import Form from 'antd/es/form';
import message from 'antd/es/message';
import { TabsProps } from 'antd/es/tabs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import FormsSubmissionList from '../../components/forms/submissions/forms-submission-list';
import GrantForm, {
  GrantFormValues,
} from '../../components/grants/grant-form/grant-form';
import GrantValidationCheckbox from '../../components/grants/grant-validation/grant-validation-checkbox';
import {
  fetchGrant,
  selectGrantById,
  updateGrant,
} from '../../features/api/slices/grants-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';
import { AdminPagesForms } from '../single-rfp-page';

export const grantPromptPropertiesTitles = [
  'financing_type',
  'providers',
  'service',
  'audience',
  'industry_sectors',
  'industry_subsectors',
  'for_profit',
  'funding_max_amount',
  'funding_min_amount',
  'percentage_funding',
  'total_funding_available',
  'needed_investment',
  'applicants_min_revenue_past_year',
  'applicants_max_revenue_past_year',
  'applicants_min_count_employee',
  'applicants_max_count_employee',
  'size',
  'city',
  'region',
  'province',
  'application_email_address',
  'application_phone_number',
];

const SingleGrantPage = () => {
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const [isGeneratingRemaining, setIsGeneratingRemaining] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [activeKey, setActiveKey] = useState(['1']);
  const [form] = Form.useForm<GrantFormValues>();
  const [isSavingAll, setIsSavingAll] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawer = () => setDrawerOpen(!drawerOpen);
  const params = useParams<{
    id: string;
  }>();
  const grant = useAppSelector((state) =>
    selectGrantById(state, params.id || ''),
  );
  const [forms, setForms] = useState<AdminPagesForms>({
    grantForm: undefined,
  });
  const { t } = useTranslations();
  useEffect(() => {
    if (!params.id) {
      return;
    }
    dispatch(fetchGrant({ api: newApi, grantId: params.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!params.id) return <Loading />;

  if (!grant) {
    return <Loading />;
  }

  const onSave = async () => {
    try {
      setLoadingSave(true);
      setIsSavingAll(true);
      const grantFormValues = forms.grantForm?.getFieldsValue()!;

      if (grantFormValues === undefined) {
        return;
      }

      const updatedGrant: AdminGrantResult = {
        ...grant,
        ...grantFormValues,
      };

      if (!!updatedGrant.percentage_funding) {
        updatedGrant.percentage_funding = Math.round(
          updatedGrant.percentage_funding,
        );
      }

      await dispatch(updateGrant({ api, grant: updatedGrant }));

      message.success('Saved!');
      setLoadingSave(false);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
      setLoadingSave(false);
    }
  };

  const generateAll = () => {
    setActiveKey(['1', '2', '3', '4']);
    setIsGeneratingAll(true);
  };

  const generateRemaining = () => {
    grant.grant_timeline
      ? setActiveKey(['1', '2'])
      : setActiveKey(['1', '2', '3']);
    setIsGeneratingRemaining(true);
  };

  const accessScrapeContext = () => {
    window.open(`/grants/scrape-context/${grant?.grant_id}`, '_blank');
  };

  const goToGrants = () => {
    navigate('/grants');
  };

  const GrantFormHeader = () => {
    return (
      <>
        <Div flex="row" justify="space-between">
          <Button
            onClick={onSave}
            size="middle"
            type="primary"
            color={'primary'}
            loading={loadingSave}
            disabled={loadingSave}
          >
            Save
          </Button>
          <Div
            flex="row"
            justify="space-between"
            style={{ width: 'fit-content' }}
          >
            <Button
              color={'primary'}
              onClick={generateAll}
              size="middle"
              type="primary"
              loading={isGeneratingAll}
              disabled={isGeneratingAll || isGeneratingRemaining}
              style={{ marginRight: '12px' }}
            >
              Generate All
            </Button>
            <Button
              onClick={generateRemaining}
              size="middle"
              type="default"
              loading={isGeneratingRemaining}
              disabled={isGeneratingRemaining || isGeneratingAll}
              style={{ marginRight: '12px' }}
            >
              Generate Remaining
            </Button>
            <Button
              type="default"
              onClick={accessScrapeContext}
              style={{ marginRight: '12px' }}
            >
              See scrape context
            </Button>
            <Button type="text" onClick={handleDrawer}>
              <Dots />
            </Button>
          </Div>
        </Div>
        <Drawer
          open={drawerOpen}
          onClose={handleDrawer}
          title="Validation"
          width={'fit-content'}
        >
          <GrantValidationCheckbox grantId={grant.grant_id} />
        </Drawer>
      </>
    );
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'grant',
      label: 'Grant Form',
      children: (
        <>
          <GrantFormHeader />
          <GrantForm
            grant={grant}
            form={form}
            setForms={setForms}
            onSave={onSave}
            isGeneratingAll={isGeneratingAll}
            setIsGeneratingAll={setIsGeneratingAll}
            isGeneratingRemaining={isGeneratingRemaining}
            setIsGeneratingRemaining={setIsGeneratingRemaining}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            isSavingAll={isSavingAll}
            setIsSavingAll={setIsSavingAll}
          />
        </>
      ),
    },
    {
      key: 'submissions',
      label: 'Grant Submissions',
      children: <FormsSubmissionList type="grant" entityId={grant.grant_id} />,
    },
  ];

  return (
    <PageLayout
      app="admin"
      tabTitle={grant?.grant_display_title_en}
      title={grant?.grant_display_title_en}
      handleBack={goToGrants}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('programs_admin|programsTitle'),
          path: '/grants',
        },
        {
          breadcrumbName: grant?.grant_display_title_en,
        },
      ]}
    >
      <Container tabs={tabs} defaultActiveTab="grant"></Container>
    </PageLayout>
  );
};

export default SingleGrantPage;
