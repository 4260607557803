enum RfpDescriptionTypeInbox {
  Client_Description = 'client description',
  Description_du_client = 'description du client',
  Project_Description = 'project description',
  Description_du_projet = 'description du projet',
  Context = 'project context',
  Contexte = 'contexte du projet',
  Goals = 'project goal &amp; objective',
  Objectifs = 'but &amp; objectifs du projet',
  Details = 'project description &amp; details',
  Detailes = 'description &amp; d\u00E9tails du projet',
  Provider_Requirement = 'service provider requirements',
  Demande_du_fournisseur = 'exigences du fournisseur',
  Budget = 'budget',
  Contact = 'budget &amp; contact',
  Other = 'other',
  Autre = 'autre',
}
export default RfpDescriptionTypeInbox;
