import styled from '@hellodarwin/core/lib/plugins/styled';

export const ProjectItemTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  padding: 24px;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  background: ${({ theme }) => theme.colors.beige_3};
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.01);

  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.purple_5};
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);
  }
`;
