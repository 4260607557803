import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';

const TiktokLogo = (props: IconProps) => {
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1333 7.76C21.2219 6.7195 20.7196 5.38324 20.72 4H16.6V20.5333C16.5682 21.428 16.1905 22.2755 15.5463 22.8973C14.9022 23.519 14.0419 23.8665 13.1467 23.8667C11.2533 23.8667 9.68 22.32 9.68 20.4C9.68 18.1067 11.8933 16.3867 14.1733 17.0933V12.88C9.57333 12.2667 5.54666 15.84 5.54666 20.4C5.54666 24.84 9.22666 28 13.1333 28C17.32 28 20.72 24.6 20.72 20.4V12.0133C22.3907 13.2131 24.3965 13.8569 26.4533 13.8533V9.73333C26.4533 9.73333 23.9467 9.85333 22.1333 7.76Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TiktokLogo;
