import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import {
  EditableFormSubmissionResponse,
  EditSubmissionItemResponse,
} from '../../../../features/entities/form-entities';
import useLocale from '../../../../features/providers/locale-provider';
import getItemTranslatedLabel from '../../form-builder/utils/get-item-translated-label';
import { FormSubmissionFieldsListContainer } from './styles';

interface FormSubmissionFieldsListProps {
  formSubmission: EditableFormSubmissionResponse;
}

const FormSubmissionFieldsList = ({
  formSubmission,
}: FormSubmissionFieldsListProps) => {
  const { selectedLocale } = useLocale();
  const columns: ColumnsType<EditSubmissionItemResponse> = [
    {
      title: 'Question',
      key: 'content_en',
      render(value, record, index) {
        return getItemTranslatedLabel({
          label_en: record.form_item.content_en,
          label_fr: record.form_item.content_fr,
          locale: selectedLocale,
          fallback: record.form_item.form_item_id,
        });
      },
    },
    {
      title: 'Value',
      key: 'value',
      width: 380,
      render(value, record, index) {
        return record.forms_submissions_item.value;
      },
    },
  ];

  const activeItems = useMemo(
    () =>
      formSubmission.all_items.filter(
        (item) => !!item.forms_submissions_item.form_submission_item_id,
      ),
    [formSubmission],
  );

  return (
    <FormSubmissionFieldsListContainer<EditSubmissionItemResponse>
      columns={columns}
      dataSource={activeItems}
      pagination={{
        defaultPageSize: 20,
        position: ['bottomCenter'],
        showSizeChanger: true,
      }}
    />
  );
};

export default FormSubmissionFieldsList;
