import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const GridViewFill = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.41765e-05 1.52381C3.41765e-05 0.682236 0.682267 2.97536e-06 1.52384 2.97536e-06H12.3893C13.2308 2.97536e-06 13.9131 0.682236 13.9131 1.52381V12.3892C13.9131 13.2308 13.2308 13.913 12.3893 13.913H1.52384C0.682267 13.913 3.41765e-05 13.2308 3.41765e-05 12.3892V1.52381Z"
        fill="currentColor"
      />
      <path
        d="M-6.10352e-05 19.6108C-6.10352e-05 18.7692 0.682172 18.087 1.52375 18.087H12.3892C13.2307 18.087 13.913 18.7692 13.913 19.6108V30.4762C13.913 31.3178 13.2307 32 12.3892 32H1.52375C0.682172 32 -6.10352e-05 31.3178 -6.10352e-05 30.4762V19.6108Z"
        fill="currentColor"
      />
      <path
        d="M18.0866 1.52381C18.0866 0.682233 18.7689 0 19.6104 0H30.4759C31.3174 0 31.9997 0.682233 31.9997 1.52381V12.3892C31.9997 13.2308 31.3174 13.913 30.4759 13.913H19.6104C18.7689 13.913 18.0866 13.2308 18.0866 12.3892V1.52381Z"
        fill="currentColor"
      />
      <path
        d="M18.0866 19.6108C18.0866 18.7692 18.7689 18.087 19.6104 18.087H30.4759C31.3174 18.087 31.9997 18.7692 31.9997 19.6108V30.4762C31.9997 31.3178 31.3174 32 30.4759 32H19.6104C18.7689 32 18.0866 31.3178 18.0866 30.4762V19.6108Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GridViewFill;
