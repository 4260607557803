import styled from 'styled-components';
import { ReviewRatingsTextPlacement } from '.';

export const ReviewRatingsContainer = styled.div<{
  $textPlacement?: ReviewRatingsTextPlacement;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: ${({ $textPlacement }) =>
    $textPlacement === 'end' ? 'row-reverse' : 'row'};
  flex-wrap: wrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: ${({ $textPlacement }) =>
      $textPlacement === 'end' ? 'column-reverse' : 'column'};
  }
`;
