import Card from '@hellodarwin/core/lib/components/common/Card';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Company } from '@hellodarwin/core/lib/features/entities/core-entities';
import { useNavigate } from 'react-router-dom';

const InfoRow = ({ label, value }: { label: string; value?: string }) => {
  if (!value) return <></>;
  return (
    <Div flex="row" gap={8} fitContent align="center">
      <Typography elementTheme="body2" style={{ fontWeight: 600 }}>
        {label}
      </Typography>
      <Typography elementTheme="body2">{value}</Typography>
    </Div>
  );
};

const FormSubmissionCompanyCard = ({ company }: { company: Company }) => {
  const navigate = useNavigate();
  if (!company.company_id) return <></>;

  return (
    <Card
      hoverable
      onClick={() => navigate(`/companies/${company.company_id}`)}
      style={{
        padding: 24,
        width: '100%',

        flex: 1,
      }}
    >
      <Div flex="column" gap={8}>
        <Typography.Title level={1} elementTheme="h6">
          {company.name}
        </Typography.Title>

        <InfoRow label="Email : " value={company.email} />
        <InfoRow label="Country : " value={company.country} />
        <InfoRow label="Province : " value={company.province} />
        <InfoRow label="Profile Language : " value={company.profile_language} />
      </Div>
    </Card>
  );
};
export default FormSubmissionCompanyCard;
