import InboxOutlined from '@ant-design/icons/InboxOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import { RfpAsset } from '@hellodarwin/core/lib/features/entities';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import { RcFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { useState } from 'react';
import { useAdminApi } from '../../features/api/use-admin-api';

type RfpAssetUploadProps = {
  rfpId: string;
  onAssetUpload: (asset: RfpAsset) => void;
};

interface RfpAssetFormValues {
  description: string;
  filename: string;
}

const RfpAssetUpload = ({ rfpId, onAssetUpload }: RfpAssetUploadProps) => {
  const api = useAdminApi();

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [newAsset, setNewAsset] = useState<RcFile | undefined>(undefined);
  const [isVisible, setIsVisible] = useState(false);

  const onFinish = (values: any) => {
    (async () => {
      setIsSaving(true);
      try {
        if (newAsset === undefined) {
          return;
        }
        const formData = new FormData();
        formData.append('file', newAsset);
        formData.append('description', values.description);
        formData.append('filename', values.filename);
        const response = await api.createRfpAsset(rfpId, formData);
        onAssetUpload(response);
        setIsVisible(false);
        message.success('Saved!');
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    })();
  };

  const beforeUpload = (asset: RcFile): boolean => {
    setNewAsset(asset);
    return false;
  };

  const onRemove = () => {
    setNewAsset(undefined);
  };

  const onModalCLose = () => {
    form.resetFields();
    setNewAsset(undefined);
    onRemove();
  };

  const initialValues: RfpAssetFormValues = {
    description: '',
    filename: '',
  };
  return (
    <div>
      <Button onClick={() => setIsVisible(true)} icon={<UploadOutlined />} />
      <Modal
        title="Upload Asset"
        afterClose={onModalCLose}
        open={isVisible}
        okText="Upload"
        okButtonProps={{ loading: isSaving }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.error('Validate Failed:', info);
            });
        }}
        onCancel={() => {
          onModalCLose();
          setIsVisible(false);
        }}
        keyboard={true}
      >
        <Row gutter={16}>
          <Div style={{ marginBottom: '1rem' }}>
            <Col span={24}>
              <Dragger
                name="file"
                fileList={newAsset === undefined ? [] : [newAsset]}
                beforeUpload={(file) => beforeUpload(file)}
                onRemove={() => onRemove()}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </Col>
          </Div>
        </Row>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="upload-asset"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Filename" name="filename">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <TextArea rows={2} autoSize />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default RfpAssetUpload;
