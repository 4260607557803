import { CSSProperties, PropsWithChildren } from 'react';
import { ListItemContainer } from './styles';

export interface ListItemProps extends PropsWithChildren {
  style?: CSSProperties;
}

export const ListItem = ({ children, style }: ListItemProps) => {
  return (
    <div>
      <ListItemContainer
        style={{
          marginBottom: 32,
          alignSelf: 'stretch',
          height: '100%',
          ...style,
        }}
      >
        {children}
      </ListItemContainer>
    </div>
  );
};
