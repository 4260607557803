import {
  AdminGrantResult,
  GinApplication,
} from '@hellodarwin/core/lib/features/entities';
import { getStringDate } from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import User from '@hellodarwin/icons/dist/icons/User';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchAdminById,
  selectAdminById,
} from '../../../features/api/slices/admins-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

const useGinApplicationSummary = (
  application?: GinApplication,
  grant?: AdminGrantResult,
) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const admin = useAppSelector((state) =>
    selectAdminById(state, application?.application_account_manager ?? ''),
  );

  useEffect(() => {
    if (!!application?.application_account_manager) {
      dispatch(
        fetchAdminById({
          api,
          adminId: application.application_account_manager,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);
  return useMemo(() => {
    if (!grant) return [];

    const {
      grant_timeline,
      grant_display_title_en,
      grant_display_title_fr,
      grant_id,
    } = grant ?? {};

    return [
      {
        label: t('application_single|program_name'),
        Icon: Grants,
        children: (
          <Link to={`/gin/${grant_id}`}>
            {selectedLocale === 'fr'
              ? grant_display_title_fr
              : grant_display_title_en}
          </Link>
        ),
      },
      {
        /* TODO change when added in contenful */
        label: t('application_single|application_manager'),
        Icon: User,
        children: !!admin ? (
          <Link to={`/admins/${admin?.admin_id}`}>
            {admin.first_name} {admin?.last_name}
          </Link>
        ) : (
          t('application_single|no_application_manager')
        ),
      },
      {
        label: t('grant_single|deadlines'),
        Icon: Timeline,
        children: (
          <ul>
            {!!grant_timeline && !!grant_timeline[0]?.opened_at && (
              <li>
                {t('grant_single|openingDate')}
                {getStringDate(
                  new Date(grant_timeline[0].opened_at),
                  selectedLocale,
                )}
              </li>
            )}
            {!!grant_timeline && !!grant_timeline[0]?.closed_at && (
              <li>
                {t('grant_single|closingDate')}
                {getStringDate(
                  new Date(grant_timeline[0].closed_at),
                  selectedLocale,
                )}
              </li>
            )}
            {(!grant_timeline ||
              (!grant_timeline[0]?.opened_at &&
                !grant_timeline[0]?.closed_at)) && (
              <li>{t('grant_single|timelineUnspecified')}</li>
            )}
          </ul>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, selectedLocale, grant, admin]);
};
export default useGinApplicationSummary;
