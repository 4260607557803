import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';

interface SavingBlockProps {
  handleClose?: () => void;
}
const SavingBlock = ({ handleClose }: SavingBlockProps) => {
  const theme = useTheme();
  return (
    <Div
      backgroundColor={theme.colors.primary}
      flex="column"
      style={{ padding: '14px 32px' }}
      gap={8}
      borderRadius={4}
    >
      <Typography
        nowrap
        textAlign="center"
        color={theme.colors.white_1}
        elementTheme="body2"
      >
        This shouldnt appear. This is for Kamel!
      </Typography>
      <Div flex="row" gap={8} align="center" justify="center">
        <Button
          size="small"
          defaultStyle={theme.colors.white_1}
          onClick={handleClose}
        >
          No
        </Button>
        <Button size="small" defaultStyle={theme.colors.purple_3}>
          Yes
        </Button>
      </Div>
    </Div>
  );
};

export default SavingBlock;
