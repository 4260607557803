import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import DatePicker from 'antd/es/date-picker';
import dayjs from 'dayjs';

const RenderSelectDate = ({
  label,
  name,
  defaultToday,
}: {
  label: string;
  name: string;
  defaultToday: boolean;
}) => {
  const { t } = useTranslations();
  return (
    <FormItem
      name={name}
      label={
        <Typography elementTheme="body2">
          {t(`application_single|${label}`)}
        </Typography>
      }
      rules={[
        {
          required: true,
          message: t('application_single|required_date'),
        },
      ]}
      initialValue={defaultToday ? dayjs() : undefined}
      getValueProps={(value) => ({ value: value ? dayjs(value) : null })}
    >
      <DatePicker />
    </FormItem>
  );
};

export default RenderSelectDate;
