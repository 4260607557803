import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { usePagination } from '@hellodarwin/core/lib/features/providers/pagination-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Input from 'antd/es/input/Input';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/app-hooks';
import CompaniesList from '../components/companies/companies-list';
import {
  queryCompanies,
  selectAllCompanies,
  selectCompaniesIsLoading,
  selectCompaniesTotal,
} from '../features/api/slices/companies-slice';
import { useAdminApi } from '../features/api/use-admin-api';

const CompaniesPage = () => {
  const dispatch = useAppDispatch();

  const companies = useAppSelector(selectAllCompanies);
  const total = useAppSelector(selectCompaniesTotal);
  const isLoading = useAppSelector(selectCompaniesIsLoading);
  const [isInitialised, setIsInitialised] = useState(false);
  const api = useAdminApi();
  const { t } = useTranslations();
  const { pathname } = useLocation();
  const { activePage, pageSize, addParam, removeParam, params, goToPage } =
    usePagination();
  const [query, setQuery] = useState(params?.['query'] ?? '');

  const handleFetch = useDebounceFunction(
    () => {
      dispatch(
        queryCompanies({ api, page: activePage, limit: pageSize, query }),
      );

      if (!isInitialised) setIsInitialised(true);
    },
    500,
    true,
  );

  useEffect(() => {
    if (!!params['query'] && params['query'] !== query) {
      setQuery(params.query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    handleFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageSize, pathname]);

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageSize]);

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialised) {
      if (!!query.length) {
        addParam('query', query);
      } else {
        removeParam('query');
      }
      if (activePage !== 1) {
        goToPage(1, 50);
      } else {
        handleFetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // const handleModal = () => {
  //   dispatch(toggleCompanyModal({ open: true, type: "create" }));
  // };

  // const companiesActions: ItemType[] = useMemo(() => {
  //   const actions: ItemType[] = [
  //     {
  //       label: t("companyAdminPage|createCompany"),
  //       icon: <AddNew size={16} />,
  //       key: "createCompany",
  //       onClick: handleModal,
  //     },
  //   ];
  //   return actions;
  // }, []);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);
  };

  return (
    <PageLayout
      app="admin"
      title={t('companyAdminPage|companies')}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('companyAdminPage|companies'),
        },
      ]}
      actions={
        <Div flex="row" gap={8} align="center" justify="flex-end">
          <Input
            prefix={<Search size={20} />}
            placeholder={t('companyAdminPage|searchCompany')}
            size="large"
            style={{ width: 500, maxWidth: '100%', alignSelf: 'flex-end' }}
            value={query}
            onChange={handleSearch}
          />
          {/* <Dropdown items={companiesActions} cta={{ size: 32 }} /> */}
        </Div>
      }
    >
      <CompaniesList
        companies={companies}
        total={total}
        isLoading={isLoading}
      />
    </PageLayout>
  );
};

export default CompaniesPage;
