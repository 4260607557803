import Propulsion from '@hellodarwin/icons/dist/icons/Propulsion';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Service from '@hellodarwin/icons/dist/icons/Service';
import Divider from 'antd/es/divider';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import List, { ListGridType } from '../../../common/List';
import ProgramEmptyStateStepCard, {
  ProgramEmptyStateStepCardProps,
} from './program-empty-card-step-card';

const ProgramEmptyStateContent = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const grid: ListGridType = {
    gutter: 28,
    column: 3,
    sm: 1,
    md: 1,
    lg: 3,
    xl: 3,
    xxl: 3,
  };

  const items: ProgramEmptyStateStepCardProps[] = [
    {
      Icon: Search,
      title: t('propulsion|empty.card1.title'),
      text: t('propulsion|empty.card1.text'),
    },
    {
      Icon: Service,
      title: t('propulsion|empty.card2.title'),
      text: t('propulsion|empty.card2.text'),
    },
    {
      Icon: Propulsion,
      title: t('propulsion|empty.card3.title'),
      text: t('propulsion|empty.card3.text'),
    },
  ];

  return (
    <div style={{ position: 'relative', width: 'fit-content', margin: 'auto' }}>
      <Divider
        style={{
          borderColor: theme.colors.grey_,
          position: 'absolute',
          top: '50%',
        }}
      />
      <List
        grid={grid}
        styles={{ container: { minWidth: theme.breakpoints.tablet } }}
        dataSource={items}
        renderItem={(item) => (
          <List.Item
            style={{ height: '100%', position: 'relative', zIndex: 1 }}
          >
            <ProgramEmptyStateStepCard {...item} />
          </List.Item>
        )}
      />
    </div>
  );
};

export default ProgramEmptyStateContent;
