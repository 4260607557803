import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import { Company } from '@hellodarwin/core/lib/features/entities';
import CompanyForm from '../company-form';

interface UpdateCompanyModalProps {
  open: boolean;
  handleCancel: () => void;
  company: Company;
}

const UpdateCompanyModal: React.FC<UpdateCompanyModalProps> = ({
  company,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps} size="large">
      <CompanyForm company={company} setForms={() => null} />
    </Modal>
  );
};

export default UpdateCompanyModal;
