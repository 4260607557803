import {
  AddressInformations,
  FormAddressInformations,
} from '../../../features/entities/core-entities';

export const getShortAddress = ({
  street_number,
  subpremise,
  route,
}: AddressInformations) => {
  let newAddressString = `${
    !!street_number ? `${street_number}${!!route ? ` ${route}` : ''}` : ''
  }`;
  newAddressString += (!!street_number || !!route) && subpremise ? ', ' : '';
  newAddressString += !!subpremise ? `${subpremise}` : '';
  return newAddressString;
};

export const getLongAddress = (
  shortAddress: string,
  { city, province, postal_code, country }: FormAddressInformations,
) => {
  let newAddressString = shortAddress;
  newAddressString += city ? `, ${city}` : '';
  newAddressString += province ? `, ${province}` : '';
  newAddressString += postal_code ? `, ${postal_code}` : '';
  newAddressString += country ? `, ${country}` : '';
  return newAddressString;
};
