import Card from '@hellodarwin/core/lib/components/common/Card';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import { useNavigate } from 'react-router-dom';

const InfoRow = ({ label, value }: { label: string; value?: string }) => {
  if (!value) return <></>;
  return (
    <Div flex="row" gap={8} fitContent align="center">
      <Typography elementTheme="body2" style={{ fontWeight: 600 }}>
        {label}
      </Typography>
      <Typography elementTheme="body2">{value}</Typography>
    </Div>
  );
};

const FormSubmissionContactCard = ({ contact }: { contact: Contact }) => {
  const navigate = useNavigate();
  if (!contact.contact_id) return <></>;

  return (
    <Card
      hoverable
      onClick={() => navigate(`/companies/${contact.company_id}/users`)}
      style={{
        padding: 24,
        width: '100%',

        flex: 1,
      }}
    >
      <Div flex="column" gap={8}>
        <Typography.Title level={1} elementTheme="h6">
          {contact.name}
        </Typography.Title>

        <InfoRow label="Email : " value={contact.email} />
        <InfoRow label="Phone : " value={contact.phone} />
        <InfoRow
          label="Preferred Language : "
          value={contact.preferred_language}
        />
      </Div>
    </Card>
  );
};
export default FormSubmissionContactCard;
