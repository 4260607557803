import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';

import { Match } from '@hellodarwin/core/lib/features/entities';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import theme from '../../../theme';
interface CancelRfpModalProps {
  modalVisible: boolean;
  handleCancel: (canceledReason: string) => Promise<void>;
  closeModal: () => void;
  matches: Match[];
}

interface CancelRfpFormValues {
  cancellation_reason: string;
}

const CancelRfpModal = ({
  modalVisible,
  handleCancel,
  closeModal,
  matches,
}: CancelRfpModalProps) => {
  const [form] = Form.useForm<CancelRfpFormValues>();
  const { Title } = Typography;
  const { Option, OptGroup } = Select;

  const matches_raise_hand =
    matches?.filter(
      (m) =>
        !!m.raised_hand_at &&
        !m.purchased_at &&
        !m.shortlisted_at &&
        !m.raise_hand_rejected_at,
    ) || [];
  const emailed_providers = matches_raise_hand?.map((p) => p.provider.name);

  return (
    <Modal
      title="Cancel Rfp"
      afterClose={form.resetFields}
      open={modalVisible}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          await handleCancel(values.cancellation_reason);
        } catch (info) {
          console.error('Validate Failed:', info);
        }
      }}
      onCancel={closeModal}
      okText={'Confirm'}
      okType={'danger'}
    >
      <Form form={form} layout={'vertical'} name={'cancel-rfp'}>
        <Div flex="column" gap={16}>
          <Div flex="column">
            <Title level={5}>
              To ensure we serve you better next time, please provide a reason
              for cancelling this rfp?
            </Title>

            <Typography color={theme.colors.yellow_2}>
              {emailed_providers.slice(0, -1).join(', ')}
              {emailed_providers.length > 1 ? ' and ' : ''}
              {emailed_providers.slice(-1)} will receive an email to notify them
              of the rfp's cancellation.
            </Typography>
          </Div>
          <Div>
            <Form.Item
              name={'cancellation_reason'}
              rules={[
                { required: true, message: 'Cancellation reason is required' },
              ]}
            >
              <Select
                placeholder={'Select Cancellation Reason'}
                defaultActiveFirstOption={false}
                size={'large'}
              >
                {/* <OptGroup label="Rfp Rejected">
                  <Option value="Spam">Spam</Option>
                  <Option value="Test">Test</Option>
                  <Option value="No budget / unrealistic expectations">
                    No budget / unrealistic expectations
                  </Option>
                  <Option value="No # number / Wrong contact info">
                    No # number / Wrong contact info
                  </Option>
                  <Option value="Not a service">Not a service</Option>
                  <Option value="The rfp is too early/not mature enough">
                    The rfp is too early/not mature enough
                  </Option>
                  <Option value="The client isn’t serious enough / Bad targeting">
                    The client isn’t serious enough / Bad targeting
                  </Option>
                </OptGroup> */}
                <OptGroup label="Rfp Canceled">
                  <Option value="Found a SP outside the platform">
                    Found a SP outside the platform
                  </Option>
                  <Option value="Rfp was canceled">Rfp was canceled</Option>
                  <Option value="Never reached the client">
                    Never reached the client
                  </Option>
                  <Option value="rescoping">Rfp was re-scoped</Option>
                  <Option value="Stopped Answering / Became Unresponsive">
                    Stopped Answering / Became Unresponsive
                  </Option>
                </OptGroup>
              </Select>
            </Form.Item>
          </Div>
        </Div>
      </Form>
    </Modal>
  );
};
export default CancelRfpModal;
