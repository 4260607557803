import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const YoutubeLogo = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6102 4.79999C17.3927 4.80438 19.3503 4.82343 21.431 4.90695L22.1695 4.93919C24.2633 5.03736 26.3557 5.20733 27.3946 5.49599C28.7793 5.88575 29.8665 7.01987 30.2343 8.45877C30.8204 10.7446 30.8937 15.2019 30.9025 16.2818L30.9039 16.5046V16.7595C30.8937 17.8394 30.8204 22.2982 30.2343 24.5826C29.8621 26.0259 28.7734 27.1615 27.3946 27.5454C26.3557 27.834 24.2633 28.004 22.1695 28.1022L21.431 28.1359C19.3503 28.2179 17.3927 28.2384 16.6102 28.2414L16.2659 28.2428H15.8922C14.2365 28.2326 7.31163 28.1579 5.10786 27.5454C3.72465 27.1556 2.63595 26.0215 2.26817 24.5826C1.68206 22.2968 1.6088 17.8394 1.60001 16.7595V16.2818C1.6088 15.2019 1.68206 10.7431 2.26817 8.45877C2.64035 7.01548 3.72904 5.87989 5.10933 5.49746C7.31163 4.88351 14.238 4.80878 15.8937 4.79999H16.6102ZM13.3207 11.3937V21.6506L22.1123 16.5221L13.3207 11.3937Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default YoutubeLogo;
