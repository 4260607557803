import Button from '@hellodarwin/core/lib/components/common/button';
import Collapse from '@hellodarwin/core/lib/components/common/Collapse';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Step, StepAsset } from '@hellodarwin/core/lib/features/entities';
import getTranslationsFromString from '@hellodarwin/core/lib/features/helpers/get-translation-from-strings';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Checkbox from 'antd/es/checkbox/Checkbox';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import React, { MouseEventHandler } from 'react';
import { useAppDispatch } from '../../../../app';
import { updateProgramStep } from '../../../../features/api/slices/gin-steps-slice';
import { useNewAdminApi } from '../../../../features/api/use-admin-api';
import { GinBlockComponentType } from '../gin-block';
import getStepNumberLabel from './get-step-number-label';
import GinStepCardContent from './gin-step-card-content';

interface GinStepCardProps extends Step {
  isClientView?: boolean;
  index: number;
  pageType?: GinBlockComponentType;
  entityId: string;
  openStepForm: (step: Step | undefined) => void;
  openStepAssetForm: (asset: StepAsset) => void;
}
const GinStepCard: React.FC<GinStepCardProps> = ({
  index,
  pageType,
  entityId,
  isClientView,
  openStepForm,
  openStepAssetForm,
  ...step
}) => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const { selectedLocale } = useLocale();

  const handleEdit: MouseEventHandler = (e) => {
    e.preventDefault();
    openStepForm(step);
  };

  const handleEditAsset = (asset: StepAsset) => {
    openStepForm(step);
    openStepAssetForm(asset);
  };

  const handleCheck = async () => {
    try {
      await dispatch(
        updateProgramStep({ api, program_id: entityId, step_id: step.step_id }),
      ).unwrap();
      message.success('Step state saved');
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
    }
  };

  const getStepOwner = (owner: string) => {
    return owner
      .split('_')
      .map((word) => word.charAt(0).toUpperCase())
      .join('');
  };
  return (
    <Collapse
      noPadding
      items={[
        {
          id: step.step_id,
          styles: {
            header: {
              backgroundColor: theme.colors.beige_2,
              padding: '8px 24px',
            },
          },

          title: (
            <Div flex="row" align="center" gap={32} justify="space-between">
              <Typography>
                {getStepNumberLabel({
                  stepText: t('gin_section|step'),
                  index,
                  fallback: t('gin_section|new_step'),
                  stepTitle: getTranslationsFromString({
                    translation_en: step.title_en,
                    translation_fr: step.title_fr,
                    selectedLocale,
                  }),
                })}
              </Typography>
              <Div flex="row" align="center" gap={8} fitContent>
                {step.owner && (
                  <Tooltip title={t(`gin_steps|owner_${step.owner}`)}>
                    <Div
                      flex="row"
                      justify="center"
                      align="center"
                      borderRadius="4px"
                      borderColor={theme.colors.black}
                      backgroundColor={theme.colors.white_1}
                      style={{
                        width: '34px',
                        height: '34px',
                      }}
                    >
                      <Typography elementTheme="body2">
                        {getStepOwner(step.owner)}
                      </Typography>
                    </Div>
                  </Tooltip>
                )}
                {pageType === 'application' ? (
                  <Checkbox
                    defaultChecked={step.validated}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleCheck}
                  />
                ) : (
                  <Button
                    onClick={handleEdit}
                    headingIcon={<Edit size={16} />}
                    size="square"
                    defaultStyle={theme.colors.white_1}
                  />
                )}
              </Div>
            </Div>
          ),
          children: (
            <GinStepCardContent
              {...step}
              isClientView={isClientView}
              handleOpenAsset={handleEditAsset}
            />
          ),
        },
      ]}
      ExpandIcon={({ isActive }) => <TabArrow size={10} down={isActive} />}
    />
  );
};

export default GinStepCard;
