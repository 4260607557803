import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { FormBuilderFunctions } from '@hellodarwin/core/lib/components/forms/form-builder/types';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  AdminFormResponse,
  FormPageResponse,
  Forms,
} from '@hellodarwin/core/lib/features/entities/form-entities';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import AddPage from '@hellodarwin/icons/dist/icons/AddPage';
import HubspotLogo from '@hellodarwin/icons/dist/icons/HubspotLogo';
import Language from '@hellodarwin/icons/dist/icons/Language';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import RefreshHubspot from '@hellodarwin/icons/dist/icons/RefreshHubspot';
import View from '@hellodarwin/icons/dist/icons/View';
import Divider from 'antd/es/divider';
import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import dayjs from 'dayjs';
import { lazy, PropsWithChildren, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app';
import {
  deleteFormPublishedDate,
  getHubspotFormId,
  updateForm,
  updateHubspotForm,
} from '../../../features/api/slices/forms-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import FormEditButton from './form-edit-button';

const DatePicker = lazy(() => import('antd/es/date-picker'));

const ActionsSection = ({
  label,
  children,
}: {
  label: string;
} & PropsWithChildren) => {
  return (
    <Div flex="column" gap={4} fitContent style={{ padding: 4 }}>
      <Typography elementTheme="overline">{label}</Typography>
      <Div flex="row" gap={4} fitContent>
        {children}
      </Div>
    </Div>
  );
};

interface FormBuilderHeaderProps {
  formResponse?: AdminFormResponse;
  functions?: FormBuilderFunctions;
  handleLocale?: () => void;
}

const FormBuilderHeader = ({
  formResponse,
  functions,
  handleLocale,
}: FormBuilderHeaderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const handleFormUpdate = async (newFormResponse: Forms) => {
    try {
      await dispatch(
        updateForm({
          api,
          form: newFormResponse,
        }),
      ).unwrap();

      message.success('Form Saved');
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const handlePublishedAt = async (date: any) => {
    if (!date) {
      deletePublishedAt();
    } else {
      const editedForm: Forms = {
        form_id: formResponse!.form_id,
        grants: formResponse!.grants!,
        title_en: formResponse!.title_en!,
        title_fr: formResponse!.title_fr!,
        subtitle_en: formResponse!.subtitle_en!,
        subtitle_fr: formResponse!.subtitle_fr!,
        form_slug_en: formResponse?.form_slug_en,
        form_slug_fr: formResponse?.form_slug_fr,
        form_type: formResponse!.form_type!,
        published_at: date,
      };

      try {
        await dispatch(
          updateForm({
            api,
            form: editedForm,
          }),
        ).unwrap();

        message.success('New published date saved');
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      }
    }
  };

  const deletePublishedAt = async () => {
    try {
      await dispatch(
        deleteFormPublishedDate({
          api,
          formId: formResponse!.form_id,
        }),
      ).unwrap();

      message.success('Published date deleted');
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const goToForms = () => {
    navigate('/forms');
  };
  const goToSubmissions = () => {
    navigate(`/forms/${formResponse?.form_id}/submissions`);
  };

  const goToHubspot = () => {
    if (!formResponse) return;
    window.open(formResponse.hubspot_url, '_blank')?.focus();
  };

  const searchHubspotForm = () => {
    if (!formResponse) return;
    dispatch(getHubspotFormId({ api, formId: formResponse.form_id }));
  };
  const handleUpdateHubspotForm = () => {
    if (!formResponse) return;
    dispatch(updateHubspotForm({ api, formId: formResponse.form_id }));
  };
  const handleNewPage = () => {
    if (!formResponse) return;

    let formPage: FormPageResponse = {
      form_page_id: '',
      page_number: formResponse?.pages?.length,
      page_title_en: 'New Page Title',
      page_title_fr: 'Nouveau titre de page',
      form_id: formResponse.form_id,
      groups: [],
    };

    functions?.createPage(formPage);
  };

  return (
    <Div
      flex="row"
      gap={16}
      align="center"
      justify="space-between"
      borderColor={theme.colors.primary}
      backgroundColor={theme.colors.beige_1}
      style={{
        padding: '0 24px',
        height: 60,
      }}
    >
      <Tooltip title="Return to forms">
        <div>
          <Button
            onClick={goToForms}
            size="auto"
            withArrowLeft
            defaultStyle={theme.colors.white_1}
            style={{ height: 32, borderRadius: 99 }}
          />
        </div>
      </Tooltip>
      <Div flex="row" gap={16} align="stretch" justify="flex-end">
        <Divider
          type="vertical"
          style={{
            borderColor: theme.colors.primary,
            height: 'auto',
            top: 0,
          }}
        />
        <ActionsSection label="Published At">
          <Suspense fallback={<Loading />}>
            <DatePicker
              size="middle"
              style={{
                height: 34,
                borderColor: theme.colors.primary,
                borderRadius: 2,
              }}
              picker="date"
              allowClear
              value={
                formResponse?.published_at
                  ? dayjs(formResponse?.published_at)
                  : null
              }
              onChange={(date) => handlePublishedAt(date)}
            />
          </Suspense>
        </ActionsSection>
        <Divider
          type="vertical"
          style={{
            borderColor: theme.colors.primary,
            height: 'auto',
            top: 0,
          }}
        />
        {!!formResponse && (
          <>
            <ActionsSection label="Hubspot">
              {!!formResponse.hubspot_url ? (
                <>
                  <Tooltip title="Update Hubspot Form">
                    {' '}
                    <div>
                      <Button
                        onClick={handleUpdateHubspotForm}
                        size="square"
                        trailingIcon={<RefreshHubspot size={16} />}
                        defaultStyle={theme.colors.white_1}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip title="Go to Hubspot">
                    <div>
                      <Button
                        onClick={goToHubspot}
                        size="square"
                        trailingIcon={<HubspotLogo size={16} />}
                        defaultStyle={theme.colors.green_1}
                        style={{ borderColor: theme.colors.primary }}
                      />
                    </div>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Search Hubspot Form">
                  {' '}
                  <div>
                    <Button
                      onClick={searchHubspotForm}
                      size="square"
                      trailingIcon={<HubspotLogo size={16} />}
                      defaultStyle={theme.colors.red_1}
                      style={{ borderColor: theme.colors.primary }}
                    />
                  </div>
                </Tooltip>
              )}
            </ActionsSection>
            <Divider
              type="vertical"
              style={{
                borderColor: theme.colors.primary,
                height: 'auto',
                top: 0,
              }}
            />
            <ActionsSection label="Edition">
              <FormEditButton
                formResponse={formResponse}
                updateForm={handleFormUpdate}
              />
              <Tooltip title="Create Page">
                {' '}
                <div>
                  <Button
                    onClick={handleNewPage}
                    size="square"
                    trailingIcon={<AddPage size={16} />}
                    defaultStyle={theme.colors.white_1}
                  />
                </div>
              </Tooltip>
            </ActionsSection>
            <Divider
              type="vertical"
              style={{
                borderColor: theme.colors.primary,
                height: 'auto',
                top: 0,
              }}
            />
            <ActionsSection label="view">
              <Tooltip title="Preview">
                <div>
                  <Button
                    onClick={() =>
                      navigate('/forms/' + formResponse.form_id + '/preview')
                    }
                    size="square"
                    trailingIcon={<View size={16} />}
                    defaultStyle={theme.colors.white_1}
                  />
                </div>
              </Tooltip>

              <Tooltip title="Change Language">
                {' '}
                <div>
                  <Button
                    onClick={handleLocale}
                    size="square"
                    trailingIcon={<Language size={16} />}
                    defaultStyle={theme.colors.white_1}
                  />
                </div>
              </Tooltip>
              <Tooltip title="View Submissions">
                <div>
                  <Button
                    onClick={goToSubmissions}
                    size="square"
                    trailingIcon={<ProjectsList size={16} />}
                    defaultStyle={theme.colors.white_1}
                  />
                </div>
              </Tooltip>
            </ActionsSection>
          </>
        )}
      </Div>
    </Div>
  );
};

export default FormBuilderHeader;
