import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Service from '@hellodarwin/icons/dist/icons/Service';
import React, { useEffect, useState } from 'react';
import {
  Portfolio,
  PortfolioAsset,
} from '../../../../../features/entities/core-entities';
import getShortId from '../../../../../features/helpers/get-short-id';
import parse from '../../../../../features/helpers/parse';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import HdTag from '../../../../common/hd-tag';
import Typography from '../../../../common/typography';
import Gallery from './Gallery';
import { PortfolioPreviewInfoContainer } from './styles';

type ProjectPortfolioPreviewProps = {
  portfolio: Portfolio;
};

const PortfolioPreview = ({ portfolio }: ProjectPortfolioPreviewProps) => {
  const { t } = useTranslations();
  const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
  const theme = useTheme();

  const [assets, setAssets] = useState<PortfolioAsset[]>([]);

  useEffect(() => {
    if (!!portfolio.assets?.length) {
      setAssets([...portfolio.assets]);
    }
  }, [portfolio]);

  const Header = () => (
    <Div
      flex="row"
      gap={38}
      tablet={{ flex: 'column' }}
      style={{ padding: 40 }}
    >
      <Typography elementTheme="h5" style={{ minWidth: 353 }}>
        {portfolio.portfolio_title ||
          portfolio.client_company_name ||
          translateTag(`tags|${portfolio.service}`) ||
          `Portfolio ${getShortId(portfolio.provider_portfolio_id)}`}
      </Typography>
    </Div>
  );

  const Infos = () => {
    const InfoTile = ({
      category,
      value,
      Icon,
    }: {
      category: string;
      value: string;
      Icon: IconNode;
    }) => (
      <PortfolioPreviewInfoContainer
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px 40px',
        }}
      >
        <Div flex="row" gap={8} style={{ maxWidth: 184 }}>
          <Icon width={13} height={13} />
          <Div style={{ marginTop: 2 }}>
            <Typography elementTheme="overline" color={theme.colors.grey_2}>
              {category}
            </Typography>
            <Typography elementTheme="body2">{value}</Typography>
          </Div>
        </Div>
      </PortfolioPreviewInfoContainer>
    );
    return (
      <Div
        flex="row"
        borderColor={theme.colors.grey_4}
        bordersSide="horizontal"
      >
        <InfoTile
          category={t('profile_portfolio|portfolioLabelsServiceReceived')}
          value={translateTag(`tags|${portfolio.service}`)}
          Icon={Service}
        />
        <InfoTile
          category={t('profile_portfolio|portfolioLabelsIndustry')}
          value={translateTag(`tags|${portfolio.category}`)}
          Icon={Industry}
        />
      </Div>
    );
  };

  const RfpDescription = () => (
    <Div
      style={{ padding: '0 35px', overflow: 'hidden' }}
      flex="column"
      gap={26}
    >
      <Typography elementTheme="h6">
        {t('profile_portfolio|rfpDescription')}
      </Typography>
      <Typography
        elementTheme="body2"
        style={{ columnCount: 2, columnGap: 32 }}
        tablet={{ style: { columnCount: 1 } }}
      >
        {parse(portfolio?.description || '')}
      </Typography>
    </Div>
  );
  const RfpTags = () => (
    <Div
      style={{ padding: '0 35px', overflow: 'hidden' }}
      flex="column"
      gap={20}
    >
      <Typography elementTheme="overline" textTransform="uppercase">
        {t('profile_portfolio|rfpTags')}
      </Typography>
      <Div flex="row" wrap="wrap" gap={8} style={{ maxWidth: 458 }}>
        {React.Children.toArray(
          portfolio.tags.map((tag) => (
            <HdTag color={theme.colors.purple_5} translateTag text={tag} />
          )),
        )}
      </Div>
    </Div>
  );

  return (
    <Div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
      <Header />
      <Div flex="column" gap={35} style={{ marginBottom: 45 }}>
        <Infos />

        {!!assets.length && <Gallery {...portfolio} />}
        {!!portfolio.description && <RfpDescription />}
        {!!portfolio.tags && <RfpTags />}
        {!!portfolio.from_hellodarwin_at && (
          <Div
            flex="row"
            align="center"
            style={{ padding: '6px 27px', paddingLeft: 45 }}
            gap={6}
            backgroundColor={theme.colors.yellow_1}
            fitContent
          >
            <Typography elementTheme="body2" inline>
              {t('profile_portfolio|madeWith')}
            </Typography>
            <HdLogo
              width={125}
              height={15}
              color="b/w"
              backgroundType="light"
              style={{ marginBottom: 4 }}
            />
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default PortfolioPreview;
