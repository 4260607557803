import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FundingExplorerProps } from '..';
import useDebounceFunction from '../../../../features/hooks/use-debounce-function';
import { usePagination } from '../../../../features/providers/pagination-provider';
import { usePlatform } from '../../../../features/providers/platform-provider';
import { FundingExplorerFilterValues } from '../filter/types';
import { default as checkCompanyIsInFilter } from './check-company-values';
import usePrepareFundingExplorerFilters from './prepare-filters';
import usePrepareFundingExplorerURL from './prepare-url';

const FILTER_DELAY_MS = 500;

export interface QueryFundingExplorerProps {
  locale: string;
  page: number;
  limit: number;
  textQuery?: string;
  companyId?: string;
  filterBy?: string;
  industry?: string[];
  subindustry?: string[];
  status?: string[];
  financingType?: string[];
  region?: string[];
  closingDate?: Date;
  service?: string[];
  selectedProjects?: string[];
  annualRevenue?: string;
  companySize?: string;
}

type ApplyFundingExplorerFilterType =
  | 'default'
  | 'init'
  | 'companyReset'
  | 'reset'
  | 'filterChanged';
interface ApplyFundingExplorerFilterProps {
  type: ApplyFundingExplorerFilterType;
}

export type ApplyFundingExplorerFilterFunction = (
  props?: ApplyFundingExplorerFilterProps,
) => Promise<void>;

type FiltersState = 'need-init' | 'loading' | 'idle';

const useApplyFundingExplorerFilter = (props: FundingExplorerProps) => {
  const {
    fetch,
    filterform,

    company,
    showCompanyFilterCardState,

    filterValues,
  } = props;
  const { app } = usePlatform();
  const { params, goToPage, paramsIsLoading } = usePagination();

  const [filtersState, setFiltersState] = useState<FiltersState>('need-init');

  const [prevCompany, setPrevCompany] = useState(undefined);
  const [pageChanged, setPageChanged] = useState(false);
  const [prevData, setPrevData] =
    useState<FundingExplorerFilterValues>(filterValues);

  const prepareFundingExplorerURL = usePrepareFundingExplorerURL(props);
  const prepareFundingExplorerFilters = usePrepareFundingExplorerFilters(props);

  const prepareFundingExplorerCompanyFilters = () => {
    if (!company) return false;

    const formMapping = {
      industry: company.industry_sector ?? [],
      subindustry: company.industry_subsector ?? [],
      region: company.province?.split(',') ?? [],
      companySize: company.size ?? '',
      annualRevenue: company.annual_revenue ?? '',
    };

    filterform.setFieldsValue(formMapping);
  };

  const resetFiltersState = () => {
    filterform.resetFields();
  };

  const setShowCompanyFilterCard = useMemo(
    () =>
      !!showCompanyFilterCardState ? showCompanyFilterCardState[1] : () => {},
    [showCompanyFilterCardState],
  );

  const handleState = (state: FiltersState) => {
    setTimeout(() => setFiltersState(state), 200);
  };

  const handleFetch = async (isInit?: boolean) => {
    const values = filterform.getFieldsValue();
    setPrevData(values);
    if (isInit) {
      const companyIsInFilter = checkCompanyIsInFilter({
        company,
        filterValues: values,
      });
      setShowCompanyFilterCard(companyIsInFilter);
    } else {
      setShowCompanyFilterCard(false);
    }
    prepareFundingExplorerURL();

    await fetch(values);
    handleState('idle');
  };

  const debouncedActions = {
    companyReset: async () => {
      prepareFundingExplorerCompanyFilters();

      handleFetch(true);
    },

    reset: async () => {
      resetFiltersState();

      handleFetch(true);
    },

    init: async () => {
      prepareFundingExplorerFilters();

      handleFetch(true);
    },

    filterChanged: useDebounceFunction(
      async () => {
        if (!pageChanged) {
          goToPage(1);
        }
        handleFetch(false);
      },
      FILTER_DELAY_MS,
      true,
    ),
  };

  const resetFilters = () => {
    applyFilter({ type: 'reset' });
  };

  const resetFiltersWithCompany = () => {
    applyFilter({ type: 'companyReset' });
  };

  useEffect(() => {
    if (company === undefined && app === 'admin') {
      applyFilter({ type: 'reset' });
    } else if (!!company.company_id.length && !paramsIsLoading) {
      applyFilter({ type: 'init' });
    }
  }, []);

  useEffect(() => {
    if (filtersState !== 'idle' || !pageChanged) return;
    applyFilter({ type: 'default' });
    setPageChanged(false);
  }, [pageChanged]);

  const applyFilter = useDebounceFunction(
    async (props?: ApplyFundingExplorerFilterProps) => {
      if (filtersState === 'loading') return;
      const applyType = props?.type ?? 'default';
      if (
        ['default', 'filterChanged'].includes(applyType) &&
        filtersState === 'need-init'
      )
        return;
      switch (applyType) {
        case 'companyReset':
          setFiltersState('need-init');
          debouncedActions.companyReset();
          break;
        case 'reset':
          setFiltersState('need-init');
          debouncedActions.reset();
          break;
        case 'init':
          setFiltersState('need-init');

          const paramsValues = Object.values(params).filter((itm) => !!itm);

          const hasNoParams =
            !paramsValues.length ||
            (paramsValues.length === 1 && !!params['page']);

          if (hasNoParams && company?.company_id) {
            debouncedActions.companyReset();
          } else {
            debouncedActions.init();
          }
          break;
        default:
          setFiltersState('loading');

          debouncedActions.filterChanged();
          break;
      }
    },
    1000,
  );

  const isInitialised = useMemo(
    () => ['idle', 'loading'].includes(filtersState),
    [filtersState],
  );
  const filtersLoading = useMemo(() => filtersState !== 'idle', [filtersState]);

  useEffect(() => {
    if (!_.isEqual(filterValues, prevData)) {
      applyFilter({ type: 'filterChanged' });
    }
  }, [filterValues]);

  const handlePageChange = async (page: number, pageSize: number) => {
    goToPage(page, pageSize);

    setPageChanged(true);
  };

  return {
    filtersLoading,
    resetFilters,
    resetFiltersWithCompany,
    isInitialised,
    handlePageChange,
  };
};

export default useApplyFundingExplorerFilter;
