import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const File = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 19.4615V15.4231C27 14.046 26.4785 12.7253 25.5502 11.7516C24.6219 10.7778 23.3628 10.2308 22.05 10.2308H19.85C19.4124 10.2308 18.9927 10.0484 18.6833 9.72384C18.3738 9.39926 18.2 8.95903 18.2 8.5V6.19231C18.2 4.81522 17.6785 3.49454 16.7502 2.52079C15.8219 1.54705 14.5628 1 13.25 1H10.5M13.8 1H6.65C5.7392 1 5 1.77538 5 2.73077V29.2692C5 30.2246 5.7392 31 6.65 31H25.35C26.2608 31 27 30.2246 27 29.2692V14.8462C27 11.1739 25.6093 7.6521 23.1338 5.05544C20.6583 2.45879 17.3009 1 13.8 1Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default File;
