import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Download from '@hellodarwin/icons/dist/icons/Download';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Hide from '@hellodarwin/icons/dist/icons/Hide';
import View from '@hellodarwin/icons/dist/icons/View';
import Tooltip from 'antd/es/tooltip';
import React, { MouseEventHandler } from 'react';
import {
  AssetRowMeta,
  AssetWithOwnersEntity,
} from '../../../../features/entities/assets-entities';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { AssetEditRowContainer } from './styles';

interface AssetEditRowProps {
  meta: AssetRowMeta;
  editAsset: (meta: AssetRowMeta | undefined) => void;
  openHideModal?: () => void;
}
const AssetEditRow: React.FC<AssetEditRowProps> = ({
  editAsset,
  meta,
  openHideModal,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleClick = () => {
    editAsset(meta);
  };

  const handleDeleteRow: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (meta.actions.onDelete) meta.actions.onDelete();
  };

  const handleDownload: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (meta.actions.onAction) meta.actions.onAction();
  };
  const isAssetHidden = () => {
    return (
      meta.hidden.is_admin_hidden ||
      meta.hidden.is_contacts_hidden ||
      meta.hidden.is_companies_hidden ||
      meta.hidden.is_providers_hidden
    );
  };

  return (
    <>
      <AssetEditRowContainer onClick={handleClick} $hidden={!!meta.hidden}>
        <Typography elementTheme="body3" textAlign="right">
          {meta.name}
        </Typography>

        <Div gap={8} flex="row" align="center" fitContent>
          <Tooltip
            title={
              isAssetHidden()
                ? t('assets|show_user_asset')
                : t('assets|hide_user_asset')
            }
          >
            <Button
              size="square"
              transparent
              defaultStyle={theme.colors.primary}
              onClick={openHideModal}
            >
              {isAssetHidden() ? <Hide size={16} /> : <View size={16} />}
            </Button>
          </Tooltip>
          <Button
            size="square"
            transparent
            defaultStyle={theme.colors.primary}
            onClick={handleClick}
          >
            <Edit size={16} />
          </Button>
          {(meta.original_asset as AssetWithOwnersEntity).file_name && (
            <Button
              size="square"
              transparent
              defaultStyle={theme.colors.primary}
              onClick={handleDownload}
            >
              <Download size={16} />
            </Button>
          )}

          <Button
            size="square"
            transparent
            defaultStyle={theme.colors.primary}
            onClick={handleDeleteRow}
          >
            <Delete size={16} />
          </Button>
        </Div>
      </AssetEditRowContainer>
    </>
  );
};

export default AssetEditRow;
