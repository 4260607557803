import styled, { DefaultTheme } from '@hellodarwin/core/lib/plugins/styled';

const getBoxColor = (value: number, theme: DefaultTheme, active: boolean) => {
  if (!active) return theme.colors.beige_2;
  switch (value) {
    case 0:
      return theme.colors.purple_5; // Green
    case 1:
      return theme.colors.purple_4; // Green
    case 2:
      return theme.colors.purple_3; // Green
    case 3:
      return theme.colors.purple_2; // Green
    case 4:
      return theme.colors.purple_1; // Green
    default:
      return theme.colors.beige_2; // Default color (gray) for invalid values
  }
};

const getTextColor = (value: number, theme: DefaultTheme, active: boolean) => {
  if (!active) return theme.colors.grey_2;
  switch (value) {
    case 0:
      return theme.colors.grey_1; // Green
    case 1:
      return theme.colors.grey_1; // Green
    case 2:
      return theme.colors.white_1; // Green
    case 3:
      return theme.colors.white_1; // Green
    case 4:
      return theme.colors.white_1; // Green
    default:
      return theme.colors.grey_2; // Default color (gray) for invalid values
  }
};

export const PriorityBox = styled.div<{ $value: number; $active: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $value, theme, $active }) =>
    getTextColor($value, theme, $active)};
  background-color: ${({ $value, theme, $active }) =>
    getBoxColor($value, theme, $active)};
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey_5};
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  &:hover {
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
  }
`;
