import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Empty from 'antd/es/empty';
import notification from 'antd/es/notification';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  fetchCompanyProvider,
  selectCompaniesIsLoading,
  selectCompanyById,
  selectCompanyProvider,
} from '../../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import { RelatedLink } from '../../../gins/sidebars/cards/related-link';

interface CompanyRelatedLinksProps {
  company_id?: string;
}

const CompanyRelatedLinks: React.FC<CompanyRelatedLinksProps> = ({
  company_id,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();

  const provider = useAppSelector(selectCompanyProvider);
  const company = useAppSelector((state) =>
    selectCompanyById(state, company_id ?? ''),
  );

  const isLoading = useAppSelector((state) =>
    selectCompaniesIsLoading(state, 'fetchProvider'),
  );

  useEffect(() => {
    if (!!company_id && company_id !== '') {
      dispatch(fetchCompanyProvider({ api, company_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  const openCompanyBilling = () => {
    (async () => {
      try {
        if (!!provider?.billing_customer_id?.length) {
          const session = await api.createBillingSession(
            company.company_billing_customer_id || '',
          );
          window.open(session.url, '_blank');
        } else {
          notification.error({ message: 'No Billing Session for Company' });
        }
      } catch (e: any) {
        console.error(e);
      }
    })();
  };

  const openProviderBilling = () => {
    (async () => {
      try {
        if (!!provider?.billing_customer_id?.length) {
          const session = await api.createBillingSession(
            provider?.billing_customer_id || '',
          );
          window.open(session.url, '_blank');
        } else {
          notification.error({ message: 'No Billing Session for Provider' });
        }
      } catch (e: any) {
        console.error(e);
      }
    })();
  };

  if (isLoading) return <Loading />;

  if (!provider?.provider_id && !company?.company_billing_customer_id)
    return <Empty description={t('singleCompanyPage|noRelatedLinks')} />;
  return (
    <Div flex="column" gap={8}>
      {!!company?.company_billing_customer_id && (
        <RelatedLink
          label={t('singleCompanyPage|manageCompanyBilling')}
          onClick={openCompanyBilling}
        />
      )}
      {!!provider?.provider_id && (
        <>
          {provider.billing_customer_id && (
            <RelatedLink
              label="Manage Provider Billing"
              onClick={openProviderBilling}
            />
          )}

          <RelatedLink
            label="Provider Profile"
            to={`/providers/${provider.provider_id}/profile`}
          />

          <RelatedLink
            label="Provider Expertise"
            to={`/providers/${provider.provider_id}`}
          />

          <RelatedLink
            label="Provider Reviews"
            to={`/providers/${provider.provider_id}/reviews`}
          />
        </>
      )}
    </Div>
  );
};

export default CompanyRelatedLinks;
