import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Modal, {
  ModalProps,
} from '@hellodarwin/core/lib/components/common/hd-modal';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Forms } from '@hellodarwin/core/lib/features/entities/form-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import DatePicker from 'antd/es/date-picker';
import message from 'antd/es/message';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app';
import { updateForm } from '../../features/api/slices/forms-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

interface FormSelectPublishedDateModalProps
  extends Omit<ModalProps, 'children'> {
  activeForm: Forms;
}

const FormSelectPublishedDateModal = ({
  activeForm,
  ...modalProps
}: FormSelectPublishedDateModalProps) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const [publishedAt, setPublishedAt] = useState<Dayjs | undefined>(
    activeForm.published_at ? dayjs(activeForm.published_at) : undefined,
  );
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setPublishedAt(
      activeForm.published_at ? dayjs(activeForm.published_at) : undefined,
    );
  }, [activeForm]);

  const handleSubmit = async () => {
    const editedForm: Forms = {
      ...activeForm,
      published_at: publishedAt?.toDate(),
    };

    try {
      await dispatch(
        updateForm({
          api,
          form: editedForm,
        }),
      ).unwrap();

      message.success('New published date saved');
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    } finally {
      modalProps.handleCancel();
    }
  };

  return (
    <Modal {...modalProps}>
      <Typography elementTheme="h6" style={{ marginBottom: 12 }}>
        Set Publication Date
      </Typography>

      <DatePicker
        size="large"
        style={{
          width: '100%',
          borderColor: theme.colors.primary,
          borderRadius: 2,
        }}
        picker="date"
        allowClear
        value={publishedAt}
        onChange={(date: Dayjs) => setPublishedAt(date)}
      />

      <Div
        flex="row"
        gap={8}
        align="center"
        justify="flex-end"
        style={{ marginTop: 24 }}
      >
        <Button
          size="small"
          defaultStyle={theme.colors.white_1}
          onClick={modalProps.handleCancel}
        >
          {t('button|cancel')}
        </Button>
        <Button
          size="small"
          defaultStyle={theme.colors.primary}
          onClick={handleSubmit}
        >
          {t('button|submit')}
        </Button>
      </Div>
    </Modal>
  );
};

export default FormSelectPublishedDateModal;
