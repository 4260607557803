import React from 'react';
import { ReviewResult } from '../../../features/entities/reviews-entities';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Div from '../../common/div';
import SeeMoreList from '../../common/see-more/see-more-list';
import Typography from '../../common/typography';
import ReviewRatings from '../ratings';
import ReviewCard from './review-card';

type ReviewListProps = {
  reviews: ReviewResult[];
  providerName?: string;
  average?: number;
  withInfos?: boolean;
  locale?: string;
};

const ReviewList: React.FC<ReviewListProps> = ({
  reviews,
  providerName,
  average,
  withInfos = false,
  locale,
}: ReviewListProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const renderReviewCards = () => {
    return React.Children.toArray(
      reviews.map((review, index) => (
        <ReviewCard
          key={review.review_id}
          review={review}
          index={index}
          locale={locale}
        />
      )),
    );
  };

  const renderReviewInfo = () => {
    return (
      <Div style={{ paddingBottom: '2rem' }}>
        <Div flex="row" justify="space-between" tablet={{ flex: 'column' }}>
          <Typography elementTheme="subtitle1" bold>
            {t('reviews|providerReviewsListCount', {
              count: reviews.length,
              providerName: providerName ?? '',
            })}
          </Typography>
          <Div align="flex-end" flex="column" fitContent gap={8}>
            <ReviewRatings
              defaultValue={average || 0}
              size="large"
              showValue
              biggerText
              showOne
              showTotal
              textPlacement="end"
              allowHalf
            />
          </Div>
        </Div>
      </Div>
    );
  };

  return (
    <>
      {withInfos && renderReviewInfo()}
      <Div flex="column" gap={16}>
        <SeeMoreList
          limit={3}
          grid={{ sm: 1, md: 1, lg: 1, column: 1, gutter: 24 }}
          content={renderReviewCards()}
        />
      </Div>
    </>
  );
};

export default ReviewList;
