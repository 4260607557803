import Divider from 'antd/es/divider';
import { SelectProps } from 'antd/es/select';
import { useMemo } from 'react';
import { Company } from '../../../../../features/entities/core-entities';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import FilterCompanyInfos from './filter-company-infos';
import FilterCompanySectors from './filter-company-sectors';

export interface FilterCompanyCardContentProps {
  company: Company;
  industries: SelectProps['options'];
  subIndustries: SelectProps['options'];
  annualRevenue: SelectProps['options'];
  companySize: SelectProps['options'];
  provinces: SelectProps['options'];
}

const FilterCompanyCardContent = ({
  company,
  industries = [],
  subIndustries = [],
  annualRevenue = [],
  companySize = [],
  provinces = [],
}: FilterCompanyCardContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const mappedProvinces: { [value: string]: string } = useMemo(
    () =>
      provinces.reduce(
        (o, province) => ({ ...o, [province.value ?? '']: province.label }),
        {},
      ),
    [provinces],
  );
  const mappedCompanySizes: { [value: string]: string } = useMemo(
    () =>
      companySize.reduce(
        (o, compSize) => ({ ...o, [compSize.value ?? '']: compSize.label }),
        {},
      ),
    [companySize],
  );
  const mappedAnnualRevenues: { [value: string]: string } = useMemo(
    () =>
      annualRevenue.reduce(
        (o, annRevenue) => ({
          ...o,
          [annRevenue.value ?? '']: annRevenue.label,
        }),
        {},
      ),
    [annualRevenue],
  );

  return (
    <Div
      flex="row"
      justify="space-between"
      tablet={{ flex: 'column', align: 'flex-start' }}
      align="stretch"
    >
      <Div flex="column" style={{ flex: 1, padding: '8px 16px' }} gap={12}>
        <FilterCompanyInfos label={'companySize'}>
          <Typography elementTheme="body3">
            {company?.size
              ? mappedCompanySizes[company?.size]
              : t(`grants|grantFilterCard.noCompanySize`)}
          </Typography>
        </FilterCompanyInfos>
        <FilterCompanyInfos label={'region'}>
          <Typography elementTheme="body3">
            {company?.province
              ? mappedProvinces[company?.province]
              : t(`grants|grantFilterCard.noRegion`)}
          </Typography>
        </FilterCompanyInfos>
        <FilterCompanyInfos label={'annualRevenue'}>
          <Typography elementTheme="body3">
            {company?.annual_revenue
              ? mappedAnnualRevenues[company?.annual_revenue]
              : t(`grants|grantFilterCard.noAnnualRevenue`)}
          </Typography>
        </FilterCompanyInfos>
      </Div>
      <Divider
        type="vertical"
        style={{
          height: 'auto',
          borderColor: theme.colors.grey_4,
          margin: 0,
          top: 0,
        }}
      />
      <Div flex="column" style={{ flex: 1, padding: '8px 16px' }} gap={12}>
        <FilterCompanySectors
          companyInfo={company.industry_sector ?? []}
          industries={industries}
          theme={theme}
          typeSector=""
        />
        <FilterCompanySectors
          companyInfo={company.industry_subsector ?? []}
          industries={subIndustries}
          theme={theme}
          typeSector="Subsector"
        />
      </Div>
    </Div>
  );
};
export default FilterCompanyCardContent;
