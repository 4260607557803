import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Image from 'antd/es/image';
import { RcFile } from 'antd/es/upload';
import { MouseEvent } from 'react';
import {
  PortfolioAsset,
  PortfolioAssetsType,
} from '../../../../../../../features/entities';
import { useTranslation } from '../../../../../../../plugins/i18n';
import { useTheme } from '../../../../../../../plugins/styled';
import { DefaultTheme } from '../../../../../../../theme';
import VideoPlayer from '../../../../../../common/VideoPlayer';
import Button from '../../../../../../common/button';
import Div from '../../../../../../common/div';
import { ModalConfirm } from '../../../../../../common/hd-modal/confirm';
import Loading from '../../../../../../loading';
import { AssetOverlay } from './styles';
type PortfolioAssetCardProps = {
  asset: PortfolioAsset;
  deleteAsset: (asset: PortfolioAsset) => void;
  loading?: boolean;
};
const PortfolioAssetCard = ({
  asset,
  deleteAsset,
  loading,
}: PortfolioAssetCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;

  const getUrl = (): string => {
    if (!!asset.file) {
      if (typeof asset.file === 'string') {
        return asset.file;
      } else if (asset?.file instanceof Blob) {
        return URL.createObjectURL(asset.file);
      } else {
        const file: RcFile = asset.file;
        return URL.createObjectURL(file as Blob);
      }
    } else {
      return asset.asset_url || '';
    }
  };
  return (
    <Div
      backgroundColor={theme.colors.grey_2}
      borderRadius={6}
      borderColor={theme.colors.grey_4}
      style={{
        width: 100,
        height: 100,
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {asset.asset_type === PortfolioAssetsType.Video ? (
            typeof window !== 'undefined' && (
              <VideoPlayer
                url={getUrl()}
                width="100%"
                height={'100%'}
                controls
                light={true}
              />
            )
          ) : (
            <Image
              src={getUrl()}
              preview={false}
              width={'100%'}
              height={'100%'}
              style={{
                objectPosition: 'center',
                objectFit: 'contain',
              }}
            />
          )}
          <AssetOverlay>
            <Button
              transparent
              size="auto"
              headingIcon={<Delete width={20} height={20} />}
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.preventDefault();
                ModalConfirm({
                  title: t('profile|assetsModal.confirm'),
                  content: t('profile|assetsModal.delete.asset'),
                  okText: t('profile|assetsModal.confirm'),
                  cancelText: t('profile|assetsModal.cancel'),

                  okButtonProps: {
                    style: {
                      backgroundColor: theme.colors.purple_1,
                      color: theme.colors.white_1,
                      borderRadius: '24px',
                    },
                  },
                  cancelButtonProps: {
                    style: {
                      backgroundColor: theme.colors.white_1,
                      borderColor: theme.colors.grey_1,
                      color: theme.colors.grey_1,
                      borderRadius: '24px',
                    },
                  },
                  onOk: async () => {
                    deleteAsset(asset);
                  },
                });
              }}
            />
          </AssetOverlay>
        </>
      )}
    </Div>
  );
};
export default PortfolioAssetCard;
