import { ColumnsType } from 'antd/es/table';
import { useMemo, useReducer, useState } from 'react';
import {
  AdminProjectResponse,
  InitialProjectState,
  Project,
  ProjectGrant,
  ProjectProgram,
} from '../../../features/entities/projects-entities';
import useCompanyProjectsColumns from './use-company-projects-columns';

export type ProjectsListState = 'editable' | 'noEdit';

export type ProjectModalType =
  | 'add-project'
  | 'edit-project'
  | 'add-new-project-item'
  | 'add-new-grant-item';

interface State {
  editState: ProjectsListState;
  activeProject: Project;
  activeParentProject?: Project;
  activeGrant?: ProjectGrant;
  activeModal?: ProjectModalType;
}

type Action =
  | { type: 'SET_STATE'; payload: ProjectsListState }
  | { type: 'SET_PROJECT'; payload: Project }
  | { type: 'SET_PARENT_PROJECT'; payload: Project }
  | { type: 'EDIT_PROJECT'; payload: Project }
  | { type: 'ADD_NEW_PROJECT'; payload?: Project }
  | { type: 'ADD_PROJECT_SUBITEM'; payload: Project }
  | { type: 'ADD_PROJECT_GRANT_SUBITEM'; payload: ProjectGrant }
  | {
      type: 'SET_GRANT';
      payload: {
        activeProject: Project;
        activeGrant: ProjectGrant;
      };
    }
  | { type: 'CLOSE_MODAL' };

const InitialState: Omit<State, 'editState'> = {
  activeProject: InitialProjectState,
  activeModal: undefined,
  activeGrant: undefined,
  activeParentProject: undefined,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, editState: action.payload };
    case 'EDIT_PROJECT':
      return {
        ...state,
        activeModal: 'edit-project',
        activeProject: action.payload,
        activeParentProject: undefined,
      };
    case 'ADD_NEW_PROJECT':
      return {
        ...state,
        activeModal: 'add-project',
        activeProject: InitialProjectState,
        activeParentProject: action.payload,
        activeGrant: undefined,
      };
    case 'ADD_PROJECT_SUBITEM':
      return {
        ...state,
        activeModal: 'add-new-project-item',
        activeProject: action.payload,
        activeParentProject: undefined,
        activeGrant: undefined,
      };
    case 'ADD_PROJECT_GRANT_SUBITEM':
      return {
        ...state,
        activeModal: 'add-new-project-item',
        activeProject: undefined,
        activeGrant: action.payload,
        activeParentProject: undefined,
      };
    case 'CLOSE_MODAL':
      return { ...state, ...InitialState };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

interface UseProjectsListStateProps {
  defaultState?: ProjectsListState;
  projects: AdminProjectResponse[];
  defaultClosed?: boolean;
  projectsOnly?: boolean;
  applicationsPath: string;
  grantsPath: string;
}

export interface ProjectsListStateValues extends State {
  handleEditProject: (project: Project) => void;
  handleAddProject: (project?: Project) => void;
  handleAddProjectSubitem: (project: Project) => void;
  handleAddGrantSubitem: (grant: ProjectGrant) => void;
  projectsColumns: {
    projectColumns: ColumnsType<Project>;
    expandGrantColumns: ColumnsType<ProjectGrant>;
    expandProgramColumns: ColumnsType<ProjectProgram>;
  };
  handleCloseModal: () => void;
  projects: AdminProjectResponse[];
  expandedRowKeys: string[];
  onExpand: (record_id: string) => void;
  projectsOnly?: boolean;
}

const useProjectsListState = ({
  defaultState = 'editable',
  projects,
  defaultClosed,
  projectsOnly,
  applicationsPath,
  grantsPath,
}: UseProjectsListStateProps): ProjectsListStateValues => {
  const [state, dispatch] = useReducer(reducer, {
    ...InitialState,
    editState: defaultState,
  });

  const [selectedRowKeys, setClosedRowKeys] = useState<string[]>([]);
  const expandedRowKeys = useMemo(
    () =>
      !!defaultClosed
        ? selectedRowKeys
        : projects.flatMap((p1) =>
            p1.projects
              ?.filter((p) => !selectedRowKeys.includes(p.project_id))
              .flatMap((p) => [
                p.project_id,
                ...(p.grants ?? [])
                  .filter((g) => !selectedRowKeys.includes(g.grant_id))
                  .map((g) => g.grant_id),
              ]),
          ),
    [selectedRowKeys, projects, defaultClosed],
  );

  const onExpand = (record_id: string) => {
    const isSelected = selectedRowKeys.includes(record_id);

    if (isSelected) {
      setClosedRowKeys((prevKeys) =>
        prevKeys.filter((key) => record_id !== key),
      );
    } else {
      setClosedRowKeys((prevKeys) => [...prevKeys, record_id]);
    }
  };

  const handleEditProject = (project: Project) =>
    dispatch({ type: 'EDIT_PROJECT', payload: project });

  const handleAddProject = (project?: Project) =>
    dispatch({ type: 'ADD_NEW_PROJECT', payload: project });

  const handleAddProjectSubitem = (project: Project) => {
    dispatch({ type: 'ADD_PROJECT_SUBITEM', payload: project });
  };

  const handleAddGrantSubitem = (grant: ProjectGrant) =>
    dispatch({ type: 'ADD_PROJECT_GRANT_SUBITEM', payload: grant });

  const handleCloseModal = () => dispatch({ type: 'CLOSE_MODAL' });

  const projectsColumns = useCompanyProjectsColumns({
    handleEditProject,
    handleAddProjectSubitem,
    handleAddGrantSubitem,
    expandedRowKeys,
    onExpand,
    projectsOnly,
    applicationsPath,
    grantsPath,
  });

  return useMemo(
    () => ({
      ...state,
      handleEditProject,
      handleAddProject,
      handleAddProjectSubitem,
      handleAddGrantSubitem,
      handleCloseModal,
      projectsColumns,
      projects,
      onExpand,
      expandedRowKeys,
      projectsOnly,
    }),
    [state, projects, projectsColumns, expandedRowKeys, projectsOnly],
  );
};

export default useProjectsListState;
