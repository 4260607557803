import Typography from '@hellodarwin/core/lib/components/common/typography';
import { WinFeeParams } from '@hellodarwin/core/lib/features/entities';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import message from 'antd/es/message';
import notification from 'antd/es/notification';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import {
  fetchRfpMatches,
  selectAllMatchesById,
} from '../../features/api/slices/rfp-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { missingDefaultPaymentError } from '../messages/missing-payment-method';

const DEFAULT_PAYMENT_METHOD_ERROR = 11_007;

const { Title } = Typography;

type ChargeWinFeeFormProps = {
  rfpId: string;
};

interface ChargeWinFeeModalForm {
  price: number;
  provider_id: string;
}

const ChargeWinFeeForm = ({ rfpId }: ChargeWinFeeFormProps) => {
  const [form] = Form.useForm();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [providerId, setProviderId] = useState<string>('');

  useEffect(() => {
    dispatch(fetchRfpMatches({ api, rfpId: rfpId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const match = useAppSelector((state) =>
    selectAllMatchesById(state, providerId),
  );

  const onFinish = (values: ChargeWinFeeModalForm) => {
    (async () => {
      if (!match) return;

      setIsSaving(true);
      try {
        await api.chargeWinFee({
          matchId: match.match_id,
          rfpId: rfpId,
          providerId: values.provider_id,
          price: values.price,
        } as WinFeeParams);
        await dispatch(fetchRfpMatches({ api, rfpId: rfpId }));
        setIsSaving(false);
        form.resetFields();
        message.success('Saved!');
      } catch (e: any) {
        setIsSaving(false);

        if (e.response.data.error_code === DEFAULT_PAYMENT_METHOD_ERROR) {
          return missingDefaultPaymentError();
        }

        notification.error({
          message: 'Payment failed!',
          description: `${e.response.data.error}, error_code: ${e.response.data.error_code}`,
          placement: 'bottomLeft',
        });
      }
    })();
  };

  const OnProviderInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProviderId(event.currentTarget.value);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      price: match?.rfp.lead_price || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      name={'manual-charge'}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4} elementTheme="h6">
            Manual Win Fee Charge
          </Title>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Provider id"
            name="provider_id"
            rules={[{ required: true, message: 'provider ID is required' }]}
          >
            <Input onChange={OnProviderInputChange} />
          </Form.Item>
        </Col>

        {!match && providerId && (
          <Typography>Can't find provider in matches</Typography>
        )}
        {match &&
          (!match.purchased_at ? (
            <Typography>
              Shortlist this match before you can charge win fee
            </Typography>
          ) : (
            <>
              <Col span={24}>
                <Form.Item
                  label="Price"
                  name="price"
                  rules={[
                    { required: true, message: 'Win fee is required' },
                    { type: 'number' },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ margin: 0 }} shouldUpdate={true}>
                  {() => (
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={isSaving}
                    >
                      Charge win fee
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </>
          ))}
      </Row>
    </Form>
  );
};

export default ChargeWinFeeForm;
