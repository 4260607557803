import styled from '../../../plugins/styled';

export const ProfileDropdown = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-shadow: 2px 2px 11px 0px rgba(43, 43, 43, 0.05);
  &:after,
  &:before {
    position: absolute;
    left: 10px;
    top: -8px;
    width: 0;
    height: 0;
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.white_1};
  }

  &:before {
    top: -10px;
    left: 6px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid ${({ theme }) => theme.colors.primary};
  }
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 12px 16px;
      border-radius: 0;
      font-size: 15px;
      &:hover:not(.ant-dropdown-menu-item-disabled) {
        background: ${({ theme }) => theme.colors.grey_5};
      }
    }
  }
`;

export const UserFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const UserFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 56px;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    & > div {
      flex: auto !important;
      width: 100%;
    }
  }
`;
