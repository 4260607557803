import { useMemo } from 'react';
import { AssetRecordInformation } from '../../../../features/entities';
import { Breadcrumb } from '../../../../features/entities/context-entities';
import { getShortId } from '../../../../features/helpers';
import BreadcrumbsContent from '../../../layout/header/breadcrumbs/breadcrumbs-content';

export interface FilesListBreadcrumbProps {
  records: AssetRecordInformation[];
  vaultPath: string;
}

const FilesListBreadcrumb: React.FC<FilesListBreadcrumbProps> = ({
  vaultPath,
  records,
}) => {
  const breadcrumbs = useMemo(() => {
    if (!records.length) return [];

    const breadcrumbs: Breadcrumb[] = [];
    let path = vaultPath;
    for (let index = 0; index < records.length; index++) {
      const record = records[index];
      if (record.record_id) {
        if (index > 0) path += `/${record.record_id}`;
        breadcrumbs.push({
          breadcrumbName: record.record_name ?? getShortId(record.record_id),
          path,
        });
      }
    }

    return breadcrumbs;
  }, [records]);

  return <BreadcrumbsContent breadcrumbs={breadcrumbs} />;
};

export default FilesListBreadcrumb;
