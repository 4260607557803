import { useTheme } from 'styled-components';
import Div from '../../../common/div';

const BreadcrumbDivider = () => {
  const theme = useTheme();

  return (
    <Div flex="row" align="center" fullHeight>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5"
        height="8"
        viewBox="0 0 5 8"
        fill="none"
      >
        <path
          d="M1 1L4 4L1 7"
          stroke={theme.colors.grey_2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Div>
  );
};

export default BreadcrumbDivider;
