import LanguageIcon from '@hellodarwin/icons/dist/icons/Language';
import { AppType } from '../../../features/entities';
import { Language } from '../../../features/entities/translations_ns';
import { useAppData } from '../../../features/providers/app-data-provider';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Typography from '../../common/typography';
import { LanguageSwitcherStyled } from './styles';

export const SUPPORTED_LANGUAGES: Language[] = ['fr', 'en'];

interface LanguageSwitcherProps {
  app: AppType;
  handleLanguageChange?: (newLang: Language) => void;
}
const LanguageSwitcher = ({
  app,
  handleLanguageChange,
}: LanguageSwitcherProps) => {
  const { t } = useTranslations();
  const { selectedLocale, changeLanguage } = useLocale();
  const { navCollapsed } = useAppData();
  const otherLang = SUPPORTED_LANGUAGES.find((lang) => selectedLocale !== lang);

  const clickHandler = () => {
    if (otherLang) {
      if (handleLanguageChange) {
        handleLanguageChange(otherLang);
      } else {
        changeLanguage(otherLang);
      }
    }
  };

  return (
    <LanguageSwitcherStyled $app={app} onClick={clickHandler}>
      <LanguageIcon size={24} />
      <Typography
        elementTheme="body3"
        style={{ fontSize: navCollapsed ? 11 : 14 }}
        textAlign="center"
      >
        {selectedLocale === 'en' ? 'Switch to ' : 'Voir en '}
        {t(`language|${otherLang}`)}
      </Typography>
    </LanguageSwitcherStyled>
  );
};

export default LanguageSwitcher;
