import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Resources = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.74707C13.3626 4.45876 9.94304 3.19529 6.40001 3.20003C4.71681 3.20003 3.10081 3.47859 1.60001 3.99238V26.0453C3.1418 25.5192 4.76507 25.2512 6.40001 25.253C10.088 25.253 13.4528 26.5947 16 28.8M16 6.74707C18.6373 4.45863 22.0569 3.19515 25.6 3.20003C27.2832 3.20003 28.8992 3.47859 30.4 3.99238V26.0453C28.8582 25.5192 27.2349 25.2512 25.6 25.253C22.057 25.2482 18.6374 26.5117 16 28.8M16 6.74707V28.8"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Resources;
