import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { AdminGrantResult } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Flex from 'antd/es/flex';
import message from 'antd/es/message';
import Radio from 'antd/es/radio';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import slugify from 'react-slugify';
import { useAppDispatch } from '../../app/app-hooks';
import CreateGrantForm from '../../components/grants/grant-form/create-grant-form';
import { GrantFormValues } from '../../components/grants/grant-form/grant-form';
import {
  createGrant,
  deleteGrant,
} from '../../features/api/slices/grants-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { AdminPagesForms } from '../single-rfp-page';
import SingleGrantForm from './single-grant-form';

const CreateGrantPage = () => {
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [grant, setGrant] = useState<AdminGrantResult>({
    grant_id: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOnGrantInfo, setIsOnGrantInfo] = useState<boolean>(false);
  const [grantIndex, setGrantIndex] = useState<number>(0);
  const [forms, setForms] = useState<AdminPagesForms>({
    grantForm: undefined,
  });

  const [grantIDs, setGrantIDs] = useState<string[]>([]);
  const { t } = useTranslations();
  const renderActionButton = () => {
    if (isOnGrantInfo) {
      return (
        <Button onClick={onSave} size="small">
          {t('programs_admin|createProgram')}
        </Button>
      );
    }
    return <></>;
  };

  const renderSimilarGrantsButtons = () => {
    return (
      <Div
        flex="row"
        justify="center"
        style={{
          marginBottom: 16,
        }}
      >
        <Typography
          style={{
            marginRight: 8,
            color: theme.colors.purple_1,
          }}
          textAlign="center"
        >
          We have found {grantIDs.length - 1} similar grant(s):
        </Typography>
        <Flex vertical gap="middle">
          <Radio.Group
            value={grantIndex}
            onChange={changeGrantIndex}
            buttonStyle="solid"
          >
            {displayRadioButtons()}
          </Radio.Group>
        </Flex>

        <Div
          flex="row"
          style={{
            maxWidth: '30%',
            marginLeft: 32,
          }}
        >
          <Button size="small" onClick={goBack} style={{ marginRight: 15 }}>
            Cancel
          </Button>
          <Button
            size="small"
            style={{ background: theme.colors.purple_1 }}
            onClick={createScrape}
          >
            Still create
          </Button>
        </Div>
      </Div>
    );
  };

  const goBack = async () => {
    await dispatch(deleteGrant({ api, grantID: grantIDs[0] }));
    setGrantIDs([]);
    navigate('/grants');
  };

  const createScrape = async () => {
    try {
      setIsLoading(true);
      await api.createScrape(grantIDs[0]);
      setGrantIDs([]);
      navigate(`/grants/${grantIDs[0]}`);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const displayRadioButtons = () => {
    return grantIDs.map((grantID, index) => {
      return (
        <Radio.Button key={index} value={index}>
          {index === 0 ? 'Created grant' : `Grant ${index}`}
        </Radio.Button>
      );
    });
  };

  const changeGrantIndex = (value: any) => {
    setGrantIndex(parseInt(value.target.value));
  };

  const getFormattedGrant = (
    grantFormValues: GrantFormValues,
  ): AdminGrantResult => {
    const updatedGrant: AdminGrantResult = {
      ...grant,
      ...grantFormValues,
    };
    updatedGrant.grant_title_en = updatedGrant.grant_title_en?.trim();
    updatedGrant.grant_title_fr = updatedGrant.grant_title_fr?.trim();

    updatedGrant.grant_display_title_en =
      updatedGrant.grant_display_title_en?.trim();
    updatedGrant.grant_display_title_fr =
      updatedGrant.grant_display_title_fr?.trim();

    updatedGrant.grant_slug_en = slugify(updatedGrant.grant_slug_en);
    updatedGrant.grant_slug_fr = slugify(updatedGrant.grant_slug_fr);

    return updatedGrant;
  };

  const onSave = async () => {
    try {
      setIsLoading(true);
      const grantFormValues = forms.grantForm?.getFieldsValue()!;

      if (grantFormValues === undefined) {
        return;
      }
      if (
        !grantFormValues.grant_title_en ||
        !grantFormValues.grant_slug_en ||
        !grantFormValues.grant_display_title_en ||
        grantFormValues.grant_urls_en[0] === ''
      ) {
        message.error('Please fill in all required fields in English');
        setIsLoading(false);
        return;
      }

      if (
        !grantFormValues.grant_title_fr ||
        !grantFormValues.grant_slug_fr ||
        !grantFormValues.grant_display_title_fr ||
        grantFormValues.grant_urls_fr[0] === ''
      ) {
        message.error('Please fill in all required fields in French');
        setIsLoading(false);
        return;
      }

      const updatedGrant: AdminGrantResult = getFormattedGrant(grantFormValues);

      if (
        updatedGrant.grant_description_short_en === undefined ||
        updatedGrant.grant_description_short_fr === undefined ||
        updatedGrant.grant_description_long_en === undefined ||
        updatedGrant.grant_description_long_fr === undefined
      ) {
        updatedGrant.verified = 'Unverified';
      }

      const createGrantResponse = await dispatch(
        createGrant({ api, grant: updatedGrant }),
      ).unwrap();
      await api.createScrape(createGrantResponse.grant.grant_id);
      setIsLoading(false);
      message.success('Grant created with success !');
      setGrant({ grant_id: '' });
      forms.grantForm?.resetFields();
      navigate('/grants/' + createGrantResponse.grant.grant_id);
    } catch (e: any) {
      if (e?.error_code === 28025) {
        message.error('There is already a grant with the same title');
      } else if (e?.error_code === 28026) {
        message.error('There is already a grant with the same slug');
      } else {
        message.error('Something went wrong, try again later!');
      }
      setIsLoading(false);
      console.error(e);
    }
  };

  const goToGrants = () => {
    navigate('/grants');
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={t('programs_admin|createProgram')}
      title={
        grantIDs.length > 0 ? undefined : t('programs_admin|createProgram')
      }
      handleBack={grantIDs.length > 0 || isLoading ? undefined : goToGrants}
      actions={
        isLoading || grantIDs.length > 0 ? undefined : renderActionButton()
      }
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('programs_admin|programsTitle'),
          path: '/grants',
        },
        {
          breadcrumbName: t('programs_admin|createProgram'),
        },
      ]}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {grantIDs.length > 0 ? (
            <Div>
              {renderSimilarGrantsButtons()}
              <SingleGrantForm grantID={grantIDs[grantIndex]}></SingleGrantForm>
            </Div>
          ) : (
            <CreateGrantForm
              grant={grant}
              setForms={setForms}
              setIsOnGrantInfo={setIsOnGrantInfo}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default CreateGrantPage;
