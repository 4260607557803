import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import { Contact } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Col from 'antd/es/col';
import Modal from 'antd/es/modal/Modal';
import Select from 'antd/es/select';
import { useState } from 'react';

import { useAppSelector } from '../../app';
import { selectCompanyContacts } from '../../features/api/slices/users-slice';
import theme from '../../theme';

type AssignCompanyContactModalProps = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (contact_id: string, contact_name: string) => void;
  companyId: string;
};

const AssignCompanyContactModal = ({
  open,
  handleClose,
  handleSubmit,
  companyId,
}: AssignCompanyContactModalProps) => {
  const { t } = useTranslation();
  const companyContacts = useAppSelector((state) =>
    selectCompanyContacts(state, companyId),
  );
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const handleChange = (value: string) => {
    const contact = companyContacts.find(
      (contact) => contact.contact_id === value,
    );
    if (contact) {
      setSelectedContact(contact);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      className="assign-contact-modal"
    >
      <Div style={{ padding: '1rem 2rem' }}>
        <Div
          borderColor={theme.colors.white_2}
          bordersSide="bottom"
          style={{ paddingBottom: '1rem' }}
        >
          {t('rfp|contacts.assign')}
        </Div>

        <Div flex="row" gap={20}>
          <Col span={16}>
            <Select
              filterOption={false}
              style={{ width: '100%' }}
              placeholder="Select a contact"
              defaultActiveFirstOption={true}
              onChange={handleChange}
            >
              {companyContacts.map((contact) => (
                <Select.Option
                  key={contact.contact_id}
                  value={contact.contact_id}
                  style={{ width: 800 }}
                >
                  {contact.name}/ {contact.email}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={2}>
            <Button
              onClick={() =>
                handleSubmit(
                  selectedContact?.contact_id || '',
                  selectedContact?.name || '',
                )
              }
            >
              Assign
            </Button>
          </Col>
        </Div>
      </Div>
    </Modal>
  );
};
export default AssignCompanyContactModal;
