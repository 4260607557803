interface GetTranslationsFromStringProps {
  translation_en?: string;
  translation_fr?: string;
  fallback?: string;
  selectedLocale: string;
}
const getTranslationsFromString = ({
  translation_en,
  translation_fr,
  selectedLocale,
  fallback,
}: GetTranslationsFromStringProps): string => {
  if (selectedLocale === 'en' && !!translation_en?.length)
    return translation_en;
  else if (!!translation_fr?.length) return translation_fr;
  else if (!!fallback) return fallback;
  else return '';
};

export default getTranslationsFromString;
