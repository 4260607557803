import {
  FormGroupResponse,
  FormPageResponse,
  MappedFormBuilderPage,
} from '../../../../features/entities/form-entities';
import { FormBuilderFunctions } from '../types';

export const findInfoByGroupId = ({
  form_group_id,
  pages,
}: {
  form_group_id: string;
  pages: MappedFormBuilderPage;
}): [FormGroupResponse | null, FormPageResponse | null] => {
  const page = Object.values(pages).find((page) =>
    Object.keys(page.groups).includes(form_group_id),
  );
  const group = page?.groups[form_group_id];

  return [group?.entity ?? null, page?.entity ?? null]; // Return null
};

export const handleReorderFormGroups = ({
  form_page_id,
  form_group_id,
  functions,
  formPages,
  group_order,
}: {
  group_order: number;
  form_group_id: string;
  form_page_id: string;
  formPages: MappedFormBuilderPage;
  functions: FormBuilderFunctions;
}) => {
  if (formPages[form_page_id]) {
    var formPageResponse: FormPageResponse = {
      ...formPages[form_page_id].entity,
      groups: [],
    };

    const groups = Object.values(formPages[form_page_id].groups).sort(
      (g1, g2) => g1.entity.group_order - g2.entity.group_order,
    );
    const movingGroupID = groups.findIndex(
      (group) => group.id === form_group_id,
    );
    const [removedGroup] = groups.splice(movingGroupID, 1);
    groups.splice(group_order, 0, removedGroup);

    groups.forEach((groupBuilder, index) => {
      formPageResponse.groups.push({
        form_page_id: groupBuilder.entity.form_page_id,
        form_id: groupBuilder.entity.form_id,
        form_group_id: groupBuilder.entity.form_group_id,
        group_title_en: groupBuilder.entity.group_title_en,
        group_title_fr: groupBuilder.entity.group_title_fr,
        items: groupBuilder?.entity?.items,
        group_order: index,
      });
    });

    functions.updateFormGroups(formPageResponse);
  }
};

export const handleDeleteFormGroup = async ({
  form_id,
  form_page_id,
  form_group_id,
  formPages,
  functions,
}: {
  form_id: string;
  form_page_id: string;
  form_group_id: string;
  formPages: MappedFormBuilderPage;
  functions: FormBuilderFunctions;
}) => {
  if (formPages[form_page_id]) {
    var formPageResponse: FormPageResponse = {
      ...formPages[form_page_id].entity,
      groups: [],
    };

    Object.values(formPages[form_page_id].groups).forEach(
      (groupBuilder, index) => {
        formPageResponse.groups.push({
          form_id,
          form_page_id,
          form_group_id: groupBuilder.entity.form_group_id,
          items: groupBuilder?.entity?.items,
          group_order: index,
        });
      },
    );

    formPageResponse.groups.push({
      form_group_id: form_group_id,
      items: [],
      group_order: 0,
      deleted: true,
      form_page_id,
      form_id,
    });

    functions.deleteFormGroup(form_group_id, form_page_id);
  }
};

export const handleNewGroup_DropInPage_FromControls = async ({
  form_id,
  form_page_id,
  group_order,
  functions,
}: {
  form_id: string;
  form_page_id: string;
  group_order: number;
  functions: FormBuilderFunctions;
}) => {
  const newGroup: FormGroupResponse = {
    form_group_id: '',
    form_id: form_id,
    form_page_id,
    group_order: group_order,
    items: [],
  };

  await functions.createGroup(newGroup);
};
