import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Budget = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 24.8636C9.66138 24.8572 17.2895 25.8917 24.6829 27.9397C25.7268 28.2296 26.7692 27.4389 26.7692 26.335V24.8636M4.15385 4V5.09808C4.15385 5.38931 4.04039 5.66861 3.83842 5.87454C3.63646 6.08048 3.36254 6.19617 3.07692 6.19617H2M2 6.19617V5.64712C2 4.73791 2.72369 4 3.61538 4H27.8462M2 6.19617V19.3732M27.8462 4V5.09808C27.8462 5.70422 28.3286 6.19617 28.9231 6.19617H30M27.8462 4H28.3846C29.2763 4 30 4.73791 30 5.64712V19.9222C30 20.8314 29.2763 21.5693 28.3846 21.5693H27.8462M2 19.3732V19.9222C2 20.359 2.17019 20.778 2.47314 21.0869C2.77608 21.3958 3.18696 21.5693 3.61538 21.5693H4.15385M2 19.3732H3.07692C3.36254 19.3732 3.63646 19.4889 3.83842 19.6948C4.04039 19.9007 4.15385 20.18 4.15385 20.4712V21.5693M27.8462 21.5693V20.4712C27.8462 20.18 27.9596 19.9007 28.1616 19.6948C28.3635 19.4889 28.6375 19.3732 28.9231 19.3732H30M27.8462 21.5693H4.15385M20.3077 12.7847C20.3077 13.9496 19.8538 15.0668 19.046 15.8905C18.2382 16.7142 17.1425 17.177 16 17.177C14.8575 17.177 13.7619 16.7142 12.954 15.8905C12.1462 15.0668 11.6923 13.9496 11.6923 12.7847C11.6923 11.6197 12.1462 10.5025 12.954 9.67881C13.7619 8.85509 14.8575 8.39233 16 8.39233C17.1425 8.39233 18.2382 8.85509 19.046 9.67881C19.8538 10.5025 20.3077 11.6197 20.3077 12.7847ZM24.6154 12.7847H24.6269V12.7964H24.6154V12.7847ZM7.38462 12.7847H7.3961V12.7964H7.38462V12.7847Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Budget;
