import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import { ReactNode } from 'react';
import Modal, { ModalProps } from '..';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../div';

type ModalLayoutWithHdLogoProps = {
  children: ReactNode;
} & ModalProps;
const ModalLayoutWithHdLogo = ({
  children,
  ...modalProps
}: ModalLayoutWithHdLogoProps) => {
  const theme = useTheme();

  const logoContainerSize = 90;
  const logoSize = logoContainerSize / 2;
  return (
    <Modal
      {...modalProps}
      style={{
        top: 0,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      styles={{
        ...modalProps?.styles,
        body: {
          padding: 24,
          overflow: 'auto',
          height: '100%',
        },

        content: {
          padding: `${logoContainerSize / 2}px 0 0 0`,
          overflow: 'visible',
          height: '100%',
          maxHeight: `calc(95vh - ${logoContainerSize}px)`,
          ...modalProps?.styles?.content,
        },
      }}
    >
      <Div
        className={'hd-modal-with-hd-logo'}
        flex={'column'}
        align={'center'}
        overflow="hidden"
      >
        <Div
          flex={'row'}
          align={'center'}
          justify={'center'}
          backgroundColor={theme.colors.primary}
          style={{
            width: logoContainerSize,
            height: logoContainerSize,
            borderRadius: 99,
            top: 0,
            transform: `translateY(-${logoContainerSize / 2}px)`,
            position: 'absolute',
            border: `1px solid ${theme.colors.primary}`,
          }}
        >
          <HdLogo
            width={logoSize}
            height={logoSize}
            backgroundType="dark"
            color="b/w"
            type="tertiary"
          />
        </Div>
        {children}
      </Div>
    </Modal>
  );
};

export default ModalLayoutWithHdLogo;
