import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItemEditList from '@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import FormLabel from '@hellodarwin/core/lib/components/forms/form-layouts/form-label';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  GinEmail,
  InitialGinEmailValue,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Drawer from 'antd/es/drawer';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import { useNewAdminApi } from '../../../features/api/use-admin-api';
import { RelatedLink } from './cards/related-link';
import {
  selectGinsIsLoading,
  createGinEmail,
  updateGinEmail,
  deleteGinEmail,
} from '../../../features/api/slices/gins-slice';

interface GinSidebarTemplateProps {
  grant_id: string;
  type: string;
  open: boolean;
  onClose: (bool: boolean) => void;
  email_items: GinEmail[];
}
const GinSidebarEmail = ({
  grant_id,
  type,
  open,
  onClose,
  email_items,
}: GinSidebarTemplateProps) => {
  const { t } = useTranslations();
  const dispatch = useAppDispatch();
  const api = useNewAdminApi();
  const [active, setActive] = useState<GinEmail | undefined>(undefined);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'email'),
  );

  const handleSubmit = async (email: GinEmail) => {
    email.type = type;
    try {
      let response: GinEmail;
      if (email.id === '') {
        response = await dispatch(
          createGinEmail({ api, email, grant_id }),
        ).unwrap();
        message.success(t(`gin_program|${type}_added`));
      } else {
        response = await dispatch(updateGinEmail({ api, email })).unwrap();
        message.success(t(`gin_program|${type}_updated`));
      }
      setActive(undefined);
      return response;
    } catch (err: any) {
      message.error(t('gin_program|template_adding_error'));
      console.error(err);
    }
    return email;
  };

  const handleDelete = async (email: GinEmail) => {
    try {
      const response = await dispatch(
        deleteGinEmail({
          api,
          gin_email_id: email.id,
        }),
      ).unwrap();
      message.success(t(`gin_program|${type}_deleted`));
      return response;
    } catch (error) {
      message.error(t(`gin_program|${type}_deleted_error`));
      console.error(error);
    }
    return email;
  };

  return (
    <Div flex="column" gap={8}>
      {isLoading ? (
        <Div style={{ height: 'fit-content' }}>
          <Loading />
        </Div>
      ) : (
        email_items &&
        React.Children.toArray(
          email_items.map((itm, index) => {
            const url =
              itm.link.startsWith('http://') || itm.link.startsWith('https://')
                ? itm.link
                : `https://${itm.link}`;
            return (
              <RelatedLink
                key={index}
                label={itm.name ? itm.name : url}
                to={url}
              />
            );
          }),
        )
      )}
      <Drawer
        open={open}
        onClose={() => {
          onClose(!open);
        }}
        title={
          <Typography elementTheme="subtitle2">
            {t(`gin_program|${type}`)}
          </Typography>
        }
      >
        <FormItem
          name={`gin_email`}
          layout="vertical"
          label={
            <FormLabel
              label={t(`gin_program|${type}`)}
              actions={
                <Button
                  transparent
                  defaultStyle={theme.colors.primary}
                  onClick={() => {
                    setActive({ ...InitialGinEmailValue });
                  }}
                >
                  <AddNew size={16} />
                </Button>
              }
            />
          }
        >
          <FormItemEditList<GinEmail>
            typeLabel={t(`gin_program|${type}`)}
            options={email_items ?? []}
            optionLabel="name"
            activeElement={active}
            setActiveElement={setActive}
            update={handleSubmit}
            deleteItem={handleDelete}
            inputs={[
              {
                label: t(`gin_program|${type}_name`),
                name: 'name',
                children: <Input />,
                rules: [
                  {
                    required: true,
                    message: t(`gin_program|${type}_name_required`),
                  },
                ],
              },
              {
                label: t(`gin_program|${type}_link`),
                name: 'link',
                children: <Input />,
                rules: [
                  {
                    required: true,
                    message: t(`gin_program|${type}_link_required`),
                  },
                ],
              },
              {
                label: t('gin_program|content'),
                name: 'content',
                children: (
                  <RichTextEditor initialValue={active?.content || ''} />
                ),
              },
            ]}
          />
        </FormItem>
      </Drawer>
    </Div>
  );
};

export default GinSidebarEmail;
