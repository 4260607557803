import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import { RfpDescriptionTemplate } from '@hellodarwin/core/lib/features/entities';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import message from 'antd/es/message';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useAdminApi } from '../../features/api/use-admin-api';
import { quillModules } from '../text-editor';

type RfpDescriptionTemplateFormProps = {
  rfpDescriptionTemplate?: RfpDescriptionTemplate;
  language: string;
};

export type RfpDescriptionTemplateFormValues = {
  content: string;
  locale: string;
  description_type: string;
};

const RfpDescriptionTemplateForm = ({
  rfpDescriptionTemplate,
  language,
}: RfpDescriptionTemplateFormProps) => {
  const api = useAdminApi();

  const [form] = Form.useForm<RfpDescriptionTemplateFormValues>();

  const save = async () => {
    try {
      const rfpDescriptionTemplateFormValues = form.getFieldsValue()!;

      if (!rfpDescriptionTemplateFormValues) return;

      rfpDescriptionTemplateFormValues.locale = language;

      const updatedRfpDescriptionTemplate: RfpDescriptionTemplate = {
        ...rfpDescriptionTemplate,
        ...rfpDescriptionTemplateFormValues,
      };

      await api.updateRfpDescriptionTemplate(updatedRfpDescriptionTemplate);

      message.success('Template added!');
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
      return;
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        if (isMounted) {
          if (rfpDescriptionTemplate) {
            form.setFieldsValue(rfpDescriptionTemplate);
          }
        }
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [form, rfpDescriptionTemplate]);

  const initialValues: RfpDescriptionTemplateFormValues = {
    content: rfpDescriptionTemplate?.content ?? '',
    locale: rfpDescriptionTemplate?.locale ?? '',
    description_type: rfpDescriptionTemplate?.description_type ?? '',
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      name="rfpDescriptionTemplate"
      size="small"
      style={{ width: '100%' }}
    >
      <Card>
        <Div flex="column" gap={8}>
          <Form.Item
            label="Description"
            name="content"
            tooltip="This information will show in all applications. The description type are as followed : 
            Contact Description, Rfp Description, Context, Goals, Details, Service Provider Requirement, Budget, Contact, Other"
            rules={[
              () => ({
                validator(_, value) {
                  if (value === '' || value === '<p><br></p>') {
                    return Promise.reject(
                      new Error('Please add a description'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <ReactQuill
              className="text-editor"
              theme="snow"
              modules={quillModules}
              preserveWhitespace={true}
            />
          </Form.Item>
        </Div>
        <Div>
          <Button size="small" onClick={save}>
            Save
          </Button>
        </Div>
      </Card>
    </Form>
  );
};

export default RfpDescriptionTemplateForm;
