import message from 'antd/es/message';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Match, ReviewResult } from '@hellodarwin/core/lib/features/entities';
import {
  getAverageAllReviews,
  getFormattedDate,
} from '@hellodarwin/core/lib/features/helpers';

import { useAdminApi } from '../../features/api/use-admin-api';

type LeadMatchPreviewProps = {
  match: Match;
};

const LeadMatchPreview = ({ match }: LeadMatchPreviewProps) => {
  const api = useAdminApi();
  const [reviews, setRfpReviews] = useState<ReviewResult[]>();
  const [average, setAverage] = useState<number>();

  useEffect(() => {
    (async () => {
      try {
        const matchReviewResults = await api.fetchMatchReview(
          match.provider_id,
          match.rfp_id,
        );
        setRfpReviews(matchReviewResults);
      } catch (e: any) {
        message.error('Something went wrong, try again later!');
        console.error(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reviews && setAverage(getAverageAllReviews(reviews));
  }, [reviews]);

  const items = [
    {
      label: 'Purchase Date',
      text: getFormattedDate(match.purchased_at || ''),
    },
    {
      label: 'Source',
      text: `${match.source}`,
      tooltip: '',
    },
    { label: 'Lead Price', text: `$${match?.price || ''}` },
    {
      label: reviews && 'Review',
      text: `${average || ''}`,
    },

    {
      label: '',
      text: reviews && (
        <Tooltip placement={'bottom'} title={'Go to detailed review page'}>
          <Link to={`/reviews/byMatch/${match.provider_id}/${match.rfp_id}`}>
            Detailed review page
          </Link>
        </Tooltip>
      ),
    },
  ];

  return (
    <div>
      {items.map((item, i) => (
        <div key={`item-${i}`} className="hd-preview-item match-preview-item">
          <div className="match-preview-item-label">{item.label}</div>
          <div>{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default LeadMatchPreview;
