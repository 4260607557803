import {
  AdminFormResponse,
  AdminFormsSubmission,
  AdminHubspotFormResponse,
  ApiError,
  ApiErrorInitialState,
  EditableFormSubmissionResponse,
  FormGroupResponse,
  FormItemResponse,
  FormPageResponse,
  Forms,
  FormsConditions,
  FormsGroup,
  FormsGroupsItem,
  FormsItemsTotal,
  FormsPage,
  FormsSelectOptions,
  FormsSubmissionItem,
  InitialAdminFormResponse,
  InitialEditableFormSubmissionResponse,
  InitialTranslatedFormResponse,
  TransferFromGroup,
  TranslatedFormResponse,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import { createFormAdapter } from '../adapters/form-adapter';
import AdminApiClient from '../admin-api-client';
import { FormSubmissionEligilityState } from './../../../../../core/lib/components/forms/form-viewer/index.d';

const adminFormsSubmissionAdapter = createEntityAdapter({
  selectId: (model: AdminFormsSubmission) => model.form_submission_id,
});
const adminFormsAdapter = createEntityAdapter({
  selectId: (model: AdminFormResponse) => model.form_id,
});

const formsAdapter = createEntityAdapter({
  selectId: (model: Forms) => model.form_id,
});

const activeFormAdapter = createFormAdapter();

type StatusType = 'generic' | 'fetchSubmissions';

type Status = {
  [key in StatusType]: 'idle' | 'pending';
};

export interface FormsState {
  status: 'idle' | 'pending';
  mappedStatus: Status;
  activeTab: string;
  error: ApiError;
  forms: EntityState<Forms, string>;
  formsSubmissions: EntityState<AdminFormsSubmission, string>;
  adminForms: EntityState<AdminFormResponse, string>;
  activeForm: AdminFormResponse;
  activeFormSubmission: EditableFormSubmissionResponse;
  activeTranslatedForm: TranslatedFormResponse;
}

const initialState: FormsState = {
  status: 'idle',
  activeTab: '',
  error: ApiErrorInitialState,
  forms: formsAdapter.getInitialState(),
  formsSubmissions: adminFormsSubmissionAdapter.getInitialState(),
  adminForms: adminFormsAdapter.getInitialState(),
  activeForm: InitialAdminFormResponse,
  activeFormSubmission: InitialEditableFormSubmissionResponse,
  activeTranslatedForm: InitialTranslatedFormResponse,
  mappedStatus: {
    generic: 'idle',
    fetchSubmissions: 'idle',
  },
};

export const fetchForms = createAsyncThunk<
  Forms[],
  { api: AdminApiClient; page: number; limit: number },
  { rejectValue: ApiError }
>(
  'admin/queryForms',
  async (
    { api, page, limit }: { api: AdminApiClient; page: number; limit: number },
    { rejectWithValue },
  ) => {
    try {
      return await api.queryForms(page, limit);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchFormById = createAsyncThunk<
  AdminFormResponse,
  { api: AdminApiClient; formId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchFormById',
  async (
    { api, formId }: { api: AdminApiClient; formId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchFormById(formId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchTranslatedFormByID = createAsyncThunk<
  TranslatedFormResponse,
  { api: AdminApiClient; formId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchTranslatedFormByID',
  async (
    { api, formId }: { api: AdminApiClient; formId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchTranslatedFormByID(formId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchSingleFormSubmission = createAsyncThunk<
  EditableFormSubmissionResponse,
  { api: AdminApiClient; formSubmissionId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchSingleFormSubmission',
  async (
    {
      api,
      formSubmissionId,
    }: { api: AdminApiClient; formSubmissionId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchSingleFormSubmissionEdit(formSubmissionId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createForm = createAsyncThunk<
  AdminFormResponse,
  { api: AdminApiClient; adminForm: Forms },
  { rejectValue: ApiError }
>(
  'admin/createForm',
  async (
    { api, adminForm }: { api: AdminApiClient; adminForm: Forms },
    { rejectWithValue },
  ) => {
    try {
      return await api.createForm(adminForm);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createFormGroup = createAsyncThunk<
  FormGroupResponse,
  { api: AdminApiClient; formGroup: FormsGroup },
  { rejectValue: ApiError }
>(
  'admin/createFormGroup',
  async (
    {
      api,
      formGroup,
    }: {
      api: AdminApiClient;
      formGroup: FormsGroup;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.createFormGroup(formGroup);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createFormItem = createAsyncThunk<
  FormsItemsTotal,
  {
    api: AdminApiClient;
    formItemTotal: FormsItemsTotal;
  },
  { rejectValue: ApiError }
>(
  'admin/createFormItem',
  async (
    {
      api,
      formItemTotal,
    }: {
      api: AdminApiClient;
      formItemTotal: FormsItemsTotal;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.createFormItem(formItemTotal);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createFormPage = createAsyncThunk<
  FormPageResponse,
  { api: AdminApiClient; formPage: FormsPage },
  { rejectValue: ApiError }
>(
  'admin/createFormPage',
  async (
    { api, formPage }: { api: AdminApiClient; formPage: FormsPage },
    { rejectWithValue },
  ) => {
    try {
      return await api.createFormPage(formPage);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormPages = createAsyncThunk<
  AdminFormResponse,
  { api: AdminApiClient; formResponse: AdminFormResponse },
  { rejectValue: ApiError }
>(
  'admin/updateFormPages',
  async (
    {
      api,
      formResponse,
    }: { api: AdminApiClient; formResponse: AdminFormResponse },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormPages(formResponse);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormGroup = createAsyncThunk<
  FormGroupResponse,
  { api: AdminApiClient; formGroup: FormGroupResponse },
  { rejectValue: ApiError }
>(
  'admin/updateFormGroup',
  async (
    { api, formGroup }: { api: AdminApiClient; formGroup: FormGroupResponse },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormGroup(formGroup);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormGroups = createAsyncThunk<
  FormPageResponse,
  { api: AdminApiClient; formPage: FormPageResponse },
  { rejectValue: ApiError }
>(
  'admin/updateFormGroups',
  async (
    { api, formPage }: { api: AdminApiClient; formPage: FormPageResponse },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormGroups(formPage);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const transferFormGroup = createAsyncThunk<
  TransferFromGroup,
  { api: AdminApiClient; transferFormGroup: TransferFromGroup },
  { rejectValue: ApiError }
>(
  'admin/transferFormGroup',
  async (
    {
      api,
      transferFormGroup,
    }: { api: AdminApiClient; transferFormGroup: TransferFromGroup },
    { rejectWithValue },
  ) => {
    try {
      return await api.transferFormGroup(transferFormGroup);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteFormPage = createAsyncThunk<
  string,
  { api: AdminApiClient; formPageId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteFormPage',
  async (
    { api, formPageId }: { api: AdminApiClient; formPageId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteFormPage(formPageId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteFormGroup = createAsyncThunk<
  string,
  { api: AdminApiClient; formGroupId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteFormGroup',
  async (
    { api, formGroupId }: { api: AdminApiClient; formGroupId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteFormGroup(formGroupId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateForm = createAsyncThunk<
  AdminFormResponse,
  { api: AdminApiClient; form: Forms },
  { rejectValue: ApiError }
>(
  'admin/updateForm',
  async (
    { api, form }: { api: AdminApiClient; form: Forms },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateForm(form);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getHubspotFormId = createAsyncThunk<
  AdminHubspotFormResponse,
  { api: AdminApiClient; formId: string },
  { rejectValue: ApiError }
>(
  'admin/getHubspotFormId',
  async (
    { api, formId }: { api: AdminApiClient; formId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getHubspotFormId(formId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateHubspotForm = createAsyncThunk<
  AdminHubspotFormResponse,
  { api: AdminApiClient; formId: string },
  { rejectValue: ApiError }
>(
  'admin/updateHubspotForm',
  async (
    { api, formId }: { api: AdminApiClient; formId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateHubspotForm(formId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormItem = createAsyncThunk<
  FormItemResponse,
  { api: AdminApiClient; formItem: FormItemResponse },
  { rejectValue: ApiError }
>(
  'admin/updateFormItem',
  async (
    { api, formItem }: { api: AdminApiClient; formItem: FormItemResponse },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormItem(formItem);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateFormItemOrder = createAsyncThunk<
  FormsGroupsItem[],
  { api: AdminApiClient; formItemsOrders: FormsGroupsItem[] },
  { rejectValue: ApiError }
>(
  'admin/updateFormItemOrder',
  async (
    {
      api,
      formItemsOrders,
    }: { api: AdminApiClient; formItemsOrders: FormsGroupsItem[] },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormItemOrder(formItemsOrders);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormPage = createAsyncThunk<
  FormPageResponse,
  { api: AdminApiClient; formPage: FormsPage },
  { rejectValue: ApiError }
>(
  'admin/updateFormPage',
  async (
    { api, formPage }: { api: AdminApiClient; formPage: FormsPage },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormPage(formPage);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormSelectOptions = createAsyncThunk<
  FormsSelectOptions[],
  { api: AdminApiClient; formSelectOptions: FormsSelectOptions[] },
  { rejectValue: ApiError }
>(
  'admin/updateFormSelectOptions',
  async (
    {
      api,
      formSelectOptions,
    }: { api: AdminApiClient; formSelectOptions: FormsSelectOptions[] },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormSelectOptions(formSelectOptions);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSingleFormSelectOptions = createAsyncThunk<
  FormsSelectOptions,
  { api: AdminApiClient; option: FormsSelectOptions },
  { rejectValue: ApiError }
>(
  'admin/updateSingleFormSelectOptions',
  async (
    { api, option }: { api: AdminApiClient; option: FormsSelectOptions },
    { rejectWithValue },
  ) => {
    try {
      return (await api.updateFormSelectOptions([option]))[0];
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createFormCondition = createAsyncThunk<
  FormsConditions,
  { api: AdminApiClient; condition: FormsConditions },
  { rejectValue: ApiError }
>(
  'admin/createFormCondition',
  async (
    { api, condition }: { api: AdminApiClient; condition: FormsConditions },
    { rejectWithValue },
  ) => {
    try {
      return await api.createFormCondition(condition);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateFormCondition = createAsyncThunk<
  FormsConditions,
  { api: AdminApiClient; condition: FormsConditions },
  { rejectValue: ApiError }
>(
  'admin/updateFormCondition',
  async (
    { api, condition }: { api: AdminApiClient; condition: FormsConditions },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateFormCondition(condition);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createFormSubmissionItems = createAsyncThunk<
  FormsSubmissionItem[],
  { api: AdminApiClient; items: FormsSubmissionItem[] },
  { rejectValue: ApiError }
>(
  'admin/createFormSubmissionItems',
  async (
    { api, items }: { api: AdminApiClient; items: FormsSubmissionItem[] },
    { rejectWithValue },
  ) => {
    try {
      return await api.createFormSubmissionItems(items);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const createSingleFormSelectOptions = createAsyncThunk<
  FormsSelectOptions,
  { api: AdminApiClient; option: FormsSelectOptions },
  { rejectValue: ApiError }
>(
  'admin/createSingleFormSelectOptions',
  async (
    { api, option }: { api: AdminApiClient; option: FormsSelectOptions },
    { rejectWithValue },
  ) => {
    try {
      return (await api.createFormSelectOptions([option]))[0];
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteFormItem = createAsyncThunk<
  string,
  { api: AdminApiClient; formItemId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteFormItem',
  async (
    { api, formItemId }: { api: AdminApiClient; formItemId: string },
    { rejectWithValue },
  ) => {
    try {
      await api.deleteFormItem(formItemId);
      return formItemId;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteFormPublishedDate = createAsyncThunk<
  string,
  { api: AdminApiClient; formId: string },
  { rejectValue: ApiError }
>(
  'admin/deleteFormPublishedDate',
  async (
    { api, formId }: { api: AdminApiClient; formId: string },
    { rejectWithValue },
  ) => {
    try {
      await api.deleteFormPublishedDate(formId);
      return formId;
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchAllFormSubmission = createAsyncThunk<
  AdminFormsSubmission[],
  { api: AdminApiClient },
  { rejectValue: ApiError }
>(
  'admin/fetchAllFormSubmission',
  async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchAllFormSubmission();
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAllFormSubmissionByCompanyId = createAsyncThunk<
  AdminFormsSubmission[],
  { api: AdminApiClient; companyId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchAllFormSubmissionByCompanyId',
  async (
    { api, companyId }: { api: AdminApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchAllFormSubmissionByCompanyId(companyId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchAllFormSubmissionByGrantId = createAsyncThunk<
  AdminFormsSubmission[],
  { api: AdminApiClient; grantId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchAllFormSubmissionByGrantId',
  async (
    { api, grantId }: { api: AdminApiClient; grantId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchAllFormSubmissionByGrantId(grantId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchAllFormSubmissionByFormId = createAsyncThunk<
  AdminFormsSubmission[],
  { api: AdminApiClient; formId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchAllFormSubmissionByFormId',
  async (
    { api, formId }: { api: AdminApiClient; formId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchAllFormSubmissionByFormId(formId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateFormSubmissionEligibilityStatus = createAsyncThunk<
  { formSubmissionId: string; status: FormSubmissionEligilityState },
  {
    api: AdminApiClient;
    formSubmissionId: string;
    status: FormSubmissionEligilityState;
  },
  { rejectValue: ApiError }
>(
  'admin/updateFormSubmissionEligibilityStatus',
  async (
    {
      api,
      formSubmissionId,
      status,
    }: {
      api: AdminApiClient;
      formSubmissionId: string;
      status: FormSubmissionEligilityState;
    },
    { rejectWithValue },
  ) => {
    try {
      await api.updateFormSubmissionEligibilityStatus(formSubmissionId, status);
      return { formSubmissionId, status };
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

// export const submitForm = createAsyncThunk<
//   string,
//   { api: AdminApiClient; formItemId: string },
//   { rejectValue: ApiError }
// >(
//   "admin/submitForm",
//   async (
//     { api, formItemId }: { api: AdminApiClient; formItemId: string },
//     { rejectWithValue }
//   ) => {
//     try {
//       await api.submitForm(formItemId);
//       return formItemId;
//     } catch (err: any) {
//       console.error(err.response.data);
//       showErrorNotification(err.response.data);
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

export const setActiveTab = createAction<string>('admin/setActiveTab');
export const selectActiveTab = (state: RootState) => state.forms.activeTab;

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchForms.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchForms.fulfilled, (state, { payload }) => {
      formsAdapter.upsertMany(state.forms, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchForms.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchFormById.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchFormById.fulfilled, (state, { payload }) => {
      state.activeForm = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchFormById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchTranslatedFormByID.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchTranslatedFormByID.fulfilled, (state, { payload }) => {
      state.activeTranslatedForm = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchTranslatedFormByID.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchSingleFormSubmission.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchSingleFormSubmission.fulfilled,
      (state, { payload }) => {
        state.activeFormSubmission = payload;
        state.status = 'idle';
      },
    );
    builder.addCase(
      fetchSingleFormSubmission.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );

    builder.addCase(updateForm.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateForm.fulfilled, (state, { payload }) => {
      state.forms = formsAdapter.upsertOne(
        state.forms,
        activeFormAdapter.ConvertToForms(payload),
      );

      state.activeForm = { ...state.activeForm, ...payload };
      state.status = 'idle';
    });
    builder.addCase(updateForm.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createFormPage.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createFormPage.fulfilled, (state, { payload }) => {
      activeFormAdapter.InsertFormPage(state.activeForm, payload);
      state.status = 'idle';
    });
    builder.addCase(createFormPage.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createFormGroup.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createFormGroup.fulfilled, (state, { payload }) => {
      activeFormAdapter.InsertFormGroup(state.activeForm, payload);

      state.status = 'idle';
    });
    builder.addCase(createFormGroup.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormPage.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormPage.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormPage(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(updateFormGroup.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormGroup.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormGroup.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormGroup(
        state.activeForm,
        payload,
      );
    });
    builder.addCase(updateFormPage.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormGroups.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormGroups.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormPage(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(updateFormGroups.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormPages.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormPages.fulfilled, (state, { payload }) => {
      state.activeForm = { ...state.activeForm, ...payload };

      state.status = 'idle';
    });
    builder.addCase(updateFormPages.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormItem.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormItem.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormItem(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(updateFormItem.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormItemOrder.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormItemOrder.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormItemOrder(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(updateFormItemOrder.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormSelectOptions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormSelectOptions.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormItemOptions(
        state.activeForm,
        payload,
      );

      state.status = 'idle';
    });
    builder.addCase(updateFormSelectOptions.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateSingleFormSelectOptions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      updateSingleFormSelectOptions.fulfilled,
      (state, { payload }) => {
        state.activeForm = activeFormAdapter.UpdateSingleFormItemOptions(
          state.activeForm,
          payload,
        );
        state.status = 'idle';
      },
    );
    builder.addCase(
      updateSingleFormSelectOptions.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(createFormCondition.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createFormCondition.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.CreateFormCondition(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(createFormCondition.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateFormCondition.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFormCondition.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.UpdateFormsConditions(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(updateFormCondition.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(createFormSubmissionItems.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(
      createFormSubmissionItems.fulfilled,
      (state, { payload }) => {
        const mappedItems = payload.reduce(
          (o, itm) => ({ ...o, [itm.form_item_id]: itm }),
          {} as { [key: string]: FormsSubmissionItem },
        );

        const newActiveFormSubmission: EditableFormSubmissionResponse = {
          ...state.activeFormSubmission,
          form: {
            ...state.activeFormSubmission.form,
            pages: state.activeFormSubmission.form.pages?.map((page) => ({
              ...page,
              groups: page.groups?.map((group) => ({
                ...group,
                items: group.items?.map((item) =>
                  Object.keys(mappedItems).includes(item.form_item.form_item_id)
                    ? {
                        ...item,
                        forms_submissions_item:
                          mappedItems[item.form_item.form_item_id],
                      }
                    : item,
                ),
              })),
            })),
          },

          all_items: state.activeFormSubmission.all_items.map((itm) =>
            Object.keys(mappedItems).includes(itm.form_item.form_item_id)
              ? {
                  ...itm,
                  forms_submissions_item:
                    mappedItems[itm.form_item.form_item_id],
                }
              : itm,
          ),
        };
        state.activeFormSubmission = newActiveFormSubmission;
        state.status = 'idle';
      },
    );
    builder.addCase(
      createFormSubmissionItems.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(createSingleFormSelectOptions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      createSingleFormSelectOptions.fulfilled,
      (state, { payload }) => {
        state.activeForm = activeFormAdapter.InsertSingleFormItemOptions(
          state.activeForm,
          payload,
        );
        state.status = 'idle';
      },
    );
    builder.addCase(
      createSingleFormSelectOptions.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(getHubspotFormId.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getHubspotFormId.fulfilled, (state, { meta, payload }) => {
      adminFormsAdapter.updateOne(state.adminForms, {
        id: meta.arg.formId,
        changes: { crm_id: payload.crm_id, hubspot_url: payload.hubspot_url },
      });
      state.status = 'idle';
    });
    builder.addCase(getHubspotFormId.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateHubspotForm.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateHubspotForm.fulfilled, (state, { meta, payload }) => {
      adminFormsAdapter.updateOne(state.adminForms, {
        id: meta.arg.formId,
        changes: { crm_id: payload.crm_id, hubspot_url: payload.hubspot_url },
      });
      state.status = 'idle';
    });
    builder.addCase(updateHubspotForm.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createForm.pending, (state) => {
      state.status = 'pending';
    });
    // builder.addCase(createForm.fulfilled, (state, { payload }) => {
    //   adminFormsAdapter.upsertOne(state.adminForms, payload);
    //   state.status = "idle";
    // });
    builder.addCase(createForm.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createFormItem.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createFormItem.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.InsertFormItem(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(createFormItem.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteFormPage.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(deleteFormPage.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.DeleteFormPage(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(deleteFormPage.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteFormGroup.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(deleteFormGroup.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.DeleteFormGroup(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(deleteFormGroup.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deleteFormItem.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(deleteFormItem.fulfilled, (state, { payload }) => {
      state.activeForm = activeFormAdapter.DeleteFormItem(
        state.activeForm,
        payload,
      );
      state.status = 'idle';
    });
    builder.addCase(deleteFormItem.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(fetchAllFormSubmission.pending, (state) => {
      state.mappedStatus.fetchSubmissions = 'pending';
    });
    builder.addCase(fetchAllFormSubmission.fulfilled, (state, { payload }) => {
      adminFormsSubmissionAdapter.setAll(state.formsSubmissions, payload);
      state.mappedStatus.fetchSubmissions = 'idle';
    });
    builder.addCase(fetchAllFormSubmission.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.mappedStatus.fetchSubmissions = 'idle';
    });
    builder.addCase(fetchAllFormSubmissionByCompanyId.pending, (state) => {
      state.mappedStatus.fetchSubmissions = 'pending';
    });
    builder.addCase(
      fetchAllFormSubmissionByCompanyId.fulfilled,
      (state, { payload }) => {
        adminFormsSubmissionAdapter.setAll(state.formsSubmissions, payload);
        state.mappedStatus.fetchSubmissions = 'idle';
      },
    );
    builder.addCase(
      fetchAllFormSubmissionByCompanyId.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.mappedStatus.fetchSubmissions = 'idle';
      },
    );
    builder.addCase(fetchAllFormSubmissionByGrantId.pending, (state) => {
      state.mappedStatus.fetchSubmissions = 'pending';
    });
    builder.addCase(
      fetchAllFormSubmissionByGrantId.fulfilled,
      (state, { payload }) => {
        adminFormsSubmissionAdapter.setAll(state.formsSubmissions, payload);
        state.mappedStatus.fetchSubmissions = 'idle';
      },
    );
    builder.addCase(
      fetchAllFormSubmissionByGrantId.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.mappedStatus.fetchSubmissions = 'idle';
      },
    );
    builder.addCase(fetchAllFormSubmissionByFormId.pending, (state) => {
      state.mappedStatus.fetchSubmissions = 'pending';
    });
    builder.addCase(
      fetchAllFormSubmissionByFormId.fulfilled,
      (state, { payload }) => {
        adminFormsSubmissionAdapter.setAll(state.formsSubmissions, payload);
        state.mappedStatus.fetchSubmissions = 'idle';
      },
    );
    builder.addCase(
      fetchAllFormSubmissionByFormId.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.mappedStatus.fetchSubmissions = 'idle';
      },
    );
    builder.addCase(updateFormSubmissionEligibilityStatus.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      updateFormSubmissionEligibilityStatus.fulfilled,
      (state, { payload }) => {
        state.activeFormSubmission.submission.eligibility_state =
          payload.status;
        state.status = 'idle';
      },
    );
    builder.addCase(
      updateFormSubmissionEligibilityStatus.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
  },
});

export const selectError = (state: RootState) => state.forms.error.error_code;

export const selectFormIsLoading = createSelector(
  [
    (state: RootState, _?: StatusType) => state.forms.mappedStatus,
    (state: RootState, _?: StatusType) => state.forms.status,
    (_, type?: StatusType) => type,
  ],
  (mappedStatus, status, type) => {
    if (!type) {
      return status === 'pending';
    } else {
      return mappedStatus[type] === 'pending';
    }
  },
);

export const selectForm = (state: RootState) => state.forms.activeForm;
export const selectActiveFormSubmission = (state: RootState) =>
  state.forms.activeFormSubmission;

export const { selectAll: selectAllForms, selectById: selectFormById } =
  formsAdapter.getSelectors((state: RootState) => state.forms.forms);
export const selectActiveTranslatedForm = (state: RootState) =>
  state.forms.activeTranslatedForm;

export const selectFormPages = createSelector(
  [
    (_, formId) => formId,
    (state, formId) => state.forms.adminForms.entities[formId],
  ],
  (formId, form) => {
    if (!formId || formId === '' || !form) {
      return [];
    }
    return form.pages;
  },
);
export const selectFormItems = createSelector(
  [
    (state: RootState, _: string, __: number, ___: number) =>
      state.forms.adminForms.entities,
    (_: RootState, formId: string, __: number, ___: number) => formId,
    (_: RootState, __: string, pageNumber: number, ___: number) => pageNumber,
    (_: RootState, __: string, ___: number, groupNumber: number) => groupNumber,
  ],
  (adminForms, formId, pageNumber, groupNumber) => {
    if (!formId || formId === '') {
      const formItems = [
        {
          form_item_id: '',
          key: '',
          form_id: '',
          display_order: 0,
          content_en: '',
          content_fr: '',
          type: '',
          field_type: '',
        },
      ];
      return formItems;
    }
    return adminForms[formId]?.pages[pageNumber]?.groups[groupNumber]?.items;
  },
);

export const {
  selectAll: selectAllFormsSubmissions,
  selectById: selectFormSubmissionById,
} = adminFormsSubmissionAdapter.getSelectors(
  (state: RootState) => state.forms.formsSubmissions,
);

export const formsReducer = formsSlice.reducer;
