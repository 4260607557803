import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import React, { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import Loading from '../../../loading';
import Collapse from '../../Collapse';
import Typography from '../../typography';
import { SidebarBlockConfig } from './sidebar-block';
import { SidebarBlockContainer, SidebarBlockHeader } from './styles';

export interface ChildSidebarBlockProps extends SidebarBlockConfig {
  isLoading?: boolean;
}

const ChildSidebarBlock = ({
  isLoading,
  content,
  title,
  extra,
}: ChildSidebarBlockProps) => {
  const theme = useTheme();

  return (
    <SidebarBlockContainer>
      <Collapse
        ghost
        style={{ overflow: 'hidden' }}
        defaultActiveKey={'sidebar-block'}
        noPadding
        styles={{
          panelContainer: {
            padding: '20px 32px',
          },
          panelContent: {
            paddingTop: 0,
          },
        }}
        items={[
          {
            id: 'sidebar-block',
            title: (
              <SidebarBlockHeader>
                <Typography nowrap elementTheme="subtitle1">
                  {title}
                </Typography>
              </SidebarBlockHeader>
            ),
            children: (
              <>
                {React.Children.toArray(
                  (content as SidebarBlockConfig[]).map((itm) => (
                    <Collapse
                      ghost
                      style={{ overflow: 'hidden' }}
                      defaultActiveKey={'sidebar-block'}
                      noPadding
                      styles={{
                        panelContainer: {
                          padding: '8px',
                        },
                        panelContent: {
                          paddingTop: 16,
                        },
                      }}
                      items={[
                        {
                          id: 'sidebar-block',
                          title: (
                            <SidebarBlockHeader>
                              <Typography nowrap elementTheme="body2">
                                {itm.title}
                              </Typography>
                            </SidebarBlockHeader>
                          ),
                          children: (
                            <SidebarBlockContainer>
                              {isLoading ? (
                                <Loading />
                              ) : (
                                (itm.content as ReactNode)
                              )}
                            </SidebarBlockContainer>
                          ),
                          extra: itm.extra,
                        },
                      ]}
                      ExpandIcon={({ isActive }) => (
                        <TabArrow
                          down={isActive}
                          size={12}
                          color={theme.colors.grey_2}
                        />
                      )}
                    />
                  )),
                )}
              </>
            ),
            extra,
          },
        ]}
        ExpandIcon={({ isActive }) => (
          <TabArrow down={isActive} size={12} color={theme.colors.grey_2} />
        )}
      />
    </SidebarBlockContainer>
  );
};

export default ChildSidebarBlock;
