import Loading from '@hellodarwin/core/lib/components/loading';
import {
  getFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Tooltip from 'antd/es/tooltip';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import List from '../../components/list/list';
import {
  fetchRefusedMatches,
  selectProviderRefusedMatches,
  selectProvidersLoading,
} from '../../features/api/slices/providers-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

type RefusedRfpListProps = {
  providerId: string;
};

const RefusedRfpList = ({ providerId }: RefusedRfpListProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const matches = useAppSelector(selectProviderRefusedMatches);
  const loading = useAppSelector(selectProvidersLoading);
  const { t } = useTranslations();
  useEffect(() => {
    dispatch(fetchRefusedMatches({ api, providerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    t('refused_rfps|rfp_id'),
    t('refused_rfps|category'),
    t('refused_rfps|estimatedValue'),
    t('refused_rfps|leadPrice'),
    t('refused_rfps|refused_reason'),
    t('refused_rfps|refused_on'),
  ];

  const getRows = () => {
    if (matches.length === 0) {
      return [];
    }

    return matches.map((m, index) => {
      const date = getFormattedDate(m.refused_at || '');
      return [
        <Tooltip placement="bottom" key={index} title="Project page">
          <Link to={'/projects/' + m.rfp_id}>{getShortId(m.rfp_id)}</Link>
        </Tooltip>,
        m.rfp.category,
        `$${m.rfp.estimated_value}`,
        `$${m.price}`,
        m.refused_reason,
        date,
      ];
    });
  };

  if (loading) return <Loading />;

  return <List headers={headers} rows={getRows()} onClick={() => null} />;
};

export default RefusedRfpList;
