import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';

const validateEmail =
  (message: string) => (_: RuleObject, value: StoreValue) => {
    var emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error(message));
  };

export default validateEmail;
