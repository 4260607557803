import Div from '@hellodarwin/core/lib/components/common/div';
import { AdminGinContactResponse } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import Email from '@hellodarwin/icons/dist/icons/Email';
import Typography from 'antd/es/typography';
import { SelectableContactContainer } from './styles';
interface SelectableContactProps {
  contact?: AdminGinContactResponse;
  selected: boolean;
}

export const SelectableContact = ({
  contact,
  selected,
}: SelectableContactProps) => {
  const { t } = useTranslations();
  return (
    <SelectableContactContainer
      style={
        selected
          ? {
              background: theme.colors.beige_2,
              boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.5)',
            }
          : {}
      }
    >
      {!!contact ? (
        <Div flex="column">
          <Typography.Text style={{ fontSize: 16 }}>
            {`${contact.last_name}, ${contact.first_name}`}
          </Typography.Text>
          <Typography.Text
            style={{ color: theme.colors.grey_2, marginBottom: 16 }}
          >
            {contact.title}
          </Typography.Text>
          {contact.email && (
            <Div flex="row" align="center" gap={8}>
              <Email size={24} />
              <Typography.Text
                style={{
                  color: theme.colors.grey_2,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {contact.email}
              </Typography.Text>
            </Div>
          )}
        </Div>
      ) : (
        <Div flex="row" style={{ alignItems: 'center' }}>
          <Typography.Text style={{ fontSize: 14, margin: 4 }}>
            +
          </Typography.Text>
          <Typography.Text
            style={{ fontSize: 14, textAlign: 'center', display: 'block' }}
          >
            {t('mandated_agents|new_agent')}
          </Typography.Text>
        </Div>
      )}
    </SelectableContactContainer>
  );
};

export default SelectableContact;
