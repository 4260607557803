import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const IdIcon = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);
  return (
    <svg
      {...iconProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M10.2671 3.07432C12.087 1.82779 14.1987 1.11541 16.378 1.01288C18.5573 0.91035 20.7227 1.42151 22.6441 2.49203C24.5654 3.56255 26.171 5.15243 27.2901 7.09273C28.4093 9.03303 29.0002 11.2512 29 13.5116C29 18.3825 28.1049 23.0348 26.4757 27.3035M6.8509 6.61237C5.54294 8.65778 4.84733 11.058 4.85139 13.5116C4.85519 15.8687 4.21317 18.1785 3 20.1724M6.20211 26.1091C9.22394 22.6613 10.8951 18.169 10.8885 13.5116C10.8885 11.8526 11.5246 10.2615 12.6568 9.08841C13.789 7.9153 15.3245 7.25625 16.9257 7.25625C18.5268 7.25625 20.0624 7.9153 21.1946 9.08841C22.3268 10.2615 22.9628 11.8526 22.9628 13.5116C22.9628 14.3907 22.929 15.2615 22.8598 16.1222M16.9257 13.5116C16.9347 19.4769 14.8783 25.2477 11.13 29.7756M21.8085 22.109C20.9368 25.2731 19.5865 28.274 17.8079 31"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IdIcon;
