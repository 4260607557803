import { CSSProperties } from 'react';

type borderSides =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'horizontal'
  | 'vertical'
  | 'all';

const createBorderStyle = ({
  color,
  borderWidth = 1,
  side = 'all',
  borderStyle = 'solid',
}: {
  color: CSSProperties['borderColor'];
  borderWidth?: number;
  side?: borderSides;
  borderStyle?: CSSProperties['borderStyle'];
}): CSSProperties => {
  const value = `${borderWidth}px ${borderStyle} ${color}`;
  switch (side) {
    case 'top':
      return {
        borderTop: value,
      };
    case 'bottom':
      return {
        borderBottom: value,
      };
    case 'left':
      return {
        borderLeft: value,
      };
    case 'right':
      return {
        borderRight: value,
      };
    case 'horizontal':
      return {
        borderTop: value,
        borderBottom: value,
      };
    case 'vertical':
      return {
        borderLeft: value,
        borderRight: value,
      };
    default:
      return {
        border: value,
      };
  }
};
export default createBorderStyle;
