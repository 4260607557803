import Collapse from '@hellodarwin/core/lib/components/common/Collapse';
import {
  CollapsePanelContainer,
  CollapsePanelHeader,
} from '@hellodarwin/core/lib/components/common/Collapse/styles';
import {
  SummaryBoxContainer,
  SummaryBoxContent,
} from '@hellodarwin/core/lib/components/grants/single/grant-sections/summary/styles';
import styled from '@hellodarwin/core/lib/plugins/styled';

export const GinSingleHeaderContainer = styled(Collapse)`
  width: 100%;

  ${CollapsePanelContainer} {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    ${CollapsePanelHeader} {
      padding: 24px 32px;
    }
    .ReactCollapse--content {
      padding: 0;
    }
  }
`;

export const GinSingleContainer = styled.div<{ $fullPage?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
`;

export const GinSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  background-color: ${({ theme }) => theme.colors.white_1};
  padding: 24px 32px;
  gap: 16px;
  width: 100%;

  scroll-margin-top: ${({ theme }) => theme.appearance.appHeaderHeight + 16}px;
`;
export const GinSectionContent = styled.div``;
export const GinSectionHeader = styled.div``;

export const GinFileSummaryWrapper = styled.div`
  ${SummaryBoxContainer} {
    border-right: none;
    padding: 0px;
    gap: 6px;
  }
`;

export const GinStepCardSummary = styled.div`
  ${SummaryBoxContainer} {
    padding: 24px 0px;
    gap: 6px;
  }
  a {
    word-break: break-all;
  }
`;

export const GinGrantSummaryWrapper = styled.div<{ $size: number }>`
  display: flex;
  flex-wrap: wrap;
  padding: 24px 48px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 54px;

  & > * {
    flex: 1 1 calc((100% / 3) - 54px);
    max-width: calc((100% / 3) - 54px);
  }

  ${SummaryBoxContainer} {
    border-right: none;
    padding: 0px;
    min-width: 300px;
    gap: 6px;
  }

  ${SummaryBoxContent} {
    padding-inline-start: 24px;
  }

  ul {
    margin: 0;
    padding-inline-start: 0px;
  }
`;
export const GinHistoryCard = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  gap: 8px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.beige_1 : 'transparent'};
  border: 1px solid ${({ theme }) => theme.colors.primary};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.purple_2};
    cursor: pointer;
    z-index: 1;
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
  }
`;
