import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import { FormPageContentButtonsProps } from '../../types';

const FormPageContentButtons = ({
  isFirstPage,
  isEndPage,
  isOpeningPage,
}: FormPageContentButtonsProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  if (isEndPage) return <></>;

  return (
    <Div
      flex="row"
      gap={16}
      justify={isOpeningPage ? 'center' : 'flex-end'}
      style={{ padding: 24 }}
    >
      {!isFirstPage && (
        <Button defaultStyle={theme.colors.white_1} size="small">
          {t('button|previous')}
        </Button>
      )}
      <Button
        defaultStyle={
          isOpeningPage ? theme.colors.yellow_1 : theme.colors.white_1
        }
        size={isOpeningPage ? 'large' : 'small'}
      >
        {isOpeningPage ? t('button|startNow') : t('button|next')}
      </Button>
    </Div>
  );
};
export default FormPageContentButtons;
