import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Favorite = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4 10.1818C30.4 6.32632 27.0416 3.20001 22.8992 3.20001C19.8032 3.20001 17.144 6.54702 16 9.0403C14.856 6.54702 12.1968 3.20001 9.09917 3.20001C4.95998 3.20001 1.59998 6.32632 1.59998 10.1818C1.59998 21.3838 16 28.8 16 28.8C16 28.8 30.4 21.3838 30.4 10.1818Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Favorite;
