import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { Program } from '@hellodarwin/core/lib/features/entities';
import {
  getShortFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import { Col, Row } from 'antd/es/grid';
import Table, { ColumnType } from 'antd/es/table';
import Tag from 'antd/es/tag';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import Clipboard from '../../components/clipboard';
import CreateProgramForm from '../../components/programs/programs-action-modal/create-program-form';
import {
  queryCompanies,
  selectAllCompanies,
} from '../../features/api/slices/companies-slice';
import {
  queryPrograms,
  selectPrograms,
  selectProgramsLoading,
} from '../../features/api/slices/programs-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { getStatusColor } from '../../features/utils';
import theme from '../../theme';

interface PaginationState {
  page: number;
  limit: number;
}

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 50;

const paginationInitialValues: PaginationState = {
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
};

const ProgramsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const programs = useAppSelector(selectPrograms);
  const isLoading = useAppSelector(selectProgramsLoading);
  const allCompanies = useAppSelector(selectAllCompanies);

  const [paginationState, setPaginationState] = useState<PaginationState>(
    paginationInitialValues,
  );
  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT);
    dispatch(
      queryCompanies({
        api: api,
        page: DEFAULT_PAGE,
        limit: DEFAULT_LIMIT,
        query: 'A',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = (page: number, limit: number) => {
    dispatch(queryPrograms({ api, page, limit }));
    setPaginationState({ page, limit });
  };

  const onPageChange = (page: number, limit: number) => {
    fetch(page, limit);
  };

  const { t } = useTranslations();
  const columns: ColumnType<Program>[] = [
    {
      key: 'program_id',
      dataIndex: 'program_id',
      title: 'ID',
      render: (v) => (
        <Div flex="row" gap={10}>
          <Clipboard copy={v} title={getShortId(v)} />
          <a href={`/programs/${v}`} rel="noopener noreferrer" target="_blank">
            <NewWindow style={{ color: theme.colors.purple_1 }} width={16} />
          </a>
        </Div>
      ),
    },
    {
      key: 'program_name',
      dataIndex: 'program_name',
      title: t('application_list|application_name'),
    },
    {
      title: t('application_list|created_on'),
      dataIndex: 'program_created_at',
      key: 'program_created_at',
      render: (v) => getShortFormattedDate(v),
      sorter: {
        compare: (a, b) =>
          new Date(a.program_created_at || 0).getTime() -
          new Date(b.program_created_at || 0).getTime(),
      },
    },
    {
      title: t('application_list|status'),
      dataIndex: 'program_grant_status',
      key: 'program_grant_status',
      render: (v) => <Tag color={getStatusColor(v)}>{v}</Tag>,
    },
    {
      title: 'Type',
      dataIndex: 'program_type',
      key: 'program_type',
      sorter: {
        compare: (a, b) => {
          if (a.program_type && b.program_type) {
            return a.program_type.localeCompare(b.program_type);
          } else {
            return 0;
          }
        },
      },
    },
    {
      key: 'program_contact_name',
      dataIndex: 'program_contact_name',
      title: t('application_list|contact_name'),
    },
    {
      key: 'program_company_name',
      dataIndex: 'program_company_name',
      title: t('application_list|companyName'),
    },
  ];

  return (
    <PageLayout
      title={t('application_list|applications')}
      app="admin"
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('application_list|applications'),
        },
      ]}
      actions={
        <Button
          size="small"
          defaultStyle={theme.colors.white_1}
          trailingIcon={<AddNew size={16} />}
          onClick={toggleModal}
          style={{ display: 'flex' }}
        >
          {t('application_list|create_application_title')}
        </Button>
      }
    >
      <CreateProgramForm
        visible={isModalVisible}
        handleClose={toggleModal}
        companies={allCompanies}
        companyId="default"
      />
      <Row gutter={16}>
        <Col span={24}>
          <Table
            dataSource={programs}
            columns={columns}
            pagination={{
              pageSize: paginationState.limit,
              current: paginationState.page,
              total: programs?.[0]?.full_count || paginationState.limit,
              showSizeChanger: true,
              onChange: (page, size) =>
                onPageChange(page, size ?? DEFAULT_LIMIT),
            }}
            loading={isLoading}
            size="small"
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => {
                  navigate(`/programs/${record.program_id}`);
                },
              };
            }}
          />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default ProgramsPage;
