import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import Dots from '@hellodarwin/icons/dist/icons/Dots';
import Dropdown from 'antd/es/dropdown';
import { ColumnsType } from 'antd/es/table';
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { AssetRowMeta } from '../../../../features/entities';
import { getStringDate } from '../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../features/providers/locale-provider';
import Button from '../../../common/button';
import Div from '../../../common/div';
import HdDropdown from '../../../common/dropdown';
import Typography from '../../../common/typography';
import { FilesListContentProps } from '../types';
import { FilesTableContainer } from './styles';

const getTableColumns = () => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  return useMemo(() => {
    const columns: ColumnsType = [
      {
        title: '',
        dataIndex: 'Icon',
        key: 'Icon',
        render: (Icon: IconNode) => (
          <Div flex="row" align="center" style={{ width: 'fit-content' }}>
            <Icon
              size={24}
              style={{ flexShrink: 0, width: 24, display: 'block' }}
            />
          </Div>
        ),
      },
      {
        title: t('assets|infosDrawer.labels.name'),
        dataIndex: 'name',
        key: 'name',

        render: (value) => (
          <Typography
            elementTheme="subtitle2"
            color={theme.colors.primary}
            nowrap
            overflow
            ellipsis
          >
            {value}
          </Typography>
        ),
      },
      {
        title: t('assets|infosDrawer.labels.size'),
        dataIndex: 'size',
        key: 'size',

        render: (value) => (
          <Typography
            elementTheme="body2"
            color={theme.colors.grey_2}
            nowrap
            overflow
            ellipsis
          >
            {value}
          </Typography>
        ),
      },
      {
        title: t('assets|infosDrawer.labels.type'),
        dataIndex: 'type',
        key: 'type',

        render: (value) => (
          <Typography
            elementTheme="body2"
            color={theme.colors.grey_2}
            nowrap
            overflow
            ellipsis
          >
            {value}
          </Typography>
        ),
      },
      {
        title: t('assets|infosDrawer.labels.createdAt'),
        dataIndex: 'created_at',
        key: 'created_at',

        render: (value) => (
          <Typography
            elementTheme="body2"
            color={theme.colors.grey_2}
            nowrap
            overflow
            ellipsis
          >
            {getStringDate(value, selectedLocale)}
          </Typography>
        ),
      },
      {
        title: t('assets|infosDrawer.labels.updatedAt'),
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (value) => (
          <Typography
            elementTheme="body2"
            color={theme.colors.grey_2}
            nowrap
            overflow
            ellipsis
          >
            {getStringDate(value, selectedLocale)}
          </Typography>
        ),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',

        render: (actions: AssetRowMeta['actions']) => (
          <HdDropdown
            items={actions.dropdownItems}
            menuStyle={{ padding: 0 }}
            ctaRender={() => (
              <Button size="square" style={{ padding: 0 }} transparent>
                <Dots size={32} />
              </Button>
            )}
          />
        ),

        fixed: 'right',
      },
    ];
    return columns;
  }, [t, selectedLocale]);
};

const FilesListTable: React.FC<FilesListContentProps> = ({
  items,
  activeItem,
}) => {
  const columns = getTableColumns();

  const [dropdownPosition, setDropdownPosition] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const onContextMenu = (event: MouseEvent, record: AssetRowMeta) => {
    event.preventDefault();
    setSelectedRow(record);

    setDropdownPosition({ x: event.clientX, y: event.clientY });
  };

  const handleCloseDropdown = () => {
    setDropdownPosition(null);
    setSelectedRow(null);
  };

  return (
    <>
      <FilesTableContainer
        dataSource={items}
        columns={columns}
        tableLayout="auto"
        rowSelection={{ selectedRowKeys: [activeItem?.id] }}
        rowKey={'id'}
        sticky
        scroll={{ x: 1200 }}
        onRow={(record: AssetRowMeta, rowIndex) => {
          return {
            contextMenu: 'test',
            onClick: record.actions.onClick,

            onDoubleClick: record.isFolder
              ? record.actions.onAction
              : record.actions.onDoubleClick,
            onContextMenu: (event) => onContextMenu(event, record),
          };
        }}
      />
      {dropdownPosition && (
        <Dropdown
          menu={{
            items: selectedRow.actions.dropdownItems,
            style: { padding: 0 },
          }}
          open={!!dropdownPosition}
          onOpenChange={(visible) => !visible && handleCloseDropdown()}
          trigger={['click']}
        >
          <div
            style={{
              position: 'fixed',
              width: 1,
              background: 'red',
              top: dropdownPosition.y,
              left: dropdownPosition.x,
            }}
          />
        </Dropdown>
      )}
    </>
  );
};

export default FilesListTable;
