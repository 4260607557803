import Select, { SelectProps } from 'antd/es/select';
import { useTranslations } from '../../../../features/providers/translations-provider';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';

const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'not_disclosed', label: 'Not Disclosed' },
  { value: 'non-binary', label: 'Non-binary' },
  { value: 'genderqueer', label: 'Genderqueer' },
  { value: 'agender', label: 'Agender' },
  { value: 'bigender', label: 'Bigender' },
  { value: 'genderfluid', label: 'Genderfluid' },
  { value: 'nonconforming', label: 'Gender Nonconforming' },
  { value: 'two-spirit', label: 'Two-Spirit' },
  { value: 'pangender', label: 'Pangender' },
  { value: 'other', label: 'Other' },
];

interface GenderSelectProps extends SelectProps {
  isRequired?: boolean;
}

const GenderSelect = ({ isRequired, ...selectProps }: GenderSelectProps) => {
  const { t } = useTranslations();
  return (
    <FormItem
      label={<FormLabel label={t('admin_settings|gender')} />}
      name="gender"
      rules={[
        {
          required: isRequired,
          message: 'Please add a gender for the contact',
        },
      ]}
    >
      <Select
        {...selectProps}
        placeholder="Select option"
        options={genders}
        defaultValue={'not_disclosed'}
      />
    </FormItem>
  );
};

export default GenderSelect;

GenderSelect.Demo = () => {
  return <GenderSelect disabled />;
};
