import { RcFile } from 'antd/es/upload';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import CompanyType from '../enums/company-type';
import Language from '../enums/language';
import Province from '../enums/province';
import SingleLanguage from '../enums/single-language';
import { Match } from './matches-entities';
import { Provider } from './providers-entities';
import { Rfp } from './rfp-entities';

declare global {
  interface Window {
    _hsq: any;
  }
}

export type AppType = 'client' | 'admin' | 'provider' | 'website';

export interface TeamMember {
  provider_team_member_id: string;
  provider_id: string;
  name: string;
  role: string;
  linked_in: string;
  profile_picture: string;
  phone?: string;
  email?: string;
  order_team?: number;
}

export const InitialTeamMember: TeamMember = {
  profile_picture: '',
  provider_id: '',
  name: '',
  role: '',
  linked_in: '',
  provider_team_member_id: '',
};

export interface TeamMemberRequest {
  provider_id?: string;
  name: string;
  role: string;
  linked_in: string;
  order_team?: number;
}

export interface Portfolio {
  provider_portfolio_id: string;
  provider_id: string;
  description: string;
  rfp_url: string;
  client_company_name: string;
  from_hellodarwin_at: string | null;
  curated_at?: string;
  public_at?: string;
  created_at?: string;
  updated_at?: string;
  thumbnail: string;
  category: string;
  service: string;
  tags: string[];
  assets: PortfolioAsset[];
  portfolio_title: string;
  linked_in?: string;
  client_name?: string;
  portfolio_picture?: string;
  rfp_id?: string;
  specialties?: string[];
}
export const PortfolioInitialState = {
  provider_portfolio_id: '',
  provider_id: '',
  description: '',
  rfp_url: '',
  client_company_name: '',
  from_hellodarwin_at: '',
  curated_at: '',
  public_at: '',
  created_at: '',
  updated_at: '',
  thumbnail: '',
  category: '',
  service: '',
  tags: [],
  assets: [],
  provider: {} as Provider,
  portfolio_title: '',
  linked_in: '',
  client_name: '',
  portfolio_picture: '',
  rfp_id: '',
  specialties: '',
};

export interface MappedPortfolios {
  [key: string]: Portfolio;
}

export interface MappedPortfolioAssets {
  [key: string]: PortfolioAsset;
}

export interface MappedAssetsByPortfolio {
  [key: string]: PortfolioAsset[];
}

export interface PortfolioAsset {
  provider_portfolio_asset_id?: string;
  provider_portfolio_id: string;
  file_extension?: string;
  pos: number;
  asset_url?: string;
  asset_type: PortfolioAssetsType;
  file?: string | RcFile | Blob;
  temp_id?: string;
  filename?: string;
  deleted?: boolean;
}

export interface ProviderPortfolio {
  provider_id?: string;
  provider_portfolio_id?: string;
  description: string;
  rfp_url: string;
  client_company_name: string;
  from_hellodarwin_at: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  thumbnail: string;
  category: string;
  service: string;
  tags: string[];
  assets: string[];
  public_at: string;
  curated_at?: string;
  title: string;
}

export type PortfolioRequest = {
  provider_id: string;
  provider_portfolio_id?: string;
  portfolio_title?: string;
  description: string;
  client_company_name: string;
  rfp_url: string;
  tags: string[];
  made_with_hellodarwin: boolean;
  public: boolean;
  curated?: boolean;
};

export enum PortfolioAssetsType {
  Video = 'Video',
  Image = 'Image',
}

export type PortfolioAssetRequest = {
  provider_portfolio_id: string;
  asset_pos: number;
  asset_type: PortfolioAssetsType;
  asset_url: string;
};

export interface Contact {
  contact_id: string;
  company_id: string;
  name: string;
  email?: string;
  title?: string;
  phone?: string;
  ext?: string;
  preferred_language?: SingleLanguage;
  gender?: string;
  roles?: string;
  comments?: string;
  auth_id?: string;
  created_at?: string;
  last_login_at?: string;
  updated_at?: string;
  auth_id_match?: boolean;
  hubspot_url?: string;
  crm_id?: string;
}

export const ContactInitialState: Contact = {
  contact_id: '',
  company_id: '',
  name: '',
};

export interface CompanyAsset {
  asset_id: string;
  company_id: string;
  asset_url: string;
  asset_title: string;
  created_at?: string;
  updated_at?: string;
}

export interface Company {
  company_id: string;
  name: string;
  industry?: string;
  type?: CompanyType;
  size?: string;
  territory?: string;
  description?: string;
  website?: string;
  email?: string;
  phone?: string;
  ext?: string;
  address?: string;
  city?: string;
  postal_code?: string;
  province?: Province | string;
  country?: string;
  facebook?: string;
  twitter?: string;
  linked_in?: string;
  instagram?: string;
  pinterest?: string;
  youtube?: string;
  tiktok?: string;
  logo?: string;
  banner?: string;
  contacts?: Contact[];
  annual_revenue?: string;
  rfp_language?: Language;
  profile_language?: SingleLanguage;
  comments?: string;
  business_number?: string;
  terms_and_conditions?: boolean;
  deleted_at?: string;
  updated_at?: string;
  created_at?: string;
  founded_at?: string | null;
  rfps?: Rfp[];
  matches?: Match[];
  assets?: CompanyAsset[];
  short_description?: string;
  credit_balance?: string;
  network?: string;
  lat?: number;
  lng?: number;
  tagline?: string;
  is_propulsion?: boolean;
  company_billing_customer_id?: string;
  slug?: string;
  crm_id?: string;
  hubspot_url?: string;
  profile_url?: string;
  industry_sector?: string[];
  industry_subsector?: string[];
}

export const CompanyInitialState: Company = {
  company_id: '',
  name: '',
};

export interface CompanyQuickInfos {
  company_id: string;
  rfps_count: number;
  latest_activated_rfp: string;
  company_size: string;
}

export interface CompanyRequest {
  company_id?: string;
  company_description?: string;
  company_founded_at?: string | Dayjs | null;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  contact_preferred_language?: string;
  contact_gender?: string;
  contact_title?: string;
  company_name?: string;
  company_address?: string;
  company_email?: string;
  company_website?: string;
  company_city?: string;
  company_postal_code?: string;
  company_province?: string;
  company_country?: string;
  company_size?: string;
  company_phone?: string;
  company_industry?: string;
  terms_and_conditions?: boolean;
  rfp_language?: Language;
  profile_language?: SingleLanguage;
  lat?: number;
  lng?: number;
  company_type?: CompanyType;
  company_tagline?: string;
  provider_hourly_rate?: number;
  facebook?: string;
  instagram?: string;
  linked_in?: string;
  tiktok?: string;
  twitter?: string;
  pinterest?: string;
  youtube?: string;
}

export type Localisation = {
  lat: number;
  lng: number;
};

export const InitialLocalisation: Localisation = {
  lat: 0,
  lng: 0,
};

export interface Client {
  company_id: string;
  name: string;
  website: string;
  size: string;
  contacts: Contact[];
  city?: string;
}

export interface AdminOauthOptions {
  domain: string;
  admin_client_id: string;
  audience: string;
}

export interface PlanPrice {
  interval: string;
  interval_count: number;
  price: number;
  billing_price_id: string;
}

export interface Plan {
  name: string;
  billing_product_id: string;
  prices: PlanPrice[];
}

export interface BillingSession {
  url: string;
}

export interface Subscription {
  billing_subscription_id: string;
  status: string;
  plan: PlanPrice;
}

export interface RfpSearchResult {
  rfp_id: string;
  title: string;
  rfp_original_description: string;
  rfp_status: string;
  budget_min: number;
  budget_max: number;
  rfp_created_at: string;
  company_id: string;
  company_name: string;
  company_email: string;
  rfp_lead_price: number;
  rfp_estimated_value: number;
  rfp_territory: string;
  rfp_category: string;
  discounted_amount: number;
  is_premium: boolean;
}

export interface ProviderSearchResult {
  provider_id: string;
  provider_created_at: string;
  company_id: string;
  company_name: string;
  company_email: string;
  company_comments: string;
  provider_subscription_plan_name: string;
  provider_subscription_status: string;
  provider_status: string;
}

export interface ContactSearchResult {
  contact_id: string;
  contact_name: string;
  contact_email: string;
}

export interface CompanySearchResult {
  company_id: string;
  company_name: string;
  company_email: string;
}

export interface GrantSearchResult {
  grant_id: string;
  grant_title: string;
  grant_display_title: string;
}

export interface GrantProviderSearchResult {
  grant_provider_id: string;
  title: string;
}

export interface SearchResults {
  providers: ProviderSearchResult[];
  rfp: RfpSearchResult[];
  contacts: Contact[];
  companies: CompanySearchResult[];
  grants: GrantSearchResult[];
  grantProviders: GrantProviderSearchResult[];
}

export interface Admin {
  id: string;
  name: string;
  email: string;
  picture: string;
  phone: string;
  meeting_link_fr: string;
  meeting_link_en: string;
}

export type Invoice = {
  HostedURL: string;
};

export interface ReferralCreditParams {
  price: number;
}

export interface CreateAuth0UserResponse {
  auth_id: string;
  reset_link: string;
}

export interface FindAuth0IDResponse {
  auth_id: string;
  auth_id_match: boolean;
}

export type NextMeeting = {
  Uri: string;
  Name: string;
  StartTime: string;
  EndTime: string;
  EventType: string;
  Location: Location | undefined;
  InviteesCounter: InviteesCounter;
  CreatedAt: string;
  UpdatedAt: string;
  EventGuests: EventGuest[];
};

export type EventGuest = {
  Email: String;
  CreatedAt: string;
  UpdatedAt: string;
};
export type InviteesCounter = {
  Total: number;
  Active: number;
  Limit: number;
};

export interface QA {
  question: string;
  answer: string | ReactNode;
  videos?: {
    label: string;
    name: string;
    url: string;
  }[];
  images?: {
    url: string;
  }[];
}

export type FormAddressInformations = {
  city: string;
  country: string;
  province: string;
  postal_code: string;
  address: string;
};
export type AddressInformations = {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  locality: string;
  neighborhood: string;
  postal_code: string;
  route: string;
  street_number: string;
  subpremise: string;
};

export const AddressInformationsInitialState: AddressInformations = {
  street_number: '',
  subpremise: '',
  route: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  postal_code: '',
  country: '',
  locality: '',
  neighborhood: '',
};

export enum AppName {
  Admin = 'Admin',
  Partner = 'Partner',
  Client = 'Client',
  Website = 'Website',
}

export interface HdUser {
  logo?: string;
  name: string;
}

export interface TeamMemberItem {
  provider_team_member_id?: string;
  order_team?: number;
}
