const formatPhoneNumber = (input: string) => {
  // Remove unnecessary spaces and split the input
  const [phonePart, ...extensionPart] = input.split(/poste|ext|extension/i);

  // Clean and normalize the phone number part
  const cleaned = ('' + phonePart).replace(/\D/g, '');

  // Remove the leading country code (if it's `1` for North America)
  const normalized =
    cleaned.startsWith('1') && cleaned.length === 11
      ? cleaned.slice(1)
      : cleaned;

  if (normalized.length !== 10) {
    return 'Invalid phone number';
  }

  const match = normalized.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return 'Invalid phone number';
  }

  const formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`;

  // Handle the extension part, if present
  const extension = extensionPart.join('').trim();
  if (extension) {
    return `${formattedPhone} ext. ${extension}`;
  }

  return formattedPhone;
};

export default formatPhoneNumber;
