import { Match, Rfp } from '@hellodarwin/core/lib/features/entities';
import RfpStatus from '@hellodarwin/core/lib/features/enums/rfp-status';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import { useAppDispatch } from '../../app';
import {
  setSelectedRfp,
  toggleRfpsModal,
} from '../../features/api/slices/rfp-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

type RfpStatusDropdownProps = {
  rfp: Rfp;
  matches: Match[];
  handleActivationVerification: () => void;
  setRfp: (rfp: Rfp) => void;
  forceUpdate: () => void;
  handleSave: () => Promise<void>;
};

const RfpStatusDropdown = ({
  rfp,
  matches,
  handleActivationVerification,
  setRfp,
  forceUpdate,
  handleSave,
}: RfpStatusDropdownProps) => {
  const { t } = useTranslation();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const onRfpCompletion = () => {
    const matches_raise_hand = matches.filter(
      (m) =>
        !!m.raised_hand_at &&
        !m.purchased_at &&
        !m.shortlisted_at &&
        !m.raise_hand_rejected_at,
    );
    const emailed_providers = matches_raise_hand.map((p) => p.provider.name);

    if (
      [
        RfpStatus.Rejected,
        RfpStatus.Canceled,
        RfpStatus.PendingApproval,
      ].includes(rfp.status)
    ) {
      message.error("You can't go from Pending or Rejected to Completed...");
    } else {
      Modal.confirm({
        title: 'Are you sure you want to Complete this rfp ?',
        content: `${emailed_providers.slice(0, -1).join(', ')}${
          emailed_providers.length > 1 ? ' and ' : ''
        }${emailed_providers.slice(
          -1,
        )} will receive an email to notify them of the rfp's completion.`,
        okText: t('button.confirm'),
        cancelText: t('button.cancel'),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: '24px',
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: '24px',
          },
        },
        onOk() {
          return new Promise(async (resolve, reject) => {
            try {
              await api.completeRfp(rfp.rfp_id);

              const newRfp = rfp;
              newRfp.status = RfpStatus.Completed;
              setRfp(newRfp);

              forceUpdate();

              message.success('Rfp Completed!');
              resolve('Rfp Completed');
            } catch (e: any) {
              message.error('Something went wrong, try again later!');
              console.error(e);
              reject(e);
            }
          });
        },
      });
    }
  };
  const onRfpPending = () => {
    if ([RfpStatus.Completed].includes(rfp.status)) {
      message.error("You can't go from Completed to Pending...");
    } else {
      Modal.confirm({
        title: 'Are you sure you want to put this rfp at Pending ?',
        content:
          "Maybe it's preferrable that you Cancel the rfp instead. Do it only if you are absolutely sure of what you are doing.",
        okText: t('button.confirm'),
        cancelText: t('button.cancel'),
        okButtonProps: {
          style: {
            backgroundColor: theme.colors.purple_1,
            color: theme.colors.white_1,
            borderRadius: '24px',
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: theme.colors.white_1,
            borderColor: theme.colors.grey_1,
            color: theme.colors.grey_1,
            borderRadius: '24px',
          },
        },
        onOk() {
          onRfpStatusUpdate(RfpStatus.PendingApproval);
        },
      });
    }
  };

  const onRfpActivation = async () => {
    handleActivationVerification();
  };

  const onRfpStatusUpdate = async (newStatus: RfpStatus) => {
    try {
      await handleSave();
      const newRfp = await api.updateRfpStatus(rfp.rfp_id, newStatus);

      setRfp(newRfp);

      forceUpdate();

      message.success(`Rfp was set to ${newStatus}!`);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  const handleChange = (newStatus: RfpStatus) => {
    if (rfp.status !== newStatus) {
      switch (newStatus) {
        case RfpStatus.Canceled:
          dispatch(setSelectedRfp(rfp));
          dispatch(toggleRfpsModal({ isVisible: true, type: 'cancel' }));
          break;
        case RfpStatus.Rejected:
          dispatch(setSelectedRfp(rfp));
          break;
        case RfpStatus.Completed:
          onRfpCompletion();
          break;
        case RfpStatus.Active:
          onRfpActivation();
          break;
        case RfpStatus.PendingApproval:
          onRfpPending();
          break;
        default:
          if (!rfp.activated_at) {
            onRfpActivation();
          }
          onRfpStatusUpdate(newStatus);
          break;
      }
    }
  };

  return (
    <>
      <Select
        value={rfp.status}
        onChange={handleChange}
        style={{ width: 160 }}
        size="small"
      >
        {Object.keys(RfpStatus).map((status, i) => (
          <Select.Option value={status} key={`rfp-status-${i}`}>
            {t(`rfp|rfpStatus.${status}`)}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default RfpStatusDropdown;
