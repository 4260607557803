import {
  FormsGroup,
  FormsGroupsItem,
  FormsItems,
  FormsItemsTotal,
  MappedFormBuilderPage,
} from '../../../../features/entities/form-entities';
import { FormBuilderFunctions } from '../types';
import getItemTypeFromItemFieldType from '../utils/get-item-type-from-item-field-type';
import { findInfoByGroupId } from './group';

export const handleNewItem_DropInGroup_FromControls = async ({
  form_id,
  functions,
  form_group_id,
  field_type,
  item_order,
  formPages,
}: {
  form_id: string;
  form_group_id: string;
  field_type: string;
  item_order: number;
  formPages: MappedFormBuilderPage;
  functions: FormBuilderFunctions;
}) => {
  const [form_group_response, form_page_response] = findInfoByGroupId({
    form_group_id,
    pages: formPages,
  });

  const form_group: FormsGroup = {
    group_order: form_group_response?.group_order ?? 0,
    form_id,
    form_group_id,
    form_page_id: form_page_response?.form_page_id ?? '',
  };

  const form_item: FormsItems = {
    form_item_id: '',
    key: '',
    form_id: form_id,
    content_en: '',
    content_fr: '',
    type: getItemTypeFromItemFieldType(field_type),
    field_type,
  };

  const form_group_item: FormsGroupsItem = {
    forms_groups_items_id: '',
    form_group_id,
    form_item_id: '',
    forms_groups_items_order: item_order,
  };

  const newItem: FormsItemsTotal = {
    form_group_item,
    form_item,
    form_group,
  };

  functions.createItem(newItem);
};

export const handleReorderFormItems = ({
  form_group_id,
  form_item_id,
  functions,
  formPages,
  item_order,
}: {
  item_order: number;
  form_group_id: string;

  form_item_id: string;
  formPages: MappedFormBuilderPage;
  functions: FormBuilderFunctions;
}) => {
  const [group, _] = findInfoByGroupId({
    form_group_id,
    pages: formPages,
  });

  const items = [...(group?.items ?? [])];
  items.sort(
    (g1, g2) => g1.forms_groups_items_order - g2.forms_groups_items_order,
  );

  const movingItemId = items.findIndex(
    (item) => item.form_item_id === form_item_id,
  );

  const [removedItem] = items.splice(movingItemId, 1);
  items.splice(item_order, 0, removedItem);

  const formGroupsItems: FormsGroupsItem[] = [];
  items.forEach((item, index) => {
    formGroupsItems.push({
      form_group_id: group?.form_group_id ?? '',
      forms_groups_items_id: item.forms_groups_items_id,
      forms_groups_items_order: index,
      form_item_id: item.form_item_id,
    });
  });

  functions.updateFormItemOrder(formGroupsItems);
};
