export interface HdChat {
  id: string;
  template_id?: string;
  contact_id?: string;
  company_id?: string;
  title: string;
  created_at: string;
  updated_at: string;
  locale?: string;
  max_tokens?: number;
  model?: string;
  role?: string;
  questions: HdChatQuestion[];
}

export interface HdChatQuestion {
  id: string;
  prompt_id: string;
  prompt_name: string;
  chat_id: string;
  created_at?: string;
  updated_at?: string;
  content: string;
  answers: HdChatAnswer[];
}

export interface HdChatSubQuestion {
  id: string;
  answer_id: string;
  content: string;
  created_at?: string;
  updated_at?: string;
  answers: HdChatAnswer[];
}

export interface HdChatSubQuestionRequest {
  chat_id: string;
  answer_id: string;
  content: string;
}
export interface HdChatAnswer {
  id: string;
  created_at?: string;
  updated_at: string;
  answer: string;
  context: string;
  sub_questions: HdChatSubQuestion[];
}
export interface HdChatPromptRequest {
  id: string;
  type: ChatType;
  name: string;
  created_at: string;
  updated_at: string;
  content_fr: string;
  content_en: string;
}

export interface HdChatPrompt {
  id: string;
  type: ChatType;
  name: string;
  created_at?: string;
  updated_at?: string;
  content: string;
  title?: string;
}

export interface HdCreateChatRequest {
  company_id: string;
  company_name: string;
  template_id: string;
  grant_name: string;
  locale: string;
}

export interface HdCreateChatPromptRequest {
  type: ChatType;
  name: string;
  created_at?: string;
  updated_at?: string;
  content_fr: string;
  content_en: string;
}

export interface HdChatTemplate {
  id: string;
  created_at: string;
  updated_at: string;
  type: ChatType;
  name: string;
  grant_id?: string;
  content_fr: string;
  content_en: string;
  content: string;
  prompts: HdChatPrompt[];
  title?: string;
}

export interface HdCreateChatTemplateRequest {
  created_at?: string;
  updated_at?: string;
  type: ChatType;
  name: string;
  grant_id?: string;
  content_fr: string;
  content_en: string;
  prompts: HdChatPrompt[];
  title?: string;
}

export enum ChatType {
  All = 'all',
  Other = 'other',
  GrantApplication = 'grant_application',
  GrantContent = 'grant_content',
  Program = 'program',
  Project = 'project',
  GrantSearch = 'grant_search',
}

export interface ChatGptResponse {
  id: string;
  content: string;
}
