import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Popconfirm from 'antd/es/popconfirm';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import { FormBuilderGroupActionsProps } from '../../types';
import FormGroupEditButton from './form-builder-group-edit-button';

const FormBuilderGroupActions = ({
  formGroupId,
  functions,
  builderGroup,
  formPageId,
}: FormBuilderGroupActionsProps) => {
  const theme = useTheme();

  const handleDeleteFormGroup = () => {
    functions.deleteFormGroup(formGroupId, formPageId);
  };

  return (
    <Div flex="row" gap={8} justify="center" align="flex-end" fitContent>
      <FormGroupEditButton builderGroup={builderGroup} functions={functions} />
      <Popconfirm
        title="Delete the form Page"
        description="Are you sure to delete this group ? You will lose all the items in it"
        onConfirm={handleDeleteFormGroup}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <div>
          <Button
            size="circle"
            trailingIcon={<Delete size={16} />}
            defaultStyle={theme.colors.purple_2}
          />
        </div>
      </Popconfirm>
    </Div>
  );
};

export default FormBuilderGroupActions;
