import notification from 'antd/es/notification';

const handleDispatchResponse = (res: any, saved: string, error: string) => {
  if (res?.type?.includes('fulfilled')) {
    notification.success({
      message: saved,
      placement: 'top',
    });
  } else {
    notification.error({
      message: error,
      placement: 'top',
    });
  }
};

export default handleDispatchResponse;
