import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FundingExplorerFilterProps } from '..';
import { GrantProject } from '../../../../../features/entities/grants-entities';
import chunkArray from '../../../../../features/helpers/chunk-array';
import { useAppData } from '../../../../../features/providers/app-data-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Collapse from '../../../../common/Collapse';
import DynamicScroller from '../../../../common/dynamic-scroller';
import Typography from '../../../../common/typography';
import { FilterSectionContainterStyled } from '../styles';
import FilterProjectTag from './filter-project-tag';
import { FilterProjectContent } from './styles';

const FilterProjects: React.FC<FundingExplorerFilterProps> = ({
  bestProjects = [],
  filterValues,
  filterform,
  isLoading,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { navCollapsed } = useAppData();
  const [prevBubbleRows, setPrevBubbleRows] = useState<GrantProject[][]>([]);
  const bubbleRows = useMemo(
    () => chunkArray(bestProjects, Math.ceil(bestProjects?.length / 2)),
    [bestProjects],
  );

  const [isRowsLoading, setIsRowsLoading] = useState(isLoading);

  useEffect(() => {
    if (isLoading) {
      setIsRowsLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isEqual(bubbleRows, prevBubbleRows)) {
      setPrevBubbleRows(bubbleRows);
      setTimeout(() => setIsRowsLoading(false), 300);
    }
  }, [bubbleRows, isLoading]);

  const checkProjectIsActive = useCallback(
    (rfpId: string) => {
      return !!filterValues?.selectedProjects?.find((sp) => sp === rfpId);
    },
    [filterValues?.selectedProjects],
  );

  const handleProjectSelection = (rfpId: string) => {
    const newSelectedProjects = filterValues?.selectedProjects?.includes(rfpId)
      ? filterValues?.selectedProjects?.filter((id) => rfpId !== id)
      : [...(filterValues?.selectedProjects ?? []), rfpId];
    filterform.setFieldValue('selectedProjects', newSelectedProjects);
  };

  if (!prevBubbleRows?.length) return <></>;

  return (
    <FilterSectionContainterStyled
      $position="bottom"
      $navCollapsed={navCollapsed}
      style={{
        background: theme.colors.white_1,
        borderTop: `1px solid ${theme.colors.grey_4}`,
      }}
    >
      <Collapse
        ghost
        noPadding
        defaultActiveKey={'proposed-projects'}
        ExpandIcon={({ isActive }) => (
          <TabArrow
            down={!isActive}
            color={theme.colors.primary}
            style={{ cursor: 'pointer' }}
            size={20}
          />
        )}
        styles={{
          panelHeader: {
            padding: '8px 30px',
          },
          panelContainer: { width: '100%' },
        }}
        items={[
          {
            title: (
              <Typography elementTheme="overline">
                {t(`grants|fundingExplorerFilter.proposedProjects`)}
              </Typography>
            ),
            children: (
              <div style={{ height: 72 }}>
                <FilterProjectContent
                  className={isLoading || isRowsLoading ? 'fade-out' : ''}
                >
                  {React.Children.toArray(
                    prevBubbleRows.map((row, i) => (
                      <DynamicScroller speed={150} inversed={i % 2 === 0}>
                        {React.Children.toArray(
                          row.map((project) => (
                            <FilterProjectTag
                              project={project}
                              isActive={checkProjectIsActive(
                                project.grant_project_id,
                              )}
                              handleProjectSelection={handleProjectSelection}
                            />
                          )),
                        )}
                      </DynamicScroller>
                    )),
                  )}
                </FilterProjectContent>
              </div>
            ),
            id: 'proposed-projects',
          },
        ]}
      />
    </FilterSectionContainterStyled>
  );
};
export default FilterProjects;
