import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Company } from '@hellodarwin/core/lib/features/entities';
import Close from '@hellodarwin/icons/dist/icons/Close';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Button from 'antd/es/button';
import Input from 'antd/es/input/Input';
import { useEffect, useState } from 'react';
import { useAdminApi } from '../../features/api/use-admin-api';
import useDebounce from '../../hooks/use-debounce';
import theme from '../../theme';

type CompanySearchProps = {
  handleSubmit: (company: Company) => void;
};

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const CompaniesSearch = ({ handleSubmit }: CompanySearchProps) => {
  const api = useAdminApi();
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<Company[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      setIsSearching(true);
      try {
        const { results } = await api.queryCompanies(
          DEFAULT_PAGE,
          DEFAULT_LIMIT,
          query,
        );

        if (!results || !results.length) {
          setResults(undefined);
        } else {
          setResults(results);
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const clearSearch = () => {
    setSearchTerm('');
    setResults(undefined);
  };

  const handleResultClick = (company: Company) => {
    handleSubmit(company);
    clearSearch();
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <Div>
      <Div flex="row" gap={16}>
        <Div
          backgroundColor={theme.colors.purple_5}
          borderColor={theme.colors.purple_3}
          borderRadius={4}
          style={{ padding: '0.2rem 1rem' }}
          flex="row"
          align="center"
          justify="space-between"
        >
          <Search size={14} />
          <Input
            value={searchTerm}
            placeholder="Search... (company email, company name)"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              outline: 'none',
              border: 'none',
              background: theme.colors.transparent,
              width: '100%',
              boxShadow: 'none',
            }}
          />
          <Div fitContent>
            {isSearching ? (
              <Loading />
            ) : (
              searchTerm !== '' && (
                <Div onClick={clearSearch} style={{ cursor: 'pointer' }}>
                  <Close size={14} />
                </Div>
              )
            )}
          </Div>
        </Div>
      </Div>
      {!!results && (
        <Div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          {results?.map((c) => (
            <Div style={{ width: '100%' }}>
              <Button
                type="primary"
                onClick={() => handleResultClick(c)}
                key={c.company_id}
                style={{
                  width: '100%',
                  marginTop: 8,
                }}
              >
                {c.name}
              </Button>
            </Div>
          ))}
        </Div>
      )}
    </Div>
  );
};
export default CompaniesSearch;
