import Loading from '@hellodarwin/core/lib/components/loading';
import { GinApplicationCompany } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Empty from 'antd/es/empty';
import { useAppSelector } from '../../../../app';
import { selectGinsIsLoading } from '../../../../features/api/slices/gins-slice';
import CardLayout from './card-layout';

interface ApplicationCompanyCardProps {
  company: GinApplicationCompany;
}

const ApplicationCompanyCard = ({ company }: ApplicationCompanyCardProps) => {
  const { t } = useTranslations();

  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'applicationCompany'),
  );

  if (isLoading) return <Loading />;
  if (!company.company_id) return <Empty />;

  return (
    <CardLayout
      to={`/companies/${company.company_id}`}
      title={company.company_name}
      infos={[
        {
          Icon: Invoices,
          value: t('application_single|company_nb_applications', {
            number: company.company_applications.length,
          }),
        },
      ]}
    />
  );
};

export default ApplicationCompanyCard;
