import {
  AdminCategoryTag,
  AdminIndustryTag,
  AdminSelectedTags,
  AdminTag,
  AdminTags,
  CategoryTag,
  CategoryTagEntity,
  ServiceTag,
  SpecialtyTag,
  Tag,
  TagEntity,
} from '@hellodarwin/core/lib/features/entities';
import { createSelector } from '@reduxjs/toolkit';

export enum TagSubType {
  Service = 'services',
  Specialty = 'specialties',
}

export enum TagType {
  Category = 'categories',
  Industry = 'industries',
}

const getTagCategory = (service: string) => service.split(':')[1];

class TagAdapter {
  setAll = (tags: Tag[]) => {
    const mappedCategories: { [key: string]: CategoryTagEntity } = {};
    const mappedIndustries: { [key: string]: TagEntity } = {};

    tags.forEach((tag) => {
      const [type, category, subType, value] = tag.tag.split(':');

      if (type === TagType.Industry) {
        mappedIndustries[tag.tag] = { tag: tag.tag, isSelected: false };
      } else if (type === TagType.Category) {
        if (!mappedCategories[category]) {
          mappedCategories[category] = {
            tag: category,
            label: category,
            isSelected: false,
            services: {},
            specialties: {},
          };
        }

        if (subType === TagSubType.Service) {
          mappedCategories[category].services[tag.tag] = {
            category,
            tag: tag.tag,
            label: value,
            isSelected: false,
            min_budget: 0,
            max_budget: 0,
            description: '',
          };
        } else if (subType === TagSubType.Specialty) {
          mappedCategories[category].specialties[tag.tag] = {
            tag: tag.tag,
            label: value,
            isSelected: false,
          };
        }
      }
    });

    return { mappedCategories, mappedIndustries };
  };

  toggleRfpCategoryTag = (adminTags: AdminTags, category: string) => {
    Object.values(adminTags.mappedCategories).forEach((cat) => {
      cat.isSelected = false;
      Object.values(cat.services).forEach(
        (service) => (service.isSelected = false),
      );
      Object.values(cat.specialties).forEach(
        (specialty) => (specialty.isSelected = false),
      );
    });

    adminTags.mappedCategories[category].isSelected =
      !adminTags.mappedCategories[category].isSelected;
    return adminTags;
  };

  selectRfpServiceTag = (adminTags: AdminTags, service: string) => {
    const category = getTagCategory(service);

    Object.values(adminTags.mappedCategories[category].services).forEach(
      (srv) => (srv.isSelected = false),
    );
    adminTags.mappedCategories[category].services[service].isSelected = true;

    return adminTags;
  };

  deselectRfpServiceTag = (adminTags: AdminTags, service: string) => {
    const category = getTagCategory(service);

    adminTags.mappedCategories[category].services[service].isSelected = false;
    return adminTags;
  };

  toggleProviderCategoryTag = (adminTags: AdminTags, category: string) => {
    Object.values(adminTags.mappedCategories[category].services).forEach(
      (service) => (service.isSelected = false),
    );
    Object.values(adminTags.mappedCategories[category].specialties).forEach(
      (specialty) => (specialty.isSelected = false),
    );

    adminTags.mappedCategories[category].isSelected =
      !adminTags.mappedCategories[category].isSelected;
    return adminTags;
  };

  selectProviderServiceTag = (adminTags: AdminTags, service: string) => {
    const category = getTagCategory(service);

    adminTags.mappedCategories[category].services[service].isSelected =
      !adminTags.mappedCategories[category].services[service].isSelected;
    return adminTags;
  };

  deselectProviderServiceTag = (adminTags: AdminTags, service: string) => {
    const category = getTagCategory(service);

    adminTags.mappedCategories[category].services[service].isSelected = false;
    return adminTags;
  };

  updateAll = (stateTags: AdminTags, tags: AdminTag[]) => {
    tags.forEach((tag) => {
      const [type, category, subType] = tag.tag.split(':');

      if (type === TagType.Industry && stateTags.mappedIndustries[tag.tag]) {
        stateTags.mappedIndustries[tag.tag].isSelected = true;
      } else if (
        type === TagType.Category &&
        stateTags.mappedCategories[category]
      ) {
        stateTags.mappedCategories[category].isSelected = true;

        if (subType === TagSubType.Service) {
          const service =
            stateTags.mappedCategories[category].services[tag.tag];
          if (service) {
            service.min_budget = tag.min_budget;
            service.max_budget = tag.max_budget;
            service.isSelected = true;
          }
        } else if (subType === TagSubType.Specialty) {
          const specialty =
            stateTags.mappedCategories[category].specialties[tag.tag];
          if (specialty) {
            specialty.isSelected = true;
          }
        }
      }
    });

    return stateTags;
  };

  toggleSpecialtyTag = (adminTags: AdminTags, specialty: string) => {
    const category = getTagCategory(specialty);

    adminTags.mappedCategories[category].specialties[specialty].isSelected =
      !adminTags.mappedCategories[category].specialties[specialty].isSelected;
    return adminTags;
  };

  toggleIndustryTag = (adminTags: AdminTags, industry: string) => {
    if (adminTags.mappedIndustries[industry].isSelected) {
      adminTags.mappedIndustries[industry].isSelected = false;
    } else {
      Object.values(adminTags.mappedIndustries).forEach(
        (ind) => (ind.isSelected = false),
      );
      adminTags.mappedIndustries[industry].isSelected = true;
    }
    return adminTags;
  };

  handleServiceBudgetChange = (
    tags: AdminTags,
    service: string,
    minBudget: number,
    maxBudget: number,
  ) => {
    const category = getTagCategory(service);

    const serviceTag = tags.mappedCategories[category].services[service];
    serviceTag.min_budget = minBudget;
    serviceTag.max_budget = maxBudget;

    return tags;
  };

  selectTags = createSelector(
    (tags: AdminTags) => tags,
    (tags): AdminSelectedTags => {
      const categories: AdminCategoryTag[] = [];
      const industries: AdminIndustryTag[] = [];
      const selectedCategories: CategoryTag[] = [];
      const selectedServices: ServiceTag[] = [];
      const selectedSpecialties: SpecialtyTag[] = [];
      let selectedIndustry = '';

      Object.values(tags.mappedIndustries).forEach((tag) => {
        const label = tag.tag.split(':')[1];
        industries.push({ label, tag: tag.tag });

        if (tag.isSelected) {
          selectedIndustry = tag.tag;
        }
      });

      Object.values(tags.mappedCategories).forEach((category) => {
        const services = Object.values(category.services);
        const specialties = Object.values(category.specialties);

        categories.push({
          label: category.label,
          isSelected: category.isSelected,
          services,
          specialties,
        });

        if (category.isSelected) {
          selectedCategories.push({
            label: category.label,
            tag: category.tag,
            isSelected: category.isSelected,
            services,
            specialties,
          });

          services.forEach((service) => {
            if (service.isSelected) {
              selectedServices.push({ ...service, category: category.label });
            }
          });

          specialties.forEach((specialty) => {
            if (specialty.isSelected) {
              selectedSpecialties.push({
                ...specialty,
                category: category.label,
              });
            }
          });
        }
      });

      return {
        categories,
        selectedServices,
        selectedSpecialties,
        selectedCategories,
        industries,
        selectedIndustry,
      };
    },
  );

  getInitialState = () => ({
    mappedCategories: {} as { [key: string]: CategoryTagEntity },
    mappedIndustries: {} as { [key: string]: TagEntity },
  });
}

export const createTagAdapter = () => new TagAdapter();
