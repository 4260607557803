import Div from '@hellodarwin/core/lib/components/common/div';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import message from 'antd/es/message';
import Select, { SelectProps } from 'antd/es/select';
import Typography from 'antd/es/typography/Typography';
import React, { useEffect, useState } from 'react';
import {
  selectGrantById,
  updateGrant,
} from '../../features/api/slices/grants-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { useAppDispatch, useAppSelector } from '../../app';

interface GrantChangeDifficultyProps {
  grantId: string;
}

const GrantChangeDifficulty: React.FC<GrantChangeDifficultyProps> = ({
  grantId,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const grant = useAppSelector((state) => selectGrantById(state, grantId));
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>('');
  const { t } = useTranslations();
  const difficultyOptions: SelectProps['options'] = [
    { label: '', value: '' },
    { label: t('programs_admin|easy'), value: 'easy' },
    { label: t('programs_admin|medium'), value: 'medium' },
    { label: t('programs_admin|difficult'), value: 'difficult' },
  ];

  useEffect(() => {
    if (grant) {
      setSelectedDifficulty(grant?.difficulty || '');
    }
  }, [grant]);
  const handleDifficultyChange = async (newValue: string) => {
    if (!grant) return;

    try {
      const updatedGrant = { ...grant, difficulty: newValue };

      await dispatch(updateGrant({ api, grant: updatedGrant }));

      setSelectedDifficulty(newValue);
      message.success(t('createProject|saved'));
    } catch (error) {
      message.error(t('Oops!'));
    }
  };

  return (
    <Div flex="column" align="center" gap={8} style={{ width: 'auto' }}>
      <Div key={grantId} flex="row" align="center" gap={8} fitContent>
        <Typography>{t('programs_admin|difficulty')}: </Typography>
        <Select
          value={selectedDifficulty}
          style={{ width: 120 }}
          options={difficultyOptions}
          onChange={handleDifficultyChange}
        />
      </Div>
    </Div>
  );
};

export default GrantChangeDifficulty;
