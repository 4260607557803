import {
  FundingExplorerListPageLockedContainer,
  FundingExplorerListPageLockedWrapper,
} from './styles';
import FundingExplorerListLockedWarning from './warning';

interface FundingExplorerListLockedProps {
  isLocked?: boolean;
  goToSettings: () => void;
}
const FundingExplorerListLocked = ({
  isLocked,
  goToSettings,
}: FundingExplorerListLockedProps) => {
  if (!isLocked) return null;

  return (
    <FundingExplorerListPageLockedContainer>
      <FundingExplorerListPageLockedWrapper />
      <FundingExplorerListLockedWarning goToSettings={goToSettings} />
    </FundingExplorerListPageLockedContainer>
  );
};

export default FundingExplorerListLocked;
