import Button from '@hellodarwin/core/lib/components/common/button';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import {
  GrantProvider,
  GrantProviders,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Col from 'antd/es/col';
import message from 'antd/es/message/index';
import Row from 'antd/es/row';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/app-hooks';
import GrantProviderForm from '../../components/grants/grant-provider-form';
import { createGrantProvider } from '../../features/api/slices/grants-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { AdminPagesForms } from '../single-rfp-page';

const CreateGrantProviderPage = () => {
  const api = useAdminApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [grantProvider, setGrantProvider] = useState<GrantProvider[]>([
    {
      grant_provider_id: '',
    },
    {
      grant_provider_id: '',
    },
  ]);
  const { t } = useTranslations();
  const [forms, setForms] = useState<AdminPagesForms>({
    grantProviderForm: undefined,
  });

  const onSave = async () => {
    try {
      const grantProviderFormValues =
        forms.grantProviderForm?.getFieldsValue()!;

      if (grantProviderFormValues === undefined) {
        return;
      }

      const newGrantProvider: GrantProvider[] = [
        {
          grant_provider_id: grantProvider[0].grant_provider_id,
          locale: 'en',
          title: grantProviderFormValues.title_en,
          description: grantProviderFormValues.description_en,
          slug: grantProviderFormValues.slug_en,
          logo: grantProvider[0].logo,
          rank: grantProviderFormValues.rank,
          url: grantProviderFormValues.url_en,
          acronym: grantProviderFormValues.acronym_en,
          long_description: grantProviderFormValues.long_description_en,
        },
        {
          grant_provider_id: grantProvider[1].grant_provider_id,
          locale: 'fr',
          title: grantProviderFormValues.title_fr,
          slug: grantProviderFormValues.slug_fr,
          description: grantProviderFormValues.description_fr,
          logo: grantProvider[1].logo,
          rank: grantProviderFormValues.rank,
          url: grantProviderFormValues.url_en,
          acronym: grantProviderFormValues.acronym_fr,
          long_description: grantProviderFormValues.long_description_fr,
        },
      ];

      const updatedGrantProvider: GrantProvider[] = {
        ...grantProvider,
        ...newGrantProvider,
      };

      const sendGrantProvider: GrantProviders = {
        grant_provider_en: updatedGrantProvider[0],
        grant_provider_fr: updatedGrantProvider[1],
      };

      const grantProviderResponse = (await dispatch(
        createGrantProvider({ api, grantProvider: sendGrantProvider }),
      ).unwrap()) as unknown as GrantProviders;

      if ('grant_provider_id' in grantProviderResponse.grant_provider_en) {
        message.success(t('create_program|createdProgramProvider'));
        setGrantProvider([
          {
            grant_provider_id: '',
          },
          {
            grant_provider_id: '',
          },
        ]);
        forms.grantProviderForm?.resetFields();
        navigate(
          '/grants/providers/' +
            grantProviderResponse.grant_provider_en.grant_provider_id,
        );
      }
    } catch (e: any) {
      if (e?.error_code === 28030) {
        message.error('There is already a grant provider with the same title');
      } else if (e?.error_code === 28031) {
        message.error('There is already a grant provider with the same slug');
      } else {
        message.error(t('create_program|messageError'));
      }
      console.error(e);
    }
  };

  const goToGrantsProviders = () => {
    navigate('/grants/providers');
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={t('create_program|createProgramProvider')}
      title={t('create_program|createProgramProvider')}
      actions={
        <Button onClick={onSave} size="small">
          {t('create_program|createProgramProvider')}
        </Button>
      }
      handleBack={goToGrantsProviders}
      breadcrumbs={[
        {
          breadcrumbName: t('create_program|programsProviders'),
          path: '/grants/providers',
        },
        {
          breadcrumbName: t('create_program|createProgramProvider'),
        },
      ]}
    >
      <Row gutter={24}>
        <Col span={24}>
          <GrantProviderForm
            grantProvider={grantProvider}
            setForms={setForms}
          ></GrantProviderForm>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default CreateGrantProviderPage;
