import { CSSProperties, MouseEvent, useMemo } from 'react';
import { ButtonProps, ViewButtonProps } from '.';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { usePlatform } from '../../../features/providers/platform-provider';
import { useTheme } from '../../../plugins/styled';
import { ButtonContainerProps } from './styles';

const useButtonProps = (props: ButtonProps) => {
  const theme = useTheme();
  const isXL = useMediaQuery({ maxWidth: theme.breakpoints.l });
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const { app } = usePlatform();
  const defaultButtonSize = app !== 'website' ? 'small' : 'medium';

  return useMemo(() => {
    const currentProps: ButtonContainerProps = {};
    const currentStyle: CSSProperties = {};

    const addProp = (property: string, value: any) => {
      if (value !== undefined) {
        (currentProps as any)[property] = value;
      }
    };

    const addStyling = (property: keyof CSSProperties, value: any) => {
      if (value !== undefined) {
        currentStyle[property] = value;
      }
    };

    const prepareStyling = (style: CSSProperties) => {
      if (style) {
        Object.entries(style).forEach(([key, value]) => {
          addStyling(key as keyof CSSProperties, value);
        });
      }
    };

    const prepareProps = (data: ViewButtonProps) => {
      if (data) {
        const {
          fitContent,
          fullWidth,
          isLink,
          transparent,
          textWrap,
          defaultStyle,

          hidden,
          style,
        } = data;
        if (!!style) {
          prepareStyling(style);
        }

        const propMappings = {
          $fitContent: fitContent,
          $isTransparent: transparent,
          $isLink: isLink,
          $fullWidth: fullWidth,
          $wrap: textWrap,
          $hidden: hidden,
          $defaultTheme: defaultStyle,
        };

        Object.entries(propMappings).forEach(([key, value]) => {
          addProp(key as keyof ButtonContainerProps, value);
        });
      }
    };

    const handleClick = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
      const defaultClick = () => !!props.onClick && props.onClick(e);

      if (isXL) {
        !!props?.xl?.onClick ? props.xl.onClick(e) : defaultClick();
      } else if (isTablet) {
        !!props?.tablet?.onClick ? props.tablet.onClick(e) : defaultClick();
      } else {
        defaultClick();
      }
    };

    const {
      children,
      id,
      className,
      headingIcon,
      trailingIcon,
      withArrowLeft,
      withArrowRight,
      size,
      loading,
      disabled,
      xl,
      tablet,
      onMouseEnter,
      onMouseLeave,
      onPointerEnter,
      onPointerLeave,
      ...buttonProps
    } = props;

    const defaultProps = {
      children,
      id,
      className,
      headingIcon,
      trailingIcon,
      withArrowLeft,
      withArrowRight,
      disabled,
      size: !!size ? size : defaultButtonSize,
      loading,
      handleClick,
      onMouseEnter,
      onMouseLeave,
      onPointerEnter,
      onPointerLeave,
    };

    prepareProps(buttonProps);
    if (isXL && !!xl) prepareProps(xl);
    if (isTablet && !!tablet) prepareProps(tablet);

    return { currentProps, currentStyle, defaultProps };
  }, [isTablet, isXL, props, app]);
};
export default useButtonProps;
