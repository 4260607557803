import Edit from '@hellodarwin/icons/dist/icons/Edit';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Tooltip from 'antd/es/tooltip';
import { EditableFormSubmissionResponse } from '../../../../features/entities/form-entities';
import getShortId from '../../../../features/helpers/get-short-id';
import useLocale from '../../../../features/providers/locale-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import getItemTranslatedLabel from '../../form-builder/utils/get-item-translated-label';
import { FormEditorMode } from '../types';

interface FormSubmissionHeaderProps {
  onFinish: () => void;
  formSubmission: EditableFormSubmissionResponse;
  hasChanged: boolean;
  handleMode: () => void;
  mode: FormEditorMode;
}
const FormSubmissionHeader = ({
  onFinish,
  formSubmission: { form },
  hasChanged,
  mode,
  handleMode,
}: FormSubmissionHeaderProps) => {
  const theme = useTheme();

  const { selectedLocale } = useLocale();
  return (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      gap={8}
      borderColor={theme.colors.grey_5}
      style={{
        position: 'sticky',
        top: theme.appearance.appHeaderHeight,
        boxShadow: theme.appearance.box_shadow_hover,
        padding: 24,
        zIndex: 200,
        backdropFilter: 'blur(4px)',
      }}
      backgroundColor={theme.colors.white_2}
    >
      <div>
        <Typography elementTheme="h6">
          {getItemTranslatedLabel({
            label_en: form.entity.subtitle_en,
            label_fr: form.entity.subtitle_fr,
            fallback: getShortId(form.entity.form_id),
            parseOptions: {},
            locale: selectedLocale,
          })}
        </Typography>
        <Typography elementTheme="body2">
          {getItemTranslatedLabel({
            label_en: form.entity.subtitle_en,
            label_fr: form.entity.subtitle_fr,
            fallback: getShortId(form.entity.form_id),
            parseOptions: {},
            locale: selectedLocale,
          })}
        </Typography>
      </div>
      <Div flex="row" gap={8} fitContent>
        {mode === 'edit' && (
          <Tooltip title={'Save'}>
            <div>
              <Button
                size="square"
                onClick={onFinish}
                headingIcon={<Save width={20} height={20} />}
                defaultStyle={theme.colors.primary}
                disabled={!hasChanged}
              />
            </div>
          </Tooltip>
        )}

        <Tooltip title={mode === 'edit' ? 'See as List' : 'Edit'}>
          <div>
            <Button
              size="square"
              onClick={handleMode}
              headingIcon={
                mode === 'edit' ? (
                  <ProjectsList width={20} height={20} />
                ) : (
                  <Edit width={20} height={20} />
                )
              }
              defaultStyle={theme.colors.primary}
            />
          </div>
        </Tooltip>
      </Div>
    </Div>
  );
};
export default FormSubmissionHeader;
