enum SingleGrantSections {
  Summary = 'summary',
  Preview = 'preview',
  TermsAndConditions = 'terms_and_conditions',
  Admissibility = 'admissibility',
  Criteria = 'criteria',
  Register = 'register',
  OtherInfo = 'otherInfo',
  Documents = 'documents',
  Contact = 'contact',
  Projects = 'projects',
}

export default SingleGrantSections;
