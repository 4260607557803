import {
  ApiError,
  ApiErrorInitialState,
  ChatType,
  HdChatPromptRequest,
  HdCreateChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const promptsAdapter = createEntityAdapter({
  selectId: (model: HdChatPromptRequest) => model.id,
  sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

export interface PromptsState {
  status: 'idle' | 'pending';
  error: ApiError;
  prompts: EntityState<HdChatPromptRequest, string>;
  pagination: {
    page: number;
    size: number;
    total: number;
  };
}

const initialState: PromptsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  prompts: promptsAdapter.getInitialState(),
  pagination: { page: 1, size: 10, total: 0 },
};

export const fetchAllPrompts = createAsyncThunk<
  { prompts: HdChatPromptRequest[]; total: number },
  { api: AdminApiClient; page: number; size: number; type: ChatType },
  { rejectValue: ApiError }
>(
  'admin/fetchAllPrompts',
  async (
    {
      api,
      page,
      size,
      type,
    }: { api: AdminApiClient; page: number; size: number; type: ChatType },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchAllPrompts(page, size, type);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAllPromptsForGrants = createAsyncThunk<
  HdChatPromptRequest[],
  { api: AdminApiClient },
  { rejectValue: ApiError }
>(
  'admin/fetchAllPromptsForGrants',
  async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchAllPromptsForGrants();
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createNewPrompt = createAsyncThunk<
  HdChatPromptRequest,
  { api: AdminApiClient; prompt: HdCreateChatPromptRequest },
  { rejectValue: ApiError }
>(
  'admin/createNewPrompt',
  async (
    { api, prompt }: { api: AdminApiClient; prompt: HdCreateChatPromptRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.createChatPrompt(prompt);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePrompt = createAsyncThunk<
  HdChatPromptRequest,
  { api: AdminApiClient; prompt: HdChatPromptRequest },
  { rejectValue: ApiError }
>(
  'admin/updatePrompt',
  async (
    { api, prompt }: { api: AdminApiClient; prompt: HdChatPromptRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateChatPrompt(prompt);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePrompt = createAsyncThunk<
  string,
  { api: AdminApiClient; promptId: string },
  { rejectValue: ApiError }
>(
  'admin/deletePrompt',
  async (
    { api, promptId }: { api: AdminApiClient; promptId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteChatPrompt(promptId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const promptsSlice = createSlice({
  name: 'prompts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPrompts.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllPrompts.fulfilled, (state, { payload }) => {
      if (payload && payload.prompts) {
        promptsAdapter.setAll(state.prompts, payload.prompts);
        state.pagination.total = payload.total;
      } else {
        promptsAdapter.removeAll(state.prompts);
        state.pagination = { page: 1, size: 10, total: 0 };
      }
      state.status = 'idle';
    });
    builder.addCase(fetchAllPrompts.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchAllPromptsForGrants.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchAllPromptsForGrants.fulfilled,
      (state, { payload }) => {
        if (payload) {
          promptsAdapter.setAll(state.prompts, payload);
          state.status = 'idle';
        }
      },
    );
    builder.addCase(fetchAllPromptsForGrants.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createNewPrompt.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createNewPrompt.fulfilled, (state, { payload }) => {
      promptsAdapter.upsertOne(state.prompts, payload);
      state.status = 'idle';
    });
    builder.addCase(createNewPrompt.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updatePrompt.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updatePrompt.fulfilled, (state, { payload }) => {
      promptsAdapter.upsertOne(state.prompts, payload);
      state.status = 'idle';
    });
    builder.addCase(updatePrompt.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(deletePrompt.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deletePrompt.fulfilled, (state, { payload }) => {
      promptsAdapter.removeOne(state.prompts, payload);
      state.status = 'idle';
    });
    builder.addCase(deletePrompt.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const { selectAll: selectAllPrompts, selectById: selectPromptById } =
  promptsAdapter.getSelectors(
    (state: RootState) => state.hdChatPrompts.prompts,
  );

export const selectPromptsLoading = (state: RootState) =>
  state.hdChatPrompts.status === 'pending';

export const selectPromptsPagination = (state: RootState) =>
  state.hdChatPrompts.pagination;

export const promptsReducer = promptsSlice.reducer;
