import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Provider } from '@hellodarwin/core/lib/features/entities';
import {
  dollarFormat,
  getPriceInDollars,
} from '@hellodarwin/core/lib/features/helpers';
import Button from 'antd/es/button';
import { Col, Row } from 'antd/es/grid';
import Spin from 'antd/es/spin';
import Tag from 'antd/es/tag';
import { useAdminApi } from '../../features/api/use-admin-api';
import { getStatusColor } from '../../features/utils';
import SubscriptionForm from './subscription-form';

import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import './subscription-preview.scss';

const { Title } = Typography;

type SubscriptionPreviewProps = {
  provider: Provider;
  creditBalance: number;
  loading: boolean;
};

const SubscriptionPreview = ({
  provider,
  creditBalance,
  loading,
}: SubscriptionPreviewProps) => {
  const api = useAdminApi();
  const { t } = useTranslations();
  const openSession = () => {
    (async () => {
      try {
        const session = await api.createBillingSession(
          provider.billing_customer_id || '',
        );
        window.open(session.url, '_blank');
      } catch (e: any) {
        console.error(e);
      }
    })();
  };

  const preview = (
    <>
      <div className={`subscription-preview`}>
        {loading ? (
          <Spin />
        ) : (
          <Typography elementTheme={'body3'}>
            {t('provider_form|creditBalance')}: $
            {dollarFormat(Math.abs(getPriceInDollars(creditBalance)), 'en')}
          </Typography>
        )}
        <div>
          <Tag color={getStatusColor(provider?.subscription_status!)}>
            {provider.subscription_status}
          </Tag>
        </div>
        <div>
          <Title level={4} elementTheme="h6">
            {provider.subscription_plan_name}{' '}
          </Title>
          <p>
            ${getPriceInDollars(provider?.subscription_plan_price!)} per{' '}
            {provider.subscription_plan_interval_count}{' '}
            {provider.subscription_plan_interval}
          </p>
        </div>
        <Row>
          <Col span={24}>
            <Button
              block
              type="link"
              onClick={() => openSession()}
              size="small"
            >
              Manage Billing
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      {!provider.subscription_status ||
      provider.subscription_status === 'Canceled' ? (
        <SubscriptionForm
          billingId={provider.billing_customer_id || ''}
          companyId={provider.company_id}
          creditBalance={creditBalance}
        />
      ) : (
        preview
      )}
    </>
  );
};

export default SubscriptionPreview;
