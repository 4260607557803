import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import React from 'react';

interface GinSidebarMenuProps {
  menu_items: string[];
}

const GinSidebarMenu = ({ menu_items }: GinSidebarMenuProps) => {
  const theme = useTheme();
  const { t } = useTranslations();

  return (
    <Div flex="column" gap={8}>
      {React.Children.toArray(
        menu_items.map((itm) => (
          <a
            href={`#${itm}`}
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <Typography
              overflow
              ellipsis
              color={theme.colors.grey_2}
              elementTheme="body3"
            >
              {t(`gin_section|${itm}`)}
            </Typography>
          </a>
        )),
      )}
    </Div>
  );
};

export default GinSidebarMenu;
