import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Form, { FormInstance } from 'antd/es/form';
import React, { ReactNode } from 'react';
import { FormItemEditInput, FormItemEditInputRender } from '.';
import { useTheme } from '../../../../../../plugins/styled';
import Button from '../../../../../common/button';
import Div from '../../../../../common/div';
import FormItem from '../../../../form-layouts/form-item';
import FormLabel from '../../../../form-layouts/form-label';

interface renderInputProps<T> {
  input: FormItemEditInput<T>;
  activeItem: T;
  deleteItem?: (deletedItem: T) => void;
  form: FormInstance<T>;
}
const renderInput = <T,>({
  input,
  activeItem,
  deleteItem,
  form,
}: renderInputProps<T>) => {
  const theme = useTheme();
  const isArray = Array.isArray(activeItem[input.name]);
  const isNode = React.isValidElement(input.children);

  const addRowToArray = (itm: FormItemEditInput<T>) => {
    const value = form.getFieldValue(itm.name as any);

    form.setFieldValue(itm.name as any, [...value, '']);
  };

  const removeRowFromArray = (itm: FormItemEditInput<T>, index: number) => {
    const value = form.getFieldValue(itm.name as any);

    delete value[index];
    form.setFieldValue(itm.name as any, value);
  };

  return (
    <FormItem
      name={input.name}
      label={
        <FormLabel
          label={input.label}
          actions={
            <>
              {input.actions}
              {isArray &&
              (isNode ? (
                <Button
                  transparent
                  defaultStyle={theme.colors.primary}
                  onClick={() => addRowToArray(input)}
                >
                  <AddNew size={16} />
                </Button>
              ) : (
                !!(input.children as FormItemEditInputRender<T>)?.addItm
              )) ? (
                <Button
                  transparent
                  defaultStyle={theme.colors.primary}
                  onClick={() =>
                    (input.children as FormItemEditInputRender<T>).addItm()
                  }
                >
                  <AddNew size={16} />
                </Button>
              ) : undefined}
            </>
          }
        />
      }
      rules={input.rules}
    >
      {React.isValidElement(input.children) ? (
        isArray ? (
          <Form.List name={input.name}>
            {(fields) => {
              return (
                <div>
                  {React.Children.toArray(
                    fields.map((field, i) => (
                      <Div flex="row" gap={8} align="center">
                        <FormItem
                          style={{ margin: 0, width: '100%' }}
                          {...field}
                          fieldId={'trigger_value'}
                        >
                          {input.children as unknown as ReactNode}
                        </FormItem>
                        {!!deleteItem && (
                          <Button
                            transparent
                            defaultStyle={theme.colors.primary}
                            onClick={() => removeRowFromArray(input, i)}
                          >
                            <Delete size={16} />
                          </Button>
                        )}
                      </Div>
                    )),
                  )}
                </div>
              );
            }}
          </Form.List>
        ) : (
          input.children
        )
      ) : (
        (input.children as FormItemEditInputRender<T>).render(activeItem)
      )}
    </FormItem>
  );
};
export default renderInput;
