import Dropdown from '@hellodarwin/core/lib/components/common/dropdown';
import { Company } from '@hellodarwin/core/lib/features/entities';
import {
  getStringDate,
  prefixHTTPS,
} from '@hellodarwin/core/lib/features/helpers';
import formatPhoneNumber from '@hellodarwin/core/lib/features/helpers/format-phone';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { usePagination } from '@hellodarwin/core/lib/features/providers/pagination-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { ItemType } from 'antd/es/menu/interface';
import { PaginationConfig } from 'antd/es/pagination';
import { TableProps } from 'antd/es/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompaniesTable } from './styles';

interface CompaniesListProps {
  companies: Company[];
  total: number;
  isLoading?: boolean;
}

const CompaniesList = ({ companies, total, isLoading }: CompaniesListProps) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const navigate = useNavigate();

  const columns: TableProps<Company>['columns'] = useMemo(() => {
    const columns: TableProps<Company>['columns'] = [
      {
        title: t('company|companyName'),
        dataIndex: 'name',
        key: 'name',
        width: 300,
        fixed: 'left',
      },
      {
        title: t('company|companyEmail'),
        dataIndex: 'email',
        key: 'email',
        render: (v) => {
          if (!v?.length) return '';
          return (
            <a href={`mailto:${v}`} target="_blank" rel="noreferrer">
              {v}
            </a>
          );
        },
        width: 300,
      },
      {
        title: t('company|clientSince'),
        dataIndex: 'created_at',
        key: 'client_since',
        render: (v) => getStringDate(v, selectedLocale),
        width: 200,
      },
      {
        title: t('company|phone'),
        dataIndex: 'phone',
        key: 'phone',
        render: (v) => {
          if (!v?.length) return '';
          const formatted = formatPhoneNumber(v);

          if (formatted === 'Invalid phone number') {
            return `${formatted} : ${v}`;
          }
          return (
            <a href={`tel:${v}`} target="_blank" rel="noreferrer">
              {formatted}
            </a>
          );
        },
        width: 220,
      },
      {
        title: t('company|companySize'),
        dataIndex: 'size',
        key: 'size',
        render: (size) =>
          !!size?.length ? t(`company|companySizeValue`, { size }) : '',
        width: 170,
      },
      {
        title: t('company|website'),
        dataIndex: 'website',
        key: 'website',
        render: (v) => {
          if (!v?.length) return '';
          const website = prefixHTTPS(v).toLowerCase();
          return (
            <a href={website} target="_blank" rel="noreferrer">
              {website}
            </a>
          );
        },
        width: 250,
      },
      {
        key: 'actions',
        width: 32,
        className: 'actions-column',
        fixed: 'right',
        render: (_, record) => {
          const items: ItemType[] = [
            {
              label: t('company|openCompanyPage', {
                company_name: record.name,
              }),
              key: 'open',
              onClick: () => navigate(`/companies/${record.company_id}`),
            },
            {
              label: t('company|goToCompanyHubspot', {
                company_name: record.name,
              }),
              key: 'open',
              disabled: !record.hubspot_url?.length,
              onClick: () => window.open(`${record.hubspot_url}`, '_blank'),
            },
          ];
          return <Dropdown items={items} cta={{ size: 24 }} />;
        },
      },
    ];

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, selectedLocale]);

  const { goToPage, pageSize, activePage } = usePagination();

  const handlePageChange: PaginationConfig['onChange'] = (page, pageSize) => {
    goToPage(page, pageSize);
  };

  return (
    <CompaniesTable
      dataSource={companies}
      columns={columns}
      onRow={(record) => {
        return {
          onDoubleClick: () => {
            navigate(`/companies/${record.company_id}`);
          },
        };
      }}
      loading={isLoading}
      scroll={{ x: 'max-content' }}
      pagination={{
        showSizeChanger: true,
        pageSize,
        current: activePage,
        total,
        align: 'center',
        onChange: handlePageChange,
      }}
    />
  );
};

export default CompaniesList;
