import Div from '@hellodarwin/core/lib/components/common/div';
import SeeMoreTags from '@hellodarwin/core/lib/components/common/see-more/see-more-tags';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { GrantTag } from '@hellodarwin/core/lib/features/entities';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Refresh from '@hellodarwin/icons/dist/icons/Refresh';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Popconfirm from 'antd/es/popconfirm';
import Select, { SelectProps } from 'antd/es/select';
import Skeleton from 'antd/es/skeleton';
import Tag from 'antd/es/tag';
import dayjs from 'dayjs';
import { Suspense, lazy, useEffect, useMemo } from 'react';
import { useAppSelector } from '../../app';
import { selectIsLoadingSoloGen } from '../../features/api/slices/grants-slice';
import theme from '../../theme';
import { InputNumberDollars } from '../rfp/rfp-form';

const DatePicker = lazy(() => import('antd/es/date-picker'));
type TagRender = SelectProps['tagRender'];

type GrantPromptPropertySectionProps = {
  form: any;
  section: string;
  isDollarInput?: boolean;
  isMultipleSelect?: boolean;
  isTags?: boolean;
  isSimpleSelect?: boolean;
  isDateInput?: boolean;
  isNumberInput?: boolean;
  label: string;
  onMouseDown?: (e: React.MouseEvent) => void;
  industrySubsectors?: SelectProps['options'];
  fetchIndustrySubsectors?: () => void;
  fetchRegionMRCS?: () => void;
  setRegion?: (region_code: number) => void;
  onSave?: () => void;
  disabled?: boolean;
  margin?: string;
  grantsProviders?: SelectProps['options'];
  financingType?: SelectProps['options'];
  industries?: SelectProps['options'];
  service?: SelectProps['options'];
  audience?: SelectProps['options'];
  tags?: GrantTag[];
  openTagDrawer?: () => void;
  generateTags?: () => void;
  deleteAllTags?: () => void;
  provinces?: SelectProps['options'];
  regions?: SelectProps['options'];
  mrcs?: SelectProps['options'];
  difficulty?: SelectProps['options'];
  forProfitTypes?: SelectProps['options'];
};

const GrantPromptPropertySection = ({
  form,
  section,
  isDollarInput,
  isMultipleSelect,
  isTags,
  isSimpleSelect,
  isDateInput,
  isNumberInput,
  label,
  onMouseDown,
  industrySubsectors,
  fetchIndustrySubsectors,
  fetchRegionMRCS,
  onSave,
  disabled,
  margin,
  grantsProviders,
  financingType,
  industries,
  service,
  audience,
  tags,
  openTagDrawer,
  generateTags,
  deleteAllTags,
  provinces,
  regions,
  mrcs,
  difficulty,
  forProfitTypes,
}: GrantPromptPropertySectionProps) => {
  const isLoading = useAppSelector((state) =>
    selectIsLoadingSoloGen(state, section),
  );
  useEffect(() => {
    if (section === 'industry_subsectors' && industrySubsectors) {
      const industrySubsectorsForm = form.getFieldValue('industry_subsectors');
      if (industrySubsectorsForm) {
        const industrySubsectorsFiltered = industrySubsectorsForm.filter(
          (industrySubsector: string) => {
            return industrySubsectors.some(
              (industrySubsectorOption) =>
                industrySubsectorOption.value === industrySubsector,
            );
          },
        );
        form.setFieldValue('industry_subsectors', industrySubsectorsFiltered);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industrySubsectors]);

  const SkeletonTextArea = () => (
    <Form.Item label={label} name={section} style={{ margin: margin }}>
      <Skeleton.Input
        size="small"
        style={{
          backgroundColor: theme.colors.white_1,
          width: '100%',
        }}
        block
        active
      />
    </Form.Item>
  );

  const accessGrantProvider = (value: string) => {
    window.open(`/grants/providers/${value}`, '_blank');
  };

  const providersTagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        bordered={false}
        style={{
          cursor: 'pointer',
          color: theme.colors.purple_1,
          fontSize: '14px',
          backgroundColor: theme.colors.grey_5,
        }}
        onClick={() => accessGrantProvider(value)}
      >
        {label}
      </Tag>
    );
  };

  const options = useMemo(() => {
    switch (section) {
      case 'financing_type':
        return financingType;
      case 'providers':
      case 'grant_providers':
        return grantsProviders;
      case 'industry_sectors':
        return industries;
      case 'industry_subsectors':
        return industrySubsectors;
      case 'service':
        return service;
      case 'audience':
        return audience;

      default:
        return forProfitTypes;
    }
  }, [
    section,
    financingType,
    grantsProviders,

    industries,
    industrySubsectors,
    service,
    audience,
    forProfitTypes,
  ]);

  const renderInput = () => {
    if (isDateInput) {
      return (
        <Suspense fallback={<Loading />}>
          <Form.Item
            name={section}
            label={label}
            getValueFromEvent={(onChange) => (onChange ? dayjs(onChange) : '')}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
            style={{ margin: margin }}
          >
            <DatePicker
              disabled={disabled}
              size="large"
              picker="date"
              allowClear
              onChange={onSave}
            />
          </Form.Item>
        </Suspense>
      );
    } else if (isMultipleSelect) {
      return (
        <Form.Item label={label} name={section} style={{ margin: margin }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            onChange={() => {
              if (section === 'industry_sectors') {
                if (fetchIndustrySubsectors) fetchIndustrySubsectors();
              }
              if (onSave) {
                onSave();
              }
            }}
            options={options}
            filterOption={(input, option) =>
              `${option?.label}`.toLowerCase().indexOf(input?.toLowerCase()) !==
              -1
            }
            tagRender={section === 'providers' ? providersTagRender : undefined}
          />
        </Form.Item>
      );
    } else if (isSimpleSelect) {
      return (
        <Form.Item label={label} name={section} style={{ margin: margin }}>
          <Select
            showSearch
            style={{ width: '100%' }}
            options={
              section === 'quebec_administrative_region'
                ? regions
                : section === 'province'
                  ? provinces
                  : section === 'difficulty'
                    ? difficulty
                    : mrcs
            }
            onChange={(value) => {
              if (section === 'quebec_administrative_region') {
                if (fetchRegionMRCS) {
                  fetchRegionMRCS();
                  form.setFieldValue('quebec_mrc', undefined);
                }
              }
              if (
                section === 'quebec_mrc' &&
                !form.getFieldValue('quebec_administrative_region')
              ) {
                form.setFieldValue(
                  'quebec_administrative_region',
                  mrcs?.find((item) => item.value === value)?.region_code,
                );
                if (fetchRegionMRCS) fetchRegionMRCS();
              }
              if (onSave) onSave();
            }}
          />
        </Form.Item>
      );
    } else if (isDollarInput) {
      return (
        <Form.Item label={label} name={section} style={{ margin: margin }}>
          <InputNumberDollars min={0} onChange={onSave} />
        </Form.Item>
      );
    } else if (isNumberInput) {
      return (
        <Form.Item label={label} name={section} style={{ margin: margin }}>
          <InputNumber min={section === 'rank' ? -1 : 0} onChange={onSave} />
        </Form.Item>
      );
    } else if (isTags) {
      return (
        <Div flex="column">
          <Div flex="row" justify="space-between" align="center">
            <Typography elementTheme="body2">Tags</Typography>
            <Div style={{ width: 'fit-content' }}>
              <Popconfirm
                title="Delete all these grant Tags"
                description="Are you sure to delete all these grant tags ? You will not be able to recover them"
                onConfirm={deleteAllTags}
                okText="Delete All"
                cancelText="No"
              >
                <Button type="link" danger={true} icon={<Delete size={15} />}>
                  Delete All Tags
                </Button>
              </Popconfirm>

              <Button
                type="link"
                onClick={generateTags}
                icon={<Refresh size={15} />}
              >
                Generate Tags
              </Button>
              <Button
                type="link"
                onClick={openTagDrawer}
                icon={<Edit size={15} />}
              >
                Edit Tags
              </Button>
            </Div>
          </Div>
          <SeeMoreTags limit={10} size="medium" content={tags || []} />
        </Div>
      );
    } else {
      return (
        <Form.Item label={label} name={section} style={{ margin: margin }}>
          <Input onMouseDown={onMouseDown} onChange={onSave} />
        </Form.Item>
      );
    }
  };

  return <>{isLoading ? SkeletonTextArea() : renderInput()}</>;
};

export default GrantPromptPropertySection;
