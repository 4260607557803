import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Profile = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4538 26.6271C24.3496 25.1651 22.9211 23.9795 21.2807 23.1637C19.6403 22.3479 17.8329 21.9241 16.0008 21.9258C14.1687 21.9241 12.3613 22.3479 10.7209 23.1637C9.0805 23.9795 7.65194 25.1651 6.54776 26.6271M25.4538 26.6271C27.6085 24.7105 29.1281 22.1842 29.814 19.3833C30.4999 16.5823 30.3182 13.639 29.293 10.9436C28.2677 8.24831 26.4474 5.92833 24.0733 4.29135C21.6993 2.65438 18.8837 1.77777 16 1.77777C13.1163 1.77777 10.3007 2.65438 7.92669 4.29135C5.55264 5.92833 3.73228 8.24831 2.70702 10.9436C1.68177 13.639 1.50006 16.5823 2.18599 19.3833C2.87192 22.1842 4.39309 24.7105 6.54776 26.6271M25.4538 26.6271C22.8528 28.9472 19.4862 30.2272 16.0008 30.2222C12.5148 30.2276 9.14921 28.9476 6.54776 26.6271M20.7415 12.4441C20.7415 13.7015 20.2421 14.9073 19.353 15.7964C18.4639 16.6855 17.2581 17.185 16.0008 17.185C14.7435 17.185 13.5376 16.6855 12.6486 15.7964C11.7595 14.9073 11.2601 13.7015 11.2601 12.4441C11.2601 11.1868 11.7595 9.98095 12.6486 9.09188C13.5376 8.2028 14.7435 7.70332 16.0008 7.70332C17.2581 7.70332 18.4639 8.2028 19.353 9.09188C20.2421 9.98095 20.7415 11.1868 20.7415 12.4441Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Profile;
