import { RfpSearchResult } from '@hellodarwin/core/lib/features/entities';
import {
  getFormattedDate,
  getPaginationTotal,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import Table from 'antd/es/table';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useState } from 'react';

import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import Clipboard from '../components/clipboard';
import { useActionBar } from '../context/action-bar-provider';
import { useAdminApi } from '../features/api/use-admin-api';
import { getRfpStatusColor } from '../features/utils';
import ErrorPage from './error-page';

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 50;

type PageState = {
  rfp: RfpSearchResult[];
  pagination: {
    page: number;
    size: number;
  };
};

const pageStateInitialValues: PageState = {
  rfp: [],
  pagination: {
    page: DEFAULT_PAGE,
    size: DEFAULT_LIMIT,
  },
};

const RfpPage = () => {
  const { setActionBar } = useActionBar();
  const navigate = useNavigate();
  const api = useAdminApi();
  const { t } = useTranslations();
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [apiState, setApiState] = useState<{
    isLoading: boolean;
    isSearching: boolean;
    isErrored: Error | null;
  }>({
    isLoading: false,
    isSearching: false,
    isErrored: null,
  });

  const columns: ColumnsType<RfpSearchResult> = [
    {
      title: t('rfps_admin|rfp_id'),
      dataIndex: 'rfp_id',
      key: 'rfp_id',
      render: (v) => <Clipboard copy={v} title={getShortId(v)} />,
    },
    {
      title: t('rfps_admin|status'),
      dataIndex: 'project_status',
      key: 'project_status',
      render: (v) => <Tag color={getRfpStatusColor(v)}>{v}</Tag>,
      sorter: {
        compare: (a, b) => a.rfp_status.localeCompare(b.rfp_status),
      },
    },
    {
      title: t('rfps_admin|titleProgram'),
      dataIndex: 'title',
      key: 'title',
      ellipsis: {
        showTitle: false,
      },
      render: (v) => (
        <Tooltip placement="topLeft" title={v}>
          {v}
        </Tooltip>
      ),
      sorter: {
        compare: (a, b) => a.title.localeCompare(b.title),
      },
    },
    {
      title: t('rfps_admin|companyName'),
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: {
        compare: (a, b) => a.company_name.localeCompare(b.company_name),
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('rfps_admin|created_on'),
      dataIndex: 'project_created_at',
      key: 'project_created_at',
      sorter: {
        compare: (a, b) =>
          new Date(a.rfp_created_at).getTime() -
          new Date(b.rfp_created_at).getTime(),
      },
      render: (v) => <Tooltip title={v}>{getFormattedDate(v)}</Tooltip>,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t('rfps_admin|category'),
      dataIndex: 'project_category',
      key: 'project_category',
      sorter: {
        compare: (a, b) => a.rfp_category.localeCompare(b.rfp_category),
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'source',
      dataIndex: 'lead_source',
      key: 'lead_source',
    },
    {
      title: 'lp_url',
      dataIndex: 'lp_url',
      key: 'lp_url',
      render: (v) => <Tooltip title={v}>{v}</Tooltip>,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  const fetch = (
    page: number,
    size: number,
    query: string,
    loading: boolean,
    isSearching: boolean,
  ) => {
    (async () => {
      setApiState({
        isLoading: loading,
        isSearching: isSearching,
        isErrored: null,
      });
      try {
        const response = await api.queryRfp(page, size, query);
        const newPageState = {
          rfp: response,
          pagination: { page: page, size: size },
        };

        setPageState(newPageState);
        setApiState({ isLoading: false, isSearching: false, isErrored: null });
      } catch (e: any) {
        setApiState({ isLoading: false, isSearching: false, isErrored: e });
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT, '', true, false);
    setActionBar(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (record: RfpSearchResult, eventDetail: number) => {
    if (eventDetail === 2) {
      navigate(`/projects/${record.rfp_id}`);
    }
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size, '', true, false);
  };

  if (apiState.isErrored) {
    return <ErrorPage />;
  }

  return (
    <PageLayout
      app="admin"
      title={t('rfps_admin|rfp')}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('rfps_admin|rfp'),
        },
      ]}
    >
      <Table
        dataSource={pageState.rfp}
        columns={columns}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: getPaginationTotal(
            pageState.pagination.page,
            pageState.pagination.size,
            pageState.rfp?.length,
          ),
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={apiState.isLoading}
        onRow={(record) => {
          return {
            onClick: (event) => {
              onClick(record, event.detail);
            },
          };
        }}
      />
    </PageLayout>
  );
};

export default RfpPage;
