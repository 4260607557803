import Close from '@hellodarwin/icons/dist/icons/Close';
import { useTheme } from 'styled-components';
import Button from '../../common/button';
import FilterCompanyCardContent, {
  FilterCompanyCardContentProps,
} from './filter/filter-company-card/filter-company-card-content';

interface FundingExplorerCompanyInfoProps
  extends FilterCompanyCardContentProps {
  removeCompany: () => void;
}

const FundingExplorerCompanyInfo = ({
  removeCompany,
  ...cardProps
}: FundingExplorerCompanyInfoProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        padding: 0,
        background: theme.colors.white_2,
        border: `1px solid ${theme.colors.primary}`,
        position: 'relative',
      }}
    >
      <FilterCompanyCardContent {...cardProps} />
      <Button
        size="square"
        transparent
        style={{ position: 'absolute', padding: 8, top: 0, right: 0 }}
        onClick={removeCompany}
      >
        <Close size={16} />
      </Button>
    </div>
  );
};
export default FundingExplorerCompanyInfo;
