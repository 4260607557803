import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Verified = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 16.1354L14.0667 19.3941L16.9 15.5923L19.7333 11.7904M15.2 1.59998C11.7784 4.71422 7.22173 6.4223 2.50365 6.35918C1.90349 8.11163 1.59845 9.94453 1.60001 11.789C1.60001 19.888 7.37849 28.4694 15.2 30.4C23.0215 28.4708 28.8 19.8895 28.8 11.7904C28.8 9.8931 28.4827 8.06676 27.8964 6.35773H27.6667C22.8372 6.35773 18.4489 4.55022 15.2 1.59998Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Verified;
