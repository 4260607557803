import Loading from '@hellodarwin/core/lib/components/loading';
import {
  getFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Tooltip from 'antd/es/tooltip';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import List from '../../components/list/list';
import {
  fetchActiveMatches,
  selectProviderActiveMatches,
  selectProvidersLoading,
} from '../../features/api/slices/providers-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

type AvailableRfpListProps = {
  providerId: string;
};

const AvailableRfpList = ({ providerId }: AvailableRfpListProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const matches = useAppSelector(selectProviderActiveMatches);
  const loading = useAppSelector(selectProvidersLoading);
  const { t } = useTranslations();

  useEffect(() => {
    dispatch(fetchActiveMatches({ api, providerId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    t('available_rfps|rfp_id'),
    t('available_rfps|category'),
    t('available_rfps|estimatedValue'),
    t('available_rfps|leadPrice'),
    t('available_rfps|matchSource'),
    t('available_rfps|matchedAt'),
  ];

  const getRows = () => {
    if (matches.length === 0) {
      return [];
    }

    return matches.map((m, index) => {
      const date = getFormattedDate(m.created_at);
      return [
        <Tooltip placement="bottom" key={index} title="Project page">
          <Link to={'/projects/' + m.rfp_id}>{getShortId(m.rfp_id)}</Link>
        </Tooltip>,
        m.rfp.category,
        `$${m.rfp.estimated_value}`,
        `$${m.price}`,
        m.source,
        date,
      ];
    });
  };

  if (loading) {
    return <Loading />;
  }

  return <List headers={headers} rows={getRows()} onClick={() => null} />;
};

export default AvailableRfpList;
