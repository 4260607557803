import styled from '../../../../plugins/styled';

export const ProfileFastInformationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  align-items: stretch;
  gap: 24px;
  flex-wrap: wrap;

  .information-card {
    max-width: 165px;
    @media screen and (max-width: ${(props) =>
        props.theme.breakpoints.tablet}px) {
      padding: 1rem;
      max-width: none;
      width: calc(50% - 0.75rem);
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    overflow: auto;
    & > * {
      flex: 1;
      flex-basis: 135px;
      flex-shrink: 0;
      flex-grow: 0;
      overflow: hidden;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
