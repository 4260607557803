import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Hide = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0671 19.6497C21.1456 18.5712 21.7515 17.1084 21.7515 15.5832C21.7515 14.0579 21.1456 12.5951 20.0671 11.5166C18.9886 10.4381 17.5258 9.83215 16.0005 9.83215C14.4753 9.83215 13.0125 10.4381 11.9339 11.5166M4.74882 9.83215C3.38887 11.3519 2.33243 13.1487 1.67476 15.1274C1.57554 15.4256 1.57554 15.7479 1.67476 16.0461C3.67323 22.043 9.33223 26.3663 16.0005 26.3663C17.6643 26.3663 19.2651 26.0971 20.7619 25.6M23.9999 24.0747C26.9463 22.2299 29.2098 19.3962 30.3249 16.0389C30.4255 15.7399 30.4255 15.4178 30.3249 15.1202C28.3264 9.12334 22.6688 4.80001 16.0005 4.80001C12.8262 4.80001 9.88073 5.77984 7.45018 7.4534M3.125 3.20001L19.035 19.11M28.8763 28.9513L19.0365 19.1115"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Hide;
