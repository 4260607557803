import Image from 'antd/es/image';
import React from 'react';
import {
  Portfolio,
  PortfolioAssetsType,
} from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import theme from '../../../../../theme';
import VideoPlayer from '../../../../common/VideoPlayer';
import Carousel from '../../../../common/carousel';
import Div from '../../../../common/div';

const Gallery = ({ assets }: Portfolio) => {
  const { t } = useTranslations();

  if (!assets?.length) return <></>;

  return (
    <>
      <Div
        style={{ padding: '0 35px', overflow: 'hidden' }}
        flex="column"
        gap={26}
      >
        <Carousel
          tileWidth={356}
          title={t('profile_portfolio|portfolioLabelsGallery')}
          buttonPosition="top-right"
        >
          {React.Children.toArray(
            assets?.map((asset) => (
              <div
                style={{
                  background: theme.colors.grey_1,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: 356,
                }}
              >
                {asset.asset_type === PortfolioAssetsType.Video ? (
                  <VideoPlayer
                    url={asset.asset_url ?? ''}
                    width="100%"
                    height={356}
                    controls
                    light={true}
                  />
                ) : (
                  <Image
                    width={356}
                    height={356}
                    src={asset.asset_url}
                    loading="lazy"
                    style={{
                      padding: 0,
                      margin: 0,
                      objectFit: 'contain',
                    }}
                  />
                )}
              </div>
            )),
          )}
        </Carousel>
      </Div>
    </>
  );
};

export default Gallery;
