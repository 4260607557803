import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Check from '@hellodarwin/icons/dist/icons/Checkmark';

interface AddNewProjectItemCongratsProps {
  handleCancel: () => void;
  assignedValue: string | undefined;
}
const AddNewProjectItemCongrats: React.FC<AddNewProjectItemCongratsProps> = ({
  handleCancel,
  assignedValue,
}) => {
  const { t } = useTranslations();
  const theme = useTheme();
  return (
    <Div flex="column" align="center" gap={24}>
      <Div flex="column" gap={16} align="center">
        <Check size={68} color={theme.colors.green_high_contrast} />
        <Typography elementTheme="h5" textAlign="center">
          {t('project|itemWasAssigned', {
            itemName: assignedValue ?? '',
          })}
        </Typography>
      </Div>
      <Button isLink onClick={handleCancel}>
        {t('button|close')}
      </Button>
    </Div>
  );
};

export default AddNewProjectItemCongrats;
