import { useMemo } from 'react';
import FileSectionGin from '../gin-single/file-section';
import { GinBlockConfig } from '../gin-single/gin-block';
import GinTextEditor from '../gin-single/gin-editor';
import GinStepsComponent from '../gin-single/gin-steps';
import ApplicationTimeline from './application-timeline';

export enum GinApplicationSinglePageSection {
  Notes = 'application_notes',
  ImportantDate = 'important_date',
  Files = 'files',
  ProductionStages = 'production_stages',
}

const useGinApplicationBlocks = () => {
  return useMemo(() => {
    const GinSectionsBlocks: { [section: string]: GinBlockConfig } = {
      [GinApplicationSinglePageSection.ProductionStages]: {
        id: GinApplicationSinglePageSection.ProductionStages,
        Content: GinStepsComponent,
        isSimpleActions: true,
        toggleName: 'Client View',
        noHistory: true,
        isHiddenActions: true,
      },
      [GinApplicationSinglePageSection.Notes]: {
        id: GinApplicationSinglePageSection.Notes,
        Content: GinTextEditor,
        noHistory: true,
      },
      [GinApplicationSinglePageSection.Files]: {
        id: GinApplicationSinglePageSection.Files,
        Content: FileSectionGin,
        isSimpleActions: true,
        noHistory: true,
      },

      [GinApplicationSinglePageSection.ImportantDate]: {
        id: GinApplicationSinglePageSection.ImportantDate,
        Content: ApplicationTimeline,
        isHiddenActions: true,
        noHistory: true,
      },
    };

    return GinSectionsBlocks;
  }, []);
};

export default useGinApplicationBlocks;
