import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AssetRowMeta } from '../../../../features/entities/assets-entities';
import { getStringDate } from '../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import useOwnerLabelAndPath from '../utils/use-owner-label-and-path';

interface AssetCardUpdateInfosProps extends AssetRowMeta {}

const AssetCardUpdateInfos: React.FC<AssetCardUpdateInfosProps> = (asset) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const { last_uploaded_by, updated_at } = asset;

  const ownerLabelAndPath = useOwnerLabelAndPath(last_uploaded_by);

  return (
    <Div flex="column" gap={6}>
      <Typography
        elementTheme="overline"
        color={theme.colors.grey_2}
        style={{ marginLeft: 12 }}
      >
        {t('files_gin|last_update')}
      </Typography>
      <ul style={{ margin: 0, marginBottom: 12 }}>
        <li>
          <Typography elementTheme="body3" style={{ alignSelf: 'stretch' }}>
            {getStringDate(new Date(`${updated_at}`), selectedLocale)}
          </Typography>
        </li>
        {!!last_uploaded_by?.owner_name && (
          <>
            <li>
              <Typography elementTheme="body3" color={theme.colors.grey_1}>
                {t('files_gin|by')}{' '}
                <Link to={ownerLabelAndPath.path} target="_blank">
                  {ownerLabelAndPath.label}
                </Link>
              </Typography>
            </li>
          </>
        )}
      </ul>
    </Div>
  );
};

export default AssetCardUpdateInfos;
