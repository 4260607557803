import styled from '../../../../../plugins/styled';

export const SummaryBoxContent = styled.div`
  padding-inline-start: calc(16px + 8px);
  ul {
    padding-inline-start: 0;
  }
`;
export const SummaryBoxContainer = styled.div`
  padding: 26px 32px;

  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    border-right: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
    padding: 26px 32px;
  }
`;

export const SummaryBoxesList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey_4};
  gap: 24px;
  & > * {
    padding-right: 24px;
    padding-left: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.grey_4};
    &:last-child {
      border: none;
      padding-right: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    padding: 0;
    & > * {
      padding: 26px 32px;
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
      &:last-child {
        border: none;
      }
    }
  }
`;
