import Checkbox from 'antd/es/checkbox/Checkbox';
import { FormInstance, useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import { ChangeEvent, useEffect, useState } from 'react';
import { GMAPS_API_KEY } from '../../../../api';
import useLocale from '../../../../features/providers/locale-provider';
import { Status, Wrapper } from '../../../../features/wrappers/gmaps-wrapper';
import { useTranslation } from '../../../../plugins/i18n';
import Collapse from '../../../common/Collapse';
import Div from '../../../common/div';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import { getLongAddress } from '../../utils/get-address';
import SelectCountry from '../country-select';
import SelectProvince from '../province-select';
import GoogleAutocomplete from './GoogleAutocomplete';

export interface AddressValues {
  address: string;
  city: string;
  province: string;
  postal_code: string;
  country: string;
  isCanada: boolean;
  lat: string;
  lng: string;
}

interface AddressAutocompleteInputProps {
  form: FormInstance<any>;
  showManualAddress?: boolean;
  required?: boolean;
  provinceRequired?: boolean;
  disabled?: boolean;
}

const AddressAutocompleteInput = ({
  form,
  showManualAddress,
  required,
  provinceRequired,
  disabled,
}: AddressAutocompleteInputProps) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const [isManual, setManual] = useState(false);
  const [isCanada, setCanada] = useState<boolean>(false);

  const getCompleteFromForm = () => {
    const values = form.getFieldsValue() as AddressValues;
    const complete = getLongAddress(values.address, {
      city: values.city,
      address: values.address,
      country: values.country,
      postal_code: values.postal_code,
      province: values.province,
    });
    if (!complete || complete === 'undefined') {
      form.setFieldValue('complete', '');
    } else {
      form.setFieldValue('complete', complete);
    }
  };

  useEffect(() => {
    getCompleteFromForm();
  }, []);

  useEffect(() => {
    setCanada(form.getFieldValue('isCanada'));
  }, [isCanada]);

  useEffect(() => {
    if (isCanada) {
      form.setFieldValue('country', 'Canada');
    }
  }, [isManual]);

  const render = (status: Status) => {
    getCompleteFromForm();
    switch (status) {
      case Status.FAILURE:
      case Status.LOADING:
        return <></>;

      default:
        return <GoogleAutocomplete form={form} setCanada={setCanada} />;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    getCompleteFromForm();
  };

  return (
    <Div style={{ marginBottom: 16 }}>
      <FormItem
        name="complete"
        label={
          <FormLabel label={t('profile|profileForm.company.labels.address')} />
        }
        style={{ margin: 0 }}
        rules={[
          required || provinceRequired
            ? {
                required: true,
                message:
                  provinceRequired && !required
                    ? t('profile|profileForm.address.validation.province')
                    : t('profile|profileForm.company.validation.address'),
              }
            : {},
        ]}
      >
        <Wrapper
          apiKey={GMAPS_API_KEY}
          language={`${selectedLocale || 'en'}-ca`}
          region="CA"
          id="GOOGLE_MAPS"
          render={render}
          libraries={['maps', 'places']}
          library="places"
        />
      </FormItem>
      <Div hidden={!showManualAddress}>
        <Collapse
          noPadding
          ExpandIcon={(props) => <></>}
          ghost
          items={[
            {
              id: 'autocomplete',
              title: (
                <Checkbox
                  style={{ marginTop: 16 }}
                  onChange={(e) => setManual(e.target.checked)}
                >
                  {t('profile|profileForm.address.extra.addressManually')}
                </Checkbox>
              ),
              isActive: isManual,
              children: (
                <>
                  <Div style={{ padding: '16px 0' }} flex="column" gap={16}>
                    <FormItem
                      name="address"
                      label={
                        <FormLabel
                          label={t(
                            'profile|profileForm.address.labels.address',
                          )}
                        />
                      }
                      style={{ margin: 0 }}
                    >
                      <Input size="large" onChange={handleChange} />
                    </FormItem>
                    <Div flex="row" wrap="wrap" gap={16}>
                      <SelectCountry setCanada={setCanada} form={form} />
                      <FormItem
                        hidden={isCanada}
                        name="country"
                        label={
                          <FormLabel
                            label={t(
                              'profile|profileForm.address.labels.otherCountry',
                            )}
                          />
                        }
                        rules={[
                          {
                            message: t(
                              'profile|profileForm.address.validation.country',
                            ),
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          onChange={handleChange}
                          placeholder={t(
                            'profile|profileForm.address.placeholder.otherCountry',
                          )}
                          width={100}
                        />
                      </FormItem>
                      <SelectProvince
                        isRequired={provinceRequired}
                        onChange={handleChange}
                        isCanada={isCanada}
                      />
                      <FormItem
                        name="city"
                        label={
                          <FormLabel
                            label={t('profile|profileForm.address.labels.city')}
                          />
                        }
                      >
                        <Input size="large" onChange={handleChange} />
                      </FormItem>

                      <FormItem
                        name="postal_code"
                        label={
                          <FormLabel
                            label={t(
                              `profile|profileForm.address.labels.${
                                isCanada ? 'postalCode' : 'zipCode'
                              }`,
                            )}
                          />
                        }
                      >
                        <Input size="large" onChange={handleChange} />
                      </FormItem>
                    </Div>
                    <Div hidden>
                      <FormItem name="lat">
                        <Input size="large" onChange={handleChange} />
                      </FormItem>
                      <FormItem name="lng">
                        <Input size="large" />
                      </FormItem>
                    </Div>
                  </Div>
                </>
              ),
            },
          ]}
        />
      </Div>
    </Div>
  );
};

export default AddressAutocompleteInput;

AddressAutocompleteInput.Demo = () => {
  const [form] = useForm();
  return <AddressAutocompleteInput form={form} showManualAddress />;
};
