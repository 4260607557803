import { IconNode } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const RatingFill: IconNode = (props) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.22 2.98631C15.51 2.33631 16.48 2.33631 16.77 2.98631L20.37 10.9263C20.49 11.1963 20.76 11.3763 21.07 11.4063L30.14 12.2063C30.88 12.2763 31.18 13.1463 30.62 13.6063L23.78 19.3063C23.55 19.4963 23.44 19.7963 23.51 20.0863L25.51 28.5163C25.67 29.2063 24.89 29.7463 24.25 29.3863L16.42 24.9763C16.15 24.8263 15.82 24.8263 15.56 24.9763L7.72999 29.3863C7.08999 29.7463 6.30999 29.2063 6.46999 28.5163L8.46999 20.0863C8.53999 19.7963 8.43999 19.4963 8.19999 19.3063L1.37999 13.6063C0.819987 13.1363 1.11999 12.2663 1.85999 12.2063L10.93 11.4063C11.24 11.3763 11.51 11.1963 11.63 10.9263L15.23 2.98631H15.22Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
      <path
        d="M16.0217 24.92C15.8917 24.92 15.7717 24.95 15.6517 25.02L8.24171 29.34C7.64171 29.69 6.91171 29.15 7.06171 28.47L8.94171 20.28C9.00171 20 8.91171 19.7 8.69171 19.52L2.27171 13.97C1.75171 13.52 2.02171 12.66 2.71171 12.59L11.2417 11.81C11.5317 11.78 11.7817 11.6 11.9017 11.33L15.3617 3.43C15.4917 3.14 15.7517 3 16.0217 3"
        fill="currentColor"
      />
    </svg>
  );
};

export default RatingFill;
