import { useMemo } from 'react';
import { AssetRowMeta } from '../../../features/entities/assets-entities';
import { usePlatform } from '../../../features/providers/platform-provider';
import Div from '../../common/div';
import Dropdown from '../../common/dropdown';
import AssetRowActions from './components/asset-card-actions';
import AssetCardUpdateInfos from './components/asset-card-update-infos';
import AssetMetaContent from './components/asset-meta';
import {
  AssetBadgeContainer,
  AssetIconContainer,
  AssetTileContainer,
} from './styles';

export interface AssetCardProps {
  meta: AssetRowMeta;

  isActive: boolean;
}

const AssetCard: React.FC<AssetCardProps> = (props) => {
  const { meta, isActive } = props;

  const {
    Icon,
    opened_at,
    actions: { dropdownItems, onDoubleClick },
  } = meta;
  const { app } = usePlatform();

  const isOpened: boolean = useMemo(() => !!opened_at, [opened_at]);

  return (
    <Dropdown
      items={dropdownItems}
      ctaRender={() => (
        <AssetBadgeContainer
          dot={!isOpened && app !== 'admin'}
          offset={[-12, 12]}
          styles={{
            root: {
              display: 'block',
              width: 'fit-content',
            },
            indicator: { width: 10, height: 10 },
          }}
        >
          <AssetTileContainer
            $isActive={isActive}
            onClick={onDoubleClick}
            $withUpdateInfos={app === 'admin'}
            $isRow={false}
          >
            <Div flex="column" gap={4} align="center" justify="center">
              <AssetIconContainer>
                <Icon size={32} />
              </AssetIconContainer>
              <AssetMetaContent {...meta} />
            </Div>
            {app === 'admin' && <AssetCardUpdateInfos {...meta} />}
            <AssetRowActions items={dropdownItems} />
          </AssetTileContainer>
        </AssetBadgeContainer>
      )}
      trigger={['contextMenu']}
    />
  );
};

export default AssetCard;
