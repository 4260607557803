import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { SearchResults } from '@hellodarwin/core/lib/features/entities';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import List from 'antd/es/list';
import Skeleton from 'antd/es/skeleton';
import Tooltip from 'antd/es/tooltip';
import { Link } from 'react-router-dom';
import { copyIntoClipboard } from '../clipboard';

type SearchBarGrantProvidersProps = {
  results: SearchResults;
  isSearching: boolean;
  onClose: () => void;
};

const SearchBarGrantProviders = ({
  results,
  isSearching,
  onClose,
}: SearchBarGrantProvidersProps) => {
  return (
    <Div flex="column">
      <Typography elementTheme="body2">Grant Providers</Typography>
      <List
        grid={{ gutter: 16, column: 2 }}
        itemLayout="horizontal"
        dataSource={results?.grantProviders}
        renderItem={(p, index) => (
          <List.Item>
            <Skeleton avatar title={false} loading={isSearching} active>
              <List.Item.Meta
                avatar={
                  <>
                    <Div
                      onClick={() =>
                        window.open(
                          '/grants/providers/' + p.grant_provider_id,
                          '_blank',
                        )
                      }
                      style={{
                        width: 60,
                        height: 20,
                        marginBottom: 7,
                      }}
                    >
                      <Tooltip
                        placement="top"
                        zIndex={5000}
                        title={'Open in new page'}
                      >
                        <Button
                          style={{ width: 45, border: 0 }}
                          icon={<NewWindow width={17} height={17} />}
                        ></Button>
                      </Tooltip>
                    </Div>
                    <Div
                      onClick={() => copyIntoClipboard(p.grant_provider_id)}
                      style={{ width: 60, height: 30, margin: 0 }}
                    >
                      <Tooltip
                        placement="top"
                        zIndex={5000}
                        title={'Copy ID To Clipboard'}
                      >
                        <Button
                          style={{
                            width: 45,
                            border: 0,
                          }}
                          icon={<CopyOutlined />}
                        ></Button>
                      </Tooltip>
                    </Div>
                  </>
                }
                title={
                  <Link
                    to={'/grants/providers/' + p.grant_provider_id}
                    onClick={onClose}
                  >
                    <Typography elementTheme="body3" style={{ marginTop: 7 }}>
                      {`${p.title}`}
                    </Typography>
                  </Link>
                }
                description={
                  <Div flex="row" gap={20}>
                    <Typography elementTheme="body3">
                      {'ID: '} {getShortId(p.grant_provider_id)}
                    </Typography>
                  </Div>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </Div>
  );
};

export default SearchBarGrantProviders;
