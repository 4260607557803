import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Performance = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.59998 17.8C1.59998 16.8064 2.40638 16 3.39998 16H6.99998C7.99358 16 8.79998 16.8064 8.79998 17.8V28.6C8.79998 29.5936 7.99358 30.4 6.99998 30.4H3.39998C2.92259 30.4 2.46475 30.2104 2.12718 29.8728C1.78962 29.5352 1.59998 29.0774 1.59998 28.6V17.8ZM12.4 10.6C12.4 9.60641 13.2064 8.80001 14.2 8.80001H17.8C18.7936 8.80001 19.6 9.60641 19.6 10.6V28.6C19.6 29.5936 18.7936 30.4 17.8 30.4H14.2C13.7226 30.4 13.2647 30.2104 12.9272 29.8728C12.5896 29.5352 12.4 29.0774 12.4 28.6V10.6ZM23.2 3.40001C23.2 2.40641 24.0064 1.60001 25 1.60001H28.6C29.5936 1.60001 30.4 2.40641 30.4 3.40001V28.6C30.4 29.5936 29.5936 30.4 28.6 30.4H25C24.5226 30.4 24.0647 30.2104 23.7272 29.8728C23.3896 29.5352 23.2 29.0774 23.2 28.6V3.40001Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Performance;
