import React, { useRef } from 'react';
import Typography from '../typography';
import { MenuContainer, MenuContent } from './styles';

export interface SingleHeaderMenuItem {
  label: string;
  id: string;
}

export interface SingleHeaderMenuProps {
  items: SingleHeaderMenuItem[];
}

const SingleHeaderMenu = ({ items }: SingleHeaderMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    const menuItem = e.currentTarget as HTMLAnchorElement;

    // Get the container element
    const container = ref.current;
    if (!container) return;

    const { offsetWidth: containerWidth, scrollLeft } = container;
    const { clientWidth: menuItemWidth, offsetLeft: menuItemLeft } = menuItem;

    const menuItemRight = menuItemLeft + menuItemWidth;
    const isOverflowingRight = menuItemRight > scrollLeft + containerWidth;
    const isOverflowingLeft = menuItemLeft < scrollLeft;

    if (isOverflowingRight) {
      container.scrollTo({
        left: menuItemRight - containerWidth + 40,
        behavior: 'smooth',
      });
    } else if (isOverflowingLeft) {
      container.scrollTo({
        left: menuItemLeft - 40,
        behavior: 'smooth',
      });
    }
  };

  return (
    <MenuContainer>
      <MenuContent ref={ref}>
        {React.Children.toArray(
          items.map(
            (s) =>
              !!s.label.length && (
                <a
                  href={`#${s.id}`}
                  onMouseOver={handleScroll}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                >
                  <Typography
                    elementTheme="body2"
                    style={{
                      padding: '8px 24px',
                      cursor: 'pointer',
                    }}
                    nowrap
                  >
                    {s.label}
                  </Typography>
                </a>
              ),
          ),
        )}
      </MenuContent>
    </MenuContainer>
  );
};

export default SingleHeaderMenu;
