import Dots from '@hellodarwin/icons/dist/icons/Dots';
import { ItemType } from 'antd/es/menu/interface';
import { CSSProperties, useMemo } from 'react';
import { useTheme } from 'styled-components';
import Button from '../button';
import Typography from '../typography';
import { DropdownContainer, DropdownStyled } from './styles';

export type DropdownPlacement =
  | 'bottom'
  | 'top'
  | 'bottomRight'
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft';

export type DropdownTriggerType = 'contextMenu' | 'click' | 'hover';
export interface DropdownCtaProps {
  size?: number;
  color?: string;
  text?: string;
  style?: CSSProperties;
}

export interface DropdownProps {
  items: ItemType[];
  dropdownRender?: (originNode: React.ReactNode) => React.ReactNode;
  placement?: DropdownPlacement;
  menuStyle?: CSSProperties /**
   * @deprecated Use styles instead.
   */;
  cta?: DropdownCtaProps;
  ctaRender?: (cta: DropdownCtaProps) => React.ReactNode;
  overlayClassName?: string;
  overlayStyle?: CSSProperties /**
   * @deprecated Use styles instead.
   */;
  trigger?: DropdownTriggerType[];
  styles?: {
    overlay?: CSSProperties;
    menu?: CSSProperties;
    cta?: CSSProperties;
    container?: CSSProperties;
    dropdown?: CSSProperties;
  };
}

const Dropdown = ({
  items,
  dropdownRender,
  placement,
  cta,
  ctaRender,
  menuStyle,
  overlayClassName,
  styles,
  overlayStyle,
  ...props
}: DropdownProps) => {
  const theme = useTheme();

  const ctaDefaultRender = (cta: DropdownCtaProps) => {
    const buttonSize = cta?.size ?? 16;
    const buttonColor = cta?.color ?? theme.colors.grey_2;
    const buttonText = cta?.text;

    return (
      <Button transparent size={'square'} style={cta.style}>
        {!!buttonText && <Typography>{buttonText}</Typography>}
        <Dots color={buttonColor} size={buttonSize} />
      </Button>
    );
  };
  const defaultDropdownRender: DropdownProps['dropdownRender'] = (
    el: React.ReactElement,
  ) => (
    <DropdownContainer style={{ ...styles?.dropdown }}>{el}</DropdownContainer>
  );

  const ButtonRender = useMemo(
    () => (!!ctaRender ? ctaRender : ctaDefaultRender),
    [ctaRender],
  );
  const currentDropdownRender = useMemo(
    () => (!!dropdownRender ? dropdownRender : defaultDropdownRender),
    [dropdownRender],
  );

  return (
    <DropdownStyled
      menu={{ items, style: { ...menuStyle, ...styles?.menu } }}
      dropdownRender={currentDropdownRender}
      placement={placement}
      overlayClassName={overlayClassName}
      overlayStyle={{ ...overlayStyle, ...styles?.overlay }}
      {...props}
    >
      <div style={{ width: 'fit-content', ...styles?.container }}>
        <ButtonRender {...cta} style={{ ...cta?.style, ...styles?.cta }} />
      </div>
    </DropdownStyled>
  );
};

export default Dropdown;
