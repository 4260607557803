import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { SearchResults } from '@hellodarwin/core/lib/features/entities';
import {
  getShortFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import List from 'antd/es/list';
import Skeleton from 'antd/es/skeleton';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import { Link } from 'react-router-dom';
import { getStatusColor } from '../../features/utils';
import { copyIntoClipboard } from '../clipboard';

type SearchBarRfpProps = {
  results: SearchResults;
  isSearching: boolean;
  onClose: () => void;
};

const SearchBarRfp = ({ results, isSearching, onClose }: SearchBarRfpProps) => {
  const { t } = useTranslations();
  return (
    <Div flex="column">
      <Typography elementTheme="body2">{t('rfps_admin|rfp')}</Typography>
      <List
        grid={{ gutter: 16, column: 2 }}
        itemLayout="horizontal"
        dataSource={results?.rfp}
        renderItem={(p, index) => (
          <List.Item>
            <Skeleton avatar title={false} loading={isSearching} active>
              <List.Item.Meta
                avatar={
                  <>
                    <Div
                      onClick={() =>
                        window.open('/projects/' + p.rfp_id, '_blank')
                      }
                      style={{
                        width: 60,
                        height: 20,
                        marginBottom: 7,
                      }}
                    >
                      <Tooltip
                        placement="top"
                        zIndex={5000}
                        title={'Open in new page'}
                      >
                        <Button
                          style={{ width: 45, border: 0 }}
                          icon={<NewWindow width={17} height={17} />}
                        ></Button>
                      </Tooltip>
                    </Div>
                    <Div
                      onClick={() => copyIntoClipboard(p.rfp_id)}
                      style={{ width: 60, height: 30, margin: 0 }}
                    >
                      <Tooltip
                        placement="top"
                        zIndex={5000}
                        title={'Copy ID To Clipboard'}
                      >
                        <Button
                          style={{
                            width: 45,
                            border: 0,
                          }}
                          icon={<CopyOutlined />}
                        ></Button>
                      </Tooltip>
                    </Div>
                  </>
                }
                title={
                  <Link to={'/projects/' + p.rfp_id} onClick={onClose}>
                    <Typography elementTheme="body3" style={{ marginTop: 7 }}>
                      {`${p.company_name} // ${p.company_email}`}
                    </Typography>
                  </Link>
                }
                description={
                  <Div flex="row" gap={20}>
                    <Tag color={getStatusColor(p.rfp_status)}>
                      {p.rfp_status}
                    </Tag>
                    <Typography elementTheme="body3">
                      {'Created: '}
                      {getShortFormattedDate(p.rfp_created_at)}
                    </Typography>
                    <Typography elementTheme="body3">
                      {'ID: '} {getShortId(p.rfp_id)}
                    </Typography>
                  </Div>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </Div>
  );
};

export default SearchBarRfp;
