import Divider from 'antd/es/divider';
import React from 'react';
import { EditSubmissionGroupResponse } from '../../../../../../features/entities';
import useLocale from '../../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../../plugins/styled';
import Div from '../../../../../common/div';
import Typography from '../../../../../common/typography';
import getItemTranslatedLabel from '../../../../form-builder/utils/get-item-translated-label';
import FormSubmissionInput from '../form-submission-input';

interface FormSubmisionEditorGroupProps {
  group: EditSubmissionGroupResponse;
}

const FormSubmisionEditorGroup = ({ group }: FormSubmisionEditorGroupProps) => {
  const { selectedLocale } = useLocale();
  const theme = useTheme();
  return (
    <Div flex={'column'}>
      {!!group.entity.group_title_en && (
        <Typography>
          {getItemTranslatedLabel({
            label_en: group.entity.group_title_en,
            label_fr: group.entity.group_title_fr,
            fallback: '',
            parseOptions: {},
            locale: selectedLocale,
          })}
        </Typography>
      )}
      <Div flex="column">
        {React.Children.toArray(
          group.items.map((itm, k) => (
            <>
              <FormSubmissionInput editSubmissionItem={itm} />
              {k < group.items.length - 1 && (
                <Divider
                  style={{
                    borderColor: theme.colors.purple_3,
                    margin: '5px 0',
                  }}
                  variant="dotted"
                />
              )}
            </>
          )),
        )}
      </Div>
    </Div>
  );
};
export default FormSubmisionEditorGroup;
