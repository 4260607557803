import { useState } from 'react';
import { UserMenuProps } from '.';
import SubitemsListDropdown from '../../navigation/menu-item/subitems-list-dropdown';
import useHandleLinkClick from '../../navigation/utils/use-handle-link-click';
import UserMenuButton from './user-menu-button';

const DesktopUserMenu = ({ user, links, app, notifs }: UserMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownOpen = () => setIsOpen(!isOpen);
  const handleLinkClick = useHandleLinkClick({ selectedKeys: [] });

  return (
    <div style={{ position: 'relative' }}>
      <UserMenuButton
        user={user}
        withArrow
        onClick={handleDropdownOpen}
        app={app}
        notifs={notifs}
      />

      <SubitemsListDropdown
        isActive={isOpen}
        position="bottom"
        items={links}
        app={'client'}
        handleDropdownMouseLeave={handleDropdownOpen}
        isDropdown
        handleLinkClick={handleLinkClick}
        size="large"
        removeBorder
        style={{ width: '100%', paddingTop: 8, top: 'calc(100% + 8px)' }}
      />
    </div>
  );
};

export default DesktopUserMenu;
