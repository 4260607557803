import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Badge from 'antd/es/badge';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { AppType, HdUser } from '../../../../features/entities/core-entities';
import Avatar from '../../../common/avatar';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import { UserMenuButtonStyled } from './styles';

const UserMenuButton = ({
  withArrow,
  user,
  onClick,
  app,
  isOnNav,
  notifs,
}: {
  withArrow?: boolean;
  user: HdUser;
  onClick?: () => void;
  app: AppType;
  isOnNav?: boolean;
  notifs?: number;
}) => {
  const theme = useTheme();
  const isReversed = useMemo(
    () => (isOnNav ? app === 'provider' : app === 'admin'),
    [app, isOnNav],
  );
  return (
    <UserMenuButtonStyled $isOnNav={isOnNav} $app={app} onClick={onClick}>
      <Div gap={16} flex="row" align="center">
        <Badge count={notifs} size="small" offset={[-2, 6]}>
          <Avatar
            src={user.logo}
            shape="rounded-corner"
            size={38}
            style={{
              background: isReversed
                ? theme.colors.white_1
                : theme.colors.primary,
              color: isReversed ? theme.colors.primary : theme.colors.white_1,
            }}
          >
            {user?.name?.[0]?.toUpperCase()}
          </Avatar>
        </Badge>

        <Typography nowrap overflow medium elementTheme="body1">
          {user?.name}
        </Typography>
      </Div>
      {withArrow && <TabArrow down size={16} />}
    </UserMenuButtonStyled>
  );
};

export default UserMenuButton;
