import Div from '@hellodarwin/core/lib/components/common/div';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  getShortId,
  getStringDate,
} from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Empty from 'antd/es/empty';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  fetchAllFormSubmissionByCompanyId,
  selectAllFormsSubmissions,
  selectFormIsLoading,
} from '../../../../features/api/slices/forms-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import CardLayout from '../../../gins/sidebars/cards/card-layout';

interface CompanyFormsSubmissionsListProps {
  company_id?: string;
}
const CompanyFormsSubmissionsList: React.FC<
  CompanyFormsSubmissionsListProps
> = ({ company_id }) => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) =>
    selectFormIsLoading(state, 'fetchSubmissions'),
  );

  const submissions = useAppSelector(selectAllFormsSubmissions);

  useEffect(() => {
    if (!!company_id && company_id !== '') {
      dispatch(
        fetchAllFormSubmissionByCompanyId({ api, companyId: company_id }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company_id]);

  if (isLoading) return <Loading />;
  return (
    <Div flex="column" gap={8}>
      {!!submissions?.length ? (
        submissions.map((submission) => (
          <CardLayout
            title={
              submission.title ?? getShortId(submission.form_submission_id)
            }
            infos={[
              {
                Icon: Timeline,
                value: getStringDate(
                  new Date(`${submission.created_at}`),
                  selectedLocale,
                ),
              },
            ]}
            to={`/forms/submissions/${submission.form_submission_id}`}
          />
        ))
      ) : (
        <Empty description={t('singleCompanyPage|noFormSubmission')} />
      )}
    </Div>
  );
};

export default CompanyFormsSubmissionsList;
