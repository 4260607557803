import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useAppSelector } from '../../app/app-hooks';
import CreateFormBuilder from '../../components/forms/single-form/create-form-builder';
import { selectFormIsLoading } from '../../features/api/slices/forms-slice';

const CreateFormPage = () => {
  const isLoading = useAppSelector(selectFormIsLoading);
  const { t } = useTranslations();
  return (
    <PageLayout
      noPadding
      app="admin"
      tabTitle={t('forms_list|createForm')}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Forms',
          path: '/forms',
        },
        {
          breadcrumbName: t('forms_list|createForm'),
        },
      ]}
      isLoading={isLoading}
    >
      <CreateFormBuilder />
    </PageLayout>
  );
};

export default CreateFormPage;
