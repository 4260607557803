import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import { GrantResult } from '@hellodarwin/core/lib/features/entities/grants-entities';
import {
  Program,
  ProgramGrantStatus,
  ProgramSource,
  ProgramType,
} from '@hellodarwin/core/lib/features/entities/programs-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Form, { useForm } from 'antd/es/form/Form';
import InputNumber from 'antd/es/input-number';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../app/app-hooks';
import { selectAuthentifiedUser } from '../../../../../../../features/api/slices/global-slice';
import { createProgram } from '../../../../../../../features/api/slices/programs-slice';
import { useAdminApi } from '../../../../../../../features/api/use-admin-api';
import GrantsSearch from '../../../../../../grants/grants-search';
import { ProjectApplicationFormProps } from '../../../types';

const ERROR_MESSAGE = 'Something went wrong, try again later!';

const ProjectCreateApplicationForm: React.FC<ProjectApplicationFormProps> = ({
  companyId,
  projectId,
  handleCancel,
  handleSaveApplication,
}) => {
  const { t } = useTranslations();
  const [form] = useForm();

  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);

  const [selectedGrant, setSelectedGrant] = useState<GrantResult | undefined>();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<
    Contact[] | undefined
  >();
  const user = useAppSelector(selectAuthentifiedUser);

  const initContacts = async (companyId: string) => {
    try {
      const response = await api.queryCompanyContacts(companyId, 1, 20, '');
      setContacts(response);
    } catch (e: any) {
      message.error('Something went wrong, try again later!');
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!companyId && companyId !== '') {
      initContacts(companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const onFinish = async (values: any) => {
    setIsSaving(true);
    try {
      let createdProgram: Program = {
        ...values,
      };

      if (companyId) {
        createdProgram.program_company_id = companyId;
      }
      if (projectId) {
        createdProgram.program_project_id = projectId;
      }

      createdProgram.program_name = selectedGrant?.grant_title;
      if (selectedContacts && selectedContacts.length > 0) {
        createdProgram.program_contact_id = selectedContacts[0].contact_id;
        createdProgram.contacts = selectedContacts;
        createdProgram.notification_contacts = selectedContacts;
      }

      createdProgram.program_type = ProgramType.GrantsRoadmap;
      createdProgram.program_account_manager = user.admin_id;

      dispatch(createProgram({ api, createdProgram }))
        .then(({ meta: { requestStatus }, payload }) => {
          setIsSaving(false);

          if (requestStatus === 'fulfilled') {
            setSelectedGrant(undefined);
            handleCancel();
            message.success('Saved!');
            handleSaveApplication(payload as Program);
          } else {
            message.error(ERROR_MESSAGE);
          }
        })
        .catch((e) => {
          message.error(ERROR_MESSAGE + e.message);
        });
    } catch (e: any) {
      setIsSaving(false);
      message.error(ERROR_MESSAGE + e.message);
      console.error(e);
    }
  };

  const handleSubmitGrant = (grant: GrantResult) => {
    setSelectedGrant(grant);
    form.setFieldValue('program_grant_id', grant.grant_id);
  };

  return (
    <Div flex="column" gap={16}>
      <Typography elementTheme="h5">
        {t('createApplication|create_application_title')}
      </Typography>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        name="create-program"
        style={{ flexDirection: 'column', display: 'flex', gap: 12 }}
      >
        <FormItem
          label={t('createApplication|program_source')}
          name="program_source"
          rules={[
            {
              required: true,
              message: t('createApplication|missing_program_source'),
            },
          ]}
        >
          <Select
            onChange={(e) => {
              form.setFieldValue('program_source', e);
            }}
            size="large"
          >
            {(Object.keys(ProgramSource) as (keyof typeof ProgramSource)[]).map(
              (key, index) => (
                <Select.Option key={index} value={ProgramSource[key]}>
                  {ProgramSource[key]}
                </Select.Option>
              ),
            )}
          </Select>
        </FormItem>
        <FormItem
          label={t('createApplication|application_program')}
          name="program_grant_id"
          rules={[
            {
              required: true,
              message: t('createApplication|missing_application_program'),
            },
          ]}
        >
          <Div flex="column" gap={8}>
            <GrantsSearch handleSubmit={handleSubmitGrant} noStyle />
            {selectedGrant && (
              <HdTag
                color={theme.colors.purple_2}
                text={
                  selectedGrant.grant_display_title
                    ? selectedGrant.grant_display_title.length > 20
                      ? selectedGrant.grant_display_title?.substring(0, 20) +
                        '...'
                      : selectedGrant.grant_display_title
                    : ''
                }
                onClose={() => {
                  setSelectedGrant(undefined);
                  form.setFieldValue('program_grant_id', undefined);
                }}
              />
            )}
          </Div>
        </FormItem>
        <FormItem
          label={t('createApplication|status')}
          name="program_grant_status"
          onReset={() =>
            form.setFieldValue(
              'program_grant_status',
              'identified_opportunitites',
            )
          }
        >
          <Select
            onChange={(e) => {
              form.setFieldValue('program_grant_status', e);
            }}
            size="large"
          >
            {(
              Object.keys(
                ProgramGrantStatus,
              ) as (keyof typeof ProgramGrantStatus)[]
            ).map((key, index) => (
              <Select.Option key={index} value={ProgramGrantStatus[key]}>
                {t(`programs_labels|${ProgramGrantStatus[key]}`)}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={t('createApplication|contact')}
          name="notifications_contacts"
          style={{ marginBottom: 0, paddingBottom: 0 }}
          rules={[
            {
              required: true,
              message: t('createApplication|minimum_contact'),
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            filterOption={false}
            showSearch
            placeholder={t('createApplication|search_company_contacts')}
            defaultActiveFirstOption={false}
            onChange={(values) => {
              const selectedContacts = contacts.filter((c) =>
                values.includes(c.contact_id),
              );
              setSelectedContacts(selectedContacts);
              form.setFieldValue('notification_contacts', values);
              if (companyId) {
                const value = values.length > 0 ? values[0] : 'placeholder';
                form.setFieldValue('program_contact_id', value);
              }
            }}
            size="large"
          >
            {contacts.map((c) => (
              <Select.Option key={c.contact_id} value={c.contact_id}>
                {c.name} / {c.email}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={t('createApplication|targetedAmount')}
          name="program_targeted_amount"
        >
          <InputNumber size="large" style={{ width: '100%' }} />
        </FormItem>
        <Button
          htmlType="submit"
          defaultStyle={theme.colors.purple_2}
          style={{ alignSelf: 'flex-end', marginBlockStart: 16 }}
          loading={isSaving}
        >
          {t('createApplication|button_create')}
        </Button>
      </Form>
    </Div>
  );
};

export default ProjectCreateApplicationForm;
