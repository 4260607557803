import Container from '@hellodarwin/core/lib/components/common/container';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { TabsProps } from 'antd/es/tabs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import GrantApplicationsList from '../../components/grants/grant-applications-list';
import GrantProvidersList from '../../components/grants/grant-providers-list';
import GrantsList from '../../components/grants/grants-list';
import {
  selectActiveTab,
  setActiveTab,
} from '../../features/api/slices/grants-slice';
import GrantsValidationList from '../../components/grants/grants-validation-list';
type GrantsPageTab = 'grants' | 'grants-providers' | 'grants-applications';

const GrantsPage = ({ defaultKey }: { defaultKey?: GrantsPageTab }) => {
  const activeTab = useAppSelector(selectActiveTab);
  const dispatch = useAppDispatch();
  const { t } = useTranslations();

  const tabs: TabsProps['items'] = [
    {
      key: 'grants',
      label: t('programs_admin|programsTitle'),
      children: <GrantsList />,
    },
    {
      key: 'grants-validation',
      label: t('programs_admin|programs_validation_title'),
      children: <GrantsValidationList />,
    },
    {
      key: 'grants-providers',
      label: t('programs_admin|programsProviders'),
      children: <GrantProvidersList />,
    },
    {
      key: 'grants-applications',
      label: t('programs_admin|programsApplication'),
      children: <GrantApplicationsList />,
    },
  ];

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab('grants'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!!defaultKey) {
      dispatch(setActiveTab(defaultKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultKey]);

  const navigate = useNavigate();
  const handleTabChange = (key: string) => {
    switch (key) {
      case 'grants':
        navigate('/grants');
        break;
      case 'grants-providers':
        navigate('/grants/providers');
        break;
      case 'grants-applications':
        navigate('/grants/applications');
        break;
      default:
        break;
    }
    dispatch(setActiveTab(key));
  };

  return (
    <PageLayout
      app="admin"
      title={t('programs_admin|programsTitle')}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('programs_admin|programsTitle'),
        },
      ]}
    >
      <Container
        activeTab={activeTab}
        tabs={tabs}
        handleTabClick={handleTabChange}
        fullWidth
      />
    </PageLayout>
  );
};

export default GrantsPage;
