import Close from '@hellodarwin/icons/dist/icons/Close';
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { useTranslation } from '../../../plugins/i18n';
import { StyledTag } from './styles';

export interface TagEventHandlers {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClose?: () => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onPointerEnter?: MouseEventHandler<HTMLDivElement>;
  onPointerLeave?: MouseEventHandler<HTMLDivElement>;
}

export interface TagIconProps {
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
}
export interface TagThemeProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  shape?: 'round' | 'square';
  style?: CSSProperties;
  hoverable?: boolean;
  withBorder?: boolean;
  highContrastText?: boolean;
}

export interface TagContentProps {
  text: ReactNode;
  translateTag?: boolean;
}

export type TagProps = TagEventHandlers &
  TagThemeProps &
  TagIconProps &
  TagContentProps;

const HdTag = ({
  text,
  icon,
  color,
  size,
  shape,
  translateTag,
  style,
  onClick,
  onClose,
  hoverable,
  iconPosition,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  highContrastText,
  withBorder,
}: TagProps) => {
  const { t: translateTags } = useTranslation(['tags'], { useSuspense: false });

  return (
    <StyledTag
      onClick={!!onClose && !onClick ? onClose : onClick}
      $size={size}
      $color={color}
      $shape={shape}
      style={style}
      $hoverable={hoverable}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      $withBorder={withBorder}
      $highContrastText={highContrastText}
    >
      {iconPosition !== 'end' && icon}
      <span>
        {translateTag ? translateTags(`tags|${text}`).toUpperCase() : text}
      </span>
      {iconPosition === 'end' && icon}
      {!!onClose && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <Close style={{ flexShrink: 0, flexGrow: 0 }} height={8} width={8} />
        </span>
      )}
    </StyledTag>
  );
};

export default HdTag;
