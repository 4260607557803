import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';

const validateSlug =
  (message: string) => (_: RuleObject, value: StoreValue) => {
    // Slug regex: only lowercase letters, numbers, hyphens, and underscores
    var slugRegex = /^[a-z0-9-_]+$/;
    return slugRegex.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error(message));
  };

export default validateSlug;
