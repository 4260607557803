import {
  AdminRfpRequest,
  AdminTag,
  ApiError,
  ApiErrorInitialState,
  Match,
  MatchmakingNotification,
  Message,
  Rfp,
  RfpInitialState,
  RfpSearchResult,
  ServiceTag,
  SpecialtyTag,
} from '@hellodarwin/core/lib/features/entities';
import MatchSource from '@hellodarwin/core/lib/features/enums/match-source';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

export const queryRfps = createAsyncThunk<
  RfpSearchResult[],
  {
    api: AdminApiClient;
    page: number;
    size: number;
    query: string;
  },
  { rejectValue: ApiError }
>(
  'admin/queryRfps',
  async (
    {
      api,
      page,
      size,
      query,
    }: { api: AdminApiClient; page: number; size: number; query: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.queryRfp(page, size, query);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const fetchRfpEmailActivity = createAsyncThunk<
  Message[],
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchEmailActivity',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchRfpEmailActivity(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchJourneyMatchmakingNotifications = createAsyncThunk<
  MatchmakingNotification[],
  { api: AdminApiClient; journeyId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchNotificationsByJourneyID',
  async (
    { api, journeyId }: { api: AdminApiClient; journeyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getMatchmakingNotificationsByJourneyID(journeyId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRfp = createAsyncThunk<
  Rfp,
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchRfp',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getRfpById(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRfp = createAsyncThunk<
  Rfp,
  { api: AdminApiClient; rfp: AdminRfpRequest },
  { rejectValue: ApiError }
>(
  'admin/updateRfp',
  async (
    { api, rfp }: { api: AdminApiClient; rfp: AdminRfpRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateRfp(rfp);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const activateRfp = createAsyncThunk<
  Rfp,
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/activateRfp',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.activateRfp(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRfpMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchRfpMatches',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchRfpMatches(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminRefuseMatch = createAsyncThunk<
  Match,
  {
    api: AdminApiClient;
    matchId: string;
    refusedReason: string;
    refusedReasonSpecified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/refuse',
  async (
    {
      api,
      matchId,
      refusedReason,
      refusedReasonSpecified,
    }: {
      api: AdminApiClient;
      matchId: string;
      refusedReason: string;
      refusedReasonSpecified: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.adminRefuseMatch(
        matchId,
        refusedReason,
        refusedReasonSpecified,
      );
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const adminUnrefuseMatch = createAsyncThunk<
  Match,
  { api: AdminApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/unrefuse',
  async (
    { api, matchId }: { api: AdminApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.adminUnrefuseMatch(matchId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const ignoreRfp = createAsyncThunk<
  Match,
  {
    api: AdminApiClient;
    matchId: string;
    refusedReason: string;
    refuseReasonSpecified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/ignore',
  async (
    {
      api,
      matchId,
      refusedReason,
      refuseReasonSpecified,
    }: {
      api: AdminApiClient;
      matchId: string;
      refusedReason: string;
      refuseReasonSpecified: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.ignoreRfp(matchId, refusedReason, refuseReasonSpecified);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);
export const unignoreRfp = createAsyncThunk<
  Match,
  { api: AdminApiClient; matchId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/unignore',
  async (
    { api, matchId }: { api: AdminApiClient; matchId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.unignoreRfp(matchId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRfpPurchasedMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchRfpPurchasedMatches',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchRfpPurchasedMatches(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRfpRefusedMatches = createAsyncThunk<
  Match[],
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchRfpRefusedMatches',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchRfpRefusedMatches(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRfpTags = createAsyncThunk<
  AdminTag[],
  { api: AdminApiClient; rfpId: string },
  { rejectValue: ApiError }
>(
  'admin/fetchRfpTags',
  async (
    { api, rfpId }: { api: AdminApiClient; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchRfpTags(rfpId);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateRfpTags = createAsyncThunk<
  string,
  {
    api: AdminApiClient;
    rfpID: string;
    serviceTags: ServiceTag[];
    specialtyTags: SpecialtyTag[];
    industryTag: string | undefined;
  },
  { rejectValue: ApiError }
>(
  'admin/updateRfpTag',
  async (
    {
      api,
      rfpID,
      serviceTags,
      specialtyTags,
      industryTag,
    }: {
      api: AdminApiClient;
      rfpID: string;
      serviceTags: ServiceTag[];
      specialtyTags: SpecialtyTag[];
      industryTag: string | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      const tags: string[] = [];

      for (const tag of serviceTags) {
        tags.push(tag.tag);
      }

      for (const tag of specialtyTags) {
        tags.push(tag.tag);
      }

      industryTag && tags.push(industryTag);

      return await api.updateRfpTags(rfpID, tags);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleRfpCategoryTag = createAction<string>(
  'admin/toggleRfpCategoryTag',
);
export const selectRfpServiceTag = createAction<string>(
  'admin/selectRfpServiceTag',
);
export const deselectRfpServiceTag = createAction<string>(
  'admin/deselectRfpServiceTag',
);

export const setActiveTab = createAction<string>('admin/setActiveTab');
export const selectActiveTab = (state: RootState) => state.rfp.activeTab;

export const setSelectedRfp = createAction<Rfp>('admin/setSelectedRfp');
export const toggleRfpsModal = createAction<{
  isVisible: boolean;
  type: string;
}>('admin/toggleRfpsModal');

const emailActivityAdapter = createEntityAdapter({
  selectId: (model: Message) => model.to_email,
});

const rfpMatchesAdapter = createEntityAdapter({
  selectId: (model: Match) => model.provider_id,
});

const rfpsSearchAdapter = createEntityAdapter({
  selectId: (model: RfpSearchResult) => model.rfp_id,
});

const rfpsAdapter = createEntityAdapter({
  selectId: (model: Rfp) => model.rfp_id,
});

export interface RfpsState {
  status: 'idle' | 'pending';
  error: ApiError;
  emailActivity: EntityState<Message, string>;
  matchmakingNotifications: MatchmakingNotification[];
  matches: EntityState<Match, string>;
  refusedMatches: Match[];
  purchasedMatches: Match[];
  manualMatches: Match[];
  activeTab: string;
  waitingNotifications: Match[];
  rfpsSearch: EntityState<RfpSearchResult, string>;
  selectedRfp: Rfp;
  rfp: EntityState<Rfp, string>;
  modal: {
    isVisible: boolean;
    type: string;
  };
}

const initialState: RfpsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  emailActivity: emailActivityAdapter.getInitialState(),
  matchmakingNotifications: [],
  matches: rfpMatchesAdapter.getInitialState(),
  refusedMatches: [],
  purchasedMatches: [],
  manualMatches: [],
  activeTab: '',
  waitingNotifications: [],
  rfpsSearch: rfpsSearchAdapter.getInitialState(),
  selectedRfp: RfpInitialState,
  rfp: rfpsAdapter.getInitialState(),
  modal: {
    isVisible: false,
    type: '',
  },
};

const filterRfpMatches = (
  matches: Match[],
): {
  purchasedMatches: Match[];
  refusedMatches: Match[];
  manualMatches: Match[];
} => {
  const purchased: Match[] = [];
  const refused: Match[] = [];
  const manual: Match[] = [];

  for (const match of matches) {
    if (
      match?.purchased_at ||
      match.status === 'Waiting' ||
      match.raise_hand_rejected_at
    ) {
      purchased.push(match);
    }
    if (match.refused_at) {
      refused.push(match);
    }
    if (
      match.source === MatchSource.ManualMatch ||
      match.source === MatchSource.DirectoryMatch
    ) {
      manual.push(match);
    }
  }

  return {
    purchasedMatches: purchased,
    refusedMatches: refused,
    manualMatches: manual,
  };
};

const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryRfps.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(queryRfps.fulfilled, (state, { payload }) => {
      if (payload != null) {
        rfpsSearchAdapter.setAll(state.rfpsSearch, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(queryRfps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchJourneyMatchmakingNotifications.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchJourneyMatchmakingNotifications.fulfilled,
      (state, { payload }) => {
        state.matchmakingNotifications = payload;
        state.status = 'idle';
      },
    );
    builder.addCase(
      fetchJourneyMatchmakingNotifications.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      },
    );
    builder.addCase(fetchRfpEmailActivity.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfpEmailActivity.fulfilled, (state, { payload }) => {
      if (payload) emailActivityAdapter.setAll(state.emailActivity, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchRfpEmailActivity.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfpMatches.fulfilled, (state, { payload }) => {
      rfpMatchesAdapter.setAll(state.matches, payload);
      const { purchasedMatches, refusedMatches, manualMatches } =
        filterRfpMatches(payload);
      state.refusedMatches = refusedMatches;
      state.purchasedMatches = purchasedMatches;
      state.manualMatches = manualMatches;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpPurchasedMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchRfpPurchasedMatches.fulfilled,
      (state, { payload }) => {
        state.purchasedMatches = payload;
        state.status = 'idle';
      },
    );
    builder.addCase(fetchRfpPurchasedMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpRefusedMatches.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfpRefusedMatches.fulfilled, (state, { payload }) => {
      state.refusedMatches = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpRefusedMatches.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(ignoreRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(ignoreRfp.fulfilled, (state, { payload }) => {
      rfpMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(ignoreRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(unignoreRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(unignoreRfp.fulfilled, (state, { payload }) => {
      rfpMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(unignoreRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(adminRefuseMatch.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(adminRefuseMatch.fulfilled, (state, { payload }) => {
      rfpMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(adminRefuseMatch.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(adminUnrefuseMatch.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(adminUnrefuseMatch.fulfilled, (state, { payload }) => {
      rfpMatchesAdapter.updateOne(state.matches, {
        id: payload.match_id,
        changes: payload,
      });
      state.status = 'idle';
    });
    builder.addCase(adminUnrefuseMatch.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateRfpTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateRfpTags.fulfilled, (state, { payload }) => {
      state.status = 'idle';
    });
    builder.addCase(updateRfpTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(fetchRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfp.fulfilled, (state, { payload }) => {
      rfpsAdapter.upsertOne(state.rfp, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(activateRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(activateRfp.fulfilled, (state, { payload }) => {
      rfpsAdapter.upsertOne(state.rfp, payload);
      state.status = 'idle';
    });
    builder.addCase(activateRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(updateRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateRfp.fulfilled, (state, { payload }) => {
      rfpsAdapter.upsertOne(state.rfp, payload);
      state.status = 'idle';
    });
    builder.addCase(updateRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(setActiveTab, (state, { payload }) => {
      state.activeTab = payload;
    });
    builder.addCase(setSelectedRfp, (state, { payload }) => {
      state.selectedRfp = payload;
    });
    builder.addCase(toggleRfpsModal, (state, { payload }) => {
      state.modal = payload;
    });
  },
});

export const selectGroupedMatchmakingNotifications = createSelector(
  (state: RootState) => state.rfp.matchmakingNotifications,
  (_, matchmakingJourneyId) => matchmakingJourneyId,
  (entities, matchmakingJourneyId) => {
    const groupedNotifications: Record<string, MatchmakingNotification[]> = {};
    if (!entities || !matchmakingJourneyId) {
      return groupedNotifications;
    }
    const sortedNotifications = Object.values(
      entities as MatchmakingNotification[],
    )
      .filter(
        (notification) =>
          notification.matchmaking_journey_id === matchmakingJourneyId,
      )
      .sort((a, b) => b.match_score - a.match_score);

    sortedNotifications.forEach((notification) => {
      const matchId = notification.match_id;

      if (!groupedNotifications[matchId]) {
        groupedNotifications[matchId] = [];
      }

      groupedNotifications[matchId].push(notification);
    });
    return groupedNotifications;
  },
);

export const {
  selectAll: selectEmailActivity,
  selectById: selectEmailActivityByEmail,
} = emailActivityAdapter.getSelectors(
  (state: RootState) => state.rfp.emailActivity,
);

export const {
  selectAll: selectAllRfpMatches,
  selectById: selectAllMatchesById,
} = rfpMatchesAdapter.getSelectors((state: RootState) => state.rfp.matches);
export const selectRefusedMatches = (state: RootState) =>
  state.rfp.refusedMatches;
export const selectPurchasedMatches = (state: RootState) =>
  state.rfp.purchasedMatches;
export const selectManualMatches = (state: RootState) =>
  state.rfp.manualMatches;

export const selectRfpIsLoading = (state: RootState) =>
  state.rfp.status === 'pending';

export const { selectAll: selectRfps } = rfpsSearchAdapter.getSelectors(
  (state: RootState) => state.rfp.rfpsSearch,
);

export const { selectById: selectRfpById } = rfpsAdapter.getSelectors(
  (state: RootState) => state.rfp.rfp,
);

export const selectRfpsError = (state: RootState) => state.rfp.error.error_code;

export const selectRfpsModal = (state: RootState) => state.rfp.modal;
export const selectSelectedRfp = (state: RootState) => state.rfp.selectedRfp;

export const rfpReducer = rfpSlice.reducer;
