import { useMemo } from 'react';
import FileSectionGin from '../gin-single/file-section';
import { GinBlockConfig } from '../gin-single/gin-block';
import GinTextEditor from '../gin-single/gin-editor';
import GinStepsComponent from '../gin-single/gin-steps';

export enum GinGrantSinglePageSection {
  BusinessAdmissibility = 'business_eligibility',
  ActivitiesAdmissibility = 'activity_eligibility',
  ExpenseAdmissibility = 'expense_eligibility',
  Files = 'files',
  FinancialAssistance = 'financial_aid_terms',
  ProcessSummary = 'process_summary',
  ProductionStages = 'production_stages',
  Notes = 'notes',
  SelectionCriteria = 'selection_criteria',
  Other = 'other',
}

const useGinBlocks = () => {
  return useMemo(() => {
    const GinSectionsBlocks: { [section: string]: GinBlockConfig } = {
      [GinGrantSinglePageSection.BusinessAdmissibility]: {
        id: GinGrantSinglePageSection.BusinessAdmissibility,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ActivitiesAdmissibility]: {
        id: GinGrantSinglePageSection.ActivitiesAdmissibility,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ExpenseAdmissibility]: {
        id: GinGrantSinglePageSection.ExpenseAdmissibility,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.Files]: {
        id: GinGrantSinglePageSection.Files,
        Content: FileSectionGin,
        isSimpleActions: true,
      },
      [GinGrantSinglePageSection.FinancialAssistance]: {
        id: GinGrantSinglePageSection.FinancialAssistance,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ProcessSummary]: {
        id: GinGrantSinglePageSection.ProcessSummary,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.SelectionCriteria]: {
        id: GinGrantSinglePageSection.SelectionCriteria,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.Other]: {
        id: GinGrantSinglePageSection.Other,
        Content: GinTextEditor,
      },
      [GinGrantSinglePageSection.ProductionStages]: {
        id: GinGrantSinglePageSection.ProductionStages,
        Content: GinStepsComponent,
        isSimpleActions: true,
        toggleName: 'Client View',
        noHistory: true,
      },
    };

    return GinSectionsBlocks;
  }, []);
};

export default useGinBlocks;
