import Quill, { StringMap } from 'quill';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { RichTextEditorContainer } from './styles';
const Delta = Quill.import('delta');

interface RichTextEditorProps {
  initialValue: string;
  id?: string;
  onChange?: (...props: any) => void;
}

const customMatcher = (node, delta) => {
  return delta.compose(new Delta().retain(delta.length(), { header: false }));
};

const quillModules: StringMap = {
  toolbar: [
    [{ header: [3, 4, false] }],
    ['bold', 'underline', 'italic', { list: 'bullet' }, { list: 'ordered' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
    matchers: [[Node.ELEMENT_NODE, customMatcher]],
  },
};

const RichTextEditor = (props: RichTextEditorProps) => {
  const ref = useRef<ReactQuill>(null);

  const { initialValue } = props;
  const [content, setContent] = useState(initialValue);

  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);

  const handleChange = (newValue: string) => {
    !!props.onChange && props.onChange(newValue);
    setContent(newValue);
  };

  return (
    <RichTextEditorContainer
      className="text-editor"
      ref={ref}
      modules={quillModules}
      preserveWhitespace={true}
      value={content}
      onChange={handleChange}
    />
  );
};

export default RichTextEditor;
