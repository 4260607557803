import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import FormLabel from '@hellodarwin/core/lib/components/forms/form-layouts/form-label';
import validateSlug from '@hellodarwin/core/lib/components/forms/utils/validate-slug';
import {
  AdminFormResponse,
  Forms,
} from '@hellodarwin/core/lib/features/entities/form-entities';
import { GrantPreview } from '@hellodarwin/core/lib/features/entities/grants-entities';
import getShortId from '@hellodarwin/core/lib/features/helpers/get-short-id';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Save from '@hellodarwin/icons/dist/icons/Save';
import Drawer from 'antd/es/drawer';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useState } from 'react';
import Clipboard from '../../clipboard';
import GrantsSearch from '../../grants/grants-search';
import GrantsSearchSelectedList from '../../grants/grants-search-selected-list';

const FormEditButton = ({
  formResponse,
  updateForm,
}: {
  formResponse: AdminFormResponse;
  updateForm: (formResponse: Forms) => void;
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModal = () => setEditModalOpen(!editModalOpen);
  const [form] = useForm<Forms>();
  const theme = useTheme();
  const [selectedGrants, setSelectedGrants] = useState<GrantPreview[]>(
    formResponse.grants ?? [],
  );
  const { t } = useTranslations();
  useEffect(() => {
    setSelectedGrants(formResponse.grants ?? []);
  }, [formResponse.grants]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const editedForm: Forms = {
          ...values,
          form_id: formResponse.form_id,
        };

        updateForm(editedForm);
      })
      .catch((e) => console.error(e));
  };

  const handleDeleteGrant = (grant: GrantPreview) => {
    setSelectedGrants((prev) => {
      const newGrants = prev.filter(
        (grantVal) => grantVal.grant_id !== grant.grant_id,
      );
      form.setFieldValue('grants', newGrants);
      form.validateFields();
      return newGrants;
    });
  };

  return (
    <>
      <Tooltip title="Edit Form">
        <div>
          <Button
            size="square"
            trailingIcon={<Edit size={16} />}
            onClick={handleEditModal}
            defaultStyle={theme.colors.white_1}
          />
        </div>
      </Tooltip>
      <Drawer
        open={editModalOpen}
        onClose={handleEditModal}
        title={
          <Div
            align="center"
            flex="row"
            style={{ height: '100%' }}
            justify="space-between"
          >
            <Div fitContent flex="row" gap={8} align="center">
              <Typography elementTheme="subtitle1" lineClamp={3}>
                Form :
              </Typography>
              <Typography
                elementTheme="subtitle1"
                color={theme.colors.purple_1}
                colorHover={theme.colors.purple_2}
              >
                <Clipboard
                  title={getShortId(formResponse.form_id)}
                  copy={formResponse.form_id}
                />
              </Typography>
            </Div>
            <Button
              size="circle"
              defaultStyle={theme.colors.white_1}
              onClick={handleSubmit}
            >
              <Save size={16} />
            </Button>
          </Div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={formResponse}
          onFinish={handleSubmit}
        >
          <Div flex="column" gap={24}>
            <FormItem
              name={'form_type'}
              label={<FormLabel label="Form Type" />}
            >
              <Select
                options={[
                  { label: 'Qualification', value: 'qualification' },
                  { label: 'Application', value: 'application' },
                ]}
              />
            </FormItem>
            <FormItem
              name={'form_slug_en'}
              label={<FormLabel label="Slug En" />}
              rules={[
                {
                  validator: validateSlug(
                    'Invalid slug format. Only lowercase letters, numbers, hyphens, and underscores are allowed.',
                  ),
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem
              name={'form_slug_fr'}
              label={<FormLabel label="Slug Fr" />}
              rules={[
                {
                  validator: validateSlug(
                    'Invalid slug format. Only lowercase letters, numbers, hyphens, and underscores are allowed.',
                  ),
                },
              ]}
            >
              <Input />
            </FormItem>
            <FormItem name={'title_en'} label={<FormLabel label="Title En" />}>
              <Input />
            </FormItem>
            <FormItem name={'title_fr'} label={<FormLabel label="Title Fr" />}>
              <Input />
            </FormItem>
            <FormItem
              name={'subtitle_en'}
              label={<FormLabel label="Subtitle En" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name={'subtitle_fr'}
              label={<FormLabel label="Subtitle Fr" />}
            >
              <Input />
            </FormItem>
            <FormItem
              name="grants"
              label={<FormLabel label={t('programs_admin|programsTitle')} />}
              rules={[
                {
                  required: true,
                  message: 'Please select at least 1 grant',
                },
              ]}
              style={{ width: '100%' }}
            >
              <GrantsSearch
                handleSubmit={(grant) => {
                  const grant_exist = selectedGrants.find(
                    (g) => g.grant_id === grant.grant_id,
                  );

                  if (!!grant_exist) {
                    message.error('This grant is already selected');
                    return;
                  }

                  const newGrant: GrantPreview = {
                    grant_id: grant.grant_id,
                    display_title: grant.grant_display_title!,
                    verified: grant.verified!,
                  };

                  setSelectedGrants((prev) => {
                    const newGrants = [...prev, newGrant];
                    form.setFieldValue('grants', newGrants);
                    form.validateFields();
                    return newGrants;
                  });
                }}
              />
            </FormItem>
            <GrantsSearchSelectedList
              grants={selectedGrants}
              handleDeleteGrant={handleDeleteGrant}
            />
          </Div>
        </Form>
      </Drawer>
    </>
  );
};
export default FormEditButton;
