import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const TeamSize = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0007 25.504C21.138 25.8342 22.3164 26.0012 23.5006 26C25.4051 26.0027 27.2848 25.5685 28.9951 24.7306C29.0456 23.5356 28.7053 22.3566 28.0255 21.3724C27.3457 20.3882 26.3637 19.6525 25.2282 19.2767C24.0926 18.9009 22.8655 18.9055 21.7329 19.2898C20.6002 19.6742 19.6237 20.4174 18.9514 21.4067M20.0007 25.504V25.5C20.0007 24.016 19.6194 22.62 18.9514 21.4067M20.0007 25.504V25.6453C17.4348 27.1908 14.4951 28.005 11.4997 28C8.39181 28 5.48391 27.14 3.00133 25.6453L3 25.5C2.99898 23.6126 3.62613 21.7786 4.78258 20.287C5.93903 18.7955 7.559 17.7312 9.38709 17.2621C11.2152 16.793 13.1474 16.9457 14.8791 17.6961C16.6109 18.4465 18.0436 19.752 18.9514 21.4067M16.0009 8.5C16.0009 9.69347 15.5268 10.8381 14.6829 11.682C13.839 12.5259 12.6945 13 11.501 13C10.3076 13 9.16305 12.5259 8.31917 11.682C7.47528 10.8381 7.00119 9.69347 7.00119 8.5C7.00119 7.30652 7.47528 6.16193 8.31917 5.31802C9.16305 4.47411 10.3076 4 11.501 4C12.6945 4 13.839 4.47411 14.6829 5.31802C15.5268 6.16193 16.0009 7.30652 16.0009 8.5ZM27.0005 11.5C27.0005 12.4282 26.6318 13.3185 25.9754 13.9749C25.319 14.6312 24.4288 15 23.5006 15C22.5724 15 21.6822 14.6312 21.0258 13.9749C20.3695 13.3185 20.0007 12.4282 20.0007 11.5C20.0007 10.5717 20.3695 9.6815 21.0258 9.02512C21.6822 8.36874 22.5724 8 23.5006 8C24.4288 8 25.319 8.36874 25.9754 9.02512C26.6318 9.6815 27.0005 10.5717 27.0005 11.5Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamSize;
