import Modal, {
  ModalProps,
} from '@hellodarwin/core/lib/components/common/hd-modal';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import AdminSettingsForm from '../settings-page/admin-settings-form';

interface EditAdminModalProps extends Omit<ModalProps, 'children'> {
  admin: AdminProfile;
}

const EditAdminModal = ({ admin, ...props }: EditAdminModalProps) => {
  return (
    <Modal {...props}>
      <AdminSettingsForm admin={admin} noSettings />
    </Modal>
  );
};

export default EditAdminModal;
