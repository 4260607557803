import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Edit = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0919 5.0413L25.5526 2.57906C26.0656 2.06608 26.7614 1.77789 27.4868 1.77789C28.2123 1.77789 28.9081 2.06608 29.421 2.57906C29.934 3.09205 30.2222 3.7878 30.2222 4.51326C30.2222 5.23873 29.934 5.93448 29.421 6.44746L8.4614 27.4071C7.69024 28.1778 6.73924 28.7443 5.6943 29.0554L1.77777 30.2223L2.94471 26.3058C3.25581 25.2609 3.82229 24.3099 4.59301 23.5387L23.0933 5.0413H23.0919ZM23.0919 5.0413L26.9398 8.88927"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit;
