import { ReviewResult } from '../../../../features/entities';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import ReviewRatings from '../../ratings';

const ReviewOverallScore = ({ review }: { review: ReviewResult }) => {
  const theme = useTheme();
  return (
    <Div
      flex="row"
      align="center"
      justify={'center'}
      tablet={{ flex: 'column' }}
    >
      <ReviewRatings
        defaultValue={review.average}
        showValue
        showOne
        textPlacement="end"
        allowHalf
      />
    </Div>
  );
};

export default ReviewOverallScore;
