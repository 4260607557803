import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  HdChat,
  HdChatQuestion,
} from '@hellodarwin/core/lib/features/entities';
import Refresh from '@hellodarwin/icons/dist/icons/Refresh';
import RestartProject from '@hellodarwin/icons/dist/icons/RestartProject';
import Carousel, { CarouselRef } from 'antd/es/carousel';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Skeleton from 'antd/es/skeleton';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useAppDispatch } from '../../app';
import {
  addSubQuestionToChat,
  generateQuestionAnswer,
} from '../../features/api/slices/hd-chat-questions-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import theme from '../../theme';
import QuestionTextArea from './context-textarea';
import SubQuestionContext from './sub-question-context';

const { TextArea } = Input;
type QuestionContextProps = {
  question: HdChatQuestion;
  chat: HdChat;
  questionIndex: number;
  setSelectedAnswers: Dispatch<SetStateAction<Map<any, any>>>;
};

const QuestionContext = ({
  question,
  chat,
  questionIndex,
  setSelectedAnswers,
}: QuestionContextProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();

  const [carouselAnswerIndex, setAnswerIndex] = useState<number>(0);

  const [loadingStates, setLoadingStates] = useState<Set<string>>(new Set());
  const questionContexts =
    question.answers?.map((answer) => answer.context) || [];
  const contextCarouselRef = useRef<CarouselRef>(null);
  const answerCarouselRef = useRef<CarouselRef>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  const navigateCarousels = (direction: 'next' | 'prev') => {
    const newIndex =
      direction === 'next'
        ? carouselAnswerIndex === question.answers.length - 1
          ? 0
          : carouselAnswerIndex + 1
        : carouselAnswerIndex === 0
          ? question.answers.length - 1
          : carouselAnswerIndex - 1;
    setAnswerIndex(newIndex);
    contextCarouselRef.current?.goTo(newIndex);
    answerCarouselRef.current?.goTo(newIndex);
  };

  const handleCarouselChange = (currentSlide: number) => {
    if (carouselAnswerIndex !== currentSlide) {
      setAnswerIndex(currentSlide);
      contextCarouselRef.current?.goTo(currentSlide);
      answerCarouselRef.current?.goTo(currentSlide);
    }
  };

  const toggleSelectedAnswers = (answerIndex: string) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const questionFullIndex = `${questionIndex + 1}.0`;
      const newSelection = new Map(prevSelectedAnswers);
      if (
        (answerIndex !== 'none' &&
          newSelection.get(questionFullIndex) !==
            question.answers[parseInt(answerIndex)].id) ||
        answerIndex === 'none'
      ) {
        if (newSelection.has(questionFullIndex)) {
          newSelection.delete(questionFullIndex);
          question.answers.forEach((answer, answersIndex) => {
            question.answers[answersIndex].sub_questions?.forEach(
              (subquestion, subQuestionIndex) => {
                if (
                  newSelection.has(
                    `${questionIndex + 1}.${subQuestionIndex + 1}`,
                  )
                ) {
                  newSelection.delete(
                    `${questionIndex + 1}.${subQuestionIndex + 1}`,
                  );
                }
              },
            );
          });
        }
        if (answerIndex !== 'none') {
          newSelection.set(
            questionFullIndex,
            question.answers[parseInt(answerIndex)].id,
          );
        }
      }
      return newSelection;
    });
  };

  const getLoadingKey = (questionId: string, subQuestionId?: string) =>
    subQuestionId ? `${questionId}-${subQuestionId}` : questionId;

  const updateLoadingState = (key: string, isLoading: boolean) => {
    setLoadingStates((prev) => {
      const newLoadingStates = new Set(prev);
      if (isLoading) {
        newLoadingStates.add(key);
      } else {
        newLoadingStates.delete(key);
      }
      return newLoadingStates;
    });
  };

  const isLoading = (key: string) => loadingStates.has(key);

  const handleQuestionAnswerGeneration = async (
    questionId: string,
    context: string,
  ) => {
    const key = getLoadingKey(questionId);
    updateLoadingState(key, true);
    try {
      await dispatch(
        generateQuestionAnswer({
          api,
          chatId: chat.id,
          questionId,
          context: context,
        }),
      ).unwrap();
      const newAnswerIndex = question.answers?.length || 0;
      handleCarouselChange(newAnswerIndex);
    } catch (error) {
      console.error('Error generating question answer:', error);
    } finally {
      updateLoadingState(key, false);
    }
  };

  const addSubQuestion = (questionId: string, answerId: string) => {
    dispatch(
      addSubQuestionToChat({
        api,
        questionId: questionId,
        subQuestion: {
          chat_id: chat.id,
          answer_id: answerId,
          content: 'New sub-question',
        },
      }),
    );
  };

  const SkeletonTextArea = (rows: number, minHeight: number) => {
    return (
      <Skeleton
        paragraph={{ rows: rows, width: '100%' }}
        style={{
          backgroundColor: theme.colors.white_1,
          padding: 10,
          border: '1px solid',
          borderColor: theme.colors.grey_4,
          borderRadius: 5,
          minHeight: minHeight,
        }}
        active
      ></Skeleton>
    );
  };

  return (
    <>
      <Div flex="column" gap={16}>
        <Carousel
          ref={contextCarouselRef}
          draggable={false}
          dots={false}
          style={{ minHeight: 250 }}
        >
          {question.answers?.length > 0 ? (
            question.answers?.map((answer, index) => (
              <QuestionTextArea
                key={index}
                question={question}
                index={index}
                editMode={editMode}
                handleQuestionAnswerGeneration={handleQuestionAnswerGeneration}
                setEditMode={setEditMode}
                questionContexts={questionContexts}
              ></QuestionTextArea>
            ))
          ) : (
            <QuestionTextArea
              question={question}
              editMode={editMode}
              setEditMode={setEditMode}
              index={0}
              questionContexts={questionContexts}
              handleQuestionAnswerGeneration={handleQuestionAnswerGeneration}
            ></QuestionTextArea>
          )}
        </Carousel>
        <Div
          flex="row"
          align="center"
          justify="space-between"
          gap={24}
          style={{ paddingRight: 8, paddingLeft: 8 }}
        >
          <Div flex="column">
            <Paragraph className="caption" color={theme.colors.grey_3}>
              {question.prompt_name}
            </Paragraph>
            <Title level={5}>{question.content}</Title>
          </Div>
          <Div flex="row" gap={8} fitContent>
            <Button
              headingIcon={
                question.answers ? (
                  <Refresh size={24} />
                ) : (
                  <RestartProject size={24} />
                )
              }
              onClick={() =>
                handleQuestionAnswerGeneration(
                  question.id,
                  questionContexts[carouselAnswerIndex],
                )
              }
              size="auto"
              disabled={editMode}
              style={{ height: 'fit-content', padding: 8 }}
              defaultStyle={theme.colors.purple_1}
            ></Button>
          </Div>
        </Div>
        {isLoading(getLoadingKey(question.id)) ? (
          SkeletonTextArea(4, 250)
        ) : (
          <Div>
            <Carousel
              ref={answerCarouselRef}
              draggable={false}
              dots={false}
              dotPosition="top"
            >
              {question.answers?.map((answer, i) => (
                <Div key={i} style={{ minHeight: 300 }}>
                  <Div flex="column" style={{ width: '100%' }} gap={8}>
                    <TextArea
                      value={answer.answer}
                      style={{ minHeight: 250 }}
                      color={theme.colors.grey_3}
                      rows={4}
                      placeholder="Answer will appear here..."
                    />
                  </Div>
                </Div>
              ))}
            </Carousel>
            {!question.answers && (
              <Div flex="column" style={{ width: '100%' }}>
                <TextArea
                  value={'Answer will appear here...'}
                  style={{ minHeight: 250 }}
                  color={theme.colors.grey_3}
                  rows={4}
                  placeholder="Answer will appear here..."
                />
              </Div>
            )}
          </Div>
        )}
        <Div flex="row" justify="center" align="center" gap={16}>
          <Div flex="row" style={{ paddingLeft: 8 }}>
            {question.answers?.length > 0 && (
              <Div flex="row" justify="left" align="left" gap={8}>
                <Typography>Answer selected for export: </Typography>
                <Select
                  defaultValue={'none'}
                  filterOption={false}
                  defaultActiveFirstOption={true}
                  style={{ minWidth: 80 }}
                  onChange={(value) => {
                    toggleSelectedAnswers(value);
                  }}
                >
                  <Select.Option key={0} value={'none'}>
                    None
                  </Select.Option>
                  {question?.answers?.map((answer, index) => (
                    <Select.Option key={index + 1} value={index}>
                      {index + 1}
                    </Select.Option>
                  ))}
                </Select>
              </Div>
            )}
          </Div>

          <Div flex="row" justify="right" gap={8}>
            {question.answers?.length > 1 && (
              <>
                <Button
                  size="auto"
                  style={{
                    width: 32,
                    height: 32,
                    padding: 10,
                  }}
                  onClick={() => navigateCarousels('prev')}
                  withArrowLeft
                ></Button>
                {carouselAnswerIndex + 1}/{question.answers.length}
                <Button
                  size="auto"
                  style={{
                    width: 32,
                    height: 32,
                    padding: 10,
                  }}
                  onClick={() => navigateCarousels('next')}
                  withArrowRight
                ></Button>
              </>
            )}
          </Div>
        </Div>
        <Div flex="column" gap={32}>
          {!!question.answers &&
            question.answers[carouselAnswerIndex]?.sub_questions?.map(
              (subQuestion, j) => (
                <Div key={j} style={{ paddingLeft: 16 }}>
                  <SubQuestionContext
                    chat={chat}
                    questionIndex={questionIndex}
                    subQuestionIndex={j}
                    questionId={question.id}
                    subQuestion={subQuestion}
                    setSelectedAnswers={setSelectedAnswers}
                  ></SubQuestionContext>
                </Div>
              ),
            )}
        </Div>

        <Div flex="row" justify="center" align="center" gap={16}>
          <Button
            defaultStyle={theme.colors.purple_1}
            size="auto"
            style={{ padding: 10 }}
            onClick={() =>
              addSubQuestion(
                question.id,
                question.answers[carouselAnswerIndex].id,
              )
            }
          >
            Reply
          </Button>
        </Div>
      </Div>
    </>
  );
};

export default QuestionContext;
