import Budget from '@hellodarwin/icons/dist/icons/Budget';
import Language from '@hellodarwin/icons/dist/icons/Language';
import Projects from '@hellodarwin/icons/dist/icons/Projects';
import TeamSize from '@hellodarwin/icons/dist/icons/TeamSize';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Verified from '@hellodarwin/icons/dist/icons/Verified';
import dayjs from 'dayjs';
import { ProfileModalProps } from '../../../../features/entities/profile-entities';
import { Provider } from '../../../../features/entities/providers-entities';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import dollarFormat from '../../../../features/helpers/dollar-format';
import { useTranslations } from '../../../../features/providers/translations-provider';
import ProfileSection from '../profile-section';
import InformationCard from './information-card';
import { ProfileFastInformationsContainer } from './styles';

type ProfileFastInformationsProps = {
  provider: Provider;
  isEditable: boolean;
  id: string;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  locale: string;
};
const ProfileFastInformations = ({
  provider,
  isEditable,
  id,
  toggleEditModal,
  locale,
}: ProfileFastInformationsProps) => {
  const { t } = useTranslations();

  const yearsVerified = dayjs().diff(provider?.verified_at, 'years') + 1;
  const yearFounded = provider?.founded_at?.split('-')[0];

  const handleModal = () => {
    if (!!toggleEditModal) {
      toggleEditModal({ open: true, type: ProfileBlocks.FastInfos });
    }
  };

  return (
    <>
      <ProfileSection
        title={t('profile_overview|fastInformation')}
        isEditable={isEditable}
        id={id}
        handleEdit={handleModal}
      >
        <ProfileFastInformationsContainer>
          {!!yearsVerified && provider?.verified_at && (
            <InformationCard
              Icon={Verified}
              title={t('profile_overview|verify')}
              value={`${dayjs(provider?.verified_at).year()}`}
            />
          )}
          {!!provider?.rfp_total_value && (
            <InformationCard
              Icon={Projects}
              value={`${dollarFormat(provider?.rfp_total_value, locale)}$`}
              title={t('profile_overview|projectsWon')}
            />
          )}
          {(!!yearFounded || isEditable) && (
            <InformationCard
              Icon={Timeline}
              title={t('profile_overview|foundation')}
              value={yearFounded || ''}
            />
          )}
          {(!!provider?.size?.length || isEditable) && (
            <InformationCard
              Icon={TeamSize}
              value={provider?.size ?? ''}
              title={t('profile_overview|teamSize')}
            />
          )}
          {((!!provider?.hourly_rate && provider?.hourly_rate > 1) ||
            isEditable) && (
            <InformationCard
              Icon={Budget}
              title={t('profile_overview|hourlyRate')}
              value={t('profile_overview|hourlyRateValue', {
                value: provider?.hourly_rate || 0,
              })}
            />
          )}
          {(!!provider?.website?.length || isEditable) && (
            <InformationCard
              Icon={Language}
              title={t('profile_overview|website')}
              value={t('profile_overview|viewSite')}
              link={provider?.website}
              tooltip={provider?.website}
            />
          )}
        </ProfileFastInformationsContainer>
      </ProfileSection>
    </>
  );
};
export default ProfileFastInformations;
