import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ContactAdvisorModal from '../contact-advisor-modal';

const ProgramEmptyStateFooter = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => setModalOpen(!modalOpen);

  return (
    <>
      <Div flex="column" gap={32} align="center" style={{ maxWidth: 400 }}>
        <Button
          defaultStyle={theme.colors.green_1}
          fitContent
          tablet={{ fullWidth: true }}
          style={{ height: 40 }}
          onClick={handleModal}
        >
          {t('propulsion|empty.cta')}
        </Button>
      </Div>
      <ContactAdvisorModal open={modalOpen} handleCancel={handleModal} />
    </>
  );
};

export default ProgramEmptyStateFooter;
