import React, { PropsWithChildren, useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbsContextType,
} from '../entities/context-entities';
import { arraysOfObjectsEqual } from '../helpers/arrays-equal';

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextType>({
  setBreadCrumbs: (crumbs) => {},
  breadCrumbs: [],
});

const BreadcrumbsProvider = ({ children }: PropsWithChildren) => {
  const [breadCrumbs, setBreadCrumbs] = React.useState<Breadcrumb[]>([]);

  const handleBreadcrumbs = (crumbs: Breadcrumb[]) => {
    if (!arraysOfObjectsEqual(crumbs, breadCrumbs)) {
      setBreadCrumbs(crumbs);
    }
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadCrumbs,
        setBreadCrumbs: handleBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);
export default BreadcrumbsProvider;
