import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import theme from '@hellodarwin/core/lib/theme';
import message from 'antd/es/message';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  selectGrantValidation,
  updateGrantValidation,
  getGrantValidations,
} from '../../../features/api/slices/grants-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

interface GrantValidationButtonProps {
  grantId: string;
  filterByType: string;
}

const GrantValidationButton: React.FC<GrantValidationButtonProps> = ({
  grantId,
  filterByType,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const grantValidations = useAppSelector(selectGrantValidation);
  const [validationStates, setValidationStates] = useState(grantValidations);

  useEffect(() => {
    const updatedStates = grantValidations?.map((validation) => ({
      ...validation,
      isValidated: !!validation.validatedAt,
    }));
    setValidationStates(updatedStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantValidations]);

  const handleValidateClick = async (id: string) => {
    const updatedStates = validationStates.map((validation) =>
      validation.grantValidationId === id
        ? {
            ...validation,
            isValidated: true,
            validatedAt: new Date().toISOString(),
          }
        : validation,
    );
    setValidationStates(updatedStates);

    const updatedValidation = updatedStates.find(
      (validation) => validation.grantValidationId === id,
    );

    if (updatedValidation) {
      await dispatch(
        updateGrantValidation({
          api,
          grantValidation: updatedValidation,
        }),
      );
      message.success('Validation successful!');
    }
  };

  useEffect(() => {
    dispatch(getGrantValidations({ api, grantId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredValidations = filterByType
    ? validationStates.filter((validation) => validation.type === filterByType)
    : validationStates;

  return (
    <Div flex="column" align="center" gap={8} style={{ width: 'auto' }}>
      <Div flex="row" gap={8}>
        {filteredValidations.map((validation) => (
          <Div
            key={validation.grantValidationId}
            flex="row"
            align="center"
            gap={8}
            fitContent
          >
            {validation.validatedAt ? (
              <HdTag
                text="Validated"
                color={theme.colors.green_4}
                highContrastText
              />
            ) : (
              <Button
                defaultStyle={theme.colors.purple_3}
                size="extra-small"
                onClick={() =>
                  handleValidateClick(validation.grantValidationId)
                }
              >
                Validate
              </Button>
            )}
          </Div>
        ))}
      </Div>
    </Div>
  );
};

export default GrantValidationButton;
