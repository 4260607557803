import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Phone = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60001 8.24613C1.60001 20.481 11.519 30.4 23.7539 30.4H27.0769C27.9583 30.4 28.8035 30.0499 29.4267 29.4267C30.0499 28.8035 30.4 27.9582 30.4 27.0769V25.0506C30.4 24.2885 29.8816 23.6239 29.1417 23.4392L22.6092 21.8058C21.9594 21.6433 21.2771 21.887 20.8768 22.4216L19.4442 24.3313C19.0277 24.8866 18.3084 25.1318 17.6571 24.8925C15.2392 24.0036 13.0434 22.5997 11.2218 20.7781C9.40024 18.9565 7.99637 16.7608 7.10745 14.3429C6.86819 13.6915 7.11336 12.9723 7.66868 12.5558L9.57834 11.1232C10.1145 10.7229 10.3567 10.0391 10.1942 9.39074L8.56074 2.85831C8.47086 2.49897 8.26347 2.17997 7.97153 1.95198C7.67959 1.72399 7.31984 1.6001 6.94942 1.59998H4.92308C4.04175 1.59998 3.19651 1.95008 2.57331 2.57328C1.95011 3.19648 1.60001 4.04172 1.60001 4.92305V8.24613Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;
