import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';

const GrantCriteria = ({ selection_criteria }: WebsiteGrantResult) => {
  if (!selection_criteria) return <></>;
  return <Div>{parse(selection_criteria)}</Div>;
};

export default GrantCriteria;
