import Tooltip from 'antd/es/tooltip';
import { CSSProperties } from 'react';
import copyToClipboard from '../../../features/helpers/copy-to-clipboard';

type QuickActionProps = {
  copy: string;
  title: string;
  tooltip?: string;
  styles?: CSSProperties;
};

export const copyIntoClipboard = (text: string) => {
  copyToClipboard(text);
};

const Clipboard = ({
  copy,
  title,
  tooltip = 'Copy to clipboard',
  styles,
}: QuickActionProps) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-block',
      }}
    >
      <Tooltip placement="bottom" title={tooltip}>
        <div style={styles} onClick={() => copyIntoClipboard(copy)}>
          {title}
        </div>
      </Tooltip>
    </div>
  );
};

export default Clipboard;
