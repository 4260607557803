import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const ProjectTags = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.524 3.44295L22.5346 2.08167C23.3653 1.89355 24.2428 2.03339 24.9741 2.47043C25.7054 2.90746 26.2306 3.6059 26.4342 4.41209L27.9072 10.2457C28.1108 11.0522 27.9764 11.9011 27.5326 12.6029L17.4642 28.5672C16.7296 29.732 15.2839 30.3065 13.9478 29.8349C10.5851 28.6493 7.50356 26.8077 4.89566 24.4253C3.85908 23.4789 3.73113 21.9639 4.46569 20.7992L14.5331 4.83649C14.7526 4.48794 15.0414 4.18546 15.383 3.94634C15.7246 3.70723 16.1123 3.53617 16.524 3.44295Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M22.514 6.37111L22.5041 6.37335L22.5066 6.38295L22.5165 6.38071L22.514 6.37111Z"
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProjectTags;
