import classNames from 'classnames';
import { ReactNode } from 'react';
import Div from '../../common/div';
import Typography from '../../common/typography';

type ReviewFlowLayoutProps = {
  title: ReactNode;
  description: string;
  children: ReactNode;
  leftButtons?: ReactNode;
  rightButtons?: ReactNode;
  className?: string;
};

const ReviewFlowLayout = ({
  title,
  description,
  children,
  leftButtons,
  rightButtons,
  className,
}: ReviewFlowLayoutProps) => {
  const classes = classNames('fade-in', className);

  return (
    <Div
      className={classes}
      style={{ width: 1000, maxHeight: '800px', maxWidth: '100%' }}
      flex="column"
      gap={32}
      align="center"
    >
      <Div flex="column" gap={12}>
        <Typography.Title level={2} elementTheme="h4" textAlign="center">
          {title}
        </Typography.Title>
        <Typography
          elementTheme="body1"
          textAlign="center"
          className="step-description"
        >
          {description}
        </Typography>
      </Div>
      <Div flex="column" gap={32}>
        {children}
        {(leftButtons || rightButtons) && (
          <Div
            flex="row"
            gap={16}
            justify="space-between"
            tablet={{ justify: 'stretch' }}
          >
            <Div flex="row" gap={16} fitContent>
              {!!leftButtons && <>{leftButtons}</>}
            </Div>
            <Div flex="row" gap={16} fitContent>
              {!!rightButtons && <>{rightButtons}</>}
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default ReviewFlowLayout;
