import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  ChatType,
  GrantResult,
  HdChatPrompt,
  HdChatTemplate,
} from '@hellodarwin/core/lib/features/entities';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import Close from '@hellodarwin/icons/dist/icons/Close';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Drawer from 'antd/es/drawer';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import List from 'antd/es/list';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchGrant,
  selectGrantById,
} from '../../features/api/slices/grants-slice';
import {
  createNewTemplate,
  fetchTemplatePrompts,
  resetTemplatePrompts,
  selectAllTemplatePrompts,
  selectTemplatesLoading,
  toggleTemplatePromptsModal,
  updateTemplate,
} from '../../features/api/slices/templates-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';
import theme from '../../theme';
import GrantsSearch from '../grants/grants-search';
import TemplatePromptsModal from './template-prompts-modal';

const { TextArea } = Input;

interface TemplateDrawerProps {
  template: HdChatTemplate;
  visible: boolean;
  onClose: () => void;
}

export type TemplateFormValues = {
  grant_id: string;
  type: ChatType;
  name: string;
  content_fr: string;
  content_en: string;
  prompts: HdChatPrompt[];
};

const CreateUpdateTemplateDrawer = ({
  template,
  visible,
  onClose,
}: TemplateDrawerProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const [form] = Form.useForm<TemplateFormValues>();

  const grant = useAppSelector((state) =>
    selectGrantById(state, template?.grant_id || ''),
  );
  const prompts = useAppSelector((state) => selectAllTemplatePrompts(state));
  const [selectedGrant, setSelectedGrant] = useState<{
    grantId: string;
    title: string;
  }>({ grantId: grant?.grant_id || '', title: grant?.grant_title_en || '' });
  const [selectedType, setSelectedType] = useState<ChatType>(
    ChatType.GrantApplication,
  );
  const loading = useAppSelector(selectTemplatesLoading);
  const [editTemplateEnabled, setEditTemplateEnabled] = useState(true);
  const initialValues: TemplateFormValues = useMemo(() => {
    return {
      grant_id: template.grant_id || '',
      type: template.type || ChatType.GrantApplication,
      name: template.name || '',
      content_fr: template.content_fr || '',
      content_en: template.content_en || '',
      prompts: template.prompts || [],
    };
  }, [template]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    setSelectedType(initialValues.type);
    setSelectedGrant({
      grantId: template.grant_id || '',
      title: template.title || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, initialValues]);

  const handleTypeChange = (type: ChatType) => {
    setSelectedType(type);
    if (type !== ChatType.GrantApplication) {
      setSelectedGrant({ grantId: '', title: '' });
    }
    form.setFieldsValue({ type });
  };

  const triggerEditTemplateEnabled = () => {
    setEditTemplateEnabled(
      selectedType &&
        form.getFieldValue('name') !== '' &&
        form.getFieldValue('content_en') !== '' &&
        form.getFieldValue('content_fr') !== '' &&
        (selectedType === ChatType.GrantApplication
          ? selectedGrant.grantId !== ''
          : true),
    );
  };

  useEffect(() => {
    triggerEditTemplateEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, selectedGrant]);

  useEffect(() => {
    if (template.grant_id) {
      dispatch(fetchGrant({ api: newApi, grantId: template.grant_id || '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.grant_id]);

  useEffect(() => {
    if (template.id) {
      dispatch(
        fetchTemplatePrompts({ api, templateId: template.id, locale: 'en' }),
      );
    } else {
      dispatch(resetTemplatePrompts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const togglePromptsModal = async () => {
    if (!template.id) {
      const templateActionResponse = await dispatch(
        createNewTemplate({
          api: api,
          template: {
            ...form.getFieldsValue(),
            title: selectedGrant?.title || '',
          },
        }),
      ).unwrap();
      template.id = templateActionResponse.id;
      if (templateActionResponse && template.id) {
        dispatch(
          toggleTemplatePromptsModal({
            isVisible: true,
            templateId: template.id,
          }),
        );
      } else {
        message.error('Failed to create template');
      }
    } else {
      dispatch(
        toggleTemplatePromptsModal({
          isVisible: true,
          templateId: template.id,
        }),
      );
    }
  };

  useEffect(() => {
    if (grant) {
      setSelectedGrant({
        grantId: grant.grant_id,
        title: grant.grant_title_en || '',
      });
    } else {
      setSelectedGrant({ grantId: '', title: '' });
    }
  }, [grant]);

  const handleNewTemplateSubmit = async () => {
    form
      .validateFields()
      .then(async () => {
        try {
          const templateActionResponse = await dispatch(
            createNewTemplate({
              api: api,
              template: {
                ...form.getFieldsValue(),
                title: selectedGrant?.title || '',
              },
            }),
          ).unwrap();
          if (templateActionResponse) {
            onClose();
            form.resetFields();
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleTemplateUpdate = async () => {
    if (!template.id) {
      return;
    }
    form
      .validateFields()
      .then(async () => {
        try {
          const templateActionResponse = await dispatch(
            updateTemplate({
              api: api,
              template: {
                ...form.getFieldsValue(),
                content: form.getFieldValue('content_en'),
                id: template.id,
                created_at: template.created_at,
                updated_at: template.updated_at,
                type: selectedType,
                title: selectedGrant?.title || '',
                grant_id: selectedGrant?.grantId || '',
              },
            }),
          ).unwrap();
          if (templateActionResponse) {
            onClose();
            form.resetFields();
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onGrantSelect = (grant: GrantResult | null) => {
    if (grant && grant.grant_id) {
      setSelectedGrant({
        grantId: grant.grant_id,
        title: grant.grant_title || '',
      });
      form.setFieldsValue({ grant_id: grant.grant_id });
    } else {
      setSelectedGrant({ grantId: '', title: '' });
      form.setFieldsValue({ grant_id: '' });
    }
  };

  return (
    <Drawer
      title={template.id ? 'Update Template' : 'Add New Template'}
      size="large"
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
      extra={
        template.id ? (
          <Button
            size="small"
            onClick={handleTemplateUpdate}
            loading={loading}
            defaultStyle={theme.colors.purple_1}
          >
            Update
          </Button>
        ) : (
          <Button
            size="small"
            onClick={handleNewTemplateSubmit}
            loading={loading}
            defaultStyle={theme.colors.purple_1}
          >
            Submit
          </Button>
        )
      }
    >
      <Div flex="column" gap={16}>
        <Form
          form={form}
          onChange={triggerEditTemplateEnabled}
          layout="vertical"
          initialValues={initialValues}
          name="templates"
        >
          <Form.Item
            label={'Type'}
            name={'type'}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please add type'));
                },
              }),
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Select
              onChange={handleTypeChange}
              placeholder="Select type"
              style={{ width: '100%' }}
            >
              {Object.entries(ChatType).map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Template Name'}
            name={'name'}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please add template name'));
                },
              }),
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input
              onChange={(e) => form.setFieldValue('name', e.target.value)}
              placeholder="Enter prompt name"
            />
          </Form.Item>
          <Form.Item
            label={'Content FR'}
            name={'content_fr'}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please add french content'));
                },
              }),
              {
                required: true,
                message: '',
              },
            ]}
          >
            <TextArea
              onChange={(e) => form.setFieldValue('content_fr', e.target.value)}
              rows={4}
              placeholder="Enter french context here..."
              style={{ padding: 16, width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={'Content EN'}
            name={'content_en'}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Please add english content'),
                  );
                },
              }),
              {
                required: true,
                message: '',
              },
            ]}
          >
            <TextArea
              onChange={(e) => form.setFieldValue('content_en', e.target.value)}
              rows={4}
              placeholder="Enter english context here..."
              style={{ padding: 16, width: '100%' }}
            />
          </Form.Item>
          {selectedType === ChatType.GrantApplication && (
            <Form.Item
              label={'Grant'}
              name={'grant_id'}
              initialValue={grant}
              rules={[
                {
                  required: true,
                  message: 'Please select a grant',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Div flex="column" align="center" justify="center" gap={16}>
                {selectedGrant?.grantId && selectedGrant?.title && (
                  <Div
                    flex="row"
                    justify="space-between"
                    align="center"
                    gap={16}
                    borderColor={theme.colors.black}
                    borderRadius={24}
                    style={{ width: '100%', padding: 14 }}
                  >
                    <Div fitContent flex="row" gap={16} align="center">
                      <Typography.Title
                        noMargin
                        level={4}
                        elementTheme="h6"
                        fitContent
                        bold
                      >
                        {'Grant'}
                      </Typography.Title>
                      <Typography.Paragraph fitContent>
                        <Link
                          target="_blank"
                          to={`/${form.getFieldValue('type')}s/${
                            selectedGrant.grantId
                          }`}
                        >
                          {getShortId(selectedGrant.grantId)}
                        </Link>{' '}
                        - {selectedGrant.title}
                      </Typography.Paragraph>
                    </Div>
                    <Div
                      fitContent
                      onClick={() => onGrantSelect(null)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Close width={14} />
                    </Div>
                  </Div>
                )}
                <GrantsSearch handleSubmit={onGrantSelect} />
              </Div>
            </Form.Item>
          )}
          <Form.Item
            name="prompts"
            label={
              <Div flex="row" gap={16}>
                <Div>Template prompts</Div>
                <Button
                  size="auto"
                  fitContent
                  style={{ height: 'fit-content', padding: 8 }}
                  defaultStyle={theme.colors.purple_1}
                  onClick={togglePromptsModal}
                  disabled={!editTemplateEnabled}
                >
                  <Edit width={15} height={15} />
                </Button>
              </Div>
            }
          >
            <List
              dataSource={prompts}
              bordered={true}
              loading={loading}
              style={{ borderRadius: 0, maxHeight: 400, overflow: 'auto' }}
              renderItem={(p: HdChatPrompt) => (
                <List.Item>
                  <Div
                    key={p.id}
                    style={{
                      width: '100%',
                      color: theme.colors.purple_1,
                      borderColor: theme.colors.purple_1,
                      borderRadius: 5,
                      marginTop: 8,
                    }}
                  >
                    <Typography.Paragraph elementTheme="h5" medium ellipsis>
                      {p.name}
                    </Typography.Paragraph>
                    {p.content && (
                      <Typography.Paragraph elementTheme="body3" ellipsis>
                        {p.content}
                      </Typography.Paragraph>
                    )}
                  </Div>
                </List.Item>
              )}
            />
          </Form.Item>
        </Form>
        <TemplatePromptsModal prompts={prompts} type={selectedType} />
      </Div>
    </Drawer>
  );
};

export default CreateUpdateTemplateDrawer;
