import Card from '@hellodarwin/core/lib/components/common/Card';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { FormSubmissionEligilityState } from '@hellodarwin/core/lib/components/forms/form-viewer';

const FormSubmissionEligibilityCard = ({
  status,
}: {
  status?: FormSubmissionEligilityState;
}) => {
  const InfoRow = ({ label, value }: { label: string; value?: string }) => {
    if (!value) return <></>;
    return (
      <Div flex="row" gap={8} fitContent align="center">
        <Typography elementTheme="body2" style={{ fontWeight: 600 }}>
          {label}
        </Typography>

        <Typography elementTheme="body2">{value}</Typography>
      </Div>
    );
  };

  return (
    <Card
      hoverable
      style={{
        padding: 24,
        width: '100%',

        flex: 1,
      }}
    >
      <Div flex="column" gap={8}>
        <Div flex="row" gap={8} align="center" justify="space-between">
          <Typography.Title level={1} elementTheme="h6">
            Eigibility Status
          </Typography.Title>
        </Div>

        <InfoRow label="Status : " value={status} />
      </Div>
    </Card>
  );
};
export default FormSubmissionEligibilityCard;
