import HTMLReactParser, {
  attributesToProps,
  domToReact,
} from 'html-react-parser';
import addLineBreak from '../../../features/helpers/add-line-break';
import Typography, { TypographyProps } from '../typography';

type SeeMoreTextProps = {
  text: string;
  limit: number;
  elementTheme?: TypographyProps['elementTheme'];
  color?: string;
};
const SeeMoreText = ({
  text,
  limit,
  elementTheme,
  color,
}: SeeMoreTextProps) => {
  const parseOptions = {
    replace: (domNode: any) => {
      if (domNode.attribs && domNode.name === 'a') {
        const props = attributesToProps(domNode.attribs);

        return (
          <a {...props} rel="nofollow" target="_blank">
            {domToReact(domNode.children)}
          </a>
        );
      }
      if (domNode.attribs && domNode.name === 'img') {
        return <></>;
      }
    },
  };

  return (
    <Typography
      elementTheme={elementTheme}
      color={color}
      style={{ columnCount: 1, columnGap: 32 }}
      tablet={{ style: { columnCount: 1 } }}
    >
      {HTMLReactParser(addLineBreak(text), parseOptions)}
    </Typography>
  );
};
export default SeeMoreText;
