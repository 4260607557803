import Edit from '@hellodarwin/icons/dist/icons/Edit';
import { PropsWithChildren, ReactNode } from 'react';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography, { TitleProps } from '../../../common/typography';
import { ProfileSectionContainer } from '../styles';

type ProfileSectionProps = {
  title?: string;
  isEditable?: boolean;
  handleEdit?: () => void;
  id?: string;
  level?: TitleProps['level'];
  Actions?: ReactNode;
  emptyState?: ReactNode;
  hide?: boolean;
};

const ProfileSection = ({
  title,
  children,
  isEditable,
  handleEdit,
  emptyState = <></>,
  id,
  Actions,
  level = 2,
  hide,
}: ProfileSectionProps & PropsWithChildren) => {
  const theme = useTheme();

  if (!!hide && !isEditable) return <></>;
  return (
    <>
      <ProfileSectionContainer id={id}>
        {(!!title || !!Actions || isEditable) && (
          <Div
            flex="row"
            justify={title ? 'space-between' : 'flex-end'}
            align="center"
            overflow="hidden"
            tablet={{ flex: 'column', gap: 8 }}
            position="relative"
          >
            {!!title && (
              <Typography.Title
                level={level}
                elementTheme="h4"
                tablet={{ textAlign: 'center', style: { margin: '0px 24px' } }}
              >
                {title}
              </Typography.Title>
            )}

            <Div flex="row" gap={10} fitContent>
              {!!Actions && (
                <Div fitContent flex="row" gap={12}>
                  {Actions}
                </Div>
              )}
              {isEditable && !!handleEdit && (
                <Button
                  tablet={{
                    style: {
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    },
                  }}
                  onClick={handleEdit}
                  transparent
                  size="auto"
                  defaultStyle={theme.colors.primary}
                  style={{ width: 'fit-content' }}
                  headingIcon={<Edit size={18} />}
                />
              )}
            </Div>
          </Div>
        )}
        <Div>{isEditable && !!hide ? emptyState : children}</Div>
      </ProfileSectionContainer>
    </>
  );
};

export default ProfileSection;
