import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import ProjectIcon from '@hellodarwin/icons/dist/icons/ProjectIcon';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import { TableProps } from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  AdminProjectResponse,
  Project,
  ProjectGrant,
  ProjectProgram,
} from '../../../features/entities';
import { dollarFormat, getStringDate } from '../../../features/helpers';
import { getStringDateFromString } from '../../../features/helpers/get-formatted-date';
import { getProgramGrantStatusColor } from '../../../features/helpers/get-status-color';
import useLocale from '../../../features/providers/locale-provider';
import {
  GetTranslationFunc,
  useTranslations,
} from '../../../features/providers/translations-provider';
import Button from '../../common/button';
import Div from '../../common/div';
import HdTag from '../../common/hd-tag';
import TagsList from '../../common/hd-tag/tags-list';
import Typography from '../../common/typography';
import GrantCardStatusList from '../../grants/card/grant-card-status-list';
import {
  LineClampContent,
  MainProjectHeaderActions,
} from '../main-project-table/styles';
import formatRange from '../utils/format-range';
import grantsRowExpandable from '../utils/grants-row-expandable';
import projectRowExpandable from '../utils/project-row-expandable';

interface UseCompanyProjectsColumnsProps {
  handleAddProjectSubitem: (project: Project) => void;
  handleAddGrantSubitem: (grant: ProjectGrant) => void;
  handleEditProject: (project: Project) => void;
  expandedRowKeys: string[];
  onExpand: (record_id: string) => void;
  projectsOnly?: boolean;
  grantsPath: string;
  applicationsPath: string;
}

const getApplicationAmount = (
  record: ProjectProgram,
  locale: string,
): string => {
  const amount =
    record.program_accepted_amount ??
    record.program_application_amount ??
    record.program_targeted_amount;

  return !!amount ? dollarFormat(amount, locale, true) : ' - ';
};

const getApplicationAmountTooltip = (
  record: ProjectProgram,
  t: GetTranslationFunc,
): string | undefined => {
  const tooltip = !!record.program_accepted_amount
    ? t('application|application_accepted_amount')
    : !!record.program_application_amount
      ? t('application|application_applied_amount')
      : !!record.program_targeted_amount
        ? t('application|application_targeted_amount')
        : undefined;

  return tooltip;
};

const isExpanded = (record_id: string, expandedRowKeys: string[]) => {
  return expandedRowKeys.includes(record_id);
};
const RowTitle = ({
  record_id,
  isExpandable,
  expandedRowKeys,
  v,
}: {
  isExpandable?: boolean;
  expandedRowKeys: string[];
  record_id: string;
  v: string;
}) => {
  return (
    <Div flex="row" gap={8} align="center">
      {isExpandable ? (
        <TabArrow size={18} down={isExpanded(record_id, expandedRowKeys)} />
      ) : (
        <span
          style={{
            width: 18,
            flexShrink: 0,
            display: 'block',
          }}
        />
      )}
      <LineClampContent>{v}</LineClampContent>
    </Div>
  );
};

const ColumnTitleWithIcon = ({
  label,
  Icon,
}: {
  Icon: IconNode;
  label: string;
}) => {
  return (
    <Div flex="row" gap={8} align="center">
      <Icon size={18} />
      {label}
    </Div>
  );
};
const useCompanyProjectsColumns = ({
  handleAddProjectSubitem,
  handleAddGrantSubitem,
  handleEditProject,
  expandedRowKeys,
  projectsOnly,
  grantsPath,
  applicationsPath,
}: UseCompanyProjectsColumnsProps) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const projectColumns = useMemo(() => {
    const projectColumns: TableProps<AdminProjectResponse>['columns'] = [
      {
        key: 'title',
        dataIndex: 'title',

        title: (
          <ColumnTitleWithIcon
            Icon={ProjectIcon}
            label={t('project|project_title')}
          />
        ),
        render: (v, record) => (
          <RowTitle
            isExpandable={projectRowExpandable(record) && !projectsOnly}
            record_id={record.project_id}
            v={v}
            expandedRowKeys={expandedRowKeys}
          />
        ),
        width: 500,
      },

      {
        key: 'project_goals',
        dataIndex: 'project_goals',
        title: t('project|project_type'),
        render: (_, record) => (
          <TagsList
            tags={record.project_goals?.map((text) => ({ text }))}
            mainColor={theme.colors.orange_1}
            limit={1}
            collapsedTagsInTooltip
          />
        ),
        width: 300,
      },

      {
        key: 'budget',
        title: t('project|project_estimated_budget'),
        render: (_, record) => (
          <Typography elementTheme="body1" bold color={theme.colors.purple_2}>
            {dollarFormat(record.estimated_budget, selectedLocale, true)}
          </Typography>
        ),
        width: 250,
      },

      {
        key: 'timeline',
        title: t('project|project_timeline'),
        render: (_, record) =>
          formatRange({
            project: record,
            locale: selectedLocale,
            separator: selectedLocale === 'en' ? ' to ' : ' au ',
            formatValue: getStringDate,
            keys: ['started_date', 'end_date'],
          }),
        width: 400,
      },
      {
        key: 'priority',
        dataIndex: 'priority',
        title: t('project|project_priority'),
        render: (v) => (
          <Typography elementTheme="body1" bold color={theme.colors.purple_2}>
            {v}
          </Typography>
        ),
        width: 170,
        align: 'center',
      },
      {
        key: 'actions',
        title: '',
        render: (_, record) => (
          <MainProjectHeaderActions>
            <Button
              defaultStyle={theme.colors.grey_3}
              transparent
              size="square"
              headingIcon={<AddNew size={18} />}
              onClick={() => handleAddProjectSubitem(record)}
            />
            <Button
              defaultStyle={theme.colors.grey_3}
              transparent
              size="square"
              headingIcon={<Edit size={18} />}
              onClick={() => handleEditProject(record)}
            />
          </MainProjectHeaderActions>
        ),

        fixed: 'right',
      },
    ];
    return projectColumns;
  }, [expandedRowKeys, t, theme, handleAddProjectSubitem]);

  const expandProgramColumns = useMemo(() => {
    const expandProgramColumns: TableProps<ProjectProgram>['columns'] = [
      {
        title: (
          <ColumnTitleWithIcon
            Icon={Invoices}
            label={t('application|application_name')}
          />
        ),
        dataIndex: 'program_name',
        key: 'program_name',
        width: 500,
        render: (v, record) => (
          <RowTitle
            isExpandable={false}
            record_id={record.program_id}
            v={v}
            expandedRowKeys={expandedRowKeys}
          />
        ),
      },
      {
        title: t('application|application_status'),
        dataIndex: 'program_grant_status',
        key: 'program_grant_status',
        width: 300,
        render: (v) => (
          <HdTag
            color={getProgramGrantStatusColor(v, theme)}
            text={t(`programs_labels|${v}`)}
          />
        ),
      },
      {
        title: t('application|application_amount'),
        dataIndex: 'full_count',
        key: 'full_count',
        width: 250,
        render: (_, record) => (
          <Tooltip title={getApplicationAmountTooltip(record, t)}>
            <span style={{ color: theme.colors.purple_2 }}>
              {getApplicationAmount(record, selectedLocale)}
            </span>
          </Tooltip>
        ),
      },
      {
        title: t('application|application_created_on'),
        dataIndex: 'program_created_at',
        key: 'program_created_at',
        render: (v) => getStringDateFromString(v, selectedLocale),
        width: 400,
      },
      {
        key: 'actions',
        title: '',
        render: (_, record) => (
          <MainProjectHeaderActions>
            <Link to={`${applicationsPath}/${record.program_id}`}>
              <NewWindow size={18} />
            </Link>
          </MainProjectHeaderActions>
        ),

        fixed: 'right',
      },
    ];
    return expandProgramColumns;
  }, [selectedLocale, t]);

  const expandGrantColumns = useMemo(() => {
    const expandGrantColumns: TableProps<ProjectGrant>['columns'] = [
      {
        title: (
          <ColumnTitleWithIcon
            Icon={Grants}
            label={t('grant_single|grant_title')}
          />
        ),
        dataIndex: 'grant_display_title',
        key: 'grant_display_title',
        width: 500,
        render: (v, record) => (
          <RowTitle
            isExpandable={grantsRowExpandable(record)}
            record_id={record.grant_id}
            v={v}
            expandedRowKeys={expandedRowKeys}
          />
        ),
      },
      {
        title: t('grant_single|grant_status'),
        dataIndex: 'application_status',
        key: 'application_status',
        width: 300,
        render: (v) => (
          <GrantCardStatusList application_status={v} featuredTags={[]} />
        ),
      },

      {
        title: t('grant_single|openingDate'),
        key: 'opening_date',
        width: 200,
        render: (_, record) =>
          !!record.grant_timeline?.[0].opened_at
            ? getStringDate(
                record.grant_timeline?.[0].opened_at,
                selectedLocale,
              )
            : ' - ',
      },
      {
        title: t('grant_single|closingDate'),
        key: 'closing_date',
        width: 200,
        render: (_, record) =>
          !!record.grant_timeline?.[0].closed_at
            ? getStringDate(
                record.grant_timeline?.[0].closed_at,
                selectedLocale,
              )
            : ' - ',
      },
      {
        key: 'actions',
        title: '',
        render: (_, record) => (
          <MainProjectHeaderActions>
            <Link to={`${grantsPath}/${record.grant_id}`}>
              <NewWindow size={18} />
            </Link>
          </MainProjectHeaderActions>
        ),

        width: 100,
        fixed: 'right',
      },
    ];
    return expandGrantColumns;
  }, [handleAddGrantSubitem, theme, t]);

  return useMemo(() => {
    return { projectColumns, expandGrantColumns, expandProgramColumns };
  }, [
    projectColumns,
    expandProgramColumns,
    expandGrantColumns,
    applicationsPath,
    grantsPath,
  ]);
};

export default useCompanyProjectsColumns;
