import Div from '@hellodarwin/core/lib/components/common/div';
import Dropdown from '@hellodarwin/core/lib/components/common/dropdown';
import HdTag, {
  TagProps,
} from '@hellodarwin/core/lib/components/common/hd-tag';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { ItemType } from 'antd/es/menu/interface';
import Tooltip from 'antd/es/tooltip';
import React from 'react';
import { Link } from 'react-router-dom';
import CardInfoRow, { CardInfoRowProps } from './card-info-row';
import { CardLayoutContainer } from './styles';

interface CardLayoutProps {
  title: string;
  subtitle?: string;
  infos: CardInfoRowProps[];
  tag?: TagProps;
  to?: string;
  onClick?: (e: any) => void;
  dropdownItems?: ItemType[];
}

const CardLayoutContent: React.FC<CardLayoutProps> = ({
  onClick,
  tag,
  title,
  subtitle,
  infos,
  dropdownItems,
}) => {
  const theme = useTheme();
  return (
    <CardLayoutContainer onClick={onClick}>
      {!!dropdownItems?.length && (
        <Dropdown
          items={dropdownItems}
          cta={{ size: 20 }}
          styles={{ container: { position: 'absolute', right: 10, top: 10 } }}
        />
      )}
      {!!tag && <HdTag {...tag} size="small" />}
      <Div flex="column" gap={2}>
        <Tooltip title={title}>
          <div>
            <Typography elementTheme="body2" ellipsis overflow>
              {title}
            </Typography>
          </div>
        </Tooltip>
        {!!subtitle && (
          <Typography
            elementTheme="body3"
            color={theme.colors.grey_2}
            ellipsis
            overflow
          >
            {subtitle}
          </Typography>
        )}
      </Div>
      <Div flex="column" gap={6}>
        {React.Children.toArray(infos.map((info) => <CardInfoRow {...info} />))}
      </Div>
    </CardLayoutContainer>
  );
};

const CardLayout = (props: CardLayoutProps) => {
  const { to } = props;

  return to ? (
    <Link target="_blank" to={to} style={{ color: 'inherit' }}>
      <CardLayoutContent {...props} />
    </Link>
  ) : (
    <CardLayoutContent {...props} />
  );
};

export default CardLayout;
