import HdButton from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import DragDropContainer from '@hellodarwin/core/lib/components/common/drag-drop';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  ChatType,
  HdChatPrompt,
} from '@hellodarwin/core/lib/features/entities';
import BurgerMenu from '@hellodarwin/icons/dist/icons/BurgerMenu';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Button from 'antd/es/button/button';
import Modal from 'antd/es/modal/index';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  selectTemplatePromptsModal,
  toggleTemplatePromptsModal,
  updateTemplatePrompts,
} from '../../features/api/slices/templates-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import theme from '../../theme';
import PromptsSearch from './prompts-search';

type TemplatePromptsModalProps = {
  prompts: HdChatPrompt[];
  type: ChatType;
};

const TemplatePromptsModal = ({ prompts, type }: TemplatePromptsModalProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const modal = useAppSelector(selectTemplatePromptsModal);
  const [template, setTemplate] = useState<HdChatPrompt[]>(prompts);

  useEffect(() => {
    setTemplate(prompts);
  }, [prompts]);

  const handleClose = () => {
    dispatch(toggleTemplatePromptsModal({ isVisible: false, templateId: '' }));
  };

  const handleSave = async () => {
    dispatch(
      updateTemplatePrompts({
        api,
        templateId: modal.templateId,
        prompts: template,
      }),
    )
      .unwrap()
      .then(() => {
        handleClose();
      });
  };

  const removeFromTemplate = (prompt: HdChatPrompt) => {
    setTemplate(template.filter((p) => p.id !== prompt.id));
  };

  const addToTemplate = (prompt: HdChatPrompt) => {
    setTemplate([prompt, ...template]);
  };

  return (
    <Modal
      title="Edit Template Prompts"
      open={modal.isVisible}
      onCancel={handleClose}
      width={1000}
      footer={[
        <Button key="back" onClick={handleClose}>
          Return
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          Save Changes
        </Button>,
      ]}
    >
      <Div style={{ display: 'flex' }} gap={16}>
        <Div style={{ width: '100%', height: 450 }}>
          <PromptsSearch
            chatType={type}
            handleSubmit={addToTemplate}
            templatePrompts={template}
          />
        </Div>
        <DragDropContainer
          items={template}
          setItems={setTemplate}
          direction="vertical"
          renderItem={(p: HdChatPrompt) => (
            <Div
              key={p.id}
              style={{
                border: '1px solid',
                borderColor: theme.colors.purple_1,
                width: '420px',
                color: theme.colors.purple_1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 8,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                borderRadius: 5,
                backgroundColor: theme.colors.purple_6,
              }}
            >
              <BurgerMenu width={14} height={14} style={{ paddingRight: 16 }} />
              <Div
                flex="row"
                justify="space-between"
                align="center"
                fullHeight
                style={{ width: '400px' }}
              >
                <Div fullHeight flex="column" style={{ width: '100%' }}>
                  <Typography.Paragraph
                    elementTheme="subtitle1"
                    medium
                    fitContent
                    ellipsis
                    style={{ maxWidth: '350px' }}
                  >
                    {p.name}
                  </Typography.Paragraph>
                  {p.content && (
                    <Typography.Paragraph
                      elementTheme="body3"
                      fitContent
                      ellipsis
                      style={{ maxWidth: '350px' }}
                    >
                      {p.content}
                    </Typography.Paragraph>
                  )}
                </Div>
              </Div>
              <HdButton
                size="auto"
                onClick={() => removeFromTemplate(p)}
                fitContent
                defaultStyle={theme.colors.red_1}
                style={{ padding: 5, color: theme.colors.white_1 }}
              >
                <Delete width={14} height={14} />
              </HdButton>
            </Div>
          )}
          style={{
            border: '1px solid #ccc',
            alignItems: 'center',
            width: '100%',
            height: 450,
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            flexWrap: 'nowrap',
          }}
        />
      </Div>
    </Modal>
  );
};

export default TemplatePromptsModal;
