import { useEffect, useState } from 'react';
import { FilesListProps } from '..';
import {
  AssetItemType,
  AssetRecordInformation,
} from '../../../../features/entities/assets-entities';
import filterFiles from '../../../../features/helpers/filter-files';
import Div from '../../../common/div';
import RowSelector from '../../../common/row-selector';
import Typography from '../../../common/typography';
import FilesListBreadcrumb from './files-list-breadcrumb';
import FilesListOrderBySelect from './files-list-order-by';
import FilesListSortBySelect from './files-list-sort-by';

interface FilesListHeaderProps extends FilesListProps {
  setSortedItems: (items: AssetItemType[]) => void;
  setIsRow: React.Dispatch<React.SetStateAction<boolean>>;
  isRow: boolean;
  records: AssetRecordInformation[];
}

const FilesListHeader: React.FC<FilesListHeaderProps> = (props) => {
  const { items, setSortedItems, isRow, setIsRow, vaultPath, title, records } =
    props;
  const [sortType, setSortType] = useState('recent');
  const [orderType, setOrderType] = useState('descending');

  useEffect(() => {
    setSortedItems([...filterFiles(items, sortType, orderType)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  useEffect(() => {
    setSortedItems([...filterFiles(items, sortType, orderType)]);
    // eslint-disable-ne
  }, [orderType]);

  return (
    <Div flex="column" gap={8}>
      {!!title && <Typography elementTheme="subtitle2">{title}</Typography>}
      <Div
        flex="row"
        align="center"
        wrap="wrap"
        justify="space-between"
        gap={8}
        tablet={{ flex: 'column', gap: 8, align: 'flex-start' }}
      >
        <FilesListBreadcrumb vaultPath={vaultPath} records={records} />

        <Div
          flex="row"
          gap={16}
          fitContent
          align="center"
          wrap="wrap"
          tablet={{
            flex: 'column-reverse',
            gap: 8,
            align: 'flex-start',
            fitContent: false,
          }}
        >
          <RowSelector isRow={isRow} setIsRow={setIsRow} />
          <Div
            flex="row"
            gap={16}
            fitContent
            align="center"
            wrap="wrap"
            tablet={{ gap: 8, align: 'flex-start' }}
          >
            <FilesListSortBySelect onChange={setSortType} value={sortType} />
            <FilesListOrderBySelect onChange={setOrderType} value={orderType} />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default FilesListHeader;
