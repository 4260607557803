import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { ChangeEvent } from 'react';
import { useTranslation } from '../../../../plugins/i18n';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';

export interface SelectProvinceProps {
  isCanada?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SelectProvince = ({
  onChange,
  isRequired,
  isCanada,
  disabled,
}: SelectProvinceProps) => {
  const { t } = useTranslation();

  return (
    <FormItem
      label={
        <FormLabel
          label={t(
            `profile|profileForm.address.labels.${isCanada ? 'province' : 'state'}`,
          )}
        />
      }
      name="province"
      rules={[
        {
          required: isRequired,
          message: t(
            `profile|profileForm.address.validation.${isCanada ? 'province' : 'state'}`,
          ),
        },
      ]}
      style={{ minWidth: 175 }}
    >
      {isCanada ? (
        <Select
          placeholder={t(`profile|profileForm.address.extra.selectProvince`)}
          onChange={onChange}
          size="large"
          disabled={disabled}
        >
          <Select.Option value="ON">ON</Select.Option>
          <Select.Option value="QC">QC</Select.Option>
          <Select.Option value="NS">NS</Select.Option>
          <Select.Option value="NB">NB</Select.Option>
          <Select.Option value="MB">MB</Select.Option>
          <Select.Option value="BC">BC</Select.Option>
          <Select.Option value="PE">PE</Select.Option>
          <Select.Option value="SK">SK</Select.Option>
          <Select.Option value="AB">AB</Select.Option>
          <Select.Option value="NL">NL</Select.Option>
        </Select>
      ) : (
        <Input size="large" onChange={onChange} disabled={disabled}></Input>
      )}
    </FormItem>
  );
};

SelectProvince.Demo = () => {
  return <SelectProvince isCanada disabled />;
};

export default SelectProvince;
