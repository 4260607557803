export const API_URL = (() => {
  // gitlab sets this env var see client.gitlab-ci.yaml
  const env = process.env['CI_HELLODARWIN_API_URL'];

  if (env !== undefined) {
    return env;
  }

  const hostname =
    typeof window !== 'undefined' ? window?.location?.hostname : '';

  return hostname === 'localhost'
    ? 'http://localhost:8080'
    : `https://api.${hostname.substring(hostname.indexOf('.') + 1)}`;
})();

export const CONTENTFUL_API_URL = (() => {
  // gitlab sets this env var see client.gitlab-ci.yaml
  const env = process.env['CI_HELLODARWIN_CONTENTFUL_API_URL'];

  if (env !== undefined) {
    return env;
  }
  const spaceId = 'modxx330knqj';
  return `https://graphql.contentful.com/content/v1/spaces/${spaceId}`;
})();

const STRIPE_TEST_PUBLISHABLE_KEY =
  'pk_test_51IB0JjBFLFuEKur7WuOqbL4ngsdzdOr4A2Y3hlYgBAYErhJGH1buYedJGKjDdACvPokYWs87r62yKgOQ2KmzdkKn00B2c2Rthk';

export const STRIPE_PUBLISHABLE_KEY = (() => {
  // gitlab sets this env var see partner.gitlab-ci.yaml
  const env = process.env['REACT_APP_HELLODARWIN_STRIPE_PUBLISHABLE_KEY'];

  if (env !== undefined) {
    return env;
  }

  return STRIPE_TEST_PUBLISHABLE_KEY;
})();

export const GMAPS_API_KEY = (() => {
  // gitlab sets this env var see partner.gitlab-ci.yaml
  const env = process.env['REACT_APP_HELLODARWIN_GMAPS_API_KEY'];

  if (env !== undefined) {
    return env;
  }

  const env2 = process.env['GATSBY_REACT_APP_HELLODARWIN_GMAPS_API_KEY'];
  if (env2 !== undefined) {
    return env2;
  }

  return '';
})();
export const GMAPS_MAP_ID = (() => {
  // gitlab sets this env var see partner.gitlab-ci.yaml
  const env = process.env['REACT_APP_HELLODARWIN_GMAPS_ID'];

  if (env !== undefined) {
    return env;
  }

  return '';
})();

export const PLACES_API_KEY = (() => {
  // gitlab sets this env var see partner.gitlab-ci.yaml
  const env = process.env['REACT_APP_HELLODARWIN_GPLACES_API_KEY'];

  if (env !== undefined) {
    return env;
  }

  const env2 = process.env['GATSBY_REACT_APP_HELLODARWIN_GPLACES_API_KEY'];
  if (env2 !== undefined) {
    return env2;
  }

  return '';
})();
