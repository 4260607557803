import { ExpandableConfig } from 'antd/es/table/interface';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  ProjectGrant,
  ProjectProgram,
} from '../../../features/entities/projects-entities';
import { ProjectsTableLevel3 } from '../main-project-table/styles';
import { ProjectsListStateValues } from './use-projects-list-state';

interface UseExpandedGrantRowRenderProps {
  projectsListState: ProjectsListStateValues;
}

const useExpandedGrantRowRender = ({
  projectsListState,
}: UseExpandedGrantRowRenderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return useMemo(() => {
    const {
      projectsColumns: { expandProgramColumns },
    } = projectsListState;
    const expandedGrantRowRender: ExpandableConfig<ProjectGrant>['expandedRowRender'] =
      (record) => {
        return (
          <div
            style={{
              paddingInline: 24,
              paddingBlock: 16,
              background: theme.colors.grey_5,
            }}
          >
            <ProjectsTableLevel3<ProjectProgram>
              columns={expandProgramColumns.map((col, i) =>
                i === 0 ? { ...col, width: 500 - 48 } : col,
              )}
              dataSource={record.programs}
              pagination={false}
              scroll={{ x: 'max-content' }}
              rowClassName={(_r, i) => (i % 2 ? 'odd' : 'even')}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    navigate(`/programs/${record.program_id}`);
                  },
                };
              }}
            />
          </div>
        );
      };
    return expandedGrantRowRender;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsListState]);
};

export default useExpandedGrantRowRender;
