import Div from '@hellodarwin/core/lib/components/common/div';
import FormItemEditList from '@hellodarwin/core/lib/components/forms/form-builder/controllers/group/form-edit-list';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import {
  AssetEntity,
  Step,
  StepAsset,
  StepFormFunction,
} from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../app';
import { selectAllRecordAssets } from '../../../../../features/api/slices/assets-slice';
import { GinStepAssetTypeOption } from '../../../utils/gin-select-option';

export interface GinStepsAssetsFormProps {
  functions: StepFormFunction;
  grantId: string;
  setActiveStep: (step: Step | undefined) => void;
  activeStep: Step | undefined;
  setActiveAsset: (asset: StepAsset | undefined) => void;
  activeAsset: StepAsset | undefined;
}

const GinStepAssetsForm: React.FC<GinStepsAssetsFormProps> = ({
  grantId,
  functions,
  setActiveStep,
  activeStep,
  setActiveAsset,
  activeAsset,
}) => {
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();
  const [customAssetType, setCustomAssetType] = useState<boolean>(
    activeAsset?.asset_type === 'other',
  );
  const [example, setExample] = useState<AssetEntity | undefined>(
    activeAsset?.example,
  );
  const assets = useAppSelector((state) =>
    selectAllRecordAssets(state, grantId),
  );

  const stepAssetTypeOptionLabel = GinStepAssetTypeOption!.map((item) => ({
    ...item,
    label: t(`gin_steps|asset_type_${item.value}`),
  }));

  useEffect(() => {
    setExample(activeAsset?.example);
    setCustomAssetType(activeAsset?.asset_type === 'other');
  }, [activeAsset]);
  const handleSubmitFormAsset = (asset: StepAsset) => {
    asset.custom_asset_type =
      asset.asset_type === 'other' ? asset.custom_asset_type : undefined;
    asset.example = example;
    if (!!asset.step_asset_id) {
      functions.updateSelectedAsset(asset);
    } else {
      functions.createNewAsset(asset);
    }
    setActiveStep(undefined);
    setActiveAsset(undefined);
  };
  const handleDeleteAsset = (asset: StepAsset) => {
    functions.deleteSelectedAsset(asset.step_asset_id);
  };

  const exampleOptions = assets
    .map((asset) => {
      const isUsed = activeStep?.assets?.some(
        (used) => used.example?.asset_id === asset.asset_id,
      );
      if (!isUsed || asset.asset_id === example?.asset_id) {
        return { value: asset.asset_id, label: asset.display_name };
      }
      return null;
    })
    .filter(
      (option): option is { value: string; label: string } => option !== null,
    );
  return (
    <FormItemEditList<StepAsset>
      typeLabel={t('assets|file_asset')}
      options={activeStep?.assets ?? []}
      optionLabel={selectedLocale === 'en' ? 'name_en' : 'name_fr'}
      activeElement={activeAsset}
      setActiveElement={setActiveAsset}
      deleteItem={handleDeleteAsset}
      update={handleSubmitFormAsset}
      inputs={[
        {
          label: t('gin_steps|asset_type'),
          name: 'asset_type',
          rules: [{ required: true }],
          children: (
            <Div flex="column" gap={8}>
              <FormItem
                name="asset_type"
                rules={[
                  {
                    required: true,
                    message: t('gin_steps|asset_type_required'),
                  },
                ]}
              >
                <Select
                  placeholder="Select Option"
                  options={stepAssetTypeOptionLabel}
                  onChange={(value) => {
                    if (value === 'other') {
                      setCustomAssetType(true);
                    } else {
                      setCustomAssetType(false);
                    }
                  }}
                />
              </FormItem>
              {customAssetType && (
                <FormItem
                  name="custom_asset_type"
                  rules={[
                    {
                      required: true,
                      message: t('gin_steps|asset_type_required'),
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              )}
            </Div>
          ),
        },
        {
          label: t('gin_steps|asset_name_en'),
          name: 'name_en',
          rules: [
            {
              required: true,
              message: t('gin_steps|asset_name_required_en'),
            },
          ],
          children: <Input />,
        },
        {
          label: t('gin_steps|asset_name_fr'),
          name: 'name_fr',
          rules: [
            {
              required: true,
              message: t('gin_steps|asset_name_required_fr'),
            },
          ],
          children: <Input />,
        },
        {
          label: t('gin_steps|asset_example'),
          name: 'example',
          children: (
            <FormItem>
              <Select
                options={[
                  ...exampleOptions,
                  { value: 'default', label: 'No Example' },
                ]}
                value={
                  exampleOptions.some((opt) => opt.value === example?.asset_id)
                    ? example?.asset_id
                    : 'default'
                }
                onChange={(asset_id) => {
                  setExample(
                    assets.find((value) => value.asset_id === asset_id),
                  );
                }}
              />
            </FormItem>
          ),
        },
      ]}
    />
  );
};

export default GinStepAssetsForm;
