import React, { CSSProperties } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { PortfolioAsset } from '../../../../../../features/entities';
import { useTranslations } from '../../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../../plugins/styled';
import { DefaultTheme } from '../../../../../../theme';
import Div from '../../../../../common/div';
import Drop from '../../../../../common/drag-drop/drop';
import Typography from '../../../../../common/typography';
import PortfolioAssetCard from './asset-card';

type PortfolioAssetsListProps = {
  assets: PortfolioAsset[];
  setAssets: (assets: PortfolioAsset[]) => void;
};

const reorder = (
  fileList: PortfolioAsset[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(fileList);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  theme: DefaultTheme,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  width: 'fit-content',
  // change background colour if dragging
  background: isDragging ? theme.colors.purple_4 : undefined,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (
  isDraggingOver: boolean,
  theme: DefaultTheme,
): CSSProperties => ({
  background: isDraggingOver ? theme.colors.purple_5 : undefined,
  display: 'flex',
  flexWrap: 'wrap',
});

const PortfolioAssetsList = ({
  assets,
  setAssets,
}: PortfolioAssetsListProps) => {
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newAssets = reorder(
      assets,
      result.source.index,
      result.destination.index,
    );
    setAssets(newAssets);
  };

  const deleteAsset = (asset: PortfolioAsset) => {
    var temp = assets.map((el) =>
      !!asset.temp_id
        ? el.temp_id === asset.temp_id
          ? { ...el, deleted: true }
          : el
        : el.provider_portfolio_asset_id === asset.provider_portfolio_asset_id
          ? { ...el, deleted: true }
          : el,
    );

    setAssets(temp);
  };

  const DraggableList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Drop droppableId="droppable" direction="horizontal">
        {React.Children.toArray(
          assets
            .filter((asset) => !asset.deleted)
            .map((asset, index) => {
              const key =
                asset.temp_id || asset.provider_portfolio_asset_id || '';
              return (
                <Draggable key={key} draggableId={key} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        theme,
                        provided.draggableProps.style,
                      )}
                    >
                      <PortfolioAssetCard
                        asset={asset}
                        deleteAsset={deleteAsset}
                      />
                    </div>
                  )}
                </Draggable>
              );
            }),
        )}
      </Drop>
    </DragDropContext>
  );
  return (
    <Div style={{ padding: 0 }} flex="column" gap={16}>
      <DraggableList />
      <Typography
        color={theme.colors.grey_2}
        elementTheme="caption"
        inline
        style={{ padding: grid * 2 }}
      >
        {t('profile_portfolio|formDisclaimerAssets')}
      </Typography>
    </Div>
  );
};

export default PortfolioAssetsList;
