import {
  AssetEntity,
  AssetFolderEntity,
  AssetItemType,
} from '../../../features/entities';
import isAssetFolder from './is-asset-folder';

const getAssetTypeId = (asset: AssetItemType) =>
  isAssetFolder(asset)
    ? (asset as AssetFolderEntity).record_id
    : (asset as AssetEntity).asset_id;
export default getAssetTypeId;
