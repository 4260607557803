import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Filter = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.77778C20.3536 1.77778 24.6202 2.14439 28.7731 2.84918C29.6154 2.99141 30.2222 3.7278 30.2222 4.58113V6.23091C30.2222 6.69783 30.1303 7.16019 29.9516 7.59157C29.7729 8.02295 29.511 8.41492 29.1808 8.74508L20.5969 17.329C20.2668 17.6591 20.0049 18.0511 19.8262 18.4825C19.6475 18.9139 19.5556 19.3762 19.5556 19.8432V24.4685C19.5557 25.129 19.3718 25.7765 19.0246 26.3384C18.6774 26.9002 18.1805 27.3543 17.5897 27.6496L12.4444 30.2222V19.8432C12.4445 19.3762 12.3525 18.9139 12.1738 18.4825C11.9951 18.0511 11.7332 17.6591 11.4031 17.329L2.81916 8.74508C2.489 8.41492 2.2271 8.02295 2.04842 7.59157C1.86974 7.16019 1.77777 6.69783 1.77778 6.23091V4.58113C1.77778 3.7278 2.38459 2.99141 3.22687 2.84918C7.44702 2.13468 11.7198 1.77628 16 1.77778Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Filter;
