import { Portfolio } from '../../../../features/entities/core-entities';
import Modal from '../../../common/hd-modal';
import PortfolioPreview from './portfolio-preview';

export enum ModalState {
  PortfolioEdit = 'portfolioEdit',
  Empty = '',
}

type PortfolioProps = {
  open: boolean;
  handleCancel: () => void;
  portfolio?: Portfolio;
};
const PortfolioModal = ({ open, handleCancel, portfolio }: PortfolioProps) => {
  if (!portfolio) return <></>;

  return (
    <Modal open={open} handleCancel={handleCancel} size="extra-large" noPadding>
      <PortfolioPreview portfolio={portfolio} />
    </Modal>
  );
};

export default PortfolioModal;
