import Button from '@hellodarwin/core/lib/components/common/button';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import { CompanyRequest } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { useAppDispatch } from '../../../app';
import { createCompany } from '../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

interface CreateCompanyModalProps {
  open: boolean;
  handleCancel: () => void;
}

// company := entity.Company{
//   Name:            request.ContactName,
//   Email:           request.ContactEmail,
//   City:            request.CompanyCity,
//   Website:         request.CompanyWebsite,
//   Size:            request.CompanySize,
//   AnnualRevenue:   request.CompanyAnnualRevenue,
//   ProjectLanguage: entity.Language(request.ContactPreferredLanguage),
//   Phone:             request.ContactPhone,
//   Gender:            request.ContactGender,
//   PreferredLanguage: request.ProjectPreferredLanguage,
//   Title:             request.ContactTitle,
// }

const CreateCompanyModal: React.FC<CreateCompanyModalProps> = ({
  open,
  handleCancel,
}) => {
  const [form] = Form.useForm<CompanyRequest>();

  const { t } = useTranslations();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const handleSaveCompany = () => {
    form.validateFields().then((company) => {
      dispatch(createCompany({ api, company }));
    });
  };

  return (
    <Modal open={open} handleCancel={handleCancel}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSaveCompany}
        name="create-provider"
      >
        <FormItem
          label={t('createCompany|contactName')}
          name="contact_name"
          rules={[
            {
              required: true,
              message: t('createCompany|missing_contactName'),
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label={t('createCompany|contactPhone')}
          name="contact_phone"
          rules={[
            {
              required: true,
              message: t('createCompany|missing_contactPhone'),
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label={t('createCompany|contactEmail')}
          name="contact_email"
          rules={[
            {
              required: true,
              message: t('createCompany|missing_contactEmail'),
            },
          ]}
        >
          <Input />
        </FormItem>
        <FormItem
          label={t('createCompany|contactName')}
          name="contact_name"
          rules={[
            {
              required: true,
              message: t('createCompany|missing_contactName'),
            },
          ]}
        >
          <Input />
        </FormItem>
        <Button>{t('button|submit')}</Button>
      </Form>
    </Modal>
  );
};

export default CreateCompanyModal;
