export const CORE_NAMESPACES: string[] = [
  'common',
  'errors',
  'reviews',
  'provider-profile',
  'profile',
  'team',
  'portfolio',
  'tags',
  'lang',
  'confirm-modal',
  'project-single',
  'programs',
  'grants',
  'assets',
];
export type Language = 'en' | 'fr';
