import { GrantProject } from '../../../../../features/entities/grants-entities';
import dollarFormat from '../../../../../features/helpers/dollar-format';
import useLocale from '../../../../../features/providers/locale-provider';
import { useTheme } from '../../../../../plugins/styled';
import Typography from '../../../../common/typography';
import {
  GrantProjectCardContainer,
  GrantProjectCardContent,
  GrantProjectCardHeader,
} from './styles';

interface GrantProjectCardProps {
  project: GrantProject;
  open?: boolean;
  handleOpen?: () => void;
}
const GrantProjectCard = ({
  project,
  open,
  handleOpen,
}: GrantProjectCardProps) => {
  const { selectedLocale } = useLocale();
  const theme = useTheme();

  return (
    <GrantProjectCardContainer
      onClick={handleOpen}
      style={{ cursor: 'pointer' }}
      $open={open}
    >
      <GrantProjectCardHeader>
        <Typography.Title
          level={3}
          textAlign={'center'}
          elementTheme="subtitle1"
          lineClamp={3}
          color={theme.colors.purple_1}
        >
          {dollarFormat(project.amount_funded, selectedLocale, true)}
        </Typography.Title>
      </GrantProjectCardHeader>
      <GrantProjectCardContent
        elementTheme="body2"
        textAlign={'center'}
        lineClamp={!open ? 4 : 12}
      >
        {project.short_description}
      </GrantProjectCardContent>
    </GrantProjectCardContainer>
  );
};

export default GrantProjectCard;
