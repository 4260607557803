import { FormViewerType } from '..';
import {
  TranslatedFormPageResponse,
  TranslatedFormResponse,
} from '../../../../features/entities/form-entities';
import {
  getClientAppURL,
  getOrigin,
} from '../../../../features/helpers/get-website-url';
import parse from '../../../../features/helpers/parse';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import { Tablet } from '../../../common/Media';
import Typography from '../../../common/typography';
import { pageDescriptionParseOptions } from '../../form-builder/utils/form-parse-options';
import useEndPageIcon from '../../form-builder/utils/get-end-page-icon';
import FormMeeting from '../form-meeting';

interface FormCurrentPageHeaderProps {
  page: TranslatedFormPageResponse;
  form_type: TranslatedFormResponse['form_type'];
  handleClose: () => void;
  isEndPage: boolean;
  isOpening: boolean;
  viewerType: FormViewerType;
}

const FormCurrentPageDefaultHeader = ({ page }: FormCurrentPageHeaderProps) => {
  if (!page?.page_title && !page?.page_heading) return <></>;

  return (
    <Div flex="column" gap={8}>
      {!!page?.page_title && (
        <Typography.Title level={3} elementTheme={'h6'} textAlign={'left'}>
          {page.page_title}
        </Typography.Title>
      )}
      {!!page.page_heading && (
        <Typography elementTheme={'body2'} textAlign={'left'}>
          {parse(page.page_heading)}
        </Typography>
      )}
    </Div>
  );
};

const FormCurrentPageStartHeader = ({
  page,
  viewerType,
}: FormCurrentPageHeaderProps) => {
  const theme = useTheme();

  return (
    <>
      {['modal'].includes(viewerType) && (
        <svg
          width="650"
          height="639"
          viewBox="0 0 650 639"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: 'absolute',
            bottom: -100,
            left: -100,
            color: theme.colors.beige_2,
          }}
        >
          <path
            d="M346.792 565.586C354.129 568.263 357.716 559.162 356.925 553.052C345.79 514.312 414.448 493.67 432.934 465.003C489.618 395.402 446.646 352.098 383.014 419.015C361.446 440.754 360.635 531.739 323.875 514.033C308.001 504.386 300.473 487.994 285.649 481.402C241.981 464.591 191.802 485.233 208.399 541.511C214.761 560.609 253.321 554.905 247.675 577.588C244.464 589.569 230.621 594.217 226.912 605.786C212.709 661.219 270.307 634.579 284.387 613.79C290.967 606.443 302.73 587.514 306.105 585.081C316.476 574.519 328.3 548.222 344.031 564.25C342.667 563.503 346.267 565.432 346.786 565.593L346.792 565.586Z"
            fill="currentColor"
          />
          <path
            d="M25.4247 398.436C64.0461 433.499 119.81 407.111 165.414 422.237C220.4 431.506 282.867 465.842 331.934 421.112C380.354 368.539 319.722 320.642 264.995 340.927C223.762 359.619 190.2 326.583 151.694 317.832C127.911 312.016 115.337 328.855 128.306 350.308C141.105 381.107 95.9919 387.007 76.013 381.687C57.9229 380.709 5.81397 361.318 25.4247 398.429V398.436Z"
            fill="currentColor"
          />
          <path
            d="M0.699959 548.739C-2.048 516.298 17.665 485.247 46.9925 513.11C56.941 496.41 71.0626 481.57 87.3185 471.588C127.979 429.214 208.372 459.502 172.13 523.337C155.001 549.117 136.468 578.79 108.334 593.211C51.8883 646.427 -7.11433 637.214 0.699959 548.739Z"
            fill="currentColor"
          />
          <path
            d="M49.2492 184.479C63.2822 175.224 79.5858 180.551 94.4507 177.944C108.3 173.638 109.568 157.63 115.098 145.914C130.945 114.451 175.369 114.479 198.689 137.198C230.901 169.206 199.282 208.141 217.891 218.277C256.532 221.031 283.03 176.462 321.938 174.057C352.493 166.752 380.163 191.763 347.658 215.474C330.086 226.148 308.089 231.922 287.653 230.132C281.503 230.363 275.298 232.299 269.931 235.417C269.14 235.599 264.674 241.275 263.747 244.078C253.45 265.097 265.526 296.602 231.419 303.949C207.458 310.59 183.817 292.555 171.325 272.912C166.941 260.631 169.839 246.301 162.679 234.907C163.818 236.137 158.963 230.475 159.931 231.635C135.834 212.671 106.615 236.158 80.1927 231.67C55.4406 232.097 10.9892 204.241 49.2492 184.465V184.479Z"
            fill="currentColor"
          />
          <path
            d="M392.594 259.813C410.05 210.057 365.271 169.639 346.445 127.635C332.732 96.3468 327.196 58.2292 338.406 25.8646C351.777 -4.41002 395.608 -9.78549 413.419 18.7136C455.92 74.5234 435.805 146.795 414.36 206.694C390.467 265.943 434.809 267.208 476.383 248.44C518.311 232.32 624.725 221.213 649.797 267.74C654.918 334.021 561.72 334.189 516.409 339.767C508.315 344.164 512.168 356.222 505.833 362.212C471.664 378.472 426.299 322.047 404.056 299.426C399.556 295.176 392.253 293.247 386.75 292.471C368.149 292.122 351.934 312.617 333.782 305.046C318.665 295.777 326.023 264.063 344.986 266.95C360.642 271.095 384.412 281.014 392.594 259.813Z"
            fill="currentColor"
          />
        </svg>
      )}
      <Div
        flex="column"
        gap={54}
        style={{
          maxWidth: 860,
          margin: 'auto',
          marginTop: 54,
          marginBottom: 0,
          position: 'relative',
        }}
        align="center"
        tablet={{ flex: 'column', gap: 32, style: { margin: 0, marginTop: 0 } }}
      >
        <Typography.Title level={3} elementTheme={'h3'} textAlign="center">
          {page?.page_title}
        </Typography.Title>

        {!!page.page_heading && (
          <Typography
            elementTheme="body1"
            style={{
              maxWidth: 625,
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            {parse(page.page_heading, pageDescriptionParseOptions)}
          </Typography>
        )}
      </Div>
    </>
  );
};
const FormCurrentPageEndHeader = ({
  page,
  handleClose,
  form_type,
}: FormCurrentPageHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslations();

  const [EndIcon, endIconColor] = useEndPageIcon(page.page_type ?? '');

  const handleFundingExplorer = () => {
    const client_url = getClientAppURL();
    const utm_medium = getOrigin();

    const utm_campaign =
      form_type === 'application' ? 'application_form' : 'qualification_form';
    window.open(
      `${client_url}/funding-explorer?utm_source=helloDarwin&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`,
      '_blank',
    );
  };
  return (
    <Div
      flex="row"
      gap={54}
      style={{ maxWidth: 950, margin: 'auto' }}
      align="center"
      tablet={{ flex: 'column', gap: 32 }}
    >
      <Div flex="column" gap={20} align="center">
        <Div flex="column" align="center" gap={8}>
          <EndIcon width={64} height={64} color={endIconColor} />

          <Typography.Title level={3} elementTheme={'h4'} textAlign="center">
            {page?.page_title}
          </Typography.Title>
        </Div>
        {!!page.page_heading && (
          <Typography elementTheme="body1" style={{ maxWidth: 650 }}>
            {parse(page.page_heading, pageDescriptionParseOptions)}
          </Typography>
        )}
        <Button
          onClick={handleFundingExplorer}
          defaultStyle={theme.colors.purple_1}
          size="medium"
          style={{ marginBottom: 24 }}
        >
          {t('form_viewer|goToFunding')}
        </Button>
        {page.page_type !== 'not-elligible-end' && (
          <Tablet direction="down">
            <FormMeeting isICP={page.page_type === 'elligible-end-icp'} />
          </Tablet>
        )}
        <Button
          onClick={handleClose}
          defaultStyle={theme.colors.grey_2}
          size="medium"
          isLink
          style={{ minHeight: 'auto' }}
        >
          {t('button|close')}
        </Button>
      </Div>
      {page.page_type !== 'not-elligible-end' && (
        <Tablet direction="up">
          <FormMeeting isICP={page.page_type === 'elligible-end-icp'} />
        </Tablet>
      )}
    </Div>
  );
};

const FormCurrentPageHeader = (props: FormCurrentPageHeaderProps) => {
  const { isEndPage, isOpening } = props;

  return isOpening ? (
    <FormCurrentPageStartHeader {...props} />
  ) : isEndPage ? (
    <FormCurrentPageEndHeader {...props} />
  ) : (
    <FormCurrentPageDefaultHeader {...props} />
  );
};

export default FormCurrentPageHeader;
