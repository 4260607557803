import Download from '@hellodarwin/icons/dist/icons/Download';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { AssetRowMeta } from '../../../../../features/entities/assets-entities';
import { getStringDate } from '../../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../../features/providers/locale-provider';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import AssetViewerInfoRow from './asset-viewer-info-row';

const AssetViewerDrawerInfos: React.FC<AssetRowMeta> = ({
  name,
  type,
  size,
  created_at,
  updated_at,
  isFolder,
  description,
  source,
  opened_at,
  actions: { onDoubleClick, onAction },
}) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslation();
  return (
    <Div flex="column" gap={16}>
      <AssetViewerInfoRow
        label={t('assets|infosDrawer.labels.name')}
        value={name}
      />
      <AssetViewerInfoRow
        label={t('assets|infosDrawer.labels.description')}
        value={description}
      />
      <AssetViewerInfoRow
        label={
          isFolder
            ? t('assets|infosDrawer.labels.type')
            : t('assets|infosDrawer.labels.ext')
        }
        value={type}
      />
      <AssetViewerInfoRow
        label={
          isFolder
            ? t('assets|infosDrawer.labels.nbFiles')
            : t('assets|infosDrawer.labels.fileSize')
        }
        value={size}
      />
      <AssetViewerInfoRow
        label={t('assets|infosDrawer.labels.createdAt')}
        value={getStringDate(created_at, selectedLocale)}
      />
      <AssetViewerInfoRow
        label={t('assets|infosDrawer.labels.uploadedAt')}
        value={getStringDate(updated_at, selectedLocale)}
      />

      <Div flex="column" gap={16}>
        <AssetViewerInfoRow
          label={t('assets|infosDrawer.labels.source')}
          value={source}
        />
        <AssetViewerInfoRow
          label={t('assets|infosDrawer.labels.createdAt')}
          value={getStringDate(created_at, selectedLocale)}
        />

        <AssetViewerInfoRow
          label={t('assets|infosDrawer.labels.openedAt')}
          value={opened_at ? getStringDate(opened_at, selectedLocale) : '-'}
        />
      </Div>

      {!isFolder && (
        <>
          <div style={{ borderTop: `1px solid ${theme.colors.grey_4}` }} />
          <Button
            trailingIcon={<Download size={24} />}
            style={{ width: '100%' }}
            defaultStyle={theme.colors.white_1}
            onClick={onAction}
          >
            {t('button.download')}
          </Button>
        </>
      )}
    </Div>
  );
};

export default AssetViewerDrawerInfos;
