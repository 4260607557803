import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  InitialModalState,
  selectCompanyById,
  selectCompanyModal,
  toggleCompanyModal,
} from '../../../features/api/slices/companies-slice';
import CreateProviderForm from './create-company-modal';
import UpdateCompanyModal from './update-company-modal';

const ProviderActionModal = () => {
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectCompanyModal);
  const company = useAppSelector((state) =>
    selectCompanyById(state, modal.company_id ?? ''),
  );
  const handleCancel = () => {
    dispatch(toggleCompanyModal(InitialModalState));
  };

  switch (modal.type) {
    case 'create':
      return (
        <CreateProviderForm open={modal.open} handleCancel={handleCancel} />
      );
    case 'update':
      return (
        <UpdateCompanyModal
          open={modal.open}
          handleCancel={handleCancel}
          company={company}
        />
      );
    default:
      return null;
  }
};

export default ProviderActionModal;
