import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';

type ReviewRatingProps = {
  handleSubmit?: () => Promise<void>;
  handleNext: () => void;
  handlePrev?: () => void;
  isSubmit?: boolean;
};

const ReviewNavigation = ({
  handleSubmit,
  handleNext,
  handlePrev,
  isSubmit,
}: ReviewRatingProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const handlePrimary = async () => {
    if (isSubmit && !!handleSubmit) {
      handleSubmit();
    } else {
      handleNext();
    }
  };

  return (
    <Div
      flex={'row'}
      justify={'center'}
      gap={16}
      className={'review-navigation'}
      style={{ marginTop: 24 }}
    >
      {!!handlePrev && (
        <Button
          onClick={handlePrev}
          defaultStyle={theme.colors.white_1}
          withArrowLeft
          size="medium"
        >
          {t('navigation|previous')}
        </Button>
      )}

      <Button
        onClick={(e) => {
          e.preventDefault();
          handlePrimary();
        }}
        defaultStyle={theme.colors.grey_1}
        withArrowRight={!isSubmit}
        size="medium"
      >
        {isSubmit ? t('navigation|submit') : t('navigation|next')}
      </Button>
    </Div>
  );
};

export default ReviewNavigation;
