import { IconProps } from '@hellodarwin/icons/dist/features/entities/general';
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Download from '@hellodarwin/icons/dist/icons/Download';
import Hide from '@hellodarwin/icons/dist/icons/Hide';
import Informations from '@hellodarwin/icons/dist/icons/Informations';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import View from '@hellodarwin/icons/dist/icons/View';
import { MenuProps } from 'antd/es/menu/menu';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  AssetEntity,
  AssetHiddenType,
  AssetItemType,
  AssetRowMetaActions,
} from '../../../features/entities/assets-entities';
import { usePlatform } from '../../../features/providers/platform-provider';
import Div from '../../common/div';
import { ModalConfirm } from '../../common/hd-modal/confirm';
import Typography from '../../common/typography';
import getAssetTypeId from './get-asset-type-id';
import isAssetFolder from './is-asset-folder';

const Label = ({
  title,
  Icon,
}: {
  title: string;
  Icon: (props: IconProps) => ReactNode;
}) => {
  return (
    <Div flex="row" gap={8} align="center" style={{ padding: '4px 12px' }}>
      <Icon size={16} />
      <Typography elementTheme="body2">{title}</Typography>
    </Div>
  );
};

export type getAssetActionsFunction = (
  asset: AssetItemType,
) => AssetRowMetaActions;

interface useGetAssetActionsProps {
  onItemAction: (asset: AssetItemType) => void;
  onItemSelected: (asset_id: string) => void;
  setActiveItemId: (asset_id: string) => void;
  handleHideShowAsset?: (asset_id: string, hide: AssetHiddenType) => void;
  handleDeleteAsset?: (asset_id: string) => void;
}

const useGetAssetActions = ({
  onItemAction,
  onItemSelected,
  setActiveItemId,
  handleDeleteAsset,
  handleHideShowAsset,
}: useGetAssetActionsProps) => {
  const { t } = useTranslation();
  const { app } = usePlatform();
  const theme = useTheme();
  return useMemo(
    (): getAssetActionsFunction => (asset) => {
      const isFolder = isAssetFolder(asset);
      const id = getAssetTypeId(asset);

      const onClick = () => setActiveItemId(id);
      const onDoubleClick = () => onItemSelected(id);
      const onAction = () => onItemAction(asset);
      const onHideShow = (hide: AssetHiddenType) =>
        !isFolder
          ? handleHideShowAsset((asset as AssetEntity).asset_id, hide)
          : undefined;
      const onDelete = () => {
        if (isFolder || !handleDeleteAsset) return;

        ModalConfirm({
          title: t('assets|deleteConfirm.title'),
          content: t('assets|deleteConfirm.description'),
          okText: t('button.confirm'),
          cancelText: t('button.cancel'),
          onOk: async () => {
            handleDeleteAsset((asset as AssetEntity).asset_id);
          },
        });
      };

      const dropdownItems: MenuProps['items'] = [
        {
          label: (
            <Label
              title={t('assets|filesListHeader.infos')}
              Icon={Informations}
            />
          ),
          onClick: onDoubleClick,
          key: '1',
        },
      ];

      if (isFolder || !!(asset as AssetEntity).display_name) {
        dropdownItems.push({
          label: isFolder ? (
            <Label title={t('assets|filesListHeader.open')} Icon={NewWindow} />
          ) : (
            <Label
              title={t('assets|filesListHeader.download')}
              Icon={Download}
            />
          ),
          onClick: onAction,
          key: 'download',
        });
      }
      if (app === 'admin') {
        if (!isFolder) {
          const file = asset as AssetEntity;
          if (!!handleHideShowAsset) {
            dropdownItems.push({
              label: (
                <Label
                  title={
                    file.hidden
                      ? t('assets|filesListHeader.view')
                      : t('assets|filesListHeader.hide')
                  }
                  Icon={file.hidden ? Hide : View}
                />
              ),
              onClick: () => onHideShow,
              key: 'hideshow',
            });
          }
          if (!!handleDeleteAsset) {
            dropdownItems.push({
              label: (
                <Label
                  title={t('assets|filesListHeader.delete')}
                  Icon={Delete}
                />
              ),
              onClick: onDelete,
              key: 'delete',
            });
          }
        }
      }

      return {
        dropdownItems,
        onClick,
        onDoubleClick,
        onAction,
        onHideShow,
        onDelete,
      };
    },
    [onItemAction, onItemSelected, t, app],
  );
};

export default useGetAssetActions;
