import styled from 'styled-components';

const AssetsTableContent = styled.div``;

export const AssetsCardsContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align: stretch;
  flex-wrap: wrap;
`;
