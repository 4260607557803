import Div from '@hellodarwin/core/lib/components/common/div';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import Uploader from '@hellodarwin/core/lib/components/forms/form-groups/uploader';
import ProfileLogo from '@hellodarwin/core/lib/components/provider/profile/profile-assets/profile-logo';
import { GrantProvider } from '@hellodarwin/core/lib/features/entities';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { Col, Row } from 'antd/es/grid';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import TextArea from 'antd/es/input/TextArea';
import Typography from 'antd/es/typography';
import { RcFile } from 'antd/es/upload';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchGrantProvidedByProvider,
  selectGrantProvidedByProvider,
  updateGrantProviderLogo,
} from '../../features/api/slices/grants-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import { AdminPagesForms } from '../../pages/single-rfp-page';
import theme from '../../theme';
const { Title } = Typography;

type GrantProviderFormProps = {
  grantProvider?: GrantProvider[];
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
};

export type GrantProviderFormValues = {
  title_en: string;
  title_fr: string;
  description_en: string;
  description_fr: string;
  logo_en: string;
  logo_fr: string;
  slug_en: string;
  slug_fr: string;
  url_en: string;
  url_fr: string;
  acronym_en: string;
  acronym_fr: string;
  long_description_en: string;
  long_description_fr: string;
  rank?: number;
};

const GrantProviderForm = ({
  grantProvider,
  setForms,
}: GrantProviderFormProps) => {
  const [form] = Form.useForm<GrantProviderFormValues>();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [locale, setLocale] = useState<string>('en');
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const grantProvided = useAppSelector(selectGrantProvidedByProvider);
  const [logoEN, setLogoEN] = useState<string>(grantProvider![0].logo || '');
  const [logoFR, setLogoFR] = useState<string>(grantProvider![1].logo || '');

  const handleUploadChange = async (newFileList: RcFile[]) => {
    setOpen(false);
    setFileList(newFileList);
    const data = new FormData();
    data.append('logo', newFileList[0] as Blob);
    const grantProviderId = grantProvider![0].grant_provider_id || '';

    const returnedGrantProvider = await dispatch(
      updateGrantProviderLogo({ api, grantProviderId, locale, data }),
    ).unwrap();

    let changeBoth: boolean = false;
    if (locale === 'en' && (logoFR === '' || logoFR === undefined)) {
      await dispatch(
        updateGrantProviderLogo({ api, grantProviderId, locale: 'fr', data }),
      );
      changeBoth = true;
    } else if (locale === 'fr' && (logoEN === '' || logoEN === undefined)) {
      await dispatch(
        updateGrantProviderLogo({ api, grantProviderId, locale: 'en', data }),
      );
      changeBoth = true;
    }

    if (returnedGrantProvider) {
      const logoURL = returnedGrantProvider.logo;
      if (changeBoth) {
        setLogoEN(logoURL || '');
        setLogoFR(logoURL || '');
      } else {
        if (locale === 'en') {
          setLogoEN(logoURL || '');
        } else {
          setLogoFR(logoURL || '');
        }
      }
    }
  };

  useEffect(() => {
    if (grantProvider![0].grant_provider_id !== '') {
      dispatch(
        fetchGrantProvidedByProvider({
          api,
          grantProviderId: grantProvider![0].grant_provider_id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      grantProviderForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    const newGrantProvider: GrantProviderFormValues = {
      title_en: grantProvider![0].title || '',
      title_fr: grantProvider![1].title || '',
      description_en: grantProvider![0].description || '',
      description_fr: grantProvider![1].description || '',
      logo_en: grantProvider![0].description || '',
      logo_fr: grantProvider![1].description || '',
      rank: grantProvider![0].rank || undefined,
      slug_en: grantProvider![0].slug || '',
      slug_fr: grantProvider![1].slug || '',
      url_en: grantProvider![0].url || '',
      url_fr: grantProvider![1].url || '',
      acronym_en: grantProvider![0].acronym || '',
      acronym_fr: grantProvider![1].acronym || '',
      long_description_en: grantProvider![0].long_description || '',
      long_description_fr: grantProvider![1].long_description || '',
    };
    form.setFieldsValue(newGrantProvider ? newGrantProvider : {});
  }, [form, grantProvider]);

  const initialValues: GrantProviderFormValues = {
    title_en: grantProvider![0].title || '',
    title_fr: grantProvider![1].title || '',
    description_en: grantProvider![0].description || '',
    description_fr: grantProvider![1].description || '',
    logo_en: grantProvider![0].logo || '',
    logo_fr: grantProvider![1].logo || '',
    rank: grantProvider![0].rank || undefined,
    slug_en: grantProvider![0].slug || '',
    slug_fr: grantProvider![1].slug || '',
    url_en: grantProvider![0].url || '',
    url_fr: grantProvider![1].url || '',
    acronym_en: grantProvider![0].acronym || '',
    acronym_fr: grantProvider![1].acronym || '',
    long_description_en: grantProvider![0].long_description || '',
    long_description_fr: grantProvider![1].long_description || '',
  };

  return (
    <>
      <Div style={{ padding: '1rem', width: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="grant"
        >
          <Div flex="column" gap={8}>
            <Title level={4}>Grant</Title>
            {grantProvider![0].grant_provider_id !== '' && (
              <Div flex="row" justify="space-between" align="center">
                <Form.Item label="Logo EN" name="grant_logo">
                  <ProfileLogo
                    name={'logo_en'}
                    logo={logoEN}
                    isEditable={true}
                    toggleEditModal={() => {
                      setOpen(true);
                      setLocale('en');
                    }}
                  />
                </Form.Item>
                <Form.Item label="Logo FR" name="grant_logo">
                  <ProfileLogo
                    name={'logo fr'}
                    logo={logoFR}
                    isEditable={true}
                    toggleEditModal={() => {
                      setOpen(true);
                      setLocale('fr');
                    }}
                  />
                </Form.Item>
              </Div>
            )}
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Title EN"
                  name="title_en"
                  rules={[
                    {
                      required: true,
                      message: 'Please add a title for the grant',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Title FR"
                  name="title_fr"
                  rules={[
                    {
                      required: true,
                      message: 'Please add a title for the grant',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Slug EN"
                  name="slug_en"
                  rules={[
                    {
                      required: true,
                      message: 'Please add a title for the grant',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Slug FR"
                  name="slug_fr"
                  rules={[
                    {
                      required: true,
                      message: 'Please add a title for the grant',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Acronym EN" name="acronym_en">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Acronym FR" name="acronym_fr">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Row align={'middle'}>
                  <Form.Item
                    label="Website EN"
                    name="url_en"
                    style={
                      grantProvider![0].url
                        ? { width: '90%' }
                        : { width: '100%' }
                    }
                  >
                    <Input />
                  </Form.Item>
                  {grantProvider![0].url && (
                    <Button
                      onClick={() =>
                        window.open(
                          grantProvider![0].url?.includes('https://')
                            ? grantProvider![0].url
                            : 'https://' + grantProvider![0].url,
                          '_blank',
                        )
                      }
                      ghost
                      icon={
                        <NewWindow
                          style={{ color: theme.colors.purple_1 }}
                          width={16}
                          height={16}
                        />
                      }
                      type="text"
                    />
                  )}
                </Row>
              </Col>
              <Col span={12}>
                <Row align={'middle'}>
                  <Form.Item
                    label="Website FR"
                    name="url_fr"
                    style={
                      grantProvider![1].url
                        ? { width: '90%' }
                        : { width: '100%' }
                    }
                  >
                    <Input />
                  </Form.Item>
                  {grantProvider![1].url && (
                    <Button
                      onClick={() =>
                        window.open(
                          grantProvider![1].url?.includes('https://')
                            ? grantProvider![1].url
                            : 'https://' + grantProvider![1].url,
                          '_blank',
                        )
                      }
                      ghost
                      icon={
                        <NewWindow
                          style={{ color: theme.colors.purple_1 }}
                          width={16}
                          height={16}
                        />
                      }
                      type="text"
                    />
                  )}
                </Row>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Description EN" name="description_en">
                      <TextArea style={{ minHeight: 200 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item label="Description FR" name="description_fr">
                  <TextArea style={{ minHeight: 200 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Long Description EN"
                      name="long_description_en"
                    >
                      <TextArea style={{ minHeight: 600 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Long Description FR"
                  name="long_description_fr"
                >
                  <TextArea style={{ minHeight: 600 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Rank" name="rank">
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col span={12}>
                {grantProvided &&
                  grantProvided.map((item, index) => (
                    <Div key={index}>
                      <Link target="_blank" to={`/grants/${item.value}`}>
                        {item.label}
                      </Link>
                    </Div>
                  ))}
              </Col>
            </Row>
          </Div>
        </Form>
      </Div>
      <Modal
        open={open}
        handleCancel={() => setOpen(false)}
        noPadding
        size="full"
      >
        <Uploader
          fileList={fileList}
          name={'logo'}
          width={200}
          height={200}
          handleChange={handleUploadChange}
        ></Uploader>
      </Modal>
    </>
  );
};

export default GrantProviderForm;
