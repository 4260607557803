import classNames from 'classnames';
import { Suspense, lazy } from 'react';
import { ParagraphProps } from './entities';
import { ParagraphContainer, TypographyContainerProps } from './styles';
import useTypographyProps from './utils/use-typography-props';

const CopyButton = lazy(() => import('./copyButton'));

const Content = (props: ParagraphProps) => {
  const { currentProps, currentStyle, defaultProps } =
    useTypographyProps(props);
  const {
    className,
    onBlur,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    id,
    children,
  } = defaultProps;

  const { $size } = currentProps;
  const classes = classNames(className, 'hd-paragraph', {
    [`${$size}`]: !!$size,
  });

  const textProps: TypographyContainerProps = {
    ...currentProps,
    className: classes,
    onClick,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    id,
    style: currentStyle,
    children,
  };
  return <ParagraphContainer {...textProps} />;
};

const Paragraph = (props: ParagraphProps) => {
  const { copyable } = props;

  return !!copyable ? (
    <div
      style={{
        display: 'flex',
        gap: 8,
        alignItems: 'center',
      }}
    >
      <Content {...props} />
      {!!copyable ? (
        <Suspense fallback={''}>
          <CopyButton copyable={copyable} />
        </Suspense>
      ) : undefined}
    </div>
  ) : (
    <Content {...props} />
  );
};

export default Paragraph;
