import { IconParams } from '@hellodarwin/icons/dist/backgrounds/icon-entities';
import classNames from 'classnames';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { AppType } from '../../../../features/entities/core-entities';
import AppFooter from '../app-layout/app-footer';
import BackgroundIcon from './BackgroundIcon';
import {
  PageBackground,
  PageContainerStyled,
  PageContentStyled,
} from './styles';

export interface PageContainerProps extends PropsWithChildren {
  backgroundColor?: string;
  containerBackgroundColor?: string;
  overflowHidden?: boolean;
  noGap?: boolean;
  cap?: number | string;
  styles?: {
    container?: CSSProperties;
    content?: CSSProperties;
  };
  className?: string;
  noPadding?: boolean;
  icons?: IconParams[];
  withoutPaddingRight?: boolean;
  withoutPaddingHorizontal?: boolean;
  app: AppType;
  noFooter?: boolean;
}

const PageContainer = ({
  styles,
  overflowHidden,
  children,
  backgroundColor,
  containerBackgroundColor,
  className,
  noPadding,
  icons,
  withoutPaddingRight,
  withoutPaddingHorizontal,
  app,
  noFooter,
  noGap,
}: PageContainerProps) => {
  const theme = useTheme();
  const stylingBasics: CSSProperties = {
    width: '100%',
    background: containerBackgroundColor,
    position: 'relative',
    overflow: overflowHidden ? 'hidden' : 'initial',
    zIndex: 1,
    height: 'fit-content',
    maxWidth: !noPadding ? theme.caps.xl : undefined,
    margin: '0 auto',

    ...styles?.container,
  };

  const classes = classNames('PageContainer', {
    [`${className}`]: className,
  });

  return (
    <div style={stylingBasics}>
      <PageContainerStyled
        className={classes}
        style={styles?.content}
        $isAdmin={app === 'admin'}
        $withoutPaddingRight={withoutPaddingRight}
        $withoutPaddingHorizontal={withoutPaddingHorizontal}
        $withoutPadding={noPadding}
        $backgroundColor={backgroundColor}
      >
        <PageBackground>
          {React.Children.toArray(
            icons?.map((iconParams, i) => (
              <BackgroundIcon
                iconParams={iconParams}
                key={`icon-${i}-${iconParams.side}`}
              />
            )),
          )}
        </PageBackground>
        <PageContentStyled
          $isAdmin={app === 'admin'}
          $noGap={noGap}
          style={{ position: 'relative', zIndex: 1, width: '100%' }}
        >
          {children}
        </PageContentStyled>
        {!noFooter && <AppFooter />}
      </PageContainerStyled>
    </div>
  );
};

export default PageContainer;
