import NavigationSider from '@hellodarwin/core/lib/components/layout/navigation';
import {
  AdminProfile,
  NavigationLink,
} from '@hellodarwin/core/lib/features/entities';
import { Language } from '@hellodarwin/core/lib/features/entities/translations_ns';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Chat from '@hellodarwin/icons/dist/icons/Chat';
import Doc from '@hellodarwin/icons/dist/icons/Doc';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import Enterprise from '@hellodarwin/icons/dist/icons/Enterprise';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import Industry from '@hellodarwin/icons/dist/icons/Industry';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Launch from '@hellodarwin/icons/dist/icons/Launch';
import LineViewOutline from '@hellodarwin/icons/dist/icons/LineViewOutline';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import RatingOutline from '@hellodarwin/icons/dist/icons/RatingOutline';
import Settings from '@hellodarwin/icons/dist/icons/Settings';
import User from '@hellodarwin/icons/dist/icons/User';
import { useAppDispatch, useAppSelector } from '../app';
import { updateAdmin } from '../features/api/slices/admins-slice';
import {
  selectAuthentifiedUser,
  selectAuthentifiedUserProfile,
} from '../features/api/slices/global-slice';
import { useAdminApi } from '../features/api/use-admin-api';

const Navigation = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { changeLanguage } = useLocale();
  const admin = useAppSelector(selectAuthentifiedUser);
  const { t } = useTranslation();
  const profile = useAppSelector(selectAuthentifiedUserProfile);
  const links: NavigationLink[] = [
    {
      pathname: '/projects',
      Icon: Industry,
      label: 'Marketplace',
      subLinks: [
        {
          pathname: '/projects',
          Icon: ProjectsList,
          label: t('navigation.projects'),
        },
        {
          pathname: '/providers',
          Icon: Enterprise,
          label: t('navigation.providers'),
        },
        {
          pathname: '/companies',
          Icon: User,
          label: t('navigation.companies'),
        },
        {
          pathname: '/reviews',
          Icon: RatingOutline,
          label: t('navigation.reviews'),
        },
      ],
    },
    {
      pathname: '/grants',
      Icon: Grants,
      label: t('navigation.grants'),
      subLinks: [
        {
          pathname: '/grants',
          Icon: Grants,
          label: t('navigation.programs'),
        },
        {
          pathname: '/programs',
          Icon: Launch,
          label: t('navigation.applications'),
        },
        {
          pathname: '/funding-explorer',
          Icon: Invoices,
          label: t('navigation.fundingExplorer'),
        },
        {
          pathname: '/gin',
          Icon: Doc,
          label: t('navigation.gin'),
        },
        {
          pathname: '/forms',
          Icon: LineViewOutline,
          label: 'Forms',
        },
        {
          pathname: '/chats',
          Icon: Chat,
          label: 'Chats',
        },
      ],
    },
  ];

  const userMenu: NavigationLink[] = [
    {
      label: 'Settings',
      pathname: '/settings',
      Icon: Settings,
    },
    {
      Icon: Employees,
      label: 'Admins',
      pathname: '/admins',
    },
  ];

  const handleLanguageChange = (newLanguage: Language) => {
    changeLanguage(newLanguage);
    const newAdmin: AdminProfile = {
      ...admin,
      preferred_language: newLanguage,
    };

    dispatch(updateAdmin({ api, data: newAdmin }));
  };

  return (
    <NavigationSider
      links={links}
      app="admin"
      profile={profile}
      userLinks={userMenu}
      handleLanguageChange={handleLanguageChange}
    />
  );
};

export default Navigation;
