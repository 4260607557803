import Divider from 'antd/es/divider';
import Drawer from 'antd/es/drawer';
import { useEffect, useMemo, useState } from 'react';
import { NavigationLink } from '../../../../features/entities/layout-entities';
import { Profile } from '../../../../features/entities/profile-entities';
import { usePlatform } from '../../../../features/providers/platform-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import UserMenu from '../../header/user-menu';
import NavigationMenuItem from '../menu-item';
import Socials from '../socials';
import { MobileNavigationContainer } from '../styles';
import MobileNavigationHeader from './mobile-navigation-header';

interface MobileNavigationProps {
  links: NavigationLink[];
  profile?: Profile;
  userLinks?: NavigationLink[];
  pathname: string;
}

const MobileNavigation = ({
  links,
  profile,
  userLinks,
  pathname,
}: MobileNavigationProps) => {
  const { app } = usePlatform();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  useEffect(() => {
    handleClose();
  }, [pathname]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const selectedKeys: string[] = useMemo(
    () => links.map((l) => l.pathname) ?? [],
    [links],
  );

  return (
    <>
      <MobileNavigationHeader
        app={app}
        toggleMenu={toggleMenu}
        isOpen={isOpen}
      />

      <Drawer
        styles={{
          header: {
            padding: 0,
            flexDirection: 'row-reverse',
          },
          content: {
            background:
              app === 'provider' ? theme.colors.primary : theme.colors.white_1,
            padding: 0,
          },
        }}
        title={
          <MobileNavigationHeader
            app={app}
            toggleMenu={toggleMenu}
            isOpen={isOpen}
          />
        }
        closable={false}
        placement="right"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        width={'100%'}
      >
        <MobileNavigationContainer $isOpen={isOpen} $app={app}>
          <Div flex="column" gap={12} align="center">
            {links
              .filter((link) => !link.hide)
              .map((link) => (
                <NavigationMenuItem
                  {...link}
                  key={link.pathname}
                  app={app}
                  selectedKeys={selectedKeys}
                  handleLinkClickCallback={handleClose}
                />
              ))}
          </Div>
          {profile && userLinks && (
            <Div className="hd-navigation-footer" flex="column" gap={24}>
              {app !== 'admin' && (
                <Socials withoutBorder align="right" app={app} />
              )}
              <Divider
                style={{
                  borderWidth: 1,
                  borderColor:
                    app === 'provider'
                      ? theme.colors.white_1
                      : theme.colors.grey_4,
                }}
              />
              <UserMenu
                user={{ name: profile.contact_name, logo: profile.logo }}
                links={userLinks}
                app={app}
              />
            </Div>
          )}
        </MobileNavigationContainer>
      </Drawer>
    </>
  );
};

export default MobileNavigation;
