import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Div from '../../../common/div';
import Typography from '../../../common/typography';

const ProgramEmptyStateHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Div flex="column" gap={24} style={{ maxWidth: 900 }}>
      <Typography.Title level={1} elementTheme="h2" textAlign="center" noMargin>
        {t('propulsion|empty.title')}
        <Typography.Highlight color={theme.colors.green_2}>
          {t('propulsion|empty.programName')}
        </Typography.Highlight>
      </Typography.Title>

      <Typography
        color={theme.colors.purple_1}
        elementTheme="subtitle2"
        textAlign="center"
      >
        {t('propulsion|empty.subtitle')}
      </Typography>
    </Div>
  );
};

export default ProgramEmptyStateHeader;
