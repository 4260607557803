import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import { GrantPreview } from '@hellodarwin/core/lib/features/entities/grants-entities';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';

const GrantsSearchSelectedList = ({
  grants,
  handleDeleteGrant,
}: {
  grants: GrantPreview[];
  handleDeleteGrant?: (grant: GrantPreview) => void;
}) => {
  const theme = useTheme();
  return (
    <Div flex="row" wrap="wrap" gap={8}>
      {grants.map((grant) => (
        <HdTag
          key={grant.grant_id}
          onClose={
            !!handleDeleteGrant
              ? () => {
                  handleDeleteGrant(grant);
                }
              : undefined
          }
          text={grant.display_title}
          hoverable={!!handleDeleteGrant}
          color={theme.colors.purple_2}
        />
      ))}
    </Div>
  );
};

export default GrantsSearchSelectedList;
