import { ProfileModalProps, Provider } from '../../../../features/entities';
import ProfileBlocks from '../../../../features/enums/profile-blocks';
import { useTranslations } from '../../../../features/providers/translations-provider';
import VideoPlayer from '../../../common/VideoPlayer';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ProfileSection from '../profile-section';
import ProfileEmptySection from '../profile-section/empty-state';
import { VideoWrapper, VideoWrapperContainer } from '../styles';

type ProfileVideoProps = {
  provider: Provider;
  isEditable?: boolean;
  isWebsite?: boolean;
  id: string;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
};

const ProfileVideo = ({
  provider,
  isEditable,
  isWebsite,
  toggleEditModal,
  id,
}: ProfileVideoProps) => {
  const { t } = useTranslations();

  const handleEdit = () =>
    !!toggleEditModal &&
    toggleEditModal({ open: true, type: ProfileBlocks.Presentation });

  const url = provider.assets?.[0]?.asset_url || '';
  const title = provider.assets?.[0]?.asset_title || '';

  return (
    <ProfileSection
      title={t('profile_video|videoTitle')}
      isEditable={isEditable}
      handleEdit={handleEdit}
      id={id}
      hide={!provider.assets?.[0]}
      emptyState={
        <ProfileEmptySection
          title={t('profile_video|videoEmptyTitle')}
          subtitle={t('profile_video|videoEmptySubtitle')}
          cta={t('profile_video|videoEmptyCta')}
          sectionName="Video"
          handleClick={handleEdit}
          isEditable={isEditable}
        />
      }
    >
      <VideoWrapperContainer className={isWebsite ? 'full-width' : undefined}>
        <VideoWrapper>
          <Div className="video">
            {!!url.length && typeof window !== 'undefined' && (
              <VideoPlayer url={url} controls light playing />
            )}
          </Div>

          <Typography elementTheme="body1" bold className="video-title">
            {title}
          </Typography>
        </VideoWrapper>
      </VideoWrapperContainer>
    </ProfileSection>
  );
};

export default ProfileVideo;
