import { AvatarShape } from '.';
import styled, { css } from '../../../plugins/styled';

const SquareAvatar = css`
  border-radius: 0px;
`;
const RoundedCornerAvatar = css`
  border-radius: 4px;
`;
const RoundAvatar = css`
  border-radius: 99px;
`;

export const AvatarContainer = styled.div<{
  $shape: AvatarShape;
  $size: number;
  $background?: string;
  $fontSize?: number;
  $lineHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  background: ${({ theme, $background }) =>
    $background ?? theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${(props) => props.theme.colors.white_1};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${({ theme, $size }) => $size * 0.66}px;
  line-height: ${({ $size }) => $size}px;
  overflow: hidden;
  text-transform: capitalize;
  flex-shrink: 0;
  flex-grow: 0;

  ${({ $shape }) =>
    $shape === 'round'
      ? RoundAvatar
      : $shape === 'rounded-corner'
        ? RoundedCornerAvatar
        : SquareAvatar}
`;
export const StyledAvatar = styled.img`
  object-fit: cover;
`;
