import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Photo = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45419 7.09335C8.19161 7.50894 7.84128 7.86205 7.42777 8.1279C7.01425 8.39375 6.54758 8.56589 6.06048 8.63226C5.50618 8.71103 4.95626 8.79564 4.40633 8.88753C2.87033 9.14281 1.77777 10.495 1.77777 12.0514V24.3423C1.77777 25.2128 2.12356 26.0476 2.73906 26.6631C3.35457 27.2786 4.18937 27.6244 5.05982 27.6244H26.9402C27.8106 27.6244 28.6454 27.2786 29.2609 26.6631C29.8764 26.0476 30.2222 25.2128 30.2222 24.3423V12.0514C30.2222 10.495 29.1282 9.14281 27.5937 8.88753C27.0433 8.79584 26.4919 8.71074 25.9395 8.63226C25.4527 8.56569 24.9863 8.39345 24.573 8.12761C24.1598 7.86177 23.8097 7.50877 23.5473 7.09335L22.3482 5.17371C22.0789 4.73625 21.7082 4.37011 21.2674 4.10629C20.8266 3.84248 20.3287 3.68875 19.8159 3.65813C17.2738 3.52159 14.7262 3.52159 12.1841 3.65813C11.6713 3.68875 11.1734 3.84248 10.7326 4.10629C10.2918 4.37011 9.92108 4.73625 9.65178 5.17371L8.45419 7.09335Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5641 16.6842C22.5641 18.4251 21.8725 20.0947 20.6415 21.3257C19.4105 22.5568 17.7409 23.2483 16 23.2483C14.2591 23.2483 12.5895 22.5568 11.3585 21.3257C10.1275 20.0947 9.43589 18.4251 9.43589 16.6842C9.43589 14.9433 10.1275 13.2737 11.3585 12.0427C12.5895 10.8117 14.2591 10.1201 16 10.1201C17.7409 10.1201 19.4105 10.8117 20.6415 12.0427C21.8725 13.2737 22.5641 14.9433 22.5641 16.6842ZM25.8461 13.4022H25.8578V13.4138H25.8461V13.4022Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Photo;
