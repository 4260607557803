import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';

export interface CardInfoRowProps {
  Icon: IconNode;
  label: string;
  value: string;
}

const CardInfoRowWithLabel = ({ Icon, label, value }: CardInfoRowProps) => {
  const theme = useTheme();
  if (!label?.length) return <></>;
  return (
    <Div flex="column" gap={2}>
      <Div flex="row" gap={8}>
        <Icon
          width={12}
          height={12}
          style={{ flexShrink: 0 }}
          color={theme.colors.grey_2}
        />
        <Typography elementTheme="overline" color={theme.colors.grey_2}>
          {label}
        </Typography>
      </Div>
      <Typography style={{ paddingLeft: 20 }} elementTheme="body2">
        {value}
      </Typography>
    </Div>
  );
};

export default CardInfoRowWithLabel;
