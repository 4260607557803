import { TabsProps } from 'antd/es/tabs';
import { useMemo } from 'react';
import { Grant } from '../../../features/entities/grants-entities';
import {
  AdminProfile,
  Profile,
} from '../../../features/entities/profile-entities';
import { Program } from '../../../features/entities/programs-entities';
import SingleGrantSections from '../../../features/enums/single-grant-sections';
import { useTranslations } from '../../../features/providers/translations-provider';
import AdminCard from '../../admin/admin-card';
import Container from '../../common/container';
import Div from '../../common/div';
import FilesList, { FilesListProps } from '../../files/files-list';
import SingleGrant from '../../grants/single';
import ProgramEmptyState from './program-empty-state';
import ProgramSingleHeader from './program-single-header';

export interface ProgramSinglePageProps extends Program {
  admin?: AdminProfile;
  profile?: Profile;
  isLoading?: boolean;
  grant?: Grant;
  fileList?: FilesListProps;
  activeTab: ProgramSingleTabs;
  handleTabChange: (newTab: string) => void;
}

export enum ProgramSingleTabs {
  ProgramInfo = 'program',
  ProgramFiles = 'files',
}

const ProgramSingle = (props: ProgramSinglePageProps) => {
  const { t } = useTranslations();

  const {
    admin,
    profile,
    isLoading,
    grant,
    fileList,
    handleTabChange,
    activeTab,
    ...program
  } = props;

  if (!program || !program.program_id) return <ProgramEmptyState />;

  const tabs = useMemo(() => {
    const tabs: TabsProps['items'] = [
      {
        key: ProgramSingleTabs.ProgramInfo,
        label: t(`gin_section|application`),
        children: (
          <SingleGrant
            grant={grant}
            sections={[
              SingleGrantSections.Summary,
              SingleGrantSections.Preview,
              SingleGrantSections.TermsAndConditions,
              SingleGrantSections.Projects,
              SingleGrantSections.Admissibility,
              SingleGrantSections.Criteria,
              SingleGrantSections.Register,
              SingleGrantSections.OtherInfo,
              SingleGrantSections.Documents,
              SingleGrantSections.Contact,
            ]}
          />
        ),
      },
    ];
    if (!!fileList) {
      tabs.push({
        key: ProgramSingleTabs.ProgramFiles,
        label: t(`gin_section|files`),
        children: (
          <Div flex="column" gap={24} style={{ paddingTop: 24 }}>
            <FilesList {...fileList} noHeader />
          </Div>
        ),
      });
    }
    return tabs;
  }, [grant, fileList]);

  return (
    <Div flex="column" gap={32}>
      <ProgramSingleHeader {...props} />
      <Container
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabChange}
      />

      {!!admin && !!profile && (
        <AdminCard
          profile={profile}
          admin={admin}
          title={t('adminCard|adminTextProgram')}
          subtitle={t('adminCard|adminTextStep')}
        />
      )}
    </Div>
  );
};

export default ProgramSingle;
