import { keyframes } from 'styled-components';

export const fadeOut = keyframes`
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`;
export const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

export const createScrollAnimation = ($inversed: boolean) => keyframes`
  0% {
    transform: translateX(-${$inversed ? 50 : 0}%);
  }
  100% {
    transform: translateX(-${$inversed ? 0 : 50}%);
  }


`;
