import {
  TeamMember,
  TeamMemberItem,
} from '@hellodarwin/core/lib/features/entities';

class TeamMemberAdapter {
  updateTeamMembersOrder = (
    teamMembers: TeamMember[],
    payload: TeamMemberItem[],
  ): TeamMember[] => {
    const mappedOrders = payload.reduce<{ [id: string]: number }>(
      (orderMap, teamMemberItem) => {
        if (
          teamMemberItem.provider_team_member_id &&
          teamMemberItem.order_team !== undefined
        ) {
          orderMap[teamMemberItem.provider_team_member_id] =
            teamMemberItem.order_team;
        }
        return orderMap;
      },
      {},
    );

    return teamMembers
      .map((teamMember) => ({
        ...teamMember,
        order_team:
          mappedOrders[teamMember.provider_team_member_id] ??
          teamMember.order_team,
      }))
      .sort((a, b) => (a.order_team ?? 0) - (b.order_team ?? 0));
  };
}

export const createTeamMemberAdapter = () => new TeamMemberAdapter();
