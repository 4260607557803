import Tooltip from 'antd/es/tooltip';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { AppType } from '../../../../features/entities/core-entities';
import { NavigationLink } from '../../../../features/entities/layout-entities';
import { useAppData } from '../../../../features/providers/app-data-provider';
import useHandleLinkClick from '../utils/use-handle-link-click';
import NavigationMenuLabelWrapper from './menu-item-label-wrapper';
import { NavigationMenuItemStyled } from './styles';
import SubitemsListDropdown from './subitems-list-dropdown';

interface NavigationMenuItemProps extends NavigationLink {
  app: AppType;
  selectedKeys: string[];
  setSelectedKeys?: Dispatch<SetStateAction<string[]>>;
  handleLinkClickCallback?: () => void;
}
const NavigationMenuItem = ({
  selectedKeys,
  setSelectedKeys,
  handleLinkClickCallback,
  ...props
}: NavigationMenuItemProps) => {
  const { subLinks, app, pathname, label } = props;
  const { navCollapsed } = useAppData();
  const handleLinkClick = useHandleLinkClick({
    selectedKeys,
    handleLinkClickCallback,
  });

  const handleListitemsOpen = () => {
    !!setSelectedKeys &&
      setSelectedKeys((prevSelectedKeys) => [...prevSelectedKeys, pathname]);
  };
  const handleListItemsClose = () => {
    !!setSelectedKeys &&
      setSelectedKeys((prevSelectedKeys) =>
        prevSelectedKeys.filter((key) => key !== pathname),
      );
  };

  const listItemsOpen = useMemo(
    () => !!selectedKeys?.includes(pathname),
    [selectedKeys, pathname],
  );

  const handleListItemsOpening = () => {
    if (listItemsOpen) {
      handleListItemsClose();
    } else {
      handleListitemsOpen();
    }
  };

  return (
    <NavigationMenuItemStyled
      $isCollapsed={navCollapsed}
      onMouseEnter={navCollapsed ? handleListitemsOpen : undefined}
    >
      {navCollapsed && !subLinks?.length ? (
        <Tooltip title={label} placement="right">
          <div>
            <NavigationMenuLabelWrapper
              {...props}
              handleListItemsOpening={handleListItemsOpening}
              listItemsOpen={listItemsOpen}
              handleLinkClick={handleLinkClick}
              isCollapsed={navCollapsed}
            />
          </div>
        </Tooltip>
      ) : (
        <NavigationMenuLabelWrapper
          {...props}
          handleListItemsOpening={handleListItemsOpening}
          listItemsOpen={listItemsOpen}
          handleLinkClick={handleLinkClick}
          isCollapsed={navCollapsed}
        />
      )}
      {!!subLinks && (
        <SubitemsListDropdown
          style={{ width: '100%' }}
          isCollapsed={navCollapsed}
          handleDropdownMouseLeave={handleListItemsClose}
          isActive={listItemsOpen}
          items={subLinks}
          app={app}
          isDropdown={navCollapsed}
          parentItem={props}
        />
      )}
    </NavigationMenuItemStyled>
  );
};

export default NavigationMenuItem;
