import Breadcrumb from 'antd/es/breadcrumb';
import styled, { css } from '../../../../plugins/styled';
import { createFontSizeCSS } from '../../../../theme/global-style';
import { BreadcrumbsContentSize } from './breadcrumbs-content';

export const BreadcrumbStyle = css`
  ${createFontSizeCSS('overline')}
  text-transform:uppercase;
  font-weight: 500;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  span {
    margin: 0;
  }
`;
export const BreadcrumbText = styled.div`
  ${BreadcrumbStyle};
  color: ${({ theme }) => theme.colors.purple_2};
`;

export const BreadcrumbButton = styled.div`
  ${BreadcrumbStyle}
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey_2};
  &:hover {
    color: ${({ theme }) => theme.colors.grey_3};
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumb)<{
  $size?: BreadcrumbsContentSize;
}>`
  overflow: hidden;
  display: flex;
  li {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    overflow: hidden;
    max-width: 100%;
  }
  ol {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    min-width: 0;
  }

  ${({ $size }) =>
    $size === 'large'
      ? css`
          ${BreadcrumbText},${BreadcrumbButton} {
            ${createFontSizeCSS('body2')}
            text-transform:none;
            font-weight: 500;
          }
        `
      : ''}
`;
