import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const News = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.76 9.10001H18.12M15.76 13.82H18.12M6.32001 18.54H18.12M6.32001 23.26H18.12M22.84 9.10001H28.15C29.127 9.10001 29.92 9.89297 29.92 10.87V25.62C29.92 26.5589 29.547 27.4593 28.8832 28.1232C28.2193 28.787 27.3189 29.16 26.38 29.16M22.84 9.10001V25.62C22.84 26.5589 23.213 27.4593 23.8768 28.1232C24.5407 28.787 25.4411 29.16 26.38 29.16M22.84 9.10001V4.97001C22.84 3.99297 22.047 3.20001 21.07 3.20001H3.37001C2.39297 3.20001 1.60001 3.99297 1.60001 4.97001V25.62C1.60001 26.5589 1.97297 27.4593 2.63685 28.1232C3.30073 28.787 4.20114 29.16 5.14001 29.16H26.38M6.32001 9.10001H11.04V13.82H6.32001V9.10001Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default News;
