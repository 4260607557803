import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Doc = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 19.2308V15.4615C28 14.1763 27.4311 12.9436 26.4184 12.0348C25.4057 11.126 24.0322 10.6154 22.6 10.6154H20.2C19.7226 10.6154 19.2648 10.4452 18.9272 10.1422C18.5896 9.83931 18.4 9.42843 18.4 9V6.84615C18.4 5.56087 17.8311 4.32824 16.8184 3.41941C15.8057 2.51058 14.4322 2 13 2H10M10 20.3077H22M10 24.6154H16M13.6 2H5.8C4.8064 2 4 2.72369 4 3.61538V28.3846C4 29.2763 4.8064 30 5.8 30H26.2C27.1936 30 28 29.2763 28 28.3846V14.9231C28 11.4957 26.4829 8.20863 23.7823 5.78508C21.0818 3.36153 17.4191 2 13.6 2Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Doc;
