import styled from '../../../../../../../plugins/styled';
import { ButtonContainer } from '../../../../../../common/button/styles';

export const AssetOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.purple_1}20;

  ${ButtonContainer} {
    svg  {
      color: ${({ theme }) => theme.colors.white_1};
    }
    &:hover {
      svg  {
        color: ${({ theme }) => theme.colors.purple_2};
      }
    }
    span {
      margin: 0;
    }
    margin-top: 5px;
    margin-right: 5px;
  }
  opacity: 0;
  transition: opacity 100ms linear;
  &:hover {
    opacity: 1;
  }
`;
