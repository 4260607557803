import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';

const Adviser = (props: IconProps) => {
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7536 15.5205C19.1386 15.5205 21.8827 12.7654 21.8827 9.36671C21.8827 5.96803 19.1386 3.21289 15.7536 3.21289C12.3686 3.21289 9.62454 5.96803 9.62454 9.36671C9.62454 12.7654 12.3686 15.5205 15.7536 15.5205Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M14.8535 17.4434H16.6515C21.8505 17.4434 26.0713 21.6812 26.0713 26.9012V30.2698C26.0713 30.3943 25.9725 30.4934 25.8485 30.4934H5.6564C5.5324 30.4934 5.43365 30.3943 5.43365 30.2698V26.9012C5.43365 21.6812 9.65443 17.4434 14.8535 17.4434Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M19.3612 10.3419C19.3612 12.0112 17.7469 13.3623 15.7536 13.3623C13.7604 13.3623 12.146 12.0089 12.146 10.3419"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M8.50397 11.2389C7.47287 11.2389 6.63928 10.3997 6.63928 9.36675C6.63928 8.3338 7.47516 7.49457 8.50397 7.49457V11.2413V11.2389Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M23.0699 11.2389C24.101 11.2389 24.9346 10.3997 24.9346 9.36675C24.9346 8.3338 24.0987 7.49457 23.0699 7.49457V11.2413V11.2389Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M23.0699 11.043C23.0699 13.0213 21.8919 14.7321 20.181 15.546"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M20.2987 15.5128L20.2643 15.4529C20.2009 15.3426 20.0605 15.3048 19.9506 15.3685L19.1651 15.8238C19.0552 15.8875 19.0176 16.0285 19.081 16.1388L19.1155 16.1987C19.1789 16.309 19.3193 16.3468 19.4292 16.2831L20.2147 15.8277C20.3245 15.764 20.3622 15.6231 20.2987 15.5128Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M8.50397 7.48539C8.50397 4.49723 11.7648 1.50677 15.7858 1.50677C19.8068 1.50677 23.0676 4.49723 23.0676 7.48539"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Adviser;
