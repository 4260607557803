import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  AdminProfile,
  AdminProfileInitialState,
  ApiError,
  ApiErrorInitialState,
  Profile,
} from '@hellodarwin/core/lib/features/entities';
import Language from '@hellodarwin/core/lib/features/enums/language';
import SingleLanguage from '@hellodarwin/core/lib/features/enums/single-language';
import { RootState } from '../../../app/app-store';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

export const me = createAsyncThunk<
  AdminProfile,
  { api: AdminApiClient },
  { rejectValue: ApiError }
>('admin/me', async ({ api }: { api: AdminApiClient }, { rejectWithValue }) => {
  try {
    return await api.me();
  } catch (err: any) {
    showErrorNotification(err.response.data.error_code);
    return rejectWithValue(err.response.data);
  }
});

export interface GlobalState {
  status: 'idle' | 'pending';
  error: ApiError;
  profile: AdminProfile;
}

const initialState: GlobalState = {
  status: 'idle',
  error: ApiErrorInitialState,
  profile: AdminProfileInitialState,
};

const globalSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const selectIsLoading = (state: RootState) =>
  state.global.status === 'pending';

export const selectAuthentifiedUser = (state: RootState) =>
  state.admins.profile;
export const selectAuthentifiedUserProfile = createSelector(
  (state: RootState) => state.global.profile,
  (profile) => {
    const userProfile: Profile = {
      ...profile,
      contact_id: profile.admin_id,
      contact_name: profile.first_name + ' ' + profile.last_name,
      contact_email: profile.email,
      contact_phone: profile.phone,
      contact_preferred_language: SingleLanguage.French,
      project_language: Language.French,
      profile_language: SingleLanguage.English,
      company_id: '',
      company_name: '',
      company_address: '',
      company_email: '',
      company_website: '',
      company_city: '',
      company_postal_code: '',
      company_province: '',
      company_country: '',
      company_size: '',
      company_description: '',
      terms_and_conditions: false,
    };
    return userProfile;
  },
);

export const globalReducer = globalSlice.reducer;
