import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { GrantTypeValidation } from '@hellodarwin/core/lib/features/entities/grants-entities';
import { getShortFormattedDate } from '@hellodarwin/core/lib/features/helpers';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Checkbox from 'antd/es/checkbox/Checkbox';
import message from 'antd/es/message';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  getGrantValidations,
  selectGrantValidation,
  updateGrantValidation,
} from '../../../features/api/slices/grants-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

interface GrantValidationProps {
  grantId: string;
  filterByType?: string;
}

const GrantValidationCheckbox: React.FC<GrantValidationProps> = ({
  grantId,
  filterByType,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();
  const grantValidations = useAppSelector(selectGrantValidation);
  const [validationStates, setValidationStates] = useState(grantValidations);

  const validationTypeLabels: Record<GrantTypeValidation, string> = {
    basic_info: t('grant_validation|basic_info'),
    projects: t('grant_validation|projects'),
    gin: 'GIN',
    timeline: t('grant_validation|timeline'),
    scrape: t('grant_validation|scrape'),
  };

  useEffect(() => {
    const updatedStates = grantValidations?.map((validation) => ({
      ...validation,
      isValidated: !!validation.validatedAt,
    }));
    setValidationStates(updatedStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantValidations]);

  const handleCheckboxChange = async (id: string, isChecked: boolean) => {
    const updatedStates = validationStates.map((validation) =>
      validation.grantValidationId === id
        ? { ...validation, isValidated: isChecked }
        : validation,
    );
    setValidationStates(updatedStates);

    const updatedValidation = updatedStates.find(
      (validation) => validation.grantValidationId === id,
    );

    if (updatedValidation) {
      await dispatch(
        updateGrantValidation({
          api,
          grantValidation: { ...updatedValidation, isValidated: isChecked },
        }),
      );
      message.success('Saved!');
    }
  };

  useEffect(() => {
    dispatch(getGrantValidations({ api, grantId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: GrantTypeValidation) => {
        return <Typography>{validationTypeLabels[type]}</Typography>;
      },
    },
    {
      title: t('grant_validation|admin_name'),
      dataIndex: 'adminName',
      key: 'adminName',
      render: (adminId) => {
        return <Typography>{adminId}</Typography>;
      },
    },
    {
      title: t('grant_validation|validated_at'),
      dataIndex: 'validatedAt',
      key: 'validatedAt',
      render: (validatedAt) => {
        return (
          <Typography>
            {validatedAt ? getShortFormattedDate(validatedAt) : ''}
          </Typography>
        );
      },
    },
    {
      title: t('grant_validation|is_validated'),
      dataIndex: 'isValidated',
      key: 'isValidated',
      render: (isValidated, record) => {
        return (
          <Div flex="row" align="center" justify="center">
            <Checkbox
              checked={isValidated}
              onChange={(e) =>
                handleCheckboxChange(record.grantValidationId, e.target.checked)
              }
            />
          </Div>
        );
      },
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={validationStates}
      pagination={false}
    />
  );
};

export default GrantValidationCheckbox;
