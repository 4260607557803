import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { ViewerForms } from '..';
import { ContactFormValues } from '../../../../features/entities/form-entities';
import useLocale from '../../../../features/providers/locale-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import validateEmail from '../../utils/validate-email';
import ProvincesSelect from './provinces-select';

interface ContactFormProps {
  onContactChange: (key: string, value: string) => void;
  initialValues: ContactFormValues;
  handleNext: () => void;
  setForms: Dispatch<SetStateAction<ViewerForms>>;
}

const ContactForm = ({
  onContactChange,
  initialValues,
  handleNext,
  setForms,
}: ContactFormProps) => {
  const { t } = useTranslations();
  const [form] = Form.useForm<ContactFormValues>();
  const { selectedLocale } = useLocale();
  useEffect(() => {
    setForms((prevState) => ({
      ...prevState,
      contactForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleNext}
      name={'contact-form'}
    >
      <Typography elementTheme="h5" style={{ marginBottom: 8 }}>
        {t('form_viewer|viewerContactFormTitle')}
      </Typography>
      <Typography elementTheme="body2" style={{ marginBottom: 24 }}>
        {t('form_viewer|viewerContactFormSubtitle')}
      </Typography>
      <Div flex="column" gap={24}>
        <Div flex="row" gap={32} tablet={{ flex: 'column', gap: 24 }}>
          <FormItem
            name="first_name"
            label={<FormLabel label={t('form_viewer|firstName')} />}
            style={{ flex: 1, margin: 0 }}
          >
            <Input
              size="large"
              style={{ width: '100%' }}
              placeholder={t('form_viewer|firstNameExample')}
              onChange={(e) => onContactChange('first_name', e.target.value)}
            />
          </FormItem>
          <FormItem
            style={{ flex: 1, margin: 0 }}
            name="last_name"
            label={<FormLabel label={t('form_viewer|lastName')} />}
          >
            <Input
              size="large"
              style={{ width: '100%' }}
              placeholder={t('form_viewer|lastNameExample')}
              onChange={(e) => onContactChange('last_name', e.target.value)}
            />
          </FormItem>
        </Div>
        <Div flex="row" gap={32} tablet={{ flex: 'column', gap: 24 }}>
          <FormItem
            name="company_name"
            label={<FormLabel label={t('form_viewer|companyName')} />}
            style={{ flex: 1, margin: 0 }}
          >
            <Input
              size="large"
              style={{ width: '100%' }}
              placeholder={t('form_viewer|companyNameExample')}
              onChange={(e) => onContactChange('company_name', e.target.value)}
            />
          </FormItem>
          <FormItem
            name="email"
            label={<FormLabel label={t('form_viewer|email')} />}
            style={{ flex: 1, margin: 0 }}
            rules={[
              {
                required: true,
                message: t('form_viewer|emailRequired'),
                validator: validateEmail(t('form_viewer|emailRequired')),
              },
            ]}
          >
            <Input
              type="email"
              size="large"
              style={{ width: '100%' }}
              placeholder={t('form_viewer|emailExample')}
              onChange={(e) => onContactChange('email', e.target.value)}
            />
          </FormItem>
        </Div>
        <Div
          flex="row"
          gap={32}
          style={{ width: '100%' }}
          tablet={{ flex: 'column', gap: 24 }}
        >
          <FormItem
            style={{ flex: 1, margin: 0 }}
            name="phone"
            label={<FormLabel label={t('form_viewer|phone')} />}
          >
            <Input
              size="large"
              style={{ width: '100%' }}
              placeholder={t('form_viewer|phoneNumberExample')}
              onChange={(e) => onContactChange('phone', e.target.value)}
            />
          </FormItem>
          <div style={{ flex: 1, margin: 0 }}>
            <ProvincesSelect
              name="province"
              errorMessage={t('form_viewer|onlyQuebecEligible')}
              isRequired
              isLongName
            />
          </div>
        </Div>
      </Div>
    </Form>
  );
};

export default ContactForm;
