import ProviderRefuseModal from '@hellodarwin/core/lib/components/rfp/provider-refuse-modal';
import RfpIgnoreModal from '@hellodarwin/core/lib/components/rfp/rfp-ignore-modal';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import {
  selectMatchesModal,
  selectSelectedMatch,
  toggleMatchesModal,
} from '../../../features/api/slices/matches-slice';
import {
  adminRefuseMatch,
  fetchRfpMatches,
  ignoreRfp,
  selectSelectedRfp,
} from '../../../features/api/slices/rfp-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';

const MatchesActionModal = () => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const modal = useAppSelector(selectMatchesModal);
  const match = useAppSelector(selectSelectedMatch) || '';
  const rfp = useAppSelector(selectSelectedRfp) || '';

  const handleClose = () => {
    dispatch(toggleMatchesModal({ isVisible: false, type: '' }));
  };

  const ignoreMatch = async (
    match_id: string,
    refusedReason: string,
    refusedReasonSpecified: string,
  ) => {
    await dispatch(
      ignoreRfp({
        api,
        matchId: match_id,
        refusedReason: refusedReason,
        refuseReasonSpecified: refusedReasonSpecified,
      }),
    ).then(() => handleClose());
    if (!!rfp) {
      dispatch(fetchRfpMatches({ api, rfpId: rfp.rfp_id }));
    }
  };

  const refuseMatch = async (
    match_id: string,
    refusedReason: string,
    refusedReasonSpecified: string,
  ) => {
    await dispatch(
      adminRefuseMatch({
        api,
        matchId: match_id,
        refusedReason: refusedReason,
        refusedReasonSpecified: refusedReasonSpecified,
      }),
    ).then(() => handleClose());
    if (!!rfp) {
      dispatch(fetchRfpMatches({ api, rfpId: rfp.rfp_id }));
    }
  };

  switch (modal.type) {
    case 'ignore':
      return (
        <RfpIgnoreModal
          match={match}
          modalVisible={modal.isVisible}
          handleIgnore={ignoreMatch}
          closeModal={handleClose}
        />
      );
    case 'refuse':
      return (
        <ProviderRefuseModal
          match={match}
          modalVisible={modal.isVisible}
          handleRefuse={refuseMatch}
          closeModal={handleClose}
        />
      );

    default:
      return <></>;
  }
};

export default MatchesActionModal;
