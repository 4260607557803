import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';

const AssetDrawerInfoRow = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  const theme = useTheme();

  if (!value?.length) return <></>;
  return (
    <Div>
      <Typography elementTheme="overline" color={theme.colors.primary}>
        {label}
      </Typography>
      <Typography elementTheme="body2" color={theme.colors.grey_2}>
        {value}
      </Typography>
    </Div>
  );
};
export default AssetDrawerInfoRow;
