import { useTranslation } from 'react-i18next';
import { FiltersDropdownProps } from '.';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import { SmallDesktop } from '../../../../common/Media';

const FilterDropdownActions = ({
  handleClose,
  company,
  resetFiltersWithCompany,
  resetFilters,
}: FiltersDropdownProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      flex="row"
      gap={8}
      fitContent
      tablet={{ fitContent: false }}
      mobile={{ flex: 'column' }}
    >
      {!!company && (
        <Button
          onClick={resetFiltersWithCompany}
          size="small"
          style={{ borderRadius: 4, minWidth: 0 }}
          defaultStyle={theme.colors.white_1}
          tablet={{
            style: {
              width: '100%',
              justifyContent: 'flex-start',
            },
            defaultStyle: theme.colors.purple_1,
          }}
        >
          {t(`grants|fundingExplorerFilter.resetFilterWithCompany`)}
        </Button>
      )}

      <Button
        onClick={resetFilters}
        size="small"
        style={{ borderRadius: 4, minWidth: 0 }}
        defaultStyle={theme.colors.white_1}
        tablet={{
          style: {
            width: '100%',

            justifyContent: 'flex-start',
          },
          defaultStyle: theme.colors.purple_1,
        }}
      >
        {t(`grants|fundingExplorerFilter.resetFilter`)}
      </Button>
      <SmallDesktop direction="up">
        <Button
          onClick={handleClose}
          size="small"
          style={{ borderRadius: 4, minWidth: 0 }}
          defaultStyle={theme.colors.primary}
          tablet={{ style: { width: '100%', justifyContent: 'flex-start' } }}
        >
          {t(`grants|fundingExplorerFilter.closeFilters`)}
        </Button>
      </SmallDesktop>
    </Div>
  );
};

export default FilterDropdownActions;
