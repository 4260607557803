import { HubspotContainerEntity } from '../../../features/entities/hubspot-entities';
import styled, { css } from '../../../plugins/styled';

interface HubspotFormStylingProps {
  $assignedTheme: HubspotContainerEntity['assignedTheme'];
  $isNewsletter: boolean;
}

const ContainerVerticalStyling = css`
  flex-direction: row;
  flex-wrap: wrap;
  input,
  .hs-input,
  select  {
    width: 312px !important;
  }
`;
const ContainerHorizontalStyling = css`
  flex-direction: column;
  flex-wrap: wrap;
`;
const FullWidthStyling = css`
  flex-direction: column;

  .form-columns-2,
  .form-columns-1,
  .form-columns-0 {
    display: flex;
    gap: 24px;
    & > * {
      flex: 1;
    }
  }
  .hs-submit {
    margin: auto;
  }
`;

export const HubspotFormStyling = styled.div<HubspotFormStylingProps>`
  width: 100%;
  h3 {
    font-size: ${(props) => props.theme.fonts.h5};
    line-height: ${(props) => props.theme.fontsLineHeights.h5};
    font-family: Figtree;
  }
  h2 {
    font-family: Figtree;
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.h2};
    line-height: ${(props) => props.theme.fontsLineHeights.h2};
    margin-bottom: 20px;
  }
  h2,
  p {
    max-width: 650px;
  }
  .hs-richtext {
    margin-bottom: 30px;
  }

  form {
    width: 100%;
    max-width: 100%;
    display: flex;

    ${({ $assignedTheme }) =>
      $assignedTheme === 'Container - Vertical'
        ? ContainerVerticalStyling
        : $assignedTheme === 'Container - Horizontal'
          ? ContainerHorizontalStyling
          : FullWidthStyling}
    gap: 24px;

    & > div:first-child {
      flex-grow: 1;
      min-width: 0;
      width: 100%;
    }
    .hs-richtext {
      margin-bottom: 0;
      width: 100%;
    }
    fieldset {
      max-width: 100%;
    }
    .hs-main-font-element {
      font-size: 14px;
    }
    .hs-fieldtype-text {
      width: fit-content !important;
    }
    .no-list.hs-error-msgs.inputs-list {
      margin: 0;
    }
    .hs-field-desc {
      font-size: ${(props) => props.theme.fonts.body3};
      line-height: ${(props) => props.theme.fontsLineHeights.body3};
      color: ${(props) => props.theme.colors.grey_2};
    }
    .hs-error-msgs {
      color: ${(props) => props.theme.colors.red_1};
    }
    .field {
      label {
        font-size: ${(props) => props.theme.fonts.body2};
        line-height: ${(props) => props.theme.fontsLineHeights.body2};
        margin-bottom: 8px;
      }
      legend {
        margin-bottom: 8px;
      }
      .input {
        input,
        .hs-input,
        select {
          box-sizing: border-box;
          border: 1px solid ${(props) => props.theme.colors.primary};
          background: ${(props) => props.theme.colors.white_1};
          color: ${(props) => props.theme.colors.primary};
          border-radius: 4px;
          max-width: 100%;
          height: 40px;
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
          font-size: 14px;
          &::placeholder,
          &.is-placeholder {
            color: ${(props) => props.theme.colors.grey_3};
          }
        }
        .hs-form-checkbox-display {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          .hs-input[type='checkbox'] {
            width: 16px !important;
            height: 16px;
          }
          span {
            font-size: ${(props) => props.theme.fonts.body3};
            line-height: ${(props) => props.theme.fontsLineHeights.body3};
          }
        }
      }
    }
    .inputs-list {
      padding: 0;
      li {
        display: block;
      }
    }
  }
  .hs_submit {
    width: fit-content;
    height: 68px;
    display: flex;
    align-items: flex-end;
  }
  .hs-button {
    cursor: pointer;
    border-radius: 30px;
    border: none;
    min-width: 180px;
    width: fit-content !important;
    min-height: 40px;
    background: ${(props) => props.theme.colors.purple_3};
    color: ${(props) => props.theme.colors.white_1};
    padding: 0 30px;
    font-size: ${(props) => props.theme.fonts.buttonC};
    line-height: ${(props) => props.theme.fontsLineHeights.buttonC};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    &:hover {
      background: ${(props) => props.theme.colors.purple_2};
    }
  }
`;
