import DatePicker, { DatePickerProps } from 'antd/es/date-picker';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import dayjs, { Dayjs } from 'dayjs';
import React, { Suspense, useEffect, useState } from 'react';
import { ProfileEditModalFunctions } from '.';
import { Provider } from '../../../../features/entities';
import handleDispatchResponse from '../../../../features/helpers/handle-dispatch-response';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ModalWithHeaderFooter from '../../../common/hd-modal/layouts/modal-with-header-footer';
import Loading from '../../../loading';
import { companySize } from '../../utils/company-infos';
import validateWebsite from '../../utils/validate-website';

export interface ContactInfosFormValues {
  company_website: string;
  company_size: string;
  provider_hourly_rate: number;
  company_founded_at: string;
}

type FastInfosFormProps = {
  provider: Provider;
  setFooter: (footer: JSX.Element) => void;
  handleCancel: () => void;
  functions: ProfileEditModalFunctions;
};

const FastInfosForm = ({
  provider,
  setFooter,
  handleCancel,
  functions,
}: FastInfosFormProps) => {
  const { t } = useTranslation();
  const [form] = useForm<ContactInfosFormValues>();
  const theme = useTheme();

  const { isLoading } = functions;

  const initialValues: ContactInfosFormValues = {
    company_website: provider.website || '',
    company_size: provider.size || '',
    provider_hourly_rate: provider.hourly_rate || 0,
    company_founded_at: provider.founded_at || '',
  };

  const [foundedAt, setFoundedAt] = useState<Dayjs>(
    dayjs(provider.founded_at || new Date()),
  );

  const handleFoundedAtChange: DatePickerProps['onChange'] = (date) => {
    if (date !== null) setFoundedAt(date);
  };

  useEffect(() => {
    form.setFieldValue('company_founded_at', foundedAt);
  }, [foundedAt]);

  useEffect(() => {
    setFoundedAt(dayjs(provider.founded_at || new Date()));
  }, [provider]);

  const handleSave = async () => {
    form.validateFields().then((newCompany) => {
      const { company_id } = provider;
      const data = new FormData();
      data.append(
        'request',
        JSON.stringify({ ...newCompany, founded_at: foundedAt, company_id }),
      );

      !!functions.updateProfile &&
        functions
          .updateProfile({ data })
          .then((res) =>
            handleDispatchResponse(
              res,
              t('notification.saved'),
              t('notification.saving_error'),
            ),
          )
          .finally(handleCancel);
    });
  };

  useEffect(() => {
    setFooter(
      <ModalWithHeaderFooter.Footer
        actions={
          <>
            <Button
              defaultStyle={theme.colors.transparent}
              onClick={handleCancel}
              size="small"
            >
              {t('button.cancel')}
            </Button>
            <Button
              onClick={handleSave}
              size="small"
              defaultStyle={theme.colors.purple_1}
            >
              {t('button.save')}
            </Button>
          </>
        }
      />,
    );
  }, []);

  return (
    <Div flex="row" align="center" justify="stretch">
      {isLoading ? (
        <Loading />
      ) : (
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={handleSave}
          layout={'vertical'}
          style={{ width: '100%' }}
        >
          <Div flex="column" gap={16}>
            <Suspense fallback={<Loading />}>
              <Form.Item
                style={{ flex: 1 }}
                name="company_founded_at"
                valuePropName={'year'}
                label={t('profile|profileForm.company.labels.foundedAt')}
              >
                <DatePicker
                  size="large"
                  style={{ width: '100%' }}
                  onChange={handleFoundedAtChange}
                  value={foundedAt}
                  picker="year"
                />
              </Form.Item>
            </Suspense>
            <Form.Item
              label={t('profile|profileForm.company.labels.size')}
              name="company_size"
            >
              <Select placeholder="Select option">
                {React.Children.toArray(
                  companySize.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  )),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="company_website"
              label={t('profile|profileForm.company.labels.website')}
              rules={[
                {
                  required: true,
                  validator: validateWebsite({
                    message: t('profile|profileForm.validation.website'),
                    isRequired: true,
                  }),
                },
              ]}
            >
              <Input
                size={'large'}
                placeholder={t('profile|profileForm.company.labels.website')}
              />
            </Form.Item>

            <Form.Item
              name="provider_hourly_rate"
              label={t('profile|profileForm.company.labels.hourlyRate')}
            >
              <InputNumber />
            </Form.Item>
          </Div>
        </Form>
      )}
    </Div>
  );
};

export default FastInfosForm;
