import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import {
  ChatType,
  HdChatPrompt,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Close from '@hellodarwin/icons/dist/icons/Close';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Input from 'antd/es/input';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  fetchAllPrompts,
  selectAllPrompts,
} from '../../features/api/slices/hd-chat-prompts-slice';
import { useAdminApi } from '../../features/api/use-admin-api';
import useDebounce from '../../hooks/use-debounce';
import theme from '../../theme';

const SEARCH_DELAY_MS = 500;
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

type PromptsSearchProps = {
  chatType: ChatType;
  handleSubmit: (prompt: HdChatPrompt) => void;
  templatePrompts: HdChatPrompt[];
};

const PromptsSearch = ({
  chatType,
  handleSubmit,
  templatePrompts,
}: PromptsSearchProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<
    HdChatPrompt[] | HdChatPromptRequest[] | undefined
  >(undefined);
  const prompts = useAppSelector((state) => selectAllPrompts(state));
  const [searchTerm, setSearchTerm] = useState('');
  const [hovered, setHovered] = useState<string | null>(null);

  useEffect(() => {
    dispatch(
      fetchAllPrompts({
        api,
        page: DEFAULT_PAGE,
        size: DEFAULT_LIMIT,
        type: chatType,
      }),
    )
      .unwrap()
      .then(() => {
        setResults(prompts);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType]);

  const onSearch = (query: string) => {
    if (query === '') return;
    (async () => {
      setIsSearching(true);
      try {
        const response = await api.queryPrompts(
          DEFAULT_PAGE,
          DEFAULT_LIMIT,
          query,
          chatType,
        );

        if (!response || !response.length) {
          setResults(undefined);
        } else {
          setResults(response);
        }
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    })();
  };

  const clearSearch = () => {
    setSearchTerm('');
    setResults(undefined);
  };

  const handleResultClick = (prompt: HdChatPrompt) => {
    handleSubmit(prompt);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, chatType]);

  return (
    <Div>
      <Div flex="row" gap={16}>
        <Div
          backgroundColor={theme.colors.purple_5}
          borderColor={theme.colors.purple_3}
          borderRadius={4}
          style={{ padding: '0.2rem 1rem' }}
          flex="row"
          align="center"
          justify="space-between"
        >
          <Search width={14} />
          <Input
            value={searchTerm}
            placeholder="Search... (prompt content, name)"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              outline: 'none',
              border: 'none',
              background: theme.colors.transparent,
              width: '100%',
              boxShadow: 'none',
            }}
          />
          <Div fitContent>
            {isSearching ? (
              <Loading />
            ) : (
              searchTerm !== '' && (
                <Div onClick={clearSearch} style={{ cursor: 'pointer' }}>
                  <Close width={14} />
                </Div>
              )
            )}
          </Div>
        </Div>
      </Div>
      {!!results && (
        <Div
          style={{
            paddingLeft: '1rem',
            paddingRight: '1rem',
            overflow: 'auto',
            maxHeight: 400,
          }}
        >
          {results?.map(
            (p: any) =>
              !templatePrompts.find((tp) => tp.id === p.id) && (
                <Button
                  key={p.id}
                  onMouseEnter={() => setHovered(p.id)}
                  onMouseLeave={() => setHovered(null)}
                  onClick={() => handleResultClick(p)}
                  style={{
                    width: '450px',
                    color: theme.colors.purple_1,
                    border: '1px solid',
                    borderColor: theme.colors.purple_1,
                    backgroundColor:
                      hovered === p.id
                        ? theme.colors.purple_4
                        : theme.colors.purple_6,
                    borderRadius: 5,
                    padding: 8,
                    marginTop: 16,
                    paddingLeft: '-16px',
                  }}
                >
                  <Div
                    flex="row"
                    justify="space-between"
                    align="center"
                    fullHeight
                    style={{ width: '400px' }}
                  >
                    <Div fullHeight flex="column" style={{ width: '100%' }}>
                      <Typography
                        elementTheme="subtitle1"
                        medium
                        fitContent
                        ellipsis
                        style={{ maxWidth: '350px' }}
                      >
                        {p.name}
                      </Typography>
                      {p.content && (
                        <Typography
                          elementTheme="body3"
                          fitContent
                          ellipsis
                          style={{ maxWidth: '350px' }}
                        >
                          {p.content}
                        </Typography>
                      )}
                    </Div>
                    {hovered === p.id && (
                      <AddNew
                        width={15}
                        style={{ color: theme.colors.purple_1 }}
                      />
                    )}
                  </Div>
                </Button>
              ),
          )}
        </Div>
      )}
    </Div>
  );
};
export default PromptsSearch;
