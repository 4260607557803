import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

interface GreenCheckProps extends IconProps {
  bgColor?: string;
}
const GreenCheck = ({ bgColor = '#BAFFE5', ...props }: GreenCheckProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37" cy="37" r="37" fill={bgColor} />

      <path
        stroke="currentColor"
        d="M19 34.8824L31.5926 49L53 25"
        strokeWidth={strokeWidth * 2}
      />
    </svg>
  );
};

export default GreenCheck;
