import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';

type ReviewConfirmProps = {
  handleClose: () => void;
  isActive?: boolean;
};

const ReviewConfirm = ({ handleClose, isActive }: ReviewConfirmProps) => {
  const theme = useTheme();

  if (!isActive) return <></>;
  const { t } = useTranslations();
  return (
    <Div flex="column" align="center" gap={24} className="fade-in">
      <Div flex="column" gap={12} align="center">
        <Typography.Title level={2} elementTheme="h3" textAlign="center">
          {t('reviews|newReviewThankYouScreenHeader')}
        </Typography.Title>
        <Typography textAlign="center" elementTheme="body1">
          {t('reviews|newReviewThankYouScreenDescription')}
        </Typography>
      </Div>
      <Button
        defaultStyle={theme.colors.grey_1}
        size={'medium'}
        onClick={handleClose}
      >
        {t('reviews|newReviewThankYouScreenClose')}
      </Button>
    </Div>
  );
};

export default ReviewConfirm;
