import Div from '@hellodarwin/core/lib/components/common/div';
import { NewTag } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Select from 'antd/es/select';
import React from 'react';

interface TagSelectionDropdownsProps {
  selectedGoals: NewTag[];
  selectedCategories: NewTag[];
  selectedExpenses: NewTag[];
  onGoalsChange: (values: string[]) => void;
  onCategoriesChange: (values: string[]) => void;
  onExpensesChange: (values: string[]) => void;
  goalTags: Record<string, NewTag>;
  expenseCategoryTags: Record<string, NewTag>;
  expenseTags: Record<string, NewTag>;
  parentGoals: NewTag[];
  parentCategories: NewTag[];
}

const TagSelectionDropdowns: React.FC<TagSelectionDropdownsProps> = ({
  selectedGoals,
  selectedCategories,
  selectedExpenses,
  onGoalsChange,
  onCategoriesChange,
  onExpensesChange,
  goalTags,
  expenseCategoryTags,
  expenseTags,
  parentGoals,
  parentCategories,
}) => {
  const { t } = useTranslations();

  return (
    <Div>
      <Div flex="row" gap={8}>
        {/* Goals Dropdown */}
        <Div>
          <Select
            mode="multiple"
            placeholder={t('project|tag_goals')}
            style={{ width: '100%' }}
            value={selectedGoals.map((tag) => tag.tag_id)}
            onChange={(values) => onGoalsChange(values)}
          >
            {Object.values(goalTags).map((goal) => (
              <Select.Option key={goal.tag_id} value={goal.tag_id}>
                {goal.content}
              </Select.Option>
            ))}
          </Select>
        </Div>

        {/* Expense Categories Dropdown */}
        <Div>
          <Select
            mode="multiple"
            placeholder={t('project|tag_expense_categories')}
            style={{ width: '100%' }}
            value={selectedCategories.map((tag) => tag.tag_id)}
            onChange={(values) => onCategoriesChange(values)}
          >
            {parentGoals.map((goal) => (
              <Select.OptGroup key={goal.tag_id} label={goal.content}>
                {Object.values(expenseCategoryTags)
                  .filter((category) => category.parent_id === goal.tag_id)
                  .map((category) => (
                    <Select.Option
                      key={category.tag_id}
                      value={category.tag_id}
                    >
                      {category.content}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Div>

        {/* Expenses Dropdown */}
        <Div>
          <Select
            mode="multiple"
            placeholder={t('project|tag_expenses')}
            style={{ width: '100%' }}
            value={selectedExpenses.map((tag) => tag.tag_id)}
            onChange={(values) => onExpensesChange(values)}
          >
            {parentCategories.map((category) => (
              <Select.OptGroup key={category.tag_id} label={category.content}>
                {Object.values(expenseTags)
                  .filter((expense) => expense.parent_id === category.tag_id)
                  .map((expense) => (
                    <Select.Option key={expense.tag_id} value={expense.tag_id}>
                      {expense.content}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Div>
      </Div>
    </Div>
  );
};

export default TagSelectionDropdowns;
