import Empty from 'antd/es/empty';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import ReviewRatings from '../../../../reviews/ratings';

export type Sections =
  | 'About'
  | 'Video'
  | 'Reviews'
  | 'Portfolio'
  | 'Team'
  | 'Expertise';

export interface EmptySectionProps {
  handleClick?: () => void;
  cta?: string;
  title: string;
  subtitle: string;
  isEditable?: boolean;
  sectionName: Sections;
}

const EmptySection = ({
  handleClick,
  cta,
  title,
  subtitle,
  isEditable,
  sectionName,
}: EmptySectionProps) => {
  const theme = useTheme();

  return (
    <Empty
      imageStyle={{ height: 'auto' }}
      image={
        sectionName === 'Reviews' ? (
          <ReviewRatings size="extra-large" disabled defaultValue={0} />
        ) : (
          Empty.PRESENTED_IMAGE_DEFAULT
        )
      }
      description={
        <Div flex="column" align="center" gap={12}>
          <Typography
            textAlign="center"
            elementTheme="subtitle1"
            color={theme.colors.grey_1}
          >
            {title}
          </Typography>
          {isEditable && (
            <>
              <Typography color={theme.colors.grey_2} textAlign="center">
                {subtitle}
              </Typography>
              {handleClick && (
                <Button
                  onClick={handleClick}
                  fitContent
                  defaultStyle={theme.colors.purple_1}
                  size="small"
                >
                  {cta}
                </Button>
              )}
            </>
          )}
        </Div>
      }
    />
  );
};

export default EmptySection;
