export interface FundingExplorerFilterValues {
  textQuery: string;
  filterBy: string;
  industry: string[];
  subindustry: string[];
  status: string[];
  financingType: string[];
  region: string[];
  closingDate: Date;
  service: string[];
  annualRevenue: string;
  companySize: string;
  selectedProjects: string[];
}

export const InitialFundingExplorerFilterValues: FundingExplorerFilterValues = {
  subindustry: [],
  textQuery: '',
  service: [],
  status: [],
  filterBy: '',
  financingType: [],
  industry: [],
  region: [],
  closingDate: undefined,
  annualRevenue: '',
  companySize: '',
  selectedProjects: [],
};
