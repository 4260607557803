const CalculateFraction = ({
  newValue,
  oldValue,
}: {
  newValue: number;
  oldValue: number;
}) => {
  const result = (oldValue / 32) * newValue;

  return result;
};

export default CalculateFraction;
