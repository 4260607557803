import { useEffect, useState } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import SpinProgress from '../../common/spin/progress';
import Typography from '../../common/typography';

interface FormLoadingProps {
  onLoadingFinished: () => void;
}
const FormLoading = ({ onLoadingFinished }: FormLoadingProps) => {
  const { t } = useTranslations();

  const [loadingProgress, setLoadingProgress] = useState<number>(0);

  const handleLoading = () => {
    if (loadingProgress >= 100) {
      onLoadingFinished();
    } else {
      const newProgress = Math.ceil(Math.random() * 10) + 5;
      setLoadingProgress((prevProgress) => prevProgress + newProgress);
    }
  };

  useEffect(() => {
    setTimeout(handleLoading, Math.ceil(Math.random() * 10) * 50);
  }, [loadingProgress]);

  return (
    <Div
      flex="column"
      align="center"
      justify="center"
      style={{ minHeight: 400, maxWidth: 450, margin: 'auto' }}
      gap={16}
    >
      <SpinProgress percent={loadingProgress} />
      <Typography elementTheme="subtitle2" textAlign="center">
        {t('form_viewer|form_viewer_loading')}
      </Typography>
    </Div>
  );
};

export default FormLoading;
