import React, { ReactNode } from 'react';
import { WebsiteGrantResult } from '../../../features/entities/grants-entities';
import SingleGrantSections from '../../../features/enums/single-grant-sections';
import { useTranslations } from '../../../features/providers/translations-provider';
import GrantHeader from './grant-header';
import GrantSection, { GrantSectionProps } from './grant-sections';
import GrantAdmissibility from './grant-sections/admissibility';
import GrantContact from './grant-sections/contact';
import GrantCriteria from './grant-sections/criteria';
import GrantDocuments from './grant-sections/documents';
import GrantOtherInfos from './grant-sections/other-infos';
import GrantPreview from './grant-sections/preview';
import GrantProjects from './grant-sections/projects';
import GrantRegister from './grant-sections/register';
import GrantSummary from './grant-sections/summary';
import GrantTermsAndConditions from './grant-sections/terms-and-conditions';
import { GrantSingleContainer } from './styles';

export interface GrantBlock {
  label: string;
  menuLabel: string;
  content: ReactNode;
  id: string;
  hide?: boolean;
  styles?: GrantSectionProps['styles'];
}

interface SingleGrantProps {
  grant: WebsiteGrantResult;
  sections: SingleGrantSections[];
  onClick?: () => void;
  alreadyApplied?: boolean;
}
const SingleGrant = ({
  grant,
  sections,
  onClick,
  alreadyApplied,
}: SingleGrantProps) => {
  const { t } = useTranslations();

  if (!grant) return <></>;

  const showAdmissibility: boolean =
    !grant?.eligibility_criteria &&
    !grant?.eligible_expenses &&
    !grant?.who_can_apply &&
    !grant?.who_cannot_apply &&
    !grant?.zone;

  const blocks: { [block_name: string]: GrantBlock } = {
    [SingleGrantSections.Summary]: {
      label: t(`grant_single_labels|${SingleGrantSections.Summary}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Summary}`),
      content: <GrantSummary {...grant} />,
      id: SingleGrantSections.Summary,
      styles: { body: { padding: 0 } },
    },
    [SingleGrantSections.Admissibility]: {
      label: t(`grant_single_labels|${SingleGrantSections.Admissibility}`),
      menuLabel: t(
        `grant_single_labels|menu_${SingleGrantSections.Admissibility}`,
      ),
      content: <GrantAdmissibility {...grant} />,
      id: SingleGrantSections.Admissibility,
      hide: showAdmissibility,
    },
    [SingleGrantSections.Preview]: {
      label: t(`grant_single_labels|${SingleGrantSections.Preview}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Preview}`),
      content: <GrantPreview {...grant} />,
      id: SingleGrantSections.Preview,
      hide: !grant?.grant_description_long && !grant?.grant_youtube_url,
    },
    [SingleGrantSections.TermsAndConditions]: {
      label: t(`grant_single_labels|${SingleGrantSections.TermsAndConditions}`),
      menuLabel: t(
        `grant_single_labels|menu_${SingleGrantSections.TermsAndConditions}`,
      ),
      content: <GrantTermsAndConditions {...grant} />,
      id: SingleGrantSections.TermsAndConditions,
      hide: !grant?.funding_terms_and_conditions,
    },
    [SingleGrantSections.OtherInfo]: {
      label: t(`grant_single_labels|${SingleGrantSections.OtherInfo}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.OtherInfo}`),
      content: <GrantOtherInfos {...grant} />,
      id: SingleGrantSections.OtherInfo,
      hide: !grant?.additional_information,
    },
    [SingleGrantSections.Documents]: {
      label: t(`grant_single_labels|${SingleGrantSections.Documents}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Documents}`),
      content: <GrantDocuments {...grant} />,
      id: SingleGrantSections.Documents,
      hide: !grant?.official_documents,
    },
    [SingleGrantSections.Register]: {
      label: t(`grant_single_labels|${SingleGrantSections.Register}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Register}`),
      content: <GrantRegister {...grant} />,
      id: SingleGrantSections.Register,
      hide: !grant.steps_how_to_apply,
    },
    [SingleGrantSections.Criteria]: {
      label: t(`grant_single_labels|${SingleGrantSections.Criteria}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Criteria}`),
      content: <GrantCriteria {...grant} />,
      id: SingleGrantSections.Criteria,
      hide: !grant.selection_criteria,
    },
    [SingleGrantSections.Contact]: {
      label: t(`grant_single_labels|${SingleGrantSections.Contact}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Contact}`),
      content: <GrantContact {...grant} />,
      id: SingleGrantSections.Contact,
      hide: !grant.application_email_address && !grant.application_phone_number,
    },
    [SingleGrantSections.Projects]: {
      label: t(`grant_single_labels|${SingleGrantSections.Projects}`),
      menuLabel: t(`grant_single_labels|menu_${SingleGrantSections.Projects}`),
      content: <GrantProjects {...grant} />,
      id: SingleGrantSections.Projects,
      hide: !grant.project_activity && !grant.grant_projects?.length,
    },
  };

  const activeSections = sections.filter(
    (s) => !!blocks[s] && !blocks[s]?.hide,
  );
  return (
    <GrantSingleContainer flex="column" gap={25}>
      <GrantHeader {...grant} sections={activeSections} blocks={blocks} />

      {React.Children.toArray(
        activeSections.map((s) => (
          <GrantSection
            styles={blocks[s].styles}
            title={blocks[s].label}
            key={s}
            id={blocks[s].id}
            applyButton={[
              SingleGrantSections.Admissibility,
              SingleGrantSections.Register,
              SingleGrantSections.Contact,
              SingleGrantSections.Summary,
            ]
              .toString()
              .includes(blocks[s].id)}
            alreadyApplied={!!alreadyApplied}
            onClick={onClick}
          >
            {blocks[s].content}
          </GrantSection>
        )),
      )}
    </GrantSingleContainer>
  );
};

export default SingleGrant;
