import React, { CSSProperties } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import Typography from '../typography';
import Drag from './drag';
import Drop from './drop';
import { DragDropColumn, DragDropItem } from './multiple';

interface ColumnProps {
  className?: string;
  column: DragDropColumn;
  items: DragDropItem<any>[];
  provided?: DraggableProvided;
  itemStyle?: CSSProperties;
}

const Column = ({ column, items, provided, itemStyle }: ColumnProps) => {
  return (
    <div {...provided?.draggableProps}>
      <Typography.Title level={2} elementTheme="subtitle1">
        {column.title}
      </Typography.Title>
      <Drop
        droppableId={column.id}
        type="item"
        className="item-drop"
        isDropDisabled={column.isDropDisabled}
      >
        {React.Children.toArray(
          items.map((item, index) => (
            <Drag draggableId={item?.id} index={index} key={item?.id}>
              {item?.children}
            </Drag>
          )),
        )}
      </Drop>
    </div>
  );
};

export default Column;
