import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Service = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3866 2.05508C15.8453 1.89553 15.2093 2.07746 14.84 2.66808C13.6372 4.5925 6.4201 16.139 5.2171 18.0634C4.63477 18.9941 5.29229 20.2062 6.37707 20.2062H14.6253V28.6036C14.6253 30.0032 16.4215 30.5281 17.16 29.3468L26.7829 13.9515C27.3652 13.0208 26.7077 11.8087 25.6229 11.8087H17.3747V3.41126C17.3747 2.71148 16.9279 2.21603 16.3866 2.05508Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Service;
