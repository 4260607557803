import React, { CSSProperties, ReactNode } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import theme, { DefaultTheme } from '../../../theme';
import Drop from './drop';

interface DragDropSingleColumnContainerProps<T> {
  items: T[];
  setItems: (newItems: T[]) => void;
  renderItem: (item: T, index?: number) => ReactNode;
  style?: CSSProperties;
  itemStyle?: CSSProperties;
  direction?: 'vertical' | 'horizontal';
  onChange?: (newItems: T[]) => void;
}

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  theme: DefaultTheme,
  draggableStyle?: DraggingStyle | NotDraggingStyle,
  itemStyle?: CSSProperties,
): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  width: 'fit-content',
  // change background colour if dragging
  background: isDragging ? theme.colors.purple_4 : undefined,

  // styles we need to apply on draggables
  ...draggableStyle,
  ...itemStyle,
});

const DragDropSingleColumnContainer = <Type, key extends keyof Type>({
  items,
  setItems,
  renderItem,
  style,
  direction = 'horizontal',
  itemStyle,
  onChange,
}: DragDropSingleColumnContainerProps<Type>) => {
  const reorder = (list: Type[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newList = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(newList);
    onChange && onChange(newList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Drop
        className="column-content"
        droppableId="all-columns"
        type="column"
        direction={direction}
      >
        {React.Children.toArray(
          items.map((itm, index) => (
            <Draggable
              key={`drop-item-${index}`}
              draggableId={`drop-item-${index}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    theme,
                    provided.draggableProps.style,
                    itemStyle,
                  )}
                >
                  {renderItem(itm, index)}
                </div>
              )}
            </Draggable>
          )),
        )}
      </Drop>
    </DragDropContext>
  );
};

export default DragDropSingleColumnContainer;
