import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Search from '@hellodarwin/icons/dist/icons/Search';
import {
  ProjectApplicationFormStateSelectorBoxProps,
  ProjectApplicationFormStateSelectorProps,
} from '../../../types';
import { ApplicationStateBox } from './styles';

const ProjectApplicationFormStateSelectorBox: React.FC<
  ProjectApplicationFormStateSelectorBoxProps
> = ({ Icon, label, setActiveState, activeState, value }) => {
  const handleClick = () => setActiveState(value);

  return (
    <ApplicationStateBox
      onClick={handleClick}
      $isActive={value === activeState}
    >
      <Icon size={32} />
      <Typography elementTheme="body2" textAlign="center">
        {label}
      </Typography>
    </ApplicationStateBox>
  );
};

const ProjectApplicationFormStateSelector: React.FC<
  ProjectApplicationFormStateSelectorProps
> = (props) => {
  const { t } = useTranslations();
  return (
    <Div flex="column" gap={16} fitContent>
      <ProjectApplicationFormStateSelectorBox
        Icon={AddNew}
        value="create"
        label={t('createApplication|createNewApplication')}
        {...props}
      />
      <ProjectApplicationFormStateSelectorBox
        Icon={Search}
        value="search"
        label={t('createApplication|searchExistingApplication')}
        {...props}
      />
    </Div>
  );
};
export default ProjectApplicationFormStateSelector;
