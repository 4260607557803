import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import Div from '../../common/div';
import Typography from '../../common/typography';

export interface BusinessProfileInfoRowProps {
  Icon: IconNode;
  label: string;
  value: ReactNode;
}

const IconSize = 18;

const BusinessProfileInfoRow: React.FC<BusinessProfileInfoRowProps> = ({
  Icon,
  label,
  value,
}) => {
  const theme = useTheme();
  if (!value) return <></>;
  return (
    <Div flex="column" gap={8}>
      <Div flex="row" gap={8} align="center">
        <Icon size={IconSize} color={theme.colors.grey_2} />
        <Typography elementTheme="overline" color={theme.colors.grey_2}>
          {label}
        </Typography>
      </Div>
      <Typography style={{ paddingInlineStart: IconSize + 8 }}>
        {value}
      </Typography>
    </Div>
  );
};

export default BusinessProfileInfoRow;
