import Drawer from 'antd/es/drawer';
import { FundingExplorerFilterProps } from '..';
import { useTranslation } from '../../../../../plugins/i18n';
import { Tablet } from '../../../../common/Media';
import FilterDropdownDesktopDrawer from './filter-dropdown-desktop-drawer';
import FilterDropdownForm from './filter-dropdown-form';

export interface FiltersDropdownProps extends FundingExplorerFilterProps {
  isOpen: boolean;
  handleClose: () => void;
  resetFilters: () => void;
  resetFiltersWithCompany: () => void;
}

const FiltersDropdown = (props: FiltersDropdownProps) => {
  const { t } = useTranslation();
  const { isOpen, handleClose } = props;

  return (
    <>
      <Tablet direction="up">
        <FilterDropdownDesktopDrawer open={isOpen} handleClose={handleClose}>
          <FilterDropdownForm {...props} />
        </FilterDropdownDesktopDrawer>
      </Tablet>
      <Tablet direction="down">
        <Drawer
          title={t(`grants|fundingExplorerFilter.advancedFilters`)}
          open={isOpen}
          onClose={handleClose}
          styles={{ wrapper: { width: '100vw' } }}
        >
          <FilterDropdownForm {...props} />
        </Drawer>
      </Tablet>
    </>
  );
};

export default FiltersDropdown;
