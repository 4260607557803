import styled from '../../../plugins/styled';

export const FormContentStyled = styled.div`
  height: calc(100vh - 58px - 76px);
  overflow: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 0 32px;
  padding-bottom: 32px;
  width: 100%;
`;
export const FormListStyled = styled.div`
  height: calc(100vh - 58px - 76px);
  overflow: auto;
  width: 300px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-top: none;
  & > .ant-select {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    z-index: 100;
    .ant-select-selector {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
  & > .ant-form > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
  }
`;

export const DragDropContainer = styled.div<{
  $isDroppable?: boolean;
  $isMovable?: boolean;
}>`
  width: 100%;
`;
