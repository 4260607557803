import message from 'antd/es/message';
import { RcFile } from 'antd/es/upload/interface';
import { useMemo } from 'react';
import {
  AssetOwnerInformation,
  AssetRecordInformation,
  UploadAssetResponse,
  UploadFormValues,
} from '../entities/assets-entities';
import { useTranslations } from '../providers/translations-provider';

export type HandleSaveAssetFunction = (
  formData: FormData,
) => Promise<'rejected' | 'fulfilled'>;
export interface UseOnFileFinishUploadProps {
  handleSaveAsset: HandleSaveAssetFunction;
  successMessage: string;
  errorMessage: string;
}

const useOnFileFinishUpload = ({
  handleSaveAsset,
  successMessage,
  errorMessage,
}: UseOnFileFinishUploadProps) => {
  const { t } = useTranslations();
  return useMemo(
    () =>
      async (
        asset: RcFile | undefined,
        values: UploadFormValues,
        records: AssetRecordInformation[],
        owners: AssetOwnerInformation[],
      ) => {
        try {
          if (!values.asset_id && !asset) {
            message.error(t('assets|file_asset_required'));
            return new Error(t('assets|file_asset_required'));
          }

          const formData = new FormData();

          formData.append('file', asset);

          const fileRequest: UploadAssetResponse = {
            file_name: values.file_name,
            file_description: values.file_description,
            version: values.version,
            asset_id: values.asset_id,
            records,
            owners,
          };

          formData.append('request', JSON.stringify(fileRequest));

          const resultRequest = await handleSaveAsset(formData);

          if (resultRequest === 'fulfilled') {
            message.success(successMessage);
          } else {
            message.error(errorMessage);
          }
        } catch (e: any) {
          message.error(errorMessage);
          console.error(e);
        }
      },
    [handleSaveAsset, successMessage, errorMessage],
  );
};
export default useOnFileFinishUpload;
