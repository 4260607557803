import { Match } from '@hellodarwin/core/lib/features/entities';
import { getFormattedDate } from '@hellodarwin/core/lib/features/helpers';
import Tooltip from 'antd/es/tooltip';
import { useMemo } from 'react';
type RaisehandMatchPreviewProps = {
  match: Match;
};

const RaisehandMatchPreview = ({ match }: RaisehandMatchPreviewProps) => {
  const items = useMemo(() => {
    const items = [
      {
        label: 'Raisehand',
        text: `${match.raise_hand_source}  | ${getFormattedDate(
          match.raised_hand_at || '',
        )} | ${
          match.rh_price_waived_at ? 'free' : '$' + match.raise_hand_price || 0
        }`,
        tooltip: '',
      },
    ];

    if (match.shortlisted_at) {
      items.push({
        label: 'Shortlist',
        text: `${match.shortlist_source} | ${getFormattedDate(
          match.shortlisted_at || '',
        )} | $${match.price || 0}`,
        tooltip: '',
      });
    }

    if (match.winner_at) {
      items.push({
        label: 'Win',
        text: `${match.won_source || ''} | ${getFormattedDate(
          match.winner_at || '',
        )}`,
        tooltip: '',
      });
    }

    if (match.win_fee_charged_at) {
      items.push({
        label: 'Win fee',
        text: `${match.win_fee_source} | ${getFormattedDate(
          match.win_fee_charged_at || '',
        )} | $${match.win_fee_price || 0} `,
        tooltip: '',
      });
    }

    if (match.raise_hand_rejected_at) {
      items.push({
        label: 'Refused',
        text: `${
          match.raise_hand_reject_reason_source
            ? match.raise_hand_reject_reason_source + ' | '
            : ''
        }${getFormattedDate(match.raise_hand_rejected_at || '')} | ${
          match.raise_hand_reject_reason
        }`,
        tooltip: match.raise_hand_reject_reason_specified || '',
      });
    }
    if (match.source) {
      items.push({
        label: 'Source',
        text: `${match.source}`,
        tooltip: '',
      });
    }
    items.push({
      label: 'Hello Message',
      text: match.hello_message,
      tooltip: '',
    });
    if (match.shortlist_message) {
      items.push({
        label: 'Shortlist Message',
        text: match.shortlist_message,
        tooltip: '',
      });
    }
    items.push({
      label: 'Match Status',
      text: match.status || '',
      tooltip: '',
    });
    return items;
  }, [match]);
  return (
    <div>
      {items.map((item, i) => (
        <div key={`item-${i}`} className="hd-preview-item match-preview-item">
          <div className="match-preview-item-label">{item.label}</div>
          <Tooltip title={item.tooltip}>
            <div>{item.text}</div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default RaisehandMatchPreview;
