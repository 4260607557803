import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Select from 'antd/es/select';

type TagsSelectMenuProps = {
  heading: string;
  multipleSelect?: boolean;
  selectTag: (tag: string) => void;
  deselectTag: (tag: string) => void;
  selectedTags: string[] | string | undefined;
  tagOptions?:
    | {
        label: string;
        tag: string;
      }[]
    | undefined;
};

const TagsSelectMenu = ({
  heading,
  multipleSelect,
  selectTag,
  deselectTag,
  selectedTags,
  tagOptions,
}: TagsSelectMenuProps) => {
  const { t } = useTranslation();
  if (!tagOptions) {
    return <></>;
  }

  return (
    <>
      <Typography elementTheme="caption">{heading}</Typography>
      <Select
        style={{ marginBottom: '1rem', width: '100%' }}
        mode={multipleSelect ? 'multiple' : undefined}
        placeholder={'Select an industry'}
        showSearch
        onSelect={selectTag}
        onDeselect={deselectTag}
        value={selectedTags}
      >
        {tagOptions &&
          tagOptions.map((tag) => {
            return (
              <Select.Option value={tag.tag} key={tag.tag}>
                {t(`tags|${tag.label}`)}
              </Select.Option>
            );
          })}
      </Select>
    </>
  );
};

export default TagsSelectMenu;
