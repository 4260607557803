import { getShortId } from '../../../../../features/helpers';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { FormPageHeaderProps } from '../../types';
import { pageDescriptionParseOptions } from '../../utils/form-parse-options';
import useEndPageIcon from '../../utils/get-end-page-icon';
import getItemTranslatedLabel from '../../utils/get-item-translated-label';
import FormPageActions from './form-page-actions';

const FormPageHeader = ({
  page,
  isEndPage,
  isOpeningPage,
  formPageId,
  functions,
  locale,
}: FormPageHeaderProps) => {
  const [EndIcon, endIconColor] = useEndPageIcon(page.page_type ?? '');
  return (
    <Div
      gap={16}
      flex={isEndPage || isOpeningPage ? 'column-reverse' : 'row'}
      align={isEndPage ? 'flex-start' : 'center'}
      justify="space-between"
      style={{ padding: 24, flex: 1 }}
    >
      <Div
        flex="column"
        gap={isOpeningPage ? 32 : isEndPage ? 16 : 8}
        align={isEndPage || isOpeningPage ? 'center' : 'flex-start'}
      >
        {isEndPage && !!EndIcon && (
          <EndIcon width={64} height={64} color={endIconColor} />
        )}
        <Typography.Title
          level={3}
          elementTheme={isOpeningPage ? 'h2' : isEndPage ? 'h4' : 'h6'}
          textAlign={isEndPage || isOpeningPage ? 'center' : 'left'}
          noMargin
        >
          {getItemTranslatedLabel({
            label_fr: page.page_title_fr,
            label_en: page.page_title_en,
            fallback: getShortId(page.form_page_id),
            locale,
          })}
        </Typography.Title>
        {((!!page?.page_heading_en && locale === 'en') ||
          (!!page?.page_heading_fr && locale === 'fr')) && (
          <Typography
            elementTheme={isEndPage || isOpeningPage ? 'body1' : 'body2'}
            textAlign={isEndPage || isOpeningPage ? 'center' : 'left'}
          >
            {getItemTranslatedLabel({
              label_fr: page.page_heading_fr,
              label_en: page.page_heading_en,
              fallback: getShortId(page.form_page_id),
              parseOptions: pageDescriptionParseOptions,
              locale,
            })}
          </Typography>
        )}
      </Div>
      <FormPageActions
        formPageId={formPageId}
        page={page}
        functions={functions}
        isEndPage={isEndPage}
        isOpeningPage={isOpeningPage}
      />
    </Div>
  );
};

export default FormPageHeader;
