import { useMemo } from 'react';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import {
  ItemsTypes,
  MappedFormBuilderPage,
} from '../../../features/entities/form-entities';
import Div from '../../common/div';
import FormContent from './controllers/form';
import FormItemsList from './controllers/list';
import {
  handleDeleteFormGroup,
  handleNewGroup_DropInPage_FromControls,
  handleReorderFormGroups,
} from './dnd-functions/group';
import {
  handleNewItem_DropInGroup_FromControls,
  handleReorderFormItems,
} from './dnd-functions/item';
import {
  handleDeleteFormPage,
  handleNewPageDrop_FromControls,
  handleReorderFormPages,
} from './dnd-functions/page';
import { FormContentStyled } from './styles';
import { FormBuilderProps } from './types';

const FormBuilder = ({
  form_id,
  functions,
  formResponse,
  locale,
  openGrant,
}: FormBuilderProps) => {
  const formPages: MappedFormBuilderPage = useMemo(() => {
    const newFormPages: MappedFormBuilderPage = {};

    if (!formResponse.pages?.length) return newFormPages;

    for (const page of formResponse.pages) {
      if (!page) continue;

      newFormPages[page.form_page_id] = {
        id: page.form_page_id,
        entity: page,
        groups: page?.groups?.reduce(
          (o1, group) =>
            !!group
              ? {
                  ...o1,
                  [group.form_group_id]: {
                    id: group.form_group_id,
                    entity: group,
                    items: group?.items?.reduce(
                      (o2, itm) =>
                        !!itm
                          ? {
                              ...o2,
                              [itm.form_item_id]: {
                                type: itm.type,
                                fieldType: itm?.field_type,
                                entity: itm,
                                category: ItemsTypes.items,
                                id: itm.forms_groups_items_id,
                              },
                            }
                          : o2,
                      {},
                    ),
                  },
                }
              : o1,
          {},
        ),
      };
    }

    return newFormPages;
  }, [formResponse]);

  const onDragEnd: OnDragEndResponder = async (data) => {
    const { draggableId, source, destination } = data;
    if (source && destination) {
      if (destination.droppableId.includes('controls_droppable')) {
        //If the drop is in the controls items
        if (draggableId.includes('page_draggable_')) {
          const form_page_id = draggableId.replace('page_draggable_', '');
          handleDeleteFormPage({
            form_page_id,
            form_id,
            formResponse,
            formPages,
            functions,
          });
        } else if (draggableId.includes('group_draggable_')) {
          const form_page_id = source.droppableId.replace(
            'page_droppable_',
            '',
          );
          const form_group_id = draggableId.replace('group_draggable_', '');

          handleDeleteFormGroup({
            form_group_id,
            form_id,
            form_page_id,
            formPages,
            functions,
          });
        } else if (draggableId.includes('form_item_draggable_')) {
          const form_item_id = draggableId.replace('form_item_draggable_', '');

          functions.deleteFormItem(form_item_id);
        }
        return;
      } else if (source.droppableId.includes('controls_droppable')) {
        // Create page
        if (destination.droppableId.includes('group_droppable')) {
          // Drop new items in group
          const form_group_id = destination.droppableId.replace(
            'group_droppable_',
            '',
          );
          const field_type = draggableId.replace('controls_droppable_', '');

          handleNewItem_DropInGroup_FromControls({
            form_group_id,
            field_type,
            form_id,

            item_order: destination.index,
            functions,
            formPages,
          });
        } else if (destination.droppableId.includes('page_droppable')) {
          // Drop new items in page
          if (draggableId.includes('group')) {
            const form_page_id = destination.droppableId.replace(
              'page_droppable_',
              '',
            );

            handleNewGroup_DropInPage_FromControls({
              form_page_id,
              form_id,
              group_order: destination.index,
              functions,
            });
          }
        } else if (destination.droppableId.includes('form_droppable')) {
          // Drop new items in form
          if (draggableId.includes('page')) {
            handleNewPageDrop_FromControls({
              form_id,
              functions,
              page_number: destination.index,
            });
          }
        }
      } else if (source.droppableId.includes('form_droppable')) {
        if (destination.droppableId.includes('form_droppable')) {
          //only inside form
          if (draggableId.includes('page_draggable_')) {
            const formPageId = draggableId.replace('page_draggable_', '');
            const page_order = destination.index;

            handleReorderFormPages({
              formPageId,
              page_order,
              functions,
              formResponse,
            });
            //only if is page
          }
        }
      } else if (source.droppableId.includes('page_droppable')) {
        if (destination.droppableId.includes('page_droppable')) {
          // moving items only between pages
          if (draggableId.includes('group_draggable_')) {
            //only if is group
            const form_group_id = draggableId.replace('group_draggable_', '');
            const group_order = destination.index;
            if (source.droppableId === destination.droppableId) {
              // if inside same page
              const form_page_id = destination.droppableId.replace(
                'page_droppable_',
                '',
              );

              handleReorderFormGroups({
                form_group_id,
                form_page_id,
                functions,
                group_order,
                formPages,
              });
            } else {
              const form_page_source_id = source.droppableId.replace(
                'page_droppable_',
                '',
              );
              const form_page_destination_id = destination.droppableId.replace(
                'page_droppable_',
                '',
              );
              functions.transferFormGroup({
                form_group_id,
                form_page_source_id,
                form_page_destination_id,
                group_order,
              });
            }
          }
        }
      } else if (source.droppableId.includes('group_droppable')) {
        if (destination.droppableId.includes('group_droppable')) {
          // moving items only between groups
          if (draggableId.includes('form_item_draggable')) {
            //only if is item
            const form_item_id = draggableId.replace(
              'form_item_draggable_',
              '',
            );
            if (source.droppableId === destination.droppableId) {
              // if inside same group
              const item_order = destination.index;
              const form_group_id = destination.droppableId.replace(
                'group_droppable_',
                '',
              );

              handleReorderFormItems({
                form_group_id,
                form_item_id,
                functions,
                item_order,
                formPages,
              });
            } else {
            }
          }
        }
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Div flex="row" tablet={{ flex: 'column' }}>
        <FormItemsList locale={locale} />

        <FormContentStyled>
          <FormContent
            formResponse={formResponse}
            pages={!!formPages ? Object.values(formPages) : []}
            functions={functions}
            locale={locale}
            openGrant={openGrant}
          />
        </FormContentStyled>
      </Div>
    </DragDropContext>
  );
};

export default FormBuilder;
