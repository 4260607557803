import {
  GrantProject,
  GrantResult,
} from '../../../../../features/entities/grants-entities';

const getFeaturedTags = (
  grant: GrantResult,
  selectedProjects: string[],
  bestProjects: GrantProject[],
) => {
  // First, get all projects related to the specified grant.
  const mappedProjects = selectedProjects
    .map((id) => bestProjects.find((p) => p.grant_project_id === id))
    .filter((p) => !!p);

  const relatedProjects = mappedProjects.filter(
    (project) => project.grant_id === grant.grant_id,
  );

  // If there are related projects, return their short descriptions.
  if (relatedProjects.length > 0) {
    return relatedProjects.map((project) => project.short_description);
  }

  // If no related projects, gather all tags from all projects.
  const allTags = mappedProjects.flatMap(
    (project) => project.grant_project_tags || [],
  );

  // Filter and deduplicate tags based on specific conditions.
  const filteredTags = Array.from(
    new Set(
      allTags.filter(
        (tag, index, self) =>
          grant.grant_tags?.includes(tag.tag_id) &&
          tag.tag_type === 'expense_category' &&
          index === self.findIndex((t) => t.tag_id === tag.tag_id),
      ),
    ),
  );

  // Return the content of the first 5 filtered tags.
  return filteredTags.map((tag) => tag.content).slice(0, 5);
};

export default getFeaturedTags;
