import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Grant, GrantResult } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import theme from '@hellodarwin/core/lib/theme';
import CancelProject from '@hellodarwin/icons/dist/icons/CancelProject';
import Check from '@hellodarwin/icons/dist/icons/Check';
import Marketing from '@hellodarwin/icons/dist/icons/Marketing';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Verified from '@hellodarwin/icons/dist/icons/Verified';
import Checkbox from 'antd/es/checkbox/Checkbox';
import Form from 'antd/es/form';
import Table, { ColumnsType } from 'antd/es/table';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAdminApi } from '../../features/api/use-admin-api';
import { GrantFilterValues } from './grants-list';
const GrantsValidationList = () => {
  const { t } = useTranslations();
  const { selectedLocale } = useLocale();
  const api = useAdminApi();
  const DEFAULT_PAGE = 1;
  const DEFAULT_LIMIT = 100;
  const [filterform] = Form.useForm<GrantFilterValues>();
  type PageState = {
    grants: Grant[];
    pagination: {
      page: number;
      size: number;
    };
  };

  useEffect(() => {
    fetch(DEFAULT_PAGE, DEFAULT_LIMIT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageStateInitialValues: PageState = {
    grants: [],
    pagination: {
      page: DEFAULT_PAGE,
      size: DEFAULT_LIMIT,
    },
  };
  const [pageState, setPageState] = useState<PageState>(pageStateInitialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = (page: number, size: number) => {
    (async () => {
      try {
        setIsLoading(true);
        const filterValues = filterform.getFieldsValue();
        var response: GrantResult[];
        response = await api.queryGrantsValidations(
          selectedLocale,
          page,
          size,
          filterValues.amountNeeded,
          filterValues.numberEmployee,
          filterValues.financingType,
          filterValues.industry,
          filterValues.service,
          filterValues.grantProvider,
          filterValues.status,
          filterValues.difficulty,
          filterValues.textQuery,
          filterValues.filterBy,
        );
        const newPageState = {
          grants: response?.map((grant, index) => ({ key: index, ...grant })),
          pagination: { page: page, size: size },
        };
        setPageState(newPageState);
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        console.error(e);
      }
    })();
  };

  const onPageChange = (page: number, size: number) => {
    fetch(page, size);
  };
  const GrantFilter = (): ReactNode => (
    <Div flex="row" align="center" gap={24}>
      <Form form={filterform} layout="vertical" style={{ width: '35%' }}>
        <Form.Item
          name="filterBy"
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Checkbox
            onChange={(e) => {
              const limit = e.target.checked
                ? pageState.grants[0]?.total_count || DEFAULT_LIMIT
                : DEFAULT_LIMIT;
              filterform.setFieldsValue({
                filterBy: e.target.checked ? ['promoted'] : undefined,
              });
              applyFilter(limit);
            }}
          >
            {t('programs_admin|onlyPromoted')}
          </Checkbox>
        </Form.Item>
      </Form>
    </Div>
  );

  const columns: ColumnsType<any> = [
    {
      title: t('programs_admin|program'),
      dataIndex: 'grant_id',
      key: 'grant_id',
      render: (grantID, record) => {
        return (
          <Div flex="row" gap={12}>
            {record.verified === 'Verified' ? (
              <Verified
                size={20}
                color={theme.colors.purple_1}
                style={{ flexShrink: 0 }}
              />
            ) : (
              <CancelProject
                size={20}
                color={theme.colors.red_1}
                style={{ flexShrink: 0 }}
              />
            )}
            {record.promoted_at && (
              <Marketing
                size={20}
                color={theme.colors.purple_1}
                style={{ flexShrink: 0 }}
              />
            )}
            <Link
              target="_blank"
              to={`/grants/${grantID}`}
              style={{ flexShrink: 0 }}
            >
              <NewWindow size={20} />
            </Link>
          </Div>
        );
      },
      width: 12,
    },
    {
      title: t('programs_admin|titleProgram'),
      dataIndex: 'grant_title',
      key: 'grant_title',
      ellipsis: true,
      width: 40,
      render: (title) => (
        <Typography elementTheme="body2" ellipsis nowrap overflow>
          {title}
        </Typography>
      ),
    },
    {
      title: t('programs_admin|status'),
      dataIndex: 'application_status',
      key: 'application_status',
      width: 10,
      render: (status) => {
        return (
          <Typography elementTheme="body2">
            {t(`grant_card_status|${status}`)}
          </Typography>
        );
      },
    },
    {
      title: t('grant_validation|basic_info'),
      dataIndex: 'grant_validation_basic_info',
      key: 'grant_validation_basic_info',
      render: (grant_validation_basic_info) => {
        return grant_validation_basic_info ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      sorter: {
        compare: (a, b) =>
          (a.grant_validation_basic_info ? 0 : 1) -
          (b.grant_validation_basic_info ? 0 : 1),
      },
      width: 10,
    },
    {
      title: t('grant_validation|projects'),
      dataIndex: 'grant_validation_projects',
      key: 'grant_validation_projects',
      render: (grant_validation_projects) => {
        return grant_validation_projects ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      sorter: {
        compare: (a, b) =>
          (a.grant_validation_projects ? 0 : 1) -
          (b.grant_validation_projects ? 0 : 1),
      },
      width: 10,
    },
    {
      title: 'GIN',
      dataIndex: 'grant_validation_gin',
      key: 'grant_validation_gin',
      render: (grant_validation_gin) => {
        return grant_validation_gin ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      sorter: {
        compare: (a, b) =>
          (a.grant_validation_gin ? 0 : 1) - (b.grant_validation_gin ? 0 : 1),
      },
      width: 10,
    },
    {
      title: 'Timeline',
      dataIndex: 'grant_validation_timeline',
      key: 'grant_validation_timeline',
      render: (grant_validation_timeline) => {
        return grant_validation_timeline ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      sorter: {
        compare: (a, b) =>
          (a.grant_validation_timeline ? 0 : 1) -
          (b.grant_validation_timeline ? 0 : 1),
      },
      width: 10,
    },
    {
      title: 'Scrape',
      dataIndex: 'grant_validation_scrape',
      key: 'grant_validation_scrape',
      render: (grant_validation_scrape) => {
        return grant_validation_scrape ? (
          <Div flex="row" align="center" justify="center">
            <Check size={20} color={theme.colors.purple_1} />
          </Div>
        ) : null;
      },
      sorter: {
        compare: (a, b) =>
          (a.grant_validation_scrape ? 0 : 1) -
          (b.grant_validation_scrape ? 0 : 1),
      },
      width: 10,
    },
  ];
  const applyFilter = async (customLimit: number) => {
    fetch(DEFAULT_PAGE, customLimit);
  };

  return (
    <Div flex="column" style={{ marginTop: 24 }} gap={24}>
      <Div flex="column" gap={12}>
        <GrantFilter />
      </Div>
      <Table
        bordered
        columns={columns}
        dataSource={pageState.grants}
        pagination={{
          pageSize: pageState.pagination.size,
          current: pageState.pagination.page,
          total: pageState.grants?.[0]?.total_count,
          showSizeChanger: true,
          onChange: (page, size) => onPageChange(page, size ?? DEFAULT_LIMIT),
        }}
        loading={isLoading}
      />
    </Div>
  );
};

export default GrantsValidationList;
