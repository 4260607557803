import Table from 'antd/es/table/Table';
import styled from 'styled-components';
import { createFontSizeCSS } from '../../../../theme/global-style';
import { AssetCardContainerProps } from '../../asset-card/styles';

export const FilesTableContainer = styled(Table)<AssetCardContainerProps>`
  .ant-table {
    background: transparent;
    border: none;
    .ant-table-body {
      border: 1px solid ${({ theme }) => theme.colors.grey_4};
      border-radius: 4px;
    }
    .ant-table-sticky-holder {
      background: transparent;
    }

    .ant-table-thead {
      .ant-table-cell {
        background: transparent;
        padding: 8px 16px;
        border: none;
        ${createFontSizeCSS('overline')}
        text-transform:uppercase;
        color: ${({ theme }) => theme.colors.grey_2};
        font-weight: 600;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-row {
      cursor: pointer;
      background: ${({ theme }) => theme.colors.white_1};
      &.ant-table-row-selected {
        .ant-table-cell {
          background: ${({ theme }) => theme.colors.beige_2};
        }
      }
      .ant-table-cell {
        padding: 8px 16px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey_4};
        &.ant-table-cell-row-hover {
          background: ${({ theme }) => theme.colors.beige_1};
        }
      }
      &:last-child {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
  }
  .ant-table-selection-column {
    display: none;
  }
`;
