import { EditSubmissionItemResponse } from '../../../features/entities/form-entities';

export type FormEditorMode = 'edit' | 'table';

export interface FormData {
  [key: string]: string;
}
export const InitialFormData: FormData = {};

export interface MappedSubmissionItems {
  [form_item_id: string]: EditSubmissionItemResponse;
}
