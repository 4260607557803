import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Dots = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);
  return (
    <svg
      {...iconProps}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        d="M4.33333 16C4.33333 16.5304 4.15774 17.0391 3.84518 17.4142C3.53262 17.7893 3.10869 18 2.66667 18C2.22464 18 1.80072 17.7893 1.48816 17.4142C1.17559 17.0391 1 16.5304 1 16C1 15.4696 1.17559 14.9609 1.48816 14.5858C1.80072 14.2107 2.22464 14 2.66667 14C3.10869 14 3.53262 14.2107 3.84518 14.5858C4.15774 14.9609 4.33333 15.4696 4.33333 16ZM17.6667 16C17.6667 16.5304 17.4911 17.0391 17.1785 17.4142C16.8659 17.7893 16.442 18 16 18C15.558 18 15.1341 17.7893 14.8215 17.4142C14.5089 17.0391 14.3333 16.5304 14.3333 16C14.3333 15.4696 14.5089 14.9609 14.8215 14.5858C15.1341 14.2107 15.558 14 16 14C16.442 14 16.8659 14.2107 17.1785 14.5858C17.4911 14.9609 17.6667 15.4696 17.6667 16ZM31 16C31 16.5304 30.8244 17.0391 30.5118 17.4142C30.1993 17.7893 29.7754 18 29.3333 18C28.8913 18 28.4674 17.7893 28.1548 17.4142C27.8423 17.0391 27.6667 16.5304 27.6667 16C27.6667 15.4696 27.8423 14.9609 28.1548 14.5858C28.4674 14.2107 28.8913 14 29.3333 14C29.7754 14 30.1993 14.2107 30.5118 14.5858C30.8244 14.9609 31 15.4696 31 16Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Dots;
