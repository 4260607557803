import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Flag = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.59998 1.60001V4.00001M1.59998 4.00001L5.96266 2.89121C9.24554 2.05768 12.7136 2.44473 15.7401 3.98241L15.9102 4.06881C18.8755 5.57489 22.2667 5.97743 25.4939 5.20641L30.3921 4.03521C29.7967 9.61848 29.7993 15.2509 30.4 20.8336L25.4955 22.0048C22.268 22.7767 18.8762 22.3747 15.9102 20.8688L15.7401 20.7824C12.7136 19.2447 9.24554 18.8577 5.96266 19.6912L1.59998 20.8M1.59998 4.00001V20.8M1.59998 30.4V20.8"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Flag;
