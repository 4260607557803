import { Contact } from '@hellodarwin/core/lib/features/entities';
import { message } from 'antd/es';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal/Modal';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app';
import { updateGinApplicationContacts } from '../../../../features/api/slices/gins-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../../features/api/use-admin-api';
import useDebounce from '../../../../hooks/use-debounce';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';

interface ProgramContactsModalProps {
  isVisible: boolean;
  handleClose: () => void;
  companyId: string;
  applicationId: string;
  programContacts: Contact[];
}

const SEARCH_DELAY_MS = 300;

const ProgramContactsModal = ({
  isVisible,
  handleClose,
  companyId,
  applicationId,
  programContacts,
}: ProgramContactsModalProps) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [selectedContactIds, setSelectedContactIds] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);
  const [form] = Form.useForm();
  const newApi = useNewAdminApi();
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslations();

  useEffect(() => {
    if (companyId && programContacts) {
      const initialSelectedContactIds = programContacts.map(
        (contact) => contact.contact_id,
      );
      setSelectedContactIds(initialSelectedContactIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programContacts, companyId]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await api.queryCompanyContacts(companyId, 1, 100, '');
        setContacts(response);
      } catch (e) {
        message.error(t('application_single|program_contacts_fetch_failed'));
        console.error(e);
      }
    };
    if (companyId) fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, api]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      onSearch(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const onSearch = async (query: string) => {
    if (companyId) {
      setIsSearching(true);
      try {
        const response = await api.queryCompanyContacts(
          companyId,
          1,
          100,
          query,
        );
        setContacts(response);
      } catch (e) {
        message.error(t('application_single|program_contacts_fetch_failed'));
        console.error(e);
      } finally {
        setIsSearching(false);
      }
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSubmit = async () => {
    if (selectedContactIds.length === 0) {
      message.warning(t('application_single|minimum_contacts'));
      return;
    }
    try {
      const response = await dispatch(
        updateGinApplicationContacts({
          api: newApi,
          applicationId,
          contactIds: selectedContactIds,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        message.success(
          t('application_single|program_contacts_update_success'),
        );
        handleClose();
      } else {
        message.error(t('application_single|program_contacts_update_failed'));
      }
    } catch (e) {
      message.error(t('application_single|program_contacts_update_failed'));
      console.error(e);
    }
  };

  return (
    <Modal
      title={t('application_single|edit_program_contacts')}
      open={isVisible}
      onCancel={handleClose}
      onOk={handleSubmit}
      confirmLoading={isSearching}
    >
      <Form form={form}>
        <Form.Item label="Contacts" required>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            loading={isSearching}
            filterOption={false}
            showSearch
            placeholder={t('application_single|search_company_contacts')}
            defaultActiveFirstOption={false}
            onSearch={handleSearch}
            value={selectedContactIds}
            onChange={(values) => {
              if (values.length === 0) {
                message.warning(t('application_single|minimum_contacts'));
              } else {
                setSelectedContactIds(values);
              }
            }}
          >
            {contacts?.map((contact) => (
              <Select.Option
                key={contact.contact_id}
                value={contact.contact_id}
              >
                {contact.name} / {contact.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProgramContactsModal;
