import Div from '@hellodarwin/core/lib/components/common/div';
import SeeMoreTags from '@hellodarwin/core/lib/components/common/see-more/see-more-tags';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  GrantProjectTag,
  GrantTag,
  NewTag,
  ProjectTag,
  TagType,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useAppDispatch, useAppSelector } from 'admin/src/app';
import {
  addGrantProjectTags,
  addGrantTags,
  clearAllOptions,
  clearSelectedTags,
  deleteGrantProjectTagWithChildren,
  deleteGrantTagWithChildren,
  fetchChildTags,
  selectExpenseCategoryTagsOptions,
  selectExpenseTagsOptions,
  selectGoalTagsOptions,
  selectSelectedTags,
  setSelectedTags,
} from 'admin/src/features/api/slices/new-tags-slice';
import { useNewAdminApi } from 'admin/src/features/api/use-admin-api';
import Button from 'antd/es/button';
import Drawer from 'antd/es/drawer';
import message from 'antd/es/message';
import React, { useEffect, useState } from 'react';
import TagsSearch from './tag-search';
import TagSelectionDropdowns from './tags-dropdowns';

interface TagsDrawerProps {
  visible: boolean;
  onClose: () => void;
  currentTags: NewTag[] | GrantProjectTag[] | GrantTag[] | ProjectTag[];
  entityType: 'Project' | 'Grant' | 'GrantProject';
  entityId: string;
  locale: string;
  onChange?: (tags: NewTag[]) => void;
}

const TagsDrawer = ({
  visible,
  onClose,
  currentTags,
  entityType,
  entityId,
  locale,
  onChange,
}: TagsDrawerProps) => {
  const { t } = useTranslations();
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const goalTagsOptions = useAppSelector(selectGoalTagsOptions);
  const expenseCategoryTagsOptions = useAppSelector(
    selectExpenseCategoryTagsOptions,
  );
  const expenseTagsOptions = useAppSelector(selectExpenseTagsOptions);
  const selectedTags = useAppSelector(selectSelectedTags);

  const [selectedGoals, setSelectedGoals] = useState<NewTag[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<NewTag[]>([]);
  const [selectedExpenses, setSelectedExpenses] = useState<NewTag[]>([]);

  // Fetch goal tags initially
  useEffect(() => {
    dispatch(
      fetchChildTags({ api, parentIds: [], locale, type: TagType.Goal }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch child tags dynamically based on selected parent tags
  useEffect(() => {
    const parentIds = selectedGoals?.map((goal) => goal.tag_id);
    dispatch(
      fetchChildTags({ api, parentIds, locale, type: TagType.ExpenseCategory }),
    );
    setSelectedCategories(
      selectedCategories.filter((expenseCategory) =>
        parentIds.includes(expenseCategory.parent_id),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGoals, locale]);

  useEffect(() => {
    const parentIds = selectedCategories?.map(
      (expenseCategory) => expenseCategory.tag_id,
    );
    dispatch(fetchChildTags({ api, parentIds, locale, type: TagType.Expense }));
    setSelectedExpenses(
      selectedExpenses.filter((expense) =>
        parentIds.includes(expense.parent_id),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, locale]);

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    type: TagType,
  ) => {
    const options = getOptions(type);
    const newTags = values.map((value) => options[value]);

    setSelected(newTags);

    const updatedSelectedTags = [
      ...selectedTags.filter(
        (tag) => !newTags.some((newTag) => newTag.tag_id === tag.tag_id),
      ),
      ...newTags,
    ];

    dispatch(setSelectedTags(updatedSelectedTags));
  };

  const getOptions = (type: TagType) => {
    switch (type) {
      case TagType.Goal:
        return goalTagsOptions.entities;
      case TagType.ExpenseCategory:
        return expenseCategoryTagsOptions.entities;
      case TagType.Expense:
        return expenseTagsOptions.entities;
      default:
        return {};
    }
  };

  const findAllChildrenTags = (tagId: string, allTags: NewTag[]): string[] => {
    const directChildren = allTags.filter((tag) => tag.parent_id === tagId);

    const allChildren = directChildren.reduce<string[]>(
      (acc, child) => [
        ...acc,
        child.tag_id,
        ...findAllChildrenTags(child.tag_id, allTags),
      ],
      [],
    );

    return allChildren;
  };

  const handleDeleteTag = async (tagId: string) => {
    try {
      let response;
      switch (entityType) {
        case 'Project':
          if (onChange) {
            const childTagIds = findAllChildrenTags(tagId, currentTags);
            const tagsToDelete = [tagId, ...childTagIds];
            const updatedTags = currentTags.filter(
              (tag) => !tagsToDelete.includes(tag.tag_id),
            );
            onChange(updatedTags);
            message.success("Tag deleted successfully. Don't forget to save.");
          }
          break;
        case 'Grant':
          response = await dispatch(
            deleteGrantTagWithChildren({ api, grantId: entityId, tagId }),
          ).unwrap();
          break;
        case 'GrantProject':
          response = await dispatch(
            deleteGrantProjectTagWithChildren({
              api,
              grantProjectId: entityId,
              tagId,
            }),
          ).unwrap();
          break;

        default:
          throw new Error(`Unsupported entity type: ${entityType}`);
      }
      if (response) {
        message.success("Tag deleted successfully. Don't forget to save.");
      }
    } catch (err) {
      message.error('Error deleting tag');
    }
  };

  const handleSubmit = async (tag: NewTag) => {
    const searchResult = [];

    while (tag) {
      searchResult.push(tag);
      tag = tag.parent as NewTag;
    }

    const tagsToAdd = searchResult.filter(
      (tag) =>
        !currentTags.some((currentTag) => currentTag.tag_id === tag.tag_id),
    );
    try {
      if (tagsToAdd.length > 0) {
        let response;
        switch (entityType) {
          case 'Project':
            if (onChange) {
              const updatedTags = getNewProjectTags(currentTags, tagsToAdd);
              onChange(updatedTags);
            }
            response = true;
            break;
          case 'Grant':
            response = await dispatch(
              addGrantTags({
                api,
                grantId: entityId,
                tags: tagsToAdd.map((tag) => tag.tag_id),
              }),
            ).unwrap();
            break;
          case 'GrantProject':
            response = await dispatch(
              addGrantProjectTags({
                api,
                grantProjectId: entityId,
                newTags: tagsToAdd.map((tag) => tag.tag_id),
              }),
            ).unwrap();
            break;
          default:
            throw new Error(`Unsupported entity type: ${entityType}`);
        }
        if (response) {
          dispatch(clearSelectedTags());
          dispatch(clearAllOptions());
          setSelectedGoals([]);
          setSelectedCategories([]);
          setSelectedExpenses([]);
          message.success("Tags added successfully. Don't forget to save.");
        }
      }
    } catch (err) {
      message.error('Error adding tags');
    }
  };

  const getNewProjectTags = (
    currentTags: NewTag[],
    tagsToAdd: NewTag[],
  ): NewTag[] => {
    const goals = currentTags.filter((tag) => tag.tag_type === 'goal');
    const expenseCategories = currentTags.filter(
      (tag) => tag.tag_type === 'expense_category',
    );
    const expenses = currentTags.filter((tag) => tag.tag_type === 'expense');

    const newGoals = tagsToAdd.filter((tag) => tag.tag_type === 'goal');
    const newExpenseCategories = tagsToAdd.filter(
      (tag) => tag.tag_type === 'expense_category',
    );
    const newExpenses = tagsToAdd.filter((tag) => tag.tag_type === 'expense');

    const updatedTags = [
      ...goals,
      ...newGoals,
      ...expenseCategories,
      ...newExpenseCategories,
      ...expenses,
      ...newExpenses,
    ];

    return updatedTags.reduce<NewTag[]>((acc, tag) => {
      if (!acc.some((t) => t.tag_id === tag.tag_id)) {
        acc.push(tag);
      }
      return acc;
    }, []);
  };

  const handleConfirm = async () => {
    const tagsToAdd = selectedTags.filter(
      (tag) =>
        !currentTags.some((currentTag) => currentTag.tag_id === tag.tag_id),
    );

    try {
      let response;
      if (tagsToAdd.length > 0) {
        switch (entityType) {
          case 'Project':
            if (onChange) {
              const updatedTags = getNewProjectTags(currentTags, tagsToAdd);
              onChange(updatedTags);
            }
            response = true;
            break;
          case 'Grant':
            response = await dispatch(
              addGrantTags({
                api,
                grantId: entityId,
                tags: tagsToAdd.map((tag) => tag.tag_id),
              }),
            ).unwrap();
            break;
          case 'GrantProject':
            response = await dispatch(
              addGrantProjectTags({
                api,
                grantProjectId: entityId,
                newTags: tagsToAdd.map((tag) => tag.tag_id),
              }),
            ).unwrap();
            break;
          default:
            throw new Error(`Unsupported entity type: ${entityType}`);
        }
        if (response) {
          dispatch(clearSelectedTags());
          dispatch(clearAllOptions());
          setSelectedGoals([]);
          setSelectedCategories([]);
          setSelectedExpenses([]);
          message.success("Tags added successfully. Don't forget to save.");
        }
      }
    } catch (err) {
      message.error('Error adding tags');
    }
  };

  return (
    <Drawer
      title={t('project|edit_tags')}
      placement="right"
      onClose={onClose}
      open={visible}
      size="large"
      width={1000}
    >
      <Div flex="column" justify="space-between" fullHeight>
        <Div flex="column" gap={8}>
          <Div>
            <Typography elementTheme="body1">
              {t('project|search_tags')}
            </Typography>
            <TagsSearch locale={locale} handleSubmit={handleSubmit} />
          </Div>
          <Typography elementTheme="body1">
            {t('project|select_tags')}
          </Typography>
          <TagSelectionDropdowns
            selectedGoals={selectedGoals}
            selectedCategories={selectedCategories}
            selectedExpenses={selectedExpenses}
            onGoalsChange={(values) =>
              handleTagChange(values, setSelectedGoals, TagType.Goal)
            }
            onCategoriesChange={(values) =>
              handleTagChange(
                values,
                setSelectedCategories,
                TagType.ExpenseCategory,
              )
            }
            onExpensesChange={(values) =>
              handleTagChange(values, setSelectedExpenses, TagType.Expense)
            }
            goalTags={goalTagsOptions.entities}
            expenseCategoryTags={expenseCategoryTagsOptions.entities}
            expenseTags={expenseTagsOptions.entities}
            parentGoals={selectedGoals}
            parentCategories={selectedCategories}
          />

          <Div flex="row" gap={8} justify="center">
            <Button type="primary" onClick={handleConfirm}>
              {t('project|add_tags')}
            </Button>
            <Button
              onClick={() => {
                dispatch(clearSelectedTags());
                dispatch(clearAllOptions());
                setSelectedGoals([]);
                setSelectedCategories([]);
                setSelectedExpenses([]);
              }}
            >
              {t('project|clear_tags')}
            </Button>
          </Div>
        </Div>
        <Div flex="column" gap={8}>
          <Typography elementTheme="body2">{t('project|tags')}</Typography>
          <Div flex="row" gap={8} align="center" wrap="wrap">
            <SeeMoreTags
              limit={10}
              size="medium"
              position={'bottom'}
              content={currentTags.map((tag) => ({
                ...tag,
                onClose: () => handleDeleteTag(tag.tag_id),
              }))}
            />
          </Div>
        </Div>
      </Div>
    </Drawer>
  );
};

export default TagsDrawer;
