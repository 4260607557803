import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const ImagePlus = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5 18C23.4623 18 21 20.4623 21 23.5C21 26.5377 23.4623 29 26.5 29C29.5377 29 32 26.5377 32 23.5C32 20.4623 29.5377 18 26.5 18ZM26.9231 21.8077C26.9231 21.6955 26.8785 21.5879 26.7992 21.5085C26.7198 21.4292 26.6122 21.3846 26.5 21.3846C26.3878 21.3846 26.2802 21.4292 26.2008 21.5085C26.1215 21.5879 26.0769 21.6955 26.0769 21.8077V23.0769H24.8077C24.6955 23.0769 24.5879 23.1215 24.5085 23.2008C24.4292 23.2802 24.3846 23.3878 24.3846 23.5C24.3846 23.6122 24.4292 23.7198 24.5085 23.7992C24.5879 23.8785 24.6955 23.9231 24.8077 23.9231H26.0769V25.1923C26.0769 25.3045 26.1215 25.4121 26.2008 25.4915C26.2802 25.5708 26.3878 25.6154 26.5 25.6154C26.6122 25.6154 26.7198 25.5708 26.7992 25.4915C26.8785 25.4121 26.9231 25.3045 26.9231 25.1923V23.9231H28.1923C28.3045 23.9231 28.4121 23.8785 28.4915 23.7992C28.5708 23.7198 28.6154 23.6122 28.6154 23.5C28.6154 23.3878 28.5708 23.2802 28.4915 23.2008C28.4121 23.1215 28.3045 23.0769 28.1923 23.0769H26.9231V21.8077Z"
        fill="currentColor"
      />
      <path
        d="M3 21L9.87867 14.1213C10.1572 13.8428 10.488 13.6218 10.8519 13.471C11.2159 13.3202 11.606 13.2426 12 13.2426C12.394 13.2426 12.7841 13.3202 13.1481 13.471C13.512 13.6218 13.8428 13.8428 14.1213 14.1213L21 21M19 19L20.8787 17.1213C21.1572 16.8428 21.488 16.6218 21.8519 16.471C22.2159 16.3202 22.606 16.2426 23 16.2426C23.394 16.2426 23.7841 16.3202 24.1481 16.471C24.512 16.6218 24.8428 16.8428 25.1213 17.1213L29 21M5 26H24.1481C24.6785 26 22.1063 22.8751 22.4814 22.5C22.8565 22.1249 25.1213 19 29 21.4024V8C29 7.46957 28.7893 6.96086 28.4142 6.58579C28.0391 6.21071 27.5304 6 27 6H5C4.46957 6 3.96086 6.21071 3.58579 6.58579C3.21071 6.96086 3 7.46957 3 8V24C3 24.5304 3.21071 25.0391 3.58579 25.4142C3.96086 25.7893 4.46957 26 5 26ZM19 11H19.0107V11.0107H19V11ZM19.5 11C19.5 11.1326 19.4473 11.2598 19.3536 11.3536C19.2598 11.4473 19.1326 11.5 19 11.5C18.8674 11.5 18.7402 11.4473 18.6464 11.3536C18.5527 11.2598 18.5 11.1326 18.5 11C18.5 10.8674 18.5527 10.7402 18.6464 10.6464C18.7402 10.5527 18.8674 10.5 19 10.5C19.1326 10.5 19.2598 10.5527 19.3536 10.6464C19.4473 10.7402 19.5 10.8674 19.5 11Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImagePlus;
