import 'react-quill/dist/quill.snow.css';

export const quillModules = {
  toolbar: [
    [{ header: [3, 4, false] }],
    ['bold', 'italic', { list: 'bullet' }, { list: 'ordered' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
