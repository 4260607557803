import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Launch = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M21.3022 19.5003C21.6097 20.8016 21.6188 22.1556 21.3287 23.4608C21.0386 24.7661 20.4569 25.9888 19.6272 27.0374C18.7974 28.0859 17.7412 28.9331 16.5375 29.5154C15.3339 30.0978 14.014 30.4002 12.6769 30.4V23.3107M21.3022 19.5003C24.1229 17.4456 26.4176 14.7527 27.9988 11.6417C29.5799 8.53068 30.4028 5.08975 30.4 1.59998C26.9105 1.59746 23.4698 2.42039 20.3591 4.00156C17.2484 5.58272 14.5558 7.87728 12.5012 10.6978M21.3022 19.5003C18.7351 21.3779 15.7937 22.6778 12.6769 23.3107M12.6769 23.3107C12.5248 23.3418 12.3712 23.3713 12.2176 23.3994C10.8777 22.3369 9.66457 21.1237 8.6021 19.7839C8.63 19.6299 8.65806 19.4763 8.68924 19.3231M12.5012 10.6978C11.1998 10.39 9.84563 10.3808 8.54017 10.6707C7.2347 10.9607 6.01178 11.5423 4.96307 12.3721C3.91435 13.2018 3.06703 14.2582 2.48461 15.462C1.90218 16.6658 1.59976 17.9858 1.60001 19.3231H8.68924M12.5012 10.6978C10.6238 13.2644 9.32247 16.2068 8.68924 19.3231M5.38241 22.8529C4.41086 23.5754 3.65548 24.5499 3.19807 25.6708C2.74067 26.7918 2.59867 28.0166 2.78745 29.2125C3.9835 29.4012 5.20837 29.2589 6.32938 28.8013C7.45038 28.3436 8.4248 27.5879 9.14708 26.6161M22.6462 11.5692C22.6462 12.1568 22.4127 12.7203 21.9973 13.1357C21.5818 13.5512 21.0183 13.7846 20.4308 13.7846C19.8432 13.7846 19.2797 13.5512 18.8643 13.1357C18.4488 12.7203 18.2154 12.1568 18.2154 11.5692C18.2154 10.9817 18.4488 10.4182 18.8643 10.0027C19.2797 9.58723 19.8432 9.35383 20.4308 9.35383C21.0183 9.35383 21.5818 9.58723 21.9973 10.0027C22.4127 10.4182 22.6462 10.9817 22.6462 11.5692Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Launch;
