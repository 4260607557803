import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useAppSelector } from '../../app';
import { selectAuthentifiedUser } from '../../features/api/slices/global-slice';
import AdminSettingsForm from './admin-settings-form';
import RfpDescriptionTemplateContainer from './rfp-description-template';

const SettingsPage = () => {
  const admin = useAppSelector(selectAuthentifiedUser);
  return (
    <PageLayout
      app="admin"
      tabTitle={'Settings'}
      title={'Settings'}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Settings',
        },
      ]}
    >
      <AdminSettingsForm admin={admin} />
      <RfpDescriptionTemplateContainer />
    </PageLayout>
  );
};

export default SettingsPage;
