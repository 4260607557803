import { ReactNode } from 'react';
import { usePlatform } from '../../../../features/providers/platform-provider';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../div';
import HdTag, { TagProps } from '../../hd-tag';
import { Tablet } from '../../Media';
import Typography from '../../typography';

interface PageLayoutTitleProps {
  title?: ReactNode;
  status?: TagProps;
}

const PageLayoutTitle: React.FC<PageLayoutTitleProps> = ({ title, status }) => {
  const theme = useTheme();
  const { app } = usePlatform();
  return (
    <Div
      flex="row"
      align="center"
      gap={24}
      tablet={{
        flex: 'column-reverse',
        align: 'flex-start',
        gap: 14,
      }}
      fitContent
    >
      <Typography.Title
        level={1}
        elementTheme={app === 'admin' ? 'h6' : 'h5'}
        noMargin
        color={theme.colors.primary}
        fitContent
        nowrap
      >
        {title}
      </Typography.Title>
      {status && (
        <>
          <Tablet direction="up">
            <div
              style={{
                height: 'auto',
                alignSelf: 'stretch',
                borderLeft: `1px solid ${theme.colors.primary}`,
              }}
            />
          </Tablet>
          <HdTag {...status} />
        </>
      )}
    </Div>
  );
};

export default PageLayoutTitle;
