import { CSSProperties } from 'react';
import { DivProps } from '.';
import { toHex } from '../../../features/helpers/convertToHex';
import styled from '../../../plugins/styled';

const borderSides = {
  all: '1px 1px 1px 1px',
  vertical: '0 1px',
  horizontal: '1px 0',
  left: '0 0 0 1px',
  right: '0 1px 0 0',
  top: '1px 0 0 0',
  bottom: ' 0 0 1px 0',
};

export type DivContainerProps = {
  $backgroundColor?: string;
  $backgroundOpacity?: number;
  $backgroundOpacityHover?: number;
  $borderColor?: string;
  $borderColorHover?: string;
  $hidden?: boolean;
  $fitContent?: boolean;
  $minContent?: boolean;
  $maxContent?: boolean;
  $fullHeight?: boolean;
  $borderSide?: DivProps['bordersSide'];
  $flexDirection?: CSSProperties['flexDirection'];
};

export const DivContainer = styled.div<DivContainerProps>`
  width: 100%;
  border-width: 1px;
  box-sizing: border-box;

  ${(props) =>
    !!props.$backgroundColor
      ? !!props.$backgroundOpacity
        ? `background-color: ${props.$backgroundColor}${toHex(
            props.$backgroundOpacity,
          )};`
        : `background: ${props.$backgroundColor};`
      : undefined}
  &:hover {
    ${(props) =>
      !!props.$backgroundColor
        ? !!props.$backgroundOpacityHover
          ? `background-color: ${props.$backgroundColor}${toHex(
              props.$backgroundOpacityHover,
            )};`
          : `background: ${props.$backgroundColor};`
        : undefined}
  }
  ${(props) =>
    !!props.$borderColor
      ? `
      border-style: solid;
      border-color: ${props.$borderColor};`
      : undefined}
  ${(props) =>
    !!props.$borderColorHover
      ? `
    &:hover {        
        border-style: solid;
        border-color: ${props.$borderColorHover};
    }
      `
      : undefined}
      
      ${(props) =>
    !!props.$fitContent
      ? `
    height: fit-content;
    width: fit-content;
    line-height:1;
    `
      : undefined}
      ${(props) =>
    !!props.$minContent
      ? `
    height: min-content;
    width: min-content;
    `
      : undefined}
      ${(props) =>
    !!props.$maxContent
      ? `
    height: max-content;
    width: max-content;
    `
      : undefined}
      ${(props) =>
    !!props.$fullHeight
      ? `
    height: 100%;
    `
      : undefined}
      ${(props) =>
    !!props.$borderSide
      ? `
    border-width: ${borderSides[props.$borderSide]};
    `
      : undefined}
     
      ${(props) =>
    !!props.$hidden
      ? `display: none;`
      : !!props.$flexDirection
        ? `
    display:flex;
  flex-direction: ${props.$flexDirection};
  `
        : undefined}
`;
