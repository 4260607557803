import Logout from '@hellodarwin/icons/dist/icons/Logout';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AppType, HdUser } from '../../../../features/entities/core-entities';
import { NavigationLink } from '../../../../features/entities/layout-entities';
import { useLogin } from '../../../../features/providers/login-provider';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import DesktopUserMenu from './desktop';
import MobileUserMenu from './mobile';

export interface UserMenuProps {
  user: HdUser;
  links: NavigationLink[];
  app: AppType;
  notifs?: number;
}

const UserMenu = (props: UserMenuProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const { handleLogout } = useLogin();
  const { t } = useTranslations();

  const { links, user } = props;

  const items = useMemo(
    () => [
      ...links,
      {
        pathname: '?logout=true',
        onClick: handleLogout,
        label: t('navigation|logout'),
        Icon: Logout,
        withBorderTop: true,
      },
    ],
    [links],
  );

  if (!user) return <></>;
  const Menu = isTablet ? MobileUserMenu : DesktopUserMenu;
  return <Menu {...props} links={items} />;
};

export default UserMenu;
