import Div from '@hellodarwin/core/lib/components/common/div';
import Modal from '@hellodarwin/core/lib/components/common/hd-modal';
import Uploader from '@hellodarwin/core/lib/components/forms/form-groups/uploader';
import {
  AdminGrantResult,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import NewWindow from '@hellodarwin/icons/dist/icons/NewWindow';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Collapse, { CollapseProps } from 'antd/es/collapse';
import Form from 'antd/es/form';
import message from 'antd/es/message';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import { RcFile } from 'antd/es/upload';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  deleteGrant,
  updateGrantLogo,
  updateGrantPromoted,
} from '../../../features/api/slices/grants-slice';
import {
  fetchAllPromptsForGrants,
  selectAllPrompts,
} from '../../../features/api/slices/hd-chat-prompts-slice';
import { useAdminApi } from '../../../features/api/use-admin-api';
import { AdminPagesForms } from '../../../pages/single-rfp-page';
import theme from '../../../theme';
import CreateUpdatePromptDrawer from '../../hd-chat/create-update-prompt-drawer';
import GrantPromptPropertySection from '../grant-prompt-property-section';
import DeleteGrantModal from './grant-delete-modal';
import GrantFormHeader from './grant-form-header';
import GrantFormProjects from './grant-form-projects';
import GrantFormProperties from './grant-form-properties';
import GrantFormSections from './grant-form-sections';
import GrantFormTimeline from './grant-form-timeline';

type GrantFormProps = {
  grant?: AdminGrantResult;
  form: any;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
  onSave?: () => Promise<void>;
  isGeneratingAll?: boolean;
  setIsGeneratingAll?: Dispatch<SetStateAction<boolean>>;
  isGeneratingRemaining?: boolean;
  setIsGeneratingRemaining?: Dispatch<SetStateAction<boolean>>;
  activeKey?: string[];
  setActiveKey?: Dispatch<SetStateAction<string[]>>;
  isSavingAll?: boolean;
  setIsSavingAll?: Dispatch<SetStateAction<boolean>>;
};

export type GrantFormValues = {
  grant_title_en: string;
  grant_title_fr: string;
  grant_display_title_en: string;
  grant_display_title_fr: string;
  description_short_en: string;
  description_short_fr: string;
  description_long_en: string;
  description_long_fr: string;
  expanded_description_en: string;
  expanded_description_fr: string;
  url_en: string;
  url_fr: string;
  grant_urls_en: string[];
  grant_urls_fr: string[];
  grant_slug_en: string;
  grant_slug_fr: string;
  youtube_url_en: string;
  youtube_url_fr: string;
  grant_financing_type?: string[];
  grant_providers?: string[];
  funding_max_amount?: number;
  funding_min_amount?: number;
  total_funding_available?: number;
  needed_investment?: number;
  applicants_min_revenue_past_year?: number;
  applicants_max_revenue_past_year?: number;
  applicants_min_count_employee?: number;
  applicants_max_count_employee?: number;
  grant_created_at?: any;
  grant_updated_at?: any;
  percentage_funding?: number;
  application_phone_number?: string;
  application_email_address?: string;
  official_documents?: string;
  city?: string;
  region?: string;
  province?: string;
  country?: string;
  grant_logo?: string;
  service?: string[];
  audience?: string[];
  verified?: string;
  eligibility_criteria_short_en?: string;
  eligibility_criteria_short_fr?: string;
  eligibility_criteria_en?: string;
  eligibility_criteria_fr?: string;
  eligible_expenses_en?: string;
  eligible_expenses_fr?: string;
  who_can_apply_en?: string;
  who_can_apply_fr?: string;
  who_cannot_apply_en?: string;
  who_cannot_apply_fr?: string;
  project_activity_en?: string;
  project_activity_fr?: string;
  zone_en?: string;
  zone_fr?: string;
  selection_criteria_en?: string;
  selection_criteria_fr?: string;
  steps_how_to_apply_en?: string;
  steps_how_to_apply_fr?: string;
  funding_terms_and_conditions_en?: string;
  funding_terms_and_conditions_fr?: string;
  additional_information_en?: string;
  additional_information_fr?: string;
  notes?: string;
  grant_timeline?: any[];
  for_profit?: string[];
  grant_projects_en?: any[];
  grant_projects_fr?: any[];
  quebec_administrative_region?: number;
  quebec_mrc?: number;
};

export const transformSectionIntoLabel = (section: string) => {
  const words = section
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  return words.join(' ');
};

const GrantForm = ({
  grant,
  form,
  setForms,
  onSave,
  isGeneratingAll,
  setIsGeneratingAll,
  isGeneratingRemaining,
  activeKey,
  setActiveKey,
  isSavingAll,
  setIsSavingAll,
}: GrantFormProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const prompts = useAppSelector(selectAllPrompts);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [grantLogo, setGrantLogo] = useState<string>(grant?.grant_logo || '');
  const [addNewPromptDrawer, setAddNewPromptDrawer] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(
    {} as HdChatPromptRequest,
  );

  const handleUploadChange = async (newFileList: RcFile[]) => {
    setOpen(false);
    setFileList(newFileList);
    const data = new FormData();
    data.append('logo', newFileList[0] as Blob);
    const grantId = grant?.grant_id || '';

    const returnedGrant = await dispatch(
      updateGrantLogo({ api, grantId, data }),
    ).unwrap();

    if (returnedGrant) {
      setGrantLogo(returnedGrant.grant_logo || '');
    }
  };

  const handleDeleteGrant = async (grant_id: string) => {
    setDeleteModal(false);
    try {
      const response = await dispatch(
        deleteGrant({ api, grantID: grant?.grant_id || '' }),
      ).unwrap();
      message.success('Grant successfully deleted');
      navigate('/grants');
      return response;
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error_code);
    }
  };

  useEffect(() => {
    dispatch(fetchAllPromptsForGrants({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      grantForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(grant ? grant : {});
  }, [form, grant]);

  const savePromotedOnly = async (promoted: boolean) => {
    try {
      await dispatch(
        updateGrantPromoted({
          api,
          grant_id: grant!.grant_id,
          promoted: promoted,
        }),
      );

      message.success('Saved!');
    } catch (e) {
      console.error(e);
    }
  };

  const initialValues: GrantFormValues = {
    grant_title_en: grant?.grant_title_en || '',
    grant_title_fr: grant?.grant_title_fr || '',
    grant_display_title_en: grant?.grant_title_en || '',
    grant_display_title_fr: grant?.grant_title_fr || '',
    description_short_en: grant?.grant_description_short_en || '',
    description_short_fr: grant?.grant_description_short_fr || '',
    description_long_en: grant?.grant_description_long_en || '',
    description_long_fr: grant?.grant_description_long_fr || '',
    expanded_description_en: grant?.expanded_description_en || '',
    expanded_description_fr: grant?.expanded_description_fr || '',
    url_en: grant?.grant_url_en || '',
    url_fr: grant?.grant_url_fr || '',
    grant_urls_en: grant?.grant_urls_en || [''],
    grant_urls_fr: grant?.grant_urls_fr || [''],
    grant_slug_en: grant?.grant_slug_en || '',
    grant_slug_fr: grant?.grant_slug_fr || '',
    youtube_url_en: grant?.grant_youtube_url_en || '',
    youtube_url_fr: grant?.grant_youtube_url_fr || '',
    grant_financing_type: grant?.financing_type || [],
    grant_providers: grant?.providers || [],
    funding_max_amount: grant?.funding_max_amount || undefined,
    funding_min_amount: grant?.funding_min_amount || undefined,
    total_funding_available: grant?.total_funding_available || undefined,
    needed_investment: grant?.needed_investment || undefined,
    applicants_min_revenue_past_year:
      grant?.applicants_min_revenue_past_year || undefined,
    applicants_max_revenue_past_year:
      grant?.applicants_max_revenue_past_year || undefined,
    applicants_min_count_employee:
      grant?.applicants_min_count_employee || undefined,
    applicants_max_count_employee:
      grant?.applicants_max_count_employee || undefined,
    grant_created_at: grant?.grant_created_at || undefined,
    grant_updated_at: grant?.grant_updated_at || undefined,
    percentage_funding: grant?.percentage_funding || undefined,
    application_phone_number: grant?.application_phone_number || '',
    application_email_address: grant?.application_email_address || '',
    official_documents: grant?.official_documents || '',
    city: grant?.city || '',
    region: grant?.region || '',
    province: grant?.province || '',
    country: grant?.country || '',
    grant_logo: grant?.grant_logo || '',
    service: grant?.service || [],
    audience: grant?.audience || [],
    verified: grant?.verified || 'Verified',
    eligibility_criteria_short_en:
      grant?.eligibility_criteria_short_en || undefined,
    eligibility_criteria_short_fr:
      grant?.eligibility_criteria_short_fr || undefined,
    eligibility_criteria_en: grant?.eligibility_criteria_en || undefined,
    eligibility_criteria_fr: grant?.eligibility_criteria_fr || undefined,
    eligible_expenses_en: grant?.eligible_expenses_en || undefined,
    eligible_expenses_fr: grant?.eligible_expenses_fr || undefined,
    who_can_apply_en: grant?.who_can_apply_en || undefined,
    who_can_apply_fr: grant?.who_can_apply_fr || undefined,
    who_cannot_apply_en: grant?.who_cannot_apply_en || undefined,
    who_cannot_apply_fr: grant?.who_cannot_apply_fr || undefined,
    project_activity_en: grant?.project_activity_en || undefined,
    project_activity_fr: grant?.project_activity_fr || undefined,
    zone_en: grant?.zone_en || undefined,
    zone_fr: grant?.zone_fr || undefined,
    selection_criteria_en: grant?.selection_criteria_en || undefined,
    selection_criteria_fr: grant?.selection_criteria_fr || undefined,
    steps_how_to_apply_en: grant?.steps_how_to_apply_en || undefined,
    steps_how_to_apply_fr: grant?.steps_how_to_apply_fr || undefined,
    funding_terms_and_conditions_en:
      grant?.funding_terms_and_conditions_en || undefined,
    funding_terms_and_conditions_fr:
      grant?.funding_terms_and_conditions_fr || undefined,
    additional_information_en: grant?.additional_information_en || undefined,
    additional_information_fr: grant?.additional_information_fr || undefined,
    notes: grant?.notes || undefined,
    grant_timeline: grant?.grant_timeline || [],
    for_profit: grant?.for_profit || undefined,
    grant_projects_en: grant?.grant_projects_en || [],
    grant_projects_fr: grant?.grant_projects_fr || [],
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Grant Properties',
      children: (
        <GrantFormProperties
          setSelectedPrompt={setSelectedPrompt}
          setAddNewPromptDrawer={setAddNewPromptDrawer}
          onSave={onSave}
          prompts={prompts}
          grant={grant}
          form={form}
          isGeneratingRemaining={isGeneratingRemaining}
          isGeneratingAll={isGeneratingAll}
          setIsGeneratingAll={setIsGeneratingAll}
        />
      ),
    },
    {
      key: '2',
      label: 'Grant Sections',
      children: (
        <GrantFormSections
          grant={grant}
          form={form}
          onSave={onSave}
          setSelectedPrompt={setSelectedPrompt}
          setAddNewPromptDrawer={setAddNewPromptDrawer}
          prompts={prompts}
          isGeneratingRemaining={isGeneratingRemaining}
          isGeneratingAll={isGeneratingAll}
        />
      ),
    },
    {
      key: '3',
      label: 'Grant Timeline',
      children: (
        <GrantFormTimeline
          grant={grant}
          form={form}
          isGeneratingRemaining={isGeneratingRemaining}
          isGeneratingAll={isGeneratingAll}
        />
      ),
    },
    {
      key: '4',
      label: 'Grant Project',
      children: (
        <GrantFormProjects
          grant={grant}
          form={form}
          isSavingProjects={isSavingAll}
          setIsSavingProjects={setIsSavingAll}
          isGeneratingAll={isGeneratingAll}
        />
      ),
    },
  ];

  return (
    <>
      <Card style={{ padding: '1rem', background: theme.colors.beige_4 }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="grant"
        >
          <Card
            style={{
              background: theme.colors.background,
              marginBottom: '1rem',
            }}
            styles={{ body: { padding: '0.5rem 1rem 0.5rem' } }}
          >
            <Div flex="row" justify="space-between" align="center">
              <Form.Item label="Promoted" style={{ margin: '0' }}>
                <Switch
                  defaultValue={!!grant?.promoted_at}
                  onChange={savePromotedOnly}
                />
              </Form.Item>

              <Form.Item
                label="Verified"
                name="verified"
                style={{ width: '125px', margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Please specified if this grant is verified',
                  },
                ]}
              >
                <Select placeholder="Select option">
                  <Select.Option value="Verified">Verified</Select.Option>
                  <Select.Option value="Rejected">Rejected</Select.Option>
                  <Select.Option value="Suspended">Suspended</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Application Status"
                name="application_status"
                style={{ margin: '0' }}
              >
                <Select placeholder="Select option" onChange={onSave}>
                  <Select.Option value="open">Open</Select.Option>
                  <Select.Option value="suspending">Suspending</Select.Option>
                  <Select.Option value="openingSoon">
                    Opening Soon
                  </Select.Option>
                  <Select.Option value="closingSoon">
                    Closing Soon
                  </Select.Option>
                  <Select.Option value="closed">Closed</Select.Option>
                </Select>
              </Form.Item>
              <GrantPromptPropertySection
                form={form}
                onSave={onSave}
                label="Rank Score"
                section="rank"
                isNumberInput={true}
                margin="0"
              />
              <GrantPromptPropertySection
                form={form}
                label="Created At"
                section="grant_created_at"
                isDateInput={true}
                disabled={true}
                margin="0"
              />

              <GrantPromptPropertySection
                form={form}
                label="Updated At"
                section="grant_updated_at"
                isDateInput={true}
                disabled={true}
                margin="0"
              />

              <Link target="_blank" to={`/gin/${grant?.grant_id}`}>
                <Button>GIN</Button>
              </Link>

              <Button
                onClick={() =>
                  window.open(
                    `https://admin.hellodarwin.com/funding-explorer/${grant?.grant_id}`,
                    '_blank',
                  )
                }
                ghost
                icon={
                  <NewWindow
                    style={{ color: theme.colors.purple_1 }}
                    size={24}
                  />
                }
                type="text"
              />
            </Div>
          </Card>
          <Div flex="column" gap={8}>
            <GrantFormHeader
              form={form}
              setSelectedPrompt={setSelectedPrompt}
              setAddNewPromptDrawer={setAddNewPromptDrawer}
              onSave={onSave}
              initialValues={initialValues}
              grant={grant}
              grantLogo={grantLogo}
              setOpen={setOpen}
              prompts={prompts}
            />
            <Collapse
              items={items}
              defaultActiveKey={['1']}
              activeKey={activeKey}
              onChange={(key) => {
                if (setActiveKey) {
                  if (typeof key === 'string') {
                    setActiveKey([key]);
                    return;
                  }
                  setActiveKey(key);
                }
              }}
            />
          </Div>
          <Div flex="row" justify="flex-end">
            <Button
              style={{ backgroundColor: theme.colors.red_1 }}
              onClick={() => setDeleteModal(true)}
            >
              {'Delete grant'}
            </Button>
          </Div>
        </Form>
      </Card>
      <CreateUpdatePromptDrawer
        prompt={selectedPrompt}
        visible={addNewPromptDrawer}
        onClose={() => setAddNewPromptDrawer(false)}
      />
      <Modal
        open={open}
        handleCancel={() => setOpen(false)}
        noPadding
        size="full"
      >
        <Uploader
          fileList={fileList}
          name={'logo'}
          width={200}
          height={200}
          handleChange={handleUploadChange}
        />
      </Modal>
      <Modal
        open={deleteModal}
        handleCancel={() => {
          setDeleteModal(false);
        }}
      >
        <DeleteGrantModal
          grant_id={grant?.grant_id || ''}
          setDeleteModal={setDeleteModal}
          handleDeleteGrant={handleDeleteGrant}
        />
      </Modal>
    </>
  );
};

export default GrantForm;
