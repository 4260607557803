import { TabsProps } from 'antd/es/tabs';
import {
  CSSProperties,
  lazy,
  PropsWithChildren,
  ReactNode,
  Suspense,
} from 'react';
import { ContainerStyled } from './styles';

const ContainerTabsStyled = lazy(() =>
  import('./styles').then((module) => ({
    default: module.ContainerTabsStyled,
  })),
);

export interface ContainerProps extends PropsWithChildren {
  className?: string;
  noPadding?: boolean;
  fullWidth?: boolean;
  fitContent?: boolean;
  style?: CSSProperties;
  styles?: {
    container?: CSSProperties;
    content?: CSSProperties;
  };
  onClick?: () => void;
  handleTabClick?: (tab: string) => void;
  activeTab?: string;
  defaultActiveTab?: string;
  tabs?: TabsProps['items'];
  tabBarExtraContent?: ReactNode;
  overflow?: CSSProperties['overflow'];
}

const Container = ({
  children,
  className,
  noPadding,
  fitContent,
  style,
  styles,
  onClick,
  handleTabClick,
  activeTab,
  defaultActiveTab,
  tabs,
  tabBarExtraContent,
  fullWidth = false,
  overflow,
}: ContainerProps) => {
  const withTabs = !!tabs;

  return (
    <div style={{ ...styles?.container, width: '100%' }}>
      {!!tabs && (
        <Suspense fallback={<></>}>
          <ContainerTabsStyled
            items={tabs}
            activeKey={activeTab}
            defaultActiveKey={defaultActiveTab}
            onTabClick={handleTabClick}
            type="card"
            style={{ ...styles?.content, ...style }}
            tabBarExtraContent={tabBarExtraContent}
            $fullWidth={fullWidth}
          />
        </Suspense>
      )}
      {!!children && (
        <ContainerStyled
          $fitContent={fitContent}
          $noPadding={noPadding || withTabs}
          style={{ ...styles?.content, ...style }}
          className={className}
          onClick={onClick}
          $fullWidth={fullWidth}
          $overflow={overflow}
        >
          {children}
        </ContainerStyled>
      )}
    </div>
  );
};

export default Container;
