import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';

const validateAnyType =
  ({ message, isRequired }: { message?: string; isRequired?: boolean }) =>
  (rules: RuleObject, value: StoreValue) => {
    const required = isRequired ?? rules.required;
    const activeMessage = message ?? `${rules.message}`;
    if (!required) return Promise.resolve();

    switch (typeof value) {
      case 'undefined':
        return Promise.reject(new Error(activeMessage));
      case 'boolean':
      case 'number':
      case 'bigint':
        return Promise.resolve();
      case 'string':
        return !!value?.length
          ? Promise.resolve()
          : Promise.reject(new Error(activeMessage));
      case 'symbol':
      case 'function':
      case 'object':
      default:
        return Promise.reject(new Error(activeMessage));
    }
  };

export default validateAnyType;
