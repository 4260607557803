import Container from '../../../common/container';
import Div from '../../../common/div';
import ProgramEmptyStateContent from './program-empty-state-content';
import ProgramEmptyStateFooter from './program-empty-state-footer';
import ProgramEmptyStateHeader from './program-empty-state-header';

const ProgramEmptyState = () => {
  return (
    <Container style={{ paddingTop: 54, paddingBottom: 40 }}>
      <Div
        fitContent
        flex="column"
        gap={50}
        align="center"
        style={{ margin: 'auto' }}
        tablet={{ style: { width: '100%' } }}
      >
        <ProgramEmptyStateHeader />
        <ProgramEmptyStateContent />
        <ProgramEmptyStateFooter />
      </Div>
    </Container>
  );
};

export default ProgramEmptyState;
