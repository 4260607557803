import { ReactNode } from 'react';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';

type ProfileActionsProps = {
  providerActions?: ReactNode;
  websiteCTA?: () => void;
};
const ProfileActions = ({
  providerActions,
  websiteCTA,
}: ProfileActionsProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <Div flex="column" tablet={{ flex: 'column', gap: '2' }} fitContent>
      {providerActions && (
        <Div
          flex="row"
          justify={'flex-end'}
          align="center"
          tablet={{
            justify: 'flex-end',
            style: { paddingLeft: 0, paddingRight: 0 },
          }}
          gap={12}
          fitContent
        >
          {providerActions}
        </Div>
      )}
      {websiteCTA && (
        <Button
          defaultStyle={theme.colors.yellow_1}
          onClick={websiteCTA}
          fitContent
          tablet={{
            textWrap: true,
          }}
          size="small"
        >
          {t('profile_header|profileHeaderCta')}
        </Button>
      )}
    </Div>
  );
};

export default ProfileActions;
