import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import { AdminProjectResponse } from '../../../features/entities/projects-entities';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Typography from '../../common/typography';
import { ProjectsListStateValues } from '../hooks/use-projects-list-state';
import MainProjectTableContent from './main-project-table-content';
import CompanyMainProjectTableHeader from './main-project-table-header';
import { MainProjectContainer } from './styles';

export interface CompanyMainProjectTableProps {
  project: AdminProjectResponse;
  projectsListState: ProjectsListStateValues;
}

const CompanyMainProjectTable: React.FC<CompanyMainProjectTableProps> = (
  props,
) => {
  const { project, projectsListState } = props;
  const { handleAddProject, editState } = projectsListState;

  const { t } = useTranslations();
  const theme = useTheme();

  const onAddProject = () => handleAddProject(project);
  return (
    <MainProjectContainer>
      <CompanyMainProjectTableHeader
        project={project}
        projectsListState={projectsListState}
      />

      <Div flex="column" gap={12}>
        {editState === 'editable' && (
          <Div
            flex="row"
            gap={32}
            align="flex-end"
            justify="space-between"
            style={{ paddingInlineStart: 24 }}
          >
            <Typography elementTheme="body3" bold color={theme.colors.grey_2}>
              {t('project|childProjects')}
            </Typography>
            <Button
              defaultStyle={theme.colors.white_1}
              size="small"
              headingIcon={<AddNew size={20} />}
              onClick={onAddProject}
            >
              {t('project|addNewProject')}
            </Button>
          </Div>
        )}
        <MainProjectTableContent {...props} />
      </Div>
    </MainProjectContainer>
  );
};

export default CompanyMainProjectTable;
