import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Transformation = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8 9.35382L25.6 7.41462M28.8 9.35382V12.6769M28.8 9.35382L25.6 11.293M3.20001 9.35382L6.40001 7.41462M3.20001 9.35382L6.40001 11.293M3.20001 9.35382V12.6769M16 17.1077L19.2 15.1685M16 17.1077L12.8 15.1685M16 17.1077V20.4307M16 30.4L19.2 28.4608M16 30.4V27.0769M16 30.4L12.8 28.4608M12.8 3.5377L16 1.59998L19.2 3.53918M28.8 19.3231V22.6461L25.6 24.5853M6.40001 24.5853L3.20001 22.6461V19.3231"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Transformation;
