import { CSSProperties, useEffect, useRef, useState } from 'react';
import { HubspotContainerEntity } from '../../../features/entities/hubspot-entities';
import theme from '../../../theme';
import Div from '../../common/div';
import Loading from '../../loading';
import { HubspotFormStyling } from './styles';

declare global {
  interface Window {
    hbspt: any;
  }
}

const HubspotForm = ({
  hubspotId,
  containerTag,
  assignedTheme,
}: HubspotContainerEntity) => {
  const [script, setScript] = useState<HTMLScriptElement>();
  const [loading, setLoading] = useState(false);
  const formElement = useRef<HTMLDivElement>(null);

  const [form, setForm] = useState<HTMLFormElement>();

  const createForm = () => {
    if (!!window?.hbspt?.forms) {
      // protect against component unmounting before window.hbspt is available
      if (formElement.current === null) {
        return;
      }

      let options = {
        region: 'na1',
        portalId: '21902860',
        formId: hubspotId,
        target: `#${formElement.current.getAttribute(`id`)}`,
        onFormReady: (form: HTMLFormElement) => {
          setForm(form);
        },
      };
      window.hbspt.forms.create(options);
      return true;
    } else {
      setTimeout(createForm, 1);
    }
  };

  const findFormElement = () => {
    let form = formElement.current?.querySelector(`iframe`);
    if (!!form) {
      setLoading(false);
    } else {
      setTimeout(findFormElement, 1);
    }
  };

  const loadScript = () => {
    let script = document.createElement(`script`);
    script.defer = true;
    script.onload = () => {
      createForm();
      findFormElement();
    };
    script.src = `//js.hsforms.net/forms/v2.js`;
    document.head.appendChild(script);
    setScript(script);
  };

  useEffect(() => {
    setLoading(true);
    if (!window.hbspt) {
      loadScript();
    } else {
      createForm();
      findFormElement();
    }

    return () => {
      if (!!script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const containerDefaultStyle: CSSProperties = {
    color: theme.colors.primary,
    background: theme.colors.white_1,
    width: 'calc(100% - 100px)',
  };

  return (
    <>
      <Div style={containerDefaultStyle}>
        <HubspotFormStyling
          $assignedTheme={assignedTheme}
          id={`hubspot-form-${containerTag}`}
          ref={formElement}
          style={{ position: 'relative' }}
          $isNewsletter={containerTag === 'newsletter'}
        />
        {!!loading && (
          <Div
            style={{ height: 400 }}
            flex="column"
            align="center"
            justify="center"
          >
            <Loading />
          </Div>
        )}
      </Div>
    </>
  );
};

export default HubspotForm;
