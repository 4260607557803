import Loading from '@hellodarwin/core/lib/components/loading';
import Invoices from '@hellodarwin/icons/dist/icons/Invoices';
import Empty from 'antd/es/empty';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  fetchGinApplicationProgram,
  selectGinApplicationProgram,
  selectGinsIsLoading,
} from '../../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../../features/api/use-admin-api';
import CardLayout from './card-layout';

interface ApplicationProgramCardProps {
  applicationId: string;
}

const ApplicationProgramCard = ({
  applicationId,
}: ApplicationProgramCardProps) => {
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();

  const program = useAppSelector(selectGinApplicationProgram);
  const isLoading = useAppSelector((state) =>
    selectGinsIsLoading(state, 'applicationCompany'),
  );

  useEffect(() => {
    dispatch(fetchGinApplicationProgram({ api, applicationId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  if (isLoading) return <Loading />;
  if (!program.program_grant_id) return <Empty />;

  return (
    <CardLayout
      to={`/gin/${program.program_grant_id}`}
      title={program.program_name ?? ''}
      infos={[
        {
          Icon: Invoices,
          value: `${program.program_applications.length} applications`,
        },
      ]}
    />
  );
};

export default ApplicationProgramCard;
