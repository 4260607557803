import { TeamMember } from '../../../../features/entities/core-entities';
import { useTheme } from '../../../../plugins/styled';
import List from '../../../common/List';
import Div from '../../../common/div';
import { ProfileEditModalFunctions } from '../../../forms/prebuilt-forms/profile-edit-modal';
import TeamMemberCard from './team-member-card';

type TeamListProps = {
  teamMembers: TeamMember[];
  selectedMember: TeamMember;
  setSelectedMember: (selectedMember: TeamMember) => void;
  isEditable?: boolean;
  setMembers?: (value: TeamMember[]) => void;
  functions?: ProfileEditModalFunctions;
};

const TeamList = ({
  teamMembers,
  selectedMember,
  setSelectedMember,
  isEditable,
  setMembers,
  functions,
}: TeamListProps) => {
  const theme = useTheme();

  return (
    <Div
      bordersSide="left"
      borderColor={theme.colors.primary}
      overflow="auto"
      style={{
        maxHeight: '85vh',
        padding: '15px 20px',
      }}
    >
      <List
        grid={{ column: 1, gutter: 20 }}
        dataSource={
          isEditable ? [{} as TeamMember, ...teamMembers] : teamMembers
        }
        renderItem={(m) =>
          selectedMember ? (
            <List.Item
              style={{ paddingBottom: 24, marginBottom: 0, height: 'auto' }}
            >
              <TeamMemberCard
                key={m.provider_team_member_id}
                selected={
                  m.provider_team_member_id ===
                  selectedMember.provider_team_member_id
                }
                member={m}
                handleClick={() => setSelectedMember(m)}
                isEditable={isEditable}
                teamMembers={teamMembers}
                setTeamMembers={setMembers}
                functions={functions}
              />
            </List.Item>
          ) : null
        }
      />
    </Div>
  );
};

export default TeamList;
