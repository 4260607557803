import Group1Filled from '@hellodarwin/icons/dist/backgrounds/Group1Filled';
import {
  IconParams,
  SpotIconProps,
} from '@hellodarwin/icons/dist/backgrounds/icon-entities';
import React from 'react';
import { Tablet } from '../../Media';
import { IconContent } from './styles';
type IconProps = {
  iconParams: IconParams;
};
const BackgroundIcon = ({ iconParams }: IconProps) => {
  const iconProps: SpotIconProps = {
    width: iconParams?.width,
    height: iconParams?.height,
  };

  return (
    <Tablet direction="up" inactive={!iconParams?.desktopOnly}>
      <IconContent
        $iconHeight={iconParams?.height}
        $iconWidth={iconParams?.width}
        $iconRotate={iconParams?.rotate}
        $iconTop={iconParams?.top}
        $iconColor={iconParams?.color}
        $iconMargin={iconParams?.margin}
        $side={iconParams?.side}
        $inversed={iconParams?.inversed}
      >
        <Group1Filled {...iconProps} />
      </IconContent>
    </Tablet>
  );
};
export default BackgroundIcon;
