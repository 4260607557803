import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import React from 'react';
import { ParsedTag } from '../../../../features/entities';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Collapse from '../../../common/Collapse';
import { Desktop } from '../../../common/Media';
import Div from '../../../common/div';
import HdTag from '../../../common/hd-tag';
import Typography from '../../../common/typography';

const ExpertiseCard = ({ category, services, specialties }: ParsedTag) => {
  const { t: translateTag } = useTranslation(['tags'], { useSuspense: false });
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <>
      <Collapse
        ExpandIcon={({ isActive }) => (
          <Div
            gap={14}
            flex="row"
            align="center"
            fitContent
            style={{ height: 60 }}
            tablet={{ style: { height: 'auto' } }}
          >
            <Desktop direction="up">
              <Typography
                medium
                textTransform="uppercase"
                letterSpacing={0.36}
                nowrap
                elementTheme="overline"
              >
                {isActive
                  ? t('profile_expertise|seeLessDetails')
                  : t('profile_expertise|seeMoreDetails')}
              </Typography>
            </Desktop>

            <TabArrow down={isActive} width={8} height={4} />
          </Div>
        )}
        items={[
          {
            id: '1',
            headerIsColumnOnTablet: true,
            title: (
              <Typography.Title
                level={3}
                elementTheme="h6"
                noMargin
                style={{ textTransform: 'capitalize' }}
              >
                {translateTag(`tags|${category}`)}
              </Typography.Title>
            ),
            children: (
              <Div flex="column" gap={16}>
                {services?.length > 0 && (
                  <Div flex="column" gap={16}>
                    <Typography.Title
                      level={4}
                      color={theme.colors.grey_2}
                      elementTheme="caption"
                      textTransform="uppercase"
                      bold
                      nowrap
                      noMargin
                    >
                      {t('profile_expertise|expertisesServiceTitle')}
                    </Typography.Title>

                    <Div flex="column" gap={16}>
                      <Div flex="column" gap={16}>
                        {React.Children.toArray(
                          services?.map((service, serviceIndex) => (
                            <Div flex="column" gap={10} key={service?.tag}>
                              <Typography
                                color={theme.colors.grey_2}
                                elementTheme="overline"
                                textTransform="uppercase"
                                medium
                                nowrap
                              >
                                {translateTag(`tags|${service?.tag}`)}
                              </Typography>

                              <Typography elementTheme="body2">
                                {service?.description}
                              </Typography>
                            </Div>
                          )),
                        )}
                      </Div>
                    </Div>
                  </Div>
                )}

                {specialties?.length > 0 && (
                  <Div flex="column" gap={24}>
                    <Typography
                      color={theme.colors.grey_2}
                      elementTheme="overline"
                      textTransform="uppercase"
                      medium
                      nowrap
                    >
                      {t('profile_expertise|expertisesSpecialtiesTitle')}
                      {translateTag(`tags|${category}`)}
                    </Typography>
                    <Div flex="row" wrap="wrap" gap={16}>
                      {React.Children.toArray(
                        specialties?.map((specialty, specialtyIndex) => (
                          <HdTag
                            key={specialtyIndex}
                            text={specialty}
                            translateTag
                          />
                        )),
                      )}
                    </Div>
                  </Div>
                )}
              </Div>
            ),
          },
        ]}
      />
    </>
  );
};

export default ExpertiseCard;
