import { CSSProperties } from 'react';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import { Tablet } from '../../../common/Media';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ReviewRatings from '../../../reviews/ratings';

type ReviewsAverageCardProps = {
  handleLeaveAReview?: () => void;
  average?: number;
  style?: CSSProperties;
  mobileStyle?: CSSProperties;
};
const ReviewsAverageCard = ({
  handleLeaveAReview,
  average,
  style,
  mobileStyle,
}: ReviewsAverageCardProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return average !== 0 ? (
    <Div
      flex="column"
      gap={14}
      style={{
        padding: '12px 16px',
        borderRadius: 3,
        width: 128,
        height: 92,
        ...style,
      }}
      backgroundColor={theme.colors.beige_4}
      borderColor={theme.colors.beige_2}
      fitContent
      align="center"
      xl={{
        style: { width: 68, height: 30, padding: 0, ...mobileStyle },
      }}
    >
      <ReviewRatings
        size="large"
        showOne
        defaultValue={average ?? 0}
        showValue
        textPlacement="end"
        biggerText
        allowHalf
      />

      <a href={`#reviews`}>
        <Button
          isLink
          size="auto"
          defaultStyle={theme.colors.purple_1}
          style={{
            letterSpacing: 0.33,
            textDecoration: 'underline',
            textTransform: 'uppercase',
            fontWeight: 500,
          }}
          xl={{ style: { display: 'none' } }}
        >
          {t('reviews|seeReviews')}
        </Button>
      </a>
    </Div>
  ) : (
    <Tablet>
      <Div
        flex="column"
        gap={14}
        style={{
          padding: '12px 16px',
          borderRadius: 3,
          width: !!handleLeaveAReview ? 220 : 160,
          height: !!handleLeaveAReview ? 145 : 90,
          ...style,
        }}
        xl={{ style: { ...mobileStyle } }}
        backgroundColor={theme.colors.beige_4}
        borderColor={theme.colors.beige_2}
        align="center"
      >
        <ReviewRatings size="large" showOne defaultValue={0} allowHalf />

        <Div flex="column" gap={12} align="center">
          <Typography elementTheme="body3" bold textAlign="center">
            {t('reviews|noReviewsShort')}
          </Typography>
          {handleLeaveAReview && (
            <Button
              isLink
              size="auto"
              defaultStyle={theme.colors.purple_1}
              style={{
                letterSpacing: 0.33,
                textDecoration: 'underline',
                textUnderlineOffset: 2,
                fontWeight: 500,
                textAlign: 'center',
              }}
              onClick={handleLeaveAReview}
              textWrap
            >
              {t('reviews|leaveFirstReview')}
            </Button>
          )}
        </Div>
      </Div>
    </Tablet>
  );
};

export default ReviewsAverageCard;
