import Checkbox from 'antd/es/checkbox/Checkbox';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetHiddenType, AssetRowMeta } from '../../../../features/entities';
import theme from '../../../../theme';
import Button from '../../../common/button';
import Div from '../../../common/div';
import ModalLayoutSimple from '../../../common/hd-modal/layouts/modal-simple';
import Typography from '../../../common/typography';

interface HideAssetModalProps {
  open: boolean;
  onClose: () => void;
  meta?: AssetRowMeta;
}
const HideAssetModal: React.FC<HideAssetModalProps> = ({
  open,
  onClose,
  meta,
}) => {
  const { t } = useTranslation();
  const [hiddenState, setHiddenState] = useState<AssetHiddenType>();

  const handleHideShow: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (meta.actions.onHideShow) meta.actions.onHideShow(hiddenState);
    onClose();
  };

  useEffect(() => {
    setHiddenState(meta?.hidden);
  }, [meta]);

  return (
    <ModalLayoutSimple
      styles={{ wrapper: { zIndex: 3100, overflow: 'hidden' } }}
      open={open}
      noPadding
      handleCancel={onClose}
      size="small"
    >
      <Div
        flex="column"
        justify="space-evenly"
        align="center"
        style={{ padding: 24 }}
      >
        <Typography
          style={{ fontSize: 24, fontWeight: 600, lineHeight: '28px' }}
        >
          {t('assets|hide.title')}
        </Typography>
        <Div
          flex="column"
          gap={12}
          align="flex-start"
          style={{
            paddingBottom: 24,
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          <Checkbox
            checked={hiddenState?.is_admin_hidden}
            onChange={(e) =>
              setHiddenState({
                ...hiddenState,
                is_admin_hidden: e.target.checked,
              })
            }
          >
            <Typography
              style={{
                fontSize: 16,
                lineHeight: '24px',
              }}
            >
              {t('assets|hide.admins')}
            </Typography>
          </Checkbox>
          <Checkbox
            checked={hiddenState?.is_companies_hidden}
            onChange={(e) =>
              setHiddenState({
                ...hiddenState,
                is_companies_hidden: e.target.checked,
              })
            }
          >
            <Typography
              style={{
                fontSize: 16,
                lineHeight: '24px',
              }}
            >
              {t('assets|hide.companies')}
            </Typography>
          </Checkbox>
          <Div flex="row" gap={8} align="flex-start">
            <Checkbox
              checked={hiddenState?.is_contacts_hidden}
              onChange={(e) =>
                setHiddenState({
                  ...hiddenState,
                  is_contacts_hidden: e.target.checked,
                })
              }
            ></Checkbox>
            <Div flex="column">
              <Typography
                style={{
                  fontSize: 16,
                  lineHeight: '24px',
                }}
              >
                {t('assets|hide.contacts.title')}
              </Typography>
              <Typography
                style={{
                  color: theme.colors.purple_2,
                  fontSize: 16,
                  lineHeight: '24px',
                }}
              >
                {t('assets|hide.contacts.description')}
              </Typography>
            </Div>
          </Div>
          <Div flex="row" gap={8} align="flex-start">
            <Checkbox
              checked={hiddenState?.is_providers_hidden}
              onChange={(e) =>
                setHiddenState({
                  ...hiddenState,
                  is_providers_hidden: e.target.checked,
                })
              }
            ></Checkbox>
            <Div flex="column">
              <Typography
                style={{
                  fontSize: 16,
                  lineHeight: '24px',
                }}
              >
                {t('assets|hide.providers.title')}
              </Typography>
              <Typography
                style={{
                  color: theme.colors.purple_2,
                  fontSize: 16,
                  lineHeight: '24px',
                }}
              >
                {t('assets|hide.providers.description')}
              </Typography>
            </Div>
          </Div>
        </Div>
        <Button
          size="small"
          style={{ alignSelf: 'flex-end' }}
          defaultStyle={theme.colors.black}
          onClick={handleHideShow}
        >
          {t('assets|hide.save')}
        </Button>
      </Div>
    </ModalLayoutSimple>
  );
};

export default HideAssetModal;
