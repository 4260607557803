import isAssetFolder from '../../components/files/utils/is-asset-folder';
import {
  AssetEntity,
  AssetFolderEntity,
  AssetItemType,
} from '../entities/assets-entities';

const sortByType = (a1: AssetItemType, a2: AssetItemType) => {
  const asset1IsFolder = isAssetFolder(a1);
  const asset2IsFolder = isAssetFolder(a2);

  if (asset1IsFolder && asset2IsFolder) {
    const folder1 = a1 as AssetFolderEntity;
    const folder2 = a2 as AssetFolderEntity;

    return folder1.record_type.localeCompare(folder2.record_type);
  } else if (asset1IsFolder && !asset2IsFolder) {
    return -1;
  } else if (!asset1IsFolder && asset2IsFolder) {
    return 1;
  } else {
    const file1 = a1 as AssetEntity;
    const file2 = a2 as AssetEntity;

    return file1.extension.localeCompare(file2.extension);
  }
};

const sortByAlpha = (a1: AssetItemType, a2: AssetItemType) => {
  const asset1IsFolder = isAssetFolder(a1);
  const asset2IsFolder = isAssetFolder(a2);

  if (asset1IsFolder && asset2IsFolder) {
    const folder1 = a1 as AssetFolderEntity;
    const folder2 = a2 as AssetFolderEntity;

    return folder1.record_name.localeCompare(folder2.record_name);
  } else if (asset1IsFolder && !asset2IsFolder) {
    return -1;
  } else if (!asset1IsFolder && asset2IsFolder) {
    return 1;
  } else {
    const file1 = a1 as AssetEntity;
    const file2 = a2 as AssetEntity;

    return file1.display_name.localeCompare(file2.display_name);
  }
};

export const sortByCreatedDate = (a1: AssetItemType, a2: AssetItemType) => {
  if (!a1?.created_at || !a2?.created_at) return 0;
  return a2.created_at > a1.created_at ? 1 : -1;
};
const filterFiles = (
  assets: AssetItemType[],
  sortType: string,
  orderType: string,
) => {
  let filtered = [...assets];
  switch (sortType) {
    case 'type':
      filtered = [...assets].sort(sortByType);
      break;
    case 'alpha':
      filtered = [...assets].sort(sortByAlpha);
      break;
    case 'recent':
      filtered = [...assets].sort(sortByCreatedDate);
      break;
  }

  switch (orderType) {
    case 'ascending':
      break;
    case 'descending':
      filtered = filtered.reverse();
      break;
  }

  return filtered;
};

export default filterFiles;
