import { WebsiteGrantResult } from '../../../../../features/entities/grants-entities';
import parse from '../../../../../features/helpers/parse';
import Div from '../../../../common/div';
import GrantProjectsList from './grant-projects-list';

const GrantProjects = ({
  project_activity,
  grant_projects,
}: WebsiteGrantResult) => {
  return (
    <Div flex="column" gap={54}>
      {!!project_activity && <div>{parse(project_activity)}</div>}
      {!!grant_projects?.length && (
        <GrantProjectsList grant_projects={grant_projects} />
      )}
    </Div>
  );
};

export default GrantProjects;
