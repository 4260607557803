import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import Loading from '@hellodarwin/core/lib/components/loading';
import ProgramSingle, {
  ProgramSingleTabs,
} from '@hellodarwin/core/lib/components/programs/single';
import { Grant } from '@hellodarwin/core/lib/features/entities';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import { RootState } from '../../app/app-store';
import {
  fetchAdminById,
  selectAdminById,
} from '../../features/api/slices/admins-slice';
import {
  fetchGrant,
  selectGrantById,
} from '../../features/api/slices/grants-slice';
import {
  fetchProgramById,
  selectProgramsId,
} from '../../features/api/slices/programs-slice';
import { useAdminApi, useNewAdminApi } from '../../features/api/use-admin-api';

interface SingleCompanyProgramPageProps {
  defaultSection?: ProgramSingleTabs;
}
const SingleCompanyProgramPage: React.FC<SingleCompanyProgramPageProps> = ({
  defaultSection = ProgramSingleTabs.ProgramInfo,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const { t } = useTranslations();
  const { companyId, programId } = useParams<{
    companyId: string;
    programId: string;
  }>();
  const program = useAppSelector((state: RootState) =>
    selectProgramsId(state, `${programId}`),
  );
  const grant = useAppSelector((state: RootState) =>
    selectGrantById(state, `${program?.program_grant_id}`),
  );
  const admin = useAppSelector((state: RootState) =>
    selectAdminById(state, `${program?.program_account_manager}`),
  );
  //
  useEffect(() => {
    if (!!program?.program_grant_id) {
      dispatch(fetchProgramById({ api, programId: program?.program_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!program) {
      dispatch(
        fetchGrant({ api: newApi, grantId: `${program?.program_grant_id}` }),
      );
      dispatch(
        fetchAdminById({
          api,
          adminId: `${program?.program_account_manager}`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const goBack = () => {
    navigate(`/companies/${companyId}/grants`);
  };

  const handleTabChange = (newTab: string) => {
    if (newTab === 'files') {
      navigate(`/companies/${companyId}/grants/${grant.grant_id}/files`);
    } else {
      navigate(`/companies/${companyId}/grants/${grant.grant_id}`);
    }
  };
  const { selectedLocale } = useLocale();

  if (!program || !grant) return <Loading />;

  const queriedGrant = Object.entries(grant).reduce((o, entry) => {
    const [key, value] = entry;
    if (key.includes('en') || key.includes('fr')) {
      if (selectedLocale === 'en' && key.includes('en')) {
        return { ...o, [key.replace('_en', '')]: value };
      } else if (selectedLocale === 'fr' && key.includes('fr')) {
        return { ...o, [key.replace('_fr', '')]: value };
      } else {
        return { ...o };
      }
    } else {
      return { ...o, [key]: value };
    }
  }, {});
  return (
    <PageLayout
      handleBack={goBack}
      app="admin"
      title={program?.program_name}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: t('application_list|applications'),
          path: '/programs',
        },
        {
          breadcrumbName: program?.program_name,
        },
      ]}
    >
      <ProgramSingle
        handleTabChange={handleTabChange}
        activeTab={defaultSection}
        {...program}
        grant={queriedGrant as Grant}
        admin={admin}
      />
    </PageLayout>
  );
};

export default SingleCompanyProgramPage;
