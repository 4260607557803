import Button from '@hellodarwin/core/lib/components/common/button';
import { ModalConfirm } from '@hellodarwin/core/lib/components/common/hd-modal/confirm';
import UserFormModal, {
  EMAIL_ALREADY_EXISTS_ERROR,
} from '@hellodarwin/core/lib/components/profile/profile-users-list/user-form-modal';
import { Contact } from '@hellodarwin/core/lib/features/entities/core-entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import message from 'antd/es/message';
import { MouseEventHandler, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  selectCompaniesIsLoading,
  selectCompanyById,
} from '../../features/api/slices/companies-slice';
import {
  assignContact,
  createContact,
  getContactByEmail,
  selectCompanyContacts,
  updateContact,
} from '../../features/api/slices/users-slice';
import { useAdminApi } from '../../features/api/use-admin-api';

interface AddNewUserButtonProps {
  companyId: string;
}

const AddNewUserButton: React.FC<AddNewUserButtonProps> = ({ companyId }) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const company = useAppSelector((state) =>
    selectCompanyById(state, companyId),
  );
  const users = useAppSelector((state) =>
    selectCompanyContacts(state, companyId),
  );
  const isLoading = useAppSelector(selectCompaniesIsLoading);

  const [userModalOpen, setUserModalOpen] = useState(false);

  const handleUserModal = () => setUserModalOpen(!userModalOpen);

  const handleNewUser: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setUserModalOpen(true);
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: 'Client',
      }),
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        ModalConfirm({
          title: t('singleCompanyPage|contact_exists_modal_title'),

          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: '24px',
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: '24px',
            },
          },
          cancelText: t('button|cancel'),
          okText: t('button|confirm'),
          content: t('singleCompanyPage|contact_exists_modal_content'),
          onOk: async () => {
            contact.company_id = company?.company_id || '';
            await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel: async () => {},
        });
      } else {
        message.error(t('singleCompanyPage|messageError'));
      }
    }
    return createContactResult;
  };

  const handleEmailExists = async (email: string) => {
    if (users.find((c) => c.email === email)) {
      return Promise.reject(t('singleCompanyPage|emailExists'));
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = company?.company_id || '';
    return await dispatch(assignContact({ api, contact: contact }));
  };

  return (
    <>
      <Button
        size="circle"
        transparent
        headingIcon={<AddNew size={16} />}
        onClick={handleNewUser}
      />
      <UserFormModal
        open={userModalOpen}
        companyId={companyId}
        handleClose={handleUserModal}
        handleEditUser={handleContactEdit}
        handleContactAdd={handleContactAdd}
        handleContactAssign={handleContactAssign}
        handleEmailExists={handleEmailExists}
        contact={null}
        showPhone={true}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddNewUserButton;
