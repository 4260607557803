import styled from '@hellodarwin/core/lib/plugins/styled';

export const FormPreviewContainer = styled.div`
  padding: 54px;
  position: relative !important;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  width: calc(100% - 48px);
  margin: 0 auto;
  max-width: 450px;

  & > svg {
    position: absolute;
    top: -97px;
    left: -138px;
    color: ${({ theme }) => theme.colors.beige_3};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    height: 100%;
    padding: 32px 0;
    border: none;
    min-height: calc(100vh - ${({ theme }) => theme.appearance.headerHeight});
    justify-content: flex-start;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% - 200px);
    max-width: calc(${({ theme }) => theme.caps.small}px - 300px);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    max-width: ${({ theme }) => theme.caps.small}px;
  }
`;
