import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import Modal, { ModalProps } from '..';
import parse from '../../../../features/helpers/parse';
import { useTranslation } from '../../../../plugins/i18n';
import HubspotMeeting, {
  HubspotMeetingProps,
} from '../../../hubspot/hubspot-meeting';
import Button from '../../button';
import Div from '../../div';
import Typography from '../../typography';

export type ModalLayoutWithIconSize = 24 | 32 | 64 | 96 | 128;

export interface ModalLayoutWithMeetingProps
  extends ModalProps,
    HubspotMeetingProps {
  title: string;
  description?: string;
  Icon: IconNode;
  iconSize?: ModalLayoutWithIconSize;
  iconColor?: string;
}

const ModalLayoutWithMeeting = ({
  title,
  description,
  Icon,
  iconSize,
  iconColor,
  meetingUrl,
  app,
  email,
  phone,
  name,
  companyName,
  additionalParams,
  ...modalProps
}: ModalLayoutWithMeetingProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const classes = classNames('hd-modal-with-meeting');

  const hubspotMeetingProps: HubspotMeetingProps = {
    meetingUrl,
    email,
    app,
    phone,
    name,
    companyName,
    additionalParams,
  };
  return (
    <Modal {...modalProps} size="large" className={classes}>
      <Div flex="column" style={{ margin: '0 auto' }} gap={32} fitMaxContent>
        <Div flex="row" gap={54} fitContent>
          <Div flex="column" gap={20} style={{ marginTop: 108, maxWidth: 440 }}>
            <Icon
              width={iconSize}
              height={iconSize}
              style={{ color: iconColor }}
            />
            <Typography.Title level={3}>{title}</Typography.Title>
            {description && (
              <Typography elementTheme="body2">{parse(description)}</Typography>
            )}
          </Div>
          <div style={{ flexShrink: 0 }}>
            <HubspotMeeting {...hubspotMeetingProps} />
          </div>
        </Div>
        <Div flex="column" align="flex-end">
          <Button
            size="small"
            style={{ flex: 1 }}
            defaultStyle={theme.colors.primary}
            onClick={modalProps.handleCancel}
          >
            {t('button.cancel')}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

export default ModalLayoutWithMeeting;
