import { IconNode } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const RatingOutline: IconNode = (props) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7876 2.82767C15.0713 2.17541 16.0053 2.17541 16.289 2.82767L19.7662 10.8226C19.8846 11.0947 20.1435 11.281 20.4414 11.3084L29.1953 12.1123C29.9095 12.1778 30.1981 13.0574 29.6593 13.5261L23.0545 19.2711C22.8297 19.4666 22.7308 19.7681 22.7965 20.0571L24.7295 28.5489C24.8872 29.2417 24.1315 29.7853 23.5148 29.4227L15.9556 24.9784C15.6983 24.8271 15.3782 24.8271 15.121 24.9784L7.56172 29.4227C6.94501 29.7853 6.18937 29.2417 6.34707 28.5489L8.28 20.0571C8.34578 19.7681 8.24685 19.4666 8.02209 19.2711L1.41729 13.5261C0.878439 13.0574 1.16707 12.1778 1.88124 12.1123L10.6351 11.3084C10.933 11.281 11.192 11.0947 11.3103 10.8226L14.7876 2.82767Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default RatingOutline;
