import { IconProps } from '../features/entities/general';

const Spreadsheet = ({
  width = 32,
  height = 32,
  style,
  className,
  onClick,
  color = 'currentColor',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      className={className}
      onClick={onClick}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.3226 19.3231V15.4461C27.3226 14.1241 26.7916 12.8563 25.8464 11.9215C24.9012 10.9867 23.6193 10.4615 22.2826 10.4615H20.0426C19.597 10.4615 19.1697 10.2865 18.8547 9.97486C18.5396 9.66326 18.3626 9.24064 18.3626 8.79998V6.58459C18.3626 5.26259 17.8316 3.99473 16.8864 3.05994C15.9412 2.12514 14.6593 1.59998 13.3226 1.59998H10.5226M13.8826 1.59998H6.60261C5.67525 1.59998 4.92261 2.34434 4.92261 3.26151V28.7384C4.92261 29.6556 5.67525 30.4 6.60261 30.4H25.6426C26.57 30.4 27.3226 29.6556 27.3226 28.7384V14.8923C27.3226 11.3669 25.9066 7.98599 23.3861 5.4932C20.8656 3.00041 17.4471 1.59998 13.8826 1.59998Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6396 20.5897H12.7294L14.9595 23.9994L12.715 27.4102H14.6316L16 25.281L17.3684 27.4102H19.2794L17.0726 23.9994L19.2649 20.5897H17.3604L16 22.7448L14.6396 20.5897Z"
        fill={color}
      />
    </svg>
  );
};

export default Spreadsheet;
