import Sider from 'antd/es/layout/Sider';
import styled, { css } from 'styled-components';
import { CollapsePanelContainer } from '../../Collapse/styles';

export const SidebarBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.white_1};
  width: 100%;
  z-index: 10;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    z-index: 2;
    pointer-events: none;

    top: 100%;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.white_1},
      rgba(0, 0, 0, 0)
    );
  }
`;
export const SidebarBlockContainer = styled.div`
  overflow: hidden;

  border-bottom: 2px solid ${({ theme }) => theme.colors.grey_5};
  display: flex;
  flex: 1;
  max-height: fit-content;
  ${CollapsePanelContainer} {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .ReactCollapse--collapse {
      overflow-y: auto !important;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.purple_1}EE;
        transition: background 400ms ease;
        border-radius: 99px;

        cursor: pointer;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.colors.purple_2};
      }
    }
  }
`;

export const SidebarPageSiderContainer = styled(Sider)<{
  $position: 'left' | 'right';
}>`
  background: ${({ theme }) => theme.colors.white_1} !important;
  ${({ $position, theme }) =>
    $position === 'left'
      ? css`
          border-right: 1px solid ${theme.colors.grey_5};
          box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.05);
        `
      : css`
          border-left: 1px solid ${theme.colors.grey_5};
          box-shadow: -2px 0px 4px 0px rgba(0, 0, 0, 0.05);
        `};

  flex: 0 0 300px;
  max-height: calc(
    100vh - ${({ theme }) => theme.appearance.appHeaderHeight}px
  );
  position: sticky;
  top: ${({ theme }) => theme.appearance.appHeaderHeight}px;
  .ant-layout-sider-children {
    overflow: hidden;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
  .ant-layout-sider-trigger {
    position: absolute;
    top: 0;
    z-index: 1100;
    background-color: transparent !important;
    color: white;
    width: fit-content !important;
    height: 100%;
    cursor: pointer;
    ${({ $position }) =>
      $position === 'right' ? 'left: -12px;' : 'right: -12px;'}
  }
`;

export const SidebarPageContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${SidebarPageSiderContainer} {
    background: transparent;
    flex-shrink: 0;
    padding-bottom: 0;
  }
`;

export const SidebarPageBody = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-width: 0;
`;
