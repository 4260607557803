import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import {
  HdChatAnswer,
  HdChatQuestion,
} from '@hellodarwin/core/lib/features/entities';
import Edit from '@hellodarwin/icons/dist/icons/Edit';
import Input from 'antd/es/input';
import { useState } from 'react';
import theme from '../../theme';
const { TextArea } = Input;

interface QuestionTextAreaProps {
  question: HdChatQuestion;
  index: number;
  handleQuestionAnswerGeneration: (id: string, tempContext: string) => void;
  setEditMode: (mode: boolean) => void;
  editMode: boolean;
  questionContexts: string[];
  answer?: HdChatAnswer;
}

const QuestionTextArea = ({
  index,
  question,
  editMode,
  questionContexts,
  answer,
  handleQuestionAnswerGeneration,
  setEditMode,
}: QuestionTextAreaProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [tempContext, setTempContext] = useState('');

  const handleEditContext = (index: number) => {
    setEditMode(true);
    setTempContext(questionContexts[index]);
  };
  const handleCancelEdit = () => {
    setEditMode(false);
    setTempContext('');
  };

  const handleSaveContext = async () => {
    setEditMode(false);
    await handleQuestionAnswerGeneration(question.id, tempContext);
  };

  return (
    <Div key={index} flex="column">
      <div
        key={index}
        style={{
          minHeight: 250,
          position: 'relative',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <TextArea
          value={editMode ? tempContext : questionContexts[index] || ''}
          onChange={editMode ? (e) => setTempContext(e.target.value) : () => {}}
          style={{
            minHeight: 250,
            backgroundColor: editMode
              ? theme.colors.white_1
              : theme.colors.beige_3,
          }}
          placeholder="Question context"
        ></TextArea>
        {hovered && !editMode && (
          <Button
            size="auto"
            defaultStyle={theme.colors.purple_1}
            style={{
              padding: 8,
              position: 'absolute',
              right: '10px',
              top: '10px',
            }}
            onClick={() => handleEditContext(index)}
          >
            <Edit size={24} />
          </Button>
        )}
      </div>
      {editMode && (
        <Div flex="row" gap={20} justify="center" style={{ marginTop: 10 }}>
          <Button
            size="auto"
            style={{ padding: 10 }}
            onClick={() => handleSaveContext()}
            defaultStyle={theme.colors.purple_1}
          >
            Save
          </Button>
          <Button
            size="auto"
            style={{ padding: 10 }}
            defaultStyle={theme.colors.red_1}
            onClick={handleCancelEdit}
          >
            Cancel
          </Button>
        </Div>
      )}
    </Div>
  );
};

export default QuestionTextArea;
