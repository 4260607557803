import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import SidebarPageLayout from '@hellodarwin/core/lib/components/common/layout/sidebar-page-layout.tsx';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Empty from 'antd/es/empty';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import GinSingleGrantPage from '../../../components/gins/grant-single';
import { GinGrantSinglePageSection } from '../../../components/gins/grant-single/use-gin-grant-blocks';
import GinRelatedContacts from '../../../components/gins/sidebars/related-contacts';
import GinRelatedPrograms from '../../../components/gins/sidebars/related-programs';
import GinSidebarEmail from '../../../components/gins/sidebars/templates-emails';
import GrantChangeDifficulty from '../../../components/grants/grant-change-difficulty';
import GrantValidationButton from '../../../components/grants/grant-validation/grant-validation-button';
import {
  fetchGinByGrantId,
  selectCurrentGin,
} from '../../../features/api/slices/gins-slice';
import { useNewAdminApi } from '../../../features/api/use-admin-api';

const SingleGinPage = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const api = useNewAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const [isAddAgentVisible, setAddAgentVisible] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<boolean>(false);
  const [activeSnippet, setActiveSnippet] = useState<boolean>(false);
  const onClose = () => {
    setAddAgentVisible(false);
  };

  const onOpen = () => {
    setAddAgentVisible(true);
  };
  useEffect(() => {
    if (params.id) {
      dispatch(fetchGinByGrantId({ api, grantId: params.id }));
      // dispatch(fetchGinAssets({ api, grantId: grantId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  const adminGin = useAppSelector(selectCurrentGin);
  const onNewTemplates: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveTemplate(true);
  };
  const onNewSnippet: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveSnippet(true);
  };

  const handleBack = () => {
    navigate('/gin');
  };
  const title = useMemo(() => {
    if (!adminGin?.grant) return 'Grant';

    return selectedLocale === 'fr'
      ? adminGin?.grant?.grant_title_fr
      : adminGin?.grant?.grant_title_en;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminGin?.grant]);

  if (!params.id) return <Empty />;
  const sections: GinGrantSinglePageSection[] = [
    GinGrantSinglePageSection.ProductionStages,
    GinGrantSinglePageSection.BusinessAdmissibility,
    GinGrantSinglePageSection.ActivitiesAdmissibility,
    GinGrantSinglePageSection.ExpenseAdmissibility,
    GinGrantSinglePageSection.Files,
    GinGrantSinglePageSection.FinancialAssistance,
    GinGrantSinglePageSection.ProcessSummary,
    GinGrantSinglePageSection.SelectionCriteria,
    GinGrantSinglePageSection.Other,
  ];
  return (
    <SidebarPageLayout
      app="admin"
      title={title}
      breadcrumbs={[
        {
          breadcrumbName: 'Home',
          path: '/',
        },
        {
          breadcrumbName: 'Grants Internal Notes',
          path: '/gin',
        },
        {
          breadcrumbName: title,
        },
      ]}
      leftSidebarBlocks={[
        {
          title: t('gin_program|only_email'),
          content: [
            {
              title: t('gin_program|template'),
              content: (
                <GinSidebarEmail
                  grant_id={params.id}
                  type="template"
                  open={activeTemplate}
                  onClose={setActiveTemplate}
                  email_items={adminGin?.templates}
                />
              ),
              extra: (
                <Button transparent onClick={onNewTemplates}>
                  <AddNew size={16} style={{ cursor: 'pointer' }} />
                </Button>
              ),
            },
            {
              title: t('gin_program|snippet'),
              content: (
                <GinSidebarEmail
                  grant_id={params.id}
                  type="snippet"
                  open={activeSnippet}
                  onClose={setActiveSnippet}
                  email_items={adminGin?.snippets}
                />
              ),
              extra: (
                <Button transparent onClick={onNewSnippet}>
                  <AddNew size={16} style={{ cursor: 'pointer' }} />
                </Button>
              ),
            },
          ],
        },
        {
          content: (
            <GinRelatedContacts
              grantId={params.id}
              open={isAddAgentVisible}
              onClose={onClose}
            />
          ),
          title: t('gin_program|mandated_agents'),
          extra: (
            <AddNew size={16} onClick={onOpen} style={{ cursor: 'pointer' }} />
          ),
        },
      ]}
      rightSidebarBlocks={[
        {
          content: <GinRelatedPrograms grantId={params.id} />,
          title: t('gin_program|applications'),
        },
      ]}
      defaultNavCollapsed
      noFooter
      fitContent
      underSearch={
        <Div flex="row" gap={16} align="center">
          <GrantChangeDifficulty grantId={params.id} />
          <GrantValidationButton grantId={params.id} filterByType="gin" />
        </Div>
      }
      handleBack={handleBack}
    >
      {!!params.id && (
        <GinSingleGrantPage grantId={params.id} sections={sections} />
      )}
    </SidebarPageLayout>
  );
};

export default SingleGinPage;
