import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const LineViewOutline = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99976 3.06667C1.99976 2.47756 2.44747 2 2.99976 2H28.9998C29.552 2 29.9998 2.47756 29.9998 3.06667V7.21213C29.9998 7.80123 29.552 8.27879 28.9998 8.27879H15.9998H2.99976C2.44747 8.27879 1.99976 7.80123 1.99976 7.21213V3.06667Z"
        strokeWidth={strokeWidth}
        stroke="currentColor"
      />
      <path
        d="M1.99976 13.9273C1.99976 13.3382 2.44747 12.8606 2.99976 12.8606H28.9998C29.552 12.8606 29.9998 13.3382 29.9998 13.9273V18.0727C29.9998 18.6618 29.552 19.1394 28.9998 19.1394H2.99976C2.44747 19.1394 1.99976 18.6618 1.99976 18.0727V13.9273Z"
        strokeWidth={strokeWidth}
        stroke="currentColor"
      />
      <path
        d="M1.99976 24.7879C1.99976 24.1988 2.44747 23.7212 2.99976 23.7212H28.9998C29.552 23.7212 29.9998 24.1988 29.9998 24.7879V28.9333C29.9998 29.5224 29.552 30 28.9998 30H2.99976C2.44747 30 1.99976 29.5224 1.99976 28.9333V24.7879Z"
        strokeWidth={strokeWidth}
        stroke="currentColor"
      />
    </svg>
  );
};

export default LineViewOutline;
