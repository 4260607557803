import styled from 'styled-components';

export const ErrorBoxContainer = styled.div`
  background: ${({ theme }) => theme.colors.white_2};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  animation: popup 0.7s;
  max-width: 800px;
  margin: 32px;
  @keyframes popup {
    0% {
      transform: scale(0);
    }

    60% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;
