import useForm from 'antd/es/form/hooks/useForm';
import { RcFile } from 'antd/es/upload/interface';
import { useEffect, useMemo, useState } from 'react';
import {
  AssetOwnerInformation,
  AssetRecordInformation,
  AssetWithOwnersEntity,
  UploadFormValues,
} from '../../../features/entities/assets-entities';
import useOnFileFinishUpload, {
  HandleSaveAssetFunction,
} from '../../../features/helpers/use-on-file-finish-upload';

import Attachment from '@hellodarwin/icons/dist/icons/Attachment';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import Upload from '@hellodarwin/icons/dist/icons/Upload';
import User from '@hellodarwin/icons/dist/icons/User';
import XOutlined from '@hellodarwin/icons/dist/icons/XOutlined';
import Form from 'antd/es/form/Form';
import Input from 'antd/es/input/Input';
import Select, { SelectProps } from 'antd/es/select';
import Dragger from 'antd/es/upload/Dragger';
import { useTheme } from 'styled-components';
import { getLongStringDate } from '../../../features/helpers/get-formatted-date';
import useLocale from '../../../features/providers/locale-provider';
import { useTranslations } from '../../../features/providers/translations-provider';
import Button from '../../common/button';
import CardInfoRowWithLabel from '../../common/Card/card-info-row-with-label';
import Div from '../../common/div';
import Typography from '../../common/typography';
import FormItem from '../../forms/form-layouts/form-item';
import FormLabel from '../../forms/form-layouts/form-label';

export interface AssetUploadFormProps {
  handleSaveAsset: HandleSaveAssetFunction;
  records: AssetRecordInformation[];
  owners?: AssetOwnerInformation[];
  asset?: AssetWithOwnersEntity;
  assetTypes?: SelectProps['options'];
  withCancel?: boolean;
  showVersion?: boolean;
}

const AssetUploadForm = ({
  handleSaveAsset,
  records,
  owners,
  asset,
  handleAfterFinish,
  assetTypes,
  withCancel,
  showVersion,
}: AssetUploadFormProps & { handleAfterFinish: () => void }) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const { t } = useTranslations();

  const [form] = useForm<UploadFormValues>();
  const [newAsset, setNewAsset] = useState<RcFile | undefined>(undefined);

  const onFinishUpload = useOnFileFinishUpload({
    handleSaveAsset,
    successMessage: 'Successfuly uploaded file',
    errorMessage: 'Something went wrong, try again later!',
  });

  const initialValues: UploadFormValues = useMemo(
    () => ({
      file_description: asset?.description ?? '',
      file_name: asset?.display_name ?? '',
      asset_id: asset?.asset_id ?? '',
      version: asset?.version ?? '',
    }),
    [asset],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const afterFinish = () => {
    form.resetFields();
    setNewAsset(undefined);
    onRemove();
    handleAfterFinish();
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinishUpload(newAsset, values, records, owners).then(() => {
          afterFinish();
        });
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const beforeUpload = (asset: RcFile): boolean => {
    setNewAsset(asset);

    return false;
  };

  const onRemove = () => {
    setNewAsset(undefined);
  };

  const fileName = useMemo(
    () => (!!newAsset ? newAsset.name : asset?.display_name),
    [asset, newAsset],
  );

  return (
    <Form layout="vertical" form={form} initialValues={initialValues}>
      <Div
        flex="column"
        gap={16}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <FormItem required name={'asset_id'} hidden>
          <Input />
        </FormItem>
        <FormItem
          name={'file_name'}
          label={t('assets|file_name')}
          rules={[
            {
              required: true,
              message: t('assets|file_name_required'),
            },
          ]}
          style={{ width: '100%' }}
        >
          <Input />
        </FormItem>
        <FormItem
          name={'file_description'}
          label={
            !!assetTypes ? t('assets|file_type') : t('assets|file_description')
          }
          style={{ width: '100%' }}
        >
          {!!assetTypes ? (
            <Select
              options={[
                {
                  label: t('assets|file_type_internal_process'),
                  value: 'InternalProcess',
                },
                {
                  label: t('assets|file_type_grant_document'),
                  value: 'GrantDoc',
                },
              ]}
            />
          ) : (
            <Input />
          )}
        </FormItem>

        {!!showVersion && (
          <FormItem
            name={'version'}
            label={t('assets|file_version')}
            style={{ width: '100%' }}
          >
            <Input />
          </FormItem>
        )}

        <FormItem
          required
          name={'asset'}
          label={
            <FormLabel
              label={t('assets|file_asset')}
              extra={
                !!fileName && (
                  <Div
                    flex="row"
                    gap={8}
                    align="center"
                    justify="space-between"
                    style={{
                      width: '100%',
                      paddingBottom: 2,
                      cursor: !!newAsset ? 'pointer' : undefined,
                    }}
                    borderColor={theme.colors.white_1}
                    borderColorHover={
                      !!newAsset ? theme.colors.grey_4 : undefined
                    }
                    bordersSide="bottom"
                    onClick={(e) => {
                      e.preventDefault();
                      onRemove();
                    }}
                  >
                    <Div flex="row" gap={8} align="center" fitContent>
                      <Attachment size={16} />
                      <Typography elementTheme="caption">{fileName}</Typography>
                    </Div>
                    {!!newAsset && (
                      <Button
                        size="square"
                        transparent
                        style={{ justifySelf: 'flex-end' }}
                      >
                        <XOutlined size={16} />
                      </Button>
                    )}
                  </Div>
                )
              }
            />
          }
          style={{ width: '100%' }}
        >
          <Dragger
            name="file"
            beforeUpload={(file) => beforeUpload(file)}
            onRemove={() => onRemove()}
            fileList={[]}
          >
            <Div flex="column" gap={8} align="center">
              <Upload />
              <Typography elementTheme="body2">
                {t('assets|file_asset_drag_and_drop')}
              </Typography>
            </Div>
          </Dragger>
        </FormItem>
      </Div>
      <Div
        flex="row"
        gap={8}
        align="center"
        justify="flex-end"
        style={{ marginTop: 32 }}
      >
        {withCancel && (
          <Button
            size="small"
            defaultStyle={theme.colors.white_1}
            onClick={afterFinish}
          >
            {t('button|cancel')}
          </Button>
        )}
        <Button
          size="small"
          defaultStyle={theme.colors.primary}
          onClick={handleSubmit}
        >
          {t('button|save')}
        </Button>
      </Div>
      {(!!asset?.created_at ||
        !!asset?.uploaded_at ||
        !!asset?.last_uploaded_by) && (
        <Div
          style={{ padding: '12px 0', marginTop: 32 }}
          gap={12}
          flex="column"
          borderColor={theme.colors.grey_4}
          bordersSide="horizontal"
        >
          {!!asset?.created_at && (
            <CardInfoRowWithLabel
              Icon={Timeline}
              label={t('assets_upload|created_on')}
              value={getLongStringDate(asset.created_at, selectedLocale)}
            />
          )}
          {!!asset?.uploaded_at && (
            <CardInfoRowWithLabel
              Icon={Timeline}
              label={t('assets_upload|uploaded_on')}
              value={getLongStringDate(asset.uploaded_at, selectedLocale)}
            />
          )}
          {!!asset?.last_uploaded_by && (
            <CardInfoRowWithLabel
              Icon={User}
              label={t('assets_upload|uploaded_by')}
              value={asset.last_uploaded_by.owner_name}
            />
          )}
        </Div>
      )}
    </Form>
  );
};

export default AssetUploadForm;
