import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Step, StepAsset } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import Empty from 'antd/es/empty';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  clearSteps,
  fetchGinsSteps,
  fetchProgramStep,
  selectAllSteps,
  selectStepsIsLoading,
} from '../../../../features/api/slices/gin-steps-slice';
import {
  useAdminApi,
  useNewAdminApi,
} from '../../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../gin-block';
import GinStepCard from '../gin-step-card';
import GinStepsDrawer from './gin-steps-drawer';

const GinStepsComponent = ({
  entityId,
  section,
  isEditing,
  handleEditing,
  isToggle,
  type,
}: GinBlockComponentProps) => {
  const api = useAdminApi();
  const newApi = useNewAdminApi();
  const dispatch = useAppDispatch();
  const steps = useAppSelector((state) => selectAllSteps(state));
  const isLoading = useAppSelector(selectStepsIsLoading);
  const { t } = useTranslations();
  const [activeStep, setActiveStep] = useState<Step | undefined>();
  const [activeAsset, setActiveAsset] = useState<StepAsset>();

  useEffect(() => {
    if (entityId) {
      dispatch(clearSteps());
      type === 'application'
        ? dispatch(fetchProgramStep({ api: newApi, program_id: entityId }))
        : dispatch(fetchGinsSteps({ api, grant_id: entityId }));
    }
    return () => {
      dispatch(clearSteps());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, dispatch]);

  const openStepForm = (step: Step | undefined) => {
    !!handleEditing && handleEditing();
    setActiveStep(step);
  };
  const openStepAssetForm = (asset: StepAsset) => {
    setActiveAsset(asset);
  };
  if (isLoading) return <Loading />;

  return (
    <Div flex="column" gap={10}>
      {!!steps.length ? (
        React.Children.toArray(
          steps.map((step, index) => (
            <GinStepCard
              {...step}
              index={index}
              entityId={entityId || ''}
              isClientView={isToggle}
              pageType={type}
              openStepForm={openStepForm}
              openStepAssetForm={openStepAssetForm}
            />
          )),
        )
      ) : (
        <Empty
          description={
            <Typography elementTheme="subtitle1">
              {t('gin_section|empty_step')}
            </Typography>
          }
        />
      )}
      <GinStepsDrawer
        grantId={entityId}
        steps={steps}
        section={section}
        activeStep={activeStep}
        open={isEditing}
        onClose={() => openStepForm(undefined)}
        setActiveStep={setActiveStep}
        setActiveAsset={setActiveAsset}
        activeAsset={activeAsset}
      />
    </Div>
  );
};

export default GinStepsComponent;
