import Modal from 'antd/es/modal';
export const showTagsWarning = (forProvider?: boolean) => {
  if (forProvider) {
    return Modal.warning({
      title: 'Add provider service tags',
      content: `Please add service tags before verifying this provider. Without tags the provider will not get matched with projects.`,
    });
  } else {
    return Modal.warning({
      title: 'Add project service tags',
      content: `Please add service tags before activating this project. Without it the matching algorithm will not work properly.`,
    });
  }
};
