import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';

const MinusCircleOutline = (props: IconProps) => {
  const iconProps = useIconProps(props);
  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 0C7.16308 0 0 7.16308 0 16C0 24.8369 7.16308 32 16 32C24.8369 32 32 24.8369 32 16C32 7.16308 24.8369 0 16 0ZM20.9231 17.2308C21.2495 17.2308 21.5625 17.1011 21.7934 16.8703C22.0242 16.6395 22.1538 16.3264 22.1538 16C22.1538 15.6736 22.0242 15.3605 21.7934 15.1297C21.5625 14.8989 21.2495 14.7692 20.9231 14.7692H11.0769C10.7505 14.7692 10.4375 14.8989 10.2066 15.1297C9.97582 15.3605 9.84615 15.6736 9.84615 16C9.84615 16.3264 9.97582 16.6395 10.2066 16.8703C10.4375 17.1011 10.7505 17.2308 11.0769 17.2308H20.9231Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MinusCircleOutline;
