/* eslint-disable react-hooks/exhaustive-deps */
import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Contact } from '../../../features/entities';
import SingleLanguage from '../../../features/enums/single-language';
import handleDispatchResponse from '../../../features/helpers/handle-dispatch-response';
import { useTranslation } from '../../../plugins/i18n';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Modal from '../../common/hd-modal';
import { ModalConfirm } from '../../common/hd-modal/confirm';
import Typography from '../../common/typography';
import FormItem from '../../forms/form-layouts/form-item';
import FormLabel from '../../forms/form-layouts/form-label';
import { UserFormContainer, UserFormRow } from './styles';

export const EMAIL_ALREADY_EXISTS_ERROR = 5023;

interface UserFormModalProps {
  open: boolean;
  handleEditUser?: (contact: Contact) => Promise<any>;
  handleContactAdd?: (contact: Contact) => Promise<any>;
  handleContactAssign: (contact: Contact) => Promise<any>;
  handleEmailExists: (email: string) => Promise<any>;
  handleClose: () => void;
  contact: Contact | null;
  companyId: string;
  showPhone?: boolean;
  isLoading?: boolean;
}

interface ContactFormValues {
  name: string;
  email: string;
  title: string;
  phone: string;
  ext: string;
  preferred_language: SingleLanguage;
  gender: string;
}

interface FormProps {
  form: FormInstance<ContactFormValues>;
  initialValues: ContactFormValues;
  handleClose: () => void;

  isLoading?: boolean;
}
interface EditionFormProps extends FormProps {
  handleSubmit: () => void;
  showPhone?: boolean;
  isNew?: boolean;
}
interface AddFormProps extends FormProps {
  handleNext: () => void;
}

const AddForm = ({
  form,
  initialValues,
  handleNext,
  handleClose,
  isLoading,
}: AddFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleNext}
    >
      <Div flex="column" gap={32}>
        <Typography.Title level={2} elementTheme="h4">
          {t('profile|profileForm.users.form.addTitle')}
        </Typography.Title>
        <FormItem
          style={{ flex: 3 }}
          label={
            <FormLabel
              label={t('profile|profileForm.users.form.labels.email')}
              extra={t('profile|profileForm.users.form.extra.email')}
            />
          }
          name="email"
          rules={[
            {
              required: true,
              message: t('profile|profileForm.users.form.validation.email'),
            },
            {
              type: 'email',
              message: t(
                'profile|profileForm.users.form.validation.invalidEmail',
              ),
            },
          ]}
        >
          <Input
            type="email"
            placeholder={t('profile|profileForm.users.form.placeholder.email')}
            size="large"
          />
        </FormItem>
        <Div flex="row" gap={16} align="center" justify="flex-end">
          <Button
            defaultStyle={theme.colors.white_1}
            onClick={handleClose}
            size="small"
            disabled={isLoading}
          >
            {t('button.cancel')}
          </Button>
          <Button onClick={handleNext} size="small" loading={isLoading}>
            {t('button.next')}
          </Button>
        </Div>
      </Div>
    </Form>
  );
};

const EditionForm = ({
  form,
  initialValues,
  handleSubmit,
  handleClose,
  showPhone,
  isLoading,
  isNew,
}: EditionFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const languageOptions: {
    value: string;
    label: string | ReactNode;
  }[] = [
    {
      value: SingleLanguage.French,
      label: t(`language.${SingleLanguage.French}`),
    },
    {
      value: SingleLanguage.English,
      label: t(`language.${SingleLanguage.English}`),
    },
  ];
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <Div flex="column" gap={37}>
        <Div flex="column" gap={32}>
          <Typography.Title level={2} elementTheme="h4">
            {isNew
              ? t('profile|profileForm.users.form.addTitle')
              : t('profile|profileForm.users.form.editTitle')}
          </Typography.Title>
          <UserFormContainer>
            <FormItem
              label={
                <FormLabel
                  label={t('profile|profileForm.users.form.labels.name')}
                />
              }
              name="name"
              rules={[
                {
                  required: true,
                  message: t('profile|profileForm.users.form.validation.name'),
                },
              ]}
            >
              <Input
                placeholder={t(
                  'profile|profileForm.users.form.placeholder.name',
                )}
                size="large"
              />
            </FormItem>

            <UserFormRow
              style={{ display: !isNew || showPhone ? 'block' : 'none' }}
            >
              <FormItem
                style={{ flex: 3, display: !isNew ? 'block' : 'none' }}
                label={
                  <FormLabel
                    label={t('profile|profileForm.users.form.labels.email')}
                    extra={t('profile|profileForm.users.form.extra.email')}
                  />
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: t(
                      'profile|profileForm.users.form.validation.email',
                    ),
                  },
                  {
                    type: 'email',
                    message: t(
                      'profile|profileForm.users.form.validation.invalidEmail',
                    ),
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder={t(
                    'profile|profileForm.users.form.placeholder.email',
                  )}
                  size="large"
                  disabled
                />
              </FormItem>

              {showPhone && (
                <Div flex="row" gap={8} style={{ flex: 2 }} align="flex-end">
                  <Form.Item
                    label={t('profile|profileForm.users.form.labels.phone')}
                    name="phone"
                    style={{ flex: 3 }}
                  >
                    <Input
                      type="phone"
                      placeholder={t(
                        'profile|profileForm.users.form.placeholder.phone',
                      )}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item name="ext" style={{ flex: 1 }}>
                    <Input
                      name="ext"
                      placeholder={t(
                        'profile|profileForm.users.form.placeholder.ext',
                      )}
                      size="large"
                    />
                  </Form.Item>
                </Div>
              )}
            </UserFormRow>

            <UserFormRow>
              <FormItem
                label={
                  <FormLabel
                    label={t('profile|profileForm.users.form.labels.title')}
                  />
                }
                name="title"
                style={{ flex: 3 }}
              >
                <Input
                  placeholder={t(
                    'profile|profileForm.users.form.placeholder.title',
                  )}
                  size="large"
                />
              </FormItem>

              <FormItem
                label={
                  <FormLabel
                    label={t(
                      'profile|profileForm.users.form.labels.preferredLanguage',
                    )}
                    extra={t(
                      'profile|profileForm.users.form.extra.preferredLanguage',
                    )}
                  />
                }
                name="preferred_language"
                style={{ flex: 2 }}
              >
                <Select
                  options={languageOptions}
                  size="large"
                  placeholder={t(
                    'profile|profileForm.users.form.placeholder.preferredLanguage',
                  )}
                />
              </FormItem>
            </UserFormRow>
          </UserFormContainer>
        </Div>
        <Div flex="row" gap={16} align="center" justify="flex-end">
          <Button
            defaultStyle={theme.colors.white_1}
            onClick={handleClose}
            size="small"
            disabled={isLoading}
          >
            {t('button.cancel')}
          </Button>
          <Button onClick={handleSubmit} size="small" loading={isLoading}>
            {t('button.save')}
          </Button>
        </Div>
      </Div>
    </Form>
  );
};

const UserForm = ({
  open,
  handleEditUser,
  handleClose,
  contact,
  companyId,
  showPhone,
  handleContactAdd,
  handleContactAssign,
  handleEmailExists,
  isLoading,
}: UserFormModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isNew, setIsNew] = useState(!contact?.contact_id);
  const [form] = Form.useForm<ContactFormValues>();

  const initialValues: ContactFormValues = useMemo(() => {
    return {
      name: contact?.name || '',
      email: contact?.email || '',
      title: contact?.title || '',
      phone: contact?.phone || '',
      ext: contact?.ext || '',
      preferred_language: contact?.preferred_language || SingleLanguage.English,
      gender: contact?.gender || '',
    };
  }, [contact]);

  const handleReset = () => {
    setIsNew(false);
    form.resetFields();
    form.setFieldsValue(initialValues);
  };

  useEffect(() => {
    if (!open) {
      setIsNew(!contact?.contact_id);
      form.resetFields();
    }
  }, [open]);

  useEffect(() => {
    handleReset();
  }, [form, initialValues]);

  useEffect(() => {
    setIsNew(!contact?.contact_id);
  }, [contact]);

  const handleAssign = (contact: Contact) => {
    try {
      handleContactAssign(contact).then((res) => {
        handleDispatchResponse(
          res,
          t('notification.saved'),
          t('notification.saving_error'),
        );
        handleClose();
      });
    } catch (error) {}
  };

  const handleNext = () => {
    handleEmailExists(form.getFieldValue('email'))
      .then((res: any) => {
        if (res && res.payload && res.payload.contact_id !== '') {
          ModalConfirm({
            title: t('profile|profileForm.users.assign.title'),
            content: t('profile|profileForm.users.assign.subtitle'),
            okText: t('profile|assetsModal.confirm'),
            cancelText: t('profile|assetsModal.cancel'),

            okButtonProps: {
              style: {
                backgroundColor: theme.colors.purple_1,
                color: theme.colors.white_1,
                borderRadius: '24px',
              },
            },
            cancelButtonProps: {
              style: {
                backgroundColor: theme.colors.white_1,
                borderColor: theme.colors.grey_1,
                color: theme.colors.grey_1,
                borderRadius: '24px',
              },
            },
            onOk: async () => {
              handleAssign(res.payload);
              form.setFieldsValue(res.payload);
            },
            onCancel: async () => {
              handleClose();
            },
          });
        } else {
          setIsNew(false);
        }
      })
      .catch((error: any) => {
        if (error == 'Email already exists') {
          form.setFields([
            {
              name: 'email',
              errors: [
                t('profile|profileForm.users.form.validation.emailExists'),
              ],
            },
          ]);
        }
      });
  };

  const handleSubmit = () => {
    try {
      form.validateFields().then((values) => {
        if (!!contact?.contact_id.length) {
          !!handleEditUser &&
            handleEditUser({
              ...values,
              company_id: companyId,
              contact_id: contact.contact_id,
            }).then((res) => {
              handleDispatchResponse(
                res,
                t('notification.saved'),
                t('notification.saving_error'),
              );
              handleClose();
            });
        } else if (!!handleContactAdd) {
          const newContact = {
            ...values,
            company_id: companyId,
          } as Contact;
          handleContactAdd(newContact).then((res) => {
            handleDispatchResponse(
              res,
              t('notification.saved'),
              t('notification.saving_error'),
            );
            handleClose();
          });
        }
      });
    } catch (error) {}
  };

  return isNew ? (
    <AddForm
      initialValues={initialValues}
      form={form}
      isLoading={isLoading}
      handleClose={handleClose}
      handleNext={handleNext}
    />
  ) : (
    <EditionForm
      isNew={!contact?.contact_id}
      initialValues={initialValues}
      form={form}
      isLoading={isLoading}
      showPhone={showPhone}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};
const UserFormModal = (props: UserFormModalProps) => {
  const { open, handleClose } = props;

  return (
    <Modal
      open={open}
      handleCancel={handleClose}
      size="large"
      styles={{ content: { padding: '56px 72px' } }}
    >
      <UserForm {...props} />
    </Modal>
  );
};

export default UserFormModal;
