import RatingFill from '@hellodarwin/icons/dist/icons/RatingFill';
import RatingHalf from '@hellodarwin/icons/dist/icons/RatingHalf';
import RatingOutline from '@hellodarwin/icons/dist/icons/RatingOutline';
import { StarProps } from 'rc-rate/lib/Star';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { ReviewRatingsProps } from '.';

const useRatingIcon = (props: ReviewRatingsProps) => {
  const theme = useTheme();

  const iconSize = useMemo(
    () =>
      props.size === 'large'
        ? 32
        : props.size === 'small'
          ? 16
          : props.size === 'extra-large'
            ? 46
            : props.size === 'extra-small'
              ? 12
              : 24,
    [props],
  );
  return React.useCallback(
    (starProps?: StarProps) => {
      const { value, showOne, allowHalf } = props;

      if (showOne) {
        if (value > 3) {
          return (
            <RatingFill size={iconSize} color={theme.colors.yellow_star} />
          );
        } else if (value < 1.5) {
          return (
            <RatingOutline size={iconSize} color={theme.colors.yellow_star} />
          );
        } else {
          return (
            <RatingHalf size={iconSize} color={theme.colors.yellow_star} />
          );
        }
      } else {
        const index = (starProps?.index ?? 0) + 1;
        const value = starProps?.value ?? 0;
        if (value >= index) {
          return (
            <RatingFill size={iconSize} color={theme.colors.yellow_star} />
          );
        } else if (value >= index - 0.5 && allowHalf) {
          return (
            <RatingHalf size={iconSize} color={theme.colors.yellow_star} />
          );
        } else {
          return <RatingOutline size={iconSize} color={theme.colors.grey_4} />;
        }
      }
    },
    [props, iconSize],
  );
};

export default useRatingIcon;
