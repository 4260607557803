import styled from 'styled-components';

export const BusinessProfileList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  & > * {
    width: 450px;
  }
`;
