import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import { useTheme } from 'styled-components';
import { useTranslations } from '../../features/providers/translations-provider';
import Button from '../common/button';
import Div from '../common/div';
import Typography from '../common/typography';
import { ErrorBoxContainer } from './styles';

interface ErrorBoxProps {
  errorMessage: string;
  ErrorIcon: IconNode;
  CtaIcon: IconNode;
  ctaAction: () => void;
}
const ErrorBox = ({
  errorMessage,
  ErrorIcon,
  ctaAction,
  CtaIcon,
}: ErrorBoxProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  return (
    <ErrorBoxContainer>
      <div>
        <Div
          style={{ padding: '8px 24px' }}
          flex="row"
          align="center"
          justify="space-between"
          borderColor={theme.colors.grey_3}
          bordersSide="bottom"
        >
          <HdLogo backgroundType="light" width={200} />
          <Button
            fitContent
            size="circle"
            onClick={ctaAction}
            style={{ padding: 12 }}
            defaultStyle={theme.colors.grey_1}
            headingIcon={<CtaIcon size={24} />}
          />
        </Div>
        <Div flex="column" gap={24} align="center" style={{ padding: 32 }}>
          <ErrorIcon size={96} />

          <Div flex="column" gap={12} align="center">
            <Typography.Title
              level={4}
              color={theme.colors.primary}
              textAlign="center"
            >
              {t('errorsPage|problemPageTitle')}
            </Typography.Title>
            <Typography textAlign="center">{errorMessage}</Typography>
          </Div>
        </Div>
      </div>
    </ErrorBoxContainer>
  );
};
export default ErrorBox;
