import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const XCircle = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.665 12.665L19.775 19.775M19.775 12.665L16.22 16.22L12.665 19.775M30.44 16.22C30.44 18.0874 30.0722 19.9365 29.3576 21.6618C28.6429 23.387 27.5955 24.9546 26.2751 26.2751C24.9546 27.5955 23.387 28.6429 21.6618 29.3576C19.9365 30.0722 18.0874 30.44 16.22 30.44C14.3526 30.44 12.5035 30.0722 10.7782 29.3576C9.05299 28.6429 7.48539 27.5955 6.16494 26.2751C4.84449 24.9546 3.79705 23.387 3.08243 21.6618C2.36781 19.9365 2 18.0874 2 16.22C2 12.4486 3.49817 8.83171 6.16494 6.16494C8.83171 3.49817 12.4486 2 16.22 2C19.9914 2 23.6083 3.49817 26.2751 6.16494C28.9418 8.83171 30.44 12.4486 30.44 16.22Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default XCircle;
