import Table, { TableProps } from 'antd/es/table';
import { ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { createFontSizeCSS } from '../../../theme/global-style';
import { ElementTheme } from '../../common/typography/entities';
import { getLineClampStyling } from '../../common/typography/styles';

type TableNode = <T>(table: TableProps<T>) => ReactNode;

interface LevelTheme {
  borderColor: string;
  scrollbarThumbColor: string;
  headerColor: string;
  headerTextColor: string;
  hoverColor: string;
  elementTheme: ElementTheme;
}

const createProjectTableLevelStyle = (level: number, theme: DefaultTheme) => {
  const LevelsThemes: LevelTheme[] = [
    {
      borderColor: theme.colors.grey_1,
      headerColor: theme.colors.grey_2,
      headerTextColor: theme.colors.white_1,
      hoverColor: theme.colors.grey_1 + '20',
      elementTheme: 'body2',
      scrollbarThumbColor: theme.colors.grey_2,
    },

    {
      borderColor: theme.colors.grey_1,
      headerColor: theme.colors.purple_2,
      headerTextColor: theme.colors.white_1,
      hoverColor: theme.colors.purple_4 + '70',
      elementTheme: 'body3',
      scrollbarThumbColor: theme.colors.purple_1,
    },
    {
      borderColor: theme.colors.grey_1,
      headerColor: theme.colors.yellow_1,
      headerTextColor: theme.colors.grey_1,
      hoverColor: theme.colors.yellow_4,
      elementTheme: 'body1',
      scrollbarThumbColor: theme.colors.yellow_1,
    },
  ];
  const levelTheme = LevelsThemes[level] ?? LevelsThemes[0];
  return css`
    .ant-table-body {
      scrollbar-width: auto;
      scrollbar-color: auto;
    }

    & > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
      border-radius: 0;
      border: 1px solid ${levelTheme.borderColor};

      & > .ant-table-container > .ant-table-content {
        &::-webkit-scrollbar {
          height: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: ${levelTheme.scrollbarThumbColor}AA;
          transition: background 400ms ease;
          border-radius: 99px;

          cursor: pointer;
        }
        & > table {
          & > .ant-table-thead {
            & > tr {
              & > th {
                background: ${levelTheme.headerColor};
                border-bottom: 1px solid ${levelTheme.borderColor};
                border-right: 1px solid ${levelTheme.borderColor};
                color: ${levelTheme.headerTextColor};
                &:last-child {
                  border-right: none;
                }
              }
            }
          }
          & > .ant-table-tbody {
            ${createFontSizeCSS(levelTheme.elementTheme)}
            & > .ant-table-expanded-row {
              & > td {
                border-bottom: 1px solid ${levelTheme.borderColor};
              }
            }
            & > .ant-table-row-level-0 {
              cursor: pointer;

              & > td {
                background: ${theme.colors.white_1};

                border-bottom: 1px solid ${levelTheme.borderColor};
                border-right: 1px solid ${levelTheme.borderColor};

                &:last-child {
                  border-right: none;
                }
              }
              &:last-child {
                & > td {
                  border-bottom: none;
                }
              }
              background: ${theme.colors.white_1};

              &:last-child {
                & > td {
                  border-bottom: none;
                }
              }
              & > td.ant-table-cell-row-hover {
                background: ${levelTheme.hoverColor};
              }
            }
          }
        }
      }
    }
  `;
};

export const ProjectsTableLevel1 = styled(Table)`
  .ant-table {
    .ant-table-cell-fix-right {
      backdrop-filter: blur(4px);
      background: ${({ theme }) => theme.colors.white_1};
    }
    margin: 0 !important;
    .ant-table-container,
    table,
    .ant-table-thead,
    .ant-table-container table .ant-table-thead > tr > th {
      border-radius: 0;

      &.ant-table-row-expand-icon-cell {
        padding: 0 16px;
      }
    }
    .ant-table-tbody {
      & > .ant-table-row {
        height: 80px;

        & > td {
          padding: 12px 24px;

          &.ant-table-row-expand-icon-cell {
            padding: 0 16px;
          }
          &.actions-column {
            padding: 0;
            & > * {
              margin: auto;
            }
          }
        }
      }
    }
    .ant-table-expanded-row-fixed {
      padding: 16px 0;
    }
    .ant-table-expanded-row {
      & > .ant-table-cell {
        padding: 0;
      }
      &:last-child {
        & > td.ant-table-cell {
          border-bottom: none;
          &
            > .ant-table-expanded-row-fixed
            > .ant-table-wrapper
            > .ant-spin-nested-loading
            > .ant-spin-container
            > .ant-table {
            background: transparent;
            padding-bottom: 0;
            border-bottom: none;
            & > .ant-table-container > .ant-table-content > table {
              border-bottom: none;
            }
          }
        }
      }
    }
    .ant-table-thead {
      border-radius: 0;
      & > tr {
        & > th {
          &:before {
            display: none;
          }
          border-radius: 0;
          padding: 10px 24px;
          ${createFontSizeCSS('overline')}
          font-weight:500;
          text-transform: uppercase;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  .ant-table-content {
    scrollbar-width: auto;
    scrollbar-color: auto;
    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.grey_5};
    }
  }

  ${({ theme }) => createProjectTableLevelStyle(0, theme)}
` as TableNode;

export const ProjectsTableLevel2 = styled(Table)`
  ${({ theme }) => createProjectTableLevelStyle(1, theme)}
` as TableNode;

export const ProjectsTableLevel3 = styled(Table)`
  ${({ theme }) => createProjectTableLevelStyle(2, theme)}
` as TableNode;

export const MainProjectHeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;
export const MainProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LineClampContent = styled.div`
  ${getLineClampStyling(2)}
`;
