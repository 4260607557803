import Divider from 'antd/es/divider';
import { AppType } from '../../../features/entities';
import { handleExternalLinkCLick } from '../../../features/helpers/get-website-url';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import { DefaultTheme } from '../../../theme';
import Div from '../../common/div';
import Typography from '../../common/typography';
import { SocialContainer, SocialIcon } from './styles';

export type SocialAlignement = 'left' | 'right';
interface SocialsProps {
  minimize?: boolean;
  app?: AppType;
  withoutBorder?: boolean;
  align?: SocialAlignement;
}
const Socials = ({
  minimize,
  app,
  withoutBorder,
  align = 'left',
}: SocialsProps) => {
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;
  return (
    <Div flex="column" gap={24} style={{ padding: 8 }}>
      {!withoutBorder && (
        <Divider style={{ backgroundColor: theme.colors.grey_5, margin: 0 }} />
      )}
      <SocialContainer $app={app} $isOpen={!minimize} $align={align}>
        {!minimize && (
          <Typography
            elementTheme="overline"
            textTransform="uppercase"
            medium
            color={theme.colors.grey_3}
            style={{ letterSpacing: 0.2, marginBottom: 6, fontSize: 10 }}
          >
            {t('navigation|followUs')}
          </Typography>
        )}

        <Div flex="row" gap={8} fitContent wrap="wrap">
          <SocialIcon
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              handleExternalLinkCLick(
                'https://www.linkedin.com/company/hellodarwin',
              )
            }
            className="linkedin"
          >
            <path
              d="M3.73059 3.0661C3.73039 3.45471 3.57583 3.82733 3.3009 4.10199C3.02597 4.37664 2.6532 4.53083 2.26459 4.53064C1.87597 4.53044 1.50335 4.37588 1.2287 4.10095C0.954044 3.82602 0.799855 3.45325 0.800049 3.06463C0.800243 2.67602 0.954806 2.3034 1.22973 2.02875C1.50466 1.75409 1.87744 1.5999 2.26605 1.6001C2.65466 1.60029 3.02728 1.75485 3.30194 2.02978C3.57659 2.30471 3.73078 2.67749 3.73059 3.0661ZM3.77455 5.61567H0.844007V14.7883H3.77455V5.61567ZM8.4048 5.61567H5.48891V14.7883H8.37549V9.97485C8.37549 7.2934 11.8702 7.04431 11.8702 9.97485V14.7883H14.7641V8.97846C14.7641 4.45811 9.59167 4.62661 8.37549 6.8465L8.4048 5.61567Z"
              fill="currentColor"
            />
          </SocialIcon>
          <SocialIcon
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              handleExternalLinkCLick(
                'https://www.facebook.com/match.hellodarwin',
              )
            }
            className="facebook"
          >
            <path
              d="M5.75539 14.7201H8.68593V8.8517H11.3263L11.6165 5.93582H8.68593V4.46322C8.68593 4.26892 8.76312 4.08257 8.90052 3.94517C9.03791 3.80778 9.22426 3.73059 9.41857 3.73059H11.6165V0.800049H9.41857C8.44703 0.800049 7.51529 1.18599 6.82831 1.87297C6.14133 2.55995 5.75539 3.49169 5.75539 4.46322V5.93582H4.29012L4 8.8517H5.75539V14.7201Z"
              fill="currentColor"
            />
          </SocialIcon>
          <SocialIcon
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="instagram"
            onClick={() =>
              handleExternalLinkCLick(
                'https://www.instagram.com/hellodarwin.match/',
              )
            }
          >
            <g clipPath="url(#clip0_1115_266)">
              <path
                d="M5.04933 0.800049H11.2035C13.5479 0.800049 15.4527 2.7049 15.4527 5.04933V11.2035C15.4527 12.3304 15.0051 13.4113 14.2082 14.2082C13.4113 15.0051 12.3304 15.4527 11.2035 15.4527H5.04933C2.7049 15.4527 0.800049 13.5479 0.800049 11.2035V5.04933C0.800049 3.92235 1.24774 2.84153 2.04463 2.04463C2.84153 1.24774 3.92235 0.800049 5.04933 0.800049ZM4.9028 2.26532C4.2033 2.26532 3.53244 2.5432 3.03782 3.03782C2.5432 3.53244 2.26532 4.2033 2.26532 4.9028V11.35C2.26532 12.8079 3.44486 13.9875 4.9028 13.9875H11.35C12.0495 13.9875 12.7203 13.7096 13.215 13.215C13.7096 12.7203 13.9875 12.0495 13.9875 11.35V4.9028C13.9875 3.44486 12.8079 2.26532 11.35 2.26532H4.9028ZM11.9727 3.36427C12.2156 3.36427 12.4485 3.46076 12.6203 3.6325C12.792 3.80424 12.8885 4.03718 12.8885 4.28006C12.8885 4.52295 12.792 4.75588 12.6203 4.92763C12.4485 5.09937 12.2156 5.19586 11.9727 5.19586C11.7298 5.19586 11.4969 5.09937 11.3252 4.92763C11.1534 4.75588 11.0569 4.52295 11.0569 4.28006C11.0569 4.03718 11.1534 3.80424 11.3252 3.6325C11.4969 3.46076 11.7298 3.36427 11.9727 3.36427ZM8.1264 4.46322C9.09793 4.46322 10.0297 4.84916 10.7167 5.53614C11.4036 6.22312 11.7896 7.15486 11.7896 8.1264C11.7896 9.09793 11.4036 10.0297 10.7167 10.7167C10.0297 11.4036 9.09793 11.7896 8.1264 11.7896C7.15486 11.7896 6.22312 11.4036 5.53614 10.7167C4.84916 10.0297 4.46322 9.09793 4.46322 8.1264C4.46322 7.15486 4.84916 6.22312 5.53614 5.53614C6.22312 4.84916 7.15486 4.46322 8.1264 4.46322ZM8.1264 5.92849C7.54348 5.92849 6.98443 6.16006 6.57224 6.57224C6.16006 6.98443 5.92849 7.54348 5.92849 8.1264C5.92849 8.70932 6.16006 9.26836 6.57224 9.68055C6.98443 10.0927 7.54348 10.3243 8.1264 10.3243C8.70932 10.3243 9.26836 10.0927 9.68055 9.68055C10.0927 9.26836 10.3243 8.70932 10.3243 8.1264C10.3243 7.54348 10.0927 6.98443 9.68055 6.57224C9.26836 6.16006 8.70932 5.92849 8.1264 5.92849Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_1115_266">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </SocialIcon>
          <SocialIcon
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="youtube"
            onClick={() =>
              handleExternalLinkCLick(
                'https://www.youtube.com/@hellodarwin8652',
              )
            }
          >
            <path
              d="M8.30516 2.3999C8.69639 2.4021 9.67519 2.41162 10.7155 2.45338L11.0848 2.4695C12.1317 2.51859 13.1779 2.60357 13.6974 2.7479C14.3897 2.94278 14.9333 3.50984 15.1172 4.22929C15.4103 5.3722 15.4469 7.60088 15.4513 8.14083L15.452 8.25219V8.37967C15.4469 8.91962 15.4103 11.149 15.1172 12.2912C14.9311 13.0128 14.3868 13.5806 13.6974 13.7726C13.1779 13.9169 12.1317 14.0019 11.0848 14.051L10.7155 14.0678C9.67519 14.1089 8.69639 14.1191 8.30516 14.1206L8.13299 14.1213H7.94617C7.11829 14.1162 3.65586 14.0788 2.55398 13.7726C1.86237 13.5777 1.31802 13.0107 1.13413 12.2912C0.841076 11.1483 0.804445 8.91962 0.800049 8.37967V8.14083C0.804445 7.60088 0.841076 5.37147 1.13413 4.22929C1.32022 3.50765 1.86457 2.93985 2.55471 2.74864C3.65586 2.44166 7.11902 2.4043 7.9469 2.3999H8.30516ZM6.66039 5.69676V10.8252L11.0562 8.26098L6.66039 5.69676Z"
              fill="currentColor"
            />
          </SocialIcon>
          <SocialIcon
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="tiktok"
            onClick={() =>
              handleExternalLinkCLick(
                'https://www.tiktok.com/@hellodarwin.match',
              )
            }
          >
            <path
              d="M11.0668 3.88C10.6111 3.35975 10.3599 2.69162 10.3601 2H8.3001V10.2667C8.28422 10.714 8.09535 11.1377 7.77328 11.4486C7.45121 11.7595 7.02107 11.9333 6.57344 11.9333C5.62677 11.9333 4.8401 11.16 4.8401 10.2C4.8401 9.05333 5.94677 8.19333 7.08677 8.54667V6.44C4.78677 6.13333 2.77344 7.92 2.77344 10.2C2.77344 12.42 4.61344 14 6.56677 14C8.6601 14 10.3601 12.3 10.3601 10.2V6.00667C11.1954 6.60657 12.1983 6.92843 13.2268 6.92667V4.86667C13.2268 4.86667 11.9734 4.92667 11.0668 3.88Z"
              fill="currentColor"
            />
          </SocialIcon>
        </Div>
      </SocialContainer>
    </Div>
  );
};

export default Socials;
