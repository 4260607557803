import {
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities/api-entitites';
import { SearchResults } from '@hellodarwin/core/lib/features/entities/core-entities';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

// Define the state interface
export interface GlobalSearchState {
  status: 'idle' | 'pending';
  error: ApiError | null;
  results: SearchResults | null;
  activeTab: string;
  modal: {
    isVisible: boolean;
    type: string;
  };
}

const initialState: GlobalSearchState = {
  status: 'idle',
  error: null,
  results: null,
  activeTab: '',
  modal: {
    isVisible: false,
    type: '',
  },
};

export const fetchGlobalSearchResults = createAsyncThunk<
  SearchResults,
  { api: AdminApiClient; query: string; locale: string },
  { rejectValue: ApiError; state: RootState }
>(
  'admin/fetchGlobalSearchResults',
  async ({ api, query, locale }, { rejectWithValue }) => {
    try {
      return await api.globalSearch(query, locale);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalSearchResults.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchGlobalSearchResults.fulfilled,
      (state, { payload }) => {
        state.status = 'idle';
        state.results = payload;
      },
    );
    builder.addCase(fetchGlobalSearchResults.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
  },
});

export const selectGlobalSearchResults = (state: RootState) =>
  state.globalSearch.results;

export const selectGlobalSearchLoading = (state: RootState) =>
  state.globalSearch.status === 'pending';

export const globalSearchReducer = globalSearchSlice.reducer;
