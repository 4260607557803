import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Minimize = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8283 10.8283V3.0707M10.8283 10.8283H3.0707M10.8283 10.8283L1.77777 1.77777M10.8283 21.1717V28.9293M10.8283 21.1717H3.0707M10.8283 21.1717L1.77777 30.2222M21.1717 10.8283H28.9293M21.1717 10.8283V3.0707M21.1717 10.8283L30.2222 1.77777M21.1717 21.1717H28.9293M21.1717 21.1717V28.9293M21.1717 21.1717L30.2222 30.2222"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Minimize;
