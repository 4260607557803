import Select from 'antd/es/select';
import React, { ReactNode } from 'react';
import { ProfileEditModalFunctions } from '..';
import { MappedTags } from '../../../../../features/entities';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import CategorySelect from './category-select';
import ServiceBudgetSelectionRow from './service-budget-selection-row';

interface ExpertiseFormProps {
  selectedTags: MappedTags;
  functions: ProfileEditModalFunctions;
}

interface ExpertiseHeaderProps {
  title: string;
  subtitle: string;
  footnote?: string;
  size?: 'medium' | 'large';
}

export const ExpertiseHeader = ({
  title,
  subtitle,
  footnote,
  size = 'medium',
}: ExpertiseHeaderProps) => {
  let formattedText: ReactNode;
  const theme = useTheme();
  switch (size) {
    case 'medium':
      formattedText = (
        <>
          <Typography.Title
            level={3}
            elementTheme="subtitle1"
            textAlign={'left'}
            style={{ width: '100%' }}
          >
            {title}
          </Typography.Title>
          <Typography elementTheme="body2">{subtitle}</Typography>
        </>
      );
      break;
    case 'large':
      formattedText = (
        <>
          <Typography.Title level={3} elementTheme="h6">
            {title}
          </Typography.Title>
          <Typography elementTheme="body2">{subtitle}</Typography>
        </>
      );
      break;
  }

  return (
    <Div flex={'column'} style={{ marginBottom: 32 }} gap={4}>
      {formattedText}

      {footnote && (
        <Typography elementTheme="caption" color={theme.colors.grey_2}>
          {footnote}
        </Typography>
      )}
    </Div>
  );
};

const ExpertiseForm = ({ selectedTags, functions }: ExpertiseFormProps) => {
  const { t } = useTranslation();

  const toggleCategory = async (category: string) => {
    !!functions.toggleCategoryTag &&
      (await functions.toggleCategoryTag(category));
  };

  const toggleService = async (service: string) => {
    !!functions.toggleServiceTag &&
      (await functions.toggleServiceTag({ service }));
  };

  const toggleSpecialty = async (specialty: string) => {
    !!functions.toggleSpecialtyTag &&
      (await functions.toggleSpecialtyTag({ specialty }));
  };

  const serviceBudgetSelectionRows = React.Children.toArray(
    selectedTags.selectedServices.map((tag, index) => {
      if (tag.isSelected) {
        return (
          <ServiceBudgetSelectionRow
            functions={functions}
            tag={tag}
            key={index}
          />
        );
      }

      return <></>;
    }),
  );
  return (
    <Div flex="column" gap={56}>
      <CategorySelect
        selectedCategories={selectedTags.selectedCategories.map(
          (category) => category.label,
        )}
        categoriesOptions={selectedTags.categories.map(
          (category) => category.label,
        )}
        toggleCategory={toggleCategory}
      />
      <div>
        <ExpertiseHeader
          title={t(`provider-profile|expertise.service.title`)}
          subtitle={t(`expertise|service.help`)}
          footnote={t(`expertise|service.footnote`)}
        />
        <Div flex="column" gap={16}>
          <Select
            mode={'multiple'}
            style={{ width: '100%' }}
            allowClear
            placeholder={t(`expertise|service.select.placeholder`)}
            value={selectedTags.selectedServices.map((tag) => tag.tag)}
            onSelect={(e: string) => toggleService(e)}
            onDeselect={(e: string) => toggleService(e)}
            defaultValue={selectedTags.selectedServices.map((tag) => tag.tag)}
            size="large"
          >
            {React.Children.toArray(
              selectedTags.selectedCategories.map((category, index: number) => (
                <Select.OptGroup
                  key={index}
                  label={t(`tags|${category.label}`)}
                >
                  {React.Children.toArray(
                    category.services.map((service) => {
                      return (
                        <Select.Option key={service.tag} value={service.tag}>
                          {t(`tags|${service.label}`)}
                        </Select.Option>
                      );
                    }),
                  )}
                </Select.OptGroup>
              )),
            )}
          </Select>
          <Div flex="column" gap={8}>
            {serviceBudgetSelectionRows}
          </Div>
        </Div>
      </div>
      <div>
        <ExpertiseHeader
          title={t(`provider-profile|expertise.specialty.title`)}
          subtitle={t(`expertise|specialty.subtitle`)}
        />
        <Select
          mode={'multiple'}
          allowClear
          placeholder={t(`expertise|specialty.select.placeholder`)}
          value={selectedTags.selectedSpecialties.map((tag) => tag.tag)}
          onSelect={(e: string) => toggleSpecialty(e)}
          onDeselect={(e: string) => toggleSpecialty(e)}
          defaultValue={selectedTags.selectedSpecialties.map((tag) => tag.tag)}
          style={{ width: '100%' }}
          size="large"
        >
          {React.Children.toArray(
            selectedTags.selectedCategories.map((category, index) => (
              <Select.OptGroup key={index} label={t(`tags|${category.label}`)}>
                {React.Children.toArray(
                  category.specialties.map((specialty) => (
                    <Select.Option key={specialty.tag} value={specialty.tag}>
                      {t(`tags|${specialty.label}`)}
                    </Select.Option>
                  )),
                )}
              </Select.OptGroup>
            )),
          )}
        </Select>
      </div>
    </Div>
  );
};

export default ExpertiseForm;
