import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface PaginationData {
  [key: string]: string;
}
export interface PaginationContextType {
  goToPrevPage: () => void;
  goToNextPage: () => void;
  addParam: (key: string, value: string) => void;
  addMultipleParams: (params: { [key: string]: string }) => void;
  addParamsToURL: () => void;
  goToPage: (page: number, size?: number) => void;
  params: PaginationData;
  getParam: (key: string) => string;
  removeParam: (key: string) => void;
  clearAllParams: (activePage: number, size?: number) => void;
  activePage: number;
  pageSize: number;
  paramsIsLoading: boolean;
}

const PAGE_KEY = 'page';
const PAGE_SIZE_KEY = 'size';

export const PaginationContext = createContext<PaginationContextType>({
  goToPrevPage() {},
  goToNextPage() {},
  goToPage(page) {},
  addParamsToURL() {},
  addParam(key, value) {},
  addMultipleParams(params) {},
  params: {},
  getParam(key) {
    return key;
  },
  removeParam(key) {},
  clearAllParams(activePage) {},
  activePage: 1,
  pageSize: 10,
  paramsIsLoading: true,
});

interface PaginationProviderProps extends PropsWithChildren {
  defaultPageSize?: number;
  defaultPage?: number;
}

export const PaginationProvider: React.FC<PaginationProviderProps> = ({
  children,
  defaultPageSize = 10,
  defaultPage = 1,
}) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [data, setData] = useState<PaginationData>({});

  const [paramsIsLoading, setParamsIsLoading] = useState(true);
  const getParam = (key: string) => {
    return data[key] || '';
  };

  const clearAllParams = (activePage: number, size?: number) => {
    const params = new URLSearchParams();
    params.set(PAGE_KEY, `${activePage}`);
    if (!!size) {
      params.set(PAGE_SIZE_KEY, `${size || defaultPageSize}`);
    }

    const newUrl = `?${params.toString()}`;
    setData({});

    window.history.replaceState(null, '', newUrl);
    navigate(newUrl);
  };

  const preparePage = () => {
    setParamsIsLoading(true);
    const params = new URLSearchParams(search);

    const page = parseInt(`${params.get(PAGE_KEY)}`);

    if (!!page && page !== activePage) {
      setActivePage(page);
    } else {
      setActivePage(defaultPage);
    }

    const size = parseInt(`${params.get(PAGE_SIZE_KEY)}`);

    if (!!size && size !== pageSize) {
      setPageSize(size);
    } else {
      setPageSize(defaultPageSize);
    }

    if (Object.keys(data).length === 0) {
      addSearchToParams();
    }
    setTimeout(() => setParamsIsLoading(false), 500);
  };
  useEffect(() => {
    preparePage();
  }, []);

  useEffect(() => {
    const page = parseInt(getParam(PAGE_KEY));

    if (activePage !== page && (activePage !== defaultPage || !!page)) {
      setData((prevData) => ({
        ...prevData,
        [`${PAGE_KEY}`]: `${activePage}`,
      }));
    }
  }, [activePage]);

  useEffect(() => {
    const size = parseInt(getParam(PAGE_SIZE_KEY));

    if (pageSize !== size && pageSize !== defaultPageSize) {
      setData((prevData) => ({
        ...prevData,
        [`${PAGE_SIZE_KEY}`]: `${pageSize}`,
      }));
    }
  }, [pageSize]);

  useEffect(() => {
    addParamsToURL();
  }, [data]);

  useEffect(() => {
    const page = parseInt(getParam(PAGE_KEY));

    if (!!page && page !== activePage) {
      setActivePage(page);
    }
  }, [pathname]);

  const addParam = (key: string, value: string) => {
    if ([PAGE_KEY, PAGE_SIZE_KEY].includes(key)) return;

    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  const addMultipleParams = (params: { [key: string]: string }) => {
    setData((prevData) => {
      return { ...prevData, ...params };
    });
  };

  const addParamsToURL = () => {
    const params = new URLSearchParams();

    for (const key of Object.keys(data)) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element !== undefined && element !== 'undefined') {
          params.set(key, element);
        } else {
          params.delete(key);
        }
      }
    }
    navigate(`?${params.toString()}`, { replace: true });
  };

  const addSearchToParams = () => {
    const params = new URLSearchParams(search);
    setData(Object.fromEntries(params.entries()));
  };

  const removeParam = (key: string) => {
    if ([PAGE_KEY, PAGE_SIZE_KEY].includes(key)) return;

    setData((prevData) => {
      const { [key]: _, ...rest } = prevData;
      return rest;
    });
  };

  const goToPrevPage = () => {
    setActivePage(activePage - 1);
  };

  const goToNextPage = () => {
    setActivePage(activePage + 1);
  };

  const goToPage = (page: number, size?: number) => {
    setActivePage(page);
    if (!!size) {
      setPageSize(size);
    }
  };

  return (
    <PaginationContext.Provider
      value={{
        goToNextPage,
        goToPrevPage,
        goToPage,
        addParam,
        addMultipleParams,
        getParam,
        removeParam,
        clearAllParams,
        activePage,
        params: data,
        pageSize,
        addParamsToURL,
        paramsIsLoading,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);

export default PaginationProvider;
