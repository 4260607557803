import {
  ApiError,
  ApiErrorInitialState,
  GrantResult,
  HdChatPrompt,
  HdChatPromptRequest,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import { showErrorNotification } from '../../utils';
import AdminApiClient from '../admin-api-client';

const grantSearchAdapter = createEntityAdapter({
  selectId: (model: GrantResult) => model.grant_id,
});

const grantSearchPromptsAdapter = createEntityAdapter({
  selectId: (model: HdChatPrompt) => model.id,
});

interface ChatGptState {
  grantSearchResults: EntityState<GrantResult, string>;
  searchInput: string;
  prompts: EntityState<HdChatPrompt, string>;
  status: 'idle' | 'pending';
  extractedFilters: string[];
  searchExplanation: string;
  error: ApiError;
}

const initialState: ChatGptState = {
  grantSearchResults: grantSearchAdapter.getInitialState(),
  searchInput: '',
  prompts: grantSearchPromptsAdapter.getInitialState(),
  status: 'idle',
  extractedFilters: [],
  searchExplanation: '',
  error: ApiErrorInitialState,
};

export const searchGrantsWithAI = createAsyncThunk<
  { grants: GrantResult[]; extracted_filters: string[]; explanation: string },
  {
    api: AdminApiClient;
    prompt: string;
    locale: string;
    grantFilters: any;
    gptFilters: any;
  },
  { rejectValue: ApiError }
>(
  'chatGpt/fetchChatGptResponse',
  async (
    {
      api,
      prompt,
      locale,
      grantFilters,
      gptFilters,
    }: {
      api: AdminApiClient;
      prompt: string;
      locale: string;
      grantFilters: any;
      gptFilters: any;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.searchGrantsWithAI(
        prompt,
        locale,
        grantFilters,
        gptFilters,
      );
    } catch (error: any) {
      showErrorNotification(error.response.data);
      return rejectWithValue(error.message);
    }
  },
);

export const fetchGrantSearchPrompt = createAsyncThunk<
  HdChatPrompt,
  { api: AdminApiClient; name: string },
  { rejectValue: ApiError }
>(
  'chatGpt/fetchPrompts',
  async (
    { api, name }: { api: AdminApiClient; name: string },
    { rejectWithValue },
  ) => {
    try {
      const prompt = await api.fetchPromptByName(name);
      return prompt;
    } catch (error: any) {
      showErrorNotification(error.response.data);
      return rejectWithValue(error.message);
    }
  },
);

export const updatePrompt = createAsyncThunk<
  HdChatPromptRequest,
  { api: AdminApiClient; prompt: HdChatPromptRequest },
  { rejectValue: ApiError }
>(
  'chatGpt/updatePrompt',
  async (
    { api, prompt }: { api: AdminApiClient; prompt: HdChatPromptRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateChatPrompt(prompt);
    } catch (error: any) {
      showErrorNotification(error.response.data);
      return rejectWithValue(error.message);
    }
  },
);

const chatGptSlice = createSlice({
  name: 'chatGpt',
  initialState,
  reducers: {
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchGrantsWithAI.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(searchGrantsWithAI.fulfilled, (state, { payload }) => {
        if (payload && payload?.grants?.length > 0) {
          const { grants, extracted_filters, explanation } = payload;
          grantSearchAdapter.setAll(state.grantSearchResults, grants);
          state.searchExplanation = explanation;
          state.extractedFilters = extracted_filters;
          state.status = 'idle';
          return;
        }
        grantSearchAdapter.removeAll(state.grantSearchResults);
        state.searchExplanation = '';
        state.extractedFilters = [];
        state.status = 'idle';
      })
      .addCase(searchGrantsWithAI.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
    builder
      .addCase(fetchGrantSearchPrompt.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchGrantSearchPrompt.fulfilled, (state, { payload }) => {
        if (payload) {
          grantSearchPromptsAdapter.setAll(state.prompts, [payload]);
          state.status = 'idle';
        }
        return;
      })
      .addCase(fetchGrantSearchPrompt.rejected, (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.status = 'idle';
      });
  },
});

export const { selectAll: selectGrantSearchResults } =
  grantSearchAdapter.getSelectors(
    (state: RootState) => state.chatGpt.grantSearchResults,
  );
export const selectIsLoading = (state: RootState) =>
  state.chatGpt.status === 'pending';
export const selectError = (state: RootState) => state.chatGpt.error;
export const selectSearchExplanation = (state: RootState) =>
  state.chatGpt.searchExplanation;
export const selectSearchExtractedFilters = (state: RootState) =>
  state.chatGpt.extractedFilters;
export const {
  selectAll: selectAllGrantSearchPrompts,
  selectById: selectPromptByID,
} = grantSearchPromptsAdapter.getSelectors(
  (state: RootState) => state.chatGpt.prompts,
);

export const { setSearchInput } = chatGptSlice.actions;
export const selectSearchInput = (state: RootState) =>
  state.chatGpt.searchInput;

export const chatGptReducer = chatGptSlice.reducer;
