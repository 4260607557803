import Div from '@hellodarwin/core/lib/components/common/div';
import RichTextEditor from '@hellodarwin/core/lib/components/common/rich-editor';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Company } from '@hellodarwin/core/lib/features/entities';
import parse, {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from '@hellodarwin/core/lib/features/helpers/parse';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Empty from 'antd/es/empty';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import {
  selectCompanyById,
  updateCompany,
} from '../../../../features/api/slices/companies-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../../../gins/gin-single/gin-block';

export const ginEditorParseOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.attribs && domNode.name === 'a') {
      const props = attributesToProps(domNode.attribs);

      return (
        <a {...props} rel="nofollow" target="_blank">
          {domToReact(domNode.children)}
        </a>
      );
    }

    if (domNode.attribs && domNode.name === 'p') {
      return (
        <Typography elementTheme="body2">
          {domToReact(domNode.children)}
        </Typography>
      );
    }
    if (domNode.attribs && domNode.name === 'li') {
      return (
        <li>
          <Typography elementTheme="body2">
            {domToReact(domNode.children)}
          </Typography>
        </li>
      );
    }
  },
};

const CompanyCommentsEditorBlock = ({
  entityId,
  section,
  isEditing,
  prepareHandleSave,
  type,
}: GinBlockComponentProps) => {
  const company = useAppSelector((state) => selectCompanyById(state, entityId));
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [editorContent, setEditorContent] = useState(company.comments);
  const { t } = useTranslations();
  const theme = useTheme();

  useEffect(() => {
    setEditorContent(company.comments);
  }, [company]);

  useEffect(() => {
    prepareHandleSave(() => {
      return async () => {
        const updatedCompany: Company = {
          ...company,
          comments: editorContent,
        };
        await dispatch(updateCompany({ api, company: updatedCompany }));
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, section, editorContent, type]);

  const handleEditorChange = (newValue: string) => {
    setEditorContent(newValue);
  };

  return isEditing ? (
    <RichTextEditor
      initialValue={company.comments || ''}
      onChange={handleEditorChange}
    />
  ) : (
    <Typography elementTheme="body2">
      <Div flex="column" gap={8}>
        {!!company?.comments?.length ? (
          parse(company.comments || '', ginEditorParseOptions)
        ) : (
          <Empty
            description={
              <Typography elementTheme="subtitle2" color={theme.colors.grey_2}>
                {t(`grant_single|emptyField`, {
                  fieldName: t(`singleCompanyPage|${section}`),
                })}
              </Typography>
            }
          />
        )}
      </Div>
    </Typography>
  );
};

export default CompanyCommentsEditorBlock;
