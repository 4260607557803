import Close from '@hellodarwin/icons/dist/icons/Close';
import Popconfirm from 'antd/es/popconfirm';
import classNames from 'classnames';
import { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import { useTranslation } from '../../../plugins/i18n';
import { StyledRecursiveTag } from './styles';

export interface RecursiveTagProps {
  text: ReactNode;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  color?: string;
  size?: 'small' | 'medium' | 'large';
  shape?: 'round' | 'square';
  translateTag?: boolean;
  style?: CSSProperties;
  hoverable?: boolean;
  isDimmed?: boolean;
  isHovered?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClose?: () => void;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  deleteConfirmation?: boolean;
}

const HdRecursiveTag = ({
  text,
  icon,
  color,
  size,
  shape,
  translateTag,
  style,
  onClick,
  onClose,
  hoverable,
  onMouseEnter,
  onMouseLeave,
  isDimmed,
  isHovered,
  iconPosition,
  deleteConfirmation,
}: RecursiveTagProps) => {
  const { t: translateTags } = useTranslation(['tags'], { useSuspense: false });

  const classes = classNames({ [`hovered`]: isHovered, [`dimmed`]: isDimmed });

  return (
    <StyledRecursiveTag
      onClick={onClick}
      $size={size}
      $color={color}
      $shape={shape}
      style={style}
      $hoverable={hoverable}
      $isDimmed={isDimmed}
      $isHovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes}
    >
      {iconPosition !== 'end' && icon}
      <span>
        {translateTag ? translateTags(`tags|${text}`).toUpperCase() : text}
      </span>
      {iconPosition === 'end' && icon}
      {!!onClose &&
        (deleteConfirmation ? (
          <Popconfirm
            title="Are you sure you want to delete this tag and its children?"
            description="This action cannot be undone."
            onConfirm={onClose}
          >
            <Close
              style={{ flexShrink: 0, flexGrow: 0 }}
              onClick={(e) => e.stopPropagation()}
              size={8}
            />
          </Popconfirm>
        ) : (
          <Close
            style={{ flexShrink: 0, flexGrow: 0 }}
            onClick={onClose}
            height={8}
            width={8}
          />
        ))}
    </StyledRecursiveTag>
  );
};

export default HdRecursiveTag;
