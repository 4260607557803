import { FormInstance } from 'antd/es/form';
import { SelectProps } from 'antd/es/select';
import { ReactNode, useState } from 'react';
import { Company } from '../../../../features/entities';
import { GrantProject } from '../../../../features/entities/grants-entities';
import FiltersDropdown, { FiltersDropdownProps } from './filter-dropdown';
import FilterHeader from './filter-header';
import { FilterSectionContainterStyled, FilterSectionStyled } from './styles';
import { FundingExplorerFilterValues } from './types';

export interface FundingExplorerFilterProps {
  filterform: FormInstance<FundingExplorerFilterValues>;
  financingType: SelectProps['options'];
  industries: SelectProps['options'];
  subIndustries: SelectProps['options'];
  provinces: SelectProps['options'];
  services: SelectProps['options'];
  annualRevenue: SelectProps['options'];
  companySize: SelectProps['options'];
  TagsCollapse?: () => ReactNode;
  bestProjects?: GrantProject[];
  grantsProjectsLoading?: boolean;
  featuredProjects?: GrantProject[];
  isAdmin?: boolean;
  company?: Company;
  beforeFilters?: ReactNode;
  afterFilters?: ReactNode;
  resetFilters: () => void;
  resetFiltersWithCompany: () => void;
  filterValues: FundingExplorerFilterValues;
  isLoading?: boolean;
}

const FundingExplorerFilter = (props: FundingExplorerFilterProps) => {
  const [advancedFiltersOpened, setAdvancedFiltersOpen] = useState(false);
  const handleAdvancedFilters = () =>
    setAdvancedFiltersOpen(!advancedFiltersOpened);

  const { beforeFilters, afterFilters } = props;

  const dropdownProps: FiltersDropdownProps = {
    isOpen: advancedFiltersOpened,
    handleClose: handleAdvancedFilters,
    ...props,
  };

  return (
    <>
      {!!beforeFilters && (
        <div style={{ marginBottom: 12 }}>{beforeFilters}</div>
      )}
      <FilterSectionContainterStyled $position="top">
        <FilterSectionStyled $position="top">
          <FilterHeader
            {...props}
            advancedFiltersOpened={advancedFiltersOpened}
            handleAdvancedFilters={handleAdvancedFilters}
          />
        </FilterSectionStyled>
        <FiltersDropdown {...dropdownProps} />
      </FilterSectionContainterStyled>
      {!!afterFilters && <div style={{ marginTop: 12 }}>{afterFilters}</div>}
    </>
  );
};

export default FundingExplorerFilter;
