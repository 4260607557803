import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationLink } from '../../../../features/entities/layout-entities';

export type handleLinkClickFunction = (
  link: NavigationLink,
  sublinks?: ItemType[],
) => void;

const useHandleLinkClick = ({
  selectedKeys,
  handleLinkClickCallback,
}: {
  selectedKeys: string[];
  handleLinkClickCallback?: () => void;
}): handleLinkClickFunction => {
  const navigate = useNavigate();

  return useCallback(
    (link: NavigationLink, sublinks?: ItemType[], callback?: () => void) => {
      if (
        !sublinks?.length ||
        !selectedKeys.find((key) => !!sublinks.find((sl) => sl.key === key))
      ) {
        if (link.onClick) link.onClick();
        else if (!link.external_link) {
          navigate(link.pathname);
        }
      }
      if (!!handleLinkClickCallback) handleLinkClickCallback();
    },
    [selectedKeys, navigate],
  );
};

export default useHandleLinkClick;
