import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const RecommendNo = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.17121 16.2222C9.34691 16.2222 10.4074 16.883 11.1338 17.8222C12.2661 19.2895 13.6875 20.5005 15.3071 21.3778C16.3617 21.9467 17.2763 22.7941 17.7183 23.9185C18.0287 24.7063 18.1882 25.547 18.188 26.3956V27.3333C18.188 27.628 18.3033 27.9106 18.5084 28.119C18.7136 28.3274 18.9919 28.4445 19.282 28.4445C20.1525 28.4445 20.9873 28.0933 21.6028 27.4681C22.2183 26.843 22.5641 25.9952 22.5641 25.1111C22.5641 23.4045 22.1848 21.7882 21.5095 20.3437C21.1214 19.5171 21.6655 18.4445 22.567 18.4445H27.1269C28.6235 18.4445 29.964 17.4163 30.123 15.9037C30.1886 15.2785 30.2222 14.6445 30.2222 14C30.2282 9.94616 28.8642 6.01301 26.3581 2.85779C25.7922 2.14372 24.9184 1.77779 24.0169 1.77779H18.1588C17.4543 1.77779 16.7527 1.89334 16.0831 2.11853L11.5408 3.65927C10.8714 3.88537 10.1705 4.00042 9.46506 4.00001H7.10782M7.10782 4.00001C7.22889 3.69631 7.36018 3.40001 7.50167 3.10816C7.78903 2.51557 7.38789 1.77779 6.73878 1.77779H5.41429C4.11751 1.77779 2.91555 2.5452 2.53775 3.80446C2.03253 5.48854 1.77641 7.2395 1.77778 9.00001C1.77778 11.3008 2.20809 13.4978 2.98995 15.5156C3.43631 16.6622 4.57408 17.3333 5.78917 17.3333H7.32517C8.01367 17.3333 8.41189 16.5096 8.05451 15.9111C6.80863 13.8282 6.15154 11.4371 6.1553 9.00001C6.1553 7.23112 6.49372 5.54372 7.10928 4.00001H7.10782ZM19.282 18.4445H22.5641"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecommendNo;
