import BusinessProfile from '@hellodarwin/core/lib/components/company/business-profile';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app';
import { selectCompanyById } from '../../../../features/api/slices/companies-slice';
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from '../../../../features/api/slices/tags-slice';
import { useAdminApi } from '../../../../features/api/use-admin-api';
import { GinBlockComponentProps } from '../../../gins/gin-single/gin-block';
import UpdateCompanyModal from '../../companies-action-modal/update-company-modal';

const BusinessProfileBlock: React.FC<GinBlockComponentProps> = ({
  entityId,
  isEditing,
  handleEditing,
}) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();
  const company = useAppSelector((state) => selectCompanyById(state, entityId));

  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectors(state, company?.industry_sector ?? []),
  );

  useEffect(() => {
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  return (
    <>
      <BusinessProfile
        company={company}
        industries={industries}
        subindustries={subindustries}
      />
      <UpdateCompanyModal
        open={isEditing}
        handleCancel={handleEditing}
        company={company}
      />
    </>
  );
};
export default BusinessProfileBlock;
