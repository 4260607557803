import { ReactNode, useMemo } from 'react';
import { Rfp } from '../../../features/entities/rfp-entities';
import { useTranslations } from '../../../features/providers/translations-provider';
import Div from '../../common/div';
import SeeMoreList from '../../common/see-more/see-more-list';
import Loading from '../../loading';
import ProfileSection from '../profile/profile-section';
import RfpCard from './RfpCard';

type ProfileRfpsProps = {
  isLoading?: boolean;
  rfps: Rfp[];
  isEditable?: boolean;
  id: string;
};

const ProfileRfps = ({
  rfps,
  isLoading,
  isEditable = false,
  id,
}: ProfileRfpsProps) => {
  const { t } = useTranslations();
  const content = useMemo<ReactNode[]>(() => {
    const content: ReactNode[] = [];
    let index = 0;
    rfps.forEach((rfp) => {
      index++;
      content.push(<RfpCard {...rfp} />);
    });
    return content;
  }, [rfps]);

  return (
    <>
      <ProfileSection
        title={t('profile_projects|projectsTitle')}
        isEditable={isEditable}
        id={id}
        hide={!rfps.length}
      >
        <Div style={{ display: 'flex', flexDirection: 'column' }} gap={32}>
          {isLoading ? (
            <Loading />
          ) : (
            <SeeMoreList
              limit={3}
              grid={{ sm: 1, md: 1, lg: 1, column: 1, gutter: 24 }}
              content={content}
            />
          )}
        </Div>
      </ProfileSection>
    </>
  );
};
export default ProfileRfps;
