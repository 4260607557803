import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import FormItem from '@hellodarwin/core/lib/components/forms/form-layouts/form-item';
import {
  FilterSelect,
  FilterSelectDropdown,
} from '@hellodarwin/core/lib/components/grants/funding-explorer/filter/styles';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Close from '@hellodarwin/icons/dist/icons/Close';
import Search from '@hellodarwin/icons/dist/icons/Search';
import Settings from '@hellodarwin/icons/dist/icons/Settings';
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Checkbox from 'antd/es/checkbox';
import Input from 'antd/es/input/Input';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useDebounce from '../../hooks/use-debounce';

export interface GinFilterHeaderProps {
  applyFilter: (query: string, filter: string[]) => void;
}

const GinFilterHeader = ({ applyFilter }: GinFilterHeaderProps) => {
  const { t: t1 } = useTranslation();
  const { t: t2 } = useTranslations();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState<string[]>([]);

  const SEARCH_DELAY_MS = 500;

  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DELAY_MS);
  const debouncedDifficultyFilter = useDebounce(
    difficultyFilter,
    SEARCH_DELAY_MS,
  );

  useEffect(() => {
    applyFilter(debouncedSearchTerm, debouncedDifficultyFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, debouncedDifficultyFilter]);

  const options: SelectProps['options'] = [
    { label: t2('programs_admin|easy'), value: 'easy' },
    { label: t2('programs_admin|medium'), value: 'medium' },
    { label: t2('programs_admin|difficult'), value: 'difficult' },
  ];
  const dropdownRender: SelectProps['dropdownRender'] = useCallback(
    (el: React.ReactElement) => (
      <FilterSelectDropdown>{el}</FilterSelectDropdown>
    ),
    [],
  );

  const dropdownStyle: CSSProperties = useMemo(
    () => ({
      padding: 0,
      borderRadius: 0,
      border: `1px solid ${theme.colors.primary}`,
    }),
    [theme],
  );

  const optionRender: SelectProps['optionRender'] = useCallback(
    (
      option: DefaultOptionType,
      info: {
        index: number;
      },
    ) => {
      return (
        <Div
          flex="row"
          gap={8}
          align="center"
          borderColor={theme.colors.grey_4}
          bordersSide="bottom"
          justify="space-between"
          style={{ padding: '12px 16px' }}
        >
          <Typography
            elementTheme="body3"
            color={theme.colors.primary}
            ellipsis
            overflow
            nowrap
          >
            {option.label}
          </Typography>
          <Checkbox
            type="circle"
            checked={difficultyFilter.includes(option.value?.toString() ?? '')}
          />
        </Div>
      );
    },
    [difficultyFilter, theme],
  );

  return (
    <Div flex="column" gap={8} tablet={{ gap: 24 }}>
      <Div flex="row" gap={8} tablet={{ flex: 'column' }}>
        <FormItem name="difficulty" style={{ flex: 1, margin: 0 }}>
          <FilterSelect
            mode="multiple"
            dropdownRender={dropdownRender}
            style={{ width: 200, maxWidth: '100%', height: 45 }}
            onChange={setDifficultyFilter}
            suffixIcon={
              <Div flex="row" gap={32} align="center" justify="space-between">
                <Div flex="row" gap={8} align="center">
                  <Settings size={20} />
                  <Typography style={{ fontSize: 15 }}>
                    {t2('programs_admin|difficulty')}
                  </Typography>
                </Div>
                <TabArrow down size={12} />
              </Div>
            }
            size="large"
            options={options}
            dropdownStyle={dropdownStyle}
            optionRender={optionRender}
            menuItemSelectedIcon={<></>}
          />
        </FormItem>
        <Input
          placeholder={t1(`grants|ginFilter.searchPlaceholder`)}
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            fontSize: 18,
            height: 45,
          }}
          prefix={
            <Search style={{ marginRight: 8 }} color={theme.colors.grey_3} />
          }
          suffix={
            searchTerm && (
              <Close
                style={{ cursor: 'pointer' }}
                size={16}
                onClick={() => setSearchTerm('')}
                color={theme.colors.grey_3}
              />
            )
          }
        />
        <Button
          defaultStyle={theme.colors.purple_3}
          style={{ height: 45, flex: 1, borderRadius: 4, gap: 24 }}
        >
          {t1(`grants|ginFilter.search`)}
        </Button>
      </Div>
    </Div>
  );
};
export default GinFilterHeader;
