import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Div from '@hellodarwin/core/lib/components/common/div';
import { Match } from '@hellodarwin/core/lib/features/entities';
import { getShortId } from '@hellodarwin/core/lib/features/helpers';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Tooltip from 'antd/es/tooltip';

import { Link } from 'react-router-dom';
import Clipboard from '../../components/clipboard';
import { copyIntoClipboard } from '../clipboard';

type ProviderMatchPreviewProps = {
  match: Match;
};

const ProvierMatchPreview = ({ match }: ProviderMatchPreviewProps) => {
  const { t } = useTranslation();

  const items = [
    {
      label: 'Rfp Name',
      text: (
        <Tooltip placement={'bottom'} title={'Go to rfp page'}>
          <Link to={`/projects/${match.rfp.rfp_id}`}>
            {match.rfp.title || t(`tags|${match.rfp.category}`)}
          </Link>
        </Tooltip>
      ),
    },
    {
      label: 'Company Name',
      text: (
        <Tooltip placement={'bottom'} title={'Go to company page'}>
          <Link to={`/companies/${match.rfp.company_id}`}>
            {match.rfp.company?.name}
          </Link>
        </Tooltip>
      ),
    },
    {
      label: 'Rfp ID',
      text: (
        <Div flex={'row'} align={'center'}>
          <Clipboard
            copy={match.rfp_id}
            title={getShortId(match.rfp_id)}
          ></Clipboard>
          <Tooltip
            placement={'bottom'}
            title={'Copy to clipboard'}
            style={{ marginLeft: '0.5rem' }}
          >
            <CopyOutlined onClick={() => copyIntoClipboard(match.rfp_id)} />
          </Tooltip>
        </Div>
      ),
    },
  ];

  return (
    <div>
      {items.map((item, i) => (
        <div key={`item-${i}`} className="hd-preview-item match-preview-item">
          <div className="match-preview-item-label">{item.label}</div>
          <div>{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default ProvierMatchPreview;
