import Divider from 'antd/es/divider';
import { CSSProperties } from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import { useTheme } from 'styled-components';
import { NavigationLink } from '../../../../features/entities';
import { AppType } from '../../../../features/entities/core-entities';
import NavigationMenuLabel from './menu-item-label';
import {
  SubitemsDropdownStyled,
  SubitemsDropdownWrapperStyled,
} from './styles';
import SubNavigationMenuItemsList, {
  SubNavigationMenuItemsListProps,
  SubNavigationMenuItemsListSize,
} from './subitems-list';

export type SubitemsListDropdownPosition = 'right' | 'bottom';

interface SubitemsListDropdownProps extends SubNavigationMenuItemsListProps {
  isActive?: boolean;
  app: AppType;
  handleDropdownMouseLeave: () => void;
  position?: SubitemsListDropdownPosition;
  size?: SubNavigationMenuItemsListSize;
  style?: CSSProperties;
  isDropdown?: boolean;
  parentItem?: NavigationLink;
}

const SubitemsListDropdown = ({
  isActive,
  app,
  handleDropdownMouseLeave,
  isDropdown,
  isCollapsed,
  position = 'right',
  size = 'medium',
  style,
  parentItem,
  ...props
}: SubitemsListDropdownProps) => {
  const theme = useTheme();

  return isDropdown ? (
    <SubitemsDropdownWrapperStyled
      $isActive={isActive}
      $position={position}
      onMouseLeave={handleDropdownMouseLeave}
    >
      <SubitemsDropdownStyled $app={app} $size={size} style={style}>
        {!!parentItem && (
          <div style={{ marginTop: 4 }}>
            <NavigationMenuLabel {...parentItem} app={app} noStyle />
            <Divider
              style={{ margin: '4px 0', borderColor: theme.colors.primary }}
            />
          </div>
        )}
        <SubNavigationMenuItemsList
          {...props}
          app={app}
          size={size}
          isCollapsed={isCollapsed}
        />
      </SubitemsDropdownStyled>{' '}
    </SubitemsDropdownWrapperStyled>
  ) : (
    <ReactCollapse isOpened={!!isActive}>
      <SubNavigationMenuItemsList
        {...props}
        app={app}
        size={size}
        isCollapsed={isCollapsed}
      />
    </ReactCollapse>
  );
};

export default SubitemsListDropdown;
