import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { AdminProfile } from '@hellodarwin/core/lib/features/entities';
import { useTranslations } from '@hellodarwin/core/lib/features/providers/translations-provider';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import HubspotUserButton from '../../components/admins/hubspot-user-buttons';

interface HubspotSettingsInfosProps {
  admin: AdminProfile;
}

interface HubspotSettingLabelProps {
  label: string;
  value?: string;
}

const HubspotSettingLabel: React.FC<HubspotSettingLabelProps> = ({
  value,
  label,
}) => {
  return (
    <Typography elementTheme="body2">
      <b>{label} :</b> {!!value?.length ? value : '   ---   '}
    </Typography>
  );
};

const HubspotSettingsInfos: React.FC<HubspotSettingsInfosProps> = ({
  admin,
}) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const isOnHubspot = !!admin.hubspot?.crm_id;

  return (
    <Div
      borderColor={theme.colors.primary}
      fitContent
      backgroundColor={theme.colors.white_1}
    >
      <Div
        flex="column"
        gap={8}
        style={{ padding: '8px 16px' }}
        borderColor={isOnHubspot ? theme.colors.grey_4 : undefined}
        bordersSide="bottom"
      >
        <Div flex="row" gap={16} align="center">
          <Typography elementTheme="body2" style={{ maxWidth: 350 }}>
            {t('admin_settings|hubspot_status')}
          </Typography>
          <HubspotUserButton admin={admin} />
        </Div>
        {isOnHubspot && (
          <Typography
            elementTheme="body2"
            color={theme.colors.error}
            style={{ maxWidth: 350 }}
          >
            {t('admin_settings|hubspot_linked_update', {
              admin_name: admin.hubspot?.name ?? admin.first_name,
            })}
          </Typography>
        )}
      </Div>
      {isOnHubspot && (
        <Div flex="column" gap={16} style={{ padding: '8px 16px' }}>
          <Typography elementTheme="subtitle2">
            Informations on Hubspot
          </Typography>
          <HubspotSettingLabel
            value={admin.first_name}
            label={t('admin_settings|firstName')}
          />
          <HubspotSettingLabel
            value={admin.last_name}
            label={t('admin_settings|lastName')}
          />
          <HubspotSettingLabel
            value={admin.phone}
            label={t('admin_settings|phone')}
          />
          <HubspotSettingLabel
            value={admin.title}
            label={t('admin_settings|job_title')}
          />
        </Div>
      )}
    </Div>
  );
};

export default HubspotSettingsInfos;
