import Warning from '@hellodarwin/icons/dist/icons/Warning';
import Modal, { ModalProps } from '..';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../div';

interface ModalWarningProps extends ModalProps {}

const ModalWarning = ({ children, ...modalProps }: ModalWarningProps) => {
  const theme = useTheme();
  return (
    <Modal
      {...modalProps}
      styles={{
        ...modalProps?.styles,
        content: { overflow: 'visible', ...modalProps?.styles?.content },
      }}
    >
      <Div
        style={{ paddingTop: 40, paddingBottom: 16 }}
        flex={'column'}
        align={'center'}
      >
        <Div
          flex={'row'}
          align={'center'}
          justify={'center'}
          backgroundColor={theme.colors.yellow_2}
          style={{
            width: 100,
            height: 100,
            borderRadius: 99,
            position: 'absolute',
            top: 0,
            transform: 'translateY(-50%)',
          }}
        >
          <Warning width={64} height={64} style={{ marginBottom: 3 }} />
        </Div>
        {children}
      </Div>
    </Modal>
  );
};

export default ModalWarning;
