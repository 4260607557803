import { useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { GrantStatus } from '../../../features/entities/grants-entities';
import { ProgramStatus } from '../../../features/entities/programs-entities';
import { useTranslations } from '../../../features/providers/translations-provider';
import HdTag, { TagEventHandlers } from '../../common/hd-tag';

interface GrantStatusTagProps extends TagEventHandlers {
  status: GrantStatus | ProgramStatus;
  text?: string;
}

export const getGrantStatusTagTheme = (
  theme: DefaultTheme,
  status: GrantStatusTagProps['status'],
) => {
  switch (status) {
    case ProgramStatus.Completed:
    case 'open':
      return {
        tagColor: theme.colors.green_4,
        svgBackground: theme.colors.green_1,
        svgBorder: theme.colors.green_3,
      };
    case ProgramStatus.Active:
    case 'openingSoon':
      return {
        tagColor: theme.colors.purple_4,
        svgBackground: theme.colors.purple_3,
        svgBorder: theme.colors.purple_2,
      };
    case 'closed':
      return {
        tagColor: theme.colors.grey_5,
        svgBackground: theme.colors.grey_4,
        svgBorder: theme.colors.grey_3,
      };
    case ProgramStatus.Rejected:
    case ProgramStatus.Canceled:
    case 'closingSoon':
      return {
        tagColor: theme.colors.red_5,
        svgBackground: theme.colors.error,
        svgBorder: theme.colors.red_3,
      };
    case 'new':
      return {
        tagColor: theme.colors.transparent,
        svgBackground: theme.colors.red_1,
        svgBorder: theme.colors.transparent,
      };

    case 'suspending':
      return {
        tagColor: theme.colors.yellow_4,
        svgBackground: theme.colors.yellow_2,
        svgBorder: theme.colors.yellow_1,
      };
    case ProgramStatus.Active:
      return {
        tagColor: theme.colors.orange_1,
        svgBackground: theme.colors.red_1,
        svgBorder: theme.colors.transparent,
      };
    case 'featured':
    default:
      return {
        tagColor: theme.colors.purple_5,
        svgBackground: 'transparent',
        svgBorder: 'transparent',
      };
  }
};

const GrantStatusTag = (props: GrantStatusTagProps) => {
  const { status, text, ...eventHandlers } = props;
  const theme = useTheme();
  const { t } = useTranslations();

  const tagTheme = useMemo(
    () => getGrantStatusTagTheme(theme, status),
    [status, theme],
  );

  return (
    <HdTag
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <circle
            cx="6"
            cy="6"
            r="5.25"
            fill={tagTheme.svgBackground}
            stroke={tagTheme.svgBorder}
            strokeWidth="1.5"
          />
        </svg>
      }
      highContrastText
      color={tagTheme.tagColor}
      iconPosition="start"
      text={text || t(`grant_card_status|${status}`)}
      {...eventHandlers}
    />
  );
};

export default GrantStatusTag;
