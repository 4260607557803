import styled from '@hellodarwin/core/lib/plugins/styled';

export const FilterSectionContainterStyled = styled.div<{
  $position: 'top' | 'bottom';
  $navCollapsed?: boolean;
}>`
  z-index: 500;

  ${({ $position, theme, $navCollapsed }) =>
    $position === 'top'
      ? `
    position: sticky;
    top: 0;
    @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
      position: relative;
    }
    `
      : `
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - ${
      $navCollapsed
        ? theme.appearance.sidebarWidthCollapsed
        : theme.appearance.sidebarWidth
    }px);
    transition: width 180ms linear;
      `}
`;

export const FilterSectionStyled = styled.div<{
  $position: 'top' | 'bottom';
}>`
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};
  background-color: ${({ theme }) => theme.colors.white_2};
  padding: 12px 24px;
  border-radius: ${({ $position }) => ($position === 'top' ? '4px' : '0px')};
  box-shadow: ${({ theme, $position }) =>
    $position === 'top'
      ? theme.appearance.box_shadow
      : theme.appearance.box_shadow_hover};
  backdrop-filter: blur(20px);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    backdrop-filter: none;
  }
`;
